import React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import { withStyles, WithStyles } from '@material-ui/core/styles';

import Day from './Day';
import { DayData } from './Day';

import { Event } from './';

import styles from './styles';


interface WeekProps {
  previousCurrentNextView: moment.Moment;
  currentMonthView: moment.Moment;
  selected: moment.Moment | null;
  select: (day: DayData) => void;
  monthEvents: Array<Event>;
}

type WeekPropsType = WeekProps & WithStyles<typeof styles>;
class Week extends React.Component<WeekPropsType, {}> {
  render() {
    const days = [];
    let date = this.props.previousCurrentNextView;
    const classes = this.props.classes;
    const currentMonthView = this.props.currentMonthView;
    const selected = this.props.selected;
    const select = this.props.select;
    const monthEvents = this.props.monthEvents;

    for (let i = 0; i < 7; i++) {
      let dayHasEvents = false;
      const events = [];

      for (let j = 0; j < monthEvents.length; j++) {
        if (monthEvents[j].date.isSame(date, "day")) {
          dayHasEvents = true;
          events.push(monthEvents[j]);
        }
      }

      const day: DayData = {
        number: date.date(),
        isCurrentMonth: date.month() === currentMonthView.month(),
        isToday: date.isSame(new Date(), "day"),
        date: date,
        events: events,
        hasEvents: dayHasEvents
      };

      days.push(<Day key={i} day={day} selected={selected} select={select} />);
      date = date.clone();
      date.add(1, "d");
    }
    return (
      <div className={classNames(classes.row, classes.week)
      }>
        {days}
      </div>
    );
  }
}

export default withStyles(styles)(Week);
