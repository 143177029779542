import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { iconColors as colors } from '../../colors';
import { VideoRoom } from '../../lib/api/videoroom';
import { State } from '../../lib/reducers';
import { shouldMirrorVideo } from '../../lib/reduxSelectors/settings';
import NoVideoElement from '../NoVideoElement';
import VideoElement from '../VideoElement';


type AnalyserCallback = (intensity: number) => void


const messages = defineMessages({
  deviceOverConstrained: { id: 'deviceOverConstrained' },
  deviceNotFound: { id: 'deviceNotFound' },
  deviceError: { id: 'deviceError' },
  noAudioDeviceFound: { id: 'noAudioDeviceFound' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.common.white,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  })
);


let audioContext: null | AudioContext = null;
function getAudioContext() {
  if (!audioContext && window.AudioContext) {
    audioContext = new window.AudioContext();
  }
  return audioContext;
}


// simple react hook to calculate sound pressure level in the given media
// stream
function useAudioAnalyser(stream: (null | MediaStream), callback: AnalyserCallback) {
  const reqRef = React.useRef<null | ReturnType<typeof requestAnimationFrame>>(null);

  React.useEffect(
    () => {
      if (!stream) {
        return;
      }

      if (!VideoRoom.getAudioTrackFromStream(stream)) {
        return;
      }

      const audioContext = getAudioContext();
      if (!audioContext) {
        return;
      }
      const analyser = audioContext.createAnalyser();
      const audioData = new Float32Array(analyser.frequencyBinCount);
      const intAudioData = new Uint8Array(analyser.frequencyBinCount);
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const processAudio = () => {
        if (analyser.getFloatTimeDomainData) {
          analyser.getFloatTimeDomainData(audioData);
        }
        else {
          // safari polyfill for getFloatTimeDomainData
          // as per https://webaudio.github.io/web-audio-api/#dom-analysernode-getbytetimedomaindata
          // data can be clamped, but should be enough for this simple case
          analyser.getByteTimeDomainData(intAudioData);
          for (let i = 0; i < intAudioData.length; i++){
            audioData[i] = (intAudioData[i] - 128) * 0.0078125;
          }
        }
        reqRef.current = requestAnimationFrame(processAudio);

        // audio data is an array with elements in the interval [-1, 1]
        // calculte the rms, and return the value (normalized by 100) to caller
        const squaredSum = audioData.reduce((acc, el) => acc + (el * el), 0);
        const rms = Math.sqrt(squaredSum / audioData.length);
        callback(Math.floor(rms * 100));
      };

      reqRef.current = requestAnimationFrame(processAudio);
      return () => {
        if (reqRef.current) {
          cancelAnimationFrame(reqRef.current);
        }
        analyser.disconnect();
        source.disconnect();
      };
    }
    , [stream, callback]
  );
}


function TalkingIndicator(props: { stream: null | MediaStream }) {
  const { stream } = props;

  const [intensity, setIntensity] = React.useState(0);

  useAudioAnalyser(stream, setIntensity);

  const scaledIntensity = intensity / 33; // scale it to be in the interval [1, 3]

  const centralHeight = scaledIntensity + 0.15;  // set a min height of 0.15
  const sideHeight = scaledIntensity / 2 + 0.15; // half the central height

  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    }}>
      <div style={{
        margin: '1em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '2em',
          width: '2em',
        }}>
          <div style={{
            transition: 'height 75ms steps(4,jump-both)',
            animation: 'jiggle .6s linear 0s infinite',
            margin: '0.05em',
            backgroundColor: colors.active,
            width: '.25em',
            height: `${sideHeight}em`,
            borderRadius: '.125em',
          }}>
          </div>
          <div style={{
            transition: 'height 75ms steps(4,jump-both)',
            animation: 'jiggle .6s linear 0s infinite',
            margin: '0.05em',
            backgroundColor: colors.active,
            width: '.25em',
            height: `${centralHeight}em`,
            borderRadius: '.125em',
          }}>
          </div>
          <div style={{
            transition: 'height 75ms steps(4,jump-both)',
            animation: 'jiggle .6s linear 0s infinite',
            margin: '0.05em',
            backgroundColor: colors.active,
            width: '.25em',
            height: `${sideHeight}em`,
            borderRadius: '.125em',
          }}>
          </div>
        </div>
      </div>
    </div>
  );
}


const DevicePreview = React.forwardRef(
  function DevicePreview(props: DevicePreviewProps, ref) {
    const {
      isAcquiringMedia,
      streamError,
      stream,
      shouldMirrorVideo
    } = props;

    const hasVideoTrack = Boolean(VideoRoom.getVideoTrackFromStream(stream));
    const hasAudioTrack = Boolean(VideoRoom.getAudioTrackFromStream(stream));

    return (
      <div style={{ height: '100%', position: 'relative' }}>
        {(hasVideoTrack && props.videoEnabled) ?
          <VideoElement
            ref={ref}
            muted
            mirrored={shouldMirrorVideo}
            fullHeight={false}
            rounded={true}
            src={stream}
          />
          : <NoVideoElement />
        }
        <TalkingIndicator stream={stream} />
        {
          (!hasAudioTrack && !streamError)
            ? <StreamError error={{ name: 'NoAudioDeviceFound' }} isAcquiringMedia={isAcquiringMedia} />
            : <StreamError error={streamError} isAcquiringMedia={isAcquiringMedia} />
        }
      </div>
    );
  }
);


type StreamErrorProps = {
  error: null | { name?: string; constraint?: string};
  isAcquiringMedia: boolean;
}


function StreamError({ error, isAcquiringMedia }: StreamErrorProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  if (!error || isAcquiringMedia) {
    return null;
  }

  let msg = formatMessage(messages.deviceError);
  if (error.name && error.name === 'OverconstrainedError' && error.constraint) {
    const reason = error.constraint;
    if (reason === 'deviceId') {
      msg = formatMessage(messages.deviceNotFound);
    }
    else if (reason === 'width' || reason === 'height') {
      msg = formatMessage(messages.deviceOverConstrained);
    }
  }
  else if (error.name && error.name === 'NotFoundError') {
    msg = formatMessage(messages.deviceNotFound);
  }
  else if (error.name && error.name === 'TypeError') {
    msg = formatMessage(messages.noAudioDeviceFound);
  }
  else if (error.name && error.name === 'NoAudioDeviceFound') {
    msg = formatMessage(messages.noAudioDeviceFound);
  }

  return (
    <div className={classes.error}>
      {msg}
    </div>
  );
}


type Props = {
  stream: null | MediaStream;
  streamError: StreamErrorProps['error'];
  isAcquiringMedia: boolean;
  videoEnabled: boolean;
}

type MappedProps = {
  shouldMirrorVideo: boolean;
}


export type DevicePreviewProps = Props & MappedProps


const mapStateToProps = (state: State): MappedProps => {
  return {
    shouldMirrorVideo: shouldMirrorVideo(state),
  };
};


export default connect(mapStateToProps, null, null, { forwardRef: true })(DevicePreview);
