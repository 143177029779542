import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import classNames from 'classnames';

import { linkify } from '../../lib/utils';
import CopyToClipboard from '../CopyToClipboard';

import useStyles from './style';


const messages = defineMessages({
  roomWebAccess: { id: 'roomInfoWebAccess' },
});


type Props = {
  rawUrl: string;
}


function ConfWebAccess(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { rawUrl } = props;

  const url = linkify(rawUrl, '_blank', 25);

  return (
    <React.Fragment>
      <div className={classes.title}>
        {formatMessage(messages.roomWebAccess)}
      </div>
      <div className={classes.webAccessContainer}>
        <div className={classNames(classes.content, classes.linkContent)}>
          {url}
        </div>
        <CopyToClipboard rawUrl={rawUrl} />
      </div>
    </React.Fragment>
  );

}


export default ConfWebAccess;
