import React from "react";
import { useIntl } from "react-intl";

import moment from "moment";

import { DialogContentText } from "@material-ui/core";

import { WebSocketReady } from "../../../../lib/reducers/websocket";

import messages from "./messages";


type TitleProps = {
  errorCode: WebSocketReady['errorCode'];
}

type MessageProps = {
  errorPayload: WebSocketReady['errorPayload'];
} & TitleProps


function DialogTitle(props: TitleProps) {
  const { formatMessage } = useIntl();

  const { errorCode } = props;

  const errorCodes = messages.errorCode;
  type errKey = keyof typeof errorCodes;

  let label = errorCodes.generic.title;
  if (errorCode) {
    if (errorCodes[errorCode as errKey] && errorCodes[errorCode as errKey].title) {
      label = errorCodes[errorCode as errKey].title;
    }
  }

  return formatMessage(label);
}


function DialogMessage(props: MessageProps) {
  const { formatMessage } = useIntl();

  const { errorCode, errorPayload } = props;

  const errorCodes = messages.errorCode;
  type errKey = keyof typeof errorCodes;

  let label = errorCodes.generic.message;
  if (errorCode) {
    if (errorCodes[errorCode as errKey] && errorCodes[errorCode as errKey].message) {
      label = errorCodes[errorCode as errKey].message;
    }
  }

  let formattedMessage = '';
  if (errorCode === 1002 && errorPayload && errorPayload.dt_start) {
    const start = moment(errorPayload.dt_start);
    const now = moment();

    const messageParams = {
      title: errorPayload.title,
      dStart: start.format('LL'),
      tStart: start.format('LT'),
      delta: start.from(now)
    };

    formattedMessage = formatMessage(label, messageParams);
  }
  else if (errorCode === 1003 && errorPayload && errorPayload.dt_end) {
    // scheduled meeting has ended
    const end = moment(errorPayload.dt_end);
    const now = moment();
    const format = end.isSame(now, 'day') ? 'LT' : 'LL';
    formattedMessage = formatMessage(label, { dtEnd: end.format(format) });
  }
  else if (errorCode === 1002 || errorCode === 1003) {
    formattedMessage = formatMessage(errorCodes.generic.message);
  }
  else {
    formattedMessage = formatMessage(label);
  }

  return (
    <DialogContentText>
      {formattedMessage}
    </DialogContentText>
  );
}


export { DialogTitle, DialogMessage };
