import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Button, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import { endMeeting } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import ClosableDialog from '../ClosableDialog';


const localMessages = defineMessages({
  title: { id: 'leaveMeetingDialogTitle' },
  body: { id: 'leaveMeetingDialogBody' },
  terminate: { id: 'leaveMeetingDialogTerminate' },
  leave: { id: 'leaveMeetingDialogLeave' },
});


type Props = {
  onClose: () => void;
  onHangup: () => void;
  open: boolean;
}

type ExtendedProps = Props & RouteComponentProps;


function LeaveMeetingDialog(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onEnd = React.useCallback(
    () => {
      dispatch(endMeeting());
      logEvent(Event.END_MEETING, { 'from': 'own_controls' });
    }
    , [dispatch]
  );

  return (
    <ClosableDialog
      open={props.open}
      onClose={props.onClose}
      disableBackdropClick
      maxWidth="xs"
      title={formatMessage(localMessages.title)}
    >
      <DialogContent>
        <DialogContentText>
          {formatMessage(localMessages.body)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onEnd} variant="outlined" color="primary">
          {formatMessage(localMessages.terminate)}
        </Button>
        <Button onClick={props.onHangup} variant="contained" color="primary">
          {formatMessage(localMessages.leave)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


export default withRouter(LeaveMeetingDialog);
