import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import {
  startLivestreaming,
  stopLivestreaming
} from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { RoomActionsContext } from '../../lib/contexts';
import { State as RoomState } from '../../lib/reducers/room';
import { IconStreaming, IconStop } from '../IconSet';


const messages = defineMessages({
  menuStreaming: { id: 'menuStreaming' },
});

type MappedProps = {
  isStreaming: boolean;
  isRequestingStreamingOp: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    // eslint-disable-next-line @typescript-eslint/camelcase
    handleStartLivestreaming: () => startLivestreaming({ baseUrl: window.location.origin }),
    handleStopLivestreaming: () => stopLivestreaming(),
  },
  dispatch
  );

type State = {
  room: RoomState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isStreaming: state.room.isStreaming,
  isRequestingStreamingOp: state.room.isRequestingStreamingOp,
});

type Props = {} & MappedProps

type PropsType = Props & { intl: IntlShape } & ReturnType<typeof mapDispatchToProps>

function getStopRecordingIcon(props: PropsType, stopRecord: () => void) {
  const l10n = props.intl.formatMessage;
  const stopRecordingIcon = (
    <React.Fragment>
      <MenuItem onClick={stopRecord} disabled={props.isRequestingStreamingOp}>
        <ListItemIcon>
          <IconStop size={32} />
        </ListItemIcon>
        <ListItemText primary={l10n(messages.menuStreaming)} />
      </MenuItem>
    </React.Fragment>
  );
  return stopRecordingIcon;
}

function getStartRecordingIcon(props: PropsType, startRecord: () => void) {
  const l10n = props.intl.formatMessage;
  const startRecordingIcon = (
    <React.Fragment>
      <MenuItem onClick={startRecord} disabled={props.isRequestingStreamingOp}>
        <ListItemIcon>
          <IconStreaming size={32} />
        </ListItemIcon>
        <ListItemText primary={l10n(messages.menuStreaming)} />
      </MenuItem>
    </React.Fragment>

  );
  return startRecordingIcon;
}

function StreamingButton(props: PropsType) {
  const roomActionsContext = React.useContext(RoomActionsContext);

  const startRecord = (): void => {
    props.handleStartLivestreaming();
    logEvent(Event.STREAMING_VIDEO, { 'action': 'start', 'from': roomActionsContext });
  };

  const stopRecord = (): void => {
    props.handleStopLivestreaming();
    logEvent(Event.STREAMING_VIDEO, { 'action': 'stop', 'from': roomActionsContext });
  };

  return (
    <div>
      {props.isStreaming ?
        getStopRecordingIcon(props, stopRecord) :
        getStartRecordingIcon(props, startRecord)
      }
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(StreamingButton));
