type Size = {
  width: number;
  height: number;
}


// position a single element according to the container size (given as input
// args) and maintaining the 16:9 ratio
export function scaleAndCenterVideo(size: Size): React.CSSProperties {
  const constrWidth = size.height / 9 * 16;
  const constrHeight = size.width / 16 * 9;
  let realWidth = size.width;
  let realHeight = size.height;
  if (constrWidth <= size.width) realWidth = constrWidth;
  if (constrHeight <= size.height) realHeight = constrHeight;
  const top = Math.floor(Math.abs(size.height - realHeight) / 2);
  const left = Math.floor(Math.abs(size.width - realWidth) / 2);
  realWidth = Math.floor(realWidth);
  realHeight = Math.floor(realHeight);

  return {
    width: realWidth,
    height: realHeight,
    top,
    left,
  };
}


// stretch a video to completely fill the container, even if some cropping is
// needed
/*export function stretchVideo(containerSize: Size, videoSize: Size): React.CSSProperties {
  const widthRatio = containerSize.width / videoSize.width;
  const heightRatio = containerSize.height / videoSize.height;

  const ratio = Math.max(widthRatio, heightRatio);

  let realWidth = videoSize.width * ratio;
  let realHeight = videoSize.height * ratio;
  const top = Math.floor((containerSize.height - realHeight) / 2);
  const left = Math.floor((containerSize.width - realWidth) / 2);
  realWidth = Math.floor(realWidth);
  realHeight = Math.floor(realHeight);

  return {
    width: realWidth,
    height: realHeight,
    top,
    left,
  };
}*/


function calculateGrid(howMany: number, viewSize: Size, gutterSize: Size) {
  const w = 16 / 9;
  const h = 1;
  const W = viewSize.width;
  const H = viewSize.height;
  const sW = gutterSize.width;
  const sH = gutterSize.height;

  if (howMany < 1) {
    return { rows: 0, cols: 0, ratio: 0, all: [] };
  }

  const sequence = range(1, howMany);

  const lW = sequence.map((c) => {
    return (W - (c - 1) * sW) / (c * w);
  });
  const lH = sequence.map((c) => {
    return (H - (c - 1) * sH) / (c * h);
  });
  const l = lW.concat(lH);

  const res = l.filter((c) => {
    const a = Math.floor((W + sW) / (c * w + sW));
    const b = Math.floor((H + sH) / (c * h + sH));
    return a * b >= howMany;
  }).sort((a, b) => { return b - a; });

  const bestRatio = res[0];
  let rows = Math.floor((H + sH) / (bestRatio * h + sH));
  const cols = Math.floor((W + sW) / (bestRatio * w + sW));
  rows = Math.min(rows, Math.ceil(howMany / cols));  // only return filled rows

  return { rows: rows, cols: cols, ratio: bestRatio, all: res };
}


function range(start: number, end: number) {
  return [...Array(1 + end - start).keys()].map(v => start + v);
}


function gridElements(w: number, h: number, streams: number) {
  const gutters = { width: 0, height: 0 };
  const viewSize = { width: w, height: h };
  const grid = calculateGrid(streams, viewSize, gutters);

  const boxes: React.CSSProperties[] = [];
  const colsInLastRow = streams % grid.cols;
  for (let row = 0; row < grid.rows; row++) {
    for (let col = 0; col < grid.cols; col++) {
      const videoW = Math.floor(grid.ratio * (16 / 9));
      const videoH = Math.floor(grid.ratio);
      /* calculate centering offset */
      const neededWidth = videoW * grid.cols;
      let availableWidth = viewSize.width - neededWidth;
      if ((row === grid.rows - 1) && (colsInLastRow > 0) && (col < colsInLastRow)) {
        availableWidth = viewSize.width - videoW * colsInLastRow;
      }
      const left = (col * videoW) + (availableWidth / 2);
      const usedRows = Math.ceil(streams / grid.cols);
      const neededHeight = videoH * usedRows;
      const availableHeight = viewSize.height - neededHeight;
      const top = (row * grid.ratio) + (availableHeight / 2);
      const p = {
        left: left,
        top: top,
        width: videoW,
        height: grid.ratio,
        position: 'absolute' as React.CSSProperties["position"],
        transition: 'all 1s, transform 1s',
      };
      boxes.push(p);
    } // end cols
  } // end rows
  return boxes;
}


export default gridElements;
