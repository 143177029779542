import React from 'react';
import { withOrientationChange } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import useMediaStream from '../../hooks/useMediaStream';
import { republishStream } from '../../lib/actions/room';
import { toggleDeviceSettings } from '../../lib/actions/settings';
import { getLogger } from '../../lib/logger';
import { State } from '../../lib/reducers';
import { inMeeting } from '../../lib/reduxSelectors/videoRoom';
import { OrientationProps } from '../../lib/utils/types';
import DevicePreview, { DevicePreviewProps } from '../AVSettings';
import DeviceSelection from '../AVSettings/DeviceSelection';
import ClosableDialog from '../ClosableDialog';

import VideoSwitch from './VideoSwitch';


const messages = defineMessages({
  ok: { id: 'ok' },
  dialogTitle: { id: 'deviceSelectorTitle' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    portrait: {
      display: 'flex',
      flexDirection: 'column',
    },
    landscape: {
      display: 'flex',
      flexDirection: 'row',
    },
    previewContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 2,
    },
    previewContainerPortrait: {
      marginBottom: '2em',
    },
    previewContainerLandscape: {
      marginRight: '2em',
    },
    previewPortrait: {
      width: '320px',
      height: '180px',
      marginBottom: '1em',
      alignSelf: 'center',
    },
    previewLandscape: {
      minWidth: '160px',
      maxWidth: '160px',
      minHeight: '90px',
      maxHeight: '90px',
      alignSelf: 'center',
      marginBottom: '1em',
    },
    deviceSelectionLandscape: {
      flexGrow: 10
    }
  })
);


function DeviceSelector(props: ExtendedProps) {
  const {
    canPublishVideo,
    isOpen,
    inMeeting,
    isLandscape,
    isVideoEnabled,
    showVideoSwitch,
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  const close = React.useCallback(
    () => dispatch(toggleDeviceSettings())
    , [dispatch]
  );

  const withVideo = inMeeting ? canPublishVideo && isVideoEnabled : isVideoEnabled;

  const roomOptions = {
    acquireVideo: withVideo,
    muted: false,
    frameRate: props.roomOptions.frame_rate,
    streamQuality: props.roomOptions.stream_quality,
  };

  const accept = React.useCallback(
    () => {
      if (inMeeting) {
        const logger = getLogger(`Republish stream`);
        dispatch(republishStream(roomOptions, logger));
      }
      dispatch(toggleDeviceSettings());
    }
    , [dispatch, inMeeting, roomOptions]
  );

  const previewContainerStyle = classNames(classes.previewContainer,
    isLandscape ? classes.previewContainerLandscape : classes.previewContainerPortrait
  );

  const forceFullScreen = useMediaQuery(useTheme().breakpoints.down('sm')) && isLandscape;

  return (
    <ClosableDialog
      open={isOpen}
      disableBackdropClick
      onClose={close}
      fullWidth={isLandscape}
      forceFullScreen={forceFullScreen}
      title={formatMessage(messages.dialogTitle)}
    >
      <DialogContent className={isLandscape ? classes.landscape : classes.portrait}>
        <div className={previewContainerStyle}>
          <div className={isLandscape ? classes.previewLandscape : classes.previewPortrait}>
            <MediaPreview videoEnabled={withVideo} />
          </div>
          { showVideoSwitch && <VideoSwitch withVideo={withVideo} /> }
        </div>
        <div className={isLandscape ? classes.deviceSelectionLandscape : undefined}>
          <DeviceSelection withVideo={withVideo} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={accept} color='primary'>
          {formatMessage(messages.ok)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


function MediaPreview({ videoEnabled }: { videoEnabled: DevicePreviewProps['videoEnabled'] }) {
  const [isAcquiringMedia, setIsAcquiringMedia] = React.useState(true);
  const onVideoLoading = React.useCallback(
    (isAcquiring: boolean) => {
      setIsAcquiringMedia(isAcquiring);
    },
    [setIsAcquiringMedia]
  );

  const { stream, error: streamError } = useMediaStream({ videoEnabled: videoEnabled, onVideoLoading });


  return (
    <DevicePreview
      stream={stream}
      streamError={streamError}
      videoEnabled={videoEnabled}
      isAcquiringMedia={isAcquiringMedia}
    />
  );
}


type Props = {
};

type MappedProps = {
  isOpen: State['settings']['deviceSettingsDialogOpen'];
  inMeeting: boolean;
  roomOptions: State['appconfig']['room_options'];
  canPublishVideo: boolean;
  isVideoEnabled: boolean;
  showVideoSwitch: boolean;
}

type ExtendedProps = Props & MappedProps & OrientationProps

function mapStateToProps(state: State) {
  const amInMeeting = inMeeting(state);
  return {
    isOpen: state.settings.deviceSettingsDialogOpen,
    inMeeting: amInMeeting,
    roomOptions: state.appconfig.room_options,
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
    isVideoEnabled: state.settings.videoEnabled,
    showVideoSwitch: amInMeeting ? state.room.mediaPermissions.canPublishVideo : true,
  };
}


export default withOrientationChange(connect(mapStateToProps)(DeviceSelector));
