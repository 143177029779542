import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { State } from '../../lib/reducers';
import { PaneType } from '../../lib/redux_types';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { setSelectedPaneHelper } from '../../lib/utils/pane';
import LocalStorage from '../../localStorage';

import PaneDesktop from './Pane/PaneDesktop';
import PaneMobile from './Pane/PaneMobile';


type Props = {
  handleScreenShare: () => void;
  enableDesktopControl: () => void;
  localStore: LocalStorage;
  url: string;
}

type ExtendedProps = Props & MappedProps


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loadingMaskContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

function Meeting(props: ExtendedProps) {
  const { selectedPane, waitForMediaPermissions } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  const openPane = (wantedPane: PaneType) => {
    setSelectedPaneHelper(wantedPane, selectedPane, 'meeting_bar', dispatch);
  };

  if (waitForMediaPermissions) {
    return (
      <div className={classes.loadingMaskContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      {isMobileOnly
        ? <PaneMobile openPane={openPane} url={props.url} />
        : <PaneDesktop openPane={openPane} url={props.url} />
      }
    </React.Fragment>
  );
}


type MappedProps = {
  isRecorder: boolean;
  selectedPane: PaneType;
  waitForMediaPermissions: boolean;
}


const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    isRecorder: isRecorder(state, ownProps.url),
    selectedPane: state.room.selectedPane,
    waitForMediaPermissions: !state.settings.mediaPermissionsGranted,
  };
};


export default connect(mapStateToProps)(Meeting);
