import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { FormControlLabel, Switch, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { saveBeautifyEffectEnabledConfig } from '../../lib/actions/settings';
import { State } from '../../lib/reducers';
import LocalStorage from '../../localStorage';


const messages = defineMessages({
  beautifyEffect: { id: 'beautifyEffect' },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      width: "100%",
      margin: 0,
    },
    label: {
      flex: 1,
    }
  })
);


function BeautifySwitch(props: ExtendedProps) {
  const { isBeautifyEffectEnabled, isButtonDisabled } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  const switchBeautifyEffectEnabled = React.useCallback(
    () => {
      dispatch(saveBeautifyEffectEnabledConfig(!isBeautifyEffectEnabled, new LocalStorage()));
    }
    , [dispatch, isBeautifyEffectEnabled]
  );

  return (
    <FormControlLabel
      disabled={isButtonDisabled}
      classes={{ label: classes.label,  root: classes.root }}
      label={ formatMessage(messages.beautifyEffect) }
      labelPlacement='start'
      control={
        <Switch
          checked={isBeautifyEffectEnabled}
          onChange={switchBeautifyEffectEnabled}
          color="primary"
        />
      }
    />
  );
}

type Props = {
  isButtonDisabled: boolean;
}

type MappedProps = {
  isBeautifyEffectEnabled: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State) => ({
  isBeautifyEffectEnabled: Boolean(state.settings.beautifyEffectEnabled),
});


export default connect(mapStateToProps)(BeautifySwitch);
