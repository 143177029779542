/**
 * Combines/merges Material UI styles so that they may be used on a single
 * component with the "withStyles" HOC.
 *
 * @see {@link https://github.com/mui-org/material-ui/issues/11517#issuecomment-407509327}
 * @see {@link https://material-ui.com/customization/css-in-js/}
 *
 */
function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}

export default combineStyles;