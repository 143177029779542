import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { resetJoinFailure } from '../../../lib/actions/websocket';
import { State } from '../../../lib/reducers';
import { WebSocketReady } from '../../../lib/reducers/websocket';
import { getIsAuthenticated } from '../../../lib/reduxSelectors/auth';
import {
  getWsErrorCode,
  getWsErrorMessage,
  getWsErrorPayload,
  getWsJoinFailure } from '../../../lib/reduxSelectors/websocket';
import ClosableDialog from '../../ClosableDialog';

import { DialogMessage, DialogTitle } from './utils/DialogStrings';
import LoginLink from './utils/LoginLink';
import messages from './utils/messages';
import RetriableError from './utils/RetriableError';
import useStyles from './utils/styles';
import { backToHome } from './utils/utils';


type MappedProps = {
  joinFailed: boolean;
  errorCode: WebSocketReady['errorCode'];
  errorMessage: WebSocketReady['errorMessage'];
  errorPayload: WebSocketReady['errorPayload'];
  isAuthenticated: boolean;
  loginEnabled: boolean;
  snapshot: State['waitingRoom']['snapshot'];
}

type Props = {};

type ExtendedProps = Props & MappedProps;


function isRetriableError(errorCode: MappedProps['errorCode']) {
  if (errorCode) {
    return [
      1000, // private room not running
      1001, // unhandled server error
      // 1002, removed: handled in WaitingRoom -> too early for scheduled meeting
      1005, // room owner not present yet
      1013, // room locked
      1408, // connection error
    ].includes(errorCode);
  }
  else {
    return false;
  }
}


function isJoinableError(errorCode: MappedProps['errorCode']) {
  // 1016: only authenticated user can join
  return errorCode === 1016;
}


function JoinableError(props: ExtendedProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  const gotoHome = React.useCallback(
    () => {
      dispatch(resetJoinFailure());
      backToHome(history, true);
    }, [history, dispatch]
  );

  const title = DialogTitle(props);
  const message = DialogMessage(props);

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth
      title={title}
    >
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <LoginLink renderAs='button' />
      </DialogActions>
    </ClosableDialog>
  );
}


function NonRetriableError(props: ExtendedProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const history = useHistory();

  const gotoHome = React.useCallback(
    () => {
      dispatch(resetJoinFailure());
      backToHome(history, true);
    }, [history, dispatch]
  );

  const title = DialogTitle(props);
  const message = DialogMessage(props);

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth
      title={title}
    >
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Box
          className={classes.fullWidth}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box flexGrow={1}>
            <LoginLink renderAs='text' />
          </Box>
          <Box>
            <Button variant='contained' onClick={gotoHome} color='primary'>
              {formatMessage(messages.ok)}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </ClosableDialog>
  );
}


function JoinErrorDialog(props: ExtendedProps) {
  const { joinFailed, errorCode } = props;

  if (isRetriableError(errorCode)) {
    return <RetriableError {...props} />;
  }

  if (isJoinableError(errorCode)) {
    return <JoinableError {...props} />;
  }

  if (joinFailed) {
    return <NonRetriableError {...props} />;
  }

  return null;
}


function mapStateToProps(state: State) {
  return {
    joinFailed: getWsJoinFailure(state.websocket),
    errorCode: getWsErrorCode(state.websocket),
    errorMessage: getWsErrorMessage(state.websocket),
    errorPayload: getWsErrorPayload(state.websocket),
    isAuthenticated: getIsAuthenticated(state.auth),
    loginEnabled: state.appconfig.login_enabled,
    snapshot: state.waitingRoom.snapshot,
  };
}


export default connect(mapStateToProps)(JoinErrorDialog);
