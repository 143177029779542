import React from 'react';
import { connect } from 'react-redux';

import { State as RoomState } from '../../lib/reducers/room';
import { IconRecordOn } from '../IconSet';


type MappedProps = {
  isRecording: boolean;
}

type State = {
  room: RoomState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isRecording: state.room.isRecording,
});

type Props = {
} & MappedProps


function RecordOnIcon(props: Props) {

  return (
    <React.Fragment>
      {props.isRecording ?
        <IconRecordOn size={24} /> :
        null
      }
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(RecordOnIcon);
