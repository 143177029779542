import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { Dispatch } from 'redux';

import { subscribeToVideo, changeVideoQuality } from '../../lib/actions/room';
import { getLogger } from '../../lib/logger';
import { VideoQualityOptions, StreamQualityValue } from '../../lib/redux_types';
import { showStream } from '../../lib/utils/mobile';
import LoadingVideoElement from '../VideoElement/LoadingVideoElement';

import Fullscreen from './Fullscreen';
import mapStateToProps, { ExtendedProps } from './PresentationLayout/state';
import usePublishersWatcher from './usePublishersWatcher';
import VideoToolbar from './VideoToolbar';


type Props = {} & ExtendedProps;


function MobileLayout(props: Props) {
  const {
    selectedStream,
    myScreenIsSelected,
    myUserId,
    remoteVideoStreams,
    myStreamIsSelected,
    hasVideoStream,
    roomOwner,
    roomOwnerHasStream,
    roomOwnerHasScreen,
    localVideoStream,
    localScreenStream,
    layoutConfig,
    shouldMirrorVideo
  } = props;

  /* Subscribing to all new publishers */
  const dispatch = useDispatch();
  usePublishersWatcher()
    .forEach((u) => {
      if (u.stream) {
        dispatch(subscribeToVideo(u.uid));
      }
      if (u.screen) {
        dispatch(subscribeToVideo(`${u.uid}_screen`));
      }
    });

  let stream = null;
  let uid = null;
  if (selectedStream) {
    if (myStreamIsSelected && hasVideoStream && myUserId) {
      uid = myUserId;
      stream = localVideoStream;
    }
    else if (myScreenIsSelected && localScreenStream && myUserId) {
      uid = `${myUserId}_screen`;
      stream = localScreenStream;
    }
    else if (remoteVideoStreams[selectedStream] && remoteVideoStreams[selectedStream].stream) {
      uid = selectedStream;
      stream = remoteVideoStreams[selectedStream].stream;
    }
  } else {
    if (roomOwner && roomOwnerHasScreen) {
      uid = `${roomOwner}_screen`;
      if (roomOwner === myUserId) {
        stream = localScreenStream;
      }
      else if (remoteVideoStreams[uid]) {
        stream = remoteVideoStreams[uid].stream;
      }
    }
    else if (roomOwner && roomOwnerHasStream) {
      uid = roomOwner;
      if (roomOwner === myUserId) {
        stream = localVideoStream;
      }
      else if (remoteVideoStreams[uid]) {
        stream = remoteVideoStreams[uid].stream;
      }
    }
    else if (hasVideoStream && myUserId && localVideoStream) {
      uid = myUserId;
      stream = localVideoStream;
    }
  }

  const isScreen = uid ? uid.endsWith('_screen') : false;
  const mySelf = uid === myUserId;

  // Force the shown stream to always be 'featured' if not already so
  if (uid && layoutConfig.featured_id !== uid && !mySelf) {
    const streamType = isScreen ? 'screen' : 'stream';
    if (layoutConfig.featured_type !== streamType) {
      showStream(streamType, uid, dispatch);
    }
  }

  maybeChangePublishingQuality(props, dispatch);


  if (uid && stream) {
    return (
      <Fullscreen user={uid}>
        <LoadingVideoElement
          user={uid}
          mirrored={mySelf && shouldMirrorVideo}
          fullHeight={false}
          addVideoMutedIconOverlay={true}
          stream={stream}
          fit={!isScreen}
        />
        <VideoToolbar uid={uid} />
      </Fullscreen>
    );
  } else {
    // FIXME: decide what to do here
    // the outer div is there otherwise the resize detector stops working
    return (
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      </div>
    );
  }
}


// TODO: why is any needed here?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function maybeChangePublishingQuality(props: ExtendedProps, dispatch: Dispatch<any>) {
  const { layout, amIRoomModerator } = props;
  if (layout !== 'lesson') {
    return;
  }

  // no need to avoid useless calls since this api won't apply settings if no
  // change is needed
  if (amIRoomModerator) {
    const constraints: VideoQualityOptions = {
      streamQuality: props.configuredVideoQuality,
    };
    if (props.roomOptions.frame_rate) {
      constraints.frameRate = props.roomOptions.frame_rate;
    }
    dispatch(changeVideoQuality(constraints, getLogger('MobileLayout')));
  }
  else {
    const constraints: VideoQualityOptions = {
      streamQuality: StreamQualityValue.P180,
      frameRate: 5
    };
    dispatch(changeVideoQuality(constraints, getLogger('MobileLayout')));
  }
}


export default connect(mapStateToProps)(MobileLayout);
