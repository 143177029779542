import React from 'react';
import { connect } from 'react-redux';
import { QueryRenderer } from 'react-relay';

import { useRelayEnvironment } from 'relay-hooks';

import {
  myRecordingsQuery as myRecordingsType,
  SearchSort
} from '../../lib/api/relay/__generated__/myRecordingsQuery.graphql';
import { myRecordingsQuery } from '../../lib/api/relay/myRecordings';
import { newEvent, ERROR } from '../../lib/notifications';
import { AuthState } from '../../lib/reducers/auth';
import { getIsAuthenticated } from '../../lib/reduxSelectors/auth';

import MyRecordingList from './MyRecordingList';


type Props = {
  onCloseClick: () => void;
};

type MappedProps = {
  isAuthenticated: boolean;
}

type ExtendedProps = Props & MappedProps;

function MyRecordings(ownProps: ExtendedProps) {
  const relayEnvironment = useRelayEnvironment();
  const pageSize = 10;
  const { isAuthenticated } = ownProps;
  if (!isAuthenticated) {
    return null;
  }

  const renderer = ({ error, props }: { error: Error | null; props: myRecordingsType['response'] | null }) => {
    if (error) {
      newEvent(ERROR, 'reportUnableLoadRecording', 'reportUnableLoadRecording', "Load recordings Error");
      return null;
    } else if (props) {
      return (
        <MyRecordingList
          onCloseClick={ownProps.onCloseClick}
          data={props}
          pageSize={pageSize}
        />
      );
    }
    return null;
  };

  const searchSorting: SearchSort = { direction: "DESC", sort: "recStart" };
  return (
    <QueryRenderer<myRecordingsType>
      environment={relayEnvironment}
      query={myRecordingsQuery}
      variables={{
        count: pageSize,
        sorting: [searchSorting],
        filters: []
      }}
      render={renderer}
    />
  );

}

type State = {
  auth: AuthState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isAuthenticated: getIsAuthenticated(state.auth),
});

export default connect(mapStateToProps)(MyRecordings);
