import { HoloPushSub } from '../../pushNotifications/pushNotifications';
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  ADD_LOCKED_JOIN_REQUEST,
  SET_LOCKED_JOIN_REQUESTS,
  REMOVE_LOCKED_JOIN_REQUEST,
  CLEAR_LOCKED_JOIN_REQUESTS,
  SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED,
  SET_PUSH_SUBSCRIPTION,
  SET_PUSH_NOTIFICATIONS_AVAILABLE,
  SET_IS_SUBSCRIBING,
  Action,
} from '../actions/notifications';
import { UUID, LockedJoinRequest, NotificationEvent } from '../redux_types';


type Notification = {
  ref: UUID;
  notification: NotificationEvent;
};

export interface State {
  notifications: Notification[];
  lockedJoinRequests: LockedJoinRequest[];
  lockedJoinRequestDialogMinimized: boolean;
  pushSubscription: HoloPushSub | null;
  pushNotificationsAvailable: boolean;
  isSubscribing: boolean;
}


const defaultState = {
  notifications: [],
  lockedJoinRequests: [],
  lockedJoinRequestDialogMinimized: false,
  pushSubscription: null,
  pushNotificationsAvailable: false,
  isSubscribing: false,
};

function notifications(state: State = defaultState, action: Action) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      let newNotifications = [];
      if (action.payload) {
        newNotifications = state.notifications.concat([
          Object.assign({}, action.payload)]);
      } else {
        newNotifications = state.notifications;
      }
      return Object.assign({}, state, {
        notifications: newNotifications
      });
    }
    case REMOVE_NOTIFICATION: {
      const payload = action.payload || {};
      return Object.assign({}, state, {
        notifications: state.notifications.filter(({ ref }) => {
          return payload.ref !== ref;
        }),
      });
    }
    case ADD_LOCKED_JOIN_REQUEST: {
      return { ...state,
        lockedJoinRequests: state.lockedJoinRequests.concat(action.payload.requests)
      };
    }
    case SET_LOCKED_JOIN_REQUESTS: {
      if (action.payload && action.payload.requests) {
        return Object.assign({}, state, {
          lockedJoinRequests: action.payload.requests
        });
      }
      return state;
    }
    case REMOVE_LOCKED_JOIN_REQUEST: {
      const payload = action.payload || {};
      return Object.assign({}, state, {
        lockedJoinRequests: state.lockedJoinRequests.filter((request) => {
          return payload.reqId !== request.reqId;
        }),
      });
    }
    case CLEAR_LOCKED_JOIN_REQUESTS: {
      return Object.assign({}, state, {
        lockedJoinRequests: [],
      });
    }
    case SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED: {
      return Object.assign({}, state, {
        lockedJoinRequestDialogMinimized: action.payload.minimized,
      });
    }
    case SET_PUSH_SUBSCRIPTION: {
      return Object.assign({}, state, {
        pushSubscription: action.payload.subscription,
      });
    }
    case SET_PUSH_NOTIFICATIONS_AVAILABLE: {
      return Object.assign({}, state, {
        pushNotificationsAvailable: action.payload.available,
      });
    }
    case SET_IS_SUBSCRIBING: {
      return Object.assign({}, state, {
        isSubscribing: action.payload.isSubscribing,
      });
    }
    default:
      return state;
  }
}

export default notifications;
export { defaultState };
