import React, { Component } from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Error } from '@material-ui/icons';


const messages = defineMessages({
  errBoundaryHeader: { id: 'errBoundaryHeader' },
  errBoundaryMessage: { id: 'errBoundaryMessage' }
});


const styles = (theme: Theme) => createStyles({
  mainContainer: {
    marginTop: theme.spacing(8)
  },
  container: {
    maxWidth: '100%'
  },
  errorHeader: {
    textAlign: 'center',
    color: theme.palette.text.disabled
  },
  errorMessage: {
    textAlign: 'center',
    color: theme.palette.text.disabled
  },
  iconContainer: {
    textAlign: 'center',
  },
  errorIcon: {
    fontSize: theme.typography.fontSize * 6
  }
});

type Props = {}


type State = {
  hasError: boolean;
}


type ExtendedProps = React.PropsWithChildren<Props> & {intl: IntlShape} & WithStyles<typeof styles>;


class ErrorBoundary extends Component<ExtendedProps, State> {
  public state: State = { hasError: false };

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  renderError() {
    const { classes, intl } = this.props;

    return (
      <div className={classes.mainContainer}>
        <CssBaseline />
        <Container className={classes.container}>
          <Container className={classes.iconContainer}>
            <Error className={classes.errorIcon} color="disabled" />
          </Container>
          <Typography variant="h2" className={classes.errorHeader}>
            {intl.formatMessage(messages.errBoundaryHeader)}
          </Typography>
          <Typography variant="h5" className={classes.errorMessage}>
            {intl.formatMessage(messages.errBoundaryMessage)}
          </Typography>
        </Container>
      </div>
    );

  }

  render() {
    if (this.state.hasError) {
      // inform the user that something bad just happened
      return this.renderError();
    }

    return this.props.children;
  }
}


//export default ErrorBoundary;

export default withStyles(styles)(injectIntl(ErrorBoundary));
