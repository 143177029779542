import { WebSocketState } from "../reducers/websocket";


export function getWsUserId(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_READY':
      return state.uid;
    default:
      return null;
  }
}


export function getWsRoomName(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_READY':
      return state.room_name;
    default:
      return null;
  }
}


export function getWsMeetingDetails(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_READY':
      return state.meeting_details;
    default:
      return null;
  }
}


export function getWsJoinFailure(state: WebSocketState): boolean {
  switch (state.kind) {
    case 'SOCKET_CONNECTED':
    case 'SOCKET_READY':
      return state.joinFailure;
    default:
      return false;
  }
}


export function getWsErrorCode(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_CONNECTED':
    case 'SOCKET_FAILED':
    case 'SOCKET_READY':
      return state.errorCode;
    default:
      return null;
  }
}


export function getWsErrorMessage(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_CONNECTED':
    case 'SOCKET_FAILED':
    case 'SOCKET_READY':
      return state.errorMessage;
    default:
      return null;
  }
}


export function getWsChannel(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_READY':
      return state.channel;
    default:
      return null;
  }
}


export function getWsErrorPayload(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_CONNECTED':
    case 'SOCKET_READY':
      return state.errorPayload;
    default:
      return null;
  }
}


export function getWsLeaveReason(state: WebSocketState) {
  switch (state.kind) {
    case 'SOCKET_CONNECTED':
      return state.leaveReason;
    default:
      return null;
  }
}


export function isWsConnected(state: WebSocketState): boolean {
  switch (state.kind) {
    case 'SOCKET_READY':
    case 'SOCKET_CONNECTED':
      return state.isConnected;
    default:
      return false;
  }
}


export function isWsConnecting(state: WebSocketState): boolean {
  switch (state.kind) {
    case 'SOCKET_CONNECTING':
      return state.isConnecting;
    default:
      return false;
  }
}


export function isWsReady(state: WebSocketState): boolean {
  switch (state.kind) {
    case 'SOCKET_READY':
      return true;
    default:
      return false;
  }
}
