import {
  AUTH_READY,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  GUESTLOGIN_REQUEST,
  GUESTLOGIN_SUCCESS,
  GUESTLOGIN_FAILURE,
  SYNC_TOKEN_LOGIN,
  SYNC_TOKEN_LOGOUT
} from '../actions/auth';


export interface ReadyAuthState {
  kind: 'AUTH_READY';
  isFetching: boolean;
  isAuthenticated: boolean;
  isAuthenticatedAsGuest: boolean;
  token: string | null;
  displayName: string | null;
  username: string | null;
  errorCode: number | null;
  errorMessage: string | null;
}

// export interface NotReadyAuthState {}
interface NotReadyAuthState {
  kind: 'AUTH_NOT_READY';
}

export type AuthState = ReadyAuthState | NotReadyAuthState;

const defaultState: NotReadyAuthState = {
  kind: 'AUTH_NOT_READY'
};

const defaultReadyState: ReadyAuthState = {
  kind: 'AUTH_READY',
  isFetching: false,               // auth api request done, waiting for response
  isAuthenticated: false,          // correctly authenticated with username and password
  isAuthenticatedAsGuest: false,   // "authenticated" as guest
  token: null,                     // user token (as platform user or guest)
  displayName: null,               // name and surname if a user, requested display name if guest
  username: null,                  // username
  errorCode: null,                 // auth api error code
  errorMessage: null,              // auth api error message
};


// FIXME: remove any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function auth(state: AuthState = defaultState, action: any = {}) {
  switch (state.kind) {
    case 'AUTH_NOT_READY':
      return notReadyAuth(state, action);
    case 'AUTH_READY':
      return readyAuth(state, action);
  }
}

// FIXME: remove any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function notReadyAuth(state: NotReadyAuthState = defaultState, action: any = {}) {
  switch (action.type) {
    case AUTH_READY:
      return Object.assign({}, state, defaultReadyState);
    case LOGIN_SUCCESS:
      return readyAuth(defaultReadyState, action);
    case LOGOUT_SUCCESS:
      return readyAuth(defaultReadyState, action);
    case GUESTLOGIN_SUCCESS:
      return readyAuth(defaultReadyState, action);
    case GUESTLOGIN_FAILURE:
      return readyAuth(defaultReadyState, action);
    default:
      return state;
  }
}

// FIXME: remove any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function readyAuth(state: ReadyAuthState = defaultReadyState, action: any = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        username: action.payload.username,
        errorCode: null,
        errorMessage: null,
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        isAuthenticatedAsGuest: false,
        token: action.payload.token,
        username: action.payload.username,
        errorCode: null,
        errorMessage: null,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        username: null,
        token: null,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        isAuthenticatedAsGuest: false,
        username: null,
        token: null,
        errorCode: null,
        displayName: null,
        errorMessage: null,
      });
    case GUESTLOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticatedAsGuest: false,
        displayName: action.payload.displayName,
        errorCode: null,
        errorMessage: null,
      });
    case GUESTLOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticatedAsGuest: true,
        token: action.payload.token,
        displayName: action.payload.displayName,
        errorCode: null,
        errorMessage: null,
      });
    case GUESTLOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticatedAsGuest: false,
        displayName: null,
        token: null,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode,
      });
    case SYNC_TOKEN_LOGIN:
      return Object.assign({}, state, {
        token: action.payload.token,
      });
    case SYNC_TOKEN_LOGOUT:
      return state = defaultReadyState;
    default:
      return state;
  }
}


export default auth;
export { defaultState };
