import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { FormControlLabel, Switch } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { saveVideoEnabled } from '../../lib/actions/settings';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import LocalStorage from '../../localStorage';
import { IconVideo } from '../IconSet';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cameraSwitchContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      border: `2px solid ${theme.palette.secondary.light}`,
      borderRadius: '10px',
      width: 'fit-content',
      padding: theme.spacing(0.5, 1),
      alignSelf: 'center'
    },
    switchLabelPlacement: {
      margin: 0
    }
  })
);


function VideoSwitch(props: ExtendedProps) {
  const { withVideo, isVideoEnabled } = props;

  const dispatch = useDispatch();
  const classes = useStyles();

  const switchVideoEnabled = React.useCallback(
    () => {
      const action = isVideoEnabled ? 'mute' : 'unmute';
      logEvent(Event.MUTE_MY_VIDEO, { 'from': 'media_settings', 'action': action });
      dispatch(saveVideoEnabled(!isVideoEnabled, new LocalStorage()));
    }
    , [dispatch, isVideoEnabled]
  );

  return (
    <div className={classes.cameraSwitchContainer}>
      <FormControlLabel
        classes={{ labelPlacementStart: classes.switchLabelPlacement }}
        label={<IconVideo size={24} />}
        labelPlacement='start'
        control={
          <Switch
            checked={withVideo}
            onChange={switchVideoEnabled}
            color="primary"
          />
        }
      />
    </div>
  );
}


type Props = {
  withVideo: boolean;
};

type MappedProps = {
  isVideoEnabled: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State) => ({
  isVideoEnabled: state.settings.videoEnabled,
});


export default connect(mapStateToProps)(VideoSwitch);
