import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';

import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { sendChatMessage } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { IconSend } from '../IconSet';


const messages = defineMessages({
  sendAMessage: { id: 'sendAMessage' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    input: {
      padding: '1ex',
    },
  })
);


type Props = {
  to?: string;
  disableFocus?: boolean;
  disabled?: boolean;
}


function Input(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const dispatch = useDispatch();

  const [inputValue, setInputValue] = React.useState("");

  const sendMessage = () => {
    if (inputValue !== "" && !props.disabled) {
      dispatch(sendChatMessage(inputValue, props.to));
      setInputValue("");
      logEvent(Event.SEND_CHAT_MESSAGE, { 'message_type': props.to ? 'private' : 'public' });
    }
  };

  const onKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      sendMessage();
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(ev.target.value);
  };

  const disabled = inputValue === '' || props.disabled;

  return (
    <TextField
      className={classes.input}
      disabled={props.disabled}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              disabled={disabled}
              onClick={sendMessage}
            >
              <IconSend size={24} />
            </IconButton>
          </InputAdornment>
      }}
      autoFocus={(isMobileOnly || props.disableFocus) ? false : true}
      fullWidth
      value={inputValue}
      placeholder={formatMessage(messages.sendAMessage)}
      onChange={onChange}
      onKeyUp={onKeyUp}
    />
  );
}


export default Input;
