import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';


import { Tooltip, Theme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { iconColors } from '../../colors';
import { removeLockedJoinRequest, setLockedJoinRequestDialogMinimized } from '../../lib/actions/notifications';
import { acceptLockedJoinRequest, denyLockedJoinRequest } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { LockedJoinRequest } from '../../lib/redux_types';
import { avatarLarge, avatarSmall } from '../../style/LockedJoinRequests';
import ClosableDialog from '../ClosableDialog';
import { IconAcknowledgeState, IconEsc } from '../IconSet';
import NoVideoElement from '../NoVideoElement';


const Transition = React.forwardRef(function Transition(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: React.ComponentPropsWithoutRef<typeof Slide>,
  ref: SlideProps['ref'],
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const messages = defineMessages({
  lockedJoinRequestMsg: { id: 'lockedJoinRequestMsg' },
  lockedJoinRequestTitle: { id: 'lockedJoinRequestTitle' },
  lockedJoinRequestsTitle: { id: 'lockedJoinRequestsTitle' },
  acceptButton: { id: 'acceptLockedJoinRequestButton' },
  denyButton: { id: 'denyLockedJoinRequestButton' },
  acceptAllButton: { id: 'acceptAllLockedJoinRequestButton' },
  denyAllButton: { id: 'denyAllLockedJoinRequestButton' },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    avatar: {
      width: `${avatarSmall.width}`,
      height: `${avatarSmall.height}`,
      marginRight: theme.spacing(3),
      borderRadius: '10px'
    },
    avatarLarge: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: '10px'
    },
    avatarLargeImage: {
      width: `${avatarLarge.width}`,
      height: `${avatarLarge.height}`,
      display: 'flex',
      justifyContent: 'center',
      objectFit: 'contain',
      margin: 'auto',
    },
    list: {
      overflow: 'auto'
    },
    actions: {
      padding: theme.spacing(1.5)
    },
    buttonAllow: {
      '&:hover': {
        fill: `${iconColors.active}BB`,
      }
    },
    buttonDeny: {
      '&:hover': {
        fill: `${iconColors.inactive}BB`,
      }
    }
  })
);

function LockedJoinRequestDialog(props: ExtendedProps) {

  const {
    lockedJoinRequests,
    minimized,
  } = props;

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const classes = useStyles();

  function handleAccept(lockedJoinRequest: LockedJoinRequest) {
    return (() => {
      dispatch(acceptLockedJoinRequest(lockedJoinRequest));
      dispatch(removeLockedJoinRequest(lockedJoinRequest.reqId));
      logEvent(Event.HANDLE_LOCKED_REQUESTS, { 'action': 'allow' });
    });
  }

  function handleDeny(lockedJoinRequest: LockedJoinRequest) {
    return (() => {
      dispatch(denyLockedJoinRequest(lockedJoinRequest));
      dispatch(removeLockedJoinRequest(lockedJoinRequest.reqId));
      logEvent(Event.HANDLE_LOCKED_REQUESTS, { 'action': 'deny' });
    });
  }

  function handleAcceptAll(requests: LockedJoinRequest[]) {
    return () => {
      requests.forEach((req: LockedJoinRequest) => {
        dispatch(acceptLockedJoinRequest(req));
        dispatch(removeLockedJoinRequest(req.reqId));
        logEvent(Event.HANDLE_LOCKED_REQUESTS, { 'action': 'allow_all' });
      });
    };
  }

  function handleDenyAll(requests: LockedJoinRequest[]) {
    return () => {
      requests.forEach((req: LockedJoinRequest) => {
        dispatch(denyLockedJoinRequest(req));
        dispatch(removeLockedJoinRequest(req.reqId));
        logEvent(Event.HANDLE_LOCKED_REQUESTS, { 'action': 'deny_all' });
      });
    };
  }

  function handleClose() {
    dispatch(setLockedJoinRequestDialogMinimized(true));
    logEvent(Event.LOCKED_REQUESTS, { 'action': 'close' });
  }

  function SingleRequestContent(props: { request: LockedJoinRequest }) {

    const { request } = props;

    return (
      <DialogContent className={classes.avatarLarge}>
        <DialogContentText>
          {formatMessage(messages.lockedJoinRequestMsg, { dname: request.dname })}
        </DialogContentText>
        <div className={classes.avatarLargeImage}>
          {request.snapshot && request.snapshot.startsWith('data:image/')
            ? <img src={request.snapshot} alt='' />
            : <NoVideoElement />
          }
        </div>
      </DialogContent>
    );
  }

  function MultipleRequestsContent(props: { requests: LockedJoinRequest[] }) {

    const { requests } = props;

    const showAvatar = (snapshot: string) => {
      return (
        snapshot && snapshot.startsWith('data:image/') ?
          <Avatar variant='square' src={snapshot} className={classes.avatar} />
          :
          <div className={classes.avatar}>
            <NoVideoElement />
          </div>
      );
    };

    return (
      <List className={classes.list}>
        {requests.map((lockedJoinRequest: LockedJoinRequest) => {
          return (
            <ListItem
              style={{ paddingRight: '96px' }}
              key={lockedJoinRequest.reqId}>
              <ListItemAvatar>
                {showAvatar(lockedJoinRequest.snapshot)}
              </ListItemAvatar>
              <ListItemText
                primary={lockedJoinRequest.dname}
                style={{ overflow: 'hidden' }}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleAccept(lockedJoinRequest)}>
                  <Tooltip
                    placement="top"
                    title={formatMessage(messages.acceptButton)}
                  >
                    <div><IconAcknowledgeState className={classes.buttonAllow} size={28} /></div>
                  </Tooltip>
                </IconButton>
                <IconButton edge="end" onClick={handleDeny(lockedJoinRequest)}>
                  <Tooltip
                    placement="top"
                    title={formatMessage(messages.denyButton)}
                  >
                    <div><IconEsc className={classes.buttonDeny} size={28} /></div>
                  </Tooltip>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }

  function Actions(props: { requests: LockedJoinRequest[] }) {
    const { requests } = props;
    const classes = useStyles();
    const single = requests.length === 1;
    const allowText = formatMessage(single ? messages.acceptButton : messages.acceptAllButton);
    const denyText = formatMessage(single ? messages.denyButton : messages.denyAllButton);
    return (
      <DialogActions className={classes.actions}>
        <Button
          onClick={single ? handleDeny(requests[0]) : handleDenyAll(requests)}
          color="primary"
          variant='text'
        >
          {denyText}
        </Button>
        <Button
          onClick={single ? handleAccept(requests[0]) : handleAcceptAll(requests)}
          color="primary"
          variant='text'
          autoFocus
        >
          {allowText}
        </Button>
      </DialogActions>
    );
  }

  const requestCount = lockedJoinRequests.length;

  return (
    <ClosableDialog
      disableBackdropClick
      open={!minimized && requestCount > 0}
      TransitionComponent={Transition}
      onClose={handleClose}
      title={requestCount === 1 ?
        formatMessage(messages.lockedJoinRequestTitle) :
        formatMessage(messages.lockedJoinRequestsTitle)}
    >
      {requestCount === 1 ?
        <SingleRequestContent request={lockedJoinRequests[0]} /> :
        <MultipleRequestsContent requests={lockedJoinRequests} />
      }
      <Actions requests={lockedJoinRequests} />
    </ClosableDialog>
  );
}

function mapStateToProps(state: State) {
  return {
    lockedJoinRequests: state.notifications.lockedJoinRequests,
    minimized: state.notifications.lockedJoinRequestDialogMinimized,
  };
}

type MappedProps = {
  lockedJoinRequests: State['notifications']['lockedJoinRequests'];
  minimized: State['notifications']['lockedJoinRequestDialogMinimized'];
}


type Props = {
};


type ExtendedProps = Props & MappedProps;


export default connect(mapStateToProps)(LockedJoinRequestDialog);
