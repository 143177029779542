import { isIOS, isSafari } from "react-device-detect";

import { isValidBase64ImageUri } from "./base64";


export enum BlurValueKind {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export enum BlurTypeDefaultValue {
  NONE = 'none',
  SOFT = 'soft',
  HARD = 'hard',
  IMAGE = 'background',
}

export enum BlurTypeCustomValue {
  CUSTOM_IMAGE_1 = 'customBackground1',
  CUSTOM_IMAGE_2 = 'customBackground2',
  CUSTOM_IMAGE_3 = 'customBackground3',
}

export enum BlurValidIntValue {
  NONE = 0,
  SOFT = 10,
  HARD = 25,
}

export enum BackgroundValidImageValue {
  FLOWERS = '/assets/background/flowers.jpg',
  KITCHEN = '/assets/background/kitchen.jpg',
  LEAVES = '/assets/background/leaves.jpg',
  MOUNTAIN = '/assets/background/mountain.jpg',
  OFFICE = '/assets/background/office.jpg',
  SKYLINE = '/assets/background/skyline.jpg',
  SUNRISE = '/assets/background/sunrise.jpg',
  THAILAND = '/assets/background/thailand.jpg',
  UMBRELLA = '/assets/background/umbrella.jpg',
}

export type BlurDefaultValue = {
  kind: BlurValueKind.DEFAULT;
  label: { id: string };
  value: string | number;
  image: string;
  type: 'icon' | 'image';
  typeValue: BlurTypeDefaultValue;
}

export type BlurCustomValue = {
  kind: BlurValueKind.CUSTOM;
  label: { id: string };
  value: string;
  image: string;
  type: 'icon' | 'image';
  typeValue: BlurTypeCustomValue;
}

export type BlurValue = BlurDefaultValue | BlurCustomValue;

export const blurValues: Array<BlurValue> = [
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurNone' },
    value: BlurValidIntValue.NONE,
    image: 'blur-none',
    type: 'icon',
    typeValue: BlurTypeDefaultValue.NONE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurSoft' },
    value: BlurValidIntValue.SOFT,
    image: "blur-soft",
    type: 'icon',
    typeValue: BlurTypeDefaultValue.SOFT
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurHard' },
    value: BlurValidIntValue.HARD,
    image: "blur-hard",
    type: 'icon',
    typeValue: BlurTypeDefaultValue.HARD
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurFlowers' },
    value: BackgroundValidImageValue.FLOWERS,
    image: BackgroundValidImageValue.FLOWERS,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurKitchen' },
    value: BackgroundValidImageValue.KITCHEN,
    image: BackgroundValidImageValue.KITCHEN,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurLeaves' },
    value: BackgroundValidImageValue.LEAVES,
    image: BackgroundValidImageValue.LEAVES,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurMountain' },
    value: BackgroundValidImageValue.MOUNTAIN,
    image: BackgroundValidImageValue.MOUNTAIN,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurOffice' },
    value: BackgroundValidImageValue.OFFICE,
    image: BackgroundValidImageValue.OFFICE,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurSkyline' },
    value: BackgroundValidImageValue.SKYLINE,
    image: BackgroundValidImageValue.SKYLINE,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurSunrise' },
    value: BackgroundValidImageValue.SUNRISE,
    image: BackgroundValidImageValue.SUNRISE,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurThailand' },
    value: '/assets/background/thailand.jpg',
    image: '/assets/background/thailand.jpg',
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.DEFAULT,
    label: { id: 'backgroundBlurUmbrella' },
    value: BackgroundValidImageValue.UMBRELLA,
    image: BackgroundValidImageValue.UMBRELLA,
    type: 'image',
    typeValue: BlurTypeDefaultValue.IMAGE
  },
  {
    kind: BlurValueKind.CUSTOM,
    label: { id: 'backgroundBlurCustom' },
    value: BlurTypeCustomValue.CUSTOM_IMAGE_1,
    image: "blur-add",
    type: 'icon',
    typeValue: BlurTypeCustomValue.CUSTOM_IMAGE_1
  },
  {
    kind: BlurValueKind.CUSTOM,
    label: { id: 'backgroundBlurCustom' },
    value: BlurTypeCustomValue.CUSTOM_IMAGE_2,
    image: "blur-add",
    type: 'icon',
    typeValue: BlurTypeCustomValue.CUSTOM_IMAGE_2
  },
  {
    kind: BlurValueKind.CUSTOM,
    label: { id: 'backgroundBlurCustom' },
    value: BlurTypeCustomValue.CUSTOM_IMAGE_3,
    image: "blur-add",
    type: 'icon',
    typeValue: BlurTypeCustomValue.CUSTOM_IMAGE_3
  },
];


export const BLUR_DISABLE: BlurValue = blurValues[0];
export const BLUR_DEFAULT: BlurValue = blurValues[1];

export function findBlurValue(val: BlurValue['value'] | undefined): BlurValue['value'] {
  if (val !== undefined && isValidBase64ImageUri(val.toString())) return val;
  const found = blurValues.find(v => v.value === val);
  if (found) return found.value;
  else return BLUR_DEFAULT.value;
}

export function findBlurTypeValue(val: BlurValue['value'] | undefined): string {
  const found = blurValues.find(v => v.value === val);
  if (found) return found.typeValue;
  else return BlurTypeDefaultValue.SOFT;
}

export function isBlurAvailable(): boolean {
  return !isIOS;
}

export function flipTextureOnSafari(): boolean {
  return isSafari;
}
