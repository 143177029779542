import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { InputLabel, Select, MenuItem } from '@material-ui/core';

import { MeetingType, meetingTypeValues } from '../../lib/api/relay/utils/types';
import { State } from '../../lib/reducers';


const messages = defineMessages({
  label: { id: 'typeLabel' },
});


function MeetingTypeSelector(props: ExtendedProps) {
  const { defaultMeetingType, isAudioOnly, setMeetingType, webinarEnabled } = props;
  const { formatMessage } = useIntl();

  const [ value, setValue ] = React.useState<MeetingType>(defaultMeetingType);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const type: MeetingType = event.target.value as MeetingType;
    setValue(type);
    setMeetingType(type);
  };

  return (
    <React.Fragment>
      <InputLabel>{formatMessage(messages.label)}</InputLabel>
      <Select value={value} onChange={handleChange}>
        <MenuItem value={meetingTypeValues.regular.value} disabled={isAudioOnly}>
          {formatMessage(meetingTypeValues.regular.label)}
        </MenuItem>
        <MenuItem value={meetingTypeValues.audioOnly.value}>
          {formatMessage(meetingTypeValues.audioOnly.label)}
        </MenuItem>
        <MenuItem value={meetingTypeValues.lesson.value} disabled={isAudioOnly}>
          {formatMessage(meetingTypeValues.lesson.label)}
        </MenuItem>
        <MenuItem value={meetingTypeValues.webinar.value} disabled={!webinarEnabled}>
          {formatMessage(meetingTypeValues.webinar.label)}
        </MenuItem>
      </Select>
    </React.Fragment>
  );
}


type Props = {
  defaultMeetingType: MeetingType;
  setMeetingType: (type: MeetingType) => void;
}

type MappedProps = {
  isAudioOnly: boolean;
  webinarEnabled: boolean;
}

const mapStateToProps = (state: State): MappedProps => ({
  isAudioOnly: state.session.isAudioOnly,
  webinarEnabled: state.session.webinarEnabled,
});

type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(MeetingTypeSelector);
