export enum Route {
  Home = '/',
  Login = '/login',
  Logout = '/logout',
  About = '/about',
  Meeting = '/meeting/:slug',
  RecordedMeeting = '/recorded_meeting/:slug',
}


interface RouteParam {
  [key: string]: string;
}


export default function getRouteFor(route: Route, params: RouteParam = {}, query = '', base = ''): string {
  const url = _substituteParams(route, params);
  return _joinBase(base, url, query);
}


export function getRoomNameFromParams({ id }: { id: string }) {
  return decodeURIComponent(id);
}


function _joinBase(base: string, url: string, query: string): string {
  if (!url) {
    return base;
  }
  if (base && base.endsWith('/')) {
    base = base.slice(0, -1);
  }
  if (!base) {
    base = '';
  }
  if (!query) {
    return `${ base }${ url }`;
  }
  else if (query.startsWith('?')) {
    return `${ base }${ url }${ query }`;
  }
  else {
    return `${ base }${ url }?${ query }`;
  }
}


function _substituteParams(url: string, params: RouteParam = {}): string {
  return url.replace(/:([a-z]+)/g, (_, capture) => {
    const subst = params[capture];
    if (!subst && subst !== '') {
      return `:${ capture }`;
    }
    if (subst.match(/:[a-z]+/i)) {
      // do not encode react router params (starting with a :)
      return subst;
    }
    else {
      // a plain url component, encode it if needed
      return encodeURIComponent(subst);
    }
  });
}
