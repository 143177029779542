import React from 'react';
import { isMobileOnly, withOrientationChange } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { iconColors } from '../../colors';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { getIsAuthenticated } from '../../lib/reduxSelectors/auth';
import { isAudioOnlyLayout, isThereAnyVideo } from '../../lib/reduxSelectors/room';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { inMeeting, inWaitingRoom } from '../../lib/reduxSelectors/videoRoom';
import getRouteFor, { Route } from '../../lib/utils/router';
import { OrientationProps } from '../../lib/utils/types';
import LocalStorage from '../../localStorage';
import AvatarNew from '../AvatarNew';
import { IconLogout, IconChevronDown } from '../IconSet';
import Logout from '../Logout';
import MyRecordings from '../MyRecordings';
import ShortcutDialog from '../ShortcutDialog';
import SiteTitle from '../SiteTitle';
import StyledMenu from '../StyledMenu';
import UserLiveStreaming from '../UserLiveStreaming';
import UserOther from '../UserOther';
import UserPins from '../UserPins';
import UserProfile from '../UserProfile';

import Actions from './Actions';
import ParticipantsCount from './ParticipantsCount';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.modal - 1,
    },
    rootLandscape: {
      // zIndex is videotoolbar topbar's zIndex - 1
      zIndex: theme.zIndex.modal - 3,
    },
    rootLandscapeAudioOnly: {
      display: 'flex',
      justifyContent: 'center'
    },
    rootLandscapeVideo: {
      padding: theme.spacing(1),
      // Video Width in Landscape Mode
      width: '55%'
    },
    showAppbarAudioOnly: {
      padding: 0,
    },
    showAppbarVideo: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      background: iconColors.contrast
    },
    partCount: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: theme.palette.background.paper,
    },
    accountButton: {
      '&:hover': {
        backgroundColor: "transparent",
      }
    },
    menu: {
      padding: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    item: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    }
  }),
);


const messages = defineMessages({
  logout: { id: 'logout' },
  userInfo: { id: 'userInformation' },
  pins: { id: 'userPins' },
  other: { id: 'userOther' },
  streamingSettings: { id: 'streamingSettings' },
  recordingsList: { id: 'reportRecording' },
  holocomAccount: { id: 'holocomAccount' },
  manualLabel: { id: 'manualUrlLabel' },
  shortcutsLabel: { id: 'shortcutsLabel' },
});


type MappedProps = {
  isAuthenticated: boolean;
  displayName: string;
  isRecorder: boolean;
  isLivestreamingEnabled: boolean;
  isRecordingEnabled: boolean;
  siteTitle: string;
  loginEnabled: boolean;
  inMeeting: boolean;
  inWaitingRoom: boolean;
  isAudioOnlyLayout: boolean;
  isThereAnyVideo: boolean;
  manualUrl: string | undefined;
}


type Props = {
  localStore: LocalStorage;
  url?: string;
}

type ExtendedProps = Props & MappedProps & RouteComponentProps & OrientationProps;


const mapStateToProps = (state: State, { url }: Props): MappedProps => ({
  isAuthenticated: getIsAuthenticated(state.auth),
  displayName: state.session.displayName || "",
  isRecorder: isRecorder(state, url),
  isLivestreamingEnabled: state.session.livestreamingEnabled || false,
  isRecordingEnabled: state.session.recordingEnabled || false,
  siteTitle: state.appconfig.site_title,
  loginEnabled: state.appconfig.login_enabled,
  inMeeting: inMeeting(state),
  inWaitingRoom: inWaitingRoom(state),
  isAudioOnlyLayout: isAudioOnlyLayout(state),
  isThereAnyVideo: isThereAnyVideo(state),
  manualUrl: state.appconfig.urls ? state.appconfig.urls.manual : undefined
});


function Appbar(props: ExtendedProps) {
  const classes = useStyles();
  const {
    isAuthenticated,
    inMeeting,
    inWaitingRoom,
    isLandscape,
    isAudioOnlyLayout,
    isThereAnyVideo
  } = props;

  const shouldAppbarBeHidden = isMobileOnly && isLandscape && (inMeeting || inWaitingRoom);
  const [appbarHidden, setAppbarHidden] = React.useState(shouldAppbarBeHidden);

  // Show/Hide appbar statically on componente mount and on mobile device rotation
  React.useEffect(() => {
    setAppbarHidden(shouldAppbarBeHidden);
  }, [shouldAppbarBeHidden]);

  // Hide appbar after timeout when shown and shouldAppbarBeHidden
  React.useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;
    if (!appbarHidden && shouldAppbarBeHidden) {
      timerId = setTimeout(() => setAppbarHidden(true), 5000);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [appbarHidden, shouldAppbarBeHidden]);

  const onAppbarShowClick = React.useCallback(
    () => {
      setAppbarHidden(false);
      logEvent(Event.MOBILE_SHOW_APPBAR);
    }, [setAppbarHidden]
  );

  const homePath = getRouteFor(Route.Home);

  if (props.location.pathname === homePath && !isAuthenticated) {
    return null;
  }

  if (props.isRecorder) return null;

  const getShowAppbar = () => {
    const isAudioOnly = isAudioOnlyLayout || !isThereAnyVideo;
    const rootCls = classNames(
      classes.rootLandscape,
      isAudioOnly ? classes.rootLandscapeAudioOnly : classes.rootLandscapeVideo
    );
    const buttonCls = isAudioOnly ? classes.showAppbarAudioOnly : classes.showAppbarVideo;
    return (
      <div className={rootCls}>
        <IconButton className={buttonCls} onClick={onAppbarShowClick} size='small'>
          <IconChevronDown color={iconColors.normal} size={18} />
        </IconButton>
      </div>
    );
  };

  const getAppbar = () => {
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <SiteTitle />

            <div className={classes.partCount}>
              <ParticipantsCount />
            </div>
            <Actions />
            <AccountMenu {...props} />
          </Toolbar>
        </AppBar>
      </div>
    );
  };

  return (
    <React.Fragment>
      { appbarHidden
        ? getShowAppbar()
        : getAppbar()
      }
    </React.Fragment>
  );
}


function AccountMenu(props: ExtendedProps) {
  const siteTitle = props.siteTitle;
  const classes = useStyles();
  const { isLivestreamingEnabled, isRecordingEnabled, loginEnabled, manualUrl } = props;
  const { formatMessage } = useIntl();

  const { isAuthenticated } = props;

  const [logoutOpen, setLogoutOpen] = React.useState<boolean>(false);
  const [userProfileOpen, setUserProfileOpen] = React.useState<boolean>(false);
  const [myRecordingsOpen, setMyRecordingsOpen] = React.useState<boolean>(false);
  const [userPinsOpen, setUserPinsOpen] = React.useState<boolean>(false);
  const [userOtherOpen, setUserOtherOpen] = React.useState<boolean>(false);
  const [liveStreamingSettingsOpen, setLiveStreamingSettingsOpen] = React.useState<boolean>(false);
  const [shortcutInfoOpen, setShortcutInfoOpenOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => setAnchorEl(null);

  const logout = (): void => {
    setLogoutOpen(true);
    closeMenu();
  };

  const logoutClose = (): void => {
    setLogoutOpen(false);
    closeMenu();
  };

  const showUserInfo = (): void => {
    setUserProfileOpen(true);
    closeMenu();
    logEvent(Event.USER_INFO);
  };

  const closeUserInfo = (): void => {
    setUserProfileOpen(false);
    closeMenu();
  };

  const showUserPins = (): void => {
    setUserPinsOpen(true);
    closeMenu();
    logEvent(Event.MY_PINS);
  };

  const closeUserPins = (): void => {
    setUserPinsOpen(false);
    closeMenu();
  };

  const showUserOther = (): void => {
    setUserOtherOpen(true);
    closeMenu();
    logEvent(Event.OTHER_SETTINGS);
  };

  const closeUserOther = (): void => {
    setUserOtherOpen(false);
    closeMenu();
  };

  const showLiveStreamingSettings = (): void => {
    setLiveStreamingSettingsOpen(true);
    closeMenu();
    logEvent(Event.MY_LIVESTREAMING_SETTINGS);
  };

  const closeLiveStreamingSettings = (): void => {
    setLiveStreamingSettingsOpen(false);
    closeMenu();
  };

  const showMyRecordings = (): void => {
    setMyRecordingsOpen(true);
    closeMenu();
    logEvent(Event.MY_RECORDINGS);
  };

  const closeMyRecordings = (): void => {
    setMyRecordingsOpen(false);
    closeMenu();
  };

  const onManualClick = React.useCallback(
    () => {
      window.open(manualUrl, '_blank');
      logEvent(Event.SUPPORT_LINK, { 'support_type': 'manual', 'from': 'user_menu'  });
      closeMenu();
    }, [manualUrl]
  );

  const showShortcutInfo = React.useCallback(
    () => {
      setShortcutInfoOpenOpen(true);
      closeMenu();
      logEvent(Event.SHORTCUT_INFO);
    }, []
  );

  const closeShortcutInfo = React.useCallback(
    () => {
      setShortcutInfoOpenOpen(false);
      closeMenu();
    }, []
  );

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <Tooltip
        title={
          <React.Fragment>
            <Typography variant="body2">{formatMessage(messages.holocomAccount, { site: siteTitle })}</Typography>
            <Typography variant="caption">{props.displayName}</Typography>
          </React.Fragment>
        }
      >
        <IconButton onClick={handleMenu} className={classes.accountButton}>
          <AvatarNew name={props.displayName} />
        </IconButton>
      </Tooltip>
      <StyledMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeMenu}
      >
        <div className={classes.menu}>
          <MenuItem className={classes.item} onClick={showUserInfo}>
            {formatMessage(messages.userInfo)}
          </MenuItem>
          <MenuItem className={classes.item} onClick={showUserPins}>
            {formatMessage(messages.pins)}
          </MenuItem>
          {isLivestreamingEnabled &&
            <MenuItem className={classes.item} onClick={showLiveStreamingSettings}>
              {formatMessage(messages.streamingSettings)}
            </MenuItem>
          }
          {isRecordingEnabled &&
            <MenuItem className={classes.item} onClick={showMyRecordings}>
              {formatMessage(messages.recordingsList)}
            </MenuItem>
          }
          <Divider className={classes.divider} />
          <MenuItem className={classes.item} onClick={showUserOther}>
            {formatMessage(messages.other)}
          </MenuItem>
          <Divider className={classes.divider} />
          {manualUrl &&
            <MenuItem className={classes.item} onClick={onManualClick}>
              {formatMessage(messages.manualLabel)}
            </MenuItem>
          }
          <MenuItem className={classes.item} onClick={showShortcutInfo}>
            {formatMessage(messages.shortcutsLabel)}
          </MenuItem>
          {loginEnabled &&
            <React.Fragment>
              <Divider className={classes.divider} />
              <MenuItem className={classes.item} onClick={logout}>
                <ListItemIcon>
                  <IconLogout size={24} />
                </ListItemIcon>
                <ListItemText primary={formatMessage(messages.logout)} />
              </MenuItem>
            </React.Fragment>
          }
        </div>
      </StyledMenu>
      {logoutOpen &&
        <Logout
          localStore={props.localStore}
          onLogoutClick={logout}
          onCancelClick={logoutClose}
        />}
      {myRecordingsOpen && <MyRecordings onCloseClick={closeMyRecordings} />}
      {userProfileOpen && <UserProfile onClose={closeUserInfo} />}
      {userPinsOpen && <UserPins onClose={closeUserPins} />}
      {liveStreamingSettingsOpen && <UserLiveStreaming onClose={closeLiveStreamingSettings} />}
      {userOtherOpen && <UserOther onClose={closeUserOther} />}
      {shortcutInfoOpen && <ShortcutDialog onClose={closeShortcutInfo} />}
    </div>
  );
}


export default withOrientationChange(withRouter(connect(mapStateToProps)(Appbar)));
