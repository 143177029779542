import { commitMutation } from 'react-relay';

import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';


const mutation = graphql`
mutation deleteSubscription($input: DeleteSubscriptionInput!){
  deleteSubscription(input: $input){
    subscription{
      subId
    },
    errors {
      key
      message
      reason
    }
  }
}
`;

function deleteSubscription(environment: IEnvironment, subId: string) {
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        subscription: {
          subId
        }
      }
    };
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) {
            return reject(errors);
          }
          return resolve(response);
        },
        onError: err => {
          reject(err);
        }
      },
    );
  });
}

export { deleteSubscription };
