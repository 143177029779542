import * as Tone from 'tone';

import lockedJoinRequest from '../../../assets/locked_request.mp3';


/**
 * Plays the sound specified in the tone param; 
 * to play the sound the user must have made at least one interaction with the page
 * 
 * @param tone a string representing the path to the sound, should be one of the #tones
 */
function play(tone: string) {
  const player = new Tone.Player(tone).toDestination();
  Tone.loaded().then(() => {
    player.start();
  });
}


/**
 * object containing all the possible sounds
 */
export const tones = {
  lockedJoinRequest: lockedJoinRequest
};


export default play;
