import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    link: {
      textDecoration: 'none',
    },
  }),
);


export default useStyles;
