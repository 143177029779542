import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


export const controlButtonSize = 36;
const controlButtonSizeMini = 24;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlButton: {
      backgroundColor: theme.palette.background.default,
      width: controlButtonSize,
      height: controlButtonSize,
      '&:hover': {
        background: theme.palette.grey.A100,
      }
    },
    controlButtonMini: {
      width: controlButtonSizeMini,
      height: controlButtonSizeMini,
    },
    controlButtonHidden: {
      visibility: "hidden",
    },
  }),
);


export default useStyles;
