import { relayRequest, relayDone } from '../actions/relay';
import { getLogger } from '../logger';


const SCHEDULE_MEETING = 'SCHEDULE_MEETING';
const DELETE_MEETING = 'DELETE_MEETING';

function relayOperation(dispatch, callback, reqId, operation) {
  const logger = getLogger("Relay api");
  if (!reqId) {
    logger.warning("no request ID set");
  }
  dispatch(relayRequest(reqId, operation));
  callback()
    .then(() => dispatch(relayDone(reqId, operation, null)))
    .catch((e) => {
      dispatch(relayDone(reqId, operation, e));
      throw (e);
    });
}

export { relayOperation, SCHEDULE_MEETING, DELETE_MEETING };
