import React from 'react';
import { connect } from 'react-redux';

import { State as RoomState } from '../../lib/reducers/room';
import { IconStreamingActive } from '../IconSet';


type MappedProps = {
  isStreaming: boolean;
}

type State = {
  room: RoomState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isStreaming: state.room.isStreaming,
});

type Props = {
} & MappedProps


function StreamingOnIcon(props: Props) {

  return (
    <React.Fragment>
      {props.isStreaming ?
        <IconStreamingActive size={24} /> :
        null
      }
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(StreamingOnIcon);
