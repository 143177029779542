/* tslint:disable */
/* eslint-disable */
/* @relayHash c27e2588f828922c077eb2ead65f521e */

import { ConcreteRequest } from "relay-runtime";
export type myProfileQueryVariables = {};
export type myProfileQueryResponse = {
    readonly loggedUser: {
        readonly profile: {
            readonly email: string | null;
            readonly middlename: string | null;
            readonly name: string | null;
            readonly username: string | null;
            readonly surname: string | null;
            readonly meta: {
                readonly livestreaming: {
                    readonly apiKey: string | null;
                    readonly apiUrl: string | null;
                    readonly publishUrl: string | null;
                } | null;
            } | null;
        } | null;
    };
};
export type myProfileQuery = {
    readonly response: myProfileQueryResponse;
    readonly variables: myProfileQueryVariables;
};



/*
query myProfileQuery {
  loggedUser {
    profile {
      email
      middlename
      name
      username
      surname
      meta {
        livestreaming {
          apiKey
          apiUrl
          publishUrl
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "loggedUser",
    "storageKey": null,
    "args": null,
    "concreteType": "LoggedUserData",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "profile",
        "storageKey": null,
        "args": null,
        "concreteType": "UserProfile",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "middlename",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "surname",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "meta",
            "storageKey": null,
            "args": null,
            "concreteType": "UserMeta",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "livestreaming",
                "storageKey": null,
                "args": null,
                "concreteType": "LivestreamingConfig",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "apiKey",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "apiUrl",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "publishUrl",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "myProfileQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "myProfileQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "myProfileQuery",
    "id": null,
    "text": "query myProfileQuery {\n  loggedUser {\n    profile {\n      email\n      middlename\n      name\n      username\n      surname\n      meta {\n        livestreaming {\n          apiKey\n          apiUrl\n          publishUrl\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '839408a2bf82895c860883258cc65b3d';
export default node;
