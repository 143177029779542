import { StreamAdapter } from "../../../../streamAdapters";

import { AbstractStreamTransformation } from "./AbstractStreamTransformation";
import { WebGLBilateral } from './webglUtils';


export class BilateralStreamTransformation extends AbstractStreamTransformation {
  private webglBilateral?: WebGLBilateral;

  public async setup(source: StreamAdapter, sink: StreamAdapter): Promise<void> {
    const shape = await source.getShape();
    this.webglBilateral = new WebGLBilateral(shape);
    this.setStreams(source, sink);
    await this.setupPromise;
  }

  public async render(): Promise<void> {
    await this.setupPromise;
    if (!this.source || !this.webglBilateral || !this.sink) return;
    const frame = await this.source.getFrame();
    const bilateral = await this.webglBilateral.bilateral(frame, this.flipTexture);
    this.sink.get2DContext().drawImage(bilateral, 0, 0);
  }

  public async stop(): Promise<void> {
    await this.setupPromise;
    if (this.webglBilateral) this.webglBilateral.dispose();
    this.stopStreams();
  }
}
