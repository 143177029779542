import { constructPipeStreamAdapter, constructImageStreamAdapter, StreamAdapter } from "../../../../streamAdapters";

import { AbstractStreamTransformation } from "./AbstractStreamTransformation";
import { WebGLBlur, WebGLLanczos } from './webglUtils';


export class MaskingStreamTransformation extends AbstractStreamTransformation {
  private shape?: [number, number];
  private webglBlur?: WebGLBlur;
  private webglLanczos?: WebGLLanczos;
  private blurSink?: StreamAdapter;
  private lanczosSink?: StreamAdapter;
  private imageSink?: StreamAdapter;
  private context?: CanvasRenderingContext2D;
  private backgroundBlurValue: string | number;

  constructor(backgroundBlurValue: string | number) {
    super();
    this.backgroundBlurValue = backgroundBlurValue;
  }

  private async setBackground() {
    if (typeof this.backgroundBlurValue === 'number') return;
    const img = constructImageStreamAdapter(String(this.backgroundBlurValue));
    const frame = await img.getFrame();
    if (!this.imageSink || !this.shape) return;
    const context = this.imageSink.get2DContext();
    const srcShape = this.shape;
    const bgShape = await img.getShape();
    const ratio  = Math.max ( srcShape[0]  / bgShape[0], srcShape[1]  / bgShape[1] );
    const wSize = bgShape[0] * ratio;
    const hSize = bgShape[1] * ratio;
    const wShift = ( srcShape[0] - wSize ) / 2;
    const hShift = ( srcShape[1] - hSize ) / 2;
    context.fillRect( 0, 0, ...srcShape);
    context.drawImage(frame, 0, 0, bgShape[0], bgShape[1], wShift, hShift, wSize, hSize);
    if (!this.webglLanczos) return;
    const imFrame = await this.imageSink.getFrame();
    const lancFrame = await this.webglLanczos.lanczos(imFrame, 0.5);
    context.drawImage(lancFrame, 0, 0);
  }

  public async setup(source: StreamAdapter, sink: StreamAdapter): Promise<void> {
    this.shape = await source.getShape();
    this.imageSink = constructPipeStreamAdapter();
    this.imageSink.setShape(this.shape);
    this.context = sink.get2DContext();
    this.webglBlur = new WebGLBlur(this.shape);
    this.webglLanczos = new WebGLLanczos(this.shape);
    await this.setBackground();
    this.setStreams(source, sink);
  }

  public async render(): Promise<void> {
    await this.setupPromise;
    if (!this.context) return;
    if (typeof this.backgroundBlurValue === 'number') {
      if (!this.webglBlur || !this.source) return;
      const frame = await this.source.getFrame();
      const blur = await this.webglBlur.blur(frame, this.backgroundBlurValue);
      this.context.drawImage(blur, 0, 0);
    } else {
      if (!this.imageSink) return;
      const image = await this.imageSink.getFrame();
      this.context.drawImage(image, 0, 0);
    }
  }

  public async stop(): Promise<void> {
    await this.setupPromise;
    if (this.webglLanczos) this.webglLanczos.dispose();
    if (this.webglBlur) this.webglBlur.dispose();
    this.stopStreams();
  }
}
