/* tslint:disable */
/* eslint-disable */
/* @relayHash 9a009e6074e5f76b9e3c731932ceed90 */

import { ConcreteRequest } from "relay-runtime";
export type MeetingPrivacy = "LOCKED" | "LOCKED_LOGGED" | "LOGGED" | "OPEN" | "%future added value";
export type MeetingType = "AUDIO_ONLY" | "LESSON" | "REGULAR" | "WEBINAR" | "%future added value";
export type ScheduleMeetingInput = {
    meeting?: MeetingInput | null;
};
export type MeetingInput = {
    attendees?: Array<AttendeeInput | null> | null;
    doNotify?: boolean | null;
    domainId: string;
    dtEnd: string;
    dtStart: string;
    meta?: MeetingMetaInput | null;
    notes?: string | null;
    title: string;
    type?: MeetingType | null;
    userId: string;
};
export type AttendeeInput = {
    email: string;
};
export type MeetingMetaInput = {
    privacy?: MeetingPrivacy | null;
};
export type scheduleMeetingMutationVariables = {
    input: ScheduleMeetingInput;
};
export type scheduleMeetingMutationResponse = {
    readonly scheduleMeeting: {
        readonly errors: ReadonlyArray<{
            readonly key: string;
            readonly message: ReadonlyArray<string | null> | null;
            readonly reason: ReadonlyArray<string | null> | null;
        } | null> | null;
        readonly meeting: {
            readonly id: string;
            readonly userId: string;
            readonly domainId: string;
            readonly title: string;
            readonly url: string | null;
            readonly slug: string;
            readonly number: string;
        } | null;
    } | null;
};
export type scheduleMeetingMutation = {
    readonly response: scheduleMeetingMutationResponse;
    readonly variables: scheduleMeetingMutationVariables;
};



/*
mutation scheduleMeetingMutation(
  $input: ScheduleMeetingInput!
) {
  scheduleMeeting(input: $input) {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title
      url
      slug
      number
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ScheduleMeetingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "scheduleMeeting",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScheduleMeetingPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "InputError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reason",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "meeting",
        "storageKey": null,
        "args": null,
        "concreteType": "Meeting",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "domainId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "url",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "number",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "scheduleMeetingMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "scheduleMeetingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "scheduleMeetingMutation",
    "id": null,
    "text": "mutation scheduleMeetingMutation(\n  $input: ScheduleMeetingInput!\n) {\n  scheduleMeeting(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    meeting {\n      id\n      userId\n      domainId\n      title\n      url\n      slug\n      number\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd43b4bf15abe6549000834392c351dd7';
export default node;
