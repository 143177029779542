import { isMobileOnly as isMobile } from 'react-device-detect';

import { State } from '../reducers';
import { getIsAuthenticated, getIsAuthenticatedAsGuest } from '../reduxSelectors/auth';


function showGuestLogin(state: State): boolean {
  return !getIsAuthenticated(state.auth) && !getIsAuthenticatedAsGuest(state.auth);
}

export function showMobileGuestLogin(state: State): boolean {
  return isMobile && showGuestLogin(state);
}
