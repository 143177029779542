import React from 'react';

import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import style from './style';


const Spinner = (props) => {
  const { classes } = props;
  return (
    <div className={classes.overlay}>
      <CircularProgress className={classes.progress} size={100} />
    </div>
  );
};

Spinner.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Spinner);
