import { useSelector } from 'react-redux';

import { State } from '../../lib/reducers';
import { createDeepEqualitySelector } from '../../lib/reduxSelectors';
import { usePrevious } from '../../lib/utils/hooks';


type Publishers = {
  [k: string]: {
    streamPublished: boolean;
    screenPublished: boolean;
  };
}


const _getPublishers = ({ room: { roster } }: State) => {
  const publishers: Publishers = {};
  Object.keys(roster).forEach(
    k => {
      const u = roster[k];
      if (u) {
        const newPublisher = {
          streamPublished: Boolean(
            u.streamPublisherData
            && !u.streamPublisherData.subscribed
            && !u.streamPublisherData.subscribeRequested
            && !u.streamPublisherData.subscribeError
          ),
          screenPublished: Boolean(
            u.screenPublisherData
            && !u.screenPublisherData.subscribed
            && !u.screenPublisherData.subscribeRequested
            && !u.screenPublisherData.subscribeError
          ),
        };
        if (newPublisher.streamPublished || newPublisher.screenPublished) {
          publishers[k] = newPublisher;
        }
      }
    }
  );
  return publishers;
};


const getPublishers = createDeepEqualitySelector(
  [_getPublishers],
  (publishers: Publishers) => publishers
);


type Publisher = {
  uid: string;
  stream: boolean;
  screen: boolean;
}


export default function usePublishersWatcher() {
  const publishers = useSelector(getPublishers);
  const previousPublishers = usePrevious(publishers, {} as Publishers);

  const newPublishers: Publisher[] = [];

  Object.keys(publishers).forEach((uid) => {
    const userNewStreams = publishers[uid];
    const userPrevStreams = previousPublishers[uid];
    if (userPrevStreams) {
      const newPub: Publisher = { uid, stream: false, screen: false };
      if (userNewStreams.streamPublished && !userPrevStreams.streamPublished) {
        // they published a video
        newPub.stream = true;
      }
      if (userNewStreams.screenPublished && !userPrevStreams.screenPublished) {
        // they published a screen
        newPub.screen = true;
      }

      if (newPub.stream || newPub.screen) {
        newPublishers.push(newPub);
      }
    }
    else {
      const newPub: Publisher = {
        uid,
        stream: userNewStreams.streamPublished,
        screen: userNewStreams.screenPublished
      };
      newPublishers.push(newPub);
    }
  });

  return newPublishers;
}
