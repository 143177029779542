import React from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

import { republishStream } from '../../lib/actions/room';
import { saveVideoFacingMode } from '../../lib/actions/settings';
import { logEvent, Event } from '../../lib/analytics';
import { VideoRoom } from '../../lib/api/videoroom';
import { getLogger } from '../../lib/logger';
import { State } from '../../lib/reducers';
import { FacingMode, StreamOptions } from '../../lib/redux_types';
import LocalStorage from '../../localStorage';
import { IconSwitchCamera } from '../IconSet';

import ToolbarButton from './ToolbarButton';

import useStyles from './buttonsStyle';


type Props = {} & MappedProps


function SwitchCamera(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getNewFacingMode = (facing?: FacingMode): FacingMode => {
    switch (facing) {
      case 'user':
        return 'environment';
      case 'environment':
        return 'user';
      case undefined:
        return 'user';
    }
  };

  const handleCameraSwitch = () => {
    const newFacingMode: FacingMode = getNewFacingMode(props.facingMode);
    const streamOptions: StreamOptions = {
      acquireVideo: true,
      muted: false,
      facingMode: newFacingMode
    };
    dispatch(saveVideoFacingMode(newFacingMode, new LocalStorage()));
    dispatch(republishStream(streamOptions, getLogger('Republish stream')));
    logEvent(Event.MOBILE_SWITCH_CAMERA);
  };

  if (props.hasVideoStream) {
    return (
      <ToolbarButton
        icon={<IconSwitchCamera size={28} />}
        text=''
        buttonProps={{ className: classes.buttons, onClick: handleCameraSwitch }}
      />
    );
  } else {
    return null;
  }
}


type MappedProps = {
  videoDevice: State['settings']['videoDevice'];
  hasVideoStream: boolean;
  canPublishVideo: boolean;
  facingMode: State['settings']['videoFacingMode'];
}

const mapStateToProps = (state: State): MappedProps => {
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }
  return {
    videoDevice: state.settings.videoDevice,
    hasVideoStream: hasVideoStream,
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
    facingMode: state.settings.videoFacingMode || 'user',
  };
};


export default connect(mapStateToProps)(SwitchCamera);
