/* tslint:disable */
/* eslint-disable */
/* @relayHash 777cd2506634b8cf26216c24d95e1789 */

import { ConcreteRequest } from "relay-runtime";
export type DeleteSubscriptionInput = {
    subscription?: PushSubscriptionDeleteInput | null;
};
export type PushSubscriptionDeleteInput = {
    subId: string;
};
export type deleteSubscriptionVariables = {
    input: DeleteSubscriptionInput;
};
export type deleteSubscriptionResponse = {
    readonly deleteSubscription: {
        readonly subscription: {
            readonly subId: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly key: string;
            readonly message: ReadonlyArray<string | null> | null;
            readonly reason: ReadonlyArray<string | null> | null;
        } | null> | null;
    } | null;
};
export type deleteSubscription = {
    readonly response: deleteSubscriptionResponse;
    readonly variables: deleteSubscriptionVariables;
};



/*
mutation deleteSubscription(
  $input: DeleteSubscriptionInput!
) {
  deleteSubscription(input: $input) {
    subscription {
      subId
    }
    errors {
      key
      message
      reason
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteSubscription",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSubscriptionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subscription",
        "storageKey": null,
        "args": null,
        "concreteType": "PushSubscriptionDelete",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subId",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "InputError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reason",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "deleteSubscription",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "deleteSubscription",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "deleteSubscription",
    "id": null,
    "text": "mutation deleteSubscription(\n  $input: DeleteSubscriptionInput!\n) {\n  deleteSubscription(input: $input) {\n    subscription {\n      subId\n    }\n    errors {\n      key\n      message\n      reason\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9a24134e6e4992f8a95c623d622826a6';
export default node;
