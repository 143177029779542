import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import { WithAuthorization } from '../../../authorization';
import { iconColors as colors } from '../../../colors';
import { kickUser } from '../../../lib/actions/room';
import { logEvent, Event } from '../../../lib/analytics';
import { UserActionsContext } from '../../../lib/contexts';
import { State } from '../../../lib/reducers';
import { isKickEnabled, isPipEnabled, canChangeRole, canFullscreen } from '../../../lib/reduxSelectors/user';
import { IconTrash } from '../../IconSet';


const messages = defineMessages({
  ejectMessage: { id: 'ejectIconButtonTooltip' }
});



function KickMenuItem(props: ExtendedProps) {
  const { kickEnabled, user, onClose, iconSize = 20, shouldShowDivider } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const userActionsContext = React.useContext(UserActionsContext);

  const onKickUser = React.useCallback(
    () => {
      if (user) {
        const who = user.replace(/_screen$/, '');
        dispatch(kickUser(who));
        logEvent(Event.KICK_USER, { 'from': userActionsContext });
      }
      onClose();
    }
    , [dispatch, user, onClose, userActionsContext]
  );

  if (!kickEnabled) {
    return null;
  }

  return (
    <React.Fragment>
      { shouldShowDivider && <Divider /> }
      <MenuItem onClick={onKickUser}>
        <ListItemIcon>
          <IconTrash color={colors.attention} size={iconSize} />
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.ejectMessage)} />
      </MenuItem>
    </React.Fragment>
  );

}

type Props = {
  user: string;
  iconSize?: number;
  onClose: () => void;
}

type MappedProps = {
  kickEnabled: boolean;
  shouldShowDivider: boolean;
}

type ExtendedProps = Props & MappedProps;


const mapStateToProps = (state: State, props: Props): MappedProps => {
  const userId = props.user.replace(/_screen$/, '');
  return {
    kickEnabled: isKickEnabled(userId, state),
    shouldShowDivider:
      isPipEnabled(props.user, state) || canChangeRole(userId, state) || canFullscreen(props.user, state)
  };
};

const roles = ['room_owner', 'room_moderator'];

export default WithAuthorization(roles, connect(mapStateToProps)(KickMenuItem));
