import React from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import {
  startRecording,
  stopRecording
} from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { RoomActionsContext } from '../../lib/contexts';
import { State as RoomState } from '../../lib/reducers/room';
import { IconRecordVideo, IconStop } from '../IconSet';


const messages = defineMessages({
  menuRecording: { id: 'menuRecording' },
  stopRecording: { id: 'stopRecording' },
});

type MappedProps = {
  isRecording: boolean;
  isAudioRecording: boolean;
  isRequestingRecordingOp: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    // eslint-disable-next-line @typescript-eslint/camelcase
    handleStartRecording: () => startRecording({ baseUrl: window.location.origin, type: 'video' }),
    handleStopRecording: () => stopRecording(),
  },
  dispatch
  );

type State = {
  room: RoomState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isRecording: state.room.isRecording,
  isAudioRecording: state.room.isAudioRecording,
  isRequestingRecordingOp: state.room.isRequestingRecordingOp,
});

type Props = {} & MappedProps

type PropsType = Props & { intl: IntlShape } & ReturnType<typeof mapDispatchToProps>

function getStopRecordingIcon(props: PropsType, stopRecord: () => void) {
  const l10n = props.intl.formatMessage;
  const stopRecordingIcon = (
    <React.Fragment>
      <MenuItem onClick={stopRecord} disabled={props.isRequestingRecordingOp}>
        <ListItemIcon>
          <IconStop size={32} />
        </ListItemIcon>
        <ListItemText primary={l10n(messages.stopRecording)} />
      </MenuItem>
    </React.Fragment>
  );
  return stopRecordingIcon;
}

function getStartRecordingIcon(props: PropsType, startRecord: () => void) {
  const l10n = props.intl.formatMessage;
  const startRecordingIcon = (
    <React.Fragment>
      <MenuItem onClick={startRecord} disabled={props.isRequestingRecordingOp || props.isAudioRecording}>
        <ListItemIcon>
          <IconRecordVideo size={32} />
        </ListItemIcon>
        <ListItemText primary={l10n(messages.menuRecording)} />
      </MenuItem>
    </React.Fragment>

  );
  return startRecordingIcon;
}

function RecordButton(props: PropsType) {
  const roomActionsContext = React.useContext(RoomActionsContext);

  const startRecord = (): void => {
    props.handleStartRecording();
    logEvent(Event.RECORD_VIDEO, { 'action': 'start', 'from': roomActionsContext });
  };

  const stopRecord = (): void => {
    props.handleStopRecording();
    logEvent(Event.RECORD_VIDEO, { 'action': 'stop', 'from': roomActionsContext });
  };

  return (
    <div>
      {props.isRecording ?
        getStopRecordingIcon(props, stopRecord) :
        getStartRecordingIcon(props, startRecord)
      }
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecordButton));
