import { defineMessages } from "react-intl";


const messages = defineMessages({
  ok: { id: 'ok' },
  clickHereToLogin: { id: 'clickHereToLogin' },
  clickHereToLoginToOwnRoom: { id: 'clickHereToLoginToOwnRoom' },
  login: { id: 'login' },
  errorCodeGenericTitle: { id: 'errorCodeGenericTitle' },
  tooEarlyTitle: { id: 'errorCode1002Title' },
  tooEarlyRoomNotAvailable: { id: 'theRoomIsNotAvailableYet' },
  tooEarlyMeetingIsFromTo: { id: 'theRoomIsScheduledFromTo' },
  tooEarlyMeetingIsTodayFromTo: { id: 'theRoomIsScheduledTodayFromTo' },
  tooEarlyMeetingWillBeAvailableIn: { id: 'theRoomWillBeAvailableIn' },
  tryAgain: { id: 'tryAgain' },
  error: { id: 'error' },
  networkError: { id: 'networkError' },
  publishError: { id: 'mediaPublishFailure' },
  answerError: { id: 'answerError' },
  roomErrorContent: { id: 'unexpectedRoomError' },
  rpcTimeoutErrorContent: { id: 'rpcTimeoutError' },
  deviceErrorTitle: { id: 'streamErrorTitle' },
  deviceErrorContent: { id: 'streamErrorContent' },
  errorCode: {
    1000: {
      title: { id: 'errorCode1000Title' },
      message: { id: 'errorCode1000Body' },
    },
    1001: {
      title: { id: 'errorCode1001Title' },
      message: { id: 'errorCode1001Body' },
    },
    1002: {
      title: { id: 'errorCode1002Title' },
      message: { id: 'errorCode1002Body' },
    },
    1003: {
      title: { id: 'errorCode1003Title' },
      message: { id: 'errorCode1003Body' },
    },
    1004: {
      title: { id: 'errorCode1004Title' },
      message: { id: 'errorCode1004Body' },
    },
    1005: {
      title: { id: 'errorCode1005Title' },
      message: { id: 'errorCode1005Body' },
    },
    1008: {
      title: { id: 'errorCode1008Title' },
      message: { id: 'errorCode1008Body' },
    },
    1009: {
      title: { id: 'errorCode1009Title' },
      message: { id: 'errorCode1009Body' },
    },
    1010: {
      title: { id: 'errorCode1010Title' },
      message: { id: 'errorCode1010Body' },
    },
    1011: {
      title: { id: 'errorCode1011Title' },
      message: { id: 'errorCode1011Body' },
    },
    1012: {
      title: { id: 'errorCode1012Title' },
      message: { id: 'errorCode1012Body' },
    },
    1013: {
      title: { id: 'errorCode1013Title' },
      message: { id: 'errorCode1013Body' },
    },
    1015: {
      title: { id: 'errorCode1015Title' },
      message: { id: 'errorCode1015Body' },
    },
    1016: {
      title: { id: 'errorCode1016Title' },
      message: { id: 'errorCode1016Body' },
    },
    1017: {
      title: { id: 'errorCode1017Title' },
      message: { id: 'errorCode1017Body' },
    },
    1408: {
      title: { id: 'connectionClosedUnexpectedlyTitle' },
      message: { id: 'connectionClosedUnexpectedlyMessage' },
    },
    4001: {
      title: { id: 'errorCode4001Title' },
      message: { id: 'errorCode4001Body' },
    },
    generic: {
      title: { id: 'errorCodeGenericTitle' },
      message: { id: 'errorCodeGenericBody' },
    }
  },
});


export default messages;
