import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { iconColors as colors } from '../../colors';
import { togglePrivateAudio } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { WebSocketReady } from '../../lib/reducers/websocket';
import { IconPrivateConfOff } from '../IconSet';

import messages from './buttonsMessages';
import ToolbarButton from './ToolbarButton';

import useStyles from './buttonsStyle';


type Props = {
  uid: WebSocketReady['uid'];
  privateAudioConf: string;
}


function LeavePrivateAudioButton(props: Props) {
  const {
    uid,
    privateAudioConf,
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const leavePrivateAudio = React.useCallback(
    () => {
      if (privateAudioConf && uid) {
        dispatch(togglePrivateAudio(uid, privateAudioConf));
        logEvent(Event.START_PRIVATE_CONFERENCE, { 'from': 'own_controls', 'action': 'stop' });
      }
    }
    , [dispatch, uid, privateAudioConf]
  );

  const classes = useStyles();

  return (
    <ToolbarButton
      icon={<IconPrivateConfOff size={28} color={colors.attention} />}
      text={formatMessage(messages.stopPrivateConfTooltip)}
      buttonProps={{ className: classes.buttons, onClick: leavePrivateAudio }}
    />
  );
}


export default LeavePrivateAudioButton;
