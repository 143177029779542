import React from 'react';
import { connect, useDispatch } from 'react-redux';

import detectedBrowser from '../../detectBrowser';
import { toggleFullscreen } from '../../lib/actions/room';
import { State as RoomState } from '../../lib/reducers/room';
import { WebSocketState as WebsocketState } from '../../lib/reducers/websocket';
import FS from '../Fullscreen';


function Fullscreen(props: ExtendedProps) {
  const { fullscreenUser, user } = props;
  const dispatch = useDispatch();

  const handleChange = (newStatus: boolean, user: string): void => {
    if (user !== fullscreenUser) {
      return;
    }
    if (!newStatus) {
      dispatch(toggleFullscreen(null));
    }
  };

  // track if this component is fullscreen with a ref to let the following
  // effect to be triggered only on unmount
  const amIFullScreen = React.useRef(fullscreenUser === user);

  React.useEffect(
    () => {
      amIFullScreen.current = fullscreenUser === user;
    },
    [user, fullscreenUser]
  );

  React.useEffect(
    () => {
      return () => {
        if (amIFullScreen) {
          // on unmount, dispatch null if this user is currently FS to avoid
          // not resetting the redux store
          dispatch(toggleFullscreen(null));
        }
      };
    },
    [dispatch]
  );

  const os = ((detectedBrowser || { os: '' }).os || '').toLowerCase();
  if (os === 'ios') {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>);
  }
  return (
    <FS
      fullscreen_id=''
      user={user}
      fullscreen_user={fullscreenUser}
      enabled={Boolean(fullscreenUser)}
      onChange={handleChange}>
      {props.children}
    </FS>
  );
}

type OwnProps = {
  user: string;
  children?: React.ReactNode;
}

type MappedProps = {
  fullscreenUser: string | null;
}

type State = {
  room: RoomState;
  websocket: WebsocketState;
}

type ExtendedProps = MappedProps & OwnProps;

const mapStateToProps = (state: State): MappedProps => {
  return {
    fullscreenUser: state.room.fullScreenEnabled,
  };
};

export default connect(mapStateToProps)(Fullscreen);
