import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { ExtendedMeeting } from '.';

import useStyles from './style';


const messages = defineMessages({
  roomName: { id: 'roomInfoName' },
});


type Props = {
  roomDetails: ExtendedMeeting;
}


function RoomInfoTitle(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>
        {formatMessage(messages.roomName)}
      </div>
      <div className={classes.content}>
        {props.roomDetails.title}
      </div>
    </div>
  );
}


export default RoomInfoTitle;