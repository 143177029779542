import { useEffect, useCallback, useRef } from 'react';

// React hook for delaying calls with time
// returns a callback to use for cancelling

// If the function passed in as first argument is redefined,
// the scheduled call will be automatically cancelled.
// Use useCallback to avoid unwanted cancellations.

export default function useTimeout(
  callback: () => void, // function to call. No args passed.
  timeout = 0, // delay, ms (default: immediately put into JS Event Queue)
  condition = true
) {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const cancel = useCallback(
    () => {
      const timeoutId = timeoutIdRef.current;
      if (timeoutId) {
        timeoutIdRef.current = undefined;
        clearTimeout(timeoutId);
      }
    },
    [timeoutIdRef],
  );

  useEffect(
    () => {
      if (condition) {
        timeoutIdRef.current = setTimeout(callback, timeout);
        return cancel;
      }
    },
    [callback, timeout, cancel, condition],
  );

  return cancel;
}
