import * as sa from "../../../streamAdapters";

import FrameTimer from "./FrameTimer";
import { StreamTransformation } from "./transformations";


type PipelineState = "idle" | "start" | "stop" | "end";



export default class StreamPipeline {
  private transformation?: StreamTransformation;
  private outputStream: Promise<MediaStream>;
  private resolveStream: (stream: MediaStream) => void;
  private rejectStream: (error: Error) => void
  private frameTimer: FrameTimer;
  private frameRate: number
  private pipelineState: PipelineState;

  constructor() {
    this.resolveStream = () => {throw new Error('resolveStream not set');};
    this.rejectStream = () => {throw new Error('rejectStream not set');};
    this.outputStream = new Promise((resolve, reject) => {
      this.resolveStream = resolve;
      this.rejectStream = reject;
    });
    this.pipelineState = "idle";
    this.frameRate = 30;
    this.frameTimer = new FrameTimer(this.execute.bind(this));
  }

  private checkPipelineState(...states: Array<PipelineState>) {
    if (states.every(expectedState => this.pipelineState !== expectedState)) {
      const strState = String(states);
      throw new Error(`Expect pipelineState to be ${strState} but was ${this.pipelineState}`);
    }
  }

  public async setup(getStream: () => Promise<MediaStream>, transformation?: StreamTransformation) {
    this.checkPipelineState("idle", "stop");
    try {
      const stream = await getStream();
      const hasVideo = stream.getVideoTracks().length !== 0;
      if (transformation && hasVideo) {
        const sourceStream = new MediaStream(stream.getVideoTracks());
        const source = sa.constructInputStreamAdapter(sourceStream);
        const sink = sa.constructPipeStreamAdapter();
        source.onSetShape(() => transformation.setup(source, sink));
        await transformation.setup(source, sink);
        const sinkStream = sink.getStream();
        stream.getVideoTracks().forEach(t => stream.removeTrack(t));
        sinkStream.getVideoTracks().forEach(t => stream.addTrack(t));
        this.transformation = transformation;
      }
      if (this.pipelineState === 'idle') {
        this.pipelineState = 'start';
        await this.execute();
        this.resolveStream(stream);
      }
      if (this.pipelineState === 'stop') {
        this.execute();
        this.resolveStream(stream);
      }
    } catch (error) {
      this.rejectStream(error);
      this.frameTimer.stop();
      if (this.transformation) this.transformation.stop();
      this.transformation = undefined;
      this.pipelineState = "end";
    }
  }

  private async execute(): Promise<void> {
    this.checkPipelineState("start", "stop");
    if (this.pipelineState === "stop") {
      this.frameTimer.stop();
      if (this.transformation) this.transformation.stop();
      this.transformation = undefined;
      (await this.outputStream).getVideoTracks().forEach(t => t.stop());
      this.pipelineState = "end";
    }
    if (this.pipelineState === "start") {
      if (this.transformation) await this.transformation.render();
      this.frameTimer.nextFrame(this.frameRate);
    }
  }

  public setFrameRate(frameRate: number) {
    this.frameRate = frameRate;
  }

  public start(): Promise<MediaStream> {
    this.checkPipelineState("idle", "start", "stop");
    return this.outputStream;
  }

  public stopVideo(): void {
    this.pipelineState = "stop";
  }

  public stopAudio(): void {
    this.outputStream.then(s => {
      s.getAudioTracks().forEach(t => t.stop());
    }).catch(_ => {
      // ignore errors when outputStream fails
    });
  }
}
