import {
  TOGGLE_DEVICE_SETTINGS,
  TOGGLE_BACKGROUND_SETTINGS,
  VIDEO_DEVICE_SETTINGS,
  VIDEO_FACING_MODE_SETTINGS,
  VIDEO_ENABLED,
  AUDIO_JOIN_MUTED,
  AUDIOIN_DEVICE_SETTINGS,
  AUDIOOUT_DEVICE_SETTINGS,
  BACKGROUND_BLUR_ENABLED_SETTINGS,
  BACKGROUND_BLUR_VALUE_SETTINGS,
  BACKGROUND_BLUR_CUSTOM_IMAGE,
  BEAUTIFY_EFFECT_ENABLED_SETTINGS,
  VIDEO_QUALITY_SETTINGS,
  SET_IS_ELECTRON,
  SET_MEDIA_PERMISSIONS_GRANTED
} from '../actions/settings';
import { Action } from '../actions/settings';
import { FacingMode, VideoQuality } from '../redux_types';
import { assign } from '../utils/object';
import { BlurTypeCustomValue } from '../utils/pipelineDefault';


export type State = {
  deviceSettingsDialogOpen: boolean;
  backgroundSettingsDialogOpen: boolean;
  isElectron: boolean;
  videoEnabled: boolean;
  audioJoinMuted: boolean;
  videoDevice?: MediaDeviceInfo;
  videoFacingMode?: FacingMode;
  videoQuality?: VideoQuality;
  audioInDevice?: MediaDeviceInfo;
  audioOutDevice?: MediaDeviceInfo;
  backgroundBlurEnabled?: boolean;
  backgroundBlurValue?: string | number;
  beautifyEffectEnabled?: boolean;
  [BlurTypeCustomValue.CUSTOM_IMAGE_1]?: string;
  [BlurTypeCustomValue.CUSTOM_IMAGE_2]?: string;
  [BlurTypeCustomValue.CUSTOM_IMAGE_3]?: string;
  mediaPermissionsGranted: boolean;
}

export const defaultState = {
  deviceSettingsDialogOpen: false,
  backgroundSettingsDialogOpen: false,
  isElectron: false,
  videoEnabled: true,
  audioJoinMuted: false,
  mediaPermissionsGranted: false,
};


function settings(state: State = defaultState, action: Action) {
  switch (action.type) {
    case TOGGLE_DEVICE_SETTINGS:
      return assign(state, {
        deviceSettingsDialogOpen: !state.deviceSettingsDialogOpen,
      });
    case TOGGLE_BACKGROUND_SETTINGS:
      return assign(state, {
        backgroundSettingsDialogOpen: !state.backgroundSettingsDialogOpen,
      });
    case SET_MEDIA_PERMISSIONS_GRANTED:
      return assign(state, {
        mediaPermissionsGranted: action.payload.value
      });
    case VIDEO_DEVICE_SETTINGS:
    case VIDEO_FACING_MODE_SETTINGS:
    case VIDEO_ENABLED:
    case AUDIO_JOIN_MUTED:
    case AUDIOIN_DEVICE_SETTINGS:
    case AUDIOOUT_DEVICE_SETTINGS:
    case BACKGROUND_BLUR_ENABLED_SETTINGS:
    case BACKGROUND_BLUR_VALUE_SETTINGS:
    case BACKGROUND_BLUR_CUSTOM_IMAGE:
    case BEAUTIFY_EFFECT_ENABLED_SETTINGS:
    case VIDEO_QUALITY_SETTINGS:
    case SET_IS_ELECTRON:
      return assign(state, action.payload);
    default:
      return state;
  }
}


export default settings;
