import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl, defineMessages } from 'react-intl';
import { createPaginationContainer } from 'react-relay';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';

import { iconColors as colors } from '../../colors';
import IntlPropType from '../../intl';
import { logEvent, Event } from '../../lib/analytics';
import { myRecordingsFragmentList, myRecordingsQueryForward } from '../../lib/api/relay/myRecordings';
import { newEvent, ERROR } from '../../lib/notifications';
import {
  IconFilter
} from '../IconSet';
import TooltipMultiLineText from '../TooltipMultiLineText';

import MenuFilter from './MenuFilter';
import MyRecording from './MyRecording';

import style from './style';


const messages = defineMessages({
  reportTitleRecording: { id: 'reportTitleRecording' },
  reportStartRecording: { id: 'reportStartRecording' },
  reportEndRecording: { id: 'reportEndRecording' },
  reportNoteRecording: { id: 'reportNoteRecording' },
  reportSlugRecording: { id: 'reportSlugRecording' },
  reportPlayRecording: { id: 'reportPlayRecording' },
  reportLoadRecording: { id: 'reportLoadRecording' },
  reportDurationRecording: { id: 'reportDurationRecording' },
  reportFilterApplyRecording: { id: 'reportFilterApplyRecording' },
  reportFilterAppliedRecording: { id: 'reportFilterAppliedRecording' },
  close: { id: 'close' }
});

class MyRecordingList extends Component {

  constructor(props) {
    super(props);
    this.needToScroll = true;
    this.state = {
      order: 'desc',
      orderBy: 'recStart',
      filterMenuOpen: false,
      filterGraphQl: [],
      sortingGraphQl: {
        direction: "DESC",
        sort: "recStart"
      },
      menuFilterConfig: {},
      menuFilterDateConfig: { enableTemporalRange: false }
    };
    this.sortHandler = this.sortHandler.bind(this);
    this.handleFilterMenuButton = this.handleFilterMenuButtonButton.bind(this);
    this.handleApplyFilter = this.handleApplyFilter.bind(this);
    this.handleEndDateFilter = this.handleEndDateFilter.bind(this);
    this.handleStartDateFilter = this.handleStartDateFilter.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleEnableTemporalRange = this.handleEnableTemporalRange.bind(this);
    this.handleResetFilter = this.handleResetFilter.bind(this);
    this.handleDeleteRecording = this.handleDeleteRecording.bind(this);

  }

  onCloseDialog = () => {
    this.props.onCloseClick();
  }

  loadMore = () => {
    this._loadMore();
  }

  handleResetFilter() {
    this.setState({ menuFilterConfig: {}, menuFilterDateConfig: {} }, () => {
      let queryFilter = this.buildGraphqlFilter();
      this.handleApplyFilter(queryFilter);
    });
  }

  handleFilterMenuButtonButton() {
    this.setState({ filterMenuOpen: !this.state.filterMenuOpen });
  }

  buildGrapqlQueryFilter(key, value) {
    return { match: "CONTAINS", key: key, value: value };
  }

  buildGrapqlDateFilter(match, key, date) {
    return { match: match, key: key, value: date.toISOString() };
  }

  buildGraphqlFilter() {
    const menuFilterConfig = this.state.menuFilterConfig;
    const menuFilterDateConfig = this.state.menuFilterDateConfig;
    let queryFilter = [];
    if (menuFilterConfig.slug) {
      queryFilter.push(this.buildGrapqlQueryFilter('slug', menuFilterConfig.slug));
    }
    if (menuFilterConfig.notes) {
      queryFilter.push(this.buildGrapqlQueryFilter('notes', menuFilterConfig.notes));
    }
    if (menuFilterConfig.title) {
      queryFilter.push(this.buildGrapqlQueryFilter('title', menuFilterConfig.title));
    }
    if (menuFilterDateConfig.startDate && menuFilterDateConfig.enableTemporalRange) {
      queryFilter.push(this.buildGrapqlDateFilter("GTE", "rec_start", menuFilterDateConfig.startDate));
    }
    if (menuFilterDateConfig.endDate && menuFilterDateConfig.enableTemporalRange) {
      queryFilter.push(this.buildGrapqlDateFilter("LTE", "rec_end", menuFilterDateConfig.endDate));
    }
    return queryFilter;
  }

  handleEnableTemporalRange(enable, { defStart, defEnd }) {
    let endDate = null;
    let startDate = null;

    if (!this.state.menuFilterDateConfig.enableTemporalRange && enable) {
      // transition from disabled to enabled
      endDate = defEnd;
      startDate = defStart;
    }

    this.setState(prevState => ({
      menuFilterDateConfig: {
        ...prevState.menuFilterDateConfig,
        enableTemporalRange: enable,
        endDate: endDate,
        startDate: startDate
      },
    }), () => {
      let queryFilter = this.buildGraphqlFilter();
      this.handleApplyFilter(queryFilter);
    });
  }

  handleDeleteRecording() {
    this.handleApplyFilter(this.buildGraphqlFilter());
  }

  handleStartDateFilter(date) {
    this.setState(prevState => ({
      menuFilterDateConfig: {
        ...prevState.menuFilterDateConfig,
        startDate: date,
      },
    }), () => {
      let queryFilter = this.buildGraphqlFilter();
      this.handleApplyFilter(queryFilter);
    });
  }

  handleEndDateFilter(date) {
    this.setState(prevState => ({
      menuFilterDateConfig: {
        ...prevState.menuFilterDateConfig,
        endDate: date,
      },
    }), () => {
      let queryFilter = this.buildGraphqlFilter();
      this.handleApplyFilter(queryFilter);
    });
  }

  handleQuery(query) {
    this.setState(prevState => ({
      menuFilterConfig: {
        ...prevState.menuFilterConfig,
        [query.key]: query.value
      }
    }), () => {
      let queryFilter = this.buildGraphqlFilter();
      this.handleApplyFilter(queryFilter);
    });
  }

  handleApplyFilter(searchFilter) {
    this.setState({ filterGraphQl: searchFilter });
    const variables = {
      first: this.props.pageSize,
      sorting: this.state.sortingGraphQl,
      filters: searchFilter
    };
    this.refetchConnection(variables);
    logEvent(Event.FILTER_RECORDINGS);
  }

  refetchConnection(variables) {
    this.props.relay.refetchConnection(this.props.pageSize, (errors) => {
      if (errors) {
        newEvent(ERROR, 'reportUnableLoadRecording', 'reportUnableLoadRecording', "Load recordings Error");
      }
    }, variables);
  }

  sortHandler(property) {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order: order, orderBy: orderBy });
    const sorting = {
      direction: order.toUpperCase(),
      sort: orderBy
    };
    const variables = {
      first: this.props.pageSize,
      sorting: sorting,
      filters: this.state.filterGraphQl
    };
    this.setState({ sortingGraphQl: sorting });
    this.refetchConnection(variables);
  }

  _loadMore() {
    if (!this.props.relay.hasMore()) {
      return;
    } else if (this.props.relay.isLoading()) {
      return;
    }

    this.props.relay.loadMore(this.props.pageSize);
  }

  getSnapshotBeforeUpdate() {
    const { scrollTop, clientHeight, scrollHeight } = this.table;
    if (scrollTop + clientHeight === scrollHeight) {
      this.needToScroll = true;
    }
    return null;
  }

  componentDidUpdate() {
    this.scrollToLastRow();
  }

  componentDidMount() {
    this.scrollToLastRow();
  }

  scrollToLastRow() {
    if (this.table && this.needToScroll) {
      this.table.scrollTop = this.table.scrollHeight;
      this.needToScroll = false;
    }
  }

  getMenuFilter() {
    return (<Popover
      open={this.state.filterMenuOpen}
      anchorEl={this.menuFilterAnchorEl}
      onClose={this.handleFilterMenuButton}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <MenuFilter
        menuFilterConfig={this.state.menuFilterConfig}
        menuFilterDateConfig={this.state.menuFilterDateConfig}
        onApplyFilter={this.handleApplyFilter}
        onHandleQuery={this.handleQuery}
        onClose={this.handleFilterMenuButton}
        onHandleEnableTemporalRange={this.handleEnableTemporalRange}
        onHandleStartDateQuery={this.handleStartDateFilter}
        onHandleEndDateQuery={this.handleEndDateFilter}
        onHandleResetFilter={this.handleResetFilter}
      />
    </Popover>);
  }
  getHeaderTable(props) {
    const filterEnabled = this.state.filterGraphQl.length > 0;
    const { classes } = props;
    return (
      <Toolbar
        className={classNames(classes.root)}
      >
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <TooltipMultiLineText
            placement="top"
            title={filterEnabled ? this.props.intl.formatMessage(messages.reportFilterAppliedRecording) :
              this.props.intl.formatMessage(messages.reportFilterApplyRecording)}>
            <div ref={el => { this.menuFilterAnchorEl = el; }}>
              <IconButton
                onClick={this.handleFilterMenuButton}
                aria-label="Filter list">
                <IconFilter size={24} color={filterEnabled ? colors.attention : colors.normal} />
              </IconButton>
            </div>
          </TooltipMultiLineText>

        </div>
        {this.getMenuFilter()}
      </Toolbar>
    );
  }

  render() {
    const { classes } = this.props;
    const listRecordings = this.props.data.myRecordings;
    let emptyRecordings = false;
    const has_more = this.props.relay.hasMore();
    const columnData = [
      {
        id: 'title',
        label: this.props.intl.formatMessage(messages.reportTitleRecording), sorting: true
      },
      {
        id: 'slug',
        label: this.props.intl.formatMessage(messages.reportSlugRecording), sorting: true
      },
      {
        id: 'recStart',
        label: this.props.intl.formatMessage(messages.reportStartRecording), sorting: true
      },
      {
        id: 'duration',
        label: this.props.intl.formatMessage(messages.reportDurationRecording), sorting: false
      },
      {
        id: 'notes',
        label: this.props.intl.formatMessage(messages.reportNoteRecording), sorting: true
      },
      { id: 'type', label: '', sorting: false },
      { id: 'play', label: '', sorting: false },
      { id: 'download', label: '', sorting: false },
      { id: 'delete', label: '', sorting: false },
    ];

    if (!listRecordings.edges.length) {
      emptyRecordings = true;
    }
    /* eslint-disable react/no-find-dom-node */
    return (
      <Dialog
        open={true}
        fullScreen
        onClose={this.onCloseDialog}
        maxWidth='md'
        fullWidth={true}
      >
        <DialogContent ref={el => { this.table = ReactDOM.findDOMNode(el); }}>
          <Paper>
            {this.getHeaderTable(this.props)}
            <Table className={this.props.classes.table}>
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  {columnData.map(column =>
                    <TableCell
                      key={column.id}
                      className={classNames(classes.table_cell_header, classes.table_cell)}
                      sortDirection={this.state.orderBy === column.id ? this.state.order : false}
                    >
                      {column.sorting === true ?
                        <WrapperTableSortLabel
                          column={column}
                          sortHandler={this.sortHandler}
                          orderBy={this.state.orderBy}
                          order={this.state.order}
                        />
                        :
                        column.label
                      }
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {listRecordings.edges.map(({ node }) =>
                  <MyRecording
                    key={node.__id}
                    data={node}
                    relay={this.props.relay}
                    onDeleteRecording={this.handleDeleteRecording}
                  />
                )}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
        <DialogActions>
          {!emptyRecordings && has_more ? <TooltipMultiLineText
            placement="top"
            title={this.props.intl.formatMessage(messages.reportLoadRecording)}>
            <Button disabled={!has_more} color="primary" variant="contained" onClick={this.loadMore}>
              {">>"}
            </Button>
          </TooltipMultiLineText>
            : null}
          <Button color="primary" variant="contained" onClick={this.onCloseDialog}>
            {this.props.intl.formatMessage(messages.close)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

MyRecordingList.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: IntlPropType.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  relay: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired
};

class WrapperTableSortLabel extends Component {
  sortHandler = () => {
    const { column } = this.props;
    this.props.sortHandler(column.id);
  }

  render() {
    const { column } = this.props;
    return (
      <TableSortLabel
        active={this.props.orderBy === column.id && column.sorting}
        direction={this.props.order}
        onClick={this.sortHandler}
      >
        {column.label}
      </TableSortLabel>
    );
  }

  static propTypes = {
    sortHandler: PropTypes.func.isRequired,
    column: PropTypes.object.isRequired,
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }
}

export default createPaginationContainer(
  withStyles(style)(injectIntl(MyRecordingList)),
  { data: myRecordingsFragmentList },
  {
    direction: 'forward',
    query: myRecordingsQueryForward,
    getConnectionFromProps(props) {
      return props.data && props.data.myRecordings;
    },
    getFragmentVariables(previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables(props, paginationInfo, fragmentVariables) {
      return {
        count: paginationInfo.count,
        after: paginationInfo.cursor,
        sorting: fragmentVariables.sorting,
        filters: fragmentVariables.filters,
      };
    },
  }
);
