import { PushNotificationsConfig } from '../lib/actions/appconfig';
import { getLogger, LoggerInterface } from '../lib/logger';
import LocalStorage from '../localStorage';


export type HoloPushSub = {
  type: "SAFARI" | "WEB_PUSH";
  id: string;
  sub: PushSubscription | string;
  vapidPublicKey?: string;
};

export type PushNotificationsStatus = {
  available: boolean;
  sub: HoloPushSub | null;
  valid: boolean;
}

type SafariPermissionData = {
  permission: "default" | "denied" | "granted";
  deviceToken: string;
};

export async function initPushNotifications(
  conf: PushNotificationsConfig,
  localStore: LocalStorage,
): Promise<PushNotificationsStatus> {
  const logger = getLogger('pushNotifications');

  let available = pushNotificationsAvailable(conf);
  const serializedSub = localStore.loadPushSubscription();
  const sub = serializedSub;
  let valid = false;
  if (serializedSub && conf) {
    if ('safari' in window) {
      valid = checkSafariSubValidity(conf, serializedSub);
    } else {
      const worker = await registerServiceWorker(logger);
      if (worker) {
        const { validity, subscription } = await checkWebPushSubValidity(conf, serializedSub);
        valid = validity;
        sub.sub = subscription;
      } else {
        available = false;
      }
    }
  }
  return { available: available, sub: sub, valid: valid };
}

export function subscribe(
  config: PushNotificationsConfig,
  authToken: string,
): Promise<HoloPushSub> {
  if (isSafari()) {
    return enableSafariPushNotifications(config, authToken);
  } else {
    return enableWebPushNotifications(config);
  }
}

export function unsubscribe(
  config: PushNotificationsConfig,
): Promise<boolean> {
  if (isSafari()) {
    return disableSafariPushNotifications(config);
  } else {
    return disableWebPushNotifications();
  }
}

export function pushNotificationsEnabled(config: PushNotificationsConfig) {
  if (isSafari()) {
    const permData = window.safari.pushNotification.permission(config.website_push_id);
    return permData.permission === 'granted';
  } else if ('Notification' in window) {
    return Notification.permission === 'granted';
  }
  return false;
}

export function pushNotificationsAvailable(conf: PushNotificationsConfig) {
  if (isSafari()) {
    const permData = window.safari.pushNotification.permission(conf.website_push_id);
    return permData.permission !== 'denied';
  } else if ('Notification' in window) {
    return Notification.permission !== 'denied';
  }
  return false;
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function askPermission() {
  return Notification.requestPermission().then(r => r === 'granted');
}

function enableWebPushNotifications(
  config: PushNotificationsConfig
): Promise<HoloPushSub> {
  return askPermission()
    .then(installServiceWorker)
    .then(subscribeToPush(config))
    .then(
      function(sub) {
        return Promise.resolve({
          type: "WEB_PUSH",
          sub: sub, id: sub.endpoint,
          vapidPublicKey: config.vapid_public_key
        });
      },
      function(err) {
        return Promise.reject(`subscription failed: ${err}`);
      }
    );
}

function installServiceWorker(permission: boolean) {
  if (permission) {
    return navigator.serviceWorker.register('/serviceWorker.js')
      .then((() => navigator.serviceWorker.ready));
  } else {
    return Promise.reject('notification permission denied');
  }
}

function subscribeToPush(config: PushNotificationsConfig) {
  return (reg: ServiceWorkerRegistration | undefined) => {
    if (reg) {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(config.vapid_public_key)
      };

      return reg.pushManager.subscribe(subscribeOptions);
    } else {
      return Promise.reject('failed to retrieve service worker registration');
    }
  };
}

function disableWebPushNotifications(): Promise<boolean> {
  return navigator.serviceWorker.ready
    .then(getPushSub)
    .then(cancelSubscription)
    .then(
      function(res) {
        if (res) return Promise.resolve(true);
        else return Promise.reject('failed to unsubscribe');
      }
    );
}

function getPushSub(reg: ServiceWorkerRegistration | undefined) {
  if (reg) {
    return reg.pushManager.getSubscription();
  } else {
    return Promise.reject('no active service worker');
  }
}

function cancelSubscription(sub: PushSubscription | null) {
  if (sub) {
    return sub.unsubscribe();
  } else {
    return Promise.reject('no active subscription');
  }
}

function enableSafariPushNotifications(
  config: PushNotificationsConfig,
  authToken: string
): Promise<HoloPushSub> {
  const permissionData = window.safari.pushNotification.permission(config.website_push_id);
  return checkRemotePermission(permissionData, config, authToken);
}

function checkRemotePermission(
  permissionData: SafariPermissionData,
  config: PushNotificationsConfig,
  authToken: string
): Promise<HoloPushSub> {
  if (permissionData.permission === 'denied') {
    return Promise.reject('permission denied');
  } else if (permissionData.permission === 'granted') {
    return Promise.resolve({ type: "SAFARI", sub: permissionData.deviceToken, id: permissionData.deviceToken });
  } else {
    return new Promise((resolve, reject) => {
      window.safari.pushNotification.requestPermission(
        config.web_service_url,
        config.website_push_id,
        { "token": authToken },
        (res: SafariPermissionData) => {
          if (res.permission === 'denied') {
            reject('permission denied');
          } else {
            resolve({ type: "SAFARI", sub: res.deviceToken, id: res.deviceToken });
          }
        }
      );
    });
  }
}

// Safari doesn't allow to cancel a subscription programmatically
// To invalidate a sub the user has to manually remove permission from the preferences
// The only thing we can do is removing the sub from the server
function disableSafariPushNotifications(
  config: PushNotificationsConfig,
): Promise<boolean> {
  const permissionData = window.safari.pushNotification.permission(config.website_push_id);
  if (permissionData.deviceToken) {
    return Promise.resolve(true);
  } else {
    return Promise.reject('no active subscription');
  }
}

function registerServiceWorker(logger: LoggerInterface) {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.register('/serviceWorker.js').then(
      (reg) => {
        if (reg) {
          logger.info("service worker registered");
          return true;
        }
        return false;
      },
      (reason) => {
        logger.warn(`service worker registration failed, reason: ${reason}`);
        return false;
      }
    );
  }
  return Promise.resolve(false);
}

function checkSafariSubValidity(config: PushNotificationsConfig, sub: HoloPushSub) {
  if (sub.type === 'SAFARI' && 'pushNotification' in window.safari) {
    const permData = window.safari.pushNotification.permission(config.website_push_id);
    return permData.permission === 'granted' && permData.deviceToken === sub.id;
  }
  return false;
}

async function checkWebPushSubValidity(config: PushNotificationsConfig, sub: HoloPushSub) {
  if (sub.type === 'WEB_PUSH' && Notification.permission === 'granted' &&
    sub.vapidPublicKey === config.vapid_public_key) {
    const currentSub = await navigator.serviceWorker.ready.then(
      (reg) => reg ? reg.pushManager.getSubscription() : null
    );
    if (currentSub)
      return { validity: currentSub.endpoint === sub.id, subscription: currentSub };
    else
      return { validity: false, subscription: null };
  }
  return { validity: false, subscription: null };
}

function isSafari() {
  return 'safari' in window && 'pushNotification' in window.safari;
}
