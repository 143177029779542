import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { makeStyles, Theme, createStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { iconColors as colors } from '../../../colors';
import useMediaStream from '../../../hooks/useMediaStream';
import {
  saveVideoEnabled,
  saveAudioJoinMuted,
  toggleDeviceSettings,
  toggleBackgroundSettings
} from '../../../lib/actions/settings';
import { logEvent, Event } from '../../../lib/analytics';
import { VideoRoom } from '../../../lib/api/videoroom';
import { State } from '../../../lib/reducers';
import { isBlurAvailable } from '../../../lib/utils/pipelineDefault';
import { Tetris as TestTone } from '../../../lib/utils/webaudio/tetris';
import LocalStorage from '../../../localStorage';
import { avatarLarge } from '../../../style/LockedJoinRequests';
import DevicePreview from '../../AVSettings';
import {
  IconVideo,
  IconMicrophone,
  IconSettings,
  IconPlay,
  IconStop,
  IconMicrophoneOff,
  IconVideoOff,
  IconBlur,
} from '../../IconSet';
import RippleBadge from '../../RosterAvatar/RippleBadge';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    video: {
      margin: 'auto',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        width: '45vw',
        height: '25.3125vw', // 40vw * 56.25%
      },
      [theme.breakpoints.only('sm')]: {
        width: '55vw',
        height: '30.9375vw', // 55vw * 56.25%
      },
      [theme.breakpoints.down('xs')]: {
        width: '100vw',
        height: '56.25vw', // 100vw * 56.25%
      }
    },
    settings: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      padding: theme.spacing(2, 1)
    },
    button: {
      border: `1px solid ${colors.contrast}`,
      borderRadius: '50%',
      padding: theme.spacing(1.5),
      minWidth: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0.4),
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 1.5),
        '&:hover': {
          backgroundColor: theme.palette.grey.A100,
        }
      },
    },
    buttonSelected: {
      borderColor: colors.inactive,
      backgroundColor: colors.inactive,
      '&:hover': {
        backgroundColor: colors.inactive,
      }
    },
    canvas: {
      display: 'none',
      width: `${avatarLarge.width}`,
      height: `${avatarLarge.height}`,
    },
    betaBadge: {
      '&>span': {
        color: theme.palette.common.white,
        background: theme.palette.warning.dark,
      },
    },
  })
);


const messages = defineMessages({
  joinWithVideo: { id: 'joinWithVideo' },
  backgroundSettings: { id: 'backgroundSettings' },
  joinMuted: { id: 'joinWithAudio' },
  mediaSettings: { id: 'mediaSettings' },
  playTestSound: { id: 'playTestSound' },
  stopTestSound: { id: 'stopTestSound' },
  checkSettingsAndJoin: { id: 'checkSettingsAndJoin' },
  noAudioDeviceFoundTooltip: { id: 'noAudioDeviceFoundTooltip' },
});

function TestSoundPlayer({ device: d }: { device: undefined | MediaDeviceInfo }) {
  const deviceId = d ? d.deviceId : null;
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [isPlayingTestSound, setIsPlayingTestSound] = React.useState(false);
  const testToneRef = React.useRef(new TestTone());

  const playTestSound = () => {
    setIsPlayingTestSound(true);
    testToneRef.current.start(deviceId);
    logEvent(Event.TEST_AUDIO);
  };

  const stopTestSound = () => {
    setIsPlayingTestSound(false);
    testToneRef.current.stop();
  };

  React.useEffect(
    () => {
      const rr = testToneRef.current;
      return () => {
        rr.stop();
      };
    }
    , []
  );

  if (isPlayingTestSound) {
    return (
      <Tooltip title={formatMessage(messages.stopTestSound)}>
        <IconButton onClick={stopTestSound} className={classes.button}>
          <RippleBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <IconStop color={colors.contrast} size={28} />
          </RippleBadge>
        </IconButton>
      </Tooltip>);
  } else {
    return (
      <Tooltip title={formatMessage(messages.playTestSound)}>
        <IconButton onClick={playTestSound} className={classes.button}>
          <IconPlay color={colors.contrast} size={28} />
        </IconButton>
      </Tooltip>
    );
  }
}

function AVSettingsAndPreview(props: ExtendedProps) {
  const {
    videoRef,
    canvasRef,
    roomOptions,
    withVideo,
    withAudio,
    audioInitiallyMuted,
    currentAudioOutDev,
    settingsOpen
  } = props;

  const joinMuted = roomOptions.join_muted;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const openSettings = React.useCallback(
    () => {
      dispatch(toggleDeviceSettings());
      logEvent(Event.MEDIA_SETTINGS, { 'from': 'waiting_room' });
    }, [dispatch]
  );

  const getStyle = (selected: boolean) => {
    const selectedStyle = selected ? classes.buttonSelected : null;
    return classNames(classes.button, selectedStyle);
  };

  const [isAcquiringMedia, setIsAcquiringMedia] = React.useState(true);
  const onVideoLoading = React.useCallback(
    (isAcquiring: boolean) => {
      setIsAcquiringMedia(isAcquiring);
    },
    [setIsAcquiringMedia]
  );

  const { stream, error: streamError } = useMediaStream({ videoEnabled: withVideo, onVideoLoading });

  const audioDisabled = (audioInitiallyMuted || !withAudio || !!joinMuted);
  const hasAudioTrack = Boolean(VideoRoom.getAudioTrackFromStream(stream));

  const handleAudioSwitch = React.useCallback(
    () => {
      if (!hasAudioTrack) {
        return;
      }
      dispatch(saveAudioJoinMuted(withAudio, new LocalStorage()));
      const action = withAudio ? 'mute' : 'unmute';
      logEvent(Event.MUTE_MY_MICROPHONE, { 'from': 'waiting_room', 'action': action });
    }, [withAudio, hasAudioTrack, dispatch]
  );

  const handleVideoSwitch = React.useCallback(
    () => {
      dispatch(saveVideoEnabled(!withVideo, new LocalStorage()));
      const action = !withVideo ? 'unmute' : 'mute';
      logEvent(Event.MUTE_MY_VIDEO, { 'from': 'waiting_room', 'action': action });
    }, [withVideo, dispatch]
  );

  const handlebackgroundBlurSwitch = React.useCallback(
    () => {
      logEvent(Event.BACKGROUND_SETTINGS, { 'from': 'waiting_room' });
      dispatch(toggleBackgroundSettings());
    }, [dispatch]
  );



  return (
    <React.Fragment>
      <div className={classes.video}>
        <DevicePreview
          ref={videoRef}
          stream={stream}
          streamError={streamError}
          videoEnabled={withVideo}
          isAcquiringMedia={isAcquiringMedia}
        />
        <div className={classes.settings}>
          <Tooltip title={hasAudioTrack
            ? formatMessage(messages.joinMuted)
            : formatMessage(messages.noAudioDeviceFoundTooltip)}
          >
            <div>
              <IconButton
                className={getStyle(audioDisabled || !hasAudioTrack)}
                disabled={joinMuted}
                onClick={handleAudioSwitch}>
                { (audioDisabled || !hasAudioTrack)
                  ? <IconMicrophoneOff color={colors.contrast} size={28} />
                  : <IconMicrophone color={colors.contrast} size={28} />
                }
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title={formatMessage(messages.joinWithVideo)}>
            <div>
              <IconButton
                className={getStyle(!withVideo)}
                onClick={handleVideoSwitch}>
                {!withVideo
                  ? <IconVideoOff color={colors.contrast} size={28} />
                  : <IconVideo color={colors.contrast} size={28} />
                }
              </IconButton>
            </div>
          </Tooltip>
          <TestSoundPlayer device={currentAudioOutDev} />
          { isBlurAvailable() &&
            <Tooltip title={formatMessage(messages.backgroundSettings)}>
              <Badge className={classes.betaBadge} overlap="circle" badgeContent="BETA">
                <div>
                  <IconButton
                    className={classes.button}
                    onClick={handlebackgroundBlurSwitch}>
                    <IconBlur color={colors.contrast} size={28} />
                  </IconButton>
                </div>
              </Badge>
            </Tooltip>
          }
          <Tooltip title={formatMessage(messages.mediaSettings)}>
            <div>
              <IconButton
                className={getStyle(settingsOpen)}
                onClick={openSettings}>
                <IconSettings color={colors.contrast} size={28} />
              </IconButton>
            </div>
          </Tooltip>
        </div>
      </div>
      <canvas ref={(ref) => canvasRef.current = ref} className={classes.canvas} />
    </React.Fragment>
  );
}

type MappedProps = {
  withVideo: boolean;
  withAudio: boolean;
  audioInitiallyMuted: boolean;
  roomOptions: State['appconfig']['room_options'];
  currentAudioOutDev: State['settings']['audioOutDevice'];
  settingsOpen: boolean;
}

type Props = {
  videoRef: React.MutableRefObject<HTMLVideoElement | null | undefined>;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null | undefined>;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State): MappedProps => {
  return {
    withVideo: state.settings.videoEnabled,
    withAudio: !state.settings.audioJoinMuted,
    audioInitiallyMuted: state.settings.audioJoinMuted,
    roomOptions: state.appconfig.room_options,
    currentAudioOutDev: state.settings.audioOutDevice,
    settingsOpen: state.settings.deviceSettingsDialogOpen
  };
};

export default connect(mapStateToProps)(AVSettingsAndPreview);
