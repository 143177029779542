import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import { togglePip } from '../../../lib/actions/room';
import { logEvent, Event } from '../../../lib/analytics';
import { UserActionsContext } from '../../../lib/contexts';
import { State } from '../../../lib/reducers';
import { isPipEnabled } from '../../../lib/reduxSelectors/user';
import { IconMiniplayer, IconExpand } from '../../IconSet';


const messages = defineMessages({
  enablePip: { id: 'enablePipButton' },
  disablePip: { id: 'disablePipButton' }
});


function PipMenuItem(props: ExtendedProps) {
  const { user, pipUser,  pipEnabled, onClose, iconSize = 20 } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const userActionsContext = React.useContext(UserActionsContext);

  const onPipAction = React.useCallback(
    () => {
      if (pipUser) {
        dispatch(togglePip(null));
        logEvent(Event.PICTURE_IN_PICTURE, { 'action': 'exit_pip', 'from': userActionsContext });
      }
      else {
        dispatch(togglePip(user));
        logEvent(Event.PICTURE_IN_PICTURE, { 'action': 'enter_pip', 'from': userActionsContext });
      }
      onClose();
    }
    , [dispatch, user, onClose, pipUser, userActionsContext]
  );

  const getIcon = (): React.ReactElement => {
    if (pipUser) {
      return <IconExpand size={iconSize} />;
    }
    else {
      return <IconMiniplayer size={iconSize} />;
    }

  };

  if (!pipEnabled) {
    return null;
  }

  return (
    <MenuItem onClick={onPipAction}>
      <ListItemIcon>
        {getIcon()}
      </ListItemIcon>
      <ListItemText primary={formatMessage(!pipUser
        ? messages.enablePip
        : messages.disablePip)} />
    </MenuItem>

  );
}

type Props = {
  user: string;
  iconSize?: number;
  onClose: () => void;
}

type MappedProps = {
  pipUser: string | null;
  pipEnabled: boolean;
}

type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    pipEnabled: isPipEnabled(ownProps.user, state),
    pipUser: state.room.pipEnabled,
  };
};


export default connect(mapStateToProps)(PipMenuItem);
