import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from "react-redux";

import { Tooltip, IconButton, makeStyles, Theme } from '@material-ui/core';

import { iconColors } from '../../../colors';
import { ERROR } from "../../../lib/notifications";
import { State } from '../../../lib/reducers';
import { BlurCustomValue } from "../../../lib/utils/pipelineDefault";
import { IconEdit } from '../../IconSet';
import BackgroundItem from '../BackgroundItem';

import { validateImageSize, onLoadEnd, showNotification, InputComponentType } from "./utils";


const messages = defineMessages({
  editBackground: { id: 'backgroundBlurCustomEdit' },
});


const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    padding: theme.spacing(1)
  }
}));


const InputComponent = (props: ExtendedProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const { inputComponent, item, onAdd, isButtonDisabled } = props;

  // use different html ids on each custom item
  const lastChar = item.value.toString().charAt(item.value.toString().length - 1);
  const id = `icon-custom-background-${lastChar}-${inputComponent}`;

  const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const image = fileList[0];
      // Check file size
      if (validateImageSize(image)) {
        // Load file as base64 image
        const fileReader = new FileReader();
        fileReader.onloadend = (_e) => onLoadEnd(fileReader, dispatch, item.typeValue, onAdd);
        fileReader.readAsDataURL(image);
      }
    } else {
      showNotification(ERROR, 'backgroundBlurInvalidFile');
    }
    // reset target value to allow uploading the same file again
    event.target.value = '';
  };

  const getLabelComponent = (input: InputComponentType) => {
    switch (input) {
      case InputComponentType.ICON_BACKGROUND:
        return <BackgroundItem isButtonDisabled={isButtonDisabled} item={item} />;
      case InputComponentType.ICON_EDIT:
        return (
          <Tooltip placement='top' title={formatMessage(messages.editBackground)}>
            <IconButton className={classes.icons} component='span'>
              <IconEdit size={18} color={iconColors.contrast} />
            </IconButton>
          </Tooltip>
        );
    }
  };

  return (
    <React.Fragment>
      <input
        accept=".jpeg, .jpg, .png"
        style={{ display: 'none' }}
        id={id}
        type="file"
        onChange={readFile}
      />
      <label htmlFor={id}>
        { getLabelComponent(inputComponent) }
      </label>
    </React.Fragment>
  );
};


type Props = {
  inputComponent: InputComponentType;
  item: BlurCustomValue;
  onAdd: () => void;
  isButtonDisabled:  boolean;
}

type MappedProps = {
  customBackground: string | undefined;
  currentBgBlurValue: State['settings']['backgroundBlurValue'];
}

const mapStateToProps = (state: State, props: Props): MappedProps => {
  const value = props.item.typeValue;
  const background = state.settings[value] ? state.settings[value] : undefined;
  return {
    customBackground: background,
    currentBgBlurValue: state.settings.backgroundBlurValue,
  };
};

type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(InputComponent);
