import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import ClosableDialog from '../../../ClosableDialog';

import LoginLink from './LoginLink';
import messages from './messages';
import { backToHome } from './utils';

import useStyles from './styles';


function DeviceError(_props: {}) {
  const classes = useStyles();
  const history = useHistory();

  const { formatMessage } = useIntl();

  const gotoHome = React.useCallback(
    () => {
      backToHome(history, true);
    }, [history]
  );

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth
      title={formatMessage(messages.deviceErrorTitle)}
    >
      <DialogContent>
        {formatMessage(messages.deviceErrorContent)}
      </DialogContent>
      <DialogActions>
        <Box
          className={classes.fullWidth}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box flexGrow={1}>
            <LoginLink renderAs='text' />
          </Box>
          <Box>
            <Button variant='contained' onClick={gotoHome} color='primary'>
              {formatMessage(messages.ok)}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </ClosableDialog>
  );
}


export default DeviceError;
