import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';


const styles = _theme => ({
  root: {
    margin: '0 0 38px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative'
  },
  rootResponsive: {
    '@media (min-width: 600px)': {
      width: 'auto',
      marginLeft: 40,
      marginRight: 40,
    },
    '@media (min-width: 920px)': {
      width: 840,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '@media (min-width: 1260px)': {
      width: '66.66%',
    },
    '@media (min-width: 1800px)': {
      width: 1200,
    },
  },
  rootFullHeight: {
    height: '100%',
    marginBottom: 0,
  },
});


class BodyLayout extends Component {
  render() {
    const classes = this.props.classes;
    const fullHeight = this.props.fullHeight || false;
    const fullWidth = this.props.fullWidth || false;
    let cname = classes.root;
    if (!fullWidth) {
      cname += ` ${classes.rootResponsive}`;
    }
    if (fullHeight) {
      cname += ` ${classes.rootFullHeight}`;
    }
    return (
      <div className={cname}>
        {this.props.children}
      </div>
    );
  }
}


BodyLayout.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  fullHeight: PropTypes.bool,
  fullWidth: PropTypes.bool,
};


export default withStyles(styles)(BodyLayout);
