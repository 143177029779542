import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import sizeMe, { SizeMeProps } from 'react-sizeme';

import { VideoRoom } from '../../lib/api/videoroom';
import gridElements from '../../lib/grid';
import { State } from '../../lib/reducers';
import { WebSocketReady } from '../../lib/reducers/websocket';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import LoadingVideoElement from '../VideoElement/LoadingVideoElement';

import GridUser from './GridUser';
import { getStreams } from './StandardDimensionLayout';
import VideoToolbar from './VideoToolbar';


function renderParticipants(
  remoteVideoStreams: ExtendedProps['remoteVideoStreams'],
  participantsGrid: CSSProperties[]
) {
  const keys = Object.keys(remoteVideoStreams);
  const participants = keys.flatMap((uid, idx) => {
    const video = remoteVideoStreams[uid as keyof ExtendedProps['remoteVideoStreams']];
    if (video['stream'] || video['isVideoStarting']) {
      return [
        <GridUser key={idx} uid={uid} style={participantsGrid[idx]} stream={video['stream']} />
      ];
    }
    else {
      return [];
    }
  });
  return participants;
}


function getGrid(props: ExtendedProps): [CSSProperties, CSSProperties, CSSProperties[]] {
  const { size } = props;
  if (size.width && size.width > 0 && size.height && size.height > 0) {
    let nrOfStreams = Object.keys(props.remoteVideoStreams).length;
    const isNotRecorder = true;  // FIXME

    if (props.hasVideoStream && isNotRecorder) {
      nrOfStreams = nrOfStreams + 1;
    }
    if (props.localScreenStream) {
      nrOfStreams = nrOfStreams + 1;
    }

    let participantsGrid: CSSProperties[] = gridElements(size.width, size.height, nrOfStreams);
    let streamGrid = {};
    let screenGrid = {};

    if (props.hasVideoStream && isNotRecorder) {
      [streamGrid, participantsGrid] = [participantsGrid[0] || streamGrid, participantsGrid.slice(1)];
    }

    if (props.localScreenStream) {
      [screenGrid, participantsGrid] = [participantsGrid[0] || screenGrid, participantsGrid.slice(1)];
    }

    return [streamGrid, screenGrid, participantsGrid];
  }
  else {
    return [{}, {}, []];
  }
}


function GridView(props: ExtendedProps) {
  const {
    remoteVideoStreams,
    hasVideoStream,
    myUserId,
    localScreenStream,
    localVideoStream,
  } = props;

  const [streamGrid, screenGrid, participantsGrid] = getGrid(props);

  if (!myUserId) {
    return null;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {hasVideoStream &&
        <div style={streamGrid}>
          <LoadingVideoElement
            user={myUserId}
            mirrored={true}
            addVideoMutedIconOverlay={true}
            stream={localVideoStream}
          />
          <VideoToolbar uid={myUserId} />
        </div>
      }
      {localScreenStream ?
        <div style={screenGrid}>
          <LoadingVideoElement user={`${myUserId}_screen`} stream={localScreenStream} />
          <VideoToolbar uid={`${myUserId}_screen`} />
        </div>
        : null
      }
      {renderParticipants(remoteVideoStreams, participantsGrid)}
    </div>
  );
}


type MappedProps = {
  hasVideoStream: boolean;
  localVideoStream: State['room']['localvideo_stream'];
  localScreenStream: State['room']['screenStream'];
  myUserId: WebSocketReady['uid'];
}


type ExtendedProps = {
  remoteVideoStreams: ReturnType<typeof getStreams>;
} & MappedProps & SizeMeProps;


const mapStateToProps = (state: State): MappedProps => {
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }
  return {
    myUserId: getWsUserId(state.websocket),
    localVideoStream: state.room.localvideo_stream,
    localScreenStream: state.room.screenStream,
    hasVideoStream,
  };
};


export default connect(mapStateToProps)(sizeMe({ monitorHeight: true })(GridView));
