const generic = {
  ok: 'Ok',
  cancel: 'Cancel',
  reset: 'Reset',
  gotIt: 'Got it',
  close: 'Close',
  send: 'Send',
  yes: 'Yes',
  no: 'No',
  pageNotFound: 'Page not found',
  hour: 'hour',
  hours: 'hours',
  minute: 'minute',
  minutes: 'minutes',
  second: 'second',
  seconds: 'seconds',
  day: 'day',
  days: 'days',
  email: 'Email',
  phoneNumber: 'Phone number',
  call: 'Call',
  login: 'Login',
  copyToClipboard: 'Copy to clipboard',
  copiedToClipboard: 'Copied!',
  from: 'from',
  to: 'to',
  next: 'next',
  previous: 'previous',
  dateFormat: 'MMMM Do H:mm',
  fullDateFormat: 'MMMM Do YYYY H:mm',
  tryAgain: 'Try again',
  leave: 'Leave',
  error: 'Error',
  networkError: 'Network connection error',
  videoPublishFailure: 'Error publishing your video. Check your network connection and try publishing again.',
  screenPublishFailure: 'Error sharing your screen. Check your network connection and try publishing again.',
  uploadICEGlitches: 'You are experiencing network issues publishing your media. '
    + 'If this error persists check your network connection and consider disabling your video.',
  downloadICEGlitches: 'You are experiencing network issues receiving media. '
    + 'If this error persists check your network connection and consider disabling some stream.',
  mediaPublishFailure: 'Error publishing media. Check your network connection',
  endMeetingMsg: 'End meeting',
  save: 'Save',
  name: 'Name',
  allDone: 'You have no events scheduled',
  allDoneSchedule: 'Click here to schedule a new meeting.',
  userInformation: 'User profile',
  pushNotificationsStorageError: 'Could not store push subscription',
  pushNotificationsError: 'Could not enable push notifications',
  pushNotificationsSwitchText: 'Push notifications',
  pushNotificationsSwitchInfo: `You will receive a push notification on this device whenever:
  - a user tries to join your personal room while you are not there
  - a meeting you were invited to is about to start
  - it is time to start a meeting you scheduled`,
  userPins: 'Your PINs',
  userOther: 'More settings',
  roomLockPreferenceSwitchText: 'Meetings locked by default',
  roomLockPreferenceSwitchInfo: 'Your personal room and the meetings you schedule will be locked by default. '
    + 'Participants will have to be manually admitted.',
  screenshareFrameRateLabel: 'Screenshare frame rate',
  screenshareFrameRateInfo: 'Choose how many frames per second are sent while sharing your screen. '
    + 'Certain meeting types might overwrite this property.',
  screenshareFrameRateWarning: 'Higher settings for this property might cause high bandwidth usage '
    + 'and result in an unstable experience.',
  holocomAccount: '{site} Account',
  gotoHomepage: 'Go to Homepage',
  mediaSettings: 'Media settings',
  mediaSettingsFirstTimeMsg: 'Here you can change your audio and video settings',
  loginUsingAccount: 'Login using your account',
  calendarFirstTimeMsg: 'Here you can create a new appointment or display those already scheduled',
  enable: 'Enable',
  disable: 'Disable',
  invite: 'Invite',
  manageYourControlsHere: 'You can manage your controls here',
  pleaseWaitForRoom: 'Please wait while connecting to the room...',
  loginFirstTimeMessage: 'If you have an account, log in here to access all features',
  lockRoomFirstTimeMsg: 'Click here to lock access to the meeting',
  extend: 'Extend',
};

const deviceConfiguration = {
  deviceSelectorTitle: 'Select preferred camera and microphone',
  deviceSelectorVideoError:
    'Something is wrong with your webcam, it may be in use or too high quality is set. Check your settings!',
  deviceSelectorAudioInput: 'Microphone',
  deviceSelectorVideoInput: 'Camera',
  deviceSelectorAudioOutput: 'Speakers',
  deviceSelectorNoAudioOutput: 'Audio output not supported',
  deviceSelectorVideoQuality: 'Video quality',
  deviceSelectorLoadingCam: 'Refreshing webcam, please hold on...',
  deviceOverConstrained: 'The selected video quality is not supported',
  deviceError: 'Error accessing audio or video device, check your settings',
  deviceNotFound: 'Cannot find the selected device, check your settings',
  noAudioDeviceFound: 'Cannot find a usable microphone, check your devices or '
    + 'continue anyway to join in listen-only mode',
  noAudioDeviceFoundTooltip: 'No microphone found',
  noVideoDeviceFoundTooltip: 'No video device found',
  joinedInListenOnly: 'No microphone found, joined in viewer mode',
  audioIsPausedDialogTitle: 'Meeting joined',
  audioIsPausedDialogMessage: 'You have joined this meeting in viewer mode',
  audioIsPausedDialogSubMessage: 'Since access to your microphone has been denied, '
    + 'you won\'t be able to publish audio or video',
  noAudioInputAvailable: 'No microphone is available',
  noDeviceAvailable: 'No available devices',
  rescanDevices: 'Rescan devices',
  frontCamera: 'Front Camera',
  rearCamera: 'Rear Camera',
  beautifyEffect: 'Enhance Appearance',
  backgroundSettings: 'Video Effects',
  backgroundSettingsHelp:
    '\u00B7 Enablig these features will increase the resource usage (CPU, Memory, Battery) ' +
    'which may impact the performance.\n' +
    '\u00B7 Background effects work best on higher color constrast between subject and background.',
  backgroundBlur: 'Background Removal',
  backgroundBlurNone: 'Disable',
  backgroundBlurSoft: 'Soft blur',
  backgroundBlurHard: 'Hard blur',
  backgroundBlurCustom: 'Upload a background',
  backgroundBlurInvalidFile: 'The provided image is not valid',
  backgroundBlurFlowers: 'Flowers',
  backgroundBlurKitchen: 'Kitchen',
  backgroundBlurLeaves: 'Leaves',
  backgroundBlurMountain: 'Mountains',
  backgroundBlurOffice: 'Office',
  backgroundBlurSkyline: 'Skyline',
  backgroundBlurSunrise: 'Sunrise',
  backgroundBlurThailand: 'Thailand',
  backgroundBlurUmbrella: 'Umbrella',
  backgroundBlurCustomTooLarge: 'Image size is too large. Maximum image size is {value}{unit}',
  backgroundBlurCustomTooBig: 'Image resolution is too big. Maximum image resolution is {width}x{height}',
  backgroundBlurCustomRemove: 'Delete background',
  backgroundBlurCustomApply: 'Apply background',
  backgroundBlurCustomEdit: 'Edit background',
};

const login = {
  username: 'Username',
  password: 'Password',
  loginHaveAnAccount: 'Do you have an account? Click here to login.',
  disconnectedMessage: 'Failed to connect to server',
  genericOAuth: 'Logon using OAuth/2.0',
  errorLoginCode5xx:
    'The server encountered an error, please try again or contact the administrator if the problem persists',
  errorLoginCode4xx: 'The request is not valid',
  errorLoginCode401: 'Incorrect username or password',
  errorLoginGeneric:
    'An unexpected error occurred. Contact the administrator if the problem persists',
};

const logout = {
  logout: 'Logout',
  logoutDialogMessage: 'Are you sure you want to logout?',
};

const mediaPermissions = {
  mediaPermissionsMessage:
    'We need access to your camera and microphone so that other participants can see and hear you.\n' +
    'We will ask you to confirm this decision on each browser and computer you use.\n' +
    'If this takes too long, you may be experiencing hardware problems. ' +
    'Try reloading the page or checking your devices.',
  mediaPermissionsTitle: 'Allow to use your camera and microphone',
  mediaPermissionsBlockedTitle: 'Camera and microphone are blocked',
  mediaPermissionsPermsErr:
    'We require access to your camera and microphone.\n' +
    `Click the camera blocked icon in your browser's address bar, select Always allow and reload this page.\n` +
    'If you continue, you will not be able to send your audio or video.',
  mediaPermissionsGenErr:
    'Cannot access your audio or video devices. Please check your hardware.\n' +
    'If you continue, you will not be able to send your audio or video.',
  mediaPermissionsContinueWithoutAV: 'Continue without audio or video',
};

const meeting = {
  hello: 'Hello',
  startANewMeeting: 'Start a new meeting',
  deleteMeeting: 'Delete event',
  editMeeting: 'Edit event',
  youCanInvite: 'You will be able to invite other participants via email, link or phone',
  start: 'Start',
  create: 'Create',
  today: 'Today',
  tomorrow: 'Tomorrow',
  attendees: 'Attendees',
  ownMeeting: 'owner',
  assistedMeeting: 'assistant',
  doYouHaveAnInvite: 'Do you have an invite code?',
  joinUsingInvite: 'Join using your invite',
  meetingHeader: 'Meeting',
  meetingWelcome: 'Hello {user}!',
  noVideoDeviceFound: 'No video device found. Using audio only.',
  unableToStopVideoStream: 'An error occurred while stopping your video.',
  insertMeetingCodeHere: 'Insert meeting code here',
  insertMeetingCode: 'Insert meeting code',
  navigationConfirmationTitle: 'Leave the room?',
  navigationConfirmationMessage: 'Are you sure you want to leave this room?',
  theRoomIsNotAvailableYet: 'The meeting "{name}" is not available yet.',
  theRoomIsScheduledFromTo: 'It is scheduled for {date}, from {start} to {end}',
  theRoomIsScheduledTodayFromTo: 'It is scheduled for today, from {start} to {end}',
  theRoomWillBeAvailableIn: 'It will be available {time}',
  errorCodeGenericTitle: 'Error joining room.',
  errorCodeGenericBody: 'An error occurred.',
  errorCodeGenericPrimaryButton: 'Cancel',
  reconnectNotFound: 'Room not found, or not yet started.\nDo you want to rejoin this room?',
  reconnectNotRunning: 'Owner not connected yet.\nDo you want to retry?',
  errorCode1000Title: 'Cannot access the room',
  errorCode1000Body: 'Because the owner is not there',
  errorCode1000PrimaryButton: 'Ok',
  errorCode1001Title: `There was a problem`,
  errorCode1001Body:
    'The server encountered an error, please try again or contact the administrator if the problem persists',
  errorCode1001PrimaryButton: 'Try Again',
  errorCode1001SecondaryButton: 'Cancel',
  errorCode1002Title: `Cannot connect to the meeting`,
  errorCode1002Body:
    `Meeting {title} is not available yet, because it starts on {dStart} ` +
    `at {tStart}. The meeting will be available {delta}.`,
  errorCode1002SecondaryButton: 'Cancel',
  errorCode1002PrimaryButton: 'Try again',
  errorCode1003Title: `Cannot join the meeting`,
  errorCode1003Body: 'The meeting ended on {dtEnd}.',
  errorCode1003PrimaryButton: 'Ok',
  errorCode1004Title: `Meeting not found or expired`,
  errorCode1004Body: 'No meeting was found',
  errorCode1004SecondaryButton: 'Cancel',
  errorCode1004PrimaryButton: 'Try again',
  errorCode1005Title: `The owner is not present yet`,
  errorCode1005Body: 'Please wait, access to the room will be done automatically',
  errorCode1005SecondaryButton: 'Cancel',
  errorCode1005PrimaryButton: 'Try again',
  errorCode1008Title: `Domain resources exhausted`,
  errorCode1008Body: 'Cannot allow more participants in this domain.',
  errorCode1008SecondaryButton: 'Cancel',
  errorCode1008PrimaryButton: 'Try again',
  errorCode1009Title: `Domain resources exhausted`,
  errorCode1009Body: 'Cannot start more rooms in this domain.',
  errorCode1009SecondaryButton: 'Cancel',
  errorCode1009PrimaryButton: 'Try again',
  errorCode1010Title: `User resources exhausted`,
  errorCode1010Body: 'Your user cannot start more rooms.',
  errorCode1010SecondaryButton: 'Cancel',
  errorCode1010PrimaryButton: 'Try again',
  errorCode1011Title: `Room resources exhausted`,
  errorCode1011Body: 'This room cannot accept more participants.',
  errorCode1011SecondaryButton: 'Cancel',
  errorCode1011PrimaryButton: 'Try again',
  errorCode1012Title: `Generic resources error`,
  errorCode1012Body: 'The resources controlled experimented an error, ' +
    'please try again or contact the administrator if the problem persists.',
  errorCode1012SecondaryButton: 'Cancel',
  errorCode1012PrimaryButton: 'Try again',
  errorCode1013Title: `Cannot enter the room`,
  errorCode1013Body: 'This room is locked. No new participants can join.',
  errorCode1013SecondaryButton: 'Cancel',
  errorCode1013PrimaryButton: 'Try again',
  errorCode1015Title: 'Meeting terminated',
  errorCode1015Body: 'The meeting you were trying to join has ended',
  errorCode1016Title: 'Cannot enter the room',
  errorCode1016Body: 'Only authenticated users can access this room',
  errorCode1017Title: 'No reply',
  errorCode1017Body: 'The owner did not respond',
  errorCode4001Title: 'Unauthorized',
  errorCode4001Body: 'You are not authorized to perform this action.',
  errorCode4001SecondaryButton: 'Cancel',
  errorCode4001PrimaryButton: 'Ok',
  guestLoginDialogTitle: 'Guest access',
  guestLoginFieldTitle: 'Insert your name',
  guestLoginFieldMessage: 'Name',
  clickHereToLogin: 'Meeting organizer? Click here to login',
  clickHereToLoginToOwnRoom: 'Room owner? Click here to login',
  streamErrorTitle: 'Error accessing device',
  streamErrorContent: 'Please check your audio/video device or permissions',
  joinButton: 'Join',
  joinByCode: 'Do you have a meeting code?',
  screenVideoDisplayName: `{name}'s screen`,
  publisherIsNotSendingVideo: 'This user is not streaming video',
  youAreNotSendingVideo: 'You are not streaming video',
  listAttendees: 'Participants list:',
  meetingLeftDialogTitle: 'Room left',
  meetingLeftDialogContent: 'You have now left the room',
  meetingLeftDialogContentWhenKicked: 'You have been kicked from the room',
  meetingLeftDialogContentWhenEnded: 'The meeting has ended',
  meetingLeftDialogContentWhenIceFailed: 'Failed connecting to the audio/video server, probably because of ' +
    'restrictive firewall rules. Please contact your network administrator.',
  meetingAvailable: 'Next meetings',
  meetingStartsAt: 'On {dtStart}',
  attendeesErrorEmail: 'The email is not valid',
  attendeesErrorEmpty: 'The field is empty',
  attendeesErrorRequired: 'At least an attendee is required',
  attendeesErrorAlreadyEntered: 'The email is already entered',
  attendeesUploadedInvalidFound: 'One or more of the uploaded emails are not valid. ' +
    'Fix the emails and upload the file again. The invalid ones are: {list}',
  attendeesUploadedInvalidFoundMore: ' and {number} more',
  attendeesUpload: 'Upload a list from file',
  attendeesUploadInvalidFile: 'The provided file is not valid',
  dtErrorTooBrief: 'Interval too brief',
  dtErrorTooLong: 'Interval too long',
  dtErrorInvalidFormat: 'Invalid format',
  scheduleMeeting: 'Create a new event',
  scheduleMeetingPlaceHolder: 'Assign a name to event',
  scheduleStartMeeting: 'Enter the meeting start date',
  scheduleEndMeeting: 'Enter the meeting end date',
  scheduleHour: 'Hour',
  scheduleMeetingErrorTooMany: 'Too many participants',
  scheduleMeetingErrorRequired: 'You must add at least one participant',
  scheduleMeetingErrorTypeNotAllowed: 'You are not allowed to schedule this type of meeting',
  scheduleMeetingAttendeesPlaceHolder: 'Insert email to invite',
  titleMeeting: 'Event name',
  titleMeetingError: 'The field is empty',
  sendAsLabel: 'Not the organizer?',
  sendAsInsertOrganizerEmail: 'Insert the organizer\'s email',
  sendAsOrganizerNotFound: 'User not found',
  sendAsOrganizerFillText: 'Type in the email address to start the search',
  notes: 'Description',
  notesAdd: 'Add description',
  particpantsAdd: 'Add participants',
  sendInvite: 'Send invite',
  sendInviteToPersonal: 'Invite in your personal room',
  invitePersonToMeeting: 'Invite new participant',
  inviteEmail: 'Invite by email',
  roomInfoTitlePersonal: `{user}'s personal room`,
  roomHowToInvitePersonal: `To invite someone to your personal room, send the following details:`,
  roomHowInvitePersonalClipBoardHeader: `Join my personal video room using the following details:`,
  roomHowInvitePersonalClipBoardWeb: `Web access (Chrome, Firefox o Safari)`,
  roomInfoTitleScheduled: `{user}'s scheduled meeting`,
  roomInfoName: 'Room name',
  roomInfoWebAccess: 'Web access',
  roomInfoAccessTitle: 'Join the room',
  roomInfoPersonalAccessTitle: 'Join my personal room',
  roomPublishStreamUrl: 'Live streaming access',
  roomInfoPstnAccess: 'Dial in access number',
  roomInfoPstnNotAvailable: 'Not available',
  roomInfoPstnNumber: '{number} (pin is {pin})',
  roomInfoScheduling: 'Schedule',
  roomInfoParticipants: 'Invited Participants',
  roomInfoMoreParticipants: '... other {otherAttendees}',
  inviteNewParticipantDialogTitle: 'Invite new participant to current meeting',
  inviteNewParticipantDialogContent:
    'Insert a valid email for every participant you wish to invite.',
  inviteNewParticipantDialogValidationError: 'Invalid email',

  dialPersonToMeeting: 'Add someone by phone',
  dialToMeeting: 'Call phone',
  dialToMeetingDialogTitle: 'Add someone by phone to current meeting',
  dialToMeetingDialogContent: 'Insert a valid phone number to call.',
  dialToMeetingValidatioError: 'Invalid phone number',
  dialParticipantSuccess: 'Call started successfully.',
  copyLink: 'Copy link',
  copyDetails: 'Copy details',

  startMeeting: 'Start date',
  endMeeting: 'End date',
  deleteButton: `Delete`,
  highLinkQuality: 'High upload speed',
  mediumLinkQuality: 'Average upload speed',
  lowLinkQuality: 'Low upload speed',
  computingLinkQuality: 'Computing upload speed',
  linkQualityNotAvailable: 'Upload speed not available',
  6005: 'Service unavailable, probably because of dialout limits.',
  6004: 'Unable to dial participant. Number may be wrong or not allowed.',
  6003: 'Unable to invite participants, please try again or contact the administrator if the problem persists',
  6000: 'Unable to invite participants, meeting has not started yet.',
  6001: 'Unable to invite participants, meeting expired.',
  6002: 'Unable to invite participants, meeting does not exists.',
  deleteMeetingError:
    'Unable to delete meeting, please try again or contact the administrator if the problem persists',
  unexpectedRoomError: 'An error occurred: {errorMessage}.',
  answerError: 'Error receiving media stream, probably a network error.',
  connectionClosedUnexpectedlyTitle: 'Connection closed',
  connectionClosedUnexpectedlyMessage: 'Connection to server lost. Please check your connectivity',
  rpcTimeoutError: 'Server operation timeout',
  meetingDuration: 'Meeting duration',
  meetingBriefDuration: '{time} {time, plural, one {second} other {seconds} }',
  meetingCommonDuration: '{time1} {time1, plural, one {minute} other {minutes}} {time2, plural, =0 {} ' +
    'other {and {time2 } {time2, plural, one {second} other {seconds}}}}',
  meetingLongDuration: '{time1} {time1, plural, one {hour} other {hours}} {time2, plural, =0 {} ' +
    'other {and {time2 } {time2, plural, one {minute} other {minutes}}}}',
  meetingVeryLongDuration: '{time1} {time1, plural, one {day} other {days}}{time2, plural, =0 {} other ' +
    '{, {time2 } {time2, plural, one {hour} other {hours}}}} {time3, plural, =0 {} ' +
    'other {and {time3 } {time3, plural, one {minute} other {minutes}}}}',
  muteAllButton: 'Mute audience',
  unMuteAllButton: 'Unmute audience',
  muteAllVideos: 'Stop receiving other videos',
  endMeetingDialogTitle: 'End meeting',
  endMeetingDialogContent: 'Are you sure you want to end the current meeting? All participants will be kicked.',
  meetingParticipantsSummary: 'Meeting participants: {totalUsers}',
  meetingParticipantsSummaryAudioOnly: ' ({audioOnlyUsers} audio only)',
  meetingParticipantsSummaryAudioOnlyMobile: ' ({icon}{audioOnlyUsers})',
  checkSettingsAndJoinTitle: 'Ready',
  checkSettingsAndJoinBody: 'Check audio/video settings and join',
  insertYourNameAndJoinTitle: 'What\'s your name?',
  insertYourNameAndJoinBody: 'Insert your name and check the audio/video settings before joining',
  joinWithVideo: 'Join with video',
  joinWithAudio: 'Join with audio initially enabled',
  joinWithBlur: 'Join with background blur active',
  playTestSound: 'Play test sound',
  stopTestSound: 'Stop test sound',
  raiseYourHand: 'Raise your hand',
  lowerYourHand: 'Lower your hand',
  otherOptions: 'Other options',
  webinarPresenterWithoutVideo: 'The presenter has no video stream',
  webinarPresenterAbsent: 'The presenter has left the room',
  canPublishVideoAgain: 'You can publish your video now. ' +
    'Enable it from the personal controls bar on the bottom.',
  leaveWaitingRoom: 'Leave waiting room',
  waitOwnerTitle: 'Owner not here yet',
  waitOwnerBody: 'Wait for the meeting to start.',
  roomLockedTitle: 'Meeting locked',
  roomLockedBody: 'Wait for someone to let you in.',
  ownerNotifiedTitle: 'Owner notified',
  ownerNotifiedBody: 'You will join the meeting automatically as soon as they arrive.',
  lockedJoinRequestTitle: 'Join Request',
  lockedJoinRequestsTitle: 'Join Requests',
  lockedJoinRequestMsg: "{dname} wants to enter the meeting",
  lockedJoinRequestDeniedTitle: 'Request denied',
  lockedJoinRequestDeniedBody: 'The owner has denied your join request.',
  acceptLockedJoinRequestButton: 'Admit',
  denyLockedJoinRequestButton: 'Deny entry',
  acceptAllLockedJoinRequestButton: 'Admit all',
  denyAllLockedJoinRequestButton: 'Deny all',
  showLockedJoinRequests: 'Show join requests',
  waitingRoomPrivacyBody: 'A preview from your camera might be taken to allow the moderator to identify you. ' +
    'Such screenshot will never be stored on our servers.',
  leaveMeetingDialogTitle: 'Leave Meeting',
  leaveMeetingDialogBody: 'Would you like to leave the meeting or terminate it? ' +
    'If you termine it all other participants will be kicked.',
  leaveMeetingDialogLeave: 'Leave',
  leaveMeetingDialogTerminate: 'Terminate',
  meetingIsExpiring: 'Meeting expiring',
  meetingWillExpireSoon: 'This meeting will expire soon',
  meetingWillExpireIn: `This meeting will expire in {time}`,
  errorInviting: 'Error inviting new participants',
  conferenceUpdate: `This room duration has been extended until {endDate}`,
  conferenceUpdateError: `There was an error trying to extend this meeting`,
  joinedPrivateConf: `{displayName} joined a private conversation`,
  youJoinedPrivateConf: `You joined a private conversation`,
  leftPrivateConf: `{displayName} left the private conversation`,
  youLeftPrivateConf: `You left the private conversation`,
  startPrivateAudioRequestFailed: `Error: cannot start private conversation`,
  stopPrivateAudioRequestFailed: `Error: cannot stop private conversation`
};

const screenShare = {
  shareScreen: 'Share screen',
  unShareScreen: 'Stop sharing',
  screenSharingDialogTitle: 'Screen sharing',
  screensThumbnailsListDialogTitle: 'Select screen',
  windowsThumbnailsListDialogTitle: 'Select window',
  screenSharingDialogContent: 'Select what to share',
  screenSharingDialogScreen: 'Screen',
  screenSharingDialogWindow: 'Window',
  screenSharingErrorTitle: 'Error sharing the screen',
  screenSharingErrorContent: 'Please be sure to allow browser permission when asked to',
  extensionErrorTitle: 'Extension not installed',
  extensionErrorContent:
    `An extension is required to enable desktop sharing\n` +
    `To install VoiSmart Screen Share extension:\n`,
  extensionErrorContentList:
    `1. click on the install button\n` +
    `2. add the extension to your browser\n` +
    `3. reload the page.`,
  extensionErrorButton: 'Cancel',
  extensionInstallButton: 'Install',
  extensionReloadButton: 'Reload',
  screenSharingUnsupportedTitle: 'Desktop sharing not supported',
  screenSharingUnsupportedMessage:
    `Your browser does not support Desktop Sharing.\n` +
    `Please try with Chrome, Firefox or Opera.`,
};

const browserCheck = {
  unsupportedBrowserTitle: 'Unsupported web browser',
  unsupportedBrowserVersionTitle: 'Unsupported web browser',
  supportedExperimentalTitle: 'Web browser not fully supported',
  unsupportedBrowserContent: 'Your web browser is not supported.',
  unsupportedBrowserVersionContent: 'Your browser is supported starting from version {version}. ' +
    'Update it and try reconnecting.',
  supportedExperimentalContent: 'Your browser support is still experimental. Some features will not work.',
  supportedBrowsers: 'Suggested browsers are Google Chrome (at least version {minChromeVersion}) ' +
    'and Mozilla Firefox (at least version {minFirefoxVersion}).',
  iosSupportedBrowsers: 'Please use Safari (at least version {minSafariVersion}).',
};

const tooltip = {
  meetingIconButtonTooltip: `Homepage`,
  loginIconButtonTooltip: 'Login',
  logoutIconButtonTooltip: 'Logout',
  settingsIconButtonTooltip: 'Settings',
  accountIconButtonTooltip: 'Account',
  changeUrlButtonTooltip: 'Change URL',
  screenShareButtonExtensionTooltipDownload: 'Download screen sharing extension',
  screenShareButtonExtensionTooltipIsNotSupported: 'Screen sharing is not supported',
  screenShareButtonExtensionTooltipIsSupported: 'Share screen',
  screenShareButtonExtensionTooltipDisable: 'Disable screen sharing',
  microphoneButtonEnableTooltip: 'Enable Microphone',
  microphoneButtonDisableTooltip: 'Disable Microphone',
  videoButtonEnableTooltip: 'Enable Video',
  videoButtonDisableTooltip: 'Disable Video',
  startPrivateConfTooltip: 'Start Private Audio',
  stopPrivateConfTooltip: 'Stop Private Audio',
  hangupIconButton: 'Hang up',
  microphoneDisabledTooltip: 'Muted',
  microphoneActiveTooltip: 'Active',
  allowToSpeak: 'Allow to speak',
  exitFullScreenButton: 'Exit Fullscreen',
  exitFullScreenMobile: 'Exit Fullscreen',
  enterFullScreenButton: 'Enter Fullscreen',
  enablePipButton: 'Enable mini video',
  disablePipButton: 'Disable mini video',
  enableDesktopControl: 'Enable Desktop Control',
  disableDesktopControl: 'Disable Desktop Control',
  chatIconButtonTooltip: 'Chat',
  ejectIconButtonTooltip: 'Kick',
  enlargeIconButtonTooltip: 'Enlarge',
  collapseIconButtonTooltip: 'Collapse',
  clearStateIconButtonTooltip: 'Clear state',
  foregroundIconButtonTooltip: 'Put in foreground',
  promoteRoleIconButtonTooltip: 'Promote to moderator',
  demoteRoleIconButtonTooltip: 'Demote moderator',
  presentationIconTooltip: 'Moderator',
  roomInfoTooltip: 'Room info',
  scheduleButtonTooltip: 'Schedule a meeting',
  joinTooltip: 'Join',
  editTooltip: 'Edit',
  moreActionTooltip: 'More actions',
  roomActionsMenuTooltip: 'Room actions',
  lockRoom: 'Lock room',
  unLockRoom: 'Unlock room',
  streamSwitchShowScreenTooltip: 'Show shared screen',
  streamSwitchShowStreamTooltip: 'Show user stream',
};

const notificationMessage = {
  joinMeeting: `{username} has joined the meeting`,
  exitMeeting: `{username} has left the meeting`,
  muted: 'You have been muted',
  unmuted: 'Your microphone has been activated',
  promotedToModerator: 'You have been promoted to moderator',
  demotedModerator: 'You are no longer a moderator',
  kicked: 'You have been kicked from the room',
  rosterMultipleChanges: `{joinedUsers} users have joined the meeting and {exitedUsers} users have left the meeting`,
  needFullscreenForControl: 'You need to manually start desktop share in fullscreen to enable desktop control',
  stopDesktopControl: 'Remote desktop control for this room has been disabled',
  startDrawing: 'Remote drawing for this room has started',
  startDrawingBrowser: 'Remote drawing for this room has started. You need to ' +
    'manually start desktop share in fullscreen to use it.',
  stopDrawing: 'Remote drawing for this room has been disabled',
  meetingEnded: 'The meeting has ended',
  joinOnLockedRoom: '{dname} could not enter the meeting because it is locked',
  raisedHand: '{dname} has raised the hand',
  lockedJoinRequestTitle: 'Join request',
  lockedJoinRequestBody: '{dname} has requested to join',
};

const layout = {
  standardLayout: 'Standard layout',
  featuredLayout: 'Presentation',
  chooseLayout: 'Choose your layout',
  applyLayout: 'Apply current layout to all participants',
  selectToMeLayout: 'Select for me',
  selectToAllLayout: 'Select for all participants',
  gridLayout: 'Grid',
  webinarLayout: 'Webinar',
  lessonLayout: 'Lesson',
  audioOnlyLayout: 'Audio only',
  fullscreenLayout: 'Fullscreen',
  presentationLayout: 'Presentation',
};

const recording = {
  menuRecording: 'Record video',
  menuRecordingAudio: 'Record audio',
  stopRecording: 'Stop video recording',
  stopRecordingAudio: 'Stop audio recording',
  startRecording: 'Start recording',
  reportRecording: 'Recordings list',
  reportTitleRecording: 'Title',
  reportStartRecording: 'Start',
  reportEndRecording: 'End',
  reportNoteRecording: 'Notes',
  reportSlugRecording: 'Room',
  reportPlayRecording: 'Play',
  reportTypeVideoRecording: 'Video',
  reportTypeAudioRecording: 'Audio',
  reportDeleteRecording: 'Delete',
  reportDurationRecording: "Duration",
  reportFilterTemporalRangeRecording: "Temporal Range",
  reportFilterApplyRecording: "Apply Filter",
  reportFilterAppliedRecording: "Filter Applied",
  reportLoadRecording: 'Load more recordings',
  reportUnablePlayRecording: 'Unable to play recording',
  reportUnableLoadRecording: 'Unable to load recordings',
  roomStartRecording: 'This room is being recorded.',
  roomStopRecording: 'Recording has been stopped.',
  reportDownloadRecording: 'Download file.',
  7001: 'All recorders are busy at the moment.',
  7002: 'Unauthorized method called.',
  7003: 'Generic error, check serverlogs.',
  7004: "Domain name is not valid for this service.",
  deleteRecordingError: 'Unable to delete recording, please try again or contact ' +
    'the administrator if the problem persists.',
  deleteRecordingSuccess: 'Recording deleted successfully',
  5004: 'Server timeout',
  defaultNotificationError: 'Unknown Error',
  roomIsBeingLiveStreamed: 'This room is being live streamed',
};

const liveStreaming = {
  menuStreaming: 'Streaming',
  roomStartStreaming: 'This room is streaming live',
  roomStopStreaming: 'Live streaming has been stopped',
  tooltipStartLiveStreaming: 'Start live streaming',
  tooltipStopLiveStreaming: 'Stop live streaming',
  streamingSettings: 'Live streaming settings',
};

const accountCmp = {
  accountCmpUser: 'User',
  accountCmpPins: 'Pins',
  accountCmpStreaming: 'Streaming',
};

const userTabCmp = {
  userTabCmpTitle: 'User profile'
};

const regenPinsTabCmp = {
  regenPinsTabCmpRegen: 'Renew pins',
  regenPinsTabCmpTitle: 'Personal Room Pins',
  regenPinsTabCmpPubPin: 'Public',
  regenPinsTabCmpPrivPin: 'Personal',
};

const liveStreamingCmp = {
  liveStreamingCmpTitle: 'Live streaming',
  liveStreamingCmpApiKey: 'Stream key',
  liveStreamingCmpShowApiKey: 'Show stream key',
  liveStreamingCmpApiUrl: 'Server URL',
  liveStreamingCmpPubUrl: 'Public live stream URL',
};

const errors = {
  regeneratePinsGenError: 'Error while updating pins.',
  getMyProfileError: 'Error while loading user profile.',
  updateProfileError: 'Error while updating profile settings.',
  errBoundaryHeader: 'Oops! Something went wrong.',
  errBoundaryMessage: `This page didn't load correctly. ` +
    `See the javascript console for details or try to reload the page.`
};

const success = {
  updateProfileSuccess: 'Profile saved successfully!',
  personalRoom: 'Personal room',
};


const roster = {
  findParticipants: 'Find participants',
  participantNumber: 'Participants {number}',
  filteredParticipantNumber: 'Participants {number}, {filtered} matching',
};


const chat = {
  chat: 'Chat',
  sendAMessage: 'Send a new message',
  noMessages: 'There are no messages yet for this meeting',
  userJoined: '{name} has joined',
  userLeft: '{name} has left',
  newPrivateChat: 'New private chat',
  selectUser: 'Select a user',
  noOtherUsers: 'No other users',
  everyone: 'Everyone',
  userOffline: 'User is offline',
  userInvalid: 'Invalid user',
  shareFileTooltip: 'Share file',
  shareFileDialogTitlePublic: 'Share file in public chat',
  shareFileDialogTitlePrivate: 'Share file in private chat',
  shareFileDescriptionPlaceholder: 'File description',
  sharedFileUploadError: 'An error occurred while trying to upload the file',
  sharedFileUploadErrorTooLarge: 'Error: file too large',
  sharedFileUploadErrorBadType: 'Error: unsupported file type',
  sharedFileDownloadError: 'An error occurred while trying to download the file',
  sharedFileUploadCancelled: 'File upload cancelled'
};


const meetingType = {
  typeLabel: 'Meeting type',
  typeRegular: 'Video Conference',
  typeAudioOnly: 'Audio Conference',
  typeWebinar: 'Webinar',
  typeLesson: 'Lesson'
};


const meetingPrivacy = {
  privacyLabel: 'Meeting privacy',
  privacyInfo: 'Set the privacy level for this meeting\n' +
    '\u00B7 Public: anyone with the link can automatically join the meeting as soon as the owner joins\n' +
    '\u00B7 Screened: anyone with the link can join the meeting once approved by a moderator\n' +
    '\u00B7 Internal: only authenticated users can join the meeting\n' +
    '\u00B7 Internal and screened: only authenticated users can join the meeting once approved by a moderator',
  privacyOpen: 'Public',
  privacyLocked: 'Screened',
  privacyLogged: 'Internal',
  privacyLockedLogged: 'Internal and screened',
};


const supportMenu = {
  manualUrlLabel: 'Manual',
  privacyUrlLabel: 'Privacy Policy',
  termsUrlLabel: 'Terms of Service',
  shortcutsLabel: 'Shortcuts',
  shortcutsDialogTitle: 'Shortcuts list',
  shortcutKeyLabel: 'Shortcut',
  shortcutDescriptionLabel: 'Description',
  shortcutMuteMicKey: 'M',
  shortcutMuteMicDescription: 'Mute/Unmute your microphone',
  shortcutMuteVideoKey: 'V',
  shortcutMuteVideoDescription: 'Mute/Unmute your camera',
  shortcutOpenRosterKey: 'R',
  shortcutOpenRosterDescription: 'Open/Close the roster',
  shortcutOpenChatKey: 'C',
  shortcutOpenChatDescription: 'Open/Close the chat',
  shortcutRaiseHandKey: 'H',
  shortcutRaiseHandDescription: 'Raise/Lower your hand',
  shortcutLockRoomKey: 'Shift + L',
  shortcutLockRoomDescription: 'Lock/Unlock the room',
  shortcutFullscreenKey: 'Double Click',
  shortcutFullscreenDescription: 'On a user\'s video or screen to view it in fullscreen',
  shortcutsFooter: 'Some shortcuts are available only to moderator'
};


export {
  generic,
  deviceConfiguration,
  login,
  logout,
  mediaPermissions,
  meeting,
  screenShare,
  browserCheck,
  liveStreaming,
  tooltip,
  notificationMessage,
  layout,
  recording,
  accountCmp,
  userTabCmp,
  regenPinsTabCmp,
  liveStreamingCmp,
  errors,
  success,
  roster,
  chat,
  meetingType,
  meetingPrivacy,
  supportMenu,
};
