import { AuthState } from "../reducers/auth";


export function isAuthReady(state: AuthState): boolean {
  switch (state.kind) {
    case 'AUTH_READY':
      return true;
    default:
      return false;
  }
}


export function getIsAuthenticated(state: AuthState): boolean {
  switch (state.kind) {
    case 'AUTH_READY':
      return state.isAuthenticated;
    default:
      return false;
  }
}


export function getIsAuthenticatedAsGuest(state: AuthState): boolean {
  switch (state.kind) {
    case 'AUTH_READY':
      return state.isAuthenticatedAsGuest;
    default:
      return false;
  }
}


export function getToken(state: AuthState): string | null {
  switch (state.kind) {
    case 'AUTH_READY':
      return state.token;
    default:
      return null;
  }
}


export function getIsFetching(state: AuthState): boolean {
  switch (state.kind) {
    case 'AUTH_READY':
      return state.isFetching;
    default:
      return false;
  }
}
