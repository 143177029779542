import { Dispatch } from 'redux';

import LocalStorage from '../../localStorage';
import { VideoQuality, FacingMode } from '../redux_types';
import { assign } from '../utils/object';
import { BlurTypeCustomValue } from '../utils/pipelineDefault';


export const TOGGLE_DEVICE_SETTINGS = 'SETTINGS::TOGGLE_DEVICES';
export const VIDEO_DEVICE_SETTINGS = 'SETTINGS::VIDEO_DEVICE';
export const VIDEO_FACING_MODE_SETTINGS = 'SETTINGS::VIDEO_SETTINGS_MODE';
export const VIDEO_QUALITY_SETTINGS = 'SETTINGS::VIDEO_QUALITY';
export const AUDIOIN_DEVICE_SETTINGS = 'SETTINGS::AUDIOIN_DEVICE';
export const AUDIOOUT_DEVICE_SETTINGS = 'SETTINGS::AUDIOOUT_DEVICE';

export const TOGGLE_BACKGROUND_SETTINGS = 'SETTINGS::TOGGLE_BACKGROUND_SETTINGS';
export const BACKGROUND_BLUR_ENABLED_SETTINGS = 'SETTINGS::BACKGROUND_BLUR_ENABLED';
export const BACKGROUND_BLUR_VALUE_SETTINGS = 'SETTINGS::BACKGROUND_BLUR_VALUE';
export const BACKGROUND_BLUR_CUSTOM_IMAGE = 'SETTINGS::BACKGROUND_BLUR_CUSTOM_IMAGE';
export const BEAUTIFY_EFFECT_ENABLED_SETTINGS = 'SETTINGS::BEAUTIFY_EFFECT_ENABLED';

export const SET_MEDIA_PERMISSIONS_GRANTED = 'SETTINGS::SET_MEDIA_PERMISSIONS_GRANTED';

export const VIDEO_ENABLED = 'VIDEO_ENABLED';
export const AUDIO_JOIN_MUTED = 'AUDIO_JOIN_MUTED';
export const SET_IS_ELECTRON = 'SET_IS_ELECTRON';

interface ToggleDeviceSettingsAction {
  type: typeof TOGGLE_DEVICE_SETTINGS;
  payload: {};
}

interface VideoDeviceSettingsAction {
  type: typeof VIDEO_DEVICE_SETTINGS;
  payload: { videoDevice: MediaDeviceInfo | undefined };
}

interface VideoFacingModeSettingsAction {
  type: typeof VIDEO_FACING_MODE_SETTINGS;
  payload: { videoFacingMode: FacingMode };
}

interface VideoQualitySettingsAction {
  type: typeof VIDEO_QUALITY_SETTINGS;
  payload: { videoQuality: VideoQuality };
}

interface AudioInDeviceSettingsAction {
  type: typeof AUDIOIN_DEVICE_SETTINGS;
  payload: { audioInDevice: MediaDeviceInfo | undefined};
}

interface AudioOutDeviceSettingsAction {
  type: typeof AUDIOOUT_DEVICE_SETTINGS;
  payload: { audioOutDevice: MediaDeviceInfo };
}

interface ToggleBackgroundSettingsAction {
  type: typeof TOGGLE_BACKGROUND_SETTINGS;
  payload: { };
}

interface BackgroundBlurEnabledSettingsAction{
  type: typeof BACKGROUND_BLUR_ENABLED_SETTINGS;
  payload: { backgroundBlurEnabled: boolean };
}

interface BackgroundBlurValueSettingsAction {
  type: typeof BACKGROUND_BLUR_VALUE_SETTINGS;
  payload: { backgroundBlurValue: string | number };
}

interface BackgroundBlurCustomImageAction {
  type: typeof BACKGROUND_BLUR_CUSTOM_IMAGE;
  payload: { [key: string]: string | undefined };
}

interface BeautifyEffectEnabledSettingsAction {
  type: typeof BEAUTIFY_EFFECT_ENABLED_SETTINGS;
  payload: { beautifyEffectEnabled: boolean };
}

interface VideoEnabledAction {
  type: typeof VIDEO_ENABLED;
  payload: { videoEnabled: boolean };
}

interface AudioJoinMutedAction {
  type: typeof AUDIO_JOIN_MUTED;
  payload: { audioJoinMuted: boolean };
}

interface SetIsElectronAction {
  type: typeof SET_IS_ELECTRON;
  payload: { isElectron: boolean };
}

interface SetMediaPermissionsGrantedAction {
  type: typeof SET_MEDIA_PERMISSIONS_GRANTED;
  payload: {
    value: boolean;
  };
}

export type Action =
  ToggleDeviceSettingsAction
  | VideoDeviceSettingsAction
  | VideoFacingModeSettingsAction
  | VideoQualitySettingsAction
  | AudioInDeviceSettingsAction
  | AudioOutDeviceSettingsAction
  | ToggleBackgroundSettingsAction
  | BackgroundBlurEnabledSettingsAction
  | BackgroundBlurValueSettingsAction
  | BackgroundBlurCustomImageAction
  | BeautifyEffectEnabledSettingsAction
  | VideoEnabledAction
  | AudioJoinMutedAction
  | SetIsElectronAction
  | SetMediaPermissionsGrantedAction
;

export function videoDeviceSettings(config: MediaDeviceInfo | undefined): VideoDeviceSettingsAction {
  return {
    type: VIDEO_DEVICE_SETTINGS,
    payload: { videoDevice: config, },
  };
}

export function videoFacingModeSettings(config: FacingMode): VideoFacingModeSettingsAction {
  return {
    type: VIDEO_FACING_MODE_SETTINGS,
    payload: { videoFacingMode: config, },
  };
}

export function videoEnabled(config: boolean): VideoEnabledAction {
  return {
    type: VIDEO_ENABLED,
    payload: { videoEnabled: config, },
  };
}

export function audioJoinMuted(config: boolean): AudioJoinMutedAction {
  return {
    type: AUDIO_JOIN_MUTED,
    payload: { audioJoinMuted: config, },
  };
}

export function videoQualitySettings(config: VideoQuality): VideoQualitySettingsAction {
  return {
    type: VIDEO_QUALITY_SETTINGS,
    payload: { videoQuality: config, },
  };
}

export function audioInDeviceSettings(config: MediaDeviceInfo | undefined): AudioInDeviceSettingsAction {
  return {
    type: AUDIOIN_DEVICE_SETTINGS,
    payload: { audioInDevice: config, },
  };
}

export function audioOutDeviceSettings(config: MediaDeviceInfo): AudioOutDeviceSettingsAction {
  return {
    type: AUDIOOUT_DEVICE_SETTINGS,
    payload: { audioOutDevice: config, },
  };
}

export function setIsElectron(isElectron: boolean): SetIsElectronAction {
  return {
    type: SET_IS_ELECTRON,
    payload: { isElectron: isElectron, },
  };
}

export function backgroundBlurCustomImage(
  key: BlurTypeCustomValue,
  value: string | undefined
): BackgroundBlurCustomImageAction {
  return {
    type: BACKGROUND_BLUR_CUSTOM_IMAGE,
    payload: { [key]: value },
  };
}

export function backgroundBlurEnabledSettings(enabled: boolean): BackgroundBlurEnabledSettingsAction {
  return {
    type: BACKGROUND_BLUR_ENABLED_SETTINGS,
    payload: { backgroundBlurEnabled: enabled },
  };
}

export function backgroundBlurValueSettings(value: string | number): BackgroundBlurValueSettingsAction {
  return {
    type: BACKGROUND_BLUR_VALUE_SETTINGS,
    payload: { backgroundBlurValue: value },
  };
}

export function beautifyEffectEnabledSettings(enabled: boolean): BeautifyEffectEnabledSettingsAction {
  return {
    type: BEAUTIFY_EFFECT_ENABLED_SETTINGS,
    payload: { beautifyEffectEnabled: enabled },
  };
}

function _saveBackgroundAction(dispatch: Dispatch, action: Action, storage: LocalStorage) {
  const oldconfig = storage.loadBackgroundConfig();
  const newconfig = assign(oldconfig, action.payload);
  storage.saveBackgroundConfig(newconfig);
  dispatch(action);
}

function _saveMediaAction(dispatch: Dispatch, action: Action, storage: LocalStorage) {
  const oldconfig = storage.loadMediaConfig();
  if (action.type === VIDEO_FACING_MODE_SETTINGS) {
    // on mobile there could be some device previously saved, in that case it has to be removed
    if ('videoDevice' in oldconfig) delete oldconfig['videoDevice'];
  }
  const newconfig = assign(oldconfig, action.payload);
  storage.saveMediaConfig(newconfig);
  dispatch(action);
}


export function toggleDeviceSettings() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: TOGGLE_DEVICE_SETTINGS,
      payload: {}
    });
  };
}

export function toggleBackgroundSettings() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: TOGGLE_BACKGROUND_SETTINGS,
      payload: {}
    });
  };
}

export function saveVideoEnabled(enabled: boolean, configStorage: LocalStorage) {
  return (dispatch: Dispatch) => {
    const action = videoEnabled(enabled);
    return _saveMediaAction(dispatch, action, configStorage);
  };
}

export function saveVideoFacingMode(config: FacingMode, configStorage: LocalStorage) {
  return (dispatch: Dispatch) => {
    const action = videoFacingModeSettings(config);
    return _saveMediaAction(dispatch, action, configStorage);
  };
}

export function saveAudioJoinMuted(enabled: boolean, configStorage: LocalStorage) {
  return (dispatch: Dispatch) => {
    const action = audioJoinMuted(enabled);
    return _saveMediaAction(dispatch, action, configStorage);
  };
}

export function saveVideoConfig(config: MediaDeviceInfo | undefined, configStorage: LocalStorage) {
  return (dispatch: Dispatch) => {
    const action = videoDeviceSettings(config);
    return _saveMediaAction(dispatch, action, configStorage);
  };
}

export function saveVideoQuality(config: VideoQuality, configStorage: LocalStorage) {
  return (dispatch: Dispatch) => {
    const action = videoQualitySettings(config);
    return _saveMediaAction(dispatch, action, configStorage);
  };
}

export function saveAudioInConfig(config: MediaDeviceInfo | undefined, configStorage: LocalStorage) {
  return (dispatch: Dispatch) => {
    const action = audioInDeviceSettings(config);
    return _saveMediaAction(dispatch, action, configStorage);
  };
}

export function saveAudioOutConfig(config: MediaDeviceInfo, configStorage: LocalStorage) {
  return (dispatch: Dispatch) => {
    const action = audioOutDeviceSettings(config);
    return _saveMediaAction(dispatch, action, configStorage);
  };
}

export function saveBackgroundBlurCustomBackgroundConfig(
  config: string | undefined,
  configStorage: LocalStorage,
  key: BlurTypeCustomValue
){
  return (dispatch: Dispatch) => {
    const action = backgroundBlurCustomImage(key, config);
    return _saveBackgroundAction(dispatch, action, configStorage);
  };
}
export function saveBackgroundBlurEnabledConfig(config: boolean, configStorage: LocalStorage){
  return (dispatch: Dispatch) => {
    const action = backgroundBlurEnabledSettings(config);
    return _saveBackgroundAction(dispatch, action, configStorage);
  };
}

export function saveBackgroundBlurValueConfig(config: number | string, configStorage: LocalStorage){
  return (dispatch: Dispatch) => {
    const action = backgroundBlurValueSettings(config);
    return _saveBackgroundAction(dispatch, action, configStorage);
  };
}

export function saveBeautifyEffectEnabledConfig(config: boolean, configStorage: LocalStorage){
  return (dispatch: Dispatch) => {
    const action = beautifyEffectEnabledSettings(config);
    return _saveBackgroundAction(dispatch, action, configStorage);
  };
}

export function setMediaPermissionsGranted(value: boolean): SetMediaPermissionsGrantedAction {
  return {
    type: SET_MEDIA_PERMISSIONS_GRANTED,
    payload: { value },
  };
}
