import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { WithAuthorization } from '../../../authorization';
import { iconColors as colors } from '../../../colors';
import { togglePrivateAudio } from '../../../lib/actions/room';
import { logEvent, Event } from '../../../lib/analytics';
import { UserActionsContext } from '../../../lib/contexts';
import { IconPrivateConf, IconPrivateConfOff } from '../../IconSet';

import { mapStateToProps, MappedProps } from './mapState';


const messages = defineMessages({
  startPrivateConfTooltip: { id: 'startPrivateConfTooltip' },
  stopPrivateConfTooltip: { id: 'stopPrivateConfTooltip' },
});

function PrivateAudioButton(props: ExtendedProps) {

  const {
    mySelf,
    uid,
    iconSize = 20,
    iconColor = colors.normal,
    isScreen,
    amInPrivateAudio,
    isInPrivateAudio,
    requestingPrivateAudio,
    onClose,
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const userActionsContext = React.useContext(UserActionsContext);

  const enabled = !requestingPrivateAudio && (!mySelf || amInPrivateAudio)
    && !isScreen && (amInPrivateAudio === isInPrivateAudio);

  const togglePrivate = React.useCallback(
    () => {
      if (enabled) {
        dispatch(togglePrivateAudio(uid, amInPrivateAudio));
        const action = amInPrivateAudio ? 'stop' : 'start';
        logEvent(Event.START_PRIVATE_CONFERENCE, { 'action': action, 'from': userActionsContext });
        onClose();
      }
    }
    , [dispatch, onClose, uid, enabled, amInPrivateAudio, userActionsContext]
  );

  const msg = formatMessage(
    (amInPrivateAudio) ? messages.stopPrivateConfTooltip : messages.startPrivateConfTooltip
  );

  if (!enabled) {
    return null;
  }

  return (
    <MenuItem onClick={togglePrivate}>
      <ListItemIcon>
        {amInPrivateAudio ?
          <IconPrivateConfOff color={colors.inactive} size={iconSize} />
          :
          <IconPrivateConf color={iconColor} size={iconSize} />
        }
      </ListItemIcon>
      <ListItemText primary={msg} />
    </MenuItem>
  );
}

type Props = {
  uid: string;
  iconSize?: number;
  iconColor?: string;
  mini?: boolean;
  onClose: () => void;
}


type ExtendedProps = Props & MappedProps


const roles = ['room_owner', 'room_moderator'];

export default WithAuthorization(roles, connect(mapStateToProps)(PrivateAudioButton));
