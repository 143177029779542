import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import Typography from '@material-ui/core/Typography';

import Avatar from '../Avatar';

import { ExtendedMeeting } from '.';

import useStyles from './style';


const messages = defineMessages({
  titlePersonal: { id: 'roomInfoTitlePersonal' },
  titleScheduled: { id: 'roomInfoTitleScheduled' },
});


type Props = {
  roomDetails: ExtendedMeeting;
}


function RoomInfoHeader(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const getRoomTitle = (room: ExtendedMeeting) => {
    const dname = { user: room.organizer.display };
    if (room.room_type === 'personal') {
      return formatMessage(messages.titlePersonal, dname);
    }
    else {
      return formatMessage(messages.titleScheduled, dname);
    }
  };

  return (
    <div>
      {(props.roomDetails.organizer.display) ?
        <Avatar
          className={classes.avatar}
          displayName={props.roomDetails.organizer.display}
          uid={props.roomDetails.organizer.uid}
        />
        : null}
      <Typography variant='h5' align='center' color='primary'>
        {getRoomTitle(props.roomDetails)}
      </Typography>
    </div>
  );
}


export default RoomInfoHeader;