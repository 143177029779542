import { isMobileOnly } from 'react-device-detect';


const minVideoSizePx = 250;

/**
 * Video dimensions utilities
 */
const shouldUseMiniToolbar = (size?: number | null): boolean => {
  return isMobileOnly || (size ? size < minVideoSizePx : false);
};

export { shouldUseMiniToolbar };