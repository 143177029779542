import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    webAccessContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    title: {
      paddingTop: 25,
      color: theme.palette.text.secondary,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1em',
    },

    content: {
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },

    linkContent: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    participants: {
      listStyleType: 'none',
      paddingLeft: '10px',
      margin: 0,
    },

    otherParticipants: {
      textAlign: 'right',
    },

    iconContainer: {
      display: 'flex',
      //padding: 0,
    },

    roomInfo: {
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
        overflowY: 'auto',
        height: '100%',
        margin: 0,
      },
      margin: '10px',
      maxWidth: '300px',
    //   position: 'relative',
    //   background: '#88b7d5',
    //   marginRight: '10px',
    //   border: '4px solid #c2e1f5',
    //   '&:before': {
    //     left: '100%',
    //     top: '50%',
    //     border: 'solid transparent',
    //     content: '" "',
    //     height: 0,
    //     width: 0,
    //     position: 'absolute',
    //     pointerEvents: 'none',
    //     borderColor: 'rgba(194, 225, 245, 0)',
    //     borderLeftColor: '#c2e1f5',
    //     borderWidth: '10px',
    //     marginTop: '-10px',
    //   },
    //   '&:after': {
    //     left: '100%',
    //     top: '50%',
    //     border: 'solid transparent',
    //     content: '" "',
    //     height: 0,
    //     width: 0,
    //     position: 'absolute',
    //     pointerEvents: 'none',
    //     borderColor: 'rgba(136, 183, 213, 0)',
    //     borderLeftColor: '#88b7d5',
    //     borderWidth: '8px',
    //     marginTop: '-8px',
    //   }
    },
    avatar: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
    }
  })
);

export default useStyles;
