import { commitMutation } from 'react-relay';

import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';

import {
  MeetingInput,
  scheduleMeetingMutation,
  scheduleMeetingMutationResponse
} from './__generated__/scheduleMeetingMutation.graphql';
import { handleSchedulingError } from './utils/errors';


const mutation = graphql`
mutation scheduleMeetingMutation(
  $input: ScheduleMeetingInput!
) {
  scheduleMeeting(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title
      url
      slug
      number
    }
  }
}
`;


function maybeNotifyError(response: scheduleMeetingMutationResponse | null) {
  if (!response || !response.scheduleMeeting || response.scheduleMeeting.errors !== null) {
    handleSchedulingError(response ? response.scheduleMeeting : null);
  }
}


export type MeetingParams = {
  title: MeetingInput['title'];
  userId: MeetingInput['userId'];
  domainId: MeetingInput['domainId'];
  dtStart: MeetingInput['dtStart'];
  dtEnd: MeetingInput['dtEnd'];
  users: { value: string[] };
  notes: MeetingInput['notes'];
  type: MeetingInput['type'];
  meta: MeetingInput['meta'];
}


function scheduleMeeting(
  environment: IEnvironment,
  { title, userId, domainId, dtStart, dtEnd, users, notes, type, meta }: MeetingParams
) {
  const attendees = users.value.map((el: string) => {
    return ({ email: el });
  });
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          title,
          userId,
          domainId,
          dtStart,
          dtEnd,
          attendees,
          notes,
          type,
          meta
        }
      },
    };
    commitMutation<scheduleMeetingMutation>(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { scheduleMeeting };
