import { State } from '../../../lib/reducers';
import { isVideoEnabledForUser } from '../../../lib/reduxSelectors/videoRoom';
import { getWsUserId } from '../../../lib/reduxSelectors/websocket';


export type MappedProps = {
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  amModerator: boolean;
  isModerator: boolean;
  mySelf: boolean;
  myScreen: boolean;
  hasVideoStream: boolean;
  displayName: string;
  isScreen: boolean;
  canPublishStream: boolean;
  hasRaisedHand: boolean;
  amInPrivateAudio: string | null;
  isInPrivateAudio: string | null;
  requestingPrivateAudio: boolean;
}


export function mapStateToProps(state: State, { uid }: { uid: string }): MappedProps {

  let user = null;
  let myUser = null;

  const myUid = getWsUserId(state.websocket);

  if (uid) {
    user = state.room.roster[uid.replace(/_screen$/, '')];
  }

  if (myUid) {
    myUser = state.room.roster[myUid];
  }

  const mySelf = myUid === uid;

  const myScreen = uid === `${myUid}_screen`;

  const isScreen = uid.endsWith('_screen');

  if (user && myUser) {
    return {
      isAudioMuted: user.isAudioMuted,
      isVideoMuted: isScreen ? user.isScreenMuted : user.isVideoMuted,
      mySelf: mySelf,
      myScreen,
      hasVideoStream: isVideoEnabledForUser(uid, state),
      amModerator: ['room_owner', 'room_moderator'].includes(myUser.role),
      isModerator: ['room_owner', 'room_moderator'].includes(user.role),
      displayName: user.display,
      isScreen,
      canPublishStream: state.room.mediaPermissions.canPublishVideo,
      hasRaisedHand: user.raisedHand,
      amInPrivateAudio: myUser.privateAudioConf,
      isInPrivateAudio: user.privateAudioConf,
      requestingPrivateAudio: state.room.requestingPrivateAudio,
    };
  }
  else {
    return {
      isAudioMuted: false,
      isVideoMuted: false,
      amModerator: false,
      isModerator: false,
      hasVideoStream: false,
      mySelf: false,
      myScreen,
      displayName: '',
      isScreen,
      canPublishStream: false,
      hasRaisedHand: false,
      amInPrivateAudio: null,
      isInPrivateAudio: null,
      requestingPrivateAudio: false,
    };
  }
}
