import { defineMessages } from 'react-intl';


const messages = defineMessages({
  leaveMeeting: { id: 'leave' },
  enable: { id: 'enable' },
  disable: { id: 'disable' },
  invite: { id: 'invite' },
  shareScreen: { id: 'shareScreen' },
  unShareScreen: { id: 'unShareScreen' },
  manageYourControlsHere: { id: 'manageYourControlsHere' },
  enableDesktopControl: { id: 'enableDesktopControl' },
  disableDesktopControl: { id: 'disableDesktopControl' },
  raiseHand: { id: 'raiseYourHand' },
  lowerHand: { id: 'lowerYourHand' },
  stopPrivateConfTooltip: { id: 'stopPrivateConfTooltip' },
  otherOptions: { id: 'otherOptions' },
  backgroundSettings: { id: 'backgroundSettings' },
  noAudioInputAvailable: { id: 'noAudioInputAvailable' },
});


export default messages;
