import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';
import URI from 'urijs';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import IntlPropType from '../../intl';
import { loginUser } from '../../lib/actions/auth';
import getRouteFor, { Route } from '../../lib/utils/router';
import { IconEye, IconEyeClosed } from '../IconSet';
import LoginPage from '../LoginPage';

import style from './style';


const messages = defineMessages({
  ok: { id: 'ok' },
  cancel: { id: 'cancel' },
  username: { id: 'username' },
  password: { id: 'password' },
  login: { id: 'login' },
  joinByCode: { id: 'joinByCode' },
  loginUsingAccount: { id: 'loginUsingAccount' },
  disconnectedMessage: { id: 'disconnectedMessage' },
  errorLoginCode401: { id: 'errorLoginCode401' }
});


class StandardLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitEnabled: false,
      error: false,
      errorType: null,
      showPassword: false,
    };
  }

  componentDidUpdate(prevProps) {
    const errorCode = this.props.errorCode;
    if (errorCode && errorCode !== prevProps.errorCode) {
      if (errorCode === 401) {
        this.setState({ error: true, password: '', errorCode: errorCode });
      } else {
        this.setState({ error: true, errorCode: errorCode });
      }
    }
  }

  handleLogin = () => {
    const creds = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.dispatch(loginUser(creds, this.props.localStore));
  }

  handleCancel = () => {
    const path = getRouteFor(Route.Home);
    this.props.history.push(path);
  }

  handleUsernameChange = (ev) => {
    this.setState({
      username: ev.target.value,
      submitEnabled: ev.target.value && this.state.password,
    });
  }

  handlePasswordChange = (ev) => {
    this.setState({
      password: ev.target.value,
      submitEnabled: ev.target.value && this.state.username,
    });
  }

  handleKeyUp = (ev) => {
    if ((ev.key === 'Enter') && (this.state.submitEnabled)) {
      this.handleLogin();
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  gotoHome = () => {
    this.props.history.push(getRouteFor(Route.Home));
  }

  render() {
    if (this.props.isAuthenticated) {
      let redirectTo = getRouteFor(Route.Home);
      const qs = new URI(this.props.history.location.search);
      const goTo = qs.search(true).redirectTo;
      if (goTo) {
        redirectTo = getRouteFor(Route.Meeting, { slug: goTo });
      }
      return (
        <Redirect to={{
          pathname: redirectTo
        }} />
      );
    }
    const { classes, intl } = this.props;

    return (
      <LoginPage>
        {intl.formatMessage(messages.loginUsingAccount)}

        <form>
          <FormControl className={classes.fullWidth}>
            <TextField
              inputProps={{ autoCapitalize: 'off', className: classes.inputField }}
              autoFocus={true}
              fullWidth
              className={classes.input}
              placeholder={intl.formatMessage(messages.username)}
              onChange={this.handleUsernameChange}
              onKeyUp={this.handleKeyUp}
              error={this.state.error}
            />
            {this.state.error && (this.state.errorCode === 401) &&
              <FormHelperText className={classes.formHelperText} error>
                {this.props.intl.formatMessage(messages.errorLoginCode401)}
              </FormHelperText>
            }
          </FormControl>

          <FormControl className={classes.fullWidth}>
            <TextField
              className={classes.input}
              type={this.state.showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              inputProps={{ autoCapitalize: 'off', className: classes.inputField }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPasssword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <IconEyeClosed size={24} /> : <IconEye size={24} />}
                    </IconButton>
                  </InputAdornment>,
              }}
              fullWidth
              placeholder={intl.formatMessage(messages.password)}
              onChange={this.handlePasswordChange}
              onKeyUp={this.handleKeyUp}
              error={this.state.error}
              value={this.state.password}
            />
          </FormControl>
        </form>

        <div className={classes.dialogActions}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.loginButton}
            onClick={this.handleLogin}
            disabled={!this.state.submitEnabled}
          >
            {intl.formatMessage(messages.login)}
          </Button>
        </div>

        <div className={classes.codeMessage}>
          <Link
            component="button"
            variant="body2"
            onClick={this.gotoHome}
          >
            {intl.formatMessage(messages.joinByCode)}
          </Link>
        </div>
      </LoginPage>
    );
  }
}


StandardLogin.propTypes = {
  intl: IntlPropType.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
};


function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errorCode: state.auth.errorCode
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(StandardLogin)));
