const style = theme => ({
  formControl: {
    marginTop: theme.spacing(),
    width: '100%',
    paddingRight: theme.spacing(),
  },
  listAttendees: {
    wordBreak: "break-all"
  },
  formError: {
    color: theme.palette.error.main,
    margin: theme.spacing()
  },
  dialogContent: {
    marginTop: theme.spacing(6) * -1,
  },
  dialogActions: {
    margin: theme.spacing(1),
  }
});

export default style;
