import { ThunkAction } from 'redux-thunk';
import { IEnvironment } from 'relay-runtime';

import { deleteSubscription } from '../../lib/api/relay/deleteSubscription';
import { storeSubscription } from '../../lib/api/relay/storeSubscription';
import { getLogger } from '../../lib/logger';
import LocalStorage from '../../localStorage';
import {
  initPushNotifications,
  subscribe,
  pushNotificationsEnabled,
  pushNotificationsAvailable
} from '../../pushNotifications/pushNotifications';
import { HoloPushSub, PushNotificationsStatus, unsubscribe } from '../../pushNotifications/pushNotifications';
import { PushSubscriptionLocale } from '../api/relay/__generated__/storeSubscription.graphql';
import { newEvent, WARNING, ERROR } from '../notifications';
import { State } from '../redux_types';
import { UUID, LockedJoinRequest, NotificationEvent } from '../redux_types';

import { PushNotificationsConfig } from './appconfig';


export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const ADD_LOCKED_JOIN_REQUEST = 'ADD_LOCKED_JOIN_REQUEST';
export const SET_LOCKED_JOIN_REQUESTS = 'SET_LOCKED_JOIN_REQUESTS';
export const REMOVE_LOCKED_JOIN_REQUEST = 'REMOVE_LOCKED_JOIN_REQUEST';
export const CLEAR_LOCKED_JOIN_REQUESTS = 'CLEAR_LOCKED_JOIN_REQUESTS';
export const SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED = 'SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED';
export const SET_PUSH_SUBSCRIPTION = 'SET_PUSH_SUBSCRIPTION';
export const SET_PUSH_NOTIFICATIONS_AVAILABLE = 'SET_PUSH_NOTIFICATIONS_AVAILABLE';
export const SET_IS_SUBSCRIBING = 'SET_IS_SUBSCRIBING';

interface AddNotificationAction {
  type: typeof ADD_NOTIFICATION;
  payload: {
    ref: UUID;
    notification: NotificationEvent;
  };
}

interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION;
  payload: {
    ref: UUID;
  };
}

interface AddLockedJoinRequestAction {
  type: typeof ADD_LOCKED_JOIN_REQUEST;
  payload: {
    requests: LockedJoinRequest[];
  };
}

interface SetLockedJoinRequestsAction {
  type: typeof SET_LOCKED_JOIN_REQUESTS;
  payload: {
    requests: LockedJoinRequest[];
  };
}

interface RemoveLockedJoinRequestAction {
  type: typeof REMOVE_LOCKED_JOIN_REQUEST;
  payload: {
    reqId: string;
  };
}

interface ClearLockedJoinRequestsAction {
  type: typeof CLEAR_LOCKED_JOIN_REQUESTS;
  payload: {
  };
}

interface SetLockedJoinRequestDialogMinimizedAction {
  type: typeof SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED;
  payload: {
    minimized: boolean;
  };
}

interface SetPushSubscriptionAction {
  type: typeof SET_PUSH_SUBSCRIPTION;
  payload: {
    subscription: HoloPushSub | null;
  };
}

interface SetPushNotificationsAvailableAction {
  type: typeof SET_PUSH_NOTIFICATIONS_AVAILABLE;
  payload: {
    available: boolean;
  };
}

interface SetIsSubscribingAction {
  type: typeof SET_IS_SUBSCRIBING;
  payload: {
    isSubscribing: boolean;
  };
}

export type Action =
  AddNotificationAction
  | RemoveNotificationAction
  | AddLockedJoinRequestAction
  | SetLockedJoinRequestsAction
  | RemoveLockedJoinRequestAction
  | ClearLockedJoinRequestsAction
  | SetLockedJoinRequestDialogMinimizedAction
  | SetPushSubscriptionAction
  | SetPushNotificationsAvailableAction
  | SetIsSubscribingAction;

export function addNotification(reference: UUID, event: NotificationEvent): AddNotificationAction {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      ref: reference,
      notification: event
    }
  };
}

export function removeNotification(reference: UUID): RemoveNotificationAction {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      ref: reference
    }
  };
}

export function addLockedJoinRequests(requests: LockedJoinRequest[]): AddLockedJoinRequestAction {
  return {
    type: ADD_LOCKED_JOIN_REQUEST,
    payload: {
      requests: requests
    }
  };
}

export function setLockedJoinRequests(requests: LockedJoinRequest[]): SetLockedJoinRequestsAction {
  return {
    type: SET_LOCKED_JOIN_REQUESTS,
    payload: {
      requests: requests
    }
  };
}

export function removeLockedJoinRequest(reqId: string): RemoveLockedJoinRequestAction {
  return {
    type: REMOVE_LOCKED_JOIN_REQUEST,
    payload: {
      reqId: reqId
    }
  };
}

export function clearLockedJoinRequests(): ClearLockedJoinRequestsAction {
  return {
    type: CLEAR_LOCKED_JOIN_REQUESTS,
    payload: {
    }
  };
}

export function setLockedJoinRequestDialogMinimized(minimized: boolean): SetLockedJoinRequestDialogMinimizedAction {
  return {
    type: SET_LOCKED_JOIN_REQUEST_DIALOG_MINIMIZED,
    payload: {
      minimized: minimized
    }
  };
}

function setPushSubscription(subscription: HoloPushSub | null): SetPushSubscriptionAction {
  return {
    type: SET_PUSH_SUBSCRIPTION,
    payload: {
      subscription: subscription
    }
  };
}

function setPushNotificationsAvailable(available: boolean): SetPushNotificationsAvailableAction {
  return {
    type: SET_PUSH_NOTIFICATIONS_AVAILABLE,
    payload: {
      available
    }
  };
}

function setIsSubscribing(isSubscribing: boolean): SetIsSubscribingAction {
  return {
    type: SET_IS_SUBSCRIBING,
    payload: {
      isSubscribing
    }
  };
}

type NotificationsThunkAction = ThunkAction<void, State, null, Action>

export function setupPushNotifications(
  conf: PushNotificationsConfig,
  authToken: string,
  locale: PushSubscriptionLocale,
  localStore: LocalStorage,
  relay: IEnvironment,
): NotificationsThunkAction {
  return (dispatch, _getState) => {
    const logger = getLogger('pushNotifications');

    initPushNotifications(conf, localStore)
      .then(
        (status: PushNotificationsStatus) => {
          if (!status.available) {
            logger.info("push notifications not available on this device");
            dispatch(setPushNotificationsAvailable(false));
          } else {
            logger.info("push notifications subsystem started");
            dispatch(setPushNotificationsAvailable(true));
          }

          if (status.sub && status.valid) {
            logger.info("valid push subscription found in local storage, storing it on server");
            dispatch(setIsSubscribing(true));
            storePushSubscriptionOnServer(locale, relay)(status.sub)
              .then((sub) => {
                dispatch(setPushSubscription(sub));
                dispatch(setIsSubscribing(false));
              })
              .catch(() => { dispatch(setIsSubscribing(false)); });
          } else if (status.sub && !status.valid) {
            logger.info("an invalid subscription was found in the local storage, deleting it from server");
            const sub = status.sub;
            unsubscribe(conf)
              .catch(() => { })
              .then(() => { deletePushSubscriptionFromServer(relay, sub); })
              .then(() => {
                localStore.clearPushSubscription();
                // if we still have permission get a new sub
                if (pushNotificationsEnabled(conf)) {
                  logger.info("notifications permission is still available, getting a new subscription");
                  dispatch(subscribeToPushNotifications(conf, authToken, locale, relay, localStore));
                }
              });
          }
        }
      )
      .catch((err) => {
        logger.info(`error setting up pushNotifications: ${err}`);
        dispatch(setPushNotificationsAvailable(false));
      });
  };
}

export function subscribeToPushNotifications(
  pushNotificationsConfig: PushNotificationsConfig,
  authToken: string,
  locale: PushSubscriptionLocale,
  relayEnv: IEnvironment,
  localStore: LocalStorage
): NotificationsThunkAction {
  return (dispatch, _getState) => {
    const logger = getLogger('pushNotifications');

    dispatch(setIsSubscribing(true));
    subscribe(pushNotificationsConfig, authToken)
      .then(storePushSubscriptionOnServer(locale, relayEnv))
      .then(
        (sub) => {
          dispatch(setPushSubscription(sub));
          dispatch(setIsSubscribing(false));
          try {
            localStore.savePushSubscription(sub);
          }
          catch (err) {
            logger.warn(`could not store subscription in localStorage: ${err}`);
            newEvent(WARNING, 'pushNotificationsStorageError', 'pushNotificationsStorageError',
              'Could not store push subscription');
          }
        },
        (err) => {
          logger.error(`failed to obtain a push subscription ${JSON.stringify(err)}`);
          newEvent(ERROR, 'pushNotificationsError', 'pushNotificationsError', 'Could not enable push notifications');
          dispatch(setIsSubscribing(false));
        });
  };
}

export function unsubscribeToPushNotifications(
  sub: HoloPushSub,
  pushNotificationsConfig: PushNotificationsConfig,
  relay: IEnvironment | null,
  localStore: LocalStorage
): NotificationsThunkAction {
  return (dispatch, _getState) => {
    const logger = getLogger('pushNotifications');

    dispatch(setIsSubscribing(true));
    unsubscribe(pushNotificationsConfig)
      .then(() => {
        if (relay) deletePushSubscriptionFromServer(relay, sub);
        else Promise.resolve();
      })
      .then(() => {
        localStore.clearPushSubscription();
        dispatch(setPushSubscription(null));
        dispatch(setIsSubscribing(false));
      })
      .catch((err) => {
        logger.warn(`failed to delete push subscription ${JSON.stringify(err)}`);
        dispatch(setIsSubscribing(false));
      });
  };
}

export function checkPushNotificationsAvailable(
  pushNotificationsConfig: PushNotificationsConfig
): NotificationsThunkAction {
  return (dispatch, _getState) => {
    const available = pushNotificationsAvailable(pushNotificationsConfig);
    dispatch(setPushNotificationsAvailable(available));
  };
}

function storePushSubscriptionOnServer(
  locale: PushSubscriptionLocale,
  relayEnv: IEnvironment
) {
  return (sub: HoloPushSub) => {
    const logger = getLogger("pushNotifications");
    const subId = sub.id;
    const type = sub.type;
    let webPushExtra = null;
    if (sub.type === 'WEB_PUSH' && sub.sub instanceof PushSubscription) {
      const {
        endpoint,
        keys,
        expirationTime
      } = sub.sub.toJSON();
      const auth = keys ? keys["auth"] : null;
      const p256dh = keys ? keys["p256dh"] : null;

      if (endpoint && auth && p256dh) {
        webPushExtra = {
          endpoint: endpoint,
          keys: {
            auth,
            p256dh
          },
          expirationTime: expirationTime ? expirationTime.toString() : null
        };
      }
    }

    return storeSubscription(relayEnv, subId, type, locale, webPushExtra)
      .then(
        (resp) => {
          logger.info(`stored push subscription: ${JSON.stringify(resp)}`);
          return Promise.resolve(sub);
        },
        (err) => {
          logger.info(`error storing push subscription: ${JSON.stringify(err)}`);
          return Promise.reject(JSON.stringify(err));
        }
      );
  };
}

function deletePushSubscriptionFromServer(
  relayEnv: IEnvironment,
  sub: HoloPushSub,
) {
  const logger = getLogger("pushNotifications");
  const subId = sub.id;
  return deleteSubscription(relayEnv, subId)
    .then(
      (resp) => {
        logger.info(`push subscription deleted: ${JSON.stringify(resp)}`);
        return Promise.resolve(JSON.stringify(resp));
      },
      (err) => {
        logger.info(`error deleting push subscription: ${JSON.stringify(err)}`);
        return Promise.reject(JSON.stringify(err));
      }
    );
}
