const generic = {
  ok: 'Ok',
  cancel: 'Annulla',
  close: 'Chiudi',
  send: 'Invia',
  reset: 'Reset',
  gotIt: 'Ho capito',
  yes: 'Sì',
  no: 'No',
  pageNotFound: 'Pagina non trovata',
  hour: 'ora',
  hours: 'ore',
  minute: 'minuto',
  minutes: 'minuti',
  second: 'secondo',
  seconds: 'secondi',
  day: 'giorno',
  days: 'giorni',
  login: 'Accedi',
  email: 'Email',
  phoneNumber: 'Numero di telefono',
  call: 'Chiama',
  copyToClipboard: 'Copia negli appunti',
  copiedToClipboard: 'Copiato!',
  from: 'da',
  to: 'a',
  next: 'successivo',
  previous: 'precedente',
  dateFormat: 'DD MMMM H:mm',
  fullDateFormat: 'DD MMMM YYYY HH:mm',
  tryAgain: 'Riprova',
  leave: 'Esci',
  error: 'Errore',
  networkError: 'Errore di connessione alla rete',
  videoPublishFailure: 'Errore nell\'invio del video. Controllare la connessione di rete e riprovare la pubblicazione.',
  screenPublishFailure: 'Errore nella condivisione schermo. '
    + 'Controllare la connessione di rete e riprovare la pubblicazione.',
  uploadICEGlitches: 'Rilevato degrado in trasmissione. '
    + 'Se l\'errore persiste controllare la connessione ed '
    + 'eventualmente disabilitare il proprio video.',
  downloadICEGlitches: 'Rilevati degrado in ricezione. '
    + 'Se l\'errore persiste controllare la connessione ed '
    + 'eventualmente disabilitare la ricezione di video.',
  mediaPublishFailure: 'Errore nell\'invio del media. Controllare la connessione di rete',
  endMeetingMsg: 'Termina meeting',
  save: 'Salva',
  name: 'Nome',
  allDone: 'Non hai nessun evento in programma',
  allDoneSchedule: 'Clicca qui per organizzare un appuntamento.',
  userInformation: 'Informazioni utente',
  pushNotificationsStorageError: 'Impossibile salvare la sottoscrizione push',
  pushNotificationsError: 'Impossibile abilitare le notifiche push',
  pushNotificationsSwitchText: 'Notifiche push',
  pushNotificationsSwitchInfo: `Riceverai una notifica in seguito a questi eventi:
  - un utente cerca di accedere alla tua stanza personale in tua assenza
  - un appuntamento a cui sei stato invitato sta per iniziare
  - un appuntamento organizzato da te è pronto per iniziare`,
  userPins: 'I tuoi PIN',
  userOther: 'Altre impostazioni',
  roomLockPreferenceSwitchText: 'Appuntamenti bloccati di default',
  roomLockPreferenceSwitchInfo: 'I tuoi appuntamenti e la tua stanza personale saranno bloccati di default. '
    + 'I partecipanti dovranno essere ammessi manualmente.',
  screenshareFrameRateLabel: 'Frame rate condivisione schermo',
  screenshareFrameRateInfo: 'Imposta quanti frame al secondo verranno inviati durante la condivisione dello schermo. '
    + 'Alcuni appuntamenti potrebbero sovrascrivere questa impostazione.',
  screenshareFrameRateWarning: 'Valori troppo alti per questa impostazione potrebbero causare un elevato '
    + 'consumo di banda ed instabilità.',
  holocomAccount: '{site} Account',
  gotoHomepage: 'Vai alla home',
  mediaSettings: 'Impostazioni audio/video',
  mediaSettingsFirstTimeMsg: 'Qui puoi regolare le impostazioni audio video',
  loginUsingAccount: 'Accedi usando le tue credenziali',
  calendarFirstTimeMsg: 'Qui puoi creare un nuovo appuntamento o visualizzare quelli in programma',
  enable: 'Abilita',
  disable: 'Disabilita',
  invite: 'Invita',
  manageYourControlsHere: 'Qui puoi gestire i tuoi comandi',
  pleaseWaitForRoom: 'Attendere il collegamento alla stanza...',
  loginFirstTimeMessage: 'Se hai un account, fai l\'accesso per usare tutti i servizi',
  lockRoomFirstTimeMsg: "Per bloccare l'accesso alla stanza clicca qui",
  extend: 'Estendi',
};

const deviceConfiguration = {
  deviceSelectorTitle: 'Seleziona i dispositivi audio e video',
  deviceSelectorVideoError: `Rilevato errore nell'accendere la telecamera, potrebbe esser in uso ' +
    'o con impostazioni di qualità troppa alta.`,
  deviceSelectorAudioInput: 'Microfono',
  deviceSelectorVideoInput: 'Videocamera',
  deviceSelectorAudioOutput: 'Altoparlanti',
  deviceSelectorNoAudioOutput: 'Uscita audio non supportata',
  deviceSelectorVideoQuality: 'Qualità video',
  deviceSelectorLoadingCam: 'Riconfigurazione telecamera, attendere....',
  deviceOverConstrained: 'La qualità del video selezionata non è supportata',
  deviceNotFound: 'Impossibile trovare il dispositivo selezionato, controllare le impostazioni',
  noAudioDeviceFound: 'Impossibile trovare un microfono, controllare i propri '
    + 'dispositivi. Proseguendo comunque, verrà effettuato l\'ingresso in solo ascolto',
  noAudioDeviceFoundTooltip: 'Nessun microfono trovato',
  noVideoDeviceFoundTooltip: 'Nessuna videocamera trovata',
  joinedInListenOnly: 'Nessun microfono trovato, ingresso in modalità spettatore',
  noAudioInputAvailable: 'Microfono non trovato',
  audioIsPausedDialogTitle: 'Ingresso in modalità spettatore',
  audioIsPausedDialogMessage: 'Ingresso effettuato in modalità spettatore',
  audioIsPausedDialogSubMessage: 'Avendo negato i permessi ai dispositivi di input, '
    + 'non sarà possibile inviare audio o video',
  deviceError: 'Errore nell\'accesso ai dispositivi audio o video, controllare le impostazioni',
  noDeviceAvailable: 'Nessun dispositivo trovato',
  rescanDevices: 'Cerca nuovi dispositivi',
  frontCamera: 'Camera Frontale',
  rearCamera: 'Camera Posteriore',
  beautifyEffect: 'Migliora aspetto',
  backgroundSettings: 'Effetti Video',
  backgroundSettingsHelp:
  '\u00B7 Queste impostazioni aumentano l\'uso delle risorse hardware (CPU, Memoria, Batteria) ' +
  'con possibile impatto sulle prestazioni.\n' +
  '\u00B7 Gli effetti sullo sfondo funzionano meglio se c\'è maggior contrasto di colore tra soggetto e sfondo.',
  backgroundBlur: "Rimozione Sfondo",
  backgroundBlurNone: "Disabilita",
  backgroundBlurSoft: "Sfocatura Leggera",
  backgroundBlurHard: "Sfocatura Intensa",
  backgroundBlurCustom: 'Carica sfondo',
  backgroundBlurCustomFile: 'L\'immagine caricata non è valida',
  backgroundBlurFlowers: 'Fiori',
  backgroundBlurKitchen: 'Cucina',
  backgroundBlurLeaves: 'Foglie',
  backgroundBlurMountain: 'Montagne',
  backgroundBlurOffice: 'Ufficio',
  backgroundBlurSkyline: 'Panorama',
  backgroundBlurSunrise: 'Alba',
  backgroundBlurThailand: 'Thailandia',
  backgroundBlurUmbrella: 'Ombrelli',
  backgroundBlurCustomTooLarge: 'L\'immagine è troppo grande. La dimensione massima è {value}{unit}',
  backgroundBlurCustomTooBig: 'La risoluzione dell\'immagine è troppo grande. ' +
    'La risoluzione massima è {width}x{height}',
  backgroundBlurCustomRemove: 'Elimina sfondo',
  backgroundBlurCustomApply: 'Applica sfondo',
  backgroundBlurCustomEdit: 'Modifica sfondo',
};

const login = {
  username: 'Nome utente',
  password: 'Password',
  loginHaveAnAccount: 'Hai un account? Fai login qui.',
  disconnectedMessage: 'Connessione al server non riuscita',
  genericOAuth: 'Accedi con OAuth/2.0',
  errorLoginCode5xx: `Il server ha riscontrato un errore, si prega di riprovare o contattare ` +
    `l'amministratore se il problema persiste`,
  errorLoginCode4xx: 'La richiesta non è valida',
  errorLoginCode401: 'Username o password errata',
  errorLoginGeneric: `Errore inaspettato. Contattare l'amministratore se il problema persiste`,
};

const logout = {
  logout: 'Logout',
  logoutDialogMessage: 'Sei sicuro di voler uscire?',
};

const mediaPermissions = {
  mediaPermissionsMessage:
    `Perché gli altri partecipanti possano sentirti, è richiesto l'accesso al microfono.\n` +
    `Ti verrà chiesto di confermare questa scelta per ogni browser e computer in uso.\n` +
    `Se questa operazione richiede troppo tempo, potresti avere problemi hardware. ` +
    `Prova a ricaricare la pagina o controllare i tuoi dispositivi.`,
  mediaPermissionsTitle: 'Consenti di utilizzare la videocamera e il microfono',
  mediaPermissionsBlockedTitle: 'Fotocamera e microfono bloccati',
  mediaPermissionsPermsErr:
    'Dobbiamo accedere alla videocamera e al microfono.\n' +
    'Fai clic sull\'icona della videocamera bloccata nella barra degli indirizzi del ' +
    'browser, seleziona Permetti sempre e ricarica questa pagina.\n' +
    'Se continui, non sarà possibile inviare audio o video',
  mediaPermissionsGenErr:
    'Impossibile accedere ai dispositivi audio o video. Assicurati siano collegati.\n' +
    'Se continui, non sarà possibile inviare audio o video',
  mediaPermissionsContinueWithoutAV: 'Prosegui senza audio o video',
};

const meeting = {
  hello: 'Ciao',
  startANewMeeting: 'Avvia una nuova riunione',
  deleteMeeting: 'Cancella evento',
  editMeeting: 'Modifica evento',
  start: 'Avvia',
  create: 'Crea',
  today: 'Oggi',
  tomorrow: 'Domani',
  attendees: 'Partecipanti',
  ownMeeting: 'proprietario',
  assistedMeeting: 'assistente',
  youCanInvite: 'Potrai invitare altri partecipanti via email, link e telefono',
  doYouHaveAnInvite: 'Hai ricevuto un invito?',
  joinUsingInvite: 'Entra usando il tuo invito',
  meetingHeader: 'Appuntamento',
  meetingWelcome: 'Ciao {user}, benvenuto.',
  noVideoDeviceFound: 'Nessun dispositivo video trovato. Accesso in modalità solo audio.',
  unableToStopVideoStream: 'Si è verificato un errore durante lo stop del video.',
  insertMeetingCodeHere: "Inserisci il codice dell'appuntamento qui",
  insertMeetingCode: "Inserisci il codice dell'appuntamento",
  navigationConfirmationTitle: 'Lasciare la stanza?',
  navigationConfirmationMessage: 'Sei sicuro di voler lasciare la stanza?',
  theRoomIsNotAvailableYet: 'La stanza "{name}" non è ancora disponibile.',
  theRoomIsScheduledFromTo: 'È prenotata per il {date}, dalle {start} alle {end}',
  theRoomIsScheduledTodayFromTo: 'È prenotata per oggi, dalle {start} alle {end}',
  theRoomWillBeAvailableIn: 'Sarà disponibile {time}',
  errorCodeGenericTitle: 'Errore di connessione alla stanza.',
  errorCodeGenericBody: `Si è verificato un'errore.`,
  errorCodeGenericPrimaryButton: 'Annulla',
  reconnectNotFound: 'Stanza non trovata o non ancora avviata.\nVuoi ricollegarti?',
  reconnectNotRunning: 'Proprietario non ancora collegato.\nVuoi riprovare?',
  errorCode1000Title: `Impossibile accedere alla stanza`,
  errorCode1000Body: 'Stanza privata non disponibile',
  errorCode1000PrimaryButton: 'Ok',
  errorCode1001Title: `C'è stato un errore`,
  errorCode1001Body: "Se il problema persiste contattare l'amministratore del server",
  errorCode1001PrimaryButton: 'Riprova',
  errorCode1001SecondaryButton: 'Annulla',
  errorCode1002Title: `Impossibile connettersi alla stanza`,
  errorCode1002Body:
    `L'appuntamento {title} non è ancora disponibile, poiché inizia il ` +
    `{dStart} alle {tStart}. La stanza sarà disponibile {delta}.`,
  errorCode1002SecondaryButton: 'Annulla',
  errorCode1002PrimaryButton: 'Riprova',
  errorCode1003Title: `Impossibile collegarsi all'appuntamento`,
  errorCode1003Body: `L'appuntamento è terminato alle {dtEnd}.`,
  errorCode1003PrimaryButton: 'Ok',
  errorCode1004Title: `Appuntamento non trovato oppure scaduto`,
  errorCode1004Body: 'Nessun appuntamento trovato.',
  errorCode1004SecondaryButton: 'Annulla',
  errorCode1004PrimaryButton: 'Riprova',
  errorCode1005Title: `Il proprietario non è presente`,
  errorCode1005Body: 'Si prega di attendere, l\'accesso alla stanza verrà effettuato in automatico',
  errorCode1005SecondaryButton: 'Annulla',
  errorCode1005PrimaryButton: 'Riprova',
  errorCode1008Title: `Risorse del dominio esaurite`,
  errorCode1008Body: 'Non è possibile ammettere ulteriori ospiti in questo dominio.',
  errorCode1008SecondaryButton: 'Annulla',
  errorCode1008PrimaryButton: 'Riprova',
  errorCode1009Title: `Risorse del dominio esaurite`,
  errorCode1009Body: 'Non è possibile allocare ulteriori stanze in questo dominio.',
  errorCode1009SecondaryButton: 'Annulla',
  errorCode1009PrimaryButton: 'Riprova',
  errorCode1010Title: `Risorse utente esaurite`,
  errorCode1010Body: 'Spiacenti, il tuo utente non può avviare ulteriori stanze.',
  errorCode1010SecondaryButton: 'Annulla',
  errorCode1010PrimaryButton: 'Riprova',
  errorCode1011Title: `Risorse della stanza esaurite`,
  errorCode1011Body: 'Questa stanza non può ammettere ulteriori ospiti.',
  errorCode1011SecondaryButton: 'Annulla',
  errorCode1011PrimaryButton: 'Riprova',
  errorCode1012Title: `Errore generico risorse`,
  errorCode1012Body: 'Errore generico nel controllare le risorse. Se il problema persiste contattare ' +
    'l\'amministratore del server.',
  errorCode1012SecondaryButton: 'Annulla',
  errorCode1012PrimaryButton: 'Riprova',
  errorCode1013Title: 'Impossibile entrare nella stanza',
  errorCode1013Body: 'Questa stanza è bloccata, non sono ammessi altri partecipanti.',
  errorCode1013SecondaryButton: 'Annulla',
  errorCode1013PrimaryButton: 'Riprova',
  errorCode1015Title: `Impossibile collegarsi all'appuntamento`,
  errorCode1015Body: `L'appuntamento a cui si stava tentando di accedere è stato concluso.`,
  errorCode1016Title: 'Impossibile entrare nella stanza',
  errorCode1016Body: 'Solo gli utenti autenticati possono accedere',
  errorCode1017Title: 'Nessuna risposta',
  errorCode1017Body: 'Il proprietario della stanza non ha risposto',
  errorCode4001Title: 'Non autorizzato',
  errorCode4001Body: 'Non hai permessi sufficienti per completare questa azione.',
  errorCode4001SecondaryButton: 'Annulla',
  errorCode4001PrimaryButton: 'Ok',
  guestLoginDialogTitle: 'Accesso ospite',
  guestLoginFieldTitle: 'Inserisci il tuo nome',
  guestLoginFieldMessage: 'Nome',
  clickHereToLogin: "Organizzatore dell'appuntamento? Clicca qui per fare login",
  clickHereToLoginToOwnRoom: 'Proprietario della stanza? Clicca qui per fare login',
  streamErrorTitle: 'Errore di accesso ai dispositivi hardware',
  streamErrorContent: 'Controllare le periferiche audio o video o i permessi di accesso.',
  joinButton: 'Entra',
  joinByCode: 'Hai ricevuto un invito?',
  screenVideoDisplayName: `Schermo di {name}`,
  publisherIsNotSendingVideo: 'Questo utente non sta inviando video',
  youAreNotSendingVideo: 'Non stai inviando video',
  listAttendees: 'Lista partecipanti:',
  meetingLeftDialogTitle: 'Stanza lasciata',
  meetingLeftDialogContent: 'Hai lasciato la stanza',
  meetingLeftDialogContentWhenKicked: 'Sei stato espulso dalla stanza',
  meetingLeftDialogContentWhenEnded: 'Il meeting è terminato',
  meetingLeftDialogContentWhenIceFailed: 'Errore nella connessione con il server audio/video, probabilmente ' +
    'a causa di restrizioni firewall. Contattare l\'amministratore di rete',
  meetingAvailable: 'I prossimi appuntamenti',
  meetingStartsAt: '{dtStart}',
  attendeesErrorEmail: "L'email non è valida",
  attendeesErrorEmpty: 'Il campo è vuoto',
  attendeesErrorRequired: 'Almeno un partecipante è richiesto',
  attendeesErrorAlreadyEntered: "L'email è già stata inserita",
  attendeesUploadedInvalidFound: 'Uno o più degli indirizzi email caricati non risultano validi. ' +
    'Correggili e carica nuovamente il file. Gli indirizzi non validi sono: {list}',
  attendeesUploadedInvalidFoundMore: ' e altri {number}',
  attendeesUpload: 'Carica indirizzi email da file',
  attendeesUploadInvalidFile: 'Il file non è valido',
  dtErrorTooBrief: 'Intervallo troppo breve',
  dtErrorTooLong: 'Intervallo troppo lungo',
  dtErrorInvalidFormat: 'Formato non valido',
  scheduleMeeting: 'Crea un nuovo evento',
  scheduleMeetingPlaceHolder: 'Assegna un nome all\'evento',
  scheduleStartMeeting: 'Inserisci la data di inizio appuntamento',
  scheduleEndMeeting: 'Inserisci la data di fine appuntamento',
  scheduleHour: 'Ora',
  scheduleMeetingErrorTooMany: 'Hai inserito troppi partecipanti',
  scheduleMeetingErrorRequired: 'Devi aggiungere almeno un partecipante',
  scheduleMeetingErrorTypeNotAllowed: 'Non hai i permessi per schedulare questo tipo di appuntamento',
  scheduleMeetingAttendeesPlaceHolder: 'Inserisci l\'email da invitare',
  titleMeeting: "Nome evento",
  titleMeetingError: 'Il campo è vuoto',
  sendAsLabel: 'Non sei l\'organizzatore?',
  sendAsInsertOrganizerEmail: 'Inserisci l\'email dell\'organizzatore',
  sendAsOrganizerNotFound: 'Utente non trovato',
  sendAsOrganizerFillText: 'Digita la mail per effettuare la ricerca',
  notes: 'Descrizione',
  notesAdd: 'Aggiungi descrizione',
  particpantsAdd: 'Aggiungi partecipanti',
  sendInvite: 'Invita',
  sendInviteToPersonal: 'Invita nella tua stanza personale',
  invitePersonToMeeting: 'Invita nuovo partecipante',
  inviteEmail: 'Invita via mail',
  roomInfoTitlePersonal: `Stanza personale di {user}`,
  roomHowToInvitePersonal: `Per invitare qualcuno alla tua stanza personale, inviagli i seguenti riferimenti:`,
  roomHowInvitePersonalClipBoardHeader: `Collegati alla mia conferenza personale, di seguito i dettagli:`,
  roomHowInvitePersonalClipBoardWeb: `Accesso tramite browser (Chrome, Firefox o Safari)`,
  roomInfoTitleScheduled: `Appuntamento organizzato da {user}`,
  roomInfoName: 'Nome stanza',
  roomInfoWebAccess: 'Accesso web',
  roomInfoAccessTitle: 'Collegati all\'appuntamento',
  roomInfoPersonalAccessTitle: 'Collegati alla mia stanza personale',
  roomPublishStreamUrl: 'Accesso diretta video',
  roomInfoPstnAccess: 'Numero per accesso telefonico',
  roomInfoPstnNotAvailable: 'Non disponibile',
  roomInfoPstnNumber: '{number} (il pin è {pin})',
  roomInfoScheduling: 'Pianificata per',
  roomInfoParticipants: 'Partecipanti invitati',
  roomInfoMoreParticipants: '... altri {otherAttendees}',
  inviteNewParticipantDialogTitle: 'Nuovo invito per la stanza corrente',
  inviteNewParticipantDialogContent: "Inserisci l'email per i nuovi partecipanti da invitare.",
  inviteNewParticipantDialogValidationError: 'Email non valida',

  dialPersonToMeeting: 'Chiama qualcuno via telefono',
  dialToMeeting: 'Chiama telefono',
  dialToMeetingDialogTitle: 'Aggiungi qualcuno alla stanza via telefono',
  dialToMeetingDialogContent: 'Inserisci un numero di telefono valido',
  dialToMeetingValidatioError: 'Numero di telefono non valido',
  dialParticipantSuccess: 'Chiamata avviata con successo.',
  copyLink: 'Copia link',
  copyDetails: 'Copia dettagli',

  startMeeting: 'Data inizio',
  endMeeting: 'Data fine',
  deleteButton: 'Elimina',
  highLinkQuality: 'Buona velocità in upload',
  mediumLinkQuality: 'Discreta velocità in upload',
  lowLinkQuality: 'Bassa velocità in upload',
  computingLinkQuality: 'Calcolo velocità di upload in corso',
  linkQualityNotAvailable: 'Velocità di upload non disponibile',
  6005: 'Servizio non disponibile, potrebbero esser stati raggiunti i limiti di chiamate.',
  6004: 'Numero non raggiungibile, potrebbe esser errato o non permesso.',
  6003: "Non è stato possibile invitare i partecipanti, si prega di riprovare o contattare l'amministratore " +
    "se il problema persiste",
  6000: "Non è stato possibile invitare i partecipanti, l'appuntamento non è ancora iniziato.",
  6001: "Non è stato possibile invitare i partecipanti, l'appuntamento è scaduto.",
  6002: "Non è stato possibile invitare i partecipanti, l'appuntamento non esiste.",
  deleteMeetingError:
    "Non è stato possibile cancellare l'appuntamento, si prega di riprovare o contattare " +
    "l'amministratore se il problema persiste",
  unexpectedRoomError: 'Si è verificato un errore: {errorMessage}.',
  answerError: 'Errore nella ricezione audio/video, controllare la connessione di rete',
  connectionClosedUnexpectedlyTitle: 'Connessione persa',
  connectionClosedUnexpectedlyMessage: 'Connessione con il server chiusa. Controllare la propria connettività',
  rpcTimeoutError: 'Timeout verso il server',
  meetingDuration: `Durata dell'appuntamento`,
  meetingBriefDuration: '{time} {time, plural, one {secondo} other {secondi} }',
  meetingCommonDuration: '{time1} {time1, plural, one {minuto} other {minuti}} {time2, plural, =0 {} ' +
    'other {e {time2 } {time2, plural, one {secondo} other {secondi}}}}',
  meetingLongDuration: '{time1} {time1, plural, one {ora} other {ore}} {time2, plural, =0 {} ' +
    'other {e {time2 } {time2, plural, one {minuto} other {minuti}}}}',
  meetingVeryLongDuration: '{time1} {time1, plural, one {giorno} other {giorni}}{time2, plural, =0 {} other ' +
    '{, {time2 } {time2, plural, one {ora} other {ore}}}} {time3, plural, =0 {} ' +
    'other {e {time3 } {time3, plural, one {minuto} other {minuti}}}}',
  muteAllButton: 'Disattiva audio partecipanti',
  unMuteAllButton: 'Attiva audio partecipanti',
  muteAllVideos: 'Non ricevere video dai partecipanti',
  endMeetingDialogTitle: 'Termina meeting',
  endMeetingDialogContent: 'Sei sicuro di voler terminare il meeting? Tutti i partecipanti verranno espulsi.',
  meetingParticipantsSummary: 'Partecipanti al meeting: {totalUsers}',
  meetingParticipantsSummaryAudioOnly: ' ({audioOnlyUsers} con solo audio)',
  meetingParticipantsSummaryAudioOnlyMobile: ' ({icon}{audioOnlyUsers})',
  checkSettingsAndJoinTitle: 'Pronto',
  checkSettingsAndJoinBody: 'Controlla le impostazioni audio/video ed entra',
  insertYourNameAndJoinTitle: 'Come ti chiami?',
  insertYourNameAndJoinBody: 'Inserisci il tuo nome e controlla le impostazioni audio/video prima di entrare',
  joinWithVideo: 'Partecipa con video',
  joinWithAudio: 'Partecipa con audio attivo da subito',
  joinWithBlur: 'Partecipa con sfocatura sfondo attiva',
  playTestSound: 'Riproduci audio di test',
  stopTestSound: 'Interrompi audio di test',
  webinarPresenterWithoutVideo: 'Il presentatore non ha il video attivo',
  webinarPresenterAbsent: 'Il presentatore ha lasciato la stanza',
  raiseYourHand: 'Alza la mano',
  lowerYourHand: 'Abbassa la mano',
  otherOptions: 'Altre opzioni',
  canPublishVideoAgain: 'Ora puoi pubblicare il tuo video. ' +
    'Attiva il video dalla barra dei controlli personali in basso.',
  leaveWaitingRoom: 'Lascia stanza di attesa',
  waitOwnerTitle: 'Proprietario assente',
  waitOwnerBody: 'Attendi l\'inizio dell\'appuntamento.',
  roomLockedTitle: 'Stanza bloccata',
  roomLockedBody: 'Attendi che qualcuno ti dia il permesso di entrare.',
  ownerNotifiedTitle: 'Stiamo contattando il proprietario della stanza',
  ownerNotifiedBody: 'Entrerai automaticamente nella stanza quando arriva.',
  lockedJoinRequestTitle: 'Richiesta di Accesso',
  lockedJoinRequestsTitle: 'Richieste di Accesso',
  lockedJoinRequestMsg: '{dname} chiede di entrare nella stanza',
  lockedJoinRequestDeniedTitle: 'Richiesta rifiutata',
  lockedJoinRequestDeniedBody: 'Il proprietario della stanza ha rifiutato la tua richiesta d\'accesso.',
  acceptLockedJoinRequestButton: 'Accetta',
  denyLockedJoinRequestButton: 'Rifiuta',
  acceptAllLockedJoinRequestButton: 'Accetta tutti',
  denyAllLockedJoinRequestButton: 'Rifiuta tutti',
  showLockedJoinRequests: 'Mostra richieste di accesso',
  waitingRoomPrivacyBody: 'Per permettere ai moderatori di riconoscerti, ' +
    'potremmo inviare un\'anteprima dalla tua camera. ' +
    'L\'immagine non verrà mai memorizzata sui nostri server.',
  leaveMeetingDialogTitle: 'Abbandona meeting',
  leaveMeetingDialogBody: 'Vuoi abbandonare il meeting o terminarlo? ' +
    'Se termini il meeting tutti i partecipanti verranno espulsi.',
  leaveMeetingDialogLeave: 'Abbandona',
  leaveMeetingDialogTerminate: 'Termina',
  meetingIsExpiring: 'Stanza in scadenza',
  meetingWillExpireSoon: 'Questa stanza sta per scadere',
  meetingWillExpireIn: `Questa stanza scadrà in {time}`,
  errorInviting: 'Impossibile invitare partecipanti',
  conferenceUpdate: `Il meeting è stato esteso fino alle {endDate}`,
  conferenceUpdateError: `Errore durante l'estensione del meeting`,
  joinedPrivateConf: `{displayName} è entrato in una conversazione privata`,
  youJoinedPrivateConf: `Sei entrato in una conversazione privata`,
  leftPrivateConf: `{displayName} è uscito dalla conversazione privata`,
  youLeftPrivateConf: `Sei uscito dalla conversazione privata`,
  startPrivateAudioRequestFailed: `Errore: impossibile avviare una conversazione privata`,
  stopPrivateAudioRequestFailed: `Errore: impossibile terminare la conversazione privata`
};

const screenShare = {
  shareScreen: 'Condividi schermo',
  unShareScreen: 'Interrompi condivisione',
  screenSharingDialogTitle: 'Condividi schermo',
  screensThumbnailsListDialogTitle: 'Seleziona schermo',
  windowsThumbnailsListDialogTitle: 'Seleziona finestra',
  screenSharingDialogContent: 'Seleziona cosa condividere',
  screenSharingDialogScreen: 'Schermo',
  screenSharingDialogWindow: 'Finestra',
  screenSharingErrorTitle: 'Errore nella condivisione dello schermo',
  screenSharingErrorContent:
    'Accertarsi di consentire il permesso alla condivisione quando richiesto dal browser',
  extensionErrorTitle: 'Estensione non installata',
  extensionErrorContent:
    `Per abilitare la condivisione dello schermo è necessaria un'estensione.\n` +
    `Per installare l'estensione VoiSmart Screen Share:\n`,
  extensionErrorContentList:
    `1. clicca sul buttone installa\n` +
    `2. aggiungi l'estensione al tuo browser\n` +
    `3. ricarica la pagina`,
  extensionErrorButton: 'Annulla',
  extensionInstallButton: 'Installa',
  extensionReloadButton: `Ricarica`,
  screenSharingUnsupportedTitle: 'Condivisione dello schermo non supportata',
  screenSharingUnsupportedMessage:
    `Il tuo browser non supporta la condivisione dello schermo.\n` +
    `Prova con Chrome, Firefox o Opera.`,
};

const browserCheck = {
  unsupportedBrowserTitle: 'Web browser non supportato',
  unsupportedBrowserVersionTitle: 'Web browser non supportato',
  supportedExperimentalTitle: 'Web browser non pienamente supportato',
  unsupportedBrowserContent: 'Il tuo browser non è supportato.',
  unsupportedBrowserVersionContent: 'Il tuo browser è supportato a partire dalla versione {version}. ' +
    'Aggiornalo e riprova.',
  supportedExperimentalContent: 'Il tuo browser è supportato in maniera sperimentale, alcune ' +
    'funzionalità non saranno disponibili.',
  supportedBrowsers: 'I browser consigliati sono Google Chrome (dalla versione {minChromeVersion}) ' +
    'e Mozilla Firefox (dalla versione {minFirefoxVersion}).',
  iosSupportedBrowsers: 'Si consiglia di usare Safari (dalla versione {minSafariVersion}).',
};

const tooltip = {
  meetingIconButtonTooltip: `Homepage`,
  loginIconButtonTooltip: 'Login',
  logoutIconButtonTooltip: 'Logout',
  settingsIconButtonTooltip: 'Impostazioni',
  accountIconButtonTooltip: 'Utente',
  changeUrlButtonTooltip: 'Cambia indirizzo',
  screenShareButtonExtensionTooltipDownload: `Scarica l'estensione per la condivisione dello schermo`,
  screenShareButtonExtensionTooltipIsNotSupported: 'Condivisione dello schermo non supportata',
  screenShareButtonExtensionTooltipIsSupported: 'Condividi schermo',
  screenShareButtonExtensionTooltipDisable: 'Disattiva condivisione schermo',
  microphoneButtonEnableTooltip: 'Attiva Microfono',
  microphoneButtonDisableTooltip: 'Disattiva Microfono',
  videoButtonEnableTooltip: 'Attiva Video',
  videoButtonDisableTooltip: 'Disattiva Video',
  startPrivateConfTooltip: 'Inizia Conversazione Privata',
  stopPrivateConfTooltip: 'Termina Conversazione Privata',
  hangupIconButton: 'Termina chiamata',
  microphoneDisabledTooltip: 'Muto',
  microphoneActiveTooltip: 'Attivo',
  allowToSpeak: 'Dai la parola',
  exitFullScreenButton: 'Esci dalla modalità schermo intero',
  exitFullScreenMobile: 'Esci da schermo intero',
  enterFullScreenButton: 'Schermo intero',
  enablePipButton: 'Abilita mini video',
  disablePipButton: 'Disabilita mini video',
  enableDesktopControl: 'Abilita Controllo Desktop',
  disableDesktopControl: 'Disabilita Controllo Desktop',
  chatIconButtonTooltip: 'Chat',
  ejectIconButtonTooltip: 'Espelli',
  clearStateIconButtonTooltip: 'Resetta lo stato',
  enlargeIconButtonTooltip: 'Ingrandisci',
  collapseIconButtonTooltip: 'Riduci',
  foregroundIconButtonTooltip: 'Metti in primo piano',
  promoteRoleIconButtonTooltip: 'Rendi moderatore',
  demoteRoleIconButtonTooltip: 'Rimuovi dal ruolo di moderatore',
  presentationIconTooltip: 'Moderatore',
  roomInfoTooltip: 'Informazioni sulla stanza',
  scheduleButtonTooltip: 'Organizza un appuntamento',
  joinTooltip: 'Entra',
  editTooltip: 'Modifica',
  moreActionTooltip: 'Più azioni',
  roomActionsMenuTooltip: 'Azioni stanza',
  lockRoom: 'Blocca la stanza',
  unLockRoom: 'Sblocca la stanza',
  streamSwitchShowScreenTooltip: 'Mostra schermo condiviso',
  streamSwitchShowStreamTooltip: 'Mostra video utente',
};

const notificationMessage = {
  joinMeeting: `{username} è entrato nella stanza`,
  exitMeeting: `{username} è uscito dalla stanza`,
  muted: 'Il tuo microfono è stato disattivato',
  unmuted: 'Il tuo microfono è stato attivato',
  promotedToModerator: 'Sei stato promosso a moderatore',
  demotedModerator: 'Non sei più moderatore',
  kicked: 'Sei stato espulso dalla stanza',
  rosterMultipleChanges: `{joinedUsers} utenti sono entrati nella stanza e ' +
    '{exitedUsers} utenti sono usciti dalla stanza`,
  needFullscreenForControl: 'Devi avviare manualmente lo share del desktop in fullscreen ' +
    'per avviare il controllo desktop',
  stopDesktopControl: 'Il controllo del desktop remoto per questa stanza è stato disabilitato',
  startDrawing: 'Il disegno desktop per questa stanza è stato abilitato',
  startDrawingBrowser: 'Il disegno desktop per questa stanza è stato abilitato. Devi avviare manualmente ' +
    'la condivisione del desktop a schermo intero per usarlo.',
  stopDrawing: 'Il disegno desktop per questa stanza è stato disabilitato',
  meetingEnded: 'Meeting terminato',
  joinOnLockedRoom: '{dname} non ha potuto accedere al meeting perché la stanza è bloccata',
  raisedHand: '{dname} ha alzato la mano',
  lockedJoinRequestTitle: 'Richiesta di accesso',
  lockedJoinRequestBody: '{dname} ha richiesto l\'accesso al meeting',
};

const layout = {
  standardLayout: 'Layout base',
  featuredLayout: 'Presentazione',
  chooseLayout: 'Scegli il tuo layout',
  applyLayout: 'Applica il layout corrente a tutti i partecipanti',
  selectToMeLayout: 'Seleziona per me',
  selectToAllLayout: 'Seleziona per tutti i partecipanti',
  gridLayout: 'Griglia',
  webinarLayout: 'Webinar',
  lessonLayout: 'Lezione',
  audioOnlyLayout: 'Solo audio',
  fullscreenLayout: 'Schermo intero',
  presentationLayout: 'Presentazione',
};

const recording = {
  menuRecording: 'Registra video',
  menuRecordingAudio: 'Registra audio',
  stopRecording: 'Ferma registrazione video',
  stopRecordingAudio: 'Ferma registrazione audio',
  startRecording: 'Avvia registrazione',
  reportRecording: 'Lista registrazioni',
  reportTitleRecording: 'Titolo',
  reportStartRecording: 'Inizio',
  reportEndRecording: 'Fine',
  reportNoteRecording: 'Note',
  reportSlugRecording: 'Stanza',
  reportPlayRecording: 'Riproduci',
  reportTypeVideoRecording: 'Video',
  reportTypeAudioRecording: 'Audio',
  reportDeleteRecording: 'Elimina',
  reportDurationRecording: "Durata",
  reportFilterTemporalRangeRecording: "Intervallo Temporale",
  reportFilterApplyRecording: "Applica Filtro",
  reportFilterAppliedRecording: "Filtro Applicato",
  reportLoadRecording: 'Carica altre registrazioni',
  reportUnablePlayRecording: 'Impossibile riprodurre la registrazione',
  reportUnableLoadRecording: 'Impossibile caricare la lista delle registrazioni',
  roomStartRecording: 'Questa stanza è in registrazione.',
  roomStopRecording: 'La registrazione è stata interrotta.',
  reportDownloadRecording: 'Scarica registrazione.',
  7001: 'Tutti i registratori sono occupati.',
  7002: 'Metodo non autorizzato.',
  7003: 'Errore interno, contattare amministratore.',
  7004: "Il nome di dominio utilizzato non è abilitato per questo servizio.",
  deleteRecordingError: 'Non è stato possibile cancellare la registrazione, si prega di riprovare o contattare ' +
    'l\'amministratore se il problema persiste.',
  deleteRecordingSuccess: 'La registrazione è stata cancellata con successo.',
  5004: 'Server timeout',
  defaultNotificationError: 'Unknown Error',
  roomIsBeingLiveStreamed: 'Questa stanza è in streaming live',
};

const liveStreaming = {
  menuStreaming: 'Streaming',
  roomStartStreaming: 'La conferenza è trasmessa in diretta video',
  roomStopStreaming: 'La trasmissione in diretta video è terminata',
  tooltipStartLiveStreaming: 'Avvia la diretta video',
  tooltipStopLiveStreaming: 'Ferma la diretta video',
  streamingSettings: 'Impostazioni Live streaming',
};

const accountCmp = {
  accountCmpUser: 'Utente',
  accountCmpPins: 'Pins',
  accountCmpStreaming: 'Streaming',
};

const userTabCmp = {
  userTabCmpTitle: 'Informazioni utente',
  personalRoom: 'Stanza personale',
};

const regenPinsTabCmp = {
  regenPinsTabCmpRegen: 'Rigenera codici',
  regenPinsTabCmpTitle: 'Codici accesso stanza personale',
  regenPinsTabCmpPubPin: 'Pubblico',
  regenPinsTabCmpPrivPin: 'Personale',
};

const liveStreamingCmp = {
  liveStreamingCmpTitle: 'Diretta video',
  liveStreamingCmpApiKey: 'Chiave/nome flusso',
  liveStreamingCmpShowApiKey: 'Mostra chiave',
  liveStreamingCmpApiUrl: 'Server URL',
  liveStreamingCmpPubUrl: 'URL pubblico diretta video',
};

const errors = {
  regeneratePinsGenError: 'Errore nel rinnovo dei pin.',
  getMyProfileError: 'Errore nel recupero del profilo utente.',
  updateProfileError: `Errore nell'aggiornamento del profilo utente`,
  errBoundaryHeader: 'Oops! Qualcosa è andato storto.',
  errBoundaryMessage: `La pagina non si è caricata correttamente. ` +
    `Controlla la console javascript per i dettagli oppure prova a ricaricare la pagina.`
};

const success = {
  updateProfileSuccess: 'Profilo salvato correttamente.',
};


const roster = {
  findParticipants: 'Cerca partecipanti',
  participantNumber: 'Partecipanti {number}',
  filteredParticipantNumber: 'Partecipanti {number}, {filtered} filtrati',
};


const chat = {
  chat: 'Chat',
  sendAMessage: 'Invia un nuovo messaggio',
  noMessages: 'Non ci sono ancora messaggi in questa stanza',
  userJoined: '{name} è entrato',
  userLeft: '{name} è uscito',
  newPrivateChat: 'Nuova conversazione privata',
  selectUser: 'Seleziona un utente',
  noOtherUsers: 'Non ci sono utenti',
  everyone: 'Tutti',
  userOffline: 'L\'utente non è in linea',
  userInvalid: 'Utente non trovato',
  shareFileTooltip: 'Condividi file',
  shareFileDialogTitlePublic: 'Condividi file nella chat pubblica',
  shareFileDialogTitlePrivate: 'Share file nella chat privata',
  shareFileDescriptionPlaceholder: 'Descrizione file',
  sharedFileUploadError: 'Si è verificato un errore durante l\'invio del file',
  sharedFileUploadErrorTooLarge: 'Errore: file troppo grande',
  sharedFileUploadErrorBadType: 'Errore: tipo di file non supportato',
  sharedFileDownloadError: 'Si è verificato un errore durante il download del file',
  sharedFileUploadCancelled: 'Caricamento del file annullato',
};


const meetingType = {
  typeLabel: 'Tipo di meeting',
  typeRegular: 'Video Conferenza',
  typeAudioOnly: 'Audio Conferenza',
  typeWebinar: 'Webinar',
  typeLesson: 'Lezione'
};


const meetingPrivacy = {
  privacyLabel: 'Tipo di accesso',
  privacyInfo: 'Qui puoi impostare il livello di privacy del meeting\n' +
    '\u00B7 Pubblico: chiunque abbia il link può accedere automaticamente in presenza del proprietario\n' +
    '\u00B7 Moderato: chiunque abbia il link può accedere una volta approvato da un moderatore\n' +
    '\u00B7 Interno: tutti gli utenti autenticati in piattaforma possono accedere automaticamente\n' +
    '\u00B7 Interno e moderato: tutti gli utenti autenticati in piattaforma possono accedere se ' +
    'approvati da un moderatore',
  privacyOpen: 'Pubblico',
  privacyLocked: 'Moderato',
  privacyLogged: 'Interno',
  privacyLockedLogged: 'Interno e moderato',
};


const supportMenu = {
  manualUrlLabel: 'Manuale',
  privacyUrlLabel: 'Norme sulla privacy ',
  termsUrlLabel: 'Termini di servizio',
  shortcutsLabel: 'Comandi rapidi',
  shortcutsDialogTitle: 'Lista dei comandi rapidi',
  shortcutKeyLabel: 'Comando',
  shortcutDescriptionLabel: 'Descrizione',
  shortcutMuteMicKey: 'M',
  shortcutMuteMicDescription: 'Abilita/Disabilita il tuo microfono',
  shortcutMuteVideoKey: 'V',
  shortcutMuteVideoDescription: 'Abilita/Disabilita la tua videocamera',
  shortcutOpenRosterKey: 'R',
  shortcutOpenRosterDescription: 'Apri/Chiudi la lista partecipanti',
  shortcutOpenChatKey: 'C',
  shortcutOpenChatDescription: 'Apri/Chiudi la chat',
  shortcutRaiseHandKey: 'H',
  shortcutRaiseHandDescription: 'Alza/Abbassa la mano',
  shortcutLockRoomKey: 'Shift + L',
  shortcutLockRoomDescription: 'Blocca/Sblocca la stanza',
  shortcutFullscreenKey: 'Doppio Click',
  shortcutFullscreenDescription: 'Su un video per vederlo a schermo intero',
  shortcutsFooter: 'Alcuni comandi rapidi sono disponibili esclusivamente ai moderatori'
};


export {
  generic,
  deviceConfiguration,
  login,
  logout,
  mediaPermissions,
  meeting,
  screenShare,
  browserCheck,
  liveStreaming,
  tooltip,
  notificationMessage,
  layout,
  recording,
  accountCmp,
  userTabCmp,
  regenPinsTabCmp,
  liveStreamingCmp,
  errors,
  success,
  roster,
  chat,
  meetingType,
  meetingPrivacy,
  supportMenu
};
