/* tslint:disable */
/* eslint-disable */
/* @relayHash a6448b49391165224b63a589ba8da3c5 */

import { ConcreteRequest } from "relay-runtime";
export type FilterMatch = "CONTAINS" | "ENDS" | "EQUALS" | "GT" | "GTE" | "LT" | "LTE" | "STARTS" | "%future added value";
export type SearchFilter = {
    key: string;
    match?: FilterMatch | null;
    value: string;
};
export type searchContactQueryVariables = {
    first?: number | null;
    filters?: Array<SearchFilter | null> | null;
};
export type searchContactQueryResponse = {
    readonly loggedUser: {
        readonly contacts: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly email: string;
                } | null;
            } | null> | null;
        } | null;
    };
};
export type searchContactQuery = {
    readonly response: searchContactQueryResponse;
    readonly variables: searchContactQueryVariables;
};



/*
query searchContactQuery(
  $first: Int
  $filters: [SearchFilter]
) {
  loggedUser {
    contacts(first: $first, filters: $filters) {
      edges {
        node {
          email
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "[SearchFilter]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "searchContactQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "loggedUser",
        "storageKey": null,
        "args": null,
        "concreteType": "LoggedUserData",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contacts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ContactConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Contact",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "searchContactQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "loggedUser",
        "storageKey": null,
        "args": null,
        "concreteType": "LoggedUserData",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contacts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ContactConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContactEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Contact",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "searchContactQuery",
    "id": null,
    "text": "query searchContactQuery(\n  $first: Int\n  $filters: [SearchFilter]\n) {\n  loggedUser {\n    contacts(first: $first, filters: $filters) {\n      edges {\n        node {\n          email\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'aeed2d21ec0b441560c7904d0c5b5c82';
export default node;
