import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { iconColors as colors } from '../../colors';
import { toggleRaiseHand } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { amRequestingRaiseHand, haveRaisedHand } from '../../lib/reduxSelectors/user';
import { IconHandUp, IconHandUpDisabled } from '../IconSet';

import messages from './buttonsMessages';


function RaiseHandButton(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { isHandRaised, isRequestingRaiseHand } = props;

  const toggleHand = React.useCallback(
    () => {
      logEvent(Event.RAISE_HAND, { 'action': isHandRaised ? 'lower' : 'raise', 'from': 'own_controls' });
      dispatch(toggleRaiseHand());
    }, [dispatch, isHandRaised]
  );

  const label = isHandRaised ? formatMessage(messages.lowerHand) : formatMessage(messages.raiseHand);

  return (
    <MenuItem onClick={toggleHand} disabled={isRequestingRaiseHand}>
      <ListItemIcon>
        {isHandRaised
          ? <IconHandUpDisabled color={colors.inactive} size={32} />
          : <IconHandUp size={32} />
        }
      </ListItemIcon>
      <ListItemText primary={label} />
    </MenuItem>
  );
}


type MappedProps = {
  isHandRaised: boolean;
  isRequestingRaiseHand: boolean;
}

type ExtendedProps = {} & MappedProps;

const mapStateToProps = (state: State): MappedProps => {
  return {
    isHandRaised: haveRaisedHand(state),
    isRequestingRaiseHand: amRequestingRaiseHand(state)
  };
};


export default connect(mapStateToProps)(RaiseHandButton);
