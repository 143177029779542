import React  from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Cookies from 'js-cookie';

import { useMediaQuery, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { loginGuest } from '../../lib/actions/auth';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import getRouteFor, { Route, getRoomNameFromParams } from '../../lib/utils/router';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      [theme.breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
      },
    },
    inputLabel: {
      paddingBottom: theme.spacing(0.5),
    },
    loginLink: {
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    button: {
      paddingLeft: theme.spacing(1),
    }
  })
);


const messages = defineMessages({
  guestLoginFieldTitle: { id: 'guestLoginFieldTitle' },
  guestLoginFieldMessage: { id: 'guestLoginFieldMessage' },
  clickHereToLogin: { id: 'clickHereToLogin' },
  join: { id: 'joinButton' },
});


function GuestLoginField(props: ExtendedProps) {
  const { onJoin, match, loginEnabled } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const prevDisplayName = Cookies.get('_holocom_guest_display');
  const [displayName, setDisplayName] = React.useState(prevDisplayName);
  const [canJoin, setCanJoin] = React.useState(Boolean(prevDisplayName));

  const meetingId = getRoomNameFromParams(match.params);
  const loginQs = meetingId ? `redirectTo=${meetingId}` : undefined;
  const loginRef = getRouteFor(Route.Login, {}, loginQs);


  const handleJoin = React.useCallback(
    () => {
      if (canJoin) {
        dispatch(loginGuest(displayName));
        onJoin();
      }
    }
    , [canJoin, displayName, onJoin, dispatch]
  );

  const handleChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const dname = ev.target.value;
      setDisplayName(dname);
      setCanJoin(dname.length > 0);
    }
    , [setDisplayName, setCanJoin]
  );

  const handleKeyUp = React.useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if ((ev.key === 'Enter') && (canJoin)) {
        handleJoin();
      }
    }
    , [handleJoin, canJoin]
  );

  const logLoginEvent = React.useCallback(
    () => {
      logEvent(Event.LOGIN, { 'from': 'waiting_room' });
    }, []
  );

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const justify = loginEnabled && !isXs ? 'space-between' : 'flex-end';

  return (
    <React.Fragment>
      <InputLabel className={classes.inputLabel}>
        {formatMessage(messages.guestLoginFieldTitle)}
      </InputLabel>
      <TextField
        inputProps={{ autoCapitalize: 'off' }}
        autoFocus={true}
        fullWidth
        placeholder={formatMessage(messages.guestLoginFieldMessage)}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        defaultValue={prevDisplayName}
      />
      <DialogActions classes={{ spacing: classes.root }}>
        <Grid
          container
          direction='row'
          alignItems='center'
          justify={justify}
          className={classes.root}
        >
          {loginEnabled &&
            <Grid item>
              <a onClick={logLoginEvent} href={loginRef} className={classes.loginLink}>
                <Typography color='primary' variant='caption' gutterBottom align='center'>
                  {formatMessage(messages.clickHereToLogin)}
                </Typography>
              </a>
            </Grid>
          }
          <Grid item className={classes.button}>
            <Button
              disabled={!canJoin}
              variant='contained'
              onClick={handleJoin}
              color='primary'
            >
              {formatMessage(messages.join)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </React.Fragment>
  );
}


type Props = {
  onJoin: () => void;
};


type MappedProps = {
  loginEnabled: boolean;
}


function mapStateToProps(state: State) {
  return {
    loginEnabled: state.appconfig.login_enabled,
  };
}


type ExtendedProps = Props & MappedProps & RouteComponentProps<{id: string}>


export default connect(mapStateToProps)(withRouter(GuestLoginField));
