import React from 'react';

import { RtcDevices } from '../lib/api/rtcDevices';
import { getLogger } from '../lib/logger';
import prepareWebRtcProvider from '../rtc';


export default function useRtc(loggerId: string) {
  const [rtc, setRtc] = React.useState<null | RtcDevices>(null);

  React.useEffect(() => {
    const logger = getLogger(loggerId);
    const webrtc = new RtcDevices(prepareWebRtcProvider(), logger);
    setRtc(webrtc);
  }
  , [loggerId]);
  return rtc;
}
