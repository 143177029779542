import React, { Component } from 'react';
import { QueryRenderer } from 'react-relay';

import moment from 'moment';
import { ReactRelayContext } from 'relay-hooks';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import { myMeetingsQuery } from '../../lib/api/relay/__generated__/myMeetingsQuery.graphql';
import { myMeetings } from '../../lib/api/relay/myMeetings';

import View from './View';

import style from './style';


type Props = {}

type PropsType = Props & WithStyles<typeof style>;
class MeetingsBoard extends Component<PropsType, {}> {

  static contextType = ReactRelayContext;

  renderer = ({ error, props }: { error: Error | null; props?: myMeetingsQuery['response'] | null }) => {
    if (error) {
      return null;
    } else if (props) {
      return (
        <View
          data={props}
        />
      );
    } else {
      return (
        <div className={this.props.classes.meetingListProgressContainer}>
          <CircularProgress />
        </div>
      );
    }
  }

  render() {
    const relayEnvironment = this.context.environment;

    return (
      <QueryRenderer<myMeetingsQuery>
        environment={relayEnvironment}
        query={myMeetings}
        cacheConfig={{ force: true }}
        variables={{
          //first: this.state.pageSize,
          sorting: [{
            direction: "ASC",
            sort: "dtStart"
          }],
          filters: [{
            value: moment().startOf('month').toISOString(),
            key: "dtStart",
            match: "GTE"
          }, {
            value: moment().endOf('month').toISOString(),
            key: "dtEnd",
            match: "LTE"
          }]
        }}
        render={this.renderer}
      />
    );
  }
}

export default withStyles(style)(MeetingsBoard);
