import React from 'react';
import Draggable from 'react-draggable';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import sizeMe, { SizeMeProps } from 'react-sizeme';

import { Typography, Box } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { subscribeToVideo } from '../../lib/actions/room';
import { VideoRoom } from '../../lib/api/videoroom';
import gridElements from '../../lib/grid';
import { State } from '../../lib/reducers';
import { WebSocketReady } from '../../lib/reducers/websocket';
import { RosterUser } from '../../lib/redux_types';
import {
  findRoomOwner,
  hasStream,
  hasScreen
} from '../../lib/reduxSelectors/presentationLayout';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { IconWebinarEmptyState } from '../IconSet';
import LoadingVideoElement from '../VideoElement/LoadingVideoElement';

import { getStreams } from './StandardDimensionLayout';
import usePublishersWatcher from './usePublishersWatcher';


const messages = defineMessages({
  webinarPresenterWithoutVideo: { id: 'webinarPresenterWithoutVideo' },
  webinarPresenterAbsent: { id: 'webinarPresenterAbsent' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    participantsContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    minivideo: {
      width: '250px',  // TODO?
      cursor: 'grab',
      "&:active": {
        cursor: 'grabbing',
      },
    },
    emptyState: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);


function MainVideo(props: ExtendedProps) {
  const {
    roomOwnerStream,
    roomOwnerHasStream,
    roomOwnerScreen,
    roomOwnerHasScreen,
    roomOwner
  } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();

  let uid = null;
  let stream = null;

  if (roomOwnerHasScreen) {
    uid = `${roomOwner}_screen`;
    stream = roomOwnerScreen;
  }
  else if (roomOwnerHasStream) {
    uid = roomOwner;
    stream = roomOwnerStream;
  }

  if (!uid || !stream) {
    const message = formatMessage(roomOwner
      ? messages.webinarPresenterWithoutVideo
      : messages.webinarPresenterAbsent
    );
    return (
      <Box className={classes.emptyState} p={1}>
        <IconWebinarEmptyState size={192} />
        <Typography variant='subtitle1'>
          { message }
        </Typography>
      </Box>
    );
  }

  return (
    <LoadingVideoElement
      user={uid}
      mirrored={false}
      addVideoMutedIconOverlay={true}
      stream={stream}
    />
  );
}


function MiniVideo(props: ExtendedProps) {
  const { roomOwnerHasStream, roomOwnerHasScreen, roomOwner, roomOwnerStream } = props;

  const classes = useStyles();

  if (roomOwner && roomOwnerHasScreen && roomOwnerHasStream) {
    return (
      <Draggable
        bounds='parent'
        defaultPosition={{ x: 10, y: 10 }}
      >
        <div className={classes.minivideo}>
          <LoadingVideoElement
            user={roomOwner}
            rounded
            mirrored={false}
            addVideoMutedIconOverlay={false}
            stream={roomOwnerStream}
          />
        </div>
      </Draggable>
    );
  }
  else {
    return null;
  }

}


function PresenterView(props: ExtendedProps & SizeMeProps) {
  const {
    size,
    hasVideoStream,
    myUserId,
    localVideoStream,
    localScreenStream
  } = props;

  let numVideos = 0;
  numVideos = localVideoStream ? numVideos + 1 : numVideos;
  numVideos = localScreenStream ? numVideos + 1 : numVideos;

  if (!myUserId) {
    /* FIXME meh, just to make compiler happy */
    return null;
  }

  if (size.width && size.width > 0 && size.height && size.height > 0) {
    const grid = gridElements(size.width, size.height, numVideos);

    return (
      <div style={{ width: '100%', height: '100%' }}>
        { hasVideoStream &&
          <div style={grid[0]}>
            <LoadingVideoElement
              user={myUserId}
              mirrored={true}
              addVideoMutedIconOverlay={true}
              stream={localVideoStream}
            />
          </div>
        }
        { localScreenStream &&
          <div style={grid[1]}>
            <LoadingVideoElement
              user={`${myUserId}_screen`}
              mirrored={false}
              addVideoMutedIconOverlay={false}
              stream={localScreenStream}
            />
          </div>
        }
      </div>
    );
  }
  else {
    return null;
  }
}


const SizedPresenterView = sizeMe({ monitorHeight: true })(PresenterView);


function WebinarLayout(props: ExtendedProps) {
  const { myUserId, roomOwner } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const newPublishers = usePublishersWatcher();
  newPublishers.forEach((u) => {
    if (u.uid !== roomOwner) {
      // we are only interested in roomOwner/presenter streams
      return;
    }
    if (u.stream) {
      dispatch(subscribeToVideo(u.uid));
    }
    if (u.screen) {
      dispatch(subscribeToVideo(`${u.uid}_screen`));
    }
  });

  if (myUserId === roomOwner) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <SizedPresenterView {...props} />
      </div>
    );
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <MainVideo {...props} />
      <div className={classes.participantsContainer}>
        <MiniVideo {...props} />
      </div>
    </div>
  );
}


type MappedProps = Pick<State['room'], 'audio_stream'>
& {
  hasVideoStream: boolean;
  localVideoStream: State['room']['localvideo_stream'];
  localScreenStream: State['room']['screenStream'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remoteVideoStreams: any; // FIXME
  myUserId: WebSocketReady['uid'];
  roomOwner: null | string;
  roomOwnerHasScreen: boolean;
  roomOwnerHasStream: boolean;
  roomOwnerScreen: RosterUser['screen'];
  roomOwnerStream: RosterUser['stream'];
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }

  const roomOwner = findRoomOwner(state);

  const roomOwnerHasStream = roomOwner ? hasStream(state, roomOwner) : false;
  const roomOwnerHasScreen = roomOwner ? hasScreen(state, roomOwner) : false;
  const roomOwnerStream = roomOwner ? state.room.roster[roomOwner].stream : null;
  const roomOwnerScreen = roomOwner ? state.room.roster[roomOwner].screen : null;

  return {
    // eslint-disable-next-line @typescript-eslint/camelcase
    audio_stream: state.room.audio_stream,
    myUserId: getWsUserId(state.websocket),
    localVideoStream: state.room.localvideo_stream,
    localScreenStream: state.room.screenStream,
    remoteVideoStreams: getStreams(state),
    hasVideoStream,
    roomOwner,
    roomOwnerHasStream,
    roomOwnerHasScreen,
    roomOwnerStream,
    roomOwnerScreen,
  };
};


export default connect(mapStateToProps)(WebinarLayout);
