import { VideoRoom } from '../../../lib/api/videoroom';
import { State } from '../../../lib/reducers';
import { WebSocketReady } from '../../../lib/reducers/websocket';
import {
  RoomLayout,
  RoomLayoutConfig,
  VideoQualityOptions,
} from '../../../lib/redux_types';
import {
  findRoomOwner,
  hasScreen,
  hasStream,
  findSelectedStream,
  findAScreen,
  findAStream,
} from '../../../lib/reduxSelectors/presentationLayout';
import { amModerator } from '../../../lib/reduxSelectors/room';
import { shouldMirrorVideo } from '../../../lib/reduxSelectors/settings';
import { getWsUserId } from '../../../lib/reduxSelectors/websocket';
import { getStreams } from '../StandardDimensionLayout';


export type MappedProps = {
  hasVideoStream: boolean;
  localVideoStream: State['room']['localvideo_stream'];
  localScreenStream: State['room']['screenStream'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remoteVideoStreams: any; // FIXME
  myUserId: WebSocketReady['uid'];
  layout: RoomLayout;
  layoutConfig: RoomLayoutConfig;
  selectedStream: null | string;
  myStreamIsSelected: boolean;
  myScreenIsSelected: boolean;
  fallbackStreamUid: null | string;
  fallbackScreenUid: null | string;
  roomOwner: null | string;   // uid of room owner (might be null if room owner leaves)
  roomOwnerHasScreen: boolean;
  roomOwnerHasStream: boolean;
  amIRoomModerator: boolean;
  configuredVideoQuality: undefined | VideoQualityOptions['streamQuality'];
  roomOptions: State['appconfig']['room_options'];
  shouldMirrorVideo: boolean;
}


export type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const myUserId = getWsUserId(state.websocket);

  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }

  const {
    selectedStream,
    myStreamIsSelected,
    myScreenIsSelected,
  } = findSelectedStream(state, hasVideoStream);

  const roomOwner = findRoomOwner(state);
  const roomOwnerHasStream = roomOwner ? hasStream(state, roomOwner) : false;
  const roomOwnerHasScreen = roomOwner ? hasScreen(state, roomOwner) : false;

  return {
    myUserId,
    localVideoStream: state.room.localvideo_stream,
    localScreenStream: state.room.screenStream,
    remoteVideoStreams: getStreams(state),
    hasVideoStream,
    layout: state.room.layout,
    layoutConfig: state.room.layoutConfig,
    selectedStream,
    myStreamIsSelected,
    myScreenIsSelected,
    fallbackScreenUid: selectedStream ? null : findAScreen(state),
    fallbackStreamUid: selectedStream ? null : findAStream(state),
    roomOwner,
    roomOwnerHasScreen,
    roomOwnerHasStream,
    amIRoomModerator: amModerator(state),
    configuredVideoQuality: state.settings.videoQuality ? state.settings.videoQuality.value : undefined,
    roomOptions: state.appconfig.room_options,
    shouldMirrorVideo: shouldMirrorVideo(state),
  };
};


export default mapStateToProps;
