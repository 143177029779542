const style = {
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    height: 'inherit',
    width: '100%',
    display: 'flex',
    background: 'black',
    borderRadius: '10px'
  },
  icon: {
    height: '100%',
    width: '100%',
    color: 'gray',
    padding: '10%',
  },
};

export default style;
