import React from 'react';
import sizeMe, { SizeMeProps } from 'react-sizeme';

import Roster from '../Roster';


type Props = {}


function AudioOnlyLayout(props: Props & SizeMeProps) {
  const { size } = props;

  const width = (size && size.width) ? size.width : undefined;

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Roster size={width} />
    </div>
  );
}


export default sizeMe({ monitorHeight: true })(AudioOnlyLayout);
