import React from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';

import { SupportLinksContext } from '../../lib/contexts';
import { State } from '../../lib/reducers';
import { getIsAuthenticated, isAuthReady } from '../../lib/reduxSelectors/auth';
import EnterMeeting from '../EnterMeeting';
import MeetingsBoard from '../MeetingsBoard';
import Spinner from '../Spinner';
import StartMeetingHeader from '../StartMeetingHeader';
import SupportInfo from '../SupportInfo';


const styles = (_theme: Theme) =>
  createStyles({
    main: {
      padding: 0,
      height: '100%'
    },
    noPadding: {
      padding: 0,
    },
  });


type MappedProps = {
  isAuthenticated: boolean;
  isAuthReady: boolean;
}


type Props = {} & MappedProps;

const mapStateToProps = (state: State): MappedProps => ({
  isAuthenticated: getIsAuthenticated(state.auth),
  isAuthReady: isAuthReady(state.auth)
});


class HomePage extends React.Component<Props & WithStyles<typeof styles>, {}> {
  render() {
    const { classes, isAuthReady, isAuthenticated } = this.props;

    if (!isAuthReady) {
      return <Spinner />;
    }

    if (!isAuthenticated) {
      return (
        <EnterMeeting />
      );
    }

    return (
      <React.Fragment>
        <Box display="flex" flexDirection="column" overflow="auto" p={1} className={classes.main}>
          <Box p={1} flexGrow={1} className={classes.noPadding}>
            <StartMeetingHeader />
          </Box>
          <Box p={1} flexGrow={8} className={classes.noPadding}>
            <MeetingsBoard />
          </Box>
          <Box display="flex" p={1} justifyContent="center">
            <SupportLinksContext.Provider value='home_page'>
              <SupportInfo />
            </SupportLinksContext.Provider>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}


export default withStyles(styles)(connect(mapStateToProps)(HomePage));
