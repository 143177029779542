import { commitMutation } from 'react-relay';

import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';
import { ConnectionHandler } from 'relay-runtime';

import {
  MeetingInputUpdate,
  updateMeetingMutation,
  updateMeetingMutationResponse
} from './__generated__/updateMeetingMutation.graphql';
import { MeetingParams as ScheduleMeetingParams } from './scheduleMeeting';
import { handleSchedulingError } from './utils/errors';


export type MeetingParams = ScheduleMeetingParams & { id: MeetingInputUpdate['id'] }


const mutation = graphql`
mutation updateMeetingMutation(
  $input: UpdateMeetingInput!
) {
  updateMeeting(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title,
      dtStart,
      dtEnd,
      attendees {
        email
      },
      notes
    }
  }
}
`;


function maybeNotifyError(response: updateMeetingMutationResponse | null) {
  if (!response || !response.updateMeeting || response.updateMeeting.errors !== null) {
    handleSchedulingError(response ? response.updateMeeting : null);
  }
}


function updateMeeting(
  environment: IEnvironment,
  { id, title, userId, domainId, dtStart, dtEnd, users, notes, type, meta }: MeetingParams
) {
  const attendees = users.value.map((el: string) => {
    return ({ email: el });
  });
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          id,
          title,
          userId,
          domainId,
          dtStart,
          dtEnd,
          attendees,
          notes,
          type,
          meta
        }
      },
    };

    /*const optimisticResponse = {
      updateMeeting: variables.input,
    };*/

    commitMutation<updateMeetingMutation>(
      environment,
      {
        mutation,
        variables,
        updater: (store) => {
          const payload = store.getRootField("updateMeeting");

          const model = payload.getLinkedRecord("meeting");
          const meet = { id: 'client:root' };
          const parent = store.get(meet.id);
          if (parent) {
            const connection = ConnectionHandler.getConnection(parent, "data_myMeetings");
            if (connection) {
              // a date can be updated, need to refresh connection from scratch to preserve order...
              ConnectionHandler.insertEdgeAfter(connection, model);
            }
          }
        },
        //optimisticResponse,
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { updateMeeting };
