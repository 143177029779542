import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';

import { toggleInviteParticipantsDialog } from '../../lib/actions/room';
import { State } from '../../lib/reducers';
import { getIsAuthenticated, getIsAuthenticatedAsGuest } from '../../lib/reduxSelectors/auth';
import { showMobileGuestLogin } from '../../lib/reduxSelectors/meeting';
import { isMyPersonalRoom } from '../../lib/reduxSelectors/room';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { getWsJoinFailure } from '../../lib/reduxSelectors/websocket';
import { useMountEffect } from '../../lib/utils/hooks';
import InviteToMeetingDialog from '../InviteToMeetingDialog';
import MeetingLeftDialog from '../MeetingLeftDialog';
import OwnMeetingControls from '../OwnMeetingControls';

import BlockNavigation from './BlockNavigation';
import DesktopControl from './DesktopControl';
import JoinErrorDialog from './Dialogs/JoinErrorDialog';
import RoomErrorDialog from './Dialogs/RoomErrorDialog';
import ExpiringMeetingDialog from './ExpiringMeetingDialog';
import LockedJoinRequestDialog from './LockedJoinRequestDialog';
import MeetingTitle from './MeetingTitle';
import Pip from './Pip';
import Room from './Room';
import ShortcutKeys from './ShortcutKeys';
import WaitingRoom from './WaitingRoom';


function MeetingContent(props: ExtendedProps) {
  const {
    isRecorder,
    showMobileGuestLogin,
    joinFailed,
    showInviteOnJoin,
    lockedRequestDialogIsShown
  } = props;
  const dispatch = useDispatch();

  const canJoinRoom = isRecorder || props.canJoinRoom;

  const showControls = !isMobileOnly && canJoinRoom && !showMobileGuestLogin;

  const showInviteDialog = () => {
    if (canJoinRoom && showInviteOnJoin && !isMobileOnly && !lockedRequestDialogIsShown) {
      dispatch(toggleInviteParticipantsDialog());
    }
  };

  useMountEffect(showInviteDialog, showInviteOnJoin);

  const getRoomContent = () => {
    return (
      <React.Fragment>
        <Room />
        <ExpiringMeetingDialog  />
        <ShortcutKeys />
        {showControls && <OwnMeetingControls />}
        <InviteToMeetingDialog />
        <MeetingLeftDialog />
        <LockedJoinRequestDialog />
        <BlockNavigation />
        <Pip />
        <DesktopControl />
      </React.Fragment>
    );
  };

  if (joinFailed) {
    return (
      <React.Fragment>
        <MeetingTitle />
        <JoinErrorDialog />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <MeetingTitle />
        <RoomErrorDialog />
        {!canJoinRoom && <WaitingRoom />}
        {canJoinRoom && getRoomContent()}
      </React.Fragment>
    );
  }
}


type Props = {
  url: string;
}

type MappedProps = {
  isRecorder: boolean;
  showMobileGuestLogin: boolean;
  isLoggedIn: boolean;
  canJoinRoom: boolean;
  joinFailed: boolean;
  showInviteOnJoin: boolean;
  lockedRequestDialogIsShown: boolean;
}

const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  const lockedRequestDialogIsShown = !state.notifications.lockedJoinRequestDialogMinimized
    && state.notifications.lockedJoinRequests.length > 0;
  return {
    isRecorder: isRecorder(state, ownProps.url),
    showMobileGuestLogin: showMobileGuestLogin(state),
    isLoggedIn: getIsAuthenticated(state.auth) || getIsAuthenticatedAsGuest(state.auth),
    canJoinRoom: state.waitingRoom.canJoinRoom,
    joinFailed: getWsJoinFailure(state.websocket),
    showInviteOnJoin: isMyPersonalRoom(state),
    lockedRequestDialogIsShown: lockedRequestDialogIsShown,
  };
};

type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(MeetingContent);
