import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import { State } from '../../lib/reducers';
import { Meeting } from '../../lib/reducers/websocket';
import { PaneType } from '../../lib/redux_types';
import {
  isAudioOnlyLayout,
  isThereAnyVideo,
  amModerator,
  isRosterOpen
} from '../../lib/reduxSelectors/room';
import { disableRoster } from '../../lib/reduxSelectors/roster';
import { getWsMeetingDetails } from '../../lib/reduxSelectors/websocket';
import { ChatIcon } from '../Chat';
import LockedJoinRequestsButton from '../LockedJoinRequestsButton';
import LockRoom from '../LockRoom';
import MenuLayout from '../MenuLayout';
import RosterIcon from '../Roster/RosterIcon';


const messages = defineMessages({
  chatIconButtonTooltip: { id: 'chatIconButtonTooltip' },
  attendees: { id: 'attendees' },
});


type Props = {
  openPane: (pane: PaneType) => void;
}

type ExtendedProps = Props & MappedProps


function MeetingBar(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const { isRosterOpen, openPane, meetingType, disableRoster } = props;

  const cannotChangeLayout = (
    !props.isThereAnyVideo
    || (props.isAudioOnlyLayout && !props.amModerator)
    || meetingType !== "regular"
  );

  const onRosterClick = React.useCallback(
    () => openPane(PaneType.Roster)
    , [openPane]
  );

  const onChatClick = React.useCallback(
    () => openPane(PaneType.Chat)
    , [openPane]
  );

  React.useEffect(
    () => {
      if (isRosterOpen && disableRoster) {
        openPane(PaneType.None);
      }
    }, [openPane, isRosterOpen, disableRoster]
  );

  return (
    <List>
      <ListItem
        disabled={disableRoster}
        button
        onClick={onRosterClick}
      >
        <Tooltip
          placement="left"
          title={formatMessage(messages.attendees)}
        >
          <ListItemIcon>
            <RosterIcon />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
      <ListItem button onClick={onChatClick}>
        <Tooltip
          placement="left"
          title={formatMessage(messages.chatIconButtonTooltip)}
        >
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
      <MenuLayout disabled={cannotChangeLayout} />
      <LockRoom />
      <LockedJoinRequestsButton />
    </List>
  );
}

type MappedProps = {
  isThereAnyVideo: boolean;
  isAudioOnlyLayout: boolean;
  amModerator: boolean;
  meetingType: Meeting['type'];
  disableRoster: boolean;
  isRosterOpen: boolean;
}


const mapStateToProps = (state: State): MappedProps => {
  let meetingType: Meeting['type'] = "regular";
  const meetingDetails = getWsMeetingDetails(state.websocket);
  if (meetingDetails) {
    meetingType = meetingDetails.type;
  }
  return {
    isThereAnyVideo: isThereAnyVideo(state),
    isAudioOnlyLayout: isAudioOnlyLayout(state),
    amModerator: amModerator(state),
    meetingType,
    disableRoster: disableRoster(state),
    isRosterOpen: isRosterOpen(state),
  };
};


export default connect(mapStateToProps)(MeetingBar);
