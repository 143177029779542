import * as Tone from 'tone';


function start(deviceId: null | string, audio: HTMLAudioElement) {
  let sopranoSynth: Tone.Synth;
  let bassSynth: Tone.Synth;

  if (deviceId) {
    // if we have a device, redirect output to it
    const destination = Tone.context.createMediaStreamDestination();

    audio.srcObject = destination.stream;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore FIXME
    audio.setSinkId(deviceId);
    audio.play();

    sopranoSynth = new Tone.Synth().connect(destination);
    bassSynth = new Tone.Synth().connect(destination);
  }
  else {
    sopranoSynth = new Tone.Synth().toDestination();
    bassSynth = new Tone.Synth().toDestination();
  }

  Tone.Transport.bpm.value = 130;
  Tone.Transport.start();

  const sopranoNotes = [
    // BAR 1
    { note: "E5", dur: "4n", time: "0:0" },

    { note: "B4", dur: "8n", time: "0:1:0" },
    { note: "C5", dur: "8n", time: "0:1:2" },

    { note: "D5", dur: "8n", time: "0:2" },
    { note: "E5", dur: "16n", time: "0:2:2" },
    { note: "D5", dur: "16n", time: "0:2:3" },

    { note: "C5", dur: "8n", time: "0:3" },
    { note: "B4", dur: "8n", time: "0:3:2" },

    // BAR 2
    { note: "A4", dur: "4n", time: "1:0" },

    { note: "A4", dur: "8n", time: "1:1" },
    { note: "C5", dur: "8n", time: "1:1:2" },

    { note: "E5", dur: "4n", time: "1:2" },

    { note: "D5", dur: "8n", time: "1:3" },
    { note: "C5", dur: "8n", time: "1:3:2" },

    // BAR 3
    { note: "B4", dur: "4n.", time: "2:0" },
    { note: "C5", dur: "8n", time: "2:1:2" },
    { note: "D5", dur: "4n", time: "2:2" },
    { note: "E5", dur: "4n", time: "2:3" },

    // BAR 4
    { note: "C5", dur: "4n", time: "3:0" },
    { note: "A4", dur: "4n", time: "3:1" },
    { note: "A4", dur: "2n", time: "3:2" },

    // BAR 5
    // 8n rest
    { note: "D5", dur: "4n", time: "4:0:2" },
    { note: "F5", dur: "8n", time: "4:1:2" },
    { note: "A5", dur: "4n", time: "4:2" },
    { note: "G5", dur: "8n", time: "4:3" },
    { note: "F5", dur: "8n", time: "4:3:2" },

    // BAR 6
    { note: "E5", dur: "4n", time: "5:0:0" },
    { note: "C5", dur: "8n", time: "5:1:2" },
    { note: "E5", dur: "4n", time: "5:2" },
    { note: "D5", dur: "8n", time: "5:3" },
    { note: "C5", dur: "8n", time: "5:3:2" },

    // BAR 7
    { note: "B4", dur: "4n", time: "6:0" },
    { note: "B4", dur: "8n", time: "6:1" },
    { note: "C5", dur: "8n", time: "6:1:2" },
    { note: "D5", dur: "4n", time: "6:2" },
    { note: "E5", dur: "4n", time: "6:3" },

    // BAR 8
    { note: "C5", dur: "4n", time: "7:0" },
    { note: "A4", dur: "4n", time: "7:1" },
    { note: "A4", dur: "4n", time: "7:2" },

  ];


  const bassNotes = [
    // BAR 1
    { note: "E2", dur: "8n", time: "0:0" },
    { note: "E3", dur: "8n", time: "0:0:2" },
    { note: "E2", dur: "8n", time: "0:1" },
    { note: "E3", dur: "8n", time: "0:1:2" },
    { note: "E2", dur: "8n", time: "0:2" },
    { note: "E3", dur: "8n", time: "0:2:2" },
    { note: "E2", dur: "8n", time: "0:3" },
    { note: "E3", dur: "8n", time: "0:3:2" },

    // BAR 2
    { note: "A2", dur: "8n", time: "1:0" },
    { note: "A3", dur: "8n", time: "1:0:2" },
    { note: "A2", dur: "8n", time: "1:1" },
    { note: "A3", dur: "8n", time: "1:1:2" },
    { note: "A2", dur: "8n", time: "1:2" },
    { note: "A3", dur: "8n", time: "1:2:2" },
    { note: "A2", dur: "8n", time: "1:3" },
    { note: "A3", dur: "8n", time: "1:3:2" },

    // BAR 3
    { note: "G#2", dur: "8n", time: "2:0" },
    { note: "G#3", dur: "8n", time: "2:0:2" },
    { note: "G#2", dur: "8n", time: "2:1" },
    { note: "G#3", dur: "8n", time: "2:1:2" },
    { note: "E2", dur: "8n", time: "2:2" },
    { note: "E3", dur: "8n", time: "2:2:2" },
    { note: "E2", dur: "8n", time: "2:3" },
    { note: "E3", dur: "8n", time: "2:3:2" },

    // BAR 4
    { note: "A2", dur: "8n", time: "3:0" },
    { note: "A3", dur: "8n", time: "3:0:2" },
    { note: "A2", dur: "8n", time: "3:1" },
    { note: "A3", dur: "8n", time: "3:1:2" },
    { note: "A2", dur: "8n", time: "3:2" },
    { note: "A3", dur: "8n", time: "3:2:2" },
    { note: "B2", dur: "8n", time: "3:3" },
    { note: "C3", dur: "8n", time: "3:3:2" },

    // BAR 5
    { note: "D2", dur: "8n", time: "4:0" },
    { note: "D3", dur: "8n", time: "4:0:2" },
    // 8n rest
    { note: "D2", dur: "8n", time: "4:1:2" },
    // 8n rest
    { note: "D2", dur: "8n", time: "4:2:2" },
    { note: "A2", dur: "8n", time: "4:3" },
    { note: "F2", dur: "8n", time: "4:3:2" },

    // BAR 6
    { note: "C2", dur: "8n", time: "5:0" },
    { note: "C3", dur: "8n", time: "5:0:2" },
    // 8n rest
    { note: "C3", dur: "8n", time: "5:1:2" },
    { note: "C2", dur: "8n", time: "5:2" },
    { note: "G3", dur: "8n", time: "5:2:2" },
    // 8n rest
    { note: "G3", dur: "8n", time: "5:3:2" },

    // BAR 7
    { note: "B2", dur: "8n", time: "6:0" },
    { note: "B3", dur: "8n", time: "6:0:2" },
    // 8n rest
    { note: "B3", dur: "8n", time: "6:1:2" },
    // 8n rest
    { note: "E3", dur: "8n", time: "6:2:2" },
    // 8n rest
    { note: "G#3", dur: "8n", time: "6:3:2" },

    // BAR 8
    { note: "A2", dur: "8n", time: "7:0" },
    { note: "E2", dur: "8n", time: "7:0:2" },
    { note: "A2", dur: "8n", time: "7:1" },
    { note: "E2", dur: "8n", time: "7:1:2" },
    { note: "A2", dur: "4n", time: "7:2" },
  ];

  const soprano = new Tone.Part(function(time, event) {
    sopranoSynth.triggerAttackRelease(event.note, event.dur as string, time);
  }, sopranoNotes);
  soprano.loop = true;
  soprano.loopEnd = '8m';

  const bass = new Tone.Part(function(time, event) {
    bassSynth.triggerAttackRelease(event.note, event.dur as string, time);
  }, bassNotes);
  bass.loop = true;
  bass.loopEnd = '8m';

  soprano.start(0);
  bass.start(0);

  return () => {
    Tone.Transport.stop();
    bassSynth.dispose();
    sopranoSynth.dispose();
    soprano.dispose();
    bass.dispose();
  };
}

export class Tetris {
  stopCb: null | (() => void)
  audio: HTMLAudioElement;

  constructor() {
    this.stopCb = null;
    this.audio = new Audio();
  }

  start(deviceId: null | string) {
    this.stopCb = start(deviceId, this.audio);
  }

  stop() {
    if (this.stopCb) {
      this.stopCb();
      this.stopCb = null;
    }
  }
}
