import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureContext, Severity } from "@sentry/types";
import { History } from 'history';

import { version } from '../package.json';

import { Tracer, initTracer } from "./lib/tracer";
import { getApplicationEnvironment } from "./lib/utils/environment";


const dsnDev = 'https://da750a419a014e8cb5887d75fe08552f@sentry.voismart.net/3';
const dsnProd = 'https://ed479faefeeb4f8ab04ee6418fcf8aeb@sentry.voismart.net/2';

function captureMessage(message: string,  captureContext?: CaptureContext | Severity) {
  Sentry.captureMessage(message, captureContext);
}

function unsetUser() {
  Sentry.configureScope(scope => scope.setUser(null));
}


export function startTracer(history: History) {
  let dsn;
  let release;
  let env;

  const tracer: Tracer = {
    setUser: Sentry.setUser,
    unsetUser: unsetUser,
    captureMessage: captureMessage
  };

  initTracer(tracer);


  if (getApplicationEnvironment() === 'prod') {
    env = 'production';
    dsn = dsnProd;
    release = "holocom-client@" + version;
  } else {
    env = 'development';
    dsn = dsnDev;
    release = "holocom-client-dev@" + version;
  }

  Sentry.init({
    dsn: dsn,
    release: release,
    environment: env,
    integrations: [new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })],
    tracesSampleRate: 1.0
  });
}
