export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function rightmostSplit(string, needle) {
  const idx = string.lastIndexOf(needle);

  if (idx >= 0) {
    const rest = string.substring(0, idx);

    const last = string.substring(idx + 1, string.length);
    return [rest, last];
  } else {
    return [string];
  }
}
