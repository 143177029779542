import React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import { withStyles, WithStyles } from '@material-ui/core/styles';

import { Event } from './';

import styles from './styles';


export interface DayData {
  isCurrentMonth: boolean;
  isToday: boolean;
  date: moment.Moment;
  number: number;
  events: Array<Event>;
  hasEvents: boolean;
}

interface DayProps {
  day: DayData;
  selected: moment.Moment | null;
  select: (day: DayData) => void;
}


type DayPropsType = DayProps & WithStyles<typeof styles>;
class Day extends React.Component<DayPropsType, {}> {
  constructor(props: DayPropsType) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.select(this.props.day);
  }

  renderDay(day: DayData, selected: boolean, generatedClasses: Array<string>) {
    const classes = this.props.classes;
    if (selected === true) {
      return (
        <div className={classNames(generatedClasses)} onClick={this.onClick}>
          <div className={classes.daySelected}>
            {day.number}
          </div>
        </div>);
    }
    else {
      if (day.hasEvents) {
        generatedClasses.push(classes.hasEvents);
      }
      return (
        <div className={classNames(generatedClasses)} onClick={this.onClick}>
          <div className={classes.dayNumber}>
            {day.number}
          </div>
        </div>);
    }
  }

  render() {
    const day = this.props.day;
    const selected = this.props.selected;
    const classes = this.props.classes;
    const generatedClasses: Array<string> = [];
    const isPast = day.date.isBefore(moment(), 'day');
    let isDaySelected = false;
    if (selected !== null && day.date.isSame(selected)) {
      isDaySelected = true;
      generatedClasses.push(classes.daySelectedWrapper);
    }
    else {
      generatedClasses.push(classes.day);
    }

    if (day.isToday) {
      generatedClasses.push(classes.today);
    }
    if (!day.isCurrentMonth) {
      generatedClasses.push(classes.differentMonth);
    }
    if (isPast) {
      generatedClasses.push(classes.dayDisabled);
    }
    return (this.renderDay(day, isDaySelected, generatedClasses));
    /*return (
      <div
        className={classNames(generatedClasses)}
        onClick={this.onClick}
      >
        <div className={classes.dayNumber}>
          {day.number}
        </div>
      </div>
    );*/
  }
}

export default withStyles(styles)(Day);
