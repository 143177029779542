import React, { CSSProperties } from 'react';
import { withOrientationChange } from 'react-device-detect';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';

import { State } from '../../../lib/reducers';
import { PaneType } from '../../../lib/redux_types';
import { OrientationProps } from '../../../lib/utils/types';
import OwnMeetingControls from '../../OwnMeetingControls';
import MeetingContent from '../MeetingContent';
import MeetingMobileBar from '../MeetingMobileBar';

import PaneComponent from './PaneComponent';

import useStyles from './styles';


type Props = {
  openPane: (pane: PaneType) => void;
  url: string;
}

type MappedProps = {
  canJoinRoom: boolean;
}

type ExtendedProps = Props & MappedProps & OrientationProps


const mapStateToProps = (state: State): MappedProps => {
  return {
    canJoinRoom: state.waitingRoom.canJoinRoom,
  };
};

function Pane(props: ExtendedProps) {
  const classes = useStyles();
  const {
    openPane,
    url,
    isLandscape,
    canJoinRoom
  } = props;

  // bypass style settings on waiting room (inherits WR styles)
  if (!canJoinRoom) {
    return (
      <MeetingContent url={url} />
    );
  }

  const fullDim = '100%';
  const containerHeight = isLandscape ? fullDim : 'calc(100% - 64px)';
  const videoWidth = isLandscape ? '55%' : fullDim;
  const videoHeight = isLandscape ? fullDim : 'calc(60% - 48px)';
  const paneWidth = isLandscape ? '45%' : fullDim;
  const paneHeight = isLandscape ? fullDim : 'calc(40% + 48px)';
  const paneComponentHeight = 'calc(100% - (48px + 60px))';
  const gridStyleLandscape: CSSProperties = { position: 'absolute', top: 0, left: 0, height: containerHeight };
  const gridStylePortrait: CSSProperties = { position: 'relative', height: containerHeight };
  const gridStyle: CSSProperties = isLandscape ? gridStyleLandscape : gridStylePortrait;

  return (
    <Grid container
      spacing={0}
      direction={isLandscape ? 'column' : 'row'}
      style={gridStyle}
    >
      <Grid item
        className={classes.paneMobileBase}
        style={{ width: videoWidth, height: videoHeight }}
      >
        <MeetingContent url={url} />
      </Grid>
      <Grid container
        spacing={0}
        direction={'row'}
        className={classes.paneMobileBase}
        style={{ width: paneWidth, height: paneHeight }}
      >
        <Grid item
          className={classes.paneMobileBase}
          style={{ width: fullDim, height: '48px' }}
        >
          <MeetingMobileBar openPane={openPane} />
        </Grid>
        <Grid item
          className={classes.paneMobileBase}
          style={{ width: fullDim, height: paneComponentHeight }}>
          <PaneComponent size={'100%'} />
        </Grid>
        <Grid item
          className={classes.paneMobileBase}
          style={{ width: fullDim, height: 'auto' }}>
          <OwnMeetingControls />
        </Grid>
      </Grid>
    </Grid>
  );
}


export default withOrientationChange(connect(mapStateToProps)(Pane));
