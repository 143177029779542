export const RELAY_REQUEST = 'RELAY_REQUEST';
export const RELAY_DONE = 'RELAY_DONE';

export type Action =
  RelayRequest
  | RelayDone


interface RelayRequest {
  type: typeof RELAY_REQUEST;
  payload: {
    lastRequestId: string;
    operation: string;
  };
}

interface RelayDone {
  type: typeof RELAY_DONE;
  payload: {
    lastRequestId: string;
    operation: string;
    error: Error;
  };
}

export function relayRequest(reqId: string, operation: string): RelayRequest {
  return {
    type: RELAY_REQUEST,
    payload: {
      lastRequestId: reqId,
      operation: operation
    }
  };
}

export function relayDone(reqId: string, operation: string, error: Error): RelayDone {
  return {
    type: RELAY_DONE,
    payload: {
      lastRequestId: reqId,
      operation: operation,
      error: error
    }
  };
}
