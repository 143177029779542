import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import moment from 'moment';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { roomErrorAcked } from '../../../lib/actions/room';
import { State } from '../../../lib/reducers';
import { WebSocketReady } from '../../../lib/reducers/websocket';
import { getWsErrorCode, getWsErrorPayload } from '../../../lib/reduxSelectors/websocket';
import { getRoomNameFromParams } from '../../../lib/utils/router';
import ClosableDialog from '../../ClosableDialog';

import DeviceError from './utils/DeviceError';
import messages from './utils/messages';
import RetriableError from './utils/RetriableError';
import { backToHome, reconnect } from './utils/utils';


type MappedProps = {
  roomError: State['room']['roomError'];
  errorCode: WebSocketReady['errorCode'];
  errorPayload: WebSocketReady['errorPayload'];
  snapshot: State['waitingRoom']['snapshot'];
  deviceError: State['room']['streamError'];
}

type Props = {};

type ExtendedProps = Props & MappedProps;


function isRetriableError(errorCode: MappedProps['errorCode']) {
  if (errorCode) {
    return [
      1408, // connection error
    ].includes(errorCode);
  }
  else {
    return false;
  }
}


function SchedulingMessage(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const { errorPayload } = props;

  if (errorPayload && errorPayload.dt_start && errorPayload.dt_end) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const [start, end] = [moment(errorPayload.dt_start), moment(errorPayload.dt_end)];
    const now = moment();

    let message = '';
    if (start.isSame(now, 'day')) {
      message = formatMessage(messages.tooEarlyMeetingIsTodayFromTo,
        { start: start.format('LT'), end: end.format('LT') });
    }
    else {
      message = formatMessage(messages.tooEarlyMeetingIsFromTo,
        { date: start.format('LL'), start: start.format('LT'), end: end.format('LT') });
    }
    return (
      <DialogContentText>
        {message}
      </DialogContentText>
    );
  }
  else {
    return null;
  }
}


function AvailableInMessage(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const { errorPayload } = props;

  if (errorPayload && errorPayload.dt_start) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const start = moment(errorPayload.dt_start);
    const now = moment();
    const time = moment(start).from(now);
    if (start.diff(now, 'seconds') < 0) {
      return null;
    }

    return (
      <DialogContentText>
        {formatMessage(messages.tooEarlyMeetingWillBeAvailableIn, { time: time })}
      </DialogContentText>
    );
  }
  else {
    return null;
  }
}


function RoomError(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const dismiss = React.useCallback(
    () => {
      dispatch(roomErrorAcked());
      if (props.roomError && !props.roomError.recoverable) {
        backToHome(history, true);
      }
    }
    , [history, props.roomError, dispatch]
  );

  const tryReconnect = () => {
    const slug = getRoomNameFromParams(params);
    if (slug) {
      reconnect(slug, dispatch, props.snapshot);
    }
  };

  const renderMsg = () => {
    const errorMap = {
      1408: messages.networkError,
      1409: messages.publishError,
      5004: messages.rpcTimeoutErrorContent,
      9000: messages.answerError,
    };
    const errCode = (props.roomError || {}).errorCode;

    const mappedError = errCode ? errorMap[errCode as keyof (typeof errorMap)] : null;
    let errmsg;
    if (mappedError) {
      errmsg = formatMessage(mappedError);
    }
    else {
      errmsg = 'Generic Error';
    }
    return formatMessage(messages.roomErrorContent, { errorMessage: errmsg });
  };

  const getButtons = () => {
    if (props.roomError && props.roomError.recoverable) {
      return (
        <Button variant='contained' onClick={dismiss} color='primary'>
          {formatMessage(messages.ok)}
        </Button>
      );
    }
    else {
      return (
        <Button variant='contained' onClick={tryReconnect} color='primary'>
          {formatMessage(messages.tryAgain)}
        </Button>
      );
    }
  };

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={dismiss}
      fullWidth
      title={formatMessage(messages.error)}
    >
      <DialogContent>
        <DialogContentText>
          {renderMsg()}
        </DialogContentText>
        <SchedulingMessage {...props} />
        <AvailableInMessage {...props} />
      </DialogContent>
      <DialogActions>
        {getButtons()}
      </DialogActions>
    </ClosableDialog>
  );
}


function RoomErrorDialog(props: ExtendedProps) {
  const { roomError, errorCode, deviceError } = props;

  if (deviceError) {
    return <DeviceError />;
  }

  if (roomError) {
    return <RoomError {...props} />;
  }

  if (isRetriableError(errorCode)) {
    return <RetriableError {...props} />;
  }

  return null;
}


function mapStateToProps(state: State) {
  return {
    roomError: state.room.roomError,
    errorCode: getWsErrorCode(state.websocket),
    errorPayload: getWsErrorPayload(state.websocket),
    snapshot: state.waitingRoom.snapshot,
    deviceError: state.room.streamError,
  };
}


export default connect(mapStateToProps)(RoomErrorDialog);
