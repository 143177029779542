export enum Event {
  LOGIN = 'login',
  JOIN_WITH_CODE = 'join_with_code',
  HOME = 'go_home',
  MEDIA_SETTINGS = 'open_media_settings',
  CHANGE_AUDIO_INPUT = 'devices_change_audio_in',
  CHANGE_AUDIO_OUTPUT = 'devices_change_audio_out',
  CHANGE_VIDEO_IN = 'devices_change_video_in',
  CHANGE_VIDEO_QUALITY = 'devices_change_video_quality',
  BACKGROUND_SETTINGS = 'open_background_settings',
  BLUR = 'enable_blur',
  BLUR_LOADING_TIME = 'blur_load_time',
  REFRESH_DEVICES = 'refresh_devices',
  USER_INFO = 'open_user_info',
  MY_PINS = 'open_my_pins',
  RENEW_MY_PINS = 'my_pins_renew',
  MY_RECORDINGS = 'open_my_recordings',
  FILTER_RECORDINGS = 'my_recordings_filter',
  DOWNLOAD_RECORDING = 'my_recording_download',
  PLAY_RECORDING = 'my_recording_play',
  DELETE_RECORDING = 'my_recording_delete',
  MY_LIVESTREAMING_SETTINGS = 'open_my_livestreaming_settings',
  SAVE_LIVESTREAMING_SETTINGS = 'my_livestreaming_settings_save',
  OTHER_SETTINGS = 'open_other_settings',
  ENABLE_DEFAULT_LOCK = 'enable_default_lock',
  SET_SCREEN_FRAMERATE = 'set_screen_framerate',
  ENABLE_PUSH_NOTIFICATIONS = 'enable_push_notifications',
  LOGOUT = 'logout',
  INVITE_USERS = 'open_invite_users',
  INVITE_BY_EMAIL = 'invite_by_email',
  INVITE_BY_PHONE = 'invite_by_dialout',
  COPY_MEETING_LINK = 'invite_by_copy_link',
  COPY_MEETING_DETAILS = 'invite_by_copy_details',
  START_PERSONAL_ROOM = 'meeting_personal_start',
  MOVE_BACK_IN_CALENDAR = 'calendar_move_back',
  MOVE_FORWARD_IN_CALENDAR = 'calendar_move_forward',
  SELECT_DAY_IN_CALENDAR = 'calendar_select_day',
  CREATE_MEETING = 'meeting_create',
  UPLOAD_EMAILS = 'meeting_upload_emails',
  UPDATE_MEETING = 'meeting_update',
  DELETE_MEETING = 'meeting_delete',
  START_SCHEDULED_MEETING = 'meeting_scheduled_start',
  READY_TO_JOIN = "meeting_ready_join",
  CANCEL_JOIN = 'meeting_cancel_join',
  MUTE_MY_MICROPHONE = 'my_microphone_mute',
  MUTE_MY_VIDEO = 'my_video_mute',
  SCREEN_SHARE = 'my_screen_share',
  MORE_CONTROLS = 'my_more_controls',
  RAISE_HAND = 'my_hand_raise',
  REMOTE_CONTROL = 'my_remote_control',
  DEAF = 'my_audio_deafen',
  TEST_AUDIO = 'test_audio',
  RECORD_VIDEO = 'record_video',
  RECORD_AUDIO = 'record_audio',
  STREAMING_VIDEO = 'streaming_video',
  MEETING_INFO = 'open_meeting_info',
  END_MEETING = 'meeting_end',
  MUTE_ALL_MICROPHONES = 'users_mute_all_microfones',
  MUTE_ALL_VIDEOS = 'users_mute_all_videos',
  OPEN_ROSTER = 'open_roster',
  FILTER_USERS = 'users_filter',
  MUTE_USER_MICROPHONE = 'user_mute_microphone',
  MUTE_USER_VIDEO = 'user_mute_video',
  FULLSCREEN = 'user_fullscreen',
  PICTURE_IN_PICTURE = 'user_pip',
  RESET_USER_STATE = 'user_state_reset',
  MAKE_MODERATOR = 'user_make_moderator',
  START_PRIVATE_CONFERENCE = 'user_private_conf',
  KICK_USER = 'user_kick',
  SET_MAIN_VIDEO = 'user_set_main_video',
  MAXIMIZE_FEATURED_VIDEO = 'user_maximize_featured_video',
  SCROLL_MINI_VIDEOS = 'user_scroll_mini_video',
  OPEN_CHAT = 'open_chat',
  SEND_CHAT_MESSAGE = 'chat_send_message',
  SEND_CHAT_FILE = 'chat_send_file',
  DOWNLOAD_CHAT_FILE = 'chat_download_file',
  START_PRIVATE_CHAT = 'chat_start_private',
  CHANGE_ROOM_LAYOUT = 'room_layout_change',
  LOCK_ROOM = 'room_lock',
  EXIT_MEETING = 'meeting_exit',
  EXTEND_MEETING_TIME = 'meeting_extend_time',
  HANDLE_LOCKED_REQUESTS = 'meeting_handle_locked_requests',
  LOCKED_REQUESTS = 'open_locked_requests',
  MOBILE_FULLSCREEN = 'mobile_fullscreen',
  MOBILE_SWITCH_STREAM = 'mobile_switch_stream',
  MOBILE_SHOW_APPBAR = 'mobile_show_appbar',
  MOBILE_SWITCH_CAMERA = 'mobile_switch_camera',
  MOBILE_CHANGE_FACING_MODE = 'mobile_change_facing_mode',
  SUPPORT_LINK = 'open_support_link',
  SHORTCUT_INFO = 'open_shortcut_info'
}
