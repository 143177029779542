import { CaptureContext, Severity, User } from "@sentry/types";


export interface Tracer {
  setUser: (user: User) => void;
  unsetUser: () => void;
  captureMessage: (message: string,  captureContext?: CaptureContext | Severity) => void;
}

let _tracer: Tracer = {
  setUser: () => undefined,
  unsetUser: () => undefined,
  captureMessage: () => undefined
};

export function initTracer(tracer: Tracer) {
  _tracer = tracer;
}

export function setTracedUser(id: string, username: string, email?: string, ipAddr?: string) {
  // eslint-disable-next-line @typescript-eslint/camelcase
  _tracer.setUser({ id: id, username: username, email: email, ip_address: ipAddr });
}


export function unsetTracedUser() {
  _tracer.unsetUser();
}

export function traceWSCloseEvent(event: CloseEvent) {
  if (event.wasClean === true) { return; }

  const data = {
    wasClean: event.wasClean,
    reason: event.reason,
    code: event.code,
    type: event.type
  };

  _tracer.captureMessage("WebSocket Closed", { extra: data });
}

export function traceError(message: string, event = {}) {
  _tracer.captureMessage(message, { extra: event });
}
