import { Component } from 'react';

import PropTypes from 'prop-types';


class ExternalRedirect extends Component {
  doRedirect = () => {
    const location = this.props.externalUrl;

    if (typeof document !== 'undefined') {
      if (location)
        window.location = location;
    }
  }

  render() {
    this.doRedirect();

    return null;
  }
}


ExternalRedirect.propTypes = {
  externalUrl: PropTypes.string.isRequired,
};


export default ExternalRedirect;
