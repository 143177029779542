import { Dispatch } from 'redux';
import { fetchQuery, IEnvironment, GraphQLTaggedNode } from 'relay-runtime';

import { sessionDetailsQuery } from '../api/relay/__generated__/sessionDetailsQuery.graphql';
import { getSession, SessionDetails } from '../api/relay/sessionDetails';
import { setTracedUser } from '../tracer';


export const SESSIONDETAILS_REQUEST = 'SESSIONDETAILS_REQUEST';
export const SESSIONDETAILS_FAILURE = 'SESSIONDETAILS_FAILURE';
export const SESSIONDETAILS_SUCCESS = 'SESSIONDETAILS_SUCCESS';
export const SESSIONDETAILS_CLEAR = 'SESSIONDETAILS_CLEAR';
export const SESSION_SETRECORDER = 'SESSION_SETRECORDER';
export const SESSION_SET_UPDATING = 'SESSION_SET_UPDATING';
export const SESSION_SET_ROOM_LOCK_PREFERENCE = 'SESSION_SET_ROOM_LOCK_PREFERENCE';
export const SESSION_SET_SCREENSHARE_FRAME_RATE = 'SESSION_SET_SCREENSHARE_FRAME_RATE';

export type Session = SessionDetails;


interface SessionDetailsAction {
  type: typeof SESSIONDETAILS_REQUEST;
  payload: {};
}


interface SessionDetailsFailureAction {
  type: typeof SESSIONDETAILS_FAILURE;
  payload: {
    errorMessage: string;
  };
  error: boolean;
}


interface SessionDetailsSuccessAction {
  type: typeof SESSIONDETAILS_SUCCESS;
  payload: {
    session: Session;
  };
}


interface SessionDetailsClearAction {
  type: typeof SESSIONDETAILS_CLEAR;
  payload: {};
}


interface SessionSetRecoredAction {
  type: typeof SESSION_SETRECORDER;
  payload: {
    is_recorder: boolean;
  };
}

interface SessionSetUpdatingAction {
  type: typeof SESSION_SET_UPDATING;
  payload: {
    key: string;
    isUpdating: boolean;
  };
}

interface SessionSetRoomLockPreferenceAction {
  type: typeof SESSION_SET_ROOM_LOCK_PREFERENCE;
  payload: {
    roomLockPreference: boolean;
  };
}

interface SessionSetScreenshareFrameRateAction {
  type: typeof SESSION_SET_SCREENSHARE_FRAME_RATE;
  payload: {
    screenshareFrameRate: number;
  };
}

export type Action =
  SessionDetailsAction
  | SessionDetailsFailureAction
  | SessionDetailsSuccessAction
  | SessionSetRecoredAction
  | SessionDetailsClearAction
  | SessionSetUpdatingAction
  | SessionSetRoomLockPreferenceAction
  | SessionSetScreenshareFrameRateAction


function requestSessionDetails(): SessionDetailsAction {
  return {
    type: SESSIONDETAILS_REQUEST,
    payload: {
    }
  };
}


function errorSessionDetails(errorMessage: string): SessionDetailsFailureAction {
  return {
    type: SESSIONDETAILS_FAILURE,
    payload: {
      errorMessage: errorMessage
    },
    error: true
  };
}


function successSessionDetails(sessionDetails: Session): (
  SessionDetailsSuccessAction | SessionDetailsFailureAction) {
  if (sessionDetails) {
    return {
      type: SESSIONDETAILS_SUCCESS,
      payload: {
        session: sessionDetails
      }
    };
  }
  else {
    return errorSessionDetails("null session");
  }
}


function traceUser(session: Session) {
  if (session) {
    setTracedUser(session.username + "@" + session.realm, session.username);
  }
}


export function setRecorder(isRecorder: boolean): SessionSetRecoredAction {
  return {
    type: SESSION_SETRECORDER,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_recorder: isRecorder
    }
  };
}

export function setUpdating(key: string, isUpdating: boolean): SessionSetUpdatingAction {
  return {
    type: SESSION_SET_UPDATING,
    payload: {
      key: key,
      isUpdating: isUpdating
    }
  };
}

export function setRoomLockPreference(roomLockPreference: boolean): SessionSetRoomLockPreferenceAction {
  return {
    type: SESSION_SET_ROOM_LOCK_PREFERENCE,
    payload: {
      roomLockPreference: roomLockPreference
    }
  };
}

export function setScreenshareFrameRate(screenshareFrameRate: number): SessionSetScreenshareFrameRateAction {
  return {
    type: SESSION_SET_SCREENSHARE_FRAME_RATE,
    payload: {
      screenshareFrameRate: screenshareFrameRate
    }
  };
}

export function fetchSessionDetails(relay: IEnvironment, session: GraphQLTaggedNode) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(requestSessionDetails());
    fetchQuery<sessionDetailsQuery>(relay, session, {})
      .then((res) => {
        const session = getSession(res);
        traceUser(session);
        dispatch(successSessionDetails(session));
      })
      .catch((err) => dispatch(errorSessionDetails(err.detail)));
  };
}
