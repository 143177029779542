import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageList: {
      flexGrow: 1,
      overflowY: 'auto',
    },
    tab: {
      textTransform: 'none',
      minWidth: theme.spacing(11),
      maxWidth: theme.spacing(11),
    },
    label: {
      overflow: 'hidden',
      minWidth: theme.spacing(11),
      maxWidth: theme.spacing(11),
      padding: theme.spacing(0, 1),
    },
    offline: {
      textAlign: 'center',
      padding: '2ex',
      fontStyle: 'italic'
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  })
);


export default useStyles;
