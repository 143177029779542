import React from 'react';
import { connect } from 'react-redux';
import { QueryRenderer } from 'react-relay';

import { useRelayEnvironment } from 'relay-hooks';

import {
  myProfileQuery as myProfileQueryType,
  myProfileQueryResponse
} from '../../lib/api/relay/__generated__/myProfileQuery.graphql';
import { myProfileQuery } from '../../lib/api/relay/myProfile';
import { State as SessionState } from '../../lib/reducers/session';

import Settings from './Settings';


type MappedProps = {
  isLivestreamingEnabled: boolean;
}

type Props = {
  onClose: () => void;
};

type ExtendedProps = Props & MappedProps

function UserLiveStreaming(ownProps: ExtendedProps) {
  const relayEnvironment = useRelayEnvironment();
  const { isLivestreamingEnabled } = ownProps;

  if (!isLivestreamingEnabled) {
    return null;
  }


  const getLivestreamingCmp = ({ error, props }:
    { error: Error | null; props: myProfileQueryResponse | null }) => {
    if (error) {
      return null;
    } else if (props) {
      return (
        <Settings
          onClose={ownProps.onClose}
          gqlError={error}
          gqlProps={props}
        />
      );
    } else {
      return null;
    }

  };

  return (
    <QueryRenderer<myProfileQueryType>
      environment={relayEnvironment}
      query={myProfileQuery}
      render={getLivestreamingCmp}
      variables={{}}
    />
  );
}

type State = {
  session: SessionState;
}


const mapStateToProps = (state: State): MappedProps => ({
  isLivestreamingEnabled: state.session.livestreamingEnabled || false,
});


export default connect(mapStateToProps)(UserLiveStreaming);
