import { isElectron } from 'react-device-detect';

import adapter from 'webrtc-adapter';

import detectedBrowser from './detectBrowser';


export default function prepareWebRtcProvider() {
  navigator.adapter = adapter;
  navigator.adapter.browserDetails.userAgent = navigator.userAgent;
  navigator.adapter.browserDetails.parsedUserAgent = detectedBrowser;

  if (!navigator.RTCPeerConnection) {
    navigator.RTCPeerConnection = window.RTCPeerConnection;
  }
  if (!navigator.requestScreen) {
    navigator.requestScreen = (options = {}) => {
      const browser = adapter.browserDetails.browser;
      const constraints = getScreenSharingConstraints(browser, options);

      if (!navigator.mediaDevices.getDisplayMedia) {
        return Promise.reject(new Error('no getDisplayMedia() available'));
      }
      return navigator.mediaDevices.getDisplayMedia(constraints);
    };
  }
  if (!navigator.getEmptyMediaStream) {
    navigator.getEmptyMediaStream = () => new MediaStream();
  }

  return navigator;
}

export const defaultScreenshareFrameRate = 3;

function getScreenSharingConstraints(_browser, options = {}) {
  const frameRate = options.frameRate ? options.frameRate : defaultScreenshareFrameRate;
  const maxWidth = options.maxWidth ? options.maxWidth : 1920;
  const maxHeight = options.maxHeight ? options.maxHeight : 1080;
  if (isElectron) {
    return {
      video: {
        mandatory: {
          chromeMediaSource: 'desktop',
          minFrameRate: frameRate,
          maxFrameRate: frameRate,
          maxWidth: maxWidth,
          maxHeight: maxHeight,
        },
      },
      audio: false,
    };
  }
  return {
    video: { frameRate: frameRate, width: { max: maxWidth }, height: { max: maxHeight } },
    audio: {
      sampleRate: 48000,
      sampleSize: 16,
      channelCount: 2
    },
  };
}
