import * as twgl from 'twgl.js';

import { constructPipeStreamAdapter, StreamAdapter, Frame } from "../../../../streamAdapters";


export const vertexShader = `
attribute vec2 attrPosition;
attribute vec2 attrTexCoord;
uniform vec2 uniResolution;
varying vec2 varTexCoord;

void main() {
    vec2 clipSpace = (2.0*attrPosition/uniResolution)-1.0;
    gl_Position = vec4(clipSpace * vec2(1,-1), 0, 1);
    varTexCoord = attrTexCoord;
}`;

const blur13Shader = `
precision mediump float;

uniform sampler2D uniTexture;
uniform vec2 uniDirection;
uniform vec2 shaderResolution;
varying vec2 varTexCoord;

void main() {
    vec2 off1 = vec2(1.4117647058823530) * uniDirection / shaderResolution;
    vec2 off2 = vec2(3.2941176470588234) * uniDirection / shaderResolution;
    vec2 off3 = vec2(5.1764705882352940) * uniDirection / shaderResolution;
    gl_FragColor = texture2D(uniTexture,varTexCoord) *       0.19648255015114040;
    gl_FragColor += texture2D(uniTexture,varTexCoord+off1) * 0.296906964672834400;
    gl_FragColor += texture2D(uniTexture,varTexCoord-off1) * 0.296906964672834400;
    gl_FragColor += texture2D(uniTexture,varTexCoord+off2) * 0.094470397850447320;
    gl_FragColor += texture2D(uniTexture,varTexCoord-off2) * 0.094470397850447320;
    gl_FragColor += texture2D(uniTexture,varTexCoord+off3) * 0.010381362401148057;
    gl_FragColor += texture2D(uniTexture,varTexCoord-off3) * 0.010381362401148057;
}`;

const bilateralShader = `
precision mediump float;

uniform sampler2D uniTexture;
uniform vec2 shaderResolution;
varying vec2 varTexCoord;

void main(void) {
  float kernel[15];
  kernel[0] = 0.03122521484862109; kernel[1] = 0.03332226981404129;
  kernel[2] = 0.03520633143170985; kernel[3] = 0.03682680352274845;
  kernel[4] = 0.03813856354024969; kernel[5] = 0.03910404587289969;
  kernel[6] = 0.03969502784491287; kernel[7] = 0.03989400000000000;
  kernel[8] = 0.03969502784491287; kernel[9] = 0.03910404587289969;
  kernel[10] = 0.03813856354024969; kernel[11] = 0.03682680352274845;
  kernel[12] = 0.03520633143170985; kernel[13] = 0.03332226981404129;
  kernel[14] = 0.03122521484862109;

  float normalization = 0.0;
  vec4 computedColor = vec4(0.0);
  vec4 textureColor = texture2D(uniTexture, varTexCoord);
  for (int i = -7; i <= 7; ++i) {
      vec2 coord = varTexCoord + vec2(i,i) / shaderResolution;
      vec4 currentColor = texture2D(uniTexture, coord);
      vec4 diff = currentColor - textureColor;
      float bfactor = exp(-0.5 * dot(diff, diff) / 0.01) * kernel[7+i];;
      normalization += bfactor;
      computedColor += bfactor * currentColor;
  }
  gl_FragColor = textureColor;
  gl_FragColor.rgb = computedColor.rgb / normalization;
}`;

const jointBilateralShader = `
precision mediump float;

uniform sampler2D uniTexture;
uniform sampler2D uniMask;
uniform vec2 shaderResolution;
varying vec2 varTexCoord;

void main(void) {
  float kernel[15];
  kernel[0] = 0.03122521484862109; kernel[1] = 0.03332226981404129;
  kernel[2] = 0.03520633143170985; kernel[3] = 0.03682680352274845;
  kernel[4] = 0.03813856354024969; kernel[5] = 0.03910404587289969;
  kernel[6] = 0.03969502784491287; kernel[7] = 0.03989400000000000;
  kernel[8] = 0.03969502784491287; kernel[9] = 0.03910404587289969;
  kernel[10] = 0.03813856354024969; kernel[11] = 0.03682680352274845;
  kernel[12] = 0.03520633143170985; kernel[13] = 0.03332226981404129;
  kernel[14] = 0.03122521484862109;

  float normalization = 0.0;
  float computedMask = 0.0;
  vec4 textureColor = texture2D(uniTexture, varTexCoord);
  for (int i = -7; i <= 7; ++i) {
      for (int j = -7; j <= 7; ++j) {
          vec2 coord = varTexCoord + vec2(i, j) / shaderResolution;
          vec4 currentColor = texture2D(uniTexture, coord);
          vec4 maskColor = texture2D(uniMask, coord);
          vec4 diff = currentColor - textureColor;
          float gfactor = kernel[7+i] * kernel[7+j];
          float bfactor = exp(-0.5 * dot(diff, diff) / 0.01) * gfactor;
          normalization += bfactor;
          computedMask += bfactor * maskColor.a;
        }
    }
  gl_FragColor = textureColor;
  gl_FragColor.a = computedMask / normalization;
}
`;

const lanczosShader = `
precision mediump float;

uniform sampler2D uniTexture;
uniform vec2 uniDirection;
uniform vec2 shaderResolution;
varying vec2 varTexCoord;

void main() {
  vec2 off1 = vec2(1.0) * uniDirection / shaderResolution;
  vec2 off2 = vec2(2.0) * uniDirection / shaderResolution;
  vec2 off3 = vec2(3.0) * uniDirection / shaderResolution;
  vec2 off4 = vec2(4.0) * uniDirection / shaderResolution;
  gl_FragColor = texture2D(uniTexture, varTexCoord) * 0.38026;
  gl_FragColor += texture2D(uniTexture, varTexCoord+off1) * 0.27667;
  gl_FragColor += texture2D(uniTexture, varTexCoord-off1) * 0.27667;
  gl_FragColor += texture2D(uniTexture, varTexCoord+off2) * 0.08074;
  gl_FragColor += texture2D(uniTexture, varTexCoord-off2) * 0.08074;
  gl_FragColor += texture2D(uniTexture, varTexCoord+off3) * -0.02612;
  gl_FragColor += texture2D(uniTexture, varTexCoord-off3) * -0.02612;
  gl_FragColor += texture2D(uniTexture, varTexCoord+off4) * -0.02143;
  gl_FragColor += texture2D(uniTexture, varTexCoord-off4) * -0.02143;
}
`;

type Uniform = WebGLObject | Array<number>;
type Texture = Frame | ImageData | string;
type CanvasFlyweightElement = {canvas: StreamAdapter; free: boolean}

export class CanvasFlyweight {
  private static readonly limit = 8;
  private static canvases: Array<CanvasFlyweightElement> = [];

  public static getSink(): StreamAdapter {
    for (let i = 0; i < CanvasFlyweight.canvases.length; i++) {
      if (CanvasFlyweight.canvases[i].free === true) {
        CanvasFlyweight.canvases[i].free = false;
        return CanvasFlyweight.canvases[i].canvas;
      }
    }
    if (CanvasFlyweight.canvases.length === CanvasFlyweight.limit)
      throw new Error('The maximum number of WebGL contexts has been reached');
    const canvas = constructPipeStreamAdapter();
    CanvasFlyweight.canvases.push({ canvas: canvas, free: false });
    return canvas;
  }

  public static disposeCanvas(canvas: StreamAdapter): void {
    for (let i = 0; i < CanvasFlyweight.canvases.length; i++) {
      if (CanvasFlyweight.canvases[i].canvas === canvas) {
        CanvasFlyweight.canvases[i].free = true;
      }
    }
  }
}

class WebGLEffect {
  protected canvas: StreamAdapter;
  protected gl: WebGLRenderingContext;
  protected shape: [number, number];
  protected buffers: twgl.BufferInfo;
  protected programInfo: twgl.ProgramInfo;

  constructor(shape: [number, number], shaders: [string, string]) {
    this.canvas = CanvasFlyweight.getSink();
    this.gl = this.canvas.getGLContext();
    this.canvas.setShape(shape);
    this.gl.viewport(0, 0, ...shape);
    this.shape = shape;
    this.programInfo = twgl.createProgramInfo(this.gl, shaders);
    this.buffers = twgl.createBufferInfoFromArrays(this.gl, {
      attrTexCoord: { numComponents: 2, data: [
        0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1
      ] },
      attrPosition: { numComponents: 2, data: [
        0, 0, shape[0], 0, 0, shape[1],
        0, shape[1], shape[0], 0, shape[0], shape[1]
      ] },
    });
    twgl.setBuffersAndAttributes(this.gl, this.programInfo, this.buffers);
  }

  public getSink(): StreamAdapter {
    return this.canvas;
  }

  public dispose() {
    CanvasFlyweight.disposeCanvas(this.canvas);
  }

  protected createTexture(source: Texture, flipY = false) {
    return twgl.createTexture(this.gl, {
      level: 0,
      flipY: flipY ? 1 : 0,
      minMag: this.gl.LINEAR,
      wrap: this.gl.CLAMP_TO_EDGE,
      src: source,
    });
  }

  protected draw(uniforms: Record<string, Uniform>, framebuffer?: twgl.FramebufferInfo): void {
    twgl.bindFramebufferInfo(this.gl, framebuffer);
    this.gl.useProgram(this.programInfo.program);
    twgl.setUniforms(this.programInfo, uniforms);
    twgl.drawBufferInfo(this.gl, this.buffers);
  }

  protected disposeFramebuffer(framebuffer: twgl.FramebufferInfo) {
    this.gl.deleteFramebuffer(framebuffer.framebuffer);
    for (const attachment of framebuffer.attachments) {
      if (attachment instanceof WebGLRenderbuffer) {
        this.gl.deleteRenderbuffer(attachment);
      }
      if (attachment instanceof WebGLTexture) {
        this.gl.deleteTexture(attachment);
      }
    }
  }
}

export class WebGLBlur extends WebGLEffect{
  private frameBufferA: twgl.FramebufferInfo;
  private frameBufferB: twgl.FramebufferInfo;

  constructor(shape: [number, number]) {
    super(shape, [vertexShader, blur13Shader]);
    const attr = [{ level: 0, wrap: this.gl.CLAMP_TO_EDGE, minMag: this.gl.LINEAR }];
    this.frameBufferA = twgl.createFramebufferInfo(this.gl, attr, ...this.shape);
    this.frameBufferB = twgl.createFramebufferInfo(this.gl, attr, ...this.shape);
  }

  public blur(source: Texture, radius: number, passes = 6): Promise<Frame> {
    const input = this.createTexture(source, true);
    for (let i = 0; i < 2 * passes; i++) {
      const texture = i % 2 ? this.frameBufferB.attachments[0] : this.frameBufferA.attachments[0];
      const framebuffer = i % 2 ? this.frameBufferA : this.frameBufferB;
      this.draw(      {
        uniResolution: this.shape,
        shaderResolution: this.shape,
        uniDirection: i % 2 ? [radius / passes, 0] : [0, radius / passes],
        uniTexture: i ? texture : input,
      }, i === 2 * passes - 1 ? undefined : framebuffer);
    }
    this.gl.deleteTexture(input);
    return this.getSink().getFrame();
  }

  public dispose() {
    this.disposeFramebuffer(this.frameBufferA);
    this.disposeFramebuffer(this.frameBufferB);
    CanvasFlyweight.disposeCanvas(this.canvas);
  }
}


export class WebGLBilateral extends WebGLEffect{
  constructor(shape: [number, number]) {
    super(shape, [vertexShader, bilateralShader]);
  }

  public bilateral(source: Texture, flip = false) {
    const texture = this.createTexture(source, flip);
    this.draw({
      uniResolution: this.shape,
      shaderResolution: this.shape,
      uniTexture: texture,
    });
    this.gl.deleteTexture(texture);
    return this.getSink().getFrame();
  }
}

export class WebGLJointBilateral extends WebGLEffect{
  constructor(shape: [number, number]) {
    super(shape, [vertexShader, jointBilateralShader]);
  }

  public bilateral(source: Texture, mask: Texture) {
    this.gl.useProgram(this.programInfo.program);
    const texture = this.createTexture(source, false);
    const maskTexture = this.createTexture(mask, false);
    this.draw({
      uniResolution: this.shape,
      shaderResolution: this.shape,
      uniTexture: texture,
      uniMask: maskTexture
    });
    this.gl.deleteTexture(texture);
    this.gl.deleteTexture(maskTexture);
    return this.getSink().getFrame();
  }
}

export class WebGLLanczos extends WebGLEffect {
  private frameBuffer: twgl.FramebufferInfo;

  constructor(shape: [number, number]) {
    super(shape, [vertexShader, lanczosShader]);
    const attr = [{ level: 0, wrap: this.gl.CLAMP_TO_EDGE, min: this.gl.LINEAR }];
    this.frameBuffer = twgl.createFramebufferInfo(this.gl, attr, ...this.shape);
  }

  public lanczos(source: Frame | ImageData | string, radius = 1) {
    const texture = this.createTexture(source, true);
    this.draw({
      uniResolution: this.shape,
      shaderResolution: this.shape,
      uniDirection: [radius, 0],
      uniTexture: texture,
    }, this.frameBuffer);
    this.draw({
      uniResolution: this.shape,
      shaderResolution: this.shape,
      uniDirection: [0, radius],
      uniTexture: this.frameBuffer.attachments[0],
    });
    this.gl.deleteTexture(texture);
    return this.getSink().getFrame();
  }

  public dispose() {
    this.disposeFramebuffer(this.frameBuffer);
    CanvasFlyweight.disposeCanvas(this.canvas);
  }
}
