import { User, Config, StreamDirection, StreamEvent, StreamUsage, WebrtcFunction } from './types';


export default class CallstatsIO {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cs: any = null;

  start(appId: string, appSecret: string, userId: User, _parsedUa: object | undefined, _config: Config) {
    this.cs = new window.callstats();
    const config = { disablePrecalltest: true };
    userId = this.sanitizeUserId(userId);
    this.cs.initialize(appId, appSecret, userId, this.initCb, this.statsCb, config);
  }

  addStream(
    pc: RTCPeerConnection,
    conferenceId: string,
    localUserId: string,
    remoteUserId: string,
    usage: StreamUsage,
    direction: StreamDirection
  ) {
    if (!this.cs) {
      return;
    }
    return this.cs.addNewFabric(pc, remoteUserId, usage, conferenceId, {
      fabricTransmissionDirection: this.directionToCallStatsDirection(direction),
      remoteEndpointType: this.cs.endpointType.server
    });
  }

  sendStreamEvent(pc: RTCPeerConnection, event: StreamEvent, conferenceID: string, eventData: object) {
    const csEvent = this.eventToCallStatsEvent(event);

    return this.cs.sendFabricEvent(pc, csEvent, conferenceID, eventData);
  }

  sendConferenceMetadata(_conferenceId: string, _metadata: object) {
    return;
  }

  sendUserMetadata(_conferenceId: string, _userId: string, _metadata: object) {
    return;
  }

  reportError(conferenceId: string, userId: string, failedFunction: WebrtcFunction) {
    if (!this.cs) {
      return;
    }
    return this.cs.reportError(null, conferenceId, failedFunction);
  }

  private initCb() {
    return;
  }

  private statsCb() {
    return;
  }

  private sanitizeUserId(user: User) {
    // callstats.io limits user ids to 128 bytes, we truncate the aliasName
    // since it is only used for readability purposes.
    // In case the userName and conference ids are longer, we let the
    // underlying library take care of the error, but this should not happen
    // since these values are real uids and do not depend on user input
    const maxLen = 128;
    if (user.aliasName.length > maxLen) {
      user.aliasName = user.aliasName.substring(0, maxLen);
    }
    return user;
  }

  private eventToCallStatsEvent(event: StreamEvent) {
    if (event === "streamTerminated") {
      return this.cs.fabricEvent.fabricTerminated;
    }

    return this.cs.fabricEvent[event];
  }

  private directionToCallStatsDirection(direction: StreamDirection) {
    return this.cs.transmissionDirection[direction];
  }

}
