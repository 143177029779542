import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { UserActionsContext } from '../../lib/contexts';
import { State } from '../../lib/reducers';
import { isLessonLayout } from '../../lib/reduxSelectors/room';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import AudioMuteButton from '../AudioMuteButton';
import MoreButton from '../Meeting/VideoToolbar/MoreButton';
import RosterAvatar from '../RosterAvatar';
import VideoMuteButton from '../VideoMuteButton';

import UserInfo from './UserInfo';


type Props = {
  uid: string;
  displayName: string;
  isVisible: boolean;
}


const useStyles = makeStyles((_theme: Theme) => createStyles({
  name: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));


function User(props: ExtendedProps) {
  const { uid, displayName, isVisible, hideActions } = props;
  const classes = useStyles();


  return (
    <UserActionsContext.Provider value='roster'>
      <ListItemIcon>
        <RosterAvatar name={displayName} uid={uid} isVisible={isVisible} />
      </ListItemIcon>
      <ListItemText className={classes.name} primary={displayName} />
      {!hideActions &&
        <React.Fragment>
          <UserInfo uid={uid} />
          <AudioMuteButton uid={uid} iconSize={24} />
          <VideoMuteButton uid={uid} iconSize={24} />
          <MoreButton disableWhenEmpty user={uid} iconSize={24} showItems={{ fs: !isMobileOnly, pip: !isMobileOnly }} />
        </React.Fragment>
      }
    </UserActionsContext.Provider>
  );
}


type MappedProps = {
  hideActions: boolean;
}


const mapStateToProps = (state: State, { uid }: Props): MappedProps => {
  return {
    hideActions: getWsUserId(state.websocket) === uid && isLessonLayout(state),
  };
};


type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(User);
