import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { PaletteOptions, PaletteColorOptions, Palette } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

/**
 * Material Palette for the Blue #255398
 */
const defaultPrimaryPalette = {
  light: '#5D7FC9',
  main: '#255398',
  dark: '#002B69',
  contrastText: '#fff',
};

/**
 * Not a Material Palette.
 * We use it as a container for custom colors used in Holocom UI
 */
const defaultSecondaryPalette = {
  light: '#E2F4FF',   // inputBackground color
  main: '#2B73BB',    // popoverBackgroundColor color
};

const defaultErrorPalette = {
  main: '#B00020',
};

const defaultTypography = {
  fontFamily: 'Rubik'
};


const getPrimaryPaletteOptions = (options?: PaletteColorOptions): PaletteColorOptions => {
  return options ? options : defaultPrimaryPalette;
};

const getSecondaryPaletteOptions = (options?: PaletteColorOptions): PaletteColorOptions => {
  return options ? options : defaultSecondaryPalette;
};

const getErrorPaletteOptions = (options?: PaletteColorOptions): PaletteColorOptions => {
  return options ? options : defaultErrorPalette;
};

const getDefaultPaletteOptions = (): PaletteOptions => {
  const paletteOptions = {
    primary: defaultPrimaryPalette,
    secondary: defaultSecondaryPalette,
    error: defaultErrorPalette,
  };

  return paletteOptions;
};

const getPaletteOptions = (options?: PaletteOptions): PaletteOptions => {
  const paletteOptions = {
    primary: getPrimaryPaletteOptions(options ? options.primary : undefined),
    secondary: getSecondaryPaletteOptions(options ? options.secondary : undefined),
    error: getErrorPaletteOptions(options ? options.error : undefined),
  };

  return paletteOptions;
};


const getTypographyOptions = (options?: TypographyOptions | ((palette: Palette) => TypographyOptions)) => {
  return options ? options : defaultTypography;
};


export const getDefaultThemeOptions = (): ThemeOptions => {
  const themeOptions = {
    palette: getDefaultPaletteOptions(),
    typography: defaultTypography,
  };
  return themeOptions;
};

const getThemeOptions = (options: ThemeOptions): ThemeOptions => {
  const themeOptions = {
    palette: getPaletteOptions(options ? options.palette : undefined),
    typography: getTypographyOptions(options ? options.typography : undefined),
  };
  return themeOptions;
};


export const getOptions = (themeOptions?: ThemeOptions) => {
  return themeOptions ? getThemeOptions(themeOptions) : getDefaultThemeOptions();
};
