import React, { Component } from 'react';

import PropTypes from 'prop-types';

import DialogContentText from '@material-ui/core/DialogContentText';


class DialogMultiLineText extends Component {
  line(text, index) {
    return (
      <DialogContentText key={index}>{text}</DialogContentText>
    );
  }

  render() {
    const text = this.props.message;
    return (
      <div>
        {text.split("\n").map((i, idx) => {
          return this.line(i, idx);
        })}
      </div>);
  }
}


DialogMultiLineText.propTypes = {
  message: PropTypes.string.isRequired,
};


export default DialogMultiLineText;
