/* tslint:disable */
/* eslint-disable */
/* @relayHash 8679824e4da2fd535ada35aeade4f12e */

import { ConcreteRequest } from "relay-runtime";
export type InviteToPersonalRoomInput = {
    baseUrl: string;
    emails: Array<string>;
};
export type inviteToMeetingMutationVariables = {
    input: InviteToPersonalRoomInput;
};
export type inviteToMeetingMutationResponse = {
    readonly inviteToPersonalRoom: {
        readonly result: {
            readonly code: number | null;
            readonly reason: string | null;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly key: string;
            readonly message: ReadonlyArray<string | null> | null;
            readonly reason: ReadonlyArray<string | null> | null;
        } | null> | null;
    } | null;
};
export type inviteToMeetingMutation = {
    readonly response: inviteToMeetingMutationResponse;
    readonly variables: inviteToMeetingMutationVariables;
};



/*
mutation inviteToMeetingMutation(
  $input: InviteToPersonalRoomInput!
) {
  inviteToPersonalRoom(input: $input) {
    result {
      code
      reason
    }
    errors {
      key
      message
      reason
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "InviteToPersonalRoomInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reason",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "inviteToPersonalRoom",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InviteToPersonalRoomPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "result",
        "storageKey": null,
        "args": null,
        "concreteType": "InviteResult",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "InputError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "inviteToMeetingMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "inviteToMeetingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "inviteToMeetingMutation",
    "id": null,
    "text": "mutation inviteToMeetingMutation(\n  $input: InviteToPersonalRoomInput!\n) {\n  inviteToPersonalRoom(input: $input) {\n    result {\n      code\n      reason\n    }\n    errors {\n      key\n      message\n      reason\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '28185babce3e56c9995c1b26cf2bcc70';
export default node;
