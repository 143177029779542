import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';


export const WithAuthorization = (allowedRoles, WrappedComponent) => {
  class Authorization extends Component {
    checkRoles(roles) {
      for (let role of roles){
        if (allowedRoles.includes(role)) {
          return true;
        }
      }
      return false;
    }
    render() {
      const roles = this.props.roles || [];
      const room_roles = this.props.room_roles || [];
      const all_roles = roles.concat(room_roles);
      if (this.props.sessionReady && this.checkRoles(all_roles)) {
        return <WrappedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  Authorization.propTypes = {
    roles: PropTypes.array,
    room_roles: PropTypes.array,
    sessionReady: PropTypes.bool,
  };

  return connect(state => {
    return {
      roles: state.session.roles,
      room_roles: state.websocket.room_roles,
      sessionReady: state.session.sessionReady
    };
  })(Authorization);
};
