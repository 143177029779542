const defaultVideoQuality = 'nhd';


class RtcDevices {
  constructor(rtcProvider, logger) {
    this.rtc = rtcProvider;
    this.logger = logger;
  }

  async arePermissionsGranted() {
    try {
      const devices = await this.rtc.mediaDevices.enumerateDevices();
      // enumerateDevices() will return all labels empty if no permission have
      // been granted to the page. Using the deviceId as per the specs would
      // fail because firefox will retunr a non empty deviceId even if not
      // permitted
      return !devices.every((device) => device.label === "");
    }
    catch (err) {
      this.logger.log("Enumeration error: ", err);
      return false;
    }
  }

  askFullPermissions() {
    const constraints = { audio: true, video: true };
    return this.rtc.mediaDevices.getUserMedia(constraints).then(this.stopStream);
  }

  askAudioOnlyPermissions() {
    const constraints = { audio: true, video: false };
    return this.rtc.mediaDevices.getUserMedia(constraints).then(this.stopStream);
  }

  discoverDevices() {
    return this.rtc.mediaDevices.enumerateDevices();
  }

  _getLocalStreamByConstraints(constraints, options, quality) {
    return this.rtc.mediaDevices.getUserMedia(constraints).catch(
      () => {
        // try with ideal video quality constraint if not already tried
        if (constraints.video.constructor === Object
            && Object.keys(constraints.video).length !== 0
            && constraints.video.width
            && !constraints.video.width.ideal) {
          constraints.video = this._buildVideoQualityConstraints(quality, options.frameRate, true);
          return this._getLocalStreamByConstraints(constraints, options);
        } else if (options.fallbackToAudioOnly) {
          constraints.video = false;
        }
        return this.rtc.mediaDevices.getUserMedia(constraints);
      }
    );
  }

  _getDeviceQuality(quality, options) {
    if (options.video === false) {
      return null;
    }
    const qualityConstraint = options.qualityConstraint;
    if (!qualityConstraint || qualityConstraint === 'all') {
      return quality;
    }
    if (qualityConstraint === 'low') {
      if (quality === 'nhd' || quality === 'vga' || quality === '180p') {
        return quality;
      }
      else {
        return 'nhd';
      }
    }
    if (qualityConstraint === 'high') {
      if (quality === 'hd' || quality === 'fhd') {
        return quality;
      }
      else {
        return 'hd';
      }
    }
    else {
      return defaultVideoQuality;
    }
  }

  _getFacingMode(facingMode) {
    if (facingMode) {
      return { exact: facingMode };
    } else {
      return 'user';
    }
  }

  async detectAudioVideoSupport() {
    const hasPerm = (device) => device.label !== '';

    const browser = this.rtc.adapter.browserDetails.browser;
    if (browser === 'firefox') {
      return await this.detectAudioVideoSupportOnFirefox();
    }

    // use device label to check if we have granted permissions to the device
    // the specs state that the label field is empty if not permitted, but it
    // could also be because the device itself has no label, but we use it
    // anyway, since the deviceId is not empty in firefox
    const devices = await this.rtc.mediaDevices.enumerateDevices();
    const supported = devices.reduce(
      (acc, device) => {
        if (device.kind === "audioinput" && hasPerm(device)) {
          return { ...acc, audio: true };
        }
        if (device.kind === "videoinput" && hasPerm(device)) {
          return { ...acc, video: true };
        }
        return acc;
      },
      { audio: false, video: false }
    );
    return supported;
  }

  async detectAudioVideoSupportOnFirefox() {
    // in firefox, we cannot use enumerateDevices(), because the label is empty
    // unless a stream is currently open, nor we can use the Permissions api,
    // so we fallback to two gUM calls
    let supported = { audio: false, video: false };
    try {
      const stream = await this.rtc.mediaDevices.getUserMedia({ audio: true });
      this.stopStream(stream);
      supported.audio = true;
    } catch { /* ignore */ }

    try {
      const stream = await this.rtc.mediaDevices.getUserMedia({ video: true });
      this.stopStream(stream);
      supported.video = true;
    } catch {  /* ignore */ }

    return supported;
  }

  async getLocalStreamByDevices(audioId, videoId, quality, options = {}) {
    quality = this._getDeviceQuality(quality, options);
    const frameRate = options.frameRate;
    const defaultQuality = options.video === false ? null : defaultVideoQuality;
    const qualityConstraints = this._buildVideoQualityConstraints(quality, frameRate);
    const videoConstraints = qualityConstraints
      ? { ...qualityConstraints,
        ...videoId && { deviceId: { exact: videoId } },
        ...{ facingMode: this._getFacingMode(options.facingMode) }
      }
      : false;
    const audioConstraints = {
      ...audioId && { deviceId: { exact: audioId } },
      /* see https://bugs.chromium.org/p/chromium/issues/detail?id=709931*/
      /* optional: this._getOptionalAudioConstraints(), */
    };

    const supported = await this.detectAudioVideoSupport();
    const constraints = {
      audio: supported.audio ? audioConstraints : false,
      video: supported.video ? videoConstraints : false
    };
    const defaultQualityConstraints = this._buildVideoQualityConstraints(defaultQuality, frameRate);
    const defaultVideoConstraints = defaultQualityConstraints
      ? { ...defaultQualityConstraints,
        ...videoId && { deviceId: { exact: videoId } },
        ...{ facingMode: this._getFacingMode(options.facingMode) }
      }
      : false;
    const defaultConstraints = {
      audio: supported.audio ? audioConstraints : false,
      video: supported.video ? defaultVideoConstraints : false
    };
    if (!supported.audio && !supported.video && options.allowNoAudio) {
      return this.rtc.getEmptyMediaStream();
    }
    if (options.fallbackToAudioOnly && !quality) {
      try {
        return await this.rtc.mediaDevices.getUserMedia(defaultConstraints);
      }
      catch (_err) {
        return await this._getLocalStreamByConstraints(constraints, options, quality);
      }
    }
    else {
      const c = quality ? constraints : defaultConstraints;
      return await this._getLocalStreamByConstraints(c, options, quality);
    }
  }

  setEncodingParameters(pc, options) {
    const rescaleDimension = 360;
    const rescaleDimensionWideScreen = 240;
    try {
      const sender = pc.getSenders()[0];
      const params = sender.getParameters();

      if (!params.encodings &&
          (options.scaleResolutionDownBy || options.maxBitrate || options.maxFramerate)
      ) {
        // cfr. https://bugzilla.mozilla.org/show_bug.cgi?id=1531458
        params.encodings = [ {} ];
      }

      if (options.rescaleVideo) {
        const currentSettings = sender.track.getSettings();
        const currentHeight = currentSettings.height;
        const currentWidth = currentSettings.width;

        // to determine if in landscape or portrait orientation
        const minDim = Math.min(currentWidth, currentHeight);

        // compute aspect ratio regardless of orientation
        const currentAR = Math.max(currentWidth / currentHeight, currentHeight / currentWidth);

        let ratio = minDim / rescaleDimensionWideScreen;
        if ((currentAR - (4 / 3)) < 0.5) {
          ratio = minDim / rescaleDimension;
        }
        params.encodings[0].scaleResolutionDownBy = Math.max(1, ratio);
      }
      else {
        params.encodings[0].scaleResolutionDownBy = 1;
      }
      if (options.maxFramerate) {
        params.encodings[0].maxFramerate = options.maxFramerate;
      }
      if (options.maxBitrate) {
        params.encodings[0].maxBitrate = options.maxBitrate;
      }
      return sender.setParameters(params).then(_res => params);
    }
    catch (e) {
      return Promise.reject(e);
    }
  }

  applyTrackConstraints(track, options) {
    const quality = options.streamQuality || defaultVideoQuality;
    const qualityConstraints = this._buildVideoQualityConstraints(quality, options.frameRate);
    const currSettings = track.getSettings();
    if (this._shoudlApplyNewConstraints(qualityConstraints, currSettings)) {
      return track.applyConstraints(qualityConstraints);
    }
    else {
      return Promise.resolve(null);
    }
  }

  _shoudlApplyNewConstraints(options, currSettings) {
    if (
      (!options.frameRate || options.frameRate === currSettings.frameRate)
      && currSettings.height
      && (currSettings.height === options.height.max)
    ) {
      return false;
    }
    return true;
  }

  setAudioOutputOn(videoElem, mediaDevice) {
    if (videoElem.setSinkId && mediaDevice.deviceId) {
      return videoElem.setSinkId(mediaDevice.deviceId);
    }
    return Promise.reject(
      new Error('No device ID or setSinkId on video element')
    );
  }

  stopStream(stream) {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  async validateDeviceWithState(dev) {
    try {
      const devices = await this.discoverDevices();
      const found = devices.find((device) => {
        const deviceId = dev ? dev.deviceId : undefined;
        return device.deviceId === deviceId;
      });
      if (!found && dev) {
        this.logger.warning('device not found', dev);
        return false;
      }
      return true;
    } catch (e) {
      this.logger.error(e);
    }
  }

  _buildVideoQualityConstraints(quality, frameRate, ideal = false) {
    let constr = {};
    if (frameRate) {
      constr['frameRate'] = frameRate;
    }
    if (quality === '180p') {
      return { ...constr, width: this._getResContraints(320, ideal), height: this._getResContraints(180, ideal) };
    }
    else if (quality === 'vga') {
      return { ...constr, width: this._getResContraints(640, ideal), height: this._getResContraints(480, ideal) };
    } else if (quality === 'nhd') {
      return { ...constr, width: this._getResContraints(640, ideal), height: this._getResContraints(360, ideal) };
    } else if (quality === 'hd') {
      return { ...constr, width: this._getResContraints(1280, ideal), height: this._getResContraints(720, ideal) };
    } else if (quality === 'fhd') {
      return { ...constr, width: this._getResContraints(1920, ideal), height: this._getResContraints(1080, ideal) };
    } else if (quality === null) {
      return false;
    } else {
      return constr;
    }
  }

  _getResContraints(res, ideal) {
    return ideal
      ? { ideal: res }
      : { min: res, max: res };
  }

  _getOptionalAudioConstraints() {
    /*
    * these are taken from what google meet/hangouts do
    * some are enabled by default, like echoCan and autoGainControl.
    */
    return [
      { echoCancellation: true },
      { googEchoCancellation: true },
      { googEchoCancellation2: true },
      { googDAEchoCancellation: true },
      { googAutoGainControl: true },
      { googAutoGainControl2: true },
      { googNoiseSuppression: true },
      { googNoiseSuppression2: true },
      /*
      * intelligibilityEnhancer is not active yet
      * but present in chromium source code
      */
      { intelligibilityEnhancer: true },
      { googTypingNoiseDetection: true },
      { googHighpassFilter: true },
    ];
  }

}

function getVideoQualities() {
  return [
    { value: '180p', label: '180p' },
    { value: 'vga', label: 'SD (VGA)' },
    { value: 'nhd', label: 'nHD' },
    { value: 'hd', label: 'HD' },
    { value: 'fhd', label: 'Full HD' },
  ];
}

export { RtcDevices, getVideoQualities };
