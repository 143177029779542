import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import useRtc from '../../hooks/useRtc';
import { setMediaPermissionsGranted } from "../../lib/actions/settings";
import { RtcDevices } from '../../lib/api/rtcDevices';
import DialogMultiLineText from '../DialogMultiLineText';


const messages = defineMessages({
  info: { id: 'mediaPermissionsMessage' },
  title: { id: 'mediaPermissionsTitle' },
  blockedTitle: { id: 'mediaPermissionsBlockedTitle' },
  permErr: { id: 'mediaPermissionsPermsErr' },
  genErr: { id: 'mediaPermissionsGenErr' },
  continue: { id: 'mediaPermissionsContinueWithoutAV' },
});


async function checkMediaPermissions(rtc: RtcDevices) {
  try {
    return await rtc.arePermissionsGranted();
  }
  catch (_err) {
    return false;
  }
}


// return true if granted, raises otherwise (with fallback to audio only perms)
async function askPermissions(rtc: RtcDevices) {
  try {
    await rtc.askFullPermissions();
    return true;
  }
  catch (err) {
    if (err.name === 'NotAllowedError') {
      throw err;
    }
    else {
      await rtc.askAudioOnlyPermissions();
      return true;
    }
  }
}


export default function MediaPermissions(_props: {}) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const dispatchPermissionsGranted = React.useCallback(
    (granted: boolean) => dispatch(setMediaPermissionsGranted(granted))
    , [dispatch]
  );

  const rtc = useRtc('MediaPermissions');

  const [dialogDismissed, setDialogDismissed] = React.useState(false);
  const [waitingForEnumerateDevs, setWaingForEnumerateDevs] = React.useState(true);
  const [permissionsGranted, setPermissionsGranted] = React.useState(false);
  const [showGrantPermissionsDialog, setShowGrantPermissionsDialog] = React.useState(false);
  const [permissionsError, setPermissionsError] = React.useState<null | Error>(null);

  React.useEffect(
    () => {
      if (!rtc) {
        return;
      }
      checkMediaPermissions(rtc).then((granted) => {
        setPermissionsGranted(granted);
        setWaingForEnumerateDevs(false);
        dispatchPermissionsGranted(granted);
      });
    }
    , [rtc, dispatchPermissionsGranted]
  );

  React.useEffect(
    () => {
      if (!rtc || permissionsGranted || waitingForEnumerateDevs) {
        return;
      }

      setShowGrantPermissionsDialog(true);

      askPermissions(rtc)
        .then((_) => {
          setShowGrantPermissionsDialog(false);
          dispatchPermissionsGranted(true);
        })
        .catch((err) => {
          setShowGrantPermissionsDialog(false);
          setPermissionsError(err);
          dispatchPermissionsGranted(true);
        });
    }
    , [rtc, permissionsGranted, waitingForEnumerateDevs, dispatchPermissionsGranted]
  );

  function dismissDialog() {
    setDialogDismissed(true);
  }

  let dialogTitle = formatMessage(messages.title);
  let dialogContent = formatMessage(messages.info);
  let showDismissButton = false;

  if (permissionsError) {
    if (permissionsError.name === 'NotAllowedError') {
      dialogTitle = formatMessage(messages.blockedTitle);
      dialogContent = formatMessage(messages.permErr);
      showDismissButton = true;
    }
    else {
      dialogTitle = formatMessage(messages.blockedTitle);
      dialogContent = formatMessage(messages.genErr);
      showDismissButton = true;
    }
  }

  const dialogIsOpen = !dialogDismissed && (showGrantPermissionsDialog || Boolean(permissionsError));

  return (
    <Dialog
      open={dialogIsOpen}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='md'
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogMultiLineText
          message={dialogContent}
        />
      </DialogContent>
      { showDismissButton &&
        <DialogActions>
          <Button onClick={dismissDialog} color='primary' variant='contained'>
            {formatMessage(messages.continue)}
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}
