import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dispatch } from 'redux';

import { saveAudioInConfig, saveVideoConfig } from '../lib/actions/settings';
import { startPipeline, stopPipelineShared } from '../lib/api/pipeline';
import { RtcDevices } from '../lib/api/rtcDevices';
import { getLogger } from '../lib/logger';
import { State } from '../lib/reducers';
import { StreamOptions } from '../lib/redux_types';
import { getActualSelectedBlurValue } from '../lib/reduxSelectors/blur';
import { shouldMirrorVideo } from '../lib/reduxSelectors/settings';
import LocalStorage from '../localStorage';
import prepareWebRtcProvider from '../rtc';


function getRtc() {
  const logger = getLogger('AVSettings');
  const webrtc = prepareWebRtcProvider();
  return new RtcDevices(webrtc, logger);
}


function acquireMedia(
  audioDev: State['settings']['audioInDevice'],
  videoDev: State['settings']['videoDevice'],
  videoQuality: State['settings']['videoQuality'],
  options: {
    video: boolean;
    beautifyEffectEnabled: StreamOptions['beautifyEffectEnabled'];
    backgroundBlurEnabled: StreamOptions['backgroundBlurEnabled'];
    backgroundBlurValue: StreamOptions['backgroundBlurValue'];
    qualityConstraint: StreamOptions['streamQuality'];
    facingMode: StreamOptions['facingMode'];
  },
): Promise<MediaStream> {
  const rtc = getRtc();
  const audioId = (audioDev || { deviceId: undefined }).deviceId;
  const videoId = (videoDev || { deviceId: undefined }).deviceId;
  const quality = (videoQuality || { value: undefined }).value;
  const opts = { ...options, fallbackToAudioOnly: false };
  const getStream = () => rtc.getLocalStreamByDevices(audioId, videoId, quality, opts);
  return startPipeline("av_settings", getStream, { ...options, frameRate: 15 });
}


async function validateDevs(
  audioInDev: MediaDeviceInfo | undefined,
  videoInDev: MediaDeviceInfo | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
): Promise<void> {
  const rtc = getRtc();
  try {
    if (!await rtc.validateDeviceWithState(audioInDev)) {
      dispatch(saveAudioInConfig(undefined, new LocalStorage()));
      throw new Error('unable to validate audio in device');
    }
    if (!await rtc.validateDeviceWithState(videoInDev)) {
      dispatch(saveVideoConfig(undefined, new LocalStorage()));
      throw new Error('unable to validate video in device');
    }
    return Promise.resolve();
  }
  catch (e) {
    return e;
  }
}


type Options = {
    videoEnabled: boolean;
    onVideoLoading?: (b: boolean) => void;
  }


const getDeviceOptions = (state: State) => {
  return {
    audioInDevice: state.settings.audioInDevice,
    audioOutDevice: state.settings.audioOutDevice,
    videoDevice: state.settings.videoDevice,
    videoFacingMode: state.settings.videoFacingMode,
    beautifyEffectEnabled: state.settings.beautifyEffectEnabled,
    backgroundBlurEnabled: state.settings.backgroundBlurEnabled,
    backgroundBlurValue: getActualSelectedBlurValue(state),
    videoQuality: state.settings.videoQuality,
    roomOptions: state.appconfig.room_options,
    shouldMirrorVideo: shouldMirrorVideo(state),
  };
};



export default function useMediaStream(options: Options) {
  const {
    audioInDevice,
    videoDevice,
    videoQuality,
    roomOptions,
    videoFacingMode,
    beautifyEffectEnabled,
    backgroundBlurEnabled,
    backgroundBlurValue,
  } = useSelector(getDeviceOptions);

  const {
    videoEnabled,
    onVideoLoading,
  } = options;

  const qualityConstraint = roomOptions.stream_quality;
  const [stream, setStream] = React.useState<null | MediaStream>(null);
  const [devError, setDevError] = React.useState<null | Error>(null);

  const streamRef = React.useRef(stream);
  const loggerRef = React.useRef(getLogger('AVSettings'));
  const dispatch = useDispatch();

  React.useEffect(() => {
    // this variable is used to signal whether to cancel the gUM call in
    // case the promise is resolved after this component is unmounted.
    // This can happen with slow hw, if joining the room very fast, or
    // using the ?skip_device_settings url=true param
    // When unmounting, this var is set to true, and when the gUM promise
    // is resolved, the stream is stopped and no component state is set
    let isMediaAcquisitionCancelled = false;
    validateDevs(audioInDevice, videoDevice, dispatch).then(async() => {
      try {
        if (onVideoLoading) onVideoLoading(true);
        const stream = await acquireMedia(
          audioInDevice,
          videoDevice,
          videoQuality,
          {
            video: videoEnabled,
            qualityConstraint,
            facingMode: videoFacingMode,
            beautifyEffectEnabled: beautifyEffectEnabled,
            backgroundBlurEnabled: backgroundBlurEnabled,
            backgroundBlurValue: backgroundBlurValue,
          }
        );
        if (!isMediaAcquisitionCancelled) {
          if (onVideoLoading) onVideoLoading(false);
          setStream(stream);
          streamRef.current = stream;
          setDevError(null);
        }
      } catch (error) {
        if (onVideoLoading) onVideoLoading(false);
        loggerRef.current.warn('error in acquireMedia:', error);
        setStream(null);
        streamRef.current = null;
        setDevError(error);
      }
    }).catch((e: Error) => {
      loggerRef.current.warn('error in devices validation:', e);
    });

    return () => {
      isMediaAcquisitionCancelled = true;  // cancel the gUM request
      setStream(null);
      streamRef.current = null;
      setDevError(null);
      stopPipelineShared("av_settings");
    };
  }, [
    setStream,
    setDevError,
    audioInDevice,
    videoDevice,
    videoQuality,
    videoEnabled,
    onVideoLoading,
    qualityConstraint,
    videoFacingMode,
    beautifyEffectEnabled,
    backgroundBlurEnabled,
    backgroundBlurValue,
    dispatch
  ]);

  return { stream, error: devError };
}
