import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import uuid from 'uuid';

import { DialogContent, DialogActions, Button, TextField, IconButton, Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { sendChatFile } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { abortFetch } from '../../lib/utils/abortableFetch';
import ClosableDialog from '../ClosableDialog';
import { IconAttachFile, IconClose } from '../IconSet';


const messages = defineMessages({
  sendAMessage: { id: 'sendAMessage' },
  shareFileTooltip: { id: 'shareFileTooltip' },
  dialogTitlePublic: { id: 'shareFileDialogTitlePublic' },
  dialogTitlePrivate: { id: 'shareFileDialogTitlePrivate' },
  descriptionPlaceholder: { id: 'shareFileDescriptionPlaceholder' },
  cancel: { id: 'cancel' },
  send: { id: 'send' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      padding: theme.spacing(1),
    },
    spinnerContainer: {
      margin: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
  })
);

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

type DialogProps = {
  onClose: () => void;
  file: File;
  to?: string;
}

function UploadDialog(props: DialogProps) {
  const {
    onClose,
    file,
    to
  } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState("");

  const sendFile = () => {
    dispatch(sendChatFile(file, inputValue, to));
    logEvent(Event.SEND_CHAT_FILE, { 'message_type': to ? 'private' : 'public' });
    onClose();
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(ev.target.value);
  };

  const getFileInfo = () => {
    return file.name + " (" + formatBytes(file.size) + ")";
  };

  const getTitle = () => {
    return to ? formatMessage(messages.dialogTitlePrivate) : formatMessage(messages.dialogTitlePublic);
  };

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={onClose}
      fullWidth={false}
      title={getTitle()}
    >
      <DialogContent>
        <Typography className={classes.dialog} variant={"body1"} align={'center'}>{getFileInfo()}</Typography>
        <TextField
          className={classes.dialog}
          fullWidth
          value={inputValue}
          placeholder={formatMessage(messages.descriptionPlaceholder)}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          {formatMessage(messages.cancel)}
        </Button>
        <Button onClick={sendFile} variant="contained" color="primary">
          {formatMessage(messages.send)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}

type Props = {
  to?: string;
  disabled?: boolean;
}

type MappedProps = {
  isUploadingFile: boolean;
  uploadFileRequest: State['room']['uploadFileRequest'];
}

type ExtendedProps = Props & MappedProps

function ShareFileInput(props: ExtendedProps) {
  const {
    disabled: attachFileDisabled,
    isUploadingFile,
    uploadFileRequest,
    to
  } = props;

  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [inputFile, setInputFile] = React.useState<File | null>(null);

  const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setInputFile(fileList[0]);
    }
    // reset target value to allow uploading the same file again
    event.target.value = '';
  };

  const closeUploadDialog = (): void => {
    setInputFile(null);
  };

  const cancelUpload = () => {
    if (uploadFileRequest) {
      abortFetch(uploadFileRequest);
    }
  };

  const id = 'icon-button-file-' + uuid.v4();

  const getAttachFile = () => {
    if (isUploadingFile) {
      return (
        <div className={classes.spinnerContainer}>
          <CircularProgress style={{ position: 'absolute' }} size={24} />
          <IconButton style={{ padding: '0px', position: 'absolute' }} onClick={cancelUpload}>
            <IconClose size={20} />
          </IconButton>
        </div>
      );
    }
    else {
      return (
        <React.Fragment>
          <input
            style={{ display: 'none' }}
            id={id}
            type="file"
            onChange={readFile}
          />
          <label htmlFor={id}>
            <Tooltip title={formatMessage(messages.shareFileTooltip)}>
              <IconButton component='span'>
                <IconAttachFile size={24} />
              </IconButton>
            </Tooltip>
          </label>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      {!attachFileDisabled && inputFile &&
        <UploadDialog
          onClose={closeUploadDialog}
          file={inputFile}
          to={to}
        />
      }
      {!attachFileDisabled && getAttachFile()}
    </React.Fragment>
  );
}

const mapStateToProps = (state: State): MappedProps => ({
  isUploadingFile: Boolean(state.room.uploadFileRequest),
  uploadFileRequest: state.room.uploadFileRequest,
});

export default connect(mapStateToProps)(ShareFileInput);
