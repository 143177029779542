import { RouteComponentProps } from 'react-router-dom';

import { roomErrorAcked, tearDownRoom } from '../../../../lib/actions/room';
import { tearDownWaitingRoom } from '../../../../lib/actions/waitingRoom';
import { joinWaitingRoom, leaveChannel, resetJoinFailure } from '../../../../lib/actions/websocket';
import { getLogger } from '../../../../lib/logger';
import getRouteFor, { Route } from '../../../../lib/utils/router';

// FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reconnect(slug: string, dispatch: any, snapshot?: string | null) {
  if (slug) {
    const logger = getLogger(`Meeting ${slug}`);
    dispatch(roomErrorAcked());
    dispatch(resetJoinFailure());
    dispatch(tearDownWaitingRoom());
    dispatch(tearDownRoom());
    dispatch(leaveChannel());
    dispatch(joinWaitingRoom(slug, snapshot, logger));
  }
}


function backToHome(history: RouteComponentProps['history'], confirm = true) {
  history.push(getRouteFor(Route.Home), { doNotConfirm: confirm });
}


export { backToHome, reconnect };
