import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { makeStyles, Theme, createStyles, Divider, IconButton, Tooltip } from '@material-ui/core';

import { WithAuthorization } from '../../authorization';
import { State } from '../../lib/reducers';
import { canShowExtraControls } from '../../lib/reduxSelectors/user';
import { IconMore } from '../IconSet';
import StyledMenu from '../StyledMenu';

import EndMeetingButton from './EndMeetingButton';
import RecordAudioButton from './RecordAudioButton';
import RecordButton from './RecordButton';
import StreamingButton from './StreamingButton';


const messages = defineMessages({
  roomActionsMenuTooltip: { id: 'roomActionsMenuTooltip' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  })
);


type MappedProps = {
  recordingEnabled: boolean;
  livestreamingEnabled: boolean;
  recordingAudioEnabled: boolean;
  showDivider: boolean;
}

const mapStateToProps = (state: State): MappedProps => ({
  recordingEnabled: state.room.recordingEnabled,
  recordingAudioEnabled: state.room.recordingAudioEnabled,
  livestreamingEnabled: state.room.livestreamingEnabled,
  showDivider: canShowExtraControls(state),
});

type Props = {
  showMenu?: boolean;
} & MappedProps


function ActionRoomMenu(props: Props) {
  const {
    recordingEnabled,
    recordingAudioEnabled,
    livestreamingEnabled,
    showDivider,
    showMenu = false
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const closeMenu = React.useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const getActions = () => {
    return (
      <React.Fragment>
        { (showDivider && !showMenu) && <Divider className={classes.divider} /> }
        { recordingEnabled && <RecordButton /> }
        { recordingAudioEnabled && <RecordAudioButton /> }
        { livestreamingEnabled && <StreamingButton /> }
        <EndMeetingButton />
      </React.Fragment>
    );
  };

  const getMenu = () => {
    return (
      <React.Fragment>
        <Tooltip placement="top" title={formatMessage(messages.roomActionsMenuTooltip)}>
          <IconButton onClick={handleMenu} size='small'>
            <IconMore size={24} />
          </IconButton>
        </Tooltip>
        <StyledMenu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={closeMenu}
          onClick={closeMenu}
        >
          {getActions()}
        </StyledMenu>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      { ( isMobileOnly || !showMenu) ? getActions() : getMenu() }
    </React.Fragment>
  );

}

const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, connect(mapStateToProps)(ActionRoomMenu));
