class AudioRoom {
  constructor(signalingServer, logger) {
    this.signalingServer = signalingServer;
    this.logger = logger;
    this.eventSubscription = this.signalingServer().subscribe('event', (data) => this._onEvent(data));

    // initialize handlers to keep track of them and to avoid typescript errors
    this.onTalkingEvent = (_uname, _isTalking) => { };
    this.onToggleAudioMute = (_uname, _isMuted) => { };
    this.onJoinedPrivateAudio = (_uname, _privateConfName) => { };
    this.onLeftPrivateAudio = (_uname, _privateConfName) => { };
  }

  _onEvent(data) {
    if (data.type === 'start_talking') {
      this.onTalkingEvent(data.data.username, true);
    }
    else if (data.type === 'stop_talking') {
      this.onTalkingEvent(data.data.username, false);
    }
    else if (data.type === 'mute') {
      this.onToggleAudioMute(data.data.username, data.data.mute);
    }
    else if (data.type === 'joined_private_conf') {
      this.onJoinedPrivateAudio(data.data.username, data.data.private_conf_name);
    }
    else if (data.type === 'left_private_conf') {
      this.onLeftPrivateAudio(data.data.username);
    }
  }

  toggleAudioMute(user, muted) {
    return this.signalingServer().toggleAudioMute(user, muted);
  }

  toggleOwnAudioMute(muted) {
    return this.signalingServer().toggleOwnAudioMute(muted);
  }

  togglePrivateAudio(user, inPrivateAudio) {
    return this.signalingServer().togglePrivateAudio(user, inPrivateAudio);
  }

  muteAll() {
    return this.signalingServer().muteAll();
  }

  unMuteAll() {
    return this.signalingServer().unMuteAll();
  }

  tearDown() {
    if (this.eventSubscription) {
      this.signalingServer().unsubscribe('event', this.eventSubscription);
    }
  }
}

export { AudioRoom };
