import React from 'react';

import Paper from '@material-ui/core/Paper';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';


const styles = (theme: Theme) =>
  createStyles({
    popper: {
      backgroundColor: theme.palette.secondary.main,
      zIndex: 1300,
      borderRadius: 6,
      boxShadow: `8px 8px 40px -12px ${theme.palette.primary.main}`,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '6em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
          borderBottomColor: theme.palette.secondary.main,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '6em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
          borderBottomColor: theme.palette.secondary.main,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '6em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
          borderBottomColor: theme.palette.secondary.main,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '6em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
          borderBottomColor: theme.palette.secondary.main,
        },
      },
    },

    arrow: {
      position: 'absolute',
      fontSize: 10,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    },

    paper: {
      maxWidth: 300,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    }
  });


export type PopoverDialogProps = PopperProps & WithStyles<typeof styles>;


interface State {
  arrowRef: HTMLSpanElement | null;
}


/*
 * Example usage:
     <SomeElementToAnchorTo ref={this.anchorRef} />
     <PopoverDialog
      open={Boolean(this.anchorRef.current)}
      anchorEl={this.anchorRef.current}
     >
       <DialogContent>
         <DialogContentText>Some Text Here</DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button>
           Ok
         </Button>
       </DialogActions>
     </PopoverDialog>
 */
// this component is based on https://github.com/mui-org/material-ui/blob/1a69864176e198053ae50a9361b3c2dce99ac31e/docs/src/pages/components/popper/ScrollPlayground.js
class PopoverDialog extends React.Component<PopoverDialogProps, State> {

  constructor(props: PopoverDialogProps) {
    super(props);
    this.state = {
      arrowRef: null
    };
  }

  setArrowRef = (ref: State["arrowRef"]) => {
    this.setState({ arrowRef: ref });
  }

  forceArrowInProps() {
    return {
      ...this.props,
      modifiers: {
        arrow: {
          enabled: Boolean(this.state.arrowRef),
          element: this.state.arrowRef,
        },
      }
    };
  }

  render() {
    const { classes, ...popperProps } = this.forceArrowInProps();
    return (
      <Popper className={classes.popper} {...popperProps}>
        <span className={classes.arrow} ref={this.setArrowRef} />
        <Paper elevation={0} className={classes.paper}>
          {this.props.children}
        </Paper>
      </Popper>
    );
  }
}


export default withStyles(styles)(PopoverDialog);
