import enData from './en';
import itData from './it';
import { LocaleData } from './types';


const localeData: LocaleData = {
  en: enData,
  it: itData,
};


export default localeData;
