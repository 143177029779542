import { defineMessages } from "react-intl";

import {
  MeetingType as MType,
  MeetingPrivacy as MPrivacy,
  MeetingMetaInput as MMeta,
} from "../__generated__/scheduleMeetingMutation.graphql";

/* Meeting Type types and helpers */
export type MeetingType = MType

type MeetingTypeValue = {
  label: { id: string };
  value: MeetingType;
}

type MeetingTypeValues = {
  regular: MeetingTypeValue;
  audioOnly: MeetingTypeValue;
  webinar: MeetingTypeValue;
  lesson: MeetingTypeValue;
};

const meetingTypeLabels = defineMessages({
  regular: { id: 'typeRegular' },
  audioOnly: { id: 'typeAudioOnly' },
  webinar: { id: 'typeWebinar' },
  lesson: { id: 'typeLesson' },
});

export const meetingTypeValues: MeetingTypeValues = {
  regular: { label: meetingTypeLabels.regular, value: 'REGULAR' },
  audioOnly: { label: meetingTypeLabels.audioOnly, value: 'AUDIO_ONLY' },
  webinar: { label: meetingTypeLabels.webinar, value: 'WEBINAR' },
  lesson: { label: meetingTypeLabels.lesson, value: 'LESSON' },
};

export function findMeetingValue(type: MeetingType): MeetingTypeValue {
  const value: MeetingTypeValue = meetingTypeValues.regular;
  return Object.values(meetingTypeValues).find((v) => v.value === type) || value;
}


/* Meeting Privacy types and helpers */
export type MeetingPrivacy = MPrivacy

type MeetingPrivacyValue = {
  label: { id: string };
  value: MeetingPrivacy;
}

type MeetingPrivacyValues = {
  open: MeetingPrivacyValue;
  locked: MeetingPrivacyValue;
  logged: MeetingPrivacyValue;
  lockedLogged: MeetingPrivacyValue;
};

const meetingPrivacyLabels = defineMessages({
  open: { id: 'privacyOpen' },
  locked: { id: 'privacyLocked' },
  logged: { id: 'privacyLogged' },
  lockedLogged: { id: 'privacyLockedLogged' },
});

export const meetingPrivacyValues: MeetingPrivacyValues = {
  open: { label: meetingPrivacyLabels.open, value: 'OPEN' },
  locked: { label: meetingPrivacyLabels.locked, value: 'LOCKED' },
  logged: { label: meetingPrivacyLabels.logged, value: 'LOGGED' },
  lockedLogged: { label: meetingPrivacyLabels.lockedLogged, value: 'LOCKED_LOGGED' },
};

/* Meeting Meta type */
export type MeetingMeta = MMeta
