import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { toggleInviteParticipantsDialog } from '../../lib/actions/room';
import { State } from '../../lib/reducers';
import { amModerator } from '../../lib/reduxSelectors/room';
import { inMeeting } from '../../lib/reduxSelectors/videoRoom';
import { getWsMeetingDetails } from '../../lib/reduxSelectors/websocket';
import getRouteFor, { Route } from '../../lib/utils/router';
import ClosableDialog from '../ClosableDialog';
import RoomInfo from '../RoomInfo';

import CopyLinkButton from './CopyLinkButton';
import InviteDialButton from './InviteDialButton';
import InviteEmailButton from './InviteEmailButton';


const messages = defineMessages({
  title: { id: 'invitePersonToMeeting' },
  email: { id: 'email' },
  username: { id: 'username' },
  name: { id: 'name' },
  roomInfoWebAccess: { id: 'roomInfoWebAccess' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      overflow: "hidden",
      whiteSpace: 'nowrap'
    },
    title: {
      color: theme.palette.primary.main,
      textAlign: "center"
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    buttons: {
      padding: theme.spacing(1),
      alignSelf: "center",
      textAlign: "center"
    }
  })
);


type MappedProps = {
  roomSlug: string | null;
  dialoutEnabled: boolean;
  toggle: boolean;
  amModerator: boolean;
  inMeeting: boolean;
}


type Props = {}


type ExtendedProps = Props & MappedProps;


function InviteToMeetingDialog(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { dialoutEnabled, amModerator, inMeeting, roomSlug } = props;
  let rawUrl = "";
  const dispatch = useDispatch();

  const closeInviteDialog = (): void => {
    dispatch(toggleInviteParticipantsDialog());
  };

  if (roomSlug) {
    rawUrl = getRouteFor(Route.Meeting, { slug: roomSlug }, undefined, window.location.origin);
  }

  const showEmail = amModerator || !inMeeting;
  const showDialout = amModerator && dialoutEnabled && inMeeting;

  return (
    <ClosableDialog
      open={props.toggle}
      onClose={closeInviteDialog}
      fullWidth={false}
      maxWidth='md'
      title={formatMessage(messages.title)}
    >
      <DialogContent className={classes.content}>
        <div className={classes.container}>
          {showEmail &&
            <div className={classes.buttons}>
              <InviteEmailButton />
            </div>
          }
          <div className={classes.buttons}>
            <CopyLinkButton rawUrl={rawUrl} />
          </div>
          <div className={classes.buttons}>
            <RoomInfo
              roomUrl={rawUrl}
              roomTitle={messages.roomInfoWebAccess}
              minified
            />
          </div>
          {showDialout &&
            <div className={classes.buttons}>
              <InviteDialButton />
            </div>
          }
        </div>
      </DialogContent>
    </ClosableDialog>
  );
}


const getSlug = (state: State): string | null => {
  const meeting = getWsMeetingDetails(state.websocket) || { slug: null };
  return (meeting && meeting.slug) ? meeting.slug : state.session.username;
};

const mapStateToProps = (state: State): MappedProps => {
  return {
    roomSlug: getSlug(state),
    dialoutEnabled: state.room.dialoutEnabled,
    toggle: state.invite_participants.toggle_dialog,
    amModerator: amModerator(state),
    inMeeting: inMeeting(state),
  };
};


export default connect(mapStateToProps)(InviteToMeetingDialog);
