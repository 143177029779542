import { useDispatch } from 'react-redux';

import { changeLayout } from '../actions/room';
import { RoomLayoutConfig, RoomLayoutStreamType } from '../redux_types';


export function showStream(type: RoomLayoutStreamType, user: string, dispatch: ReturnType<typeof useDispatch>) {
  const layoutConfig: RoomLayoutConfig = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    enlarge_video: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    featured_id: user,
    // eslint-disable-next-line @typescript-eslint/camelcase
    featured_type: type
  };
  dispatch(changeLayout('mobile', layoutConfig));
}
