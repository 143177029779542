import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { iconColors as colors } from '../../colors';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      borderColor: theme.palette.grey.A100,
      borderRadius: '50%',
      padding: theme.spacing(1.5),
      minWidth: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0.4),
        marginRight: theme.spacing(0.4),
        padding: theme.spacing(1),
        backgroundColor: colors.contrast,
        borderColor: colors.contrast,
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '2em',
        marginRight: '2em',
        '&:hover': {
          backgroundColor: theme.palette.grey.A100,
        }
      },
    },
    buttonsSelected: {
      borderColor: colors.inactive,
      backgroundColor: colors.inactive,
      '&:hover': {
        backgroundColor: colors.inactive,
      }
    },
    moreIcon: {
      transform: 'rotate(180deg)'
    },
    betaBadge: {
      '&>span': {
        marginRight: '10px',
        color: theme.palette.common.white,
        background: theme.palette.warning.dark,
      },
    },
  })
);


export default useStyles;
