/* tslint:disable */
/* eslint-disable */
/* @relayHash afa4f8bae5a82e0cbc959b4c0ca99ef0 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FilterMatch = "CONTAINS" | "ENDS" | "EQUALS" | "GT" | "GTE" | "LT" | "LTE" | "STARTS" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type SearchSort = {
    direction: SortDirection;
    sort: string;
};
export type SearchFilter = {
    key: string;
    match?: FilterMatch | null;
    value: string;
};
export type myRecordingsQueryVariables = {
    count: number;
    after?: string | null;
    sorting?: Array<SearchSort | null> | null;
    filters?: Array<SearchFilter | null> | null;
};
export type myRecordingsQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"myRecordings">;
};
export type myRecordingsQuery = {
    readonly response: myRecordingsQueryResponse;
    readonly variables: myRecordingsQueryVariables;
};



/*
query myRecordingsQuery(
  $count: Int!
  $after: String
  $sorting: [SearchSort]
  $filters: [SearchFilter]
) {
  ...myRecordings
}

fragment myRecordings on RootQueryType {
  myRecordings(first: $count, after: $after, sorting: $sorting, filters: $filters) {
    edges {
      node {
        ...myRecordings_recording
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

fragment myRecordings_recording on Recording {
  id
  status
  recStart
  recEnd
  slug
  title
  notes
  number
  type
  format
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sorting",
    "type": "[SearchSort]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "[SearchFilter]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "sorting",
    "variableName": "sorting"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "myRecordingsQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "myRecordings",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "myRecordingsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myRecordings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "RecordingConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "RecordingEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Recording",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "recStart",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "recEnd",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "number",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "format",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "myRecordings",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "RecordingList_myRecordings",
        "filters": [
          "SearchFilter",
          "SearchSort"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "myRecordingsQuery",
    "id": null,
    "text": "query myRecordingsQuery(\n  $count: Int!\n  $after: String\n  $sorting: [SearchSort]\n  $filters: [SearchFilter]\n) {\n  ...myRecordings\n}\n\nfragment myRecordings on RootQueryType {\n  myRecordings(first: $count, after: $after, sorting: $sorting, filters: $filters) {\n    edges {\n      node {\n        ...myRecordings_recording\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment myRecordings_recording on Recording {\n  id\n  status\n  recStart\n  recEnd\n  slug\n  title\n  notes\n  number\n  type\n  format\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a73c7bccc54753ebd476119094d9757b';
export default node;
