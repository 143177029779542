import React from 'react';

import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles(
  (theme: Theme) => createStyles(
    {
      root: {
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
      },
      rounded: {
        color: '#fff',
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.background.paper} 99%)`,
      },
    }
  )
);


interface Props {
  name: string;
}


type AvatarNewProps = AvatarProps & Props;


export default function AvatarNew(props: AvatarNewProps) {
  const classes = useStyles();

  const initial = (s: string): string => (Array.from(s)[0] || "").toUpperCase();

  return (
    <div className={classes.root}>
      <Avatar className={classes.rounded}>
        {initial(props.name)}
      </Avatar>
    </div>
  );
}
