import { StreamAdapter } from "../../../../streamAdapters";

import { AbstractStreamTransformation } from './AbstractStreamTransformation';


export class NopStreamTransformation extends AbstractStreamTransformation {
  private context?: CanvasRenderingContext2D;

  public async setup(source: StreamAdapter, sink: StreamAdapter): Promise<void> {
    this.context = sink.get2DContext();
    this.setStreams(source, sink);
  }

  public async render(): Promise<void> {
    await this.setupPromise;
    if (!this.source || ! this.context) return;
    const frame = await this.source.getFrame();
    this.context.drawImage(frame, 0, 0);
  }

  public async stop(): Promise<void> {
    this.stopStreams();
  }
}
