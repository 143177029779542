import moment from 'moment';
import { Dispatch } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { abortFetch } from '../../lib/utils/abortableFetch';
import LocalStorage from '../../localStorage';
import { AudioRoom } from '../api/audioroom';
import { ChatRoom } from '../api/chatroom';
import { VideoRoom } from '../api/videoroom';
import {
  statsStart,
  sendConferenceMetadata,
  sendUserMetadata,
  reportError
} from '../callstats';
import { getLogger } from '../logger';
import { newEvent, ERROR, INFO, WARNING } from '../notifications';
import { LocalStreamState } from '../reducers/room';
import { Meeting } from '../reducers/websocket';
import {
  State,
  Stream,
  StreamType,
  Params,
  RoomError,
  RoomLayout,
  RoomLayoutStreamType,
  RoomLayoutConfig,
  ScreenSourceType,
  Publisher,
  UserStreamType,
  ScreenSharingOptions,
  StreamOptions,
  RosterUser,
  VideoQualityOptions,
  Error as VideoRoomError,
  EventMessageSubClass,
  ChatMessageError,
  ChatMessageErrorType,
  RecordingOptions,
  RecordingType,
  PaneType,
} from '../redux_types';
import { amModerator } from '../reduxSelectors/room';
import { callWhen } from '../utils/time';

import {
  setLockedJoinRequestDialogMinimized,
  clearLockedJoinRequests,
  Action as NotificationAction
} from './notifications';
import { saveVideoEnabled } from './settings';
import { setCanJoinRoom, Action as WaitingRoomAction } from './waitingRoom';


export const ROOM_RESET = 'ROOM_RESET';
export const ROOM_CREATED = 'ROOM_CREATED';
export const LOCALSTREAM_VIDEO_ADD = 'LOCALSTREAM_VIDEO_ADD';
export const LOCALSTREAM_VIDEO_STATE = 'LOCALSTREAM_VIDEO_STATE';
export const LOCALSTREAM_AUDIO_ADD = 'LOCALSTREAM_AUDIO_ADD';
export const LOCALSTREAM_AUDIO_REMOVE = 'LOCALSTREAM_AUDIO_REMOVE';
export const LOCALSTREAM_ERROR = 'LOCALSTREAM_ERROR';
export const REMOTESTREAM_VIDEO_ADD = 'REMOTESTREAM_VIDEO_ADD';
export const REMOTESTREAM_VIDEO_REMOVE = 'REMOTESTREAM_VIDEO_REMOVE';
export const SCREEN_REQUESTED = 'SCREEN_REQUESTED';
export const SCREEN_VIDEO_ADD = 'SCREEN_VIDEO_ADD';
export const SCREEN_VIDEO_REMOVE = 'SCREEN_VIDEO_REMOVE';
export const SCREEN_ERROR = 'SCREEN_ERROR';
export const TALKING_CHANGE = 'TALKING_CHANGE';
export const MUTEVIDEO = 'MUTEVIDEO';
export const MUTEVIDEO_REQUEST = 'MUTEVIDEO_REQUEST';
export const MUTEAUDIO_REQUEST = 'MUTEAUDIO_REQUEST';
export const PRIVATEAUDIO_REQUEST = 'PRIVATEAUDIO_REQUEST';
export const MUTEVIDEO_REQUEST_ERROR = 'MUTEVIDEO_REQUEST_ERROR';
export const MUTEAUDIO_REQUEST_ERROR = 'MUTEAUDIO_REQUEST_ERROR';
export const PRIVATEAUDIO_REQUEST_ERROR = 'PRIVATEAUDIO_REQUEST_ERROR';
export const MUTEAUDIO = 'MUTEAUDIO';
export const ENABLE_DESKTOP_CONTROL = 'ENABLE_DESKTOP_CONTROL';
export const DESKTOP_CONTROL_REQUESTED = 'DESKTOP_CONTROL_REQUESTED';
export const MUTEALLAUDIO_REQUEST = 'MUTEALLAUDIO_REQUEST';
export const MUTEALLAUDIO_DONE = 'MUTEALLAUDIO_DONE';
export const CHANGE_USER_ROLE_REQUEST = 'CHANGE_USER_ROLE_REQUEST';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const CHANGE_USER_ROLE_ERROR = 'CHANGE_USER_ROLE_ERROR';
export const APPLY_LAYOUT_REQUEST = 'APPLY_LAYOUT_REQUEST';
export const APPLY_LAYOUT_SUCCESS = 'APPLY_LAYOUT_SUCCESS';
export const APPLY_LAYOUT_ERROR = 'APPLY_LAYOUT_ERROR';
export const LOCK_ROOM_REQUEST = 'LOCK_ROOM_REQUEST';
export const LOCK_ROOM_SUCCESS = 'LOCK_ROOM_SUCCESS';
export const LOCK_ROOM_ERROR = 'LOCK_ROOM_ERROR';
export const FORCE_LAYOUT = 'FORCE_LAYOUT';
export const KICK_USER_REQUEST = 'KICK_USER_REQUEST';
export const KICK_USER_SUCCESS = 'KICK_USER_SUCCESS';
export const KICK_USER_ERROR = 'KICK_USER_ERROR';
export const EXPAND_STREAMVIDEO = 'EXPAND_STREAMVIDEO';
export const ENLARGE_STREAMVIDEO = 'ENLARGE_STREAMVIDEO';
export const PIP_USER = 'PIP_USER';
export const FULLSCREEN_USER = 'FULLSCREEN_USER';
export const INVITE_PARTICIPANTS_REQUEST = 'INVITE_PARTICIPANTS_REQUEST';
export const INVITE_PARTICIPANTS_SUCCESS = 'INVITE_PARTICIPANTS_SUCCESS';
export const INVITE_PARTICIPANTS_ERROR = 'INVITE_PARTICIPANTS_ERROR';
export const INVITE_PARTICIPANTS_TOGGLE_DIALOG = 'INVITE_PARTICIPANTS_TOGGLE_DIALOG';
export const INVITE_PARTICIPANTS_CLOSE_DIALOG = 'INVITE_PARTICIPANTS_CLOSE_DIALOG';
export const SET_SELECTED_PANE = 'SET_SELECTED_PANE';
export const PUBLIC_USERMESSAGE = 'PUBLIC_USERMESSAGE';
export const PUBLIC_USERMESSAGE_RESET_UNREAD = 'PUBLIC_USERMESSAGE_RESET_UNREAD';
export const PRIVATE_USERMESSAGE = 'PRIVATE_USERMESSAGE';
export const PRIVATE_USERMESSAGE_RESET_UNREAD_THREAD = 'PRIVATE_USERMESSAGE_RESET_UNREAD_THREAD';
export const PRIVATE_USERMESSAGE_RESET_UNREAD = 'PRIVATE_USERMESSAGE_RESET_UNREAD';
export const EVENTMESSAGE = 'EVENTMESSAGE';
export const PUBLIC_SHAREDFILE = 'PUBLIC_SHAREDFILE';
export const PRIVATE_SHAREDFILE = 'PRIVATE_SHAREDFILE';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const ROOM_ERROR = 'ROOM_ERROR';
export const ROOM_ERROR_ACKED = 'ROOM_ERROR_ACKED';
export const START_RECORDING_REQUEST = 'START_RECORDING_REQUEST';
export const START_RECORDING_SUCCESS = 'START_RECORDING_SUCCESS';
export const START_RECORDING_ERROR = 'START_RECORDING_ERROR';
export const STOP_RECORDING_REQUEST = 'STOP_RECORDING_REQUEST';
export const STOP_RECORDING_SUCCESS = 'STOP_RECORDING_SUCCESS';
export const STOP_RECORDING_ERROR = 'STOP_RECORDING_ERROR';
export const START_LIVESTREAMING_REQUEST = 'START_LIVESTREAMING_REQUEST';
export const START_LIVESTREAMING_SUCCESS = 'START_LIVESTREAMING_SUCCESS';
export const START_LIVESTREAMING_ERROR = 'START_LIVESTREAMING_ERROR';
export const STOP_LIVESTREAMING_REQUEST = 'STOP_LIVESTREAMING_REQUEST';
export const STOP_LIVESTREAMING_SUCCESS = 'STOP_LIVESTREAMING_SUCCESS';
export const STOP_LIVESTREAMING_ERROR = 'STOP_LIVESTREAMING_ERROR';
export const SET_LIVESTREAMABLE = 'SET_LIVESTREAMABLE';
export const SET_RECORDABLE = 'SET_RECORDABLE';
export const SET_AUDIORECORDABLE = 'SET_AUDIORECORDABLE';
export const SET_SHARE_SCREENS = 'SET_SHARE_SCREENS';
export const DURATION_UPDATE = 'DURATION_UPDATE';
export const ROOM_EXPIRING_SOON = 'ROOM_EXPIRING_SOON';
export const ROOM_EXPIRING_ACKED = 'ROOM_EXPIRING_ACKED';
export const MEETING_UPDATED = 'MEETING_UPDATED';
export const TOGGLE_DESKSHARE = 'TOGGLE_DESKSHARE';
export const SET_MOUSE_POINTER = 'SET_MOUSE_POINTER';
export const CLEAR_MOUSE_POINTERS = 'CLEAR_MOUSE_POINTERS';
export const DIAL_PARTICIPANT_REQUEST = "DIAL_PARTICIPANT_REQUEST";
export const DIAL_PARTICIPANT_SUCCESS = "DIAL_PARTICIPANT_SUCCESS";
export const DIAL_PARTICIPANT_ERROR = "DIAL_PARTICIPANT_ERROR";
export const TOGGLE_DIALOUT = "TOGGLE_DIALOUT";
export const PUBLISHER_ADD = "PUBLISHER_ADD";
export const PUBLISHER_REMOVE = "PUBLISHER_REMOVE";
export const SUBSCRIBE_VIDEO_REQUEST = "SUBSCRIBE_VIDEO_REQUEST";
export const SUBSCRIBE_VIDEO_REQUEST_ERROR = "SUBSCRIBE_VIDEO_REQUEST_ERROR";
export const SUBSCRIBE_VIDEO_SUCCESS = "SUBSCRIBE_VIDEO_SUCCESS";
export const ADD_OBSERVED_SCREEN = "ADD_OBSERVED_SCREEN";
export const REMOVE_OBSERVED_SCREEN = "REMOVE_OBSERVED_SCREEN";
export const SET_ROOM_OWNERSHIP = "SET_ROOM_OWNERSHIP";
export const SET_MY_ROLE = "SET_MY_ROLE";
export const SET_MEDIA_PERMISSIONS = "SET_MEDIA_PERMISSIONS";
export const RAISEHAND_REQUEST = "RAISEHAND_REQUEST";
export const RAISEHAND_SUCCESS = "RAISEHAND_SUCCESS";
export const RAISEHAND_REQUEST_ERROR = "RAISEHAND_REQUEST_ERROR";
export const SET_JOINING_ROOM = 'SET_JOINING_ROOM';
export const ROSTER_RESET = 'ROSTER_RESET';
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const SET_PRIVATE_AUDIO = 'SET_PRIVATE_AUDIO';


interface RoomResetAction {
  type: typeof ROOM_RESET;
  payload: {
  };
}

interface RoomCreatedAction {
  type: typeof ROOM_CREATED;
  payload: {
    roomObject: VideoRoom;
    roomAudio: AudioRoom;
    chatRoom: ChatRoom;
  };
}


interface SetSelectedPaneAction {
  type: typeof SET_SELECTED_PANE;
  payload: {
    selectedPane: PaneType;
  };
}


interface PublicUserMessageReceivedAction {
  type: typeof PUBLIC_USERMESSAGE;
  payload: {
    class: 'message';
    subClass: 'public';
    from: string;
    fromDisplayName: string;
    message: string;
    timestamp: number;
    msgId: string;
  };
}


interface PublicUserMessagesResetAction {
  type: typeof PUBLIC_USERMESSAGE_RESET_UNREAD;
  payload: {
  };
}


interface PrivateUserMessageReceivedAction {
  type: typeof PRIVATE_USERMESSAGE;
  payload: {
    myUsername: string;
    class: 'message';
    subClass: 'private';
    to: string;
    toDisplayName: string;
    from: string;
    fromDisplayName: string;
    message: string;
    timestamp: number;
    msgId: string;
  };
}


interface PrivateUserMessagesResetAction {
  type: typeof PRIVATE_USERMESSAGE_RESET_UNREAD;
  payload: {
  };
}


interface PrivateUserMessagesResetThreadAction {
  type: typeof PRIVATE_USERMESSAGE_RESET_UNREAD_THREAD;
  payload: {
    thread: string;
  };
}


interface EventMessageReceivedAction {
  type: typeof EVENTMESSAGE;
  payload: {
    class: 'event';
    subClass: EventMessageSubClass;
    username: string;
    fromDisplayName: string;
    timestamp: number;
  };
}

interface PublicSharedFileReceivedAction {
  type: typeof PUBLIC_SHAREDFILE;
  payload: {
    class: 'shared_file';
    subClass: 'public';
    from: string;
    fromDisplayName: string;
    message: string;
    timestamp: number;
    msgId: string;
    filename: string;
  };
}

interface PrivateSharedFileReceivedAction {
  type: typeof PRIVATE_SHAREDFILE;
  payload: {
    class: 'shared_file';
    subClass: 'private';
    to: string;
    toDisplayName: string;
    from: string;
    fromDisplayName: string;
    message: string;
    timestamp: number;
    myUsername: string;
    msgId: string;
    filename: string;
  };
}

interface StreamLocalVideoAddedAction {
  type: typeof LOCALSTREAM_VIDEO_ADD;
  payload: {
    stream: Stream;
  };
}


interface StreamLocalStateAction {
  type: typeof LOCALSTREAM_VIDEO_STATE;
  payload: {
    state: LocalStreamState;
  };
}


interface LocalStreamErrorAction {
  type: typeof LOCALSTREAM_ERROR;
  payload: {
    errorMessage: string;
  };
  error: boolean;
}


interface StreamAudioAddedAction {
  type: typeof LOCALSTREAM_AUDIO_ADD;
  payload: {
    audio_stream: Stream;
  };
}


interface StreamAudioRemovedAction {
  type: typeof LOCALSTREAM_AUDIO_REMOVE;
  payload: {
  };
}


interface StreamRemoteVideoAdded {
  type: typeof REMOTESTREAM_VIDEO_ADD;
  payload: {
    user_id: string;
    stream: Stream;
    type: StreamType;
  };
}


interface StreamRemoteVideoRemoved {
  type: typeof REMOTESTREAM_VIDEO_REMOVE;
  payload: {
    user_id: string;
    type: StreamType;
  };
}


interface VideoMuteToggledAction {
  type: typeof MUTEVIDEO;
  payload: {
    user_id: string;
    type: StreamType;
    muted: boolean;
  };
}


interface VideoMuteToggleRequestedAction {
  type: typeof MUTEVIDEO_REQUEST;
  payload: {
    user_id: string;
    type: UserStreamType;
  };
}


interface VideoMuteToggleErrorAction {
  type: typeof MUTEVIDEO_REQUEST_ERROR;
  payload: {
    user_id: string;
    type: UserStreamType;
  };
}


interface AudioMuteToggledAction {
  type: typeof MUTEAUDIO;
  payload: {
    user_id: string;
    muted: boolean;
  };
}


interface AudioMuteToggleRequestedAction {
  type: typeof MUTEAUDIO_REQUEST;
  payload: {
    user_id: string;
  };
}

interface AudioPrivateToggleRequestedAction {
  type: typeof PRIVATEAUDIO_REQUEST;
  payload: {
    userId: string;
  };
}
interface AudioMuteToggleErrorAction {
  type: typeof MUTEAUDIO_REQUEST_ERROR;
  payload: {
    user_id: string;
  };
}

interface AudioPrivateToggleErrorAction {
  type: typeof PRIVATEAUDIO_REQUEST_ERROR;
  payload: {
    userId: string;
  };
}

interface EnableDesktopControlAction {
  type: typeof ENABLE_DESKTOP_CONTROL;
  payload: {
    userId: string;
    desktopControlEnabled: boolean;
    desktopControlType: string;
  };
}


interface RequestedDesktopControlAction {
  type: typeof DESKTOP_CONTROL_REQUESTED;
  payload: {
    userId: string;
  };
}


interface AudioMuteAllRequestedAction {
  type: typeof MUTEALLAUDIO_REQUEST;
  payload: {
    user_id: string;
  };
}


interface AudioMuteAllDoneAction {
  type: typeof MUTEALLAUDIO_DONE;
  payload: {
    user_id: string;
  };
}


interface RequestedScreenAction {
  type: typeof SCREEN_REQUESTED;
  payload: {
  };
}


interface LocalScreenAddedAction {
  type: typeof SCREEN_VIDEO_ADD;
  payload: {
    stream: Stream;
    screenSourceType: ScreenSourceType;
  };
}


interface LocalScreenErrorAction {
  type: typeof SCREEN_ERROR;
  payload: {
    errorMessage: string;
  };
  error: boolean;
}


interface LocalScreenRemovedAction {
  type: typeof SCREEN_VIDEO_REMOVE;
  payload: {
  };
}


interface TalkingChangeAction {
  type: typeof TALKING_CHANGE;
  payload: {
    is_talking: boolean;
    username: string;
  };
}

interface SetPrivateAudioAction {
  type: typeof SET_PRIVATE_AUDIO;
  payload: {
    username: string;
    privateAudioConf: string | null;
    isCurrentUser: boolean;
  };
}


interface ChangeUserRoleRequestedAction {
  type: typeof CHANGE_USER_ROLE_REQUEST;
  payload: {
    user_id: string;
  };
}


interface ChangeUserRoleSuccessAction {
  type: typeof CHANGE_USER_ROLE_SUCCESS;
  payload: {
    user_id: string;
  };
}


interface ChangeUserRoleErrorAction {
  type: typeof CHANGE_USER_ROLE_ERROR;
  payload: {
    user_id: string;
  };
}


interface ApplyLayoutRequestedAction {
  type: typeof APPLY_LAYOUT_REQUEST;
  payload: {
  };
}


interface ApplyLayoutSuccessAction {
  type: typeof APPLY_LAYOUT_SUCCESS;
  payload: {
  };
}


interface ApplyLayoutErrorAction {
  type: typeof APPLY_LAYOUT_ERROR;
  payload: {
  };
}


interface ForceLayoutAction {
  type: typeof FORCE_LAYOUT;
  payload: {
    layout_type: RoomLayout;
    layout_config: RoomLayoutConfig;
  };
}


interface ChangeLayoutAction {
  type: typeof CHANGE_LAYOUT;
  payload: {
    layout_type: RoomLayout;
    layout_config: RoomLayoutConfig;
  };
}


interface LockRoomRequestedAction {
  type: typeof LOCK_ROOM_REQUEST;
  payload: {
  };
}


interface LockRoomStatusAction {
  type: typeof LOCK_ROOM_SUCCESS;
  payload: {
    is_locked: boolean;
  };
}


interface LockRoomErrorAction {
  type: typeof LOCK_ROOM_ERROR;
  payload: {
  };
}


interface ToggleDeskshareAction {
  type: typeof TOGGLE_DESKSHARE;
  payload: {
    is_enabled: boolean;
  };
}


interface ToggleDialoutAction {
  type: typeof TOGGLE_DIALOUT;
  payload: {
    is_enabled: boolean;
  };
}


interface SetRecordableAction {
  type: typeof SET_RECORDABLE;
  payload: {
    recordable: boolean;
  };
}


interface SetAudioRecordableAction {
  type: typeof SET_AUDIORECORDABLE;
  payload: {
    recordable: boolean;
  };
}

interface StartRecordingRequestedAction {
  type: typeof START_RECORDING_REQUEST;
  payload: {
  };
}


interface StartRecordingSuccessAction {
  type: typeof START_RECORDING_SUCCESS;
  payload: {
    type: RecordingType;
  };
}


interface StartRecordingErrorAction {
  type: typeof START_RECORDING_ERROR;
  payload: {
  };
}


interface StopRecordingRequestedAction {
  type: typeof STOP_RECORDING_REQUEST;
  payload: {
  };
}


interface StopRecordingSuccessAction {
  type: typeof STOP_RECORDING_SUCCESS;
  payload: {
    type: RecordingType;
  };
}


interface StopRecordingErrorAction {
  type: typeof STOP_RECORDING_ERROR;
  payload: {
  };
}


interface SetLivestreamableAction {
  type: typeof SET_LIVESTREAMABLE;
  payload: {
    streamable: boolean;
  };
}


interface StartLivestreamingRequestedAction {
  type: typeof START_LIVESTREAMING_REQUEST;
  payload: {
  };
}


interface StartLivestreamingSuccessAction {
  type: typeof START_LIVESTREAMING_SUCCESS;
  payload: {
  };
}


interface StartLivestreamingErrorAction {
  type: typeof START_LIVESTREAMING_ERROR;
  payload: {
  };
}


interface StopLivestreamingRequestedAction {
  type: typeof STOP_LIVESTREAMING_REQUEST;
  payload: {
  };
}


interface StopLivestreamingSuccessAction {
  type: typeof STOP_LIVESTREAMING_SUCCESS;
  payload: {
  };
}


interface StopLivestreamingErrorAction {
  type: typeof STOP_LIVESTREAMING_ERROR;
  payload: {
  };
}


interface KickUserRequestedAction {
  type: typeof KICK_USER_REQUEST;
  payload: {
    user_id: string;
  };
}


interface KickUserSuccessAction {
  type: typeof KICK_USER_SUCCESS;
  payload: {
    user_id: string;
  };
}


interface KickUserErrorAction {
  type: typeof KICK_USER_ERROR;
  payload: {
    user_id: string;
  };
}


interface ExpandStreamVideoRequestedAction {
  type: typeof EXPAND_STREAMVIDEO;
  payload: {
    featured_id: string;
    stream_type: RoomLayoutStreamType;
  };
}


interface EnlargeStreamVideoRequestedAction {
  type: typeof ENLARGE_STREAMVIDEO;
  payload: {
    enlarge: boolean;
  };
}


interface TogglePipUserAction {
  type: typeof PIP_USER;
  payload: {
    pipUser: string | null;
  };
}


interface ToggleFullscreenUserAction {
  type: typeof FULLSCREEN_USER;
  payload: {
    fullscreenUser: string | null;
  };
}


interface InviteParticipantsRequestedAction {
  type: typeof INVITE_PARTICIPANTS_REQUEST;
}


interface InviteParticipantsSuccessAction {
  type: typeof INVITE_PARTICIPANTS_SUCCESS;
}


interface InviteParticipantsErrorAction {
  type: typeof INVITE_PARTICIPANTS_ERROR;
  payload: {
    errCode: number;
  };
}


interface InviteParticipantsToggleDialogAction {
  type: typeof INVITE_PARTICIPANTS_TOGGLE_DIALOG;
}

interface InviteParticipantsCloseDialogAction {
  type: typeof INVITE_PARTICIPANTS_CLOSE_DIALOG;
}

interface DialParticipantRequestedAction {
  type: typeof DIAL_PARTICIPANT_REQUEST;
}


interface DialParticipantSuccessAction {
  type: typeof DIAL_PARTICIPANT_SUCCESS;
}


interface DialParticipantErrorAction {
  type: typeof DIAL_PARTICIPANT_ERROR;
  payload: {
    errCode: number;
  };
}


interface RoomErrorReceivedAction {
  type: typeof ROOM_ERROR;
  payload: {
    error: VideoRoomError;
    recoverable: boolean;
  };
}


interface RoomErrorAckedAction {
  type: typeof ROOM_ERROR_ACKED;
  payload: {
  };
}


interface MeetingDurationUpdateAction {
  type: typeof DURATION_UPDATE;
  payload: {
    elapsed: number;
  };
}


interface MeetingExpiringSoonAction {
  type: typeof ROOM_EXPIRING_SOON;
  payload: {
    meetingEnd: Date;
  };
}

interface MeetingUpdatedAction {
  type: typeof MEETING_UPDATED;
  payload: {
    meeting: Meeting;
  };
}


interface ExpiringMeetingAckedAction {
  type: typeof ROOM_EXPIRING_ACKED;
  payload: {
  };
}


interface RosterResetAction {
  type: typeof ROSTER_RESET;
  payload: {
  };
}


interface AddPublisherAction {
  type: typeof PUBLISHER_ADD;
  payload: {
    publisher: Publisher;
  };
}


interface RemovePublisherAction {
  type: typeof PUBLISHER_REMOVE;
  payload: {
    publisherId: number;
  };
}


interface SubscribeToVideoRequestedAction {
  type: typeof SUBSCRIBE_VIDEO_REQUEST;
  payload: {
    userId: string;
    type: UserStreamType;
  };
}


interface SubscribeToVideoErrorAction {
  type: typeof SUBSCRIBE_VIDEO_REQUEST_ERROR;
  payload: {
    userId: string;
    type: UserStreamType;
  };
}


interface SubscribeToVideoSuccessAction {
  type: typeof SUBSCRIBE_VIDEO_SUCCESS;
  payload: {
    userId: string;
    type: UserStreamType;
  };
}


interface AddObservedScreenAction {
  type: typeof ADD_OBSERVED_SCREEN;
  payload: {
    userId: string;
  };
}


interface RemoveObservedScreenAction {
  type: typeof REMOVE_OBSERVED_SCREEN;
  payload: {
    userId: string;
  };
}


interface SetRoomOwnershipAction {
  type: typeof SET_ROOM_OWNERSHIP;
  payload: {
    isOwner: boolean;
    ownerIsAudioOnly: boolean;
  };
}


interface SetMediaPermissionsAction {
  type: typeof SET_MEDIA_PERMISSIONS;
  payload: {
    audio: boolean;
    video: boolean;
  };
}


interface SetMyRoleAction {
  type: typeof SET_MY_ROLE;
  payload: {
    role: string;
  };
}


interface RaiseHandToggleRequestedAction {
  type: typeof RAISEHAND_REQUEST;
  payload: {
    userId: string;
  };
}


interface RaiseHandToggleSuccessAction {
  type: typeof RAISEHAND_SUCCESS;
  payload: {
    userId: string;
  };
}


interface RaiseHandToggleErrorAction {
  type: typeof RAISEHAND_REQUEST_ERROR;
  payload: {
    userId: string;
  };
}


interface SetJoiningRoomAction {
  type: typeof SET_JOINING_ROOM;
  payload: {
    isJoining: boolean;
  };
}

interface UploadFileRequestAction {
  type: typeof UPLOAD_FILE_REQUEST;
  payload: {
    request: string | null;
  };
}

export type Action =
  RoomResetAction
  | RoomCreatedAction
  | SetSelectedPaneAction
  | PublicUserMessageReceivedAction
  | PublicUserMessagesResetAction
  | PrivateUserMessageReceivedAction
  | PrivateUserMessagesResetAction
  | PrivateUserMessagesResetThreadAction
  | EventMessageReceivedAction
  | PublicSharedFileReceivedAction
  | PrivateSharedFileReceivedAction
  | StreamLocalVideoAddedAction
  | LocalStreamErrorAction
  | StreamAudioAddedAction
  | StreamAudioRemovedAction
  | StreamRemoteVideoAdded
  | StreamRemoteVideoRemoved
  | VideoMuteToggledAction
  | VideoMuteToggleRequestedAction
  | VideoMuteToggleErrorAction
  | AudioMuteToggledAction
  | AudioMuteToggleRequestedAction
  | AudioMuteToggleErrorAction
  | AudioPrivateToggleRequestedAction
  | AudioPrivateToggleErrorAction
  | EnableDesktopControlAction
  | RequestedDesktopControlAction
  | AudioMuteAllRequestedAction
  | AudioMuteAllDoneAction
  | RequestedScreenAction
  | LocalScreenAddedAction
  | LocalScreenErrorAction
  | LocalScreenRemovedAction
  | TalkingChangeAction
  | ChangeUserRoleRequestedAction
  | ChangeUserRoleSuccessAction
  | ChangeUserRoleErrorAction
  | ApplyLayoutRequestedAction
  | ApplyLayoutSuccessAction
  | ApplyLayoutErrorAction
  | ChangeLayoutAction
  | ForceLayoutAction
  | LockRoomRequestedAction
  | LockRoomStatusAction
  | LockRoomErrorAction
  | ToggleDeskshareAction
  | ToggleDialoutAction
  | SetRecordableAction
  | SetAudioRecordableAction
  | StartRecordingRequestedAction
  | StartRecordingSuccessAction
  | StartRecordingErrorAction
  | StopRecordingRequestedAction
  | StopRecordingSuccessAction
  | StopRecordingErrorAction
  | SetLivestreamableAction
  | StartLivestreamingRequestedAction
  | StartLivestreamingSuccessAction
  | StartLivestreamingErrorAction
  | StopLivestreamingRequestedAction
  | StopLivestreamingSuccessAction
  | StopLivestreamingErrorAction
  | KickUserRequestedAction
  | KickUserSuccessAction
  | KickUserErrorAction
  | ExpandStreamVideoRequestedAction
  | EnlargeStreamVideoRequestedAction
  | TogglePipUserAction
  | ToggleFullscreenUserAction
  | InviteParticipantsRequestedAction
  | InviteParticipantsSuccessAction
  | InviteParticipantsErrorAction
  | InviteParticipantsToggleDialogAction
  | InviteParticipantsCloseDialogAction
  | DialParticipantRequestedAction
  | DialParticipantSuccessAction
  | DialParticipantErrorAction
  | RoomErrorReceivedAction
  | RoomErrorAckedAction
  | MeetingDurationUpdateAction
  | MeetingExpiringSoonAction
  | MeetingUpdatedAction
  | ExpiringMeetingAckedAction
  | RosterResetAction
  | AddPublisherAction
  | RemovePublisherAction
  | SubscribeToVideoRequestedAction
  | SubscribeToVideoErrorAction
  | SubscribeToVideoSuccessAction
  | AddObservedScreenAction
  | RemoveObservedScreenAction
  | SetRoomOwnershipAction
  | SetMediaPermissionsAction
  | SetMyRoleAction
  | RaiseHandToggleSuccessAction
  | RaiseHandToggleRequestedAction
  | RaiseHandToggleErrorAction
  | SetJoiningRoomAction
  | NotificationAction
  | UploadFileRequestAction
  | SetPrivateAudioAction
  | WaitingRoomAction
  | StreamLocalStateAction;


type ScreenStream = {
  stream: Stream;
  type: null | ScreenSourceType;
}
type RoomThunkAction = ThunkAction<void, State, null, Action>


function roomReset(): RoomResetAction {
  return {
    type: ROOM_RESET,
    payload: {
    }
  };
}

function roomCreated(videoRoom: VideoRoom, audioRoom: AudioRoom, chatRoom: ChatRoom): RoomCreatedAction {
  return {
    type: ROOM_CREATED,
    payload: {
      roomObject: videoRoom,
      roomAudio: audioRoom,
      chatRoom: chatRoom,
    }
  };
}


function streamLocalVideoAdded(stream: Stream): StreamLocalVideoAddedAction {
  return {
    type: LOCALSTREAM_VIDEO_ADD,
    payload: {
      stream: stream,
    }
  };
}


function streamLocalVideoPublished(state: LocalStreamState): StreamLocalStateAction {
  return {
    type: LOCALSTREAM_VIDEO_STATE,
    payload: {
      state: state
    }
  };
}


function streamAudioAdded(stream: Stream): StreamAudioAddedAction {
  return {
    type: LOCALSTREAM_AUDIO_ADD,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      audio_stream: stream,
    }
  };
}


function streamAudioRemoved(): StreamAudioRemovedAction {
  return {
    type: LOCALSTREAM_AUDIO_REMOVE,
    payload: {
    }
  };
}


function addPublisher(p: Publisher): AddPublisherAction {
  return {
    type: PUBLISHER_ADD,
    payload: {
      publisher: p
    }
  };
}

function removePublisher(id: number): RemovePublisherAction {
  return {
    type: PUBLISHER_REMOVE,
    payload: {
      publisherId: id
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function streamRemoteVideoAdded(stream: Stream, user_id: string, type: StreamType): StreamRemoteVideoAdded {
  return {
    type: REMOTESTREAM_VIDEO_ADD,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
      stream: stream,
      type: type,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function streamRemoteVideoRemoved(user_id: string, type: StreamType): StreamRemoteVideoRemoved {
  return {
    type: REMOTESTREAM_VIDEO_REMOVE,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
      type: type,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function videoMuteToggled(user_id: string, type: StreamType, muted: boolean): VideoMuteToggledAction {
  return {
    type: MUTEVIDEO,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
      type: type,
      muted: muted,
    }
  };
}


function videoMuteToggleRequested(userId: string, type: UserStreamType): VideoMuteToggleRequestedAction {
  return {
    type: MUTEVIDEO_REQUEST,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: userId,
      type: type,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function videoMuteToggleError(user_id: string, type: UserStreamType): VideoMuteToggleErrorAction {
  return {
    type: MUTEVIDEO_REQUEST_ERROR,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
      type: type,
    }
  };
}


function raiseHandSuccess(userId: string): RaiseHandToggleSuccessAction {
  return {
    type: RAISEHAND_SUCCESS,
    payload: {
      userId: userId,
    }
  };
}


function raiseHandToggleRequested(userId: string): RaiseHandToggleRequestedAction {
  return {
    type: RAISEHAND_REQUEST,
    payload: {
      userId: userId,
    }
  };
}


function raiseHandToggleError(userId: string): RaiseHandToggleErrorAction {
  return {
    type: RAISEHAND_REQUEST_ERROR,
    payload: {
      userId: userId,
    }
  };
}


function subscribeToVideoRequested(uid: string, type: UserStreamType): SubscribeToVideoRequestedAction {
  return {
    type: SUBSCRIBE_VIDEO_REQUEST,
    payload: {
      userId: uid,
      type: type,
    }
  };
}


function subscribeToVideoError(uid: string, type: UserStreamType): SubscribeToVideoErrorAction {
  return {
    type: SUBSCRIBE_VIDEO_REQUEST_ERROR,
    payload: {
      userId: uid,
      type: type,
    }
  };
}


function subscribeToVideoSuccess(userId: string, type: UserStreamType): SubscribeToVideoSuccessAction {
  return {
    type: SUBSCRIBE_VIDEO_SUCCESS,
    payload: {
      userId: userId,
      type: type,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function audioMuteToggleRequested(user_id: string): AudioMuteToggleRequestedAction {
  return {
    type: MUTEAUDIO_REQUEST,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


function audioPrivateToggleRequested(userId: string): AudioPrivateToggleRequestedAction {
  return {
    type: PRIVATEAUDIO_REQUEST,
    payload: {
      userId: userId,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function audioMuteToggled(user_id: string, muted: boolean): AudioMuteToggledAction {
  return {
    type: MUTEAUDIO,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
      muted: muted,
    }
  };
}


export function onEnableDesktopControl(
  userId: string,
  desktopControlEnabled: boolean,
  desktopControlType: string
): EnableDesktopControlAction {
  return {
    type: ENABLE_DESKTOP_CONTROL,
    payload: {
      userId,
      desktopControlEnabled,
      desktopControlType,
    }
  };
}

function requestDesktopControl(userId: string): RequestedDesktopControlAction {
  return {
    type: DESKTOP_CONTROL_REQUESTED,
    payload: {
      userId: userId,
    }
  };
}

// eslint-disable-next-line @typescript-eslint/camelcase
function audioMuteToggleError(user_id: string): AudioMuteToggleErrorAction {
  return {
    type: MUTEAUDIO_REQUEST_ERROR,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}

function audioPrivateToggleError(userId: string): AudioPrivateToggleErrorAction {
  return {
    type: PRIVATEAUDIO_REQUEST_ERROR,
    payload: {
      userId: userId,
    }
  };
}

// eslint-disable-next-line @typescript-eslint/camelcase
function audioMuteAllRequested(user_id: string): AudioMuteAllRequestedAction {
  return {
    type: MUTEALLAUDIO_REQUEST,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function audioMuteAllDone(user_id: string): AudioMuteAllDoneAction {
  return {
    type: MUTEALLAUDIO_DONE,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


function requestScreen(): RequestedScreenAction {
  return {
    type: SCREEN_REQUESTED,
    payload: {
    }
  };
}


function localScreenAdded(screenStream: ScreenStream): LocalScreenAddedAction {
  return {
    type: SCREEN_VIDEO_ADD,
    payload: {
      stream: screenStream.stream,
      screenSourceType: screenStream.type,
    }
  };
}


function localScreenError(err: string): LocalScreenErrorAction {
  return {
    type: SCREEN_ERROR,
    payload: {
      errorMessage: err,
    },
    error: true,
  };
}


function localScreenRemoved(): LocalScreenRemovedAction {
  return {
    type: SCREEN_VIDEO_REMOVE,
    payload: {
    }
  };
}


function localStreamError(errMessage: string): LocalStreamErrorAction {
  return {
    type: LOCALSTREAM_ERROR,
    payload: {
      errorMessage: errMessage,
    },
    error: true,
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function onTalkingEvent(username: string, is_talking: boolean): TalkingChangeAction {
  return {
    type: TALKING_CHANGE,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_talking: is_talking,
      username: username,
    }
  };
}

function setPrivateAudio(
  username: string,
  privateAudioConf: string | null,
  isCurrentUser: boolean
): SetPrivateAudioAction {
  return {
    type: SET_PRIVATE_AUDIO,
    payload: {
      username: username,
      privateAudioConf: privateAudioConf,
      isCurrentUser: isCurrentUser,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function changeUserRoleRequested(user_id: string): ChangeUserRoleRequestedAction {
  return {
    type: CHANGE_USER_ROLE_REQUEST,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function changeUserRoleSuccess(user_id: string): ChangeUserRoleSuccessAction {
  return {
    type: CHANGE_USER_ROLE_SUCCESS,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function changeUserRoleError(user_id: string): ChangeUserRoleErrorAction {
  return {
    type: CHANGE_USER_ROLE_ERROR,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


function applyLayoutRequested(): ApplyLayoutRequestedAction {
  return {
    type: APPLY_LAYOUT_REQUEST,
    payload: {
    }
  };
}


function applyLayoutSuccess(): ApplyLayoutSuccessAction {
  return {
    type: APPLY_LAYOUT_SUCCESS,
    payload: {
    }
  };
}


function applyLayoutError(): ApplyLayoutErrorAction {
  return {
    type: APPLY_LAYOUT_ERROR,
    payload: {
    }
  };
}


function lockRoomRequested(): LockRoomRequestedAction {
  return {
    type: LOCK_ROOM_REQUEST,
    payload: {
    }
  };
}


export function lockRoomStatus(value: boolean): LockRoomStatusAction {
  return {
    type: LOCK_ROOM_SUCCESS,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_locked: value
    }
  };
}


function lockRoomError(): LockRoomErrorAction {
  return {
    type: LOCK_ROOM_ERROR,
    payload: {
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
export function toggleDeskshare(is_enabled: boolean): ToggleDeskshareAction {
  return {
    type: TOGGLE_DESKSHARE,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_enabled: is_enabled
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
export function toggleDialout(is_enabled: boolean): ToggleDialoutAction {
  return {
    type: TOGGLE_DIALOUT,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_enabled: is_enabled
    }
  };
}


export function setRecordable(value: boolean): SetRecordableAction {
  return {
    type: SET_RECORDABLE,
    payload: {
      recordable: value
    }
  };
}

export function setAudioRecordable(value: boolean): SetAudioRecordableAction {
  return {
    type: SET_AUDIORECORDABLE,
    payload: {
      recordable: value
    }
  };
}

function startRecordingRequested(): StartRecordingRequestedAction {
  return {
    type: START_RECORDING_REQUEST,
    payload: {
    }
  };
}


export function startRecordingSuccess(type: RecordingType): StartRecordingSuccessAction {
  return {
    type: START_RECORDING_SUCCESS,
    payload: {
      type: type
    }
  };
}


function startRecordingError(): StartRecordingErrorAction {
  return {
    type: START_RECORDING_ERROR,
    payload: {
    }
  };
}


function stopRecordingRequested(): StopRecordingRequestedAction {
  return {
    type: STOP_RECORDING_REQUEST,
    payload: {
    }
  };
}


export function stopRecordingSuccess(type: RecordingType): StopRecordingSuccessAction {
  return {
    type: STOP_RECORDING_SUCCESS,
    payload: {
      type: type
    }
  };
}


function stopRecordingError(): StopRecordingErrorAction {
  return {
    type: STOP_RECORDING_ERROR,
    payload: {
    }
  };
}


export function setLivestreamable(value: boolean): SetLivestreamableAction {
  return {
    type: SET_LIVESTREAMABLE,
    payload: {
      streamable: value
    }
  };
}


function startLivestreamingRequested(): StartLivestreamingRequestedAction {
  return {
    type: START_LIVESTREAMING_REQUEST,
    payload: {
    }
  };
}


export function startLivestreamingSuccess(): StartLivestreamingSuccessAction {
  return {
    type: START_LIVESTREAMING_SUCCESS,
    payload: {
    }
  };
}


function startLivestreamingError(): StartLivestreamingErrorAction {
  return {
    type: START_LIVESTREAMING_ERROR,
    payload: {
    }
  };
}


function stopLivestreamingRequested(): StopLivestreamingRequestedAction {
  return {
    type: STOP_LIVESTREAMING_REQUEST,
    payload: {
    }
  };
}


export function stopLivestreamingSuccess(): StopLivestreamingSuccessAction {
  return {
    type: STOP_LIVESTREAMING_SUCCESS,
    payload: {
    }
  };
}


function stopLivestreamingError(): StopLivestreamingErrorAction {
  return {
    type: STOP_LIVESTREAMING_ERROR,
    payload: {
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function kickUserRequested(user_id: string): KickUserRequestedAction {
  return {
    type: KICK_USER_REQUEST,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function kickUserSuccess(user_id: string): KickUserSuccessAction {
  return {
    type: KICK_USER_SUCCESS,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function kickUserError(user_id: string): KickUserErrorAction {
  return {
    type: KICK_USER_ERROR,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      user_id: user_id,
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
function expandStreamVideoRequested(user_id: string,
  streamType: RoomLayoutStreamType
): ExpandStreamVideoRequestedAction {
  return {
    type: EXPAND_STREAMVIDEO,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      featured_id: user_id,
      // eslint-disable-next-line @typescript-eslint/camelcase
      stream_type: streamType,
    }
  };
}


function enlargeStreamVideoRequested(enlarge: boolean): EnlargeStreamVideoRequestedAction {
  return {
    type: ENLARGE_STREAMVIDEO,
    payload: {
      enlarge: enlarge,
    }
  };
}


function inviteParticipantsRequested(): InviteParticipantsRequestedAction {
  return {
    type: INVITE_PARTICIPANTS_REQUEST,
  };
}


function inviteParticipantsSuccess(): InviteParticipantsSuccessAction {
  return {
    type: INVITE_PARTICIPANTS_SUCCESS
  };
}


export function toggleInviteParticipantsDialog(): InviteParticipantsToggleDialogAction {
  return {
    type: INVITE_PARTICIPANTS_TOGGLE_DIALOG
  };
}


function inviteParticipantsCloseDialog(): InviteParticipantsCloseDialogAction {
  return {
    type: INVITE_PARTICIPANTS_CLOSE_DIALOG
  };
}


function inviteParticipantsError(errCode: number): InviteParticipantsErrorAction {
  return {
    type: INVITE_PARTICIPANTS_ERROR,
    payload: {
      errCode: errCode
    }
  };
}


function dialParticipantRequested(): DialParticipantRequestedAction {
  return {
    type: DIAL_PARTICIPANT_REQUEST,
  };
}


function dialParticipantSuccess(): DialParticipantSuccessAction {
  return {
    type: DIAL_PARTICIPANT_SUCCESS
  };
}


function dialParticipantError(errCode: number): DialParticipantErrorAction {
  return {
    type: DIAL_PARTICIPANT_ERROR,
    payload: {
      errCode: errCode
    }
  };
}


function publicUserMessageReceived(
  cls: 'message',
  subClass: 'public',
  from: string,
  fromDisplayName: string,
  message: string,
  timestamp: number,
  msgId: string,
): PublicUserMessageReceivedAction {
  return {
    type: PUBLIC_USERMESSAGE,
    payload: {
      class: cls,
      subClass: subClass,
      from: from,
      fromDisplayName: fromDisplayName,
      message: message,
      timestamp: timestamp,
      msgId: msgId,
    }
  };
}


function privateUserMessageReceived(
  cls: 'message',
  subClass: 'private',
  to: string,
  toDisplayName: string,
  from: string,
  fromDisplayName: string,
  message: string,
  timestamp: number,
  state: State,
  msgId: string,
): PrivateUserMessageReceivedAction {
  const myUsername = state.session.username;
  return {
    type: PRIVATE_USERMESSAGE,
    payload: {
      class: cls,
      subClass: subClass,
      to: to,
      toDisplayName: toDisplayName,
      from: from,
      fromDisplayName: fromDisplayName,
      message: message,
      timestamp: timestamp,
      myUsername: myUsername,
      msgId: msgId,
    }
  };
}


function eventMessageReceived(
  cls: 'event',
  subClass: EventMessageSubClass,
  username: string,
  fromDisplayName: string,
  timestamp: number,
): EventMessageReceivedAction {
  return {
    type: EVENTMESSAGE,
    payload: {
      class: cls,
      subClass: subClass,
      username: username,
      fromDisplayName: fromDisplayName,
      timestamp: timestamp,
    }
  };
}

function publicSharedFileReceived(
  cls: 'shared_file',
  subClass: 'public',
  from: string,
  fromDisplayName: string,
  message: string,
  timestamp: number,
  msgId: string,
  filename: string,
): PublicSharedFileReceivedAction {
  return {
    type: PUBLIC_SHAREDFILE,
    payload: {
      class: cls,
      subClass: subClass,
      from: from,
      fromDisplayName: fromDisplayName,
      message: message,
      timestamp: timestamp,
      msgId: msgId,
      filename: filename
    }
  };
}

function privateSharedFileReceived(
  cls: 'shared_file',
  subClass: 'private',
  to: string,
  toDisplayName: string,
  from: string,
  fromDisplayName: string,
  message: string,
  timestamp: number,
  state: State,
  msgId: string,
  filename: string,
): PrivateSharedFileReceivedAction {
  const myUsername = state.session.username;
  return {
    type: PRIVATE_SHAREDFILE,
    payload: {
      class: cls,
      subClass: subClass,
      to: to,
      toDisplayName: toDisplayName,
      from: from,
      fromDisplayName: fromDisplayName,
      message: message,
      timestamp: timestamp,
      myUsername: myUsername,
      msgId: msgId,
      filename: filename
    }
  };
}

function roomErrorReceived(err: VideoRoomError, recoverable: boolean): RoomErrorReceivedAction {
  return {
    type: ROOM_ERROR,
    payload: {
      error: err,
      recoverable: recoverable,
    }
  };
}

function meetingDurationUpdate(elapsed: number): MeetingDurationUpdateAction {
  return {
    type: DURATION_UPDATE,
    payload: {
      elapsed: elapsed
    }
  };
}


function meetingExpiringSoon(meetingEnd: Date): MeetingExpiringSoonAction {
  return {
    type: ROOM_EXPIRING_SOON,
    payload: {
      meetingEnd: meetingEnd,
    }
  };
}


function meetingUpdated(meeting: Meeting): MeetingUpdatedAction {
  return {
    type: MEETING_UPDATED,
    payload: { meeting },
  };
}


export function expiringMeetingAcked(): ExpiringMeetingAckedAction {
  return {
    type: ROOM_EXPIRING_ACKED,
    payload: {
    }
  };
}


export function rosterReset(): RosterResetAction {
  return {
    type: ROSTER_RESET,
    payload: {
    }
  };
}


export function extendMeeting(): RoomThunkAction {
  return (dispatch, getState) => {
    return doExtendMeeting(dispatch, getState);
  };
}


function doExtendMeeting(dispatch: Dispatch<Action>, getState: () => State) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  if (!room) {
    return;
  }

  room.extendMeeting();
}


function releaseScreen(dispatch: Dispatch<Action>, getState: () => State) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  if (!room) {
    return dispatch(localStreamError('no room object found'));
  }
  room.stopScreen();
  dispatch(localScreenRemoved());
}


function createRoom(
  roomname: string,
  // FIXME
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rtcProvider: any,
  logger: ReturnType<typeof getLogger>,
  dispatch: ThunkDispatch<State, null, Action>,
  getState: () => State
) {
  const state = getState();
  const wsstate = (state || {}).websocket;
  const apiserver = (wsstate || {}).apiserver;
  const session = (state || {}).session;
  const stunServers = (session || {}).stunServers;
  const appconfig = (state || {}).appconfig;

  const videoRoom = new VideoRoom(
    roomname,
    rtcProvider,
    () => apiserver,
    {
      stunServers,
      iceReconnectRetries: appconfig.room_options.ice_reconnect_retries,
      iceReconnectRetriesWindow: appconfig.room_options.ice_reconnect_retries_window,
      maxBitrate: appconfig.room_options.max_bitrate,
    },
    logger
  );

  videoRoom.onAddAudioStream = (stream) => dispatch(streamAudioAdded(stream));
  videoRoom.onRemoveAudioStream = () => dispatch(streamAudioRemoved());
  videoRoom.onNewPublisher = (p) => dispatch(addPublisher(p));
  videoRoom.onUnpublish = (id) => dispatch(removePublisher(id));

  videoRoom.onAddRemoteVideoStream = (stream, userId, type) => {
    const userIsThere = () => {
      const room = (getState() || {}).room;
      const roster = (room || {}).roster;
      return roster && roster[userId];
    };
    const doDispatch = () => {
      dispatch(streamRemoteVideoAdded(stream, userId, type));
    };
    callWhen(userIsThere, doDispatch, 10000, 5);
  };
  videoRoom.onRemoveRemoteVideoStream = (userId, type) => {
    dispatch(streamRemoteVideoRemoved(userId, type));
    dispatch(removeObservedScreen(userId));
  };
  videoRoom.onToggleVideoMute = (userId, type, muted) => {
    if (type === "localvideoevent" && muted === false) {
      dispatch(streamLocalVideoPublished('PUBLISHED'));
    } else if (type === "localvideoevent" && muted === true) {
      dispatch(streamLocalVideoPublished('IDLE'));
    }
    dispatch(videoMuteToggled(userId, type, muted));
    if (!muted) {
      dispatch(subscribeToVideoSuccess(userId, type === 'remotescreenevent' ? 'screen' : 'stream'));
    }
  };
  videoRoom.onError = (err, recoverable) => {
    if (err.errorCode === 1410) {
      newEvent(WARNING, 'videoPublishFailure', err.errorMessage, err.errorMessage);
    }
    else if (err.errorCode === 1412) {
      newEvent(WARNING, 'screenPublishFailure', err.errorMessage, err.errorMessage);
    }
    else if (err.errorCode === 1413) {
      newEvent(WARNING, 'uploadICEGlitches', err.errorMessage, err.errorMessage);
    }
    else if (err.errorCode === 1414) {
      newEvent(WARNING, 'downloadICEGlitches', err.errorMessage, err.errorMessage);
    }
    else {
      dispatch(roomErrorReceived(err, recoverable));
    }
  };
  videoRoom.enableDesktopControl =
    (username, enabled, type) => dispatch(enableDesktopControl(username, enabled, type));
  videoRoom.onMeetingTimeEvents = (type, data) => {
    if (type === 'duration') {
      const { elapsed } = data;
      dispatch(meetingDurationUpdate(elapsed));
    }
    else if (type === 'expiring' && data.dt_end) {
      dispatch(meetingExpiringSoon(data.dt_end));
    }
    else if (type === 'update' && data.error) {
      newEvent(WARNING, 'conferenceUpdateError', data.error.code, data.error.reason);
    }
    else if (type === 'update' && data.meeting) {
      dispatch(expiringMeetingAcked());
      dispatch(meetingUpdated(data.meeting));
      const endTime = moment(data.meeting.dt_end).format('H:mm');
      newEvent(INFO, 'conferenceUpdate', 'conferenceUpdate', 'conferenceUpdate', { endDate: endTime });
    }
  };

  const audioRoom = new AudioRoom(() => apiserver, logger);
  audioRoom.onTalkingEvent = (username, isTalking) => dispatch(onTalkingEvent(username, isTalking));
  audioRoom.onToggleAudioMute = onToggleAudioMute(videoRoom, dispatch);
  audioRoom.onJoinedPrivateAudio = (username, privateConfName) => {
    dispatch(joinedPrivateAudio(username, privateConfName));
  };
  audioRoom.onLeftPrivateAudio = (username) => {
    dispatch(leftPrivateAudio(username));
  };

  const chatRoom = new ChatRoom(() => apiserver, logger);
  chatRoom.onEventMessageReceived = (c, s, u, d, t) => dispatch(eventMessageReceived(c, s, u, d, t));
  chatRoom.onPublicMessageReceived = (c, s, f, fd, m, t, id) =>
    dispatch(publicUserMessageReceived(c, s, f, fd, m, t, id));
  chatRoom.onPrivateMessageReceived = (c, s, to, tod, f, fd, m, t, id) =>
    dispatch(privateUserMessageReceived(c, s, to, tod, f, fd, m, t, getState(), id));
  chatRoom.onPublicSharedFile = (c, s, f, fd, m, t, id, fname) =>
    dispatch(publicSharedFileReceived(c, s, f, fd, m, t, id, fname));
  chatRoom.onPrivateSharedFile = (c, s, to, tod, f, fd, m, t, id, fname) =>
    dispatch(privateSharedFileReceived(c, s, to, tod, f, fd, m, t, getState(), id, fname));

  return dispatch(roomCreated(videoRoom, audioRoom, chatRoom));
}

function onToggleAudioMute(videoRoom: VideoRoom, dispatch: ThunkDispatch<State, null, Action>) {
  return (username: string, isMuted: boolean) => {
    videoRoom.onToggleOwnAudioMute(username, isMuted);
    return dispatch(audioMuteToggled(username, isMuted));
  };
}

function joinedPrivateAudio(username: string, privateAudioConf: string | null): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const room = (state || {}).room;
    const roster = (room || {}).roster;
    const displayName = (roster[username] || {}).display;
    const websocket = (state || {}).websocket;
    const currentUser = (websocket || {}).uid;

    if (displayName && shouldDisplayPrivateConfNotification(state, username)) {
      newEvent(
        INFO,
        username === currentUser ? 'youJoinedPrivateConf' : 'joinedPrivateConf',
        username === currentUser ? 'youJoinedPrivateConf' : 'joinedPrivateConf',
        username === currentUser ? 'youJoinedPrivateConf' : 'joinedPrivateConf',
        { displayName: displayName }
      );
    }

    return dispatch(setPrivateAudio(username, privateAudioConf, username === currentUser));
  };
}

function leftPrivateAudio(username: string): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const room = (state || {}).room;
    const roster = (room || {}).roster;
    const displayName = (roster[username] || {}).display;
    const websocket = (state || {}).websocket;
    const currentUser = (websocket || {}).uid;

    if (displayName && shouldDisplayPrivateConfNotification(state, username)) {
      newEvent(
        INFO,
        username === currentUser ? 'youLeftPrivateConf' : 'leftPrivateConf',
        username === currentUser ? 'youLeftPrivateConf' : 'leftPrivateConf',
        username === currentUser ? 'youLeftPrivateConf' : 'leftPrivateConf',
        { displayName: displayName }
      );
    }

    return dispatch(setPrivateAudio(username, null, username === currentUser));
  };
}

function shouldDisplayPrivateConfNotification(state: State, username: string) {
  const room = (state || {}).room;
  const websocket = (state || {}).websocket;
  const currentUser = (websocket || {}).uid;
  const layout = (room || {}).layout;

  const restrictedLayouts = ['webinar', 'lesson', 'mobile'];

  return amModerator(state) || username === currentUser || !restrictedLayouts.includes(layout);
}

function setupCallStats(state: State) {
  if (!state.session.callStatsConfig) return;

  const { appId, appSecret, endpoint } = state.session.callStatsConfig;
  const backend = endpoint ? 'voismart' : 'callstatsio';
  const realm = state.session.realm;
  const displayName = state.session.displayName;
  const username = state.session.username;
  const uid = state.websocket.uid;
  const userId = {
    userName: realm ? `"${displayName}" ${username}@${realm}` : `"${displayName}" ${username}`,
    aliasName: uid,
  };
  const parsedUa = undefined; // do we need this?
  if (appId && appSecret) {
    statsStart(appId, appSecret, userId, parsedUa, { endpoint }, backend);
  }
}



function getStream(
  dispatch: Dispatch<Action>,
  getState: () => State,
  roomname: string,
  streamOptions: StreamOptions,
  logger: ReturnType<typeof getLogger>
) {
  const state = getState();
  const websocket = (getState() || {}).websocket;
  const apiserver = (websocket || {}).apiserver;
  const session = (getState() || {}).session;
  const roomState = (getState() || {}).room;
  const room = (roomState || {}).roomObject;

  if (!apiserver) {
    logger.error('no apiserver found');
    return dispatch(localStreamError('no apiserver found'));
  }
  if (!room) {
    logger.error('no room found');
    return dispatch(localStreamError('no room found'));
  }

  setupCallStats(state);

  if (streamOptions.acquireVideo) {
    dispatch(streamLocalVideoPublished('PUBLISHING'));
  }

  return room.getLocalStream(getState(), streamOptions).then(
    (stream: Stream) => {
      sendConferenceMetadata(room.getConferenceID(), {
        // eslint-disable-next-line @typescript-eslint/camelcase
        room_name: roomname,
        realm: session.realm
      });
      sendUserMetadata(room.getConferenceID(), websocket.uid, {
        username: session.username,
        realm: session.realm,
        // eslint-disable-next-line @typescript-eslint/camelcase
        display_name: session.displayName
      });
      if (!VideoRoom.getVideoTrackFromStream(stream)) {
        dispatch(streamLocalVideoPublished('IDLE'));
      }
      return dispatch(streamLocalVideoAdded(stream));
    }
  ).catch(
    (err: Error) => {
      reportError(room.getConferenceID(), websocket.uid, "getUserMedia");
      logger.error('error getUserMedia', err);
      return dispatch(localStreamError(err.name));
    }
  );
}


function _republishStream(
  dispatch: Dispatch<Action>,
  getState: () => State,
  streamOptions: StreamOptions,
  logger: ReturnType<typeof getLogger>
) {
  const state = (getState() || {}).websocket;
  const apiserver = (state || {}).apiserver;
  const roomState = (getState() || {}).room;
  const room = (roomState || {}).roomObject;

  if (!apiserver) {
    logger.error('no apiserver found');
    return dispatch(localStreamError('no apiserver found'));
  }
  if (!room) {
    logger.error('no room found');
    return dispatch(localStreamError('no room found'));
  }

  if (streamOptions.acquireVideo) {
    dispatch(streamLocalVideoPublished('PUBLISHING'));
  }

  return room.republishStream(getState(), streamOptions).then(
    (stream: Stream) => {
      if (!VideoRoom.getVideoTrackFromStream(stream)) {
        dispatch(streamLocalVideoPublished('IDLE'));
      }
      return dispatch(streamLocalVideoAdded(stream));
    }
  ).catch(
    (err: Error) => {
      reportError(room.getConferenceID(), state.uid, "getUserMedia");
      logger.error('error getUserMedia', err);
      return dispatch(localStreamError(err.name));
    }
  );
}


function _changeVideoQuality(
  dispatch: Dispatch<Action>,
  getState: () => State,
  options: VideoQualityOptions,
  logger: ReturnType<typeof getLogger>
) {
  const state = (getState() || {}).websocket;
  const apiserver = (state || {}).apiserver;
  const roomState = (getState() || {}).room;
  const room = (roomState || {}).roomObject;

  if (!apiserver) {
    logger.error('no apiserver found');
    return;
  }
  if (!room) {
    logger.error('no room found');
    return;
  }

  return room.changeVideoQuality(options);
}


function getScreenStream(
  options: ScreenSharingOptions,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  if (!room) {
    return dispatch(localScreenError("No room object found"));
  }

  dispatch(requestScreen());

  const onstop = () => {
    dispatch(localScreenRemoved());
  };

  room.shareScreen(onstop, options).then(
    (stream: ScreenStream) => {
      return dispatch(localScreenAdded(stream));
    }
  ).catch(
    (err: Error) => {
      return dispatch(localScreenError(err.name));
    }
  );
}


function _tearDownRoom(dispatch: Dispatch<Action>, getState: () => State) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  // eslint-disable-next-line @typescript-eslint/camelcase
  const audio_room = (state || {}).roomAudio;
  const chatRoom = (state || {}).chatRoom;
  const uploadFileRequest = (state || {}).uploadFileRequest;
  if (room) {
    room.tearDown();
  }
  // eslint-disable-next-line @typescript-eslint/camelcase
  if (audio_room) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    audio_room.tearDown();
  }
  if (chatRoom) {
    chatRoom.tearDown();
  }
  if (uploadFileRequest) {
    abortFetch(uploadFileRequest);
  }
  dispatch(roomReset());
  dispatch(inviteParticipantsCloseDialog());
  dispatch(setLockedJoinRequestDialogMinimized(false));
  dispatch(clearLockedJoinRequests());
  dispatch(setCanJoinRoom(false));
}


function requestSubscribeToVideo(
  user: string,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  if (!room) {
    return;
  }
  const uid = user.replace(/_screen$/, '');
  const userData = state.roster[uid];
  const key = user.endsWith('_screen') ? 'screenPublisherData' : 'streamPublisherData';
  const type = user.endsWith('_screen') ? 'screen' : 'stream';
  if (userData) {
    const publisher = userData[key];
    if (publisher && !publisher.subscribed && !publisher.subscribeRequested) {
      dispatch(subscribeToVideoRequested(uid, type));
      room.subscribeToVideo(publisher).catch(() => dispatch(subscribeToVideoError(uid, type)));
    }
  }
}


function requestSubscribeToAllVideos(
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  if (!room) {
    return;
  }
  const roster = state.roster;
  Object.keys(roster).forEach(uid => {
    requestSubscribeToVideo(uid, dispatch, getState);
    requestSubscribeToVideo(`${uid}_screen`, dispatch, getState);
  });
}


function _shouldToggleVideoMute(user: string, state: State, mute: boolean) {
  const rosterUser = state.roster[user.replace(/_screen$/, '')] as RosterUser;
  if (user.includes('_screen')) {
    return rosterUser.isScreenMuted !== mute
      && !rosterUser.isRequestingScreenMute
      && rosterUser.screenPublisherData
      && rosterUser.screenPublisherData.subscribed;
  } else {
    return rosterUser.isVideoMuted !== mute
      && !rosterUser.isRequestingVideoMute
      && rosterUser.streamPublisherData
      && rosterUser.streamPublisherData.subscribed;
  }
}

function requestToggleVideoMute(
  user: string,
  muted: boolean,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  if (!room || !_shouldToggleVideoMute(user, state, muted)) {
    return;
  }
  const type = user.includes('_screen') ? 'screen' : 'stream';
  const userToDispatchTo = user.replace(/_screen$/, '');
  dispatch(videoMuteToggleRequested(userToDispatchTo, type));
  room.toggleVideoMute(user, muted).catch(() => dispatch(videoMuteToggleError(user, type)));
}


function requestToggleAudioMute(
  user: string,
  muted: boolean,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomAudio;
  if (!room) {
    return;
  }
  dispatch(audioMuteToggleRequested(user));
  room.toggleAudioMute(user, muted).catch(() => dispatch(audioMuteToggleError(user)));
}

function requestTogglePrivateAudio(
  user: string,
  privateAudio: string | null,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomAudio;
  if (!room) {
    return;
  }
  dispatch(audioPrivateToggleRequested(user));
  room.togglePrivateAudio(user, privateAudio).catch(() => {
    dispatch(audioPrivateToggleError(user));
    const startOrStop = privateAudio ? 'stopPrivateAudioRequestFailed' : 'startPrivateAudioRequestFailed';
    newEvent(ERROR, startOrStop, startOrStop, startOrStop, {});
  });
}

function requestMuteAll(dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomAudio;
  const wsState = (state || {}).websocket;
  const user = (wsState || {}).uid;
  if (!(room && user)) {
    return;
  }
  dispatch(audioMuteAllRequested(user));
  room.muteAll().finally(() => dispatch(audioMuteAllDone(user)));
}


function requestUnMuteAll(dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomAudio;
  const wsState = (state || {}).websocket;
  const user = (wsState || {}).uid;
  if (!(room && user)) {
    return;
  }
  dispatch(audioMuteAllRequested(user));
  room.unMuteAll().finally(() => dispatch(audioMuteAllDone(user)));
}


function requestToggleOwnAudioMute(muted: boolean, dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomAudio;
  const wsState = (state || {}).websocket;
  const user = (wsState || {}).uid;
  if (!(room && user)) {
    return;
  }
  dispatch(audioMuteToggleRequested(user));
  room.toggleOwnAudioMute(muted).catch(() => dispatch(audioMuteToggleError(user)));
}


function requestPublishVideo(published: boolean, dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  const wsState = (state || {}).websocket;
  const user = (wsState || {}).uid;
  if (!(room && user)) {
    return;
  }
  dispatch(videoMuteToggleRequested(user, 'stream'));
  if (published) {
    room.unpublishVideoStream();
  } else {
    dispatch(streamLocalVideoPublished('PUBLISHING'));
    const roomOptionsState = state.appconfig.room_options;
    const roomOptions = {
      acquireVideo: true,
      muted: false,
      frameRate: roomOptionsState.frame_rate,
      streamQuality: roomOptionsState.stream_quality,
    };
    _republishStream(dispatch, getState, roomOptions, getLogger(`Republish stream`));
  }
}


function requestToggleRaiseHand(dispatch: Dispatch<Action>, getState: () => State) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  const websocketState = (getState() || {}).websocket;
  const userId = (websocketState || {}).uid;
  if (!room || !userId) {
    return;
  }
  dispatch(raiseHandToggleRequested(userId));
  room.toggleRaiseHand()
    .then(() => dispatch(raiseHandSuccess(userId)))
    .catch(() => dispatch(raiseHandToggleError(userId)));
}


function requestLowerRaisedHand(userId: string, dispatch: Dispatch<Action>, getState: () => State) {
  const state = (getState() || {}).room;
  const room = (state || {}).roomObject;
  if (!room) {
    return;
  }

  // reuse toggle own hand actions to avoid cluttering the redux state
  // it should not be a problem, since the toggle own hand and lower someobody
  // else's raised hand are orthogonal
  dispatch(raiseHandToggleRequested(userId));
  room.lowerRaisedHand(userId)
    .then(() => dispatch(raiseHandSuccess(userId)))
    .catch(() => dispatch(raiseHandToggleError(userId)));
}


function requestUserKick(user: string, dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room && user)) {
    return;
  }
  dispatch(kickUserRequested(user));
  room.kickUser(user)
    .then(() => dispatch(kickUserSuccess(user)))
    .catch(() => dispatch(kickUserError(user)));
}


function requestExpandStreamVideo(
  user: string,
  streamType: RoomLayoutStreamType,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room && user)) {
    return;
  }
  dispatch(expandStreamVideoRequested(user, streamType));
}


function requestEnlargeStreamVideo(
  enlarge: boolean,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room)) {
    return;
  }
  dispatch(enlargeStreamVideoRequested(enlarge));
}


function requestInviteParticipants(
  emails: string[],
  params: Params,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room && emails)) {
    return;
  }
  dispatch(inviteParticipantsRequested());
  room.inviteParticipants(emails, params)
    .then(() => dispatch(inviteParticipantsSuccess()))
    .catch((err: RoomError) => {
      newEvent(ERROR, err.code, err.reason, err.message);
      dispatch(inviteParticipantsError(err.code));
    });
}


function requestDialParticipant(
  destination: string,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room && destination)) {
    return;
  }
  dispatch(dialParticipantRequested());
  room.dialParticipant(destination)
    .then(() => {
      newEvent(INFO, 'dialParticipantSuccess', 'dialParticipantSuccess',
        'Call started successfully.');
      dispatch(dialParticipantSuccess());
    })
    .catch((err: RoomError) => {
      newEvent(ERROR, err.code, err.reason, err.message);
      dispatch(dialParticipantError(err.code));
    });
}


function requestUserRoleChange(
  user: string,
  role: string,
  dispatch: Dispatch<Action>,
  getState: () => State
) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room && user)) {
    return;
  }
  dispatch(changeUserRoleRequested(user));
  room.changeUserRole(user, role)
    .then(() => dispatch(changeUserRoleSuccess(user)))
    .catch(() => dispatch(changeUserRoleError(user)));
}


function requestApplyLayout(dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room)) {
    return;
  }
  const layout = state.room.layout;
  const layoutConfig = state.room.layoutConfig;
  dispatch(applyLayoutRequested());
  room.applyLayout(layout, layoutConfig)
    .then(() => dispatch(applyLayoutSuccess()))
    .catch(() => dispatch(applyLayoutError()));
}


function requestLockRoom(dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room)) {
    return;
  }
  dispatch(lockRoomRequested());
  room.toggleRoomLock()
    .then((res: { locked: boolean }) => dispatch(lockRoomStatus(res.locked)))
    .catch(() => dispatch(lockRoomError()));
}


function requestStartRecording(params: RecordingOptions, dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room)) {
    return;
  }
  dispatch(startRecordingRequested());
  room.startRecording(params)
    .catch((err: RoomError) => {
      newEvent(ERROR, err.code, err.reason, err.message);
      dispatch(startRecordingError());
    });
}


function requestStopRecording(type: RecordingType, dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room)) {
    return;
  }
  dispatch(stopRecordingRequested());
  room.stopRecording(type)
    .then(() => dispatch(stopRecordingSuccess(type)))
    .catch((err: RoomError) => {
      newEvent(ERROR, err.code, err.reason, err.message);
      dispatch(stopRecordingError());
    });
}


function requestStartLivestreaming(params: Params, dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room)) {
    return;
  }
  dispatch(startLivestreamingRequested());
  room.startLivestreaming(params)
    .catch((err: RoomError) => {
      newEvent(ERROR, err.code, err.reason, err.message);
      dispatch(startLivestreamingError());
    });
}


function requestStopLivestreaming(dispatch: Dispatch<Action>, getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room)) {
    return;
  }
  dispatch(stopLivestreamingRequested());
  room.stopLivestreaming()
    .then(() => dispatch(stopLivestreamingSuccess()))
    .catch((err: RoomError) => {
      newEvent(ERROR, err.code, err.reason, err.message);
      dispatch(stopLivestreamingError());
    });
}


export function createVideoRoom(
  room: string,
  // FIXME
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rtcProvider: any,
  logger: ReturnType<typeof getLogger>
): RoomThunkAction {
  return (dispatch, getState) => {
    return createRoom(room, rtcProvider, logger, dispatch, getState);
  };
}


export function addStream(
  room: string,
  streamOptions: StreamOptions,
  logger: ReturnType<typeof getLogger>
): RoomThunkAction {
  return (dispatch, getState) => {
    return getStream(dispatch, getState, room, streamOptions, logger);
  };
}


export function tearDownRoom(): RoomThunkAction {
  return (dispatch, getState) => {
    return _tearDownRoom(dispatch, getState);
  };
}


export function republishStream(
  streamOptions: StreamOptions,
  logger: ReturnType<typeof getLogger>
): RoomThunkAction {
  return (dispatch, getState) => {
    return _republishStream(dispatch, getState, streamOptions, logger);
  };
}


export function changeVideoQuality(
  options: VideoQualityOptions,
  logger: ReturnType<typeof getLogger>
): RoomThunkAction {
  return (dispatch, getState) => {
    return _changeVideoQuality(dispatch, getState, options, logger);
  };
}


export function shareScreen(options: ScreenSharingOptions = {}): RoomThunkAction {
  return (dispatch, getState) => {
    return getScreenStream(options, dispatch, getState);
  };
}


export function stopScreenSharing(): RoomThunkAction {
  return (dispatch, getState) => {
    return releaseScreen(dispatch, getState);
  };
}


export function subscribeToVideo(user: string): RoomThunkAction {
  return (dispatch, getState) => {
    return requestSubscribeToVideo(user, dispatch, getState);
  };
}


export function subscribeToAllVideos(): RoomThunkAction {
  return (dispatch, getState) => {
    return requestSubscribeToAllVideos(dispatch, getState);
  };
}


export function toggleVideoMute(user: string, muted: boolean): RoomThunkAction {
  return (dispatch, getState) => {
    return requestToggleVideoMute(user, muted, dispatch, getState);
  };
}

export function toggleAllVideoMute(muted: boolean, filter?: (s: string) => boolean): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;
    if (!room) {
      return;
    }
    const roster = roomState.roster;

    const websocketState = (state || {}).websocket;
    const myUid = websocketState.uid;

    Object.keys(roster).forEach(
      (uid) => {
        if (uid !== myUid) {
          if (filter) {
            if (filter(uid)) {
              requestToggleVideoMute(uid, muted, dispatch, getState);
              requestToggleVideoMute(`${uid}_screen`, muted, dispatch, getState);
            }
          }
          else {
            requestToggleVideoMute(uid, muted, dispatch, getState);
            requestToggleVideoMute(`${uid}_screen`, muted, dispatch, getState);
          }
        }
      }
    );
  };
}

function requestEnableDesktopControl(
  user: string,
  desktopControlEnabled: boolean,
  desktopControlType: string,
  dispatch: Dispatch<Action>,
  getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room && user)) {
    return;
  }
  dispatch(requestDesktopControl(user));
  desktopControlEnabled ? room.startDeskControl(user, desktopControlType) : room.stopDeskControl(user);
}

export function enableDesktopControl(
  user: string,
  desktopControlEnabled: boolean,
  desktopControlType: string
): RoomThunkAction {
  return (dispatch, getState) => {
    return requestEnableDesktopControl(user, desktopControlEnabled, desktopControlType, dispatch, getState);
  };
}

export function toggleAudioMute(user: string, muted: boolean): RoomThunkAction {
  return (dispatch, getState) => {
    return requestToggleAudioMute(user, muted, dispatch, getState);
  };
}

export function togglePrivateAudio(user: string, privateAudio: string | null): RoomThunkAction {
  return (dispatch, getState) => {
    return requestTogglePrivateAudio(user, privateAudio, dispatch, getState);
  };
}

export function toggleOwnAudioMute(muted: boolean): RoomThunkAction {
  return (dispatch, getState) => {
    return requestToggleOwnAudioMute(muted, dispatch, getState);
  };
}


export function muteAll(): RoomThunkAction {
  return (dispatch, getState) => {
    return requestMuteAll(dispatch, getState);
  };
}


export function unMuteAll(): RoomThunkAction {
  return (dispatch, getState) => {
    return requestUnMuteAll(dispatch, getState);
  };
}


export function togglePublishVideo(published: boolean): RoomThunkAction {
  return (dispatch, getState) => {
    dispatch(saveVideoEnabled(!published, new LocalStorage()));
    return requestPublishVideo(published, dispatch, getState);
  };
}


export function kickUser(user: string): RoomThunkAction {
  return (dispatch, getState) => {
    return requestUserKick(user, dispatch, getState);
  };
}

function requestSendMouseEvent(
  userId: string,
  displayName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mouseEventType: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mouseEventData: any,
  dispatch: Dispatch,
  getState: () => State) {
  const state = getState();
  const roomState = (state || {}).room;
  const room = (roomState || {}).roomObject;
  if (!(room && userId)) {
    return;
  }

  room.mouseEvent(userId, displayName, mouseEventType, mouseEventData)
    .catch((err: Error) => {
      const logger = getLogger('MouseEvent');
      logger.error("error is", err);
    });
}

export function sendMouseEvent(
  userId: string,
  displayName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mouseEventType: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mouseEventData: any
): RoomThunkAction {
  return (dispatch, getState) => {
    return requestSendMouseEvent(userId, displayName, mouseEventType, mouseEventData, dispatch, getState);
  };
}


export function startControllingDesktop(): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;
    if (!room) {
      return;
    }

    room.startControllingDesktop()
      .catch((err: Error) => {
        const logger = getLogger('Desktop Control');
        logger.error("error is", err);
      });
  };
}


export function stopControllingDesktop(): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;
    if (!room) {
      return;
    }

    room.stopControllingDesktop()
      .catch((err: Error) => {
        const logger = getLogger('Desktop Control');
        logger.error("error is", err);
      });
  };
}


export function startDrawing(): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;

    if (!room) {
      return;
    }

    room.startDrawing()
      .catch((err: Error) => {
        const logger = getLogger('Drawing');
        logger.error("error is", err);
      });
  };
}


export function stopDrawing(): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;
    if (!room) {
      return;
    }

    room.stopDrawing()
      .catch((err: Error) => {
        const logger = getLogger('Drawing');
        logger.error("error is", err);
      });
  };
}


export function enlargeStreamVideo(enlarge: boolean): RoomThunkAction {
  return (dispatch, getState) => {
    return requestEnlargeStreamVideo(enlarge, dispatch, getState);
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
export function expandStreamVideo(user: string, stream_type: RoomLayoutStreamType): RoomThunkAction {
  return (dispatch, getState) => {
    return requestExpandStreamVideo(user, stream_type, dispatch, getState);
  };
}


export function addObservedScreen(uid: string): AddObservedScreenAction {
  return {
    type: ADD_OBSERVED_SCREEN,
    payload: {
      userId: uid,
    }
  };
}


export function removeObservedScreen(uid: string): RemoveObservedScreenAction {
  return {
    type: REMOVE_OBSERVED_SCREEN,
    payload: {
      userId: uid,
    }
  };
}


export function inviteParticipants(emails: string[], params: Params): RoomThunkAction {
  return (dispatch, getState) => {
    return requestInviteParticipants(emails, params, dispatch, getState);
  };
}


export function togglePip(pipUser: string | null): TogglePipUserAction {
  return {
    type: PIP_USER,
    payload: {
      pipUser: pipUser,
    }
  };
}



export function toggleFullscreen(fullscreenUser: string | null): ToggleFullscreenUserAction {
  return {
    type: FULLSCREEN_USER,
    payload: {
      fullscreenUser: fullscreenUser,
    }
  };
}


export function dialParticipant(destination: string): RoomThunkAction {
  return (dispatch, getState) => {
    return requestDialParticipant(destination, dispatch, getState);
  };
}

export function changeRole(user: string, role: string): RoomThunkAction {
  return (dispatch, getState) => {
    return requestUserRoleChange(user, role, dispatch, getState);
  };
}


export function applyLayout(): RoomThunkAction {
  return (dispatch, getState) => {
    return requestApplyLayout(dispatch, getState);
  };
}


export function lockRoom(): RoomThunkAction {
  return (dispatch, getState) => {
    return requestLockRoom(dispatch, getState);
  };
}


export function startRecording(params: RecordingOptions): RoomThunkAction {
  return (dispatch, getState) => {
    return requestStartRecording(params, dispatch, getState);
  };
}


export function stopRecording(type: RecordingType = 'video'): RoomThunkAction {
  return (dispatch, getState) => {
    return requestStopRecording(type, dispatch, getState);
  };
}


export function startLivestreaming(params: Params): RoomThunkAction {
  return (dispatch, getState) => {
    return requestStartLivestreaming(params, dispatch, getState);
  };
}


export function stopLivestreaming(): RoomThunkAction {
  return (dispatch, getState) => {
    return requestStopLivestreaming(dispatch, getState);
  };
}


export function sendChatMessage(message: string, to?: string): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).chatRoom;
    if (!room) {
      return;
    }
    room.publishChatMessage(message, to)
      .catch((err: ChatMessageError) => {
        if (err.error === ChatMessageErrorType.UserOffline) {
          newEvent(WARNING, 'userOffline', err.error, err.error);
        } else {
          newEvent(WARNING, 'userInvalid', err.error, err.error);
        }
      });
  };
}

export function uploadFileRequest(request: string | null): UploadFileRequestAction {
  return {
    type: UPLOAD_FILE_REQUEST,
    payload: {
      request: request
    }
  };
}

export function sendChatFile(file: File, message: string, to?: string): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const roomObject = (roomState || {}).roomObject;
    const room = (roomObject || {}).name;
    const chatRoom = (roomState || {}).chatRoom;
    const auth = (state || {}).auth;
    const token = (auth || {}).token;
    if (!chatRoom) {
      return;
    }

    const { response, uuid } = chatRoom.publishChatFile(token, room, file, message, to);
    dispatch(uploadFileRequest(uuid));

    response.then((response: Response) => {
      if (response.status === 413) {
        newEvent(WARNING, 'sharedFileUploadErrorTooLarge', "sharedFileUploadErrorTooLarge");
      } else if (response.status === 415) {
        newEvent(WARNING, 'sharedFileUploadErrorBadType', "sharedFileUploadErrorBadType");
      } else if (!response.ok) {
        newEvent(WARNING, 'sharedFileUploadError', "sharedFileUploadError");
      }
      dispatch(uploadFileRequest(null));
      Promise.resolve(response);
    })
      .catch((abort: DOMException) => {
        if (abort.name === 'AbortError') {
          newEvent(INFO, 'sharedFileUploadCancelled', "sharedFileUploadCancelled");
        } else {
          newEvent(WARNING, 'sharedFileUploadError', "sharedFileUploadError");
        }
        dispatch(uploadFileRequest(null));
      });
  };
}

// eslint-disable-next-line @typescript-eslint/camelcase
export function changeLayout(layout_type: RoomLayout, layout_config: RoomLayoutConfig): ChangeLayoutAction {
  return {
    type: CHANGE_LAYOUT,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      layout_type: layout_type,
      // eslint-disable-next-line @typescript-eslint/camelcase
      layout_config: layout_config
    }
  };
}


// eslint-disable-next-line @typescript-eslint/camelcase
export function forceLayout(layout_type: RoomLayout, layout_config: RoomLayoutConfig): ForceLayoutAction {
  return {
    type: FORCE_LAYOUT,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      layout_type: layout_type,
      // eslint-disable-next-line @typescript-eslint/camelcase
      layout_config: layout_config
    }
  };
}
// export function setMousePointer(mousePointer) {
//   return {
//     type: SET_MOUSE_POINTER,
//     payload: {
//       mousePointer
//     }
//   };
// }
// export function clearMousePointers() {
//   return {
//     type: CLEAR_MOUSE_POINTERS,
//     payload: {
//     }
//   };
// }


export function setSelectedPane(pane: PaneType): SetSelectedPaneAction {
  return {
    type: SET_SELECTED_PANE,
    payload: {
      selectedPane: pane
    }
  };
}


export function resetUnreadPublicUserMessages(): PublicUserMessagesResetAction {
  return {
    type: PUBLIC_USERMESSAGE_RESET_UNREAD,
    payload: {}
  };
}


export function resetUnreadPrivateUserMessages(): PrivateUserMessagesResetAction {
  return {
    type: PRIVATE_USERMESSAGE_RESET_UNREAD,
    payload: {}
  };
}


export function resetUnreadPrivateUserMessageThread(user: string): PrivateUserMessagesResetThreadAction {
  return {
    type: PRIVATE_USERMESSAGE_RESET_UNREAD_THREAD,
    payload: {
      thread: user,
    }
  };
}


export function roomErrorAcked(): RoomErrorAckedAction {
  return {
    type: ROOM_ERROR_ACKED,
    payload: {
    }
  };
}


export function endMeeting(): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;
    if (!room) {
      return;
    }
    room.endMeeting();
  };
}


export function setRoomOwnership(roomRoles: string[], ownerIsAudioOnly: boolean): SetRoomOwnershipAction {
  return {
    type: SET_ROOM_OWNERSHIP,
    payload: {
      isOwner: roomRoles.includes('room_owner'),
      ownerIsAudioOnly: ownerIsAudioOnly,
    }
  };
}


export function setMyRole(role: string): SetMyRoleAction {
  return {
    type: SET_MY_ROLE,
    payload: {
      role: role,
    }
  };
}


export function setMediaPermissions(video: boolean, audio: boolean): SetMediaPermissionsAction {
  return {
    type: SET_MEDIA_PERMISSIONS,
    payload: {
      audio,
      video,
    }
  };
}

export function setJoiningRoom(isJoining: boolean): SetJoiningRoomAction {
  return {
    type: SET_JOINING_ROOM,
    payload: {
      isJoining: isJoining
    }
  };
}


export function toggleRaiseHand(): RoomThunkAction {
  return (dispatch, getState) => {
    return requestToggleRaiseHand(dispatch, getState);
  };
}

export function lowerRaisedHand(user: string): RoomThunkAction {
  return (dispatch, getState) => {
    return requestLowerRaisedHand(user, dispatch, getState);
  };
}

export function acceptLockedJoinRequest({ username, reqId }: { username: string; reqId: string }): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;
    if (!room) {
      return;
    }
    room.acceptLockedJoinRequest(username, reqId);
  };
}

export function denyLockedJoinRequest({ username, reqId }: { username: string; reqId: string }): RoomThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const roomState = (state || {}).room;
    const room = (roomState || {}).roomObject;
    if (!room) {
      return;
    }
    room.denyLockedJoinRequest(username, reqId);
  };
}
