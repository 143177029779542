import { createStyles, Theme } from '@material-ui/core/styles';


const style = (theme: Theme) => createStyles({

  emptyContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(5),
    width: '100%',
    height: '33vh',
  },

  emptyMessage: {
    margin: theme.spacing(5)
  }
});

export default style;
