/* tslint:disable */
/* eslint-disable */
/* @relayHash cc034868de69fb8f2cdf77d3ad7b07fd */

import { ConcreteRequest } from "relay-runtime";
export type PushSubscriptionLocale = "EN" | "IT" | "%future added value";
export type PushSubscriptionType = "SAFARI" | "WEB_PUSH" | "%future added value";
export type StoreSubscriptionInput = {
    subscription?: PushSubscriptionInput | null;
};
export type PushSubscriptionInput = {
    locale: PushSubscriptionLocale;
    subId: string;
    type: PushSubscriptionType;
    webPushExtra?: WebPushExtraInput | null;
};
export type WebPushExtraInput = {
    endpoint: string;
    expirationTime?: string | null;
    keys: WebPushExtraKeysInput;
};
export type WebPushExtraKeysInput = {
    auth: string;
    p256dh: string;
};
export type storeSubscriptionVariables = {
    input: StoreSubscriptionInput;
};
export type storeSubscriptionResponse = {
    readonly storeSubscription: {
        readonly subscription: {
            readonly subId: string;
            readonly type: PushSubscriptionType;
            readonly locale: PushSubscriptionLocale;
            readonly webPushExtra: {
                readonly keys: {
                    readonly auth: string;
                    readonly p256dh: string;
                };
                readonly endpoint: string;
                readonly expirationTime: string | null;
            } | null;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly key: string;
            readonly message: ReadonlyArray<string | null> | null;
            readonly reason: ReadonlyArray<string | null> | null;
        } | null> | null;
    } | null;
};
export type storeSubscription = {
    readonly response: storeSubscriptionResponse;
    readonly variables: storeSubscriptionVariables;
};



/*
mutation storeSubscription(
  $input: StoreSubscriptionInput!
) {
  storeSubscription(input: $input) {
    subscription {
      subId
      type
      locale
      webPushExtra {
        keys {
          auth
          p256dh
        }
        endpoint
        expirationTime
      }
    }
    errors {
      key
      message
      reason
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "StoreSubscriptionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "storeSubscription",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StoreSubscriptionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "subscription",
        "storageKey": null,
        "args": null,
        "concreteType": "PushSubscription",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "subId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "locale",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "webPushExtra",
            "storageKey": null,
            "args": null,
            "concreteType": "WebPushExtra",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "keys",
                "storageKey": null,
                "args": null,
                "concreteType": "WebPushExtraKeys",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "auth",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "p256dh",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endpoint",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "expirationTime",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "InputError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reason",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "storeSubscription",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "storeSubscription",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "storeSubscription",
    "id": null,
    "text": "mutation storeSubscription(\n  $input: StoreSubscriptionInput!\n) {\n  storeSubscription(input: $input) {\n    subscription {\n      subId\n      type\n      locale\n      webPushExtra {\n        keys {\n          auth\n          p256dh\n        }\n        endpoint\n        expirationTime\n      }\n    }\n    errors {\n      key\n      message\n      reason\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '7d83783165ea97efd026aaa1dd32f35e';
export default node;
