import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { iconColors as colors } from '../../colors';
import { shareScreen, stopScreenSharing } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { ScreenSharingOptions } from '../../lib/redux_types';
import { IconShareScreen, IconUnshare } from '../IconSet';

import messages from './buttonsMessages';
import ToolbarButton from './ToolbarButton';

import useStyles from './buttonsStyle';


function ShareScreen({ isSharingScreen, isRequestingScreen, constraints }: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const shareOrUnshare = React.useCallback(
    () => {
      if (isSharingScreen) {
        dispatch(stopScreenSharing());
        logEvent(Event.SCREEN_SHARE, { 'action': 'unshare' });
      }
      else {
        if (constraints) {
          dispatch(shareScreen(constraints));
        }
        else {
          dispatch(shareScreen());
        }
        logEvent(Event.SCREEN_SHARE, { 'action': 'share' });
      }
    }
    , [dispatch, isSharingScreen, constraints]
  );
  const label = isSharingScreen ? formatMessage(messages.unShareScreen) : formatMessage(messages.shareScreen);
  const selected = isSharingScreen ? classes.buttonsSelected : null;
  const cls = classNames(classes.buttons, selected);

  return (
    <ToolbarButton
      icon={isSharingScreen ? <IconUnshare size={28} color={colors.contrast} /> : <IconShareScreen size={28} />}
      text={label}
      buttonProps={isRequestingScreen ? { className: cls } : { className: cls, onClick: shareOrUnshare }}


    />
  );
}


type MappedProps = {
  isRequestingScreen: boolean;
  isSharingScreen: boolean;
}


type ExtendedProps = {
  constraints?: ScreenSharingOptions;
} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  return {
    isSharingScreen: Boolean(state.room.screenStream),
    isRequestingScreen: state.room.isRequestingScreen,
  };
};


export default connect(mapStateToProps)(ShareScreen);
