import { browserName, deviceType, osName } from 'react-device-detect';

import 'firebase/analytics';
import firebase from 'firebase/app';

import { UserProperties } from '.';


/**
 * Retrives the Firebase config for Holocom based on the NODE_ENV.
 * This allows to filter out debug events from the Firebase Console
 */
const getFirebaseConfig = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      apiKey: "AIzaSyDu4Xpxj4eYd9qfJzjaEAIKbZ7GtTO-XUY",
      authDomain: "voismart-holocom.firebaseapp.com",
      projectId: "voismart-holocom",
      appId: "1:536844057154:web:e5b8394c27818de6248bdb",
      measurementId: "G-VGRXFEJ6SR"
    };
  } else {
    return {
      apiKey: "AIzaSyDu4Xpxj4eYd9qfJzjaEAIKbZ7GtTO-XUY",
      authDomain: "voismart-holocom.firebaseapp.com",
      projectId: "voismart-holocom",
      appId: "1:536844057154:web:8ab7cb5ec8e2ee9b248bdb",
      measurementId: "G-18C0KP29WK"
    };
  }
};

/**
 * Initializes the Firebase Analitics service
 */
export function initFirebaseAnalytics() {
  firebase.initializeApp(getFirebaseConfig());
  firebase.analytics().setUserProperties({
    [UserProperties.BROWSER]: browserName,
    [UserProperties.DEVICE]: deviceType !== 'browser' ? deviceType : 'desktop',
    [UserProperties.OS]: osName,
    [UserProperties.REALM]: window.location.hostname,
  });
}


/**
 * Logs an event to the firebase analytics console
 *
 * The full list of events can be found in the holocom client wiki. But more can be added.
 *
 * The list of custom attributes, must be explicitly defined in the firebase console,
 * and those already defined can be found in the aforementioned wiki and in the console under "Custom Definitions"
 *
 * @param eventName The name of the event.
 * @param eventParams Custom dimension/metric associated with the event. It is just an optional object with kv pairs.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logEvent(eventName: string, eventParams?: { [key: string]: any } | undefined) {
  // Should we retrieve the analytics service once and store it in a singleton instead of doing it everytime?
  firebase.analytics().logEvent(eventName, eventParams);
}
