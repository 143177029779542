import React  from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { useRelayEnvironment } from 'relay-hooks';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { regeneratePins } from '../../lib/actions/user';
import { logEvent, Event } from '../../lib/analytics';
import { State as SessionState } from '../../lib/reducers/session';
import ClosableDialog from '../ClosableDialog';
import { IconLocked, IconKey } from '../IconSet';


const messages = defineMessages({
  title: { id: 'regenPinsTabCmpTitle' },
  regenButton: { id: 'regenPinsTabCmpRegen' },
  publicPin: { id: 'regenPinsTabCmpPubPin' },
  personalPin: { id: 'regenPinsTabCmpPrivPin' },
});


type MappedProps = {
  roomNumber: string;
  roomOwnerNumber: string;
}


type Props = {
  onClose: () => void;
};


type ExtendedProps = Props & MappedProps;


function UserPins(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const relayEnvironment = useRelayEnvironment();
  const dispatch = useDispatch();

  const getPrivPinText = () => {
    const label = formatMessage(messages.personalPin);
    const pin = props.roomOwnerNumber;
    return `${ label }: ${ pin }`;
  };

  const getPubPinText = () => {
    const label = formatMessage(messages.publicPin);
    const pin = props.roomNumber;
    return `${ label }: ${ pin }`;
  };

  const onRegenClick = React.useCallback(
    () => {
      dispatch(regeneratePins(relayEnvironment));
      logEvent(Event.RENEW_MY_PINS);
    }, [relayEnvironment, dispatch]
  );

  return (
    <ClosableDialog
      open={true}
      onClose={props.onClose}
      title={formatMessage(messages.title)}
    >
      <DialogContent>
        <List>
          <ListItem>
            <ListItemIcon>
              <IconKey size={32} />
            </ListItemIcon>
            <ListItemText primary={getPubPinText()}>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <IconLocked size={32} />
            </ListItemIcon>
            <ListItemText primary={getPrivPinText()} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRegenClick} variant="contained" color="primary">
          {formatMessage(messages.regenButton)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


type State = {
  session: SessionState;
}


const mapStateToProps = (state: State): MappedProps => ({
  roomNumber: state.session.roomNumber || "",
  roomOwnerNumber: state.session.roomOwnerNumber || "",
});


export default connect(mapStateToProps)(UserPins);
