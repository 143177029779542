import { flipTextureOnSafari, isBlurAvailable, findBlurValue, BlurValue } from '../../utils/pipelineDefault';

import { default as StreamPipeline } from './StreamPipeline';
import * as t from "./transformations";


export type PipelineOptions = {
  video?: boolean;
  frameRate?: number;
  beautifyEffectEnabled?: boolean;
  backgroundBlurEnabled?: boolean;
  backgroundBlurValue?: BlurValue['value'];
};
export type PipelineName = "video_room" | "av_settings" | "unknown_source" ;
export type PipelineMap = {
  pipeline: StreamPipeline;
  video: number;
  audio: number;
};

const { startPipeline, stopPipelineShared, stopPipelineVideo, stopPipelineAudio, getPipeline } = (
  () => {
    const pipelines = new Map<PipelineName, PipelineMap>();

    const getPipelineMap = (name: PipelineName): PipelineMap  => {
      const pipelineMap = pipelines.get(name);
      if (pipelineMap) return pipelineMap;
      else return {
        pipeline: new StreamPipeline(),
        audio: 0,
        video: 0
      };
    };

    const setupPipeline = (
      pipeline: StreamPipeline,
      getStream: () => Promise<MediaStream>,
      options: PipelineOptions
    ): void => {
      if (options.frameRate) pipeline.setFrameRate(options.frameRate);
      if (!isBlurAvailable() || !options.video) {
        pipeline.setup(getStream);
        return;
      }
      const backgroundBlurEnabled = Boolean(options.backgroundBlurEnabled);
      const backgroundBlurValue = findBlurValue(options.backgroundBlurValue);
      if (backgroundBlurEnabled) {
        const background = new t.MaskingStreamTransformation(backgroundBlurValue);
        const foreground = options.beautifyEffectEnabled ?
          new t.BilateralStreamTransformation(flipTextureOnSafari()) :
          new t.NopStreamTransformation();
        const transformation = new t.BackgroundStreamTransformation(background, foreground);
        pipeline.setup(getStream, transformation);
      } else if (options.beautifyEffectEnabled) {
        pipeline.setup(getStream, new t.BilateralStreamTransformation());
      } else {
        pipeline.setup(getStream);
      }
    };

    return ({
      startPipeline: (
        name: PipelineName,
        getStream: () => Promise<MediaStream>,
        options: PipelineOptions
      ): Promise<MediaStream> => {
        const { pipeline, video, audio } = getPipelineMap(name);
        if (video === 0 && audio !== 0)
          throw new Error('attempting to restart pipeline without stopping audio');
        if (video === 0)
          setupPipeline(pipeline, getStream, options);
        pipelines.set(name, {
          pipeline: pipeline,
          video: video + 1,
          audio: audio + 1,
        });
        return pipeline.start();
      },

      stopPipelineShared: (name: PipelineName): void => {
        const { pipeline, video, audio } = getPipelineMap(name);
        if (video === 1) {
          pipeline.stopVideo();
          pipeline.stopAudio();
          pipelines.delete(name);
        } else {
          pipelines.set(name, {
            pipeline: pipeline,
            video: video - 1,
            audio: audio - 1,
          });
        }
      },

      stopPipelineVideo: (name: PipelineName,): void => {
        const { pipeline, audio } = getPipelineMap(name);
        pipeline.stopVideo();
        if (audio <= 0) {
          pipelines.delete(name);
        } else {
          pipelines.set(name, {
            pipeline: pipeline,
            video: 0,
            audio: audio,
          });
        }
      },

      stopPipelineAudio: (name: PipelineName,): void => {
        const { pipeline, video } = getPipelineMap(name);
        pipeline.stopAudio();
        if (video <= 0) {
          pipelines.delete(name);
        } else {
          pipelines.set(name, {
            pipeline: pipeline,
            video: video,
            audio: 0,
          });
        }
      },

      getPipeline: (name: PipelineName): StreamPipeline | null => {
        const pipelineMap = pipelines.get(name);
        if (pipelineMap) return pipelineMap.pipeline;
        return null;
      },
    });
  }
)();

export { startPipeline, stopPipelineShared, stopPipelineVideo, stopPipelineAudio, getPipeline };
