import { commitMutation } from 'react-relay';

import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';

import {
  PushSubscriptionLocale,
  PushSubscriptionType,
  storeSubscription as storeSubscriptionType,
  WebPushExtraInput
} from './__generated__/storeSubscription.graphql';


const mutation = graphql`
mutation storeSubscription($input: StoreSubscriptionInput!){
  storeSubscription(input: $input){
    subscription{
      subId
      type
      locale
      webPushExtra {
        keys {
          auth
          p256dh
        }
        endpoint
        expirationTime
      }
    },
    errors {
      key
      message
      reason
    }
  }
}
`;

function storeSubscription(
  environment: IEnvironment,
  subId: string,
  type: PushSubscriptionType,
  locale: PushSubscriptionLocale,
  webPushExtra: WebPushExtraInput | null,
) {
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        subscription: {
          subId,
          type,
          locale: locale,
          webPushExtra
        }
      }
    };
    commitMutation<storeSubscriptionType>(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          const err = errors || (response.storeSubscription && response.storeSubscription.errors);
          if (err) {
            return reject(err);
          }
          return resolve(response);
        },
        onError: err => {
          reject(err);
        }
      },
    );
  });
}

export { storeSubscription };
