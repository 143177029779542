import React  from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import {
  saveAudioInConfig,
  saveAudioOutConfig,
  saveVideoConfig,
  saveVideoFacingMode,
  saveVideoQuality,
} from '../../lib/actions/settings';
import { logEvent, Event } from '../../lib/analytics';
import { RtcDevices, getVideoQualities } from '../../lib/api/rtcDevices';
import { getLogger } from '../../lib/logger';
import { State } from '../../lib/reducers';
import { FacingMode, VideoQuality, StreamQualityValue } from '../../lib/redux_types';
import LocalStorage from '../../localStorage';
import prepareWebRtcProvider from '../../rtc';
import { IconReload } from '../IconSet';


const messages = defineMessages({
  deviceSelectorAudioInput: { id: 'deviceSelectorAudioInput' },
  deviceSelectorAudioOutput: { id: 'deviceSelectorAudioOutput' },
  deviceSelectorVideoInput: { id: 'deviceSelectorVideoInput' },
  deviceSelectorVideoQuality: { id: 'deviceSelectorVideoQuality' },
  noDeviceAvailable: { id: 'noDeviceAvailable' },
  rescanDevices: { id: 'rescanDevices' },
  frontCamera: { id: 'frontCamera' },
  rearCamera: { id: 'rearCamera' },
});


type Devices = {
  videoinput: MediaDeviceInfo[];
  videofacing: FacingMode[];
  audioinput: MediaDeviceInfo[];
  audiooutput: MediaDeviceInfo[];
  videoquality: string[];
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsContainer: {
      width: '100%',
    },
    settingsElement: {
      margin: theme.spacing(1, 0),
    },
    refreshButton: {
      alignSelf: 'center',
      marginBottom: theme.spacing(1),
    },
    betaChip: {
      color: theme.palette.common.white,
      background: theme.palette.warning.dark,
      fontSize: '0.75em',
      height: '15px',
    }
  })
);


function getRtc() {
  const logger = getLogger('AVSettings');
  const webrtc = prepareWebRtcProvider();
  return new RtcDevices(webrtc, logger);
}


function orEmptyItem(arr: React.ReactNode[], msg: string) {
  if (arr.length > 0) {
    return arr;
  }
  return (
    <MenuItem key={0} value={''}>{msg}</MenuItem>
  );
}


function discoverDevices() {
  const rtc = getRtc();
  return rtc.discoverDevices();
}


function groupDevicesByKind(devs: MediaDeviceInfo[]): Devices {
  const videoIns: Devices['videoinput'] = [];
  const videoInFacings: Devices['videofacing'] = [];
  const audioIns: Devices['audioinput'] = [];
  const audioOuts: Devices['audiooutput'] = [];
  devs.forEach((d) => {
    if (d.kind === 'videoinput') {
      videoIns.push(d);
    }
    else if (d.kind === 'audioinput') {
      audioIns.push(d);
    }
    else if (d.kind === 'audiooutput') {
      audioOuts.push(d);
    }
  });

  if (videoIns.length > 0) {
    videoInFacings.push('user');
    videoInFacings.push('environment');
  }

  return {
    videoinput: videoIns,
    videofacing: videoInFacings,
    audioinput: audioIns,
    audiooutput: audioOuts,
    videoquality: [],
  };
}


function filterVideoQualities(
  videoQualities: VideoQuality[],
  roomOptions: ExtendedProps['roomOptions']
):  VideoQuality[] {
  if (roomOptions.stream_quality === 'low')
    return videoQualities.filter(q => {
      return q.value !== StreamQualityValue.HD && q.value !== StreamQualityValue.FHD;
    });
  if (roomOptions.stream_quality === 'high')
    return videoQualities.filter(q => {
      return q.value === StreamQualityValue.HD || q.value === StreamQualityValue.FHD;
    });
  return videoQualities;
}


function DeviceSelection(props: ExtendedProps) {
  const {
    currentVideoInFacingMode,
    roomOptions,
    withVideo,
  } = props;

  const { formatMessage } = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const videoQualities = filterVideoQualities(getVideoQualities() as VideoQuality[], roomOptions);

  const [devices, setDevices] = React.useState<Devices>(
    {
      videoinput: [],
      videofacing: [],
      audioinput: [],
      audiooutput: [],
      videoquality: [],
    }
  );

  const defaultVideoQuality = { value: '', label: '' } as VideoQuality;

  const ifAvailable = (dev?: MediaDeviceInfo) => {
    // helper fun to avoid rendering devices that are no more available
    const dflt = { deviceId: '' } as MediaDeviceInfo;
    if (!dev) {
      return dflt;
    }
    const findById = (d: MediaDeviceInfo) => d.deviceId === dev.deviceId;
    return devices[dev.kind].find(findById) ? dev : dflt;
  };

  const currentVideoInDevice = ifAvailable(props.currentVideoInputDev);
  const currentAudioInDevice = ifAvailable(props.currentAudioInDev);
  const currentAudioOutDevice = ifAvailable(props.currentAudioOutDev);
  const currentVideoQuality = props.currentVideoQual || defaultVideoQuality;

  const scanDevices = React.useCallback(
    () => {
      discoverDevices().then(
        (devs: ReturnType<typeof discoverDevices>) => {
          setDevices(groupDevicesByKind(devs));
        }
      );
    }
    , [setDevices]
  );

  const rescanDevices = React.useCallback(
    () => {
      scanDevices();
      logEvent(Event.REFRESH_DEVICES);
    }
    , [scanDevices]
  );

  React.useEffect(
    () => scanDevices()
    , [scanDevices]
  );

  const handleVideoQualityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const qualityId = event.target.value;
    const quality = videoQualities.find((el) => el.value === qualityId);
    if (quality) {
      dispatch(saveVideoQuality(quality, new LocalStorage()));
      logEvent(Event.CHANGE_VIDEO_QUALITY, { 'quality': quality.label });
    }
  };

  const handleVideoInChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const devId = event.target.value;
    if (devId === 'user' || devId === 'environment') {
      dispatch(saveVideoFacingMode(devId, new LocalStorage()));
      logEvent(Event.MOBILE_CHANGE_FACING_MODE, { 'facing_mode': devId });
    } else {
      const device = devices.videoinput.find((el) => el.deviceId === devId);
      if (device) {
        dispatch(saveVideoConfig(device, new LocalStorage()));
        logEvent(Event.CHANGE_VIDEO_IN);
      }
    }
  };

  const handleAudioOutChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const devId = event.target.value;
    const device = devices.audiooutput.find((el) => el.deviceId === devId);
    if (device) {
      dispatch(saveAudioOutConfig(device, new LocalStorage()));
      logEvent(Event.CHANGE_AUDIO_OUTPUT);
    }
  };

  const handleAudioInChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const devId = event.target.value;
    const device = devices.audioinput.find((el) => el.deviceId === devId);
    if (device) {
      dispatch(saveAudioInConfig(device, new LocalStorage()));
      logEvent(Event.CHANGE_AUDIO_INPUT);
    }
  };

  const noDevices = formatMessage(messages.noDeviceAvailable);

  const getVideoFacingLabel = (facing: FacingMode): string => {
    return formatMessage(facing === 'user' ? messages.frontCamera : messages.rearCamera);
  };

  const getCurrentVideoInValue = () => {
    return isMobile
      ? (currentVideoInFacingMode && devices.videofacing.length > 0 ? currentVideoInFacingMode : '')
      : currentVideoInDevice.deviceId;
  };

  const getVideoInLabels = () => {
    if (isMobile) {
      return (
        devices.videofacing.map((f, idx) =>
          <MenuItem key={idx} value={f}>{getVideoFacingLabel(f)}</MenuItem>
        )
      );
    } else {
      return (
        devices.videoinput.map((d, idx) =>
          <MenuItem key={idx} value={d.deviceId}>{d.label}</MenuItem>
        )
      );
    }
  };

  return (
    <Box
      className={classes.settingsContainer}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <FormControl className={classes.settingsElement}>
        <InputLabel>{formatMessage(messages.deviceSelectorAudioInput)}</InputLabel>
        <Select
          value={currentAudioInDevice.deviceId}
          onChange={handleAudioInChange}
        >
          {
            orEmptyItem(
              devices.audioinput.map((d, idx) =>
                <MenuItem key={idx} value={d.deviceId}>{d.label}</MenuItem>
              )
              , noDevices
            )
          }
        </Select>
      </FormControl>
      <FormControl className={classes.settingsElement}>
        <InputLabel>{formatMessage(messages.deviceSelectorAudioOutput)}</InputLabel>
        <Select
          value={currentAudioOutDevice.deviceId}
          onChange={handleAudioOutChange}
        >
          {
            orEmptyItem(
              devices.audiooutput.map((d, idx) =>
                <MenuItem key={idx} value={d.deviceId}>{d.label}</MenuItem>
              )
              , noDevices
            )
          }
        </Select>
      </FormControl>
      <FormControl className={classes.settingsElement}>
        <InputLabel>{formatMessage(messages.deviceSelectorVideoInput)}</InputLabel>
        <Select
          disabled={!withVideo}
          value={getCurrentVideoInValue()}
          onChange={handleVideoInChange}
        >
          {
            orEmptyItem(getVideoInLabels(), noDevices)
          }
        </Select>
      </FormControl>
      <FormControl className={classes.settingsElement}>
        <InputLabel>{formatMessage(messages.deviceSelectorVideoQuality)}</InputLabel>
        <Select
          disabled={!withVideo}
          value={currentVideoQuality.value}
          onChange={handleVideoQualityChange}
        >
          {
            orEmptyItem(
              videoQualities.map((q, idx) =>
                <MenuItem key={idx} value={q.value}>{q.label}</MenuItem>
              )
              , noDevices
            )
          }
        </Select>
      </FormControl>
      <div className={classes.refreshButton}>
        <Tooltip title={formatMessage(messages.rescanDevices)}>
          <IconButton onClick={rescanDevices}>
            <IconReload size={20} />
          </IconButton>
        </Tooltip>
      </div>
    </Box>
  );
}



function mapStateToProps(state: State) {
  return {
    currentVideoInputDev: state.settings.videoDevice,
    currentVideoInFacingMode: state.settings.videoFacingMode,
    currentAudioInDev: state.settings.audioInDevice,
    currentAudioOutDev: state.settings.audioOutDevice,
    currentVideoQual: state.settings.videoQuality,
    roomOptions: state.appconfig.room_options,
  };
}


type Props = {
  withVideo: boolean;
};


type MappedProps = {
  currentVideoInputDev: State['settings']['videoDevice'];
  currentVideoInFacingMode: State['settings']['videoFacingMode'];
  currentAudioInDev: State['settings']['audioInDevice'];
  currentAudioOutDev: State['settings']['audioOutDevice'];
  currentVideoQual: State['settings']['videoQuality'];
  roomOptions: State['appconfig']['room_options'];
}


type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(DeviceSelection);
