import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import lodash from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import { State as RoomState } from '../../lib/reducers/room';
import { State as HolocomSessionState } from '../../lib/reducers/session';
import { WebSocketState as WebsocketState, Meeting, Organizer } from '../../lib/reducers/websocket';
import { RoomRole } from '../../lib/redux_types';
import { getWsMeetingDetails, getWsUserId } from '../../lib/reduxSelectors/websocket';
import getRouteFor, { Route } from '../../lib/utils/router';
import MeetingDuration from '../MeetingDuration';

import ConfPstnAccess from './ConfPstnAccess';
import ConfWebAccess from './ConfWebAccess';
import MeetingAttendees from './MeetingAttendees';
import PublishStreamUrl from './PublishStreamUrl';
import RoomInfoHeader from './RoomInfoHeader';
import RoomInfoTitle from './RoomInfoTitle';
import Scheduling from './Scheduling';

import useStyles from './style';


const messages = defineMessages({
  meetingDuration: { id: 'meetingDuration' },
});


type MappedProps = {
  roomDetails: Meeting | null;
  myRole: RoomRole;
  roomNumber: HolocomSessionState['pstnNumber'];
  myUsername: HolocomSessionState['username'];
  users: { [key: string]: string };
  myDisplayName: HolocomSessionState['displayName'];
}

type State = {
  room: RoomState;
  session: HolocomSessionState;
  websocket: WebsocketState;
}

type ExtendedOrganizer = {
  uid: string;
} & Organizer;

export type ExtendedMeeting = {
  organizer: ExtendedOrganizer;
  pstnNumber: string | null;
  rawUrl: string;
  pin: string | null;
} & Meeting;

const getUsers = (state: State) => {
  const roster = state.room.roster;
  const users: { [key: string]: string } = {};
  const keys = Object.keys(roster);
  for (const user of keys) {
    users[user] = roster[user].username;
  }
  return users;
};

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, lodash.isEqual);
const mapper = createDeepEqualSelector(getUsers, (u) => u || {});

const mapStateToProps = (state: State): MappedProps => {
  const myUserId = getWsUserId(state.websocket);
  let role: RoomRole = 'room_user';
  if (myUserId && state.room.roster[myUserId]) {
    role = state.room.roster[myUserId].role;
  }
  return {
    roomDetails: getWsMeetingDetails(state.websocket),
    myRole: role,
    roomNumber: state.session.pstnNumber,
    myUsername: state.session.username,
    users: mapper(state),
    myDisplayName: state.session.displayName,
  };
};


function RoomInfo(props: MappedProps) {

  const { formatMessage } = useIntl();
  const classes = useStyles();

  const getMeetingDuration = () => {
    return (
      <div>
        <div className={classes.title}>
          {formatMessage(messages.meetingDuration)}
        </div>
        <div className={classes.content}>
          <MeetingDuration humanize={true} />
        </div>
      </div>
    );
  };

  const getRoomDetails = (meetingDetails: Meeting | null): ExtendedMeeting | null => {
    if (meetingDetails === null) {
      return null;
    }
    const getUidFromRoster = (username: string | null) => {
      if (!username || (username === "guest")) {
        return null;
      }
      const keys = Object.keys(props.users);
      for (const uid of keys) {
        if (props.users[uid] === username) {
          return uid;
        }
      }
      return null;
    };

    const isMyRoom = () => {
      return meetingDetails.slug === props.myUsername;
    };

    let organizerUsername = meetingDetails.organizer.username;
    if (!organizerUsername && isMyRoom()) {
      organizerUsername = props.myUsername;
    }
    const rawUrl = getRouteFor(Route.Meeting, { slug: meetingDetails.slug }, '', window.location.origin);
    const organizerUid = getUidFromRoster(organizerUsername) || '';
    const res = {
      ...meetingDetails,
      organizer: { ...meetingDetails.organizer, uid: organizerUid },
      pstnNumber: props.roomNumber,
      rawUrl: rawUrl,
      pin: meetingDetails.number
    };
    if (!res.organizer.display && isMyRoom()) {
      res.organizer.display = props.myDisplayName;
    }
    return res;
  };

  const room = getRoomDetails(props.roomDetails);
  if (room === null) {
    return null;
  }

  return (
    <div className={classes.roomInfo}>
      <RoomInfoHeader roomDetails={room} />
      <RoomInfoTitle roomDetails={room} />
      <ConfWebAccess rawUrl={room.rawUrl} />
      <ConfPstnAccess roomDetails={room} />
      <PublishStreamUrl roomDetails={room} />
      <Scheduling roomDetails={room} />
      <MeetingAttendees roomDetails={room} />
      {getMeetingDuration()}
    </div>
  );
}

export default connect(mapStateToProps)(RoomInfo);
