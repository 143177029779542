import { HoloPushSub } from "./pushNotifications/pushNotifications";


class InMemoryStore {
  private store: Map<string, string>

  constructor() {
    this.store = new Map();
  }

  getItem(key: string) {
    return this.store.get(key) || null;
  }

  setItem(key: string, value: string) {
    return this.store.set(key, value);
  }

  removeItem(key: string) {
    this.store.delete(key);
  }
}


const inMemoryStore = new InMemoryStore();


class LocalStorage {

  private store: InMemoryStore | Storage
  private tokenKey: string
  private mediaConfigKey: string
  private pushSubscriptionKey: string
  private backgroundConfigKey: string

  constructor() {
    this.store = LocalStorage.getStore();
    this.tokenKey = 'HoloCom.authToken';
    this.mediaConfigKey = 'HoloCom.mediaConfig';
    this.pushSubscriptionKey = 'HoloCom.pushSubscription';
    this.backgroundConfigKey = 'HoloCom.backgroundConfig';
  }

  static getStore() {
    if (localStorage) {
      return localStorage;
    }
    else {
      return inMemoryStore;
    }
  }

  saveToken(token: string) {
    return this.store.setItem(this.tokenKey, token);
  }

  loadToken() {
    return this.store.getItem(this.tokenKey);
  }

  clearToken() {
    return this.store.removeItem(this.tokenKey);
  }

  saveMediaConfig(config: object) {
    const serializedConfig = JSON.stringify(config);
    return this.store.setItem(this.mediaConfigKey, serializedConfig);
  }

  loadMediaConfig() {
    const config = this.store.getItem(this.mediaConfigKey);
    if (config) {
      return JSON.parse(config);
    } else {
      return {};
    }
  }

  savePushSubscription(sub: HoloPushSub) {
    const serializedSub = JSON.stringify(sub);
    return this.store.setItem(this.pushSubscriptionKey, serializedSub);
  }

  loadPushSubscription() {
    const sub = this.store.getItem(this.pushSubscriptionKey);
    if (sub) {
      return JSON.parse(sub);
    } else {
      return null;
    }
  }

  saveBackgroundConfig(config: object) {
    const serializedConfig = JSON.stringify(config);
    return this.store.setItem(this.backgroundConfigKey, serializedConfig);
  }

  loadBackgroundConfig() {
    const config = this.store.getItem(this.backgroundConfigKey);
    if (config) {
      return JSON.parse(config);
    } else {
      return {};
    }
  }

  clearPushSubscription() {
    return this.store.removeItem(this.pushSubscriptionKey);
  }
}

export default LocalStorage;
