const defaultRosterSizePercent = '20%';
const minRosterSizePx = 350;
const maxRosterSizePercent = '80%';
const fallbackWidth = 1920;
const fallbackHeight = 1080;


const getWindowSizes = (): {width: number; height: number} => {
  const canUseDOM = typeof window !== 'undefined';

  return {
    width: canUseDOM ? window.innerWidth : fallbackWidth,
    height: canUseDOM ? window.innerHeight : fallbackHeight,
  };
};

const getMinRosterSizePx = (): number => {
  return minRosterSizePx;
};

const getMinRosterSizePercent = (): string => {
  const { width } = getWindowSizes();
  return ((minRosterSizePx / width) * 100).toFixed(2) + '%';
};

const getMaxRosterSizePx = (): number => {
  const { width } = getWindowSizes();
  return width * parseFloat(maxRosterSizePercent) / 100;
};

const getMaxRosterSizePercent = (): string => {
  return maxRosterSizePercent;
};

const getRosterSizePx = (): number => {
  const { width } = getWindowSizes();
  return width * parseFloat(defaultRosterSizePercent) / 100;
};

const getRosterSizePercent = (): string => {
  const rosterSizePx = getRosterSizePx();
  return (rosterSizePx >= minRosterSizePx)
    ? defaultRosterSizePercent
    : getMinRosterSizePercent();
};

const getColumns = (size: string | number): number => {
  const { width } = getWindowSizes();
  if (typeof(size) === 'string') {
    const actualSize = width * parseFloat(size) / 100;
    return Math.floor(actualSize / minRosterSizePx);
  } else {
    return Math.floor(size / minRosterSizePx);
  }
};

const getPercent = (size: number): string => {
  const { width } = getWindowSizes();
  return ((size / width) * 100).toFixed(2) + '%';
};


export {
  getWindowSizes,
  getMinRosterSizePx,
  getMinRosterSizePercent,
  getMaxRosterSizePx,
  getMaxRosterSizePercent,
  getRosterSizePx,
  getRosterSizePercent,
  getColumns,
  getPercent,
};
