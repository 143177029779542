import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { State } from '../../lib/reducers';
import { getPin } from '../../lib/reduxSelectors/roomInfo';

import { ExtendedMeeting } from '.';

import useStyles from './style';


const messages = defineMessages({
  roomPstnAccess: { id: 'roomInfoPstnAccess' },
  roomPstnNotAvailable: { id: 'roomInfoPstnNotAvailable' },
  roomPstnNumber: { id: 'roomInfoPstnNumber' },
});


type MappedProps = {
  pin: string | null;
}

type Props = {
  roomDetails: ExtendedMeeting;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State, { roomDetails }: Props): MappedProps => ({
  pin: getPin(state, roomDetails)
});


function ConfPstnAccess(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { pin } = props;

  const getConfPstnAccessComponent = (room: ExtendedMeeting) => {
    const msg = formatMessage(messages.roomPstnNumber, { number: room.pstnNumber, pin: pin });
    let value = (
      <div className={classes.content}>
        {msg}
      </div>
    );
    if (!(pin && room.number)) {
      value = (
        <div className={classes.content}>
          {formatMessage(messages.roomPstnNotAvailable)}
        </div>
      );
    }
    return (
      <div>
        <div className={classes.title}>
          {formatMessage(messages.roomPstnAccess)}
        </div>
        {value}
      </div>
    );
  };

  return (
    <div>
      {getConfPstnAccessComponent(props.roomDetails)}
    </div>
  );
}


export default connect(mapStateToProps)(ConfPstnAccess);
