import React from 'react';
import { connect, useDispatch } from "react-redux";

import { toggleFullscreen } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from "../../lib/reducers";
import { ObservedUser } from '../../lib/reduxSelectors/roster';
import { canFullscreen } from '../../lib/reduxSelectors/user';
import LoadingVideoElement from '../VideoElement/LoadingVideoElement';

import Fullscreen from './Fullscreen';
import VideoToolbar from './VideoToolbar';



function GridUser(props: ExtendedProps) {
  const { style, stream, uid, isFullscreenEnabled, fullscreenUser } = props;
  const dispatch = useDispatch();

  const onDoubleClick = React.useCallback(
    () => {
      if (isFullscreenEnabled) {
        if (fullscreenUser) {
          dispatch(toggleFullscreen(null));
          logEvent(Event.FULLSCREEN, { 'action': 'exit_fullscreen', 'from': 'video_element' });
        } else {
          dispatch(toggleFullscreen(uid));
          logEvent(Event.FULLSCREEN, { 'action': 'enter_fullscreen', 'from': 'video_element' });
        }
      }
    }, [dispatch, isFullscreenEnabled, fullscreenUser, uid]
  );

  return (
    <div style={style} onDoubleClick={onDoubleClick}>
      <Fullscreen user={uid}>
        <LoadingVideoElement
          user={uid}
          mirrored={false}
          addVideoMutedIconOverlay={true}
          stream={stream}
        />
        <VideoToolbar uid={uid} />
      </Fullscreen>
    </div>
  );
}


type Props = {
  style: React.CSSProperties;
  stream: ObservedUser['stream'];
  uid: string;
}

type MappedProps = {
  fullscreenUser: string | null;
  isFullscreenEnabled: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State, { uid }: Props): MappedProps => ({
  fullscreenUser: state.room.fullScreenEnabled,
  isFullscreenEnabled: canFullscreen(uid, state),
});


export default connect(mapStateToProps)(GridUser);
