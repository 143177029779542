const style = theme => ({
  container: {
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  video: {
    width: '100%',
    height: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: 'black',
  },
  mirrored: {
    transform: 'rotateY(180deg)',
  },
  rounded: {
    borderRadius: '10px',
  },
  muted: {
    background: 'black',
    filter: 'grayscale(100%)',
    opacity: 1,
  },
  videoMutedOverlayContainer: {
    top: 0,
    left: 0,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20%',
    width: '100%',
    height: '100%',
    background: `linear-gradient(135deg, ${theme.palette.primary.main} 10%, ${theme.palette.secondary.light} 100%)`,
  },
  videoBlurred: {
    '-webkit-filter': 'blur(30px)',
    filter: 'blur(30px)',
  },
  videoMutedOverlay: {
    color: theme.palette.grey[600],
    opacity: 0.6,
    width: '100%',
    height: '100%',
  },
  videoMutedInitials: {
    fontSize: '3vw',
    textShadow: '0 2px 5px black',
    color: 'white'
  },
  videoPipOverlayContainer: {
    zIndex: theme.zIndex.appBar - 1,
    background: theme.palette.common.black,
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  videoPipOverlay: {
    color: theme.palette.grey[600],
    zIndex: theme.zIndex.appBar - 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  fullHeight: {
    height: '100%',
  },
  talkingIndicator: {
    boxShadow: `0px 0px 10px 0px ${theme.palette.primary.main}`,
    position: 'relative',
    // FIXME: implement a better talking indicator on mobile
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0px 0px',
    },
  },
  fit: {
    objectFit: 'cover'
  },
  // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Avatar/Avatar.js#L15-L16
  circled: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    objectFit: 'cover',
  }
});

export default style;
