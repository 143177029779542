import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',  // 100% to detect mouse movements on the whole child el.
      height: '100%', // ditto
    },
  }),
);

function HideOnMouseOut(props: React.PropsWithChildren<{}>) {
  const classes = useStyles();

  const [shown, setShown] = React.useState(false);

  const onMouseEntered = React.useCallback(
    () => {
      if (!shown) setShown(true);
    }
    , [shown, setShown]
  );

  const onMouseLeft = React.useCallback(
    () => {
      if (shown) setShown(false);
    }
    , [shown, setShown]
  );


  const childrenShowAware = React.Children.map(props.children, child =>
    React.cloneElement(child as React.ReactElement, { show: shown })
  );

  return (
    <div
      className={classes.container}
      onMouseEnter={onMouseEntered}
      onMouseLeave={onMouseLeft}
    >
      <React.Fragment>
        {childrenShowAware}
      </React.Fragment>
    </div>
  );
}


export default HideOnMouseOut;
