import queryString from 'query-string';

// FIXME: we should take as input parameter a correlation id to send to server
//       (and generate it if not supplied)

function _fetch(url, request) {
  return new Promise((resolve, reject) => {
    fetch(url, request).then(
      (response) => {
        if (response.ok) {
          response.json().then(
            (res) => {
              resolve(res);
            }
          ).catch(
            (err) => {
              reject({ errorMessage: "error decoding json: " + err, errorCode: null });
            }
          );
        }
        else {
          reject({ errorMessage: response.statusText, errorCode: response.status });
        }
      }
    ).catch(
      (err) => {
        reject({ errorMessage: err, errorCode: null });
      }
    );
  });
}

export function checkBrowserSession() {
  const url = '/api/session/browser_session';
  const request = {
    method: 'GET',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
  };
  return _fetch(url, request);
}

export function login(username, password) {
  const url = '/api/session/create';
  const request = {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: username, password: password }),
  };
  return _fetch(url, request);
}


export function logout(token) {
  const url = '/api/session/delete';
  const request = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
  };
  return _fetch(url, request);
}


export function guestLogin(displayName) {
  const params = queryString.stringify({ display: displayName });
  const url = `/api/session/guest?${params}`;
  const request = {
    method: 'GET',
    credentials: 'same-origin',
  };
  return _fetch(url, request);
}
