import React from "react";
import { useIntl } from 'react-intl';

import { IconChevronDown } from "../IconSet";

import messages from './buttonsMessages';
import ToolbarButton from "./ToolbarButton";

import useStyles from './buttonsStyle';


function getIcon(style: string) {
  return (
    <div className={style}>
      <IconChevronDown size={28} />
    </div>
  );
}

function ExtraControlsButton(props: Props) {
  const { showMenu } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <ToolbarButton
      icon={getIcon(classes.moreIcon)}
      text={formatMessage(messages.otherOptions)}
      buttonProps={{ className: classes.buttons, onClick: showMenu }}
    />
  );
}


type Props = {
  showMenu: (event: React.MouseEvent<HTMLElement>) => void;
}


export default ExtraControlsButton;
