import React from 'react';
import { connect } from 'react-redux';

import { createSelector } from 'reselect';

import Badge from '@material-ui/core/Badge';

import { State } from '../../lib/reducers';
import { UserMessage, ChatMessages, UnreadPrivateUserMessages, LastMessage } from '../../lib/redux_types';
import { isChatOpen } from '../../lib/reduxSelectors/room';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { usePrevious } from '../../lib/utils/hooks';
import { checkPermissionNotification, sendNotification } from '../../notification';
import { IconMessage, IconMessageSelected } from '../IconSet';


function ChatIcon(props: ExtendedProps) {
  // check browser notification permission at mount
  React.useEffect(() => checkPermissionNotification(), []);

  // send notification on chatMessages prop change
  const prevLastMessage = usePrevious(props.lastMessage, {} as LastMessage);
  React.useEffect(
    () => {
      if (!(document.hasFocus()) && prevLastMessage.msgId !== props.lastMessage.msgId) {
        if (props.lastMessage.from !== props.myself) {
          const title = props.lastMessage.displayName;
          const lastMessage = props.lastMessage.message;
          sendNotification(title, lastMessage);
        }
      }
    },
    [props.lastMessage, prevLastMessage, props.myself]
  );

  const unreadMessageCount = props.unreadPublicMessages + props.unreadPrivateMessages;

  return (
    <Badge badgeContent={unreadMessageCount} color="secondary">
      { props.isChatOpen ? <IconMessageSelected size={28} /> : <IconMessage size={28} /> }
    </Badge>
  );
}

type Props = {}

type MappedProps = {
  publicMessages: UserMessage[];
  myself: null | string;
  unreadPublicMessages: number;
  unreadPrivateMessages: number;
  lastMessage: LastMessage;
  isChatOpen: boolean;
}

type ExtendedProps = Props & MappedProps;

const getPublicMessages = createSelector(
  (state: State) => state.room.publicMessages,
  _getPublicMessages
);

const getUnreadPrivateMessageCount = createSelector(
  (state: State) => state.room.unreadPrivateMessages,
  _getUnreadPrivateMessageCount
);

const mapStateToProps = (state: State): MappedProps => ({
  publicMessages: getPublicMessages(state),
  myself: getWsUserId(state.websocket),
  unreadPublicMessages: state.room.unreadPublicMessages,
  unreadPrivateMessages: getUnreadPrivateMessageCount(state),
  lastMessage: state.room.lastMessage,
  isChatOpen: isChatOpen(state)
});


export default connect(mapStateToProps)(ChatIcon);

function _getPublicMessages(allMessages: ChatMessages): UserMessage[] {
  return allMessages.filter((msg) => msg.class !== 'event') as UserMessage[];
}

function _getUnreadPrivateMessageCount(allMessages: UnreadPrivateUserMessages): number {
  let count = 0;
  Object.values(allMessages).forEach((n) => count += n);
  return count;
}
