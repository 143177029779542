import React from 'react';
import { connect } from 'react-redux';

import { State as RoomState } from '../../lib/reducers/room';
import { IconRecordOn } from '../IconSet';


type MappedProps = {
  isAudioRecording: boolean;
}

type State = {
  room: RoomState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isAudioRecording: state.room.isAudioRecording,
});

type Props = {
} & MappedProps


function AudioRecordOnIcon(props: Props) {

  return (
    <React.Fragment>
      {props.isAudioRecording ?
        <IconRecordOn size={24} /> :
        null
      }
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(AudioRecordOnIcon);