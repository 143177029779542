import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { useRelayEnvironment } from 'relay-hooks';

import { Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import {
  subscribeToPushNotifications,
  unsubscribeToPushNotifications,
  checkPushNotificationsAvailable
} from '../../lib/actions/notifications';
import { logEvent, Event } from '../../lib/analytics';
import { PushSubscriptionLocale } from '../../lib/api/relay/__generated__/storeSubscription.graphql';
import { State } from '../../lib/reducers';
import { ReadyAuthState } from '../../lib/reducers/auth';
import { getToken } from '../../lib/reduxSelectors/auth';
import LocalStorage from '../../localStorage';
import AsyncSwitch from '../AsyncSwitch';
import { IconHelp } from '../IconSet';


const messages = defineMessages({
  pushNotificationsSwitchText: { id: 'pushNotificationsSwitchText' },
  pushNotificationsSwitchInfo: { id: 'pushNotificationsSwitchInfo' },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userInfoEntryContent: {
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    userInfoLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    userInfoLabelContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    tooltip: {
      whiteSpace: 'pre-line',
    },
    info: {
      paddingLeft: theme.spacing(0.5)
    }
  })
);

function PushNotificationsSwitch(props: ExtendedProps) {
  const {
    pushSubscription,
    pushNotificationsConfig,
    pushNotificationsAvailable,
    isSubscribing,
  } = props;

  const authToken = props.authToken || "";

  const classes = useStyles();
  const relayEnv = useRelayEnvironment();
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const convertedLocale: PushSubscriptionLocale = locale.toUpperCase() === 'IT' ? 'IT' : 'EN';

  React.useEffect(
    () => {
      if (pushNotificationsConfig)
        dispatch(checkPushNotificationsAvailable(pushNotificationsConfig));
    }
  );

  const handleChange = () => {
    if (!pushNotificationsConfig) return;

    if (pushSubscription) {
      dispatch(unsubscribeToPushNotifications(
        pushSubscription,
        pushNotificationsConfig,
        relayEnv,
        new LocalStorage()
      ));
      logEvent(Event.ENABLE_PUSH_NOTIFICATIONS, { 'setting': 'disable' });
    } else {
      dispatch(subscribeToPushNotifications(
        pushNotificationsConfig,
        authToken,
        convertedLocale,
        relayEnv,
        new LocalStorage()
      ));
      logEvent(Event.ENABLE_PUSH_NOTIFICATIONS, { 'setting': 'enable' });
    }
  };

  return (
    <div className={classes.userInfoLinkContainer}>
      <div className={`${classes.userInfoEntryContent} ${classes.userInfoLabelContainer}`}>
        {formatMessage(messages.pushNotificationsSwitchText)}
        <Tooltip placement='top'
          title={<span className={classes.tooltip}>{formatMessage(messages.pushNotificationsSwitchInfo)}</span>}
        >
          <div className={classes.info}>
            <IconHelp size={16} />
          </div>
        </Tooltip>
      </div>
      <AsyncSwitch
        isLoading={isSubscribing}
        disabled={!pushNotificationsAvailable || isSubscribing}
        checked={Boolean(pushSubscription)}
        onChange={handleChange}
        color="primary"
      />
    </div>
  );
}

type MappedProps = {
  pushSubscription: State['notifications']['pushSubscription'];
  pushNotificationsConfig: State['appconfig']['push_notifications'];
  pushNotificationsAvailable: State['notifications']['pushNotificationsAvailable'];
  isSubscribing: State['notifications']['isSubscribing'];
  authToken: ReadyAuthState['token'];
}

type Props = {
};

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State): MappedProps => ({
  pushSubscription: state.notifications.pushSubscription,
  pushNotificationsConfig: state.appconfig.push_notifications,
  pushNotificationsAvailable: state.notifications.pushNotificationsAvailable,
  isSubscribing: state.notifications.isSubscribing,
  authToken: getToken(state.auth),
});

export default connect(mapStateToProps)(PushNotificationsSwitch);
