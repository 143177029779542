/* inspired from https://github.com/IndigoUnited/node-promise-retry */
import errcode from 'err-code';
import { Err, Extensions } from 'err-code';
import retry, { RetryOperation } from 'retry';
import uuid from 'uuid';


export interface RetryOptions {
  /**
   *  The maximum amount of times to retry the operation.
   * @default 10
   */
  retries?: number;
  /**
  * The exponential factor to use.
  * @default 2
  */
  factor?: number;
  /**
  * The number of milliseconds before starting the first retry.
  * @default 1000
  */
  minTimeout?: number;
  /**
  * The maximum number of milliseconds between two retries.
  * @default Infinity
  */
  maxTimeout?: number;
  /**
  * Randomizes the timeouts by multiplying a factor between 1-2.
  * @default false
  */
  randomize?: boolean;
}


type ExtendedError = Err & Extensions;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RejectFun = (reason?: any) => void;


export type RetryRef = {
  ref: string;
  p: Promise<unknown>;
  retryOperation: RetryOperation;
  cancelFun: RejectFun;
}


function isRetryError(err: ExtendedError) {
  const hasOwn = Object.prototype.hasOwnProperty;

  return err && err.code === 'EPROMISERETRY' && hasOwn.call(err, 'retried');
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stopRetry(ref: RetryRef, reason?: any) {
  const { retryOperation, cancelFun } = ref;
  retryOperation.stop();
  cancelFun(reason);
}


export function retryPromise(options: RetryOptions,
  fn: (retry: (err: ExtendedError) => never, currentAttempt: number,
    operation: RetryOperation) => Promise<unknown>): RetryRef {
  const operation = retry.operation(options);
  let cancelFun: RejectFun = () => {};

  const p = new Promise(function(resolve, reject) {
    cancelFun = reject;

    operation.attempt(function(currentAttempt) {
      Promise.resolve()
        .then(function() {
          return fn(function(err) {
            if (isRetryError(err)) {
              err = err.retried;
            }

            throw errcode(new Error('Retrying'), 'EPROMISERETRY', { retried: err });
          }, currentAttempt, operation);
        })
        .then(resolve, function(err) {
          if (isRetryError(err)) {
            err = err.retried;

            if (operation.retry(err || new Error())) {
              return;
            }
          }

          reject(err);
        });
    });
  });

  return { p: p, retryOperation: operation, ref: uuid.v4(), cancelFun: cancelFun };
}
