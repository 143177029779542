import { VideoRoom } from '../api/videoroom';
import { State } from '../reducers';

import { getWsUserId } from './websocket';


export function getSelectedStream(state: State) {
  const layoutConfig = state.room.layoutConfig;
  if (layoutConfig.featured_type && layoutConfig.featured_id) {
    if (layoutConfig.featured_type === 'screen') {
      return `${layoutConfig.featured_id}_screen`;
    }
    else {
      return layoutConfig.featured_id;
    }
  }
  return null;
}


export function areSecondaryVideosHidden(state: State) {
  const layoutConfig = state.room.layoutConfig;
  if (layoutConfig.enlarge_video) {
    return true;
  }
  return false;
}


export function findRoomOwner(state: State) {
  const filterByOwnerRole = Object.keys(state.room.roster).filter(
    (uid) => {
      return state.room.roster[uid].role === 'room_owner';
    }
  );
  return filterByOwnerRole[0] || null;
}


export function hasScreen(state: State, uid: string) {
  if (uid === getWsUserId(state.websocket)) {
    return Boolean(state.room.screenStream);
  }
  else {
    const user = state.room.roster[uid];
    return Boolean(user && user.screen);
  }
}


export function hasStream(state: State, uid: string) {
  if (uid === getWsUserId(state.websocket)) {
    const stream = state.room.localvideo_stream;
    return Boolean(stream && VideoRoom.getVideoTrackFromStream(stream));
  }
  else {
    const user = state.room.roster[uid];
    return Boolean(user && user.stream && VideoRoom.getVideoTrackFromStream(user.stream));
  }
}


export function findSelectedStream(state: State, hasVideoStream: boolean) {
  const myUserId = getWsUserId(state.websocket);

  const layoutConfig = state.room.layoutConfig;
  let selectedStream = null;
  let myStreamIsSelected = false;
  let myScreenIsSelected = false;
  if (layoutConfig.featured_type && layoutConfig.featured_id) {
    if (layoutConfig.featured_type === 'screen') {
      if (layoutConfig.featured_id === myUserId && state.room.screenStream) {
        myScreenIsSelected = true;
        selectedStream = `${myUserId}_screen`;
      }
      else {
        const selectedUser = state.room.roster[layoutConfig.featured_id];
        if (selectedUser && selectedUser.screen) {
          selectedStream = `${layoutConfig.featured_id}_screen`;
        }
      }
    }
    else {
      if (layoutConfig.featured_id === myUserId && hasVideoStream) {
        myStreamIsSelected = true;
        selectedStream = myUserId;
      }
      else {
        const selectedUser = state.room.roster[layoutConfig.featured_id];
        if (selectedUser && selectedUser.stream && VideoRoom.getVideoTrackFromStream(selectedUser.stream)) {
          selectedStream = layoutConfig.featured_id;
        }
      }
    }
  }
  return { selectedStream, myStreamIsSelected, myScreenIsSelected };
}


export function findAScreen(state: State) {
  // find the first valid screen in the roster
  const myUserId = getWsUserId(state.websocket);
  if (myUserId && hasScreen(state, myUserId)) {
    return myUserId;
  }

  const roster = state.room.roster;
  const uids = Object.keys(roster);
  for (const uid of uids) {
    if (hasScreen(state, uid)) {
      return uid;
    }
  }

  return null;
}


export function findAStream(state: State) {
  // find the first valid stream in the roster
  const myUserId = getWsUserId(state.websocket);
  if (myUserId && hasStream(state, myUserId)) {
    return myUserId;
  }

  const roster = state.room.roster;
  const uids = Object.keys(roster);
  for (const uid of uids) {
    if (hasStream(state, uid)) {
      return uid;
    }
  }

  return null;
}
