import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { useRelayEnvironment } from 'relay-hooks';

import { Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { updateScreenshareFrameRate } from '../../lib/actions/user';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { usePrevious } from '../../lib/utils/hooks';
import { defaultScreenshareFrameRate } from '../../rtc';
import { IconHelp, IconWarning } from '../IconSet';


const messages = defineMessages({
  screenshareFrameRateLabel: { id: 'screenshareFrameRateLabel' },
  screenshareFrameRateInfo: { id: 'screenshareFrameRateInfo' },
  screenshareFrameRateWarning: { id: 'screenshareFrameRateWarning' }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userInfoEntryContent: {
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },
    userInfoLabelContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    info: {
      paddingLeft: theme.spacing(0.5)
    },
    warningContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: theme.spacing(2)
    },
    slider: {
      width: '95%'
    }
  })
);

const marks = [
  {
    value: 1,
    label: <Typography variant='caption'>1</Typography>
  },
  {
    value: 3,
  },
  {
    value: 5,
    label: <Typography variant='caption'>5</Typography>
  },
  {
    value: 10,
    label: <Typography variant='caption'>10</Typography>
  },
  {
    value: 15,
  },
  {
    value: 20,
  },
  {
    value: 25,
    label: <Typography variant='caption'>25 FPS</Typography>
  }
];

function ScreenshareFrameRateSlider(props: ExtendedProps) {
  const {
    screenshareFrameRate,
    isUpdating
  } = props;

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const relayEnv = useRelayEnvironment();


  const storeSettingsFunRef = React.useRef(() => { });

  const handleChangeCommitted = (_event: React.ChangeEvent<{}>, selectedValue: number | number[]) => {
    if (typeof selectedValue === "number") {
      storeSettingsFunRef.current = () => {
        if (selectedValue && selectedValue !== screenshareFrameRate) {
          dispatch(updateScreenshareFrameRate(relayEnv, selectedValue));
          logEvent(Event.SET_SCREEN_FRAMERATE, { 'framerate': selectedValue.toString() });
        }
      };
    }
  };

  // only perform the updateScreenshareFrameRate mutation on unmount
  React.useEffect(() => {
    return () => {
      storeSettingsFunRef.current();
    };
  }, [storeSettingsFunRef]);


  // this value only controls the graphic part of the slider
  const [value, setValue] = React.useState(screenshareFrameRate || defaultScreenshareFrameRate);

  // handler needed only to update the graphics of the slider while the user is changing it
  const handleChange = (_event: React.ChangeEvent<{}>, selectedValue: number | number[]) => {
    if (typeof selectedValue === "number")
      setValue(selectedValue);
  };


  const previousScreenshareFrameRate = usePrevious(screenshareFrameRate, null);

  // this effect updates the view if a new value for screenshareFrameRate is set in the background
  React.useEffect(() => {
    if (screenshareFrameRate && screenshareFrameRate !== previousScreenshareFrameRate) {
      setValue(screenshareFrameRate);
    }
  }, [screenshareFrameRate, previousScreenshareFrameRate, setValue]);


  return (
    <Grid container
      direction='row'
      alignItems='center'
    >
      <Grid item xs={10}>
        <div className={`${classes.userInfoEntryContent} ${classes.userInfoLabelContainer}`}>
          {formatMessage(messages.screenshareFrameRateLabel)}
          <Tooltip placement='top' title={formatMessage(messages.screenshareFrameRateInfo)}>
            <div className={classes.info}>
              <IconHelp size={16} />
            </div>
          </Tooltip>
        </div>
        <Slider
          disabled={isUpdating}
          className={classes.slider}
          color='primary'
          value={value}
          step={null}
          valueLabelDisplay="auto"
          min={1}
          max={25}
          marks={marks}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
        />
      </Grid>
      <Grid item xs={2}>
        {value >= 10 &&
          <div className={classes.warningContainer}>
            <Tooltip placement='top-end' title={formatMessage(messages.screenshareFrameRateWarning)}>
              <div>
                <IconWarning color='red' size={24} />
              </div>
            </Tooltip>
          </div>
        }
      </Grid>
    </Grid>
  );
}

type MappedProps = {
  screenshareFrameRate: State['session']['screenshareFrameRate'];
  isUpdating: State['session']['isUpdatingScreenshareFrameRate'];
}

type Props = {
};

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State): MappedProps => ({
  screenshareFrameRate: state.session.screenshareFrameRate,
  isUpdating: state.session.isUpdatingScreenshareFrameRate
});

export default connect(mapStateToProps)(ScreenshareFrameRateSlider);
