import {
  SESSIONDETAILS_REQUEST,
  SESSIONDETAILS_FAILURE,
  SESSIONDETAILS_SUCCESS,
  SESSIONDETAILS_CLEAR,
  SESSION_SETRECORDER,
  SESSION_SET_UPDATING,
  SESSION_SET_ROOM_LOCK_PREFERENCE,
  Action,
  Session,
  SESSION_SET_SCREENSHARE_FRAME_RATE,
} from '../actions/session';
import { MeetingType } from '../api/relay/utils/types';


export interface State {
  isFetching: boolean;
  sessionReady: boolean;
  errorCode: null | number;
  errorMessage: null | string;
  isRecorder: boolean;
  realm: null | Session['realm'];
  displayName: null | Session['displayName'];
  username: null | Session['username'];
  roles: Session['roles'];
  stunServers: Session['stunServers'];
  domainId: null | Session['domainId'];
  email: Session['email'];
  userId: null | Session['userId'];
  roomNumber: null | Session['roomNumber'];
  roomOwnerNumber: null | Session['roomOwnerNumber'];
  pstnNumber: null | Session['pstnNumber'];
  callStatsConfig: null | Session['callStatsConfig'];
  chromeScreenShareConfig: null | Session['chromeScreenShareConfig'];
  recordingEnabled: Session['recordingEnabled'];
  livestreamingEnabled: Session['livestreamingEnabled'];
  sendAs: Session['sendAs'];
  personalRoomEnabled: Session['personalRoomEnabled'];
  webinarEnabled: Session['webinarEnabled'];
  isAudioOnly: Session['isAudioOnly'];
  defaultMeetingType: MeetingType;
  roomLockPreference: boolean;
  isUpdatingRoomLockPreference: boolean;
  screenshareFrameRate: Session['screenshareFrameRate'];
  isUpdatingScreenshareFrameRate: boolean;
}


const defaultState: State = {
  isFetching: false,
  sessionReady: false,
  errorCode: null,
  errorMessage: null,
  isRecorder: false,
  realm: null,
  displayName: null,    // name and surname if a user, requested display name if guest
  username: null,
  roles: [],
  stunServers: [],
  domainId: null,
  email: null,
  userId: null,
  roomNumber: null,
  roomOwnerNumber: null,
  pstnNumber: null,
  callStatsConfig: null,
  chromeScreenShareConfig: null,
  recordingEnabled: false,
  livestreamingEnabled: false,
  sendAs: false,
  personalRoomEnabled: false,
  webinarEnabled: false,
  isAudioOnly: false,
  defaultMeetingType: 'REGULAR',
  roomLockPreference: false,
  isUpdatingRoomLockPreference: false,
  screenshareFrameRate: null,
  isUpdatingScreenshareFrameRate: false,
};


function session(state: State = defaultState, action: Action) {
  switch (action.type) {
    case SESSIONDETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        sessionReady: false,
      });
    case SESSIONDETAILS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        sessionReady: false,
        errorMessage: action.payload.errorMessage
      });
    case SESSIONDETAILS_SUCCESS: {
      const sess = action.payload.session;
      return Object.assign({}, state, {
        isFetching: false,
        sessionReady: true,
        realm: sess.realm,
        displayName: sess.displayName,
        username: sess.username,
        roles: sess.roles,
        stunServers: sess.stunServers,
        domainId: sess.domainId,
        email: sess.email,
        userId: sess.userId,
        roomNumber: sess.roomNumber,
        roomOwnerNumber: sess.roomOwnerNumber,
        pstnNumber: sess.pstnNumber,
        callStatsConfig: sess.callStatsConfig,
        chromeScreenShareConfig: sess.chromeScreenShareConfig,
        recordingEnabled: sess.recordingEnabled,
        livestreamingEnabled: sess.livestreamingEnabled,
        sendAs: sess.sendAs,
        personalRoomEnabled: sess.personalRoomEnabled,
        webinarEnabled: sess.webinarEnabled,
        isAudioOnly: sess.isAudioOnly,
        defaultMeetingType: sess.isAudioOnly ? 'AUDIO_ONLY' : 'REGULAR',
        roomLockPreference: sess.roomLockPreference,
        screenshareFrameRate: sess.screenshareFrameRate
      });
    }
    case SESSIONDETAILS_CLEAR:
      return Object.assign({}, state, defaultState);
    case SESSION_SETRECORDER:
      return Object.assign({}, state, {
        isRecorder: action.payload.is_recorder
      });
    case SESSION_SET_UPDATING:
      switch (action.payload.key) {
        case "roomLockPreference":
          return Object.assign({}, state, {
            isUpdatingRoomLockPreference: action.payload.isUpdating
          });

        case "screenshareFrameRate":
          return Object.assign({}, state, {
            isUpdatingScreenshareFrameRate: action.payload.isUpdating
          });

        default:
          return state;
      }
    case SESSION_SET_ROOM_LOCK_PREFERENCE:
      return Object.assign({}, state, {
        roomLockPreference: action.payload.roomLockPreference,
        isUpdatingRoomLockPreference: false
      });
    case SESSION_SET_SCREENSHARE_FRAME_RATE:
      return Object.assign({}, state, {
        screenshareFrameRate: action.payload.screenshareFrameRate,
        isUpdatingScreenshareFrameRate: false
      });
    default:
      return state;
  }
}
export default session;
export { defaultState };
