import React, { useRef } from 'react';
import { connect } from 'react-redux';

import Chip from '@material-ui/core/Chip';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { logEvent, Event } from '../../lib/analytics';
import { downloadSharedFile } from '../../lib/api/sharedFiles';
import { newEvent, WARNING } from '../../lib/notifications';
import { State } from '../../lib/reducers';
import { ReadyAuthState } from '../../lib/reducers/auth';
import { SharedFile } from '../../lib/redux_types';
import { getToken } from '../../lib/reduxSelectors/auth';
import { getWsRoomName } from '../../lib/reduxSelectors/websocket';


type Props = {
  msg: SharedFile;
}

type MappedProps = {
  token: ReadyAuthState['token'];
  roomName: string;
}

type ExtendedProps = Props & MappedProps

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    attachment: {
      maxWidth: '100%'
    }
  })
);

function DownloadFile(props: ExtendedProps) {
  const {
    msg,
    token,
    roomName
  } = props;

  const classes = useStyles();

  const linkRef = useRef<HTMLAnchorElement>(null);

  const handleClick = () => {
    if (!token) {return;}
    downloadSharedFile(msg.data.msgId, roomName, token)
      .then(response => response.blob())
      .then(blob => {
        const link = linkRef.current;
        if (link) {
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.setAttribute('download', msg.data.filename);
          link.click();
          window.URL.revokeObjectURL(url);
          logEvent(Event.DOWNLOAD_CHAT_FILE);
        }
      })
      .catch(() => {
        newEvent(WARNING, 'sharedFileDownloadError', 'sharedFileDownloadError');
      });
  };

  return (
    <React.Fragment>
      <Tooltip title={msg.data.filename}>
        <Chip
          className={classes.attachment}
          icon={<AttachFileIcon fontSize="small" />}
          label={msg.data.filename}
          onClick={handleClick}
        />
      </Tooltip>
      <a hidden ref={linkRef} href={"/"}>download</a>
    </React.Fragment>
  );
}

const mapStateToProps = (state: State): MappedProps => ({
  token: getToken(state.auth),
  roomName: getWsRoomName(state.websocket) || "",
});

export default connect(mapStateToProps)(DownloadFile);
