const workerScript = "../assets/js/streamPipelineFrameTimerWorker.js";

export default class FrameTimer {
  private worker: Worker;

  constructor(callback: () => void) {
    this.worker = new Worker(workerScript);
    this.worker.onmessage = callback;
  }

  public nextFrame(framerate: number) {
    this.worker.postMessage(1000 / framerate);
  }

  public stop() {
    this.worker.terminate();
  }
}
