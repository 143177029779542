import { getLogger } from "../logger";

import { initFirebaseAnalytics, logEvent as logFirebaseEvent } from "./firebaseAnalytics";


/**
 * Initilizes all the configured analytics services
 */
export async function initAnalytics() {
  try {
    initFirebaseAnalytics();
  } catch (e) {
    const logger = getLogger('Analytics');
    logger.error('Error initializing the Analytics service: ', e);
  }
}


/**
 * Logs an event to the initialized analytics services in #initAnalytics
 * The full list of events and attributes can be found in the holocom client wiki
 *
 * The function is async to avoid blocking the UI in case something goes wrong
 *
 * @param eventName The name of the event.
 * @param eventParams Custom dimension/metric associated with the event. It is just an optional object with kv pairs.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function logEvent(eventName: string, eventParams?: { [key: string]: any } | undefined) {
  try {
    logFirebaseEvent(eventName, eventParams);
  } catch (e) {
    const logger = getLogger('Analytics');
    logger.error('Error logging the Event: ', eventName, ' with params: ', eventParams, e);
  }
}
