import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core';

import { iconColors } from '../../../colors';
import {
  saveBackgroundBlurCustomBackgroundConfig,
  saveBackgroundBlurEnabledConfig,
  saveBackgroundBlurValueConfig
} from '../../../lib/actions/settings';
import { State } from '../../../lib/reducers';
import { BlurValue, BlurCustomValue } from '../../../lib/utils/pipelineDefault';
import LocalStorage from '../../../localStorage';
import HideOnMouseOut from '../../HideOnMouseOut';
import { IconAcknowledgeState, IconTrash } from '../../IconSet';
import BackgroundItem from '../BackgroundItem';

import InputComponent from './InputComponent';
import { InputComponentType } from './utils';


const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: theme.spacing(1),
  },
  hideActions: {
    display: 'none'
  },
  icons: {
    padding: theme.spacing(1)
  },
}));


const messages = defineMessages({
  removeBackground: { id: 'backgroundBlurCustomRemove' },
  applyBackground: { id: 'backgroundBlurCustomApply' },
  editBackground: { id: 'backgroundBlurCustomEdit' },
});


function ItemActions(props: ActionProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { currentBgBlurValue, item, onClick, show, isButtonDisabled } = props;

  const handleClick = React.useCallback(
    () => onClick(item.value), [onClick, item.value]
  );

  const handleRemove = React.useCallback(
    () => {
      dispatch(saveBackgroundBlurCustomBackgroundConfig(undefined, new LocalStorage(), item.typeValue));
      // disable blur if removing current selected background
      if (currentBgBlurValue === item.value) {
        dispatch(saveBackgroundBlurValueConfig(0, new LocalStorage()));
        dispatch(saveBackgroundBlurEnabledConfig(false, new LocalStorage()));
      }
    }, [currentBgBlurValue, dispatch, item.value, item.typeValue]
  );

  const style = classNames(classes.actions, show ? null : classes.hideActions);

  if (isButtonDisabled) return null;

  return (
    <div className={style}>
      <Tooltip placement='top' title={formatMessage(messages.removeBackground)}>
        <IconButton className={classes.icons} onClick={handleRemove}>
          <IconTrash size={18} color={iconColors.contrast} />
        </IconButton>
      </Tooltip>
      <Tooltip placement='top' title={formatMessage(messages.applyBackground)}>
        <IconButton className={classes.icons} onClick={handleClick}>
          <IconAcknowledgeState size={18} color={iconColors.contrast} />
        </IconButton>
      </Tooltip>
      <InputComponent
        inputComponent={InputComponentType.ICON_EDIT}
        item={item}
        onAdd={handleClick}
        isButtonDisabled={false}
      />;
    </div>
  );
}


function BackgroundCustomItem(props: ExtendedProps) {
  const { customBackground, item, onClick, isButtonDisabled } = props;

  const handleClick = React.useCallback(
    () => onClick(item.value), [onClick, item.value]
  );

  const getBackgroundItem = (background: string) => {
    const customItem: BlurCustomValue = { ...item, image: background, type: 'image' };
    return (
      <React.Fragment>
        <HideOnMouseOut>
          <ItemActions {...props} />
        </HideOnMouseOut>
        <BackgroundItem item={customItem} isButtonDisabled={isButtonDisabled} />
      </React.Fragment>
    );
  };

  return customBackground
    ? getBackgroundItem(customBackground)
    : <InputComponent
      inputComponent={InputComponentType.ICON_BACKGROUND}
      item={item}
      isButtonDisabled={isButtonDisabled}
      onAdd={handleClick}
    />;
}


type Props = {
  item: BlurCustomValue;
  onClick: (value: BlurValue['value']) => void;
  isButtonDisabled: boolean;
}

type MappedProps = {
  customBackground: string | undefined;
  currentBgBlurValue: State['settings']['backgroundBlurValue'];
}

const mapStateToProps = (state: State, props: Props): MappedProps => {
  const value = props.item.typeValue;
  const background = state.settings[value] ? state.settings[value] : undefined;

  return {
    customBackground: background,
    currentBgBlurValue: state.settings.backgroundBlurValue,
  };
};

type ExtendedProps = Props & MappedProps
type ActionProps = ExtendedProps & { show?: boolean }


export default connect(mapStateToProps)(BackgroundCustomItem);
