import { State } from "../reducers";

/**
 * Returns true if own stream has not facingMode === 'environment'
 * 
 * @param state global state from reducers
 */
export function shouldMirrorVideo(state: State): boolean {
  return state.settings.videoFacingMode !== 'environment';
}
