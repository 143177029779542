import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import { RoomLayout, Urls } from '../redux_types';


export const APPCONFIG_SET = 'APPCONFIG_SET';

/**
 * Application Config sent by the server
 */
interface AppConfig {
  site_logo?: string | undefined;
  site_title: string;
  login_enabled: boolean;
  oauth: OAuthConfig | {};
  is_holocom: boolean;
  theme?: ThemeOptions;
  room_options?: RoomOptions;
  push_notifications: PushNotificationsConfig;
  urls: Urls;
}

export interface OAuthConfig {
  type: string;
  label: string;
}

export interface RoomOptions {
  // eslint-disable-next-line @typescript-eslint/camelcase
  join_muted?: boolean;
  // eslint-disable-next-line @typescript-eslint/camelcase
  stream_quality?: 'low' | 'high' | 'all';
  // eslint-disable-next-line @typescript-eslint/camelcase
  frame_rate?: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  ice_reconnect_retries?: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  ice_reconnect_retries_window?: number;
  // eslint-disable-next-line @typescript-eslint/camelcase
  allowed_layouts?: RoomLayout[];
  // eslint-disable-next-line @typescript-eslint/camelcase
  max_bitrate?: number;
}

export interface PushNotificationsConfig {
  vapid_public_key: string;
  website_push_id: string;
  web_service_url: string;
}

interface AppConfigSetAction {
  type: typeof APPCONFIG_SET;
  payload: {
    appconfig: AppConfig;
  };
}

export type Action = AppConfigSetAction


export function setAppConfig(appConfig: AppConfig): AppConfigSetAction {
  return {
    type: APPCONFIG_SET,
    payload: {
      appconfig: appConfig
    }
  };
}
