import { State } from '../reducers';
import { BlurTypeCustomValue } from '../utils/pipelineDefault';


export function getActualSelectedBlurValue(state: State) {
  switch (state.settings.backgroundBlurValue) {
    case BlurTypeCustomValue.CUSTOM_IMAGE_1:
    case BlurTypeCustomValue.CUSTOM_IMAGE_2:
    case BlurTypeCustomValue.CUSTOM_IMAGE_3:
      return state.settings[state.settings.backgroundBlurValue];
    default:
      return state.settings.backgroundBlurValue;
  }
}
