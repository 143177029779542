const isElectron =
  (
    typeof navigator === 'object'
    && typeof navigator.userAgent === 'string'
    && navigator.userAgent.indexOf('Electron') >= 0
  )
  || (
    typeof process !== 'undefined'
    && typeof process.versions === 'object'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    && !!(process.versions as any).electron
  )
  || (
    typeof window !== 'undefined'
    && typeof window.process === 'object'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    && (window.process as any).type === 'renderer'
  );


export default isElectron;
