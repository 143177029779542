import React from 'react';
import { isElectron } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';

import {
  enableDesktopControl,
  startControllingDesktop,
  stopControllingDesktop,
  sendMouseEvent,
  startDrawing,
  stopDrawing,
  toggleOwnAudioMute,
  stopScreenSharing,
} from '../../lib/actions/room';
import { newEvent, INFO } from '../../lib/notifications';
import { State } from '../../lib/reducers';
import { getMemoizedDeskControlledUser, getDrawingHasStarted } from '../../lib/reduxSelectors/room';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';

import { getRoster } from './reduxSelectors';


declare let window: ElectronWindow;

function getMouseType(type: string): string {
  switch (type) {
    case 'mousemove': return 'move';
    case 'mouseup': return 'mouseUp';
    case 'mousedown': return 'mouseDown';
    default: return type;
  }
}

function DesktopControl(props: ExtendedProps) {
  const {
    isControlledDesktopFullscreen,
    myUser,
    displayName,
    controlledUser,
    controlledScreen,
    roster,
    hasStartedDrawing,
    myUserEnabledDesktopControl,
    isAudioMuted,
  } = props;

  const dispatch = useDispatch();

  const me = React.useRef(myUser);
  const name = React.useRef(displayName);
  const drawingStarted = React.useRef(hasStartedDrawing);
  const isMyUserControlled = React.useRef(myUserEnabledDesktopControl);
  const muted = React.useRef(isAudioMuted);

  React.useEffect(() => {
    me.current = myUser;
  }
  , [myUser]);

  React.useEffect(() => {
    name.current = displayName;
  }
  , [displayName]);

  React.useEffect(() => {
    isMyUserControlled.current = myUserEnabledDesktopControl;

    if (!isElectron) { return; }

    if (myUserEnabledDesktopControl) {
      window.ipcRenderer.sendToHost('enableDesktopControl');
    } else {
      window.ipcRenderer.sendToHost('disableDesktopControl');
    }
  }
  , [myUserEnabledDesktopControl]);

  React.useEffect(() => {
    if (isElectron) {
      window.ipcRenderer.sendToHost('setRoster', roster);
    }
  }
  , [roster]);

  React.useEffect(() => {
    muted.current = isAudioMuted;

    if (isElectron) {
      window.ipcRenderer.sendToHost('audioMuted', isAudioMuted);
    }
  }
  , [isAudioMuted]);

  React.useEffect(() => {
    const isDesktopControlEnabled = Boolean(controlledScreen);

    const stopDesktopControl = () => {
      if (isElectron && isMyUserControlled.current && controlledUser) {
        dispatch(enableDesktopControl(controlledUser, false, ''));
      }

      stopControllingDesktop();
    };

    const startDesktopControl = () => {
      startControllingDesktop();
    };

    const requestFullscreen = () => {
      if (isMyUserControlled.current || drawingStarted.current) {
        return;
      }

      newEvent(INFO, 'needFullscreenForControl', 'not_used_reason', 'needFullscreenForControl');
    };

    const toggleMuteAudio = () => {
      if (isMyUserControlled.current) {
        dispatch(toggleOwnAudioMute(!muted.current));
      }
    };

    const stopSharingScreen = () => {
      if (isMyUserControlled.current) {
        dispatch(stopScreenSharing());
      }
    };

    if (isElectron && isDesktopControlEnabled) {
      window.ipcRenderer.on('disableDesktopControl', stopDesktopControl);
      window.ipcRenderer.on('toggleAudioMute', toggleMuteAudio);
      window.ipcRenderer.on('stopSharingScreen', stopSharingScreen);
    }

    if (isDesktopControlEnabled && !isControlledDesktopFullscreen) {
      requestFullscreen();
    } else if (isDesktopControlEnabled && isControlledDesktopFullscreen) {
      startDesktopControl();
    } else if (!isDesktopControlEnabled || !isControlledDesktopFullscreen) {
      stopDesktopControl();
    }

    return () => {
      if (isElectron) {
        window.ipcRenderer.removeListener('disableDesktopControl', stopDesktopControl);
        window.ipcRenderer.removeListener('toggleAudioMute', toggleMuteAudio);
        window.ipcRenderer.removeListener('stopSharingScreen', stopSharingScreen);
      }
    };
  }
  , [dispatch, controlledUser, controlledScreen, isControlledDesktopFullscreen]);

  React.useEffect(() => {
    drawingStarted.current = hasStartedDrawing;

    const startRemoteDraw = () => {
      dispatch(startDrawing());
    };

    const stopRemoteDraw = () => {
      dispatch(stopDrawing());
    };

    if (isElectron) {
      window.ipcRenderer.on('startDrawing', startRemoteDraw);
      window.ipcRenderer.on('stopDrawing', stopRemoteDraw);
    }

    if (hasStartedDrawing && !isControlledDesktopFullscreen && !isMyUserControlled.current) {
      newEvent(INFO, 'startDrawingBrowser', 'not_used_reason', 'startDrawingBrowser');
    }

    return () => {
      if (isElectron) {
        window.ipcRenderer.removeListener('startDrawing', startRemoteDraw);
        window.ipcRenderer.removeListener('stopDrawing', stopRemoteDraw);
      }
    };
  }
  , [dispatch, hasStartedDrawing, isControlledDesktopFullscreen]);

  React.useEffect(() => {
    const onMouseEvent = (ev: MouseEvent) => {
      const type = getMouseType(ev.type);
      const data = {
        clientX: ev.clientX,
        clientY: ev.clientY,
        canvasWidth: window.screen.width,
        canvasHeight: window.screen.height,
      };

      if (me.current && name.current) {
        dispatch(sendMouseEvent(me.current, name.current, type, data));
      }
    };

    const onKeyDown = (ev: KeyboardEvent) => {
      const data = {
        key: ev.key,
        keyCode: ev.keyCode,
        shift: ev.shiftKey,
        meta: ev.metaKey,
        control: ev.ctrlKey,
        alt: ev.altKey,
      };

      if (me.current && name.current) {
        dispatch(sendMouseEvent(me.current, name.current, 'keydown', data));
      }
    };

    if (isControlledDesktopFullscreen) {
      window.addEventListener('mousemove', onMouseEvent, false);
      window.addEventListener('mouseup', onMouseEvent, false);
      window.addEventListener('mousedown', onMouseEvent, false);
      window.addEventListener('keydown', onKeyDown, false);
    }

    return () => {
      window.removeEventListener('mousemove', onMouseEvent, false);
      window.removeEventListener('mouseup', onMouseEvent, false);
      window.removeEventListener('mousedown', onMouseEvent, false);
      window.removeEventListener('keydown', onKeyDown, false);
    };
  }
  , [dispatch, isControlledDesktopFullscreen]);

  React.useEffect(() => {
    return () => {
      if (isElectron) {
        window.ipcRenderer.sendToHost('disableDesktopControl');
      }
    };
  }
  , []);

  return null;
}


interface ElectronWindow extends Window {
  ipcRenderer: IPCRenderer;
}

type IPCRenderer = {
  on: Function;
  sendToHost: Function;
  removeListener: Function;
}

type MappedProps = {
  isControlledDesktopFullscreen: boolean;
  hasStartedDrawing: ReturnType<typeof getDrawingHasStarted>;
  myUserEnabledDesktopControl: boolean;
  controlledUser: string | null;
  controlledScreen: string | null;
  myUser: string | null;
  displayName: string | null;
  roster: object;
  isAudioMuted: boolean;
}


type ExtendedProps = {} & MappedProps;

const mapStateToProps = (state: State): MappedProps => {
  const myUser = getWsUserId(state.websocket);
  const controlledUser = getMemoizedDeskControlledUser(state);

  let controlledScreen = null;
  let isControlledDesktopFullscreen = false;
  let displayName = null;
  let myUserEnabledDesktopControl = false;
  let isAudioMuted = false;

  if (myUser && state.room.roster[myUser]) {
    displayName = state.room.roster[myUser].display;
    myUserEnabledDesktopControl = state.room.roster[myUser].isDesktopControlEnabled;
    isAudioMuted = state.room.roster[myUser].isAudioMuted;
  }

  if (controlledUser && (state.room.roster[controlledUser].screen || state.room.screenStream)) {
    controlledScreen = `${controlledUser}_screen`;
    isControlledDesktopFullscreen = controlledScreen === state.room.fullScreenEnabled;
  }

  return {
    isControlledDesktopFullscreen: isControlledDesktopFullscreen,
    controlledUser: controlledUser,
    controlledScreen: controlledScreen,
    myUser: getWsUserId(state.websocket),
    displayName: displayName,
    roster: getRoster(state),
    hasStartedDrawing: getDrawingHasStarted(state),
    myUserEnabledDesktopControl: myUserEnabledDesktopControl,
    isAudioMuted: isAudioMuted,
  };
};


export default connect(mapStateToProps)(DesktopControl);
