enum NotificationLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

const getNotificationLevel = (level: string) => {
  switch (level) {
    case NotificationLevel.INFO:
      return 'info';
    case NotificationLevel.WARNING:
      return 'warning';
    case NotificationLevel.ERROR:
      return 'error';
    default:
      return 'info';
  }
};


export { getNotificationLevel, NotificationLevel };
