import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { expandStreamVideo } from '../../../lib/actions/room';
import { logEvent, Event } from '../../../lib/analytics';
import { State } from '../../../lib/reducers';
import { RoomLayoutStreamType } from '../../../lib/redux_types';
import { getSelectedStream } from '../../../lib/reduxSelectors/presentationLayout';
import { isFullscreenLayout, isPresentationLayout } from '../../../lib/reduxSelectors/room';
import useStyles from '../../../style/ControlButton';
import { IconMaximize } from '../../IconSet';


const messages = defineMessages({
  foregroundIconButtonTooltip: { id: 'foregroundIconButtonTooltip' },
});


function MoveVideoToMain(props: ExtendedProps) {
  const classes = useStyles();

  const { isPresentationOrFullscreenLayout, isMainVideo, iconSize = 20, mini, uid } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const layout = useSelector((state: State) => isFullscreenLayout(state)) ? 'fullscreen' : 'featured';

  const moveToMain = () => {
    let streamType: RoomLayoutStreamType = 'stream';
    if (uid.endsWith('_screen')) {
      streamType = 'screen';
    }
    logEvent(Event.SET_MAIN_VIDEO, { 'from': 'video_toolbar', 'from_layout': layout });
    dispatch(expandStreamVideo(uid.replace(/_screen$/, ''), streamType));
  };

  const cls = classNames(classes.controlButton, mini ? classes.controlButtonMini : null);

  if (isPresentationOrFullscreenLayout && !isMainVideo) {
    const msg = formatMessage(messages.foregroundIconButtonTooltip);
    return (
      <Tooltip placement="top" title={msg}>
        <div>
          <IconButton
            onClick={moveToMain}
            className={cls}
            size='small'
          >
            <IconMaximize size={iconSize} />
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  return null;
}


type Props = {
  uid: string;
  iconSize?: number;
  mini?: boolean;
}


type MappedProps = {
  isMainVideo: boolean;
  isPresentationOrFullscreenLayout: boolean;
}


type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, { uid }: Props): MappedProps => {
  const selectedStream = getSelectedStream(state);

  return {
    isMainVideo: uid === selectedStream,
    isPresentationOrFullscreenLayout: (isFullscreenLayout(state) || isPresentationLayout(state)),
  };
};


export default connect(mapStateToProps)(MoveVideoToMain);
