declare global {
  // HTMLCanvasElement.captureStream is marked as experimental
  interface HTMLCanvasElement {
    captureStream(): MediaStream;
  }
  // Missing attribute https://github.com/microsoft/TypeScript/issues/36204
  interface HTMLVideoElement {
    playsInline: boolean;
  }
}

export type Frame = HTMLVideoElement | HTMLCanvasElement | HTMLImageElement;

export class StreamAdapter {
  protected callback: () => void;

  constructor() {
    this.callback = () => {};
  }

  public onSetShape(callback: () => void) {
    this.callback = callback;
  }

  public setShape(_: [number, number]): void {
    throw new Error(this.constructor.name + ': Not Implemented');
  }

  public getShape(): Promise<[number, number]> {
    throw new Error(this.constructor.name + ': Not Implemented');
  }

  public getStream(): MediaStream {
    throw new Error(this.constructor.name + ': Not Implemented');
  }

  public getFrame(): Promise<Frame> {
    throw new Error(this.constructor.name + ': Not Implemented');
  }

  public get2DContext(): CanvasRenderingContext2D {
    throw new Error(this.constructor.name + ': Not Implemented');
  }

  public getGLContext(): WebGLRenderingContext {
    throw new Error(this.constructor.name + ': Not Implemented');
  }

  public stop(): void {
    // do nothing
  }
}

export class VideoStreamAdapter extends StreamAdapter {
  private stream: MediaStream;
  private video: Promise<HTMLVideoElement>;
  private shape: [number, number];

  constructor(
    stream: MediaStream,
  ) {
    super();
    this.stream = stream;
    this.shape = [640, 480];
    const video = document.createElement('video');
    this.video = new Promise(resolve => {
      video.addEventListener('canplaythrough', _ => {
        this.shape = [video.videoWidth, video.videoHeight];
        video.addEventListener('resize', _ => {
          if (this.shape[0] !== video.videoWidth || this.shape[1] !== video.videoHeight) {
            this.shape = [video.videoWidth, video.videoHeight];
            this.callback();
          }
        });
        video.play().then(() => resolve(video));
      });
    });
    video.muted = true;
    video.autoplay = true;
    video.playsInline = true;
    video.srcObject = stream;
  }

  public getStream(): MediaStream {
    return this.stream;
  }

  public getShape(): Promise<[number, number]> {
    return this.video.then(() => this.shape);
  }

  public getFrame(): Promise<Frame> {
    return this.video;
  }

  public stop(): void {
    this.video.then(video => {
      video.pause();
      video.srcObject = null;
    });
    this.stream.getTracks().forEach(t => t.stop());
  }
}

export class CanvasStreamAdapter extends StreamAdapter {
  private shape: [number, number];
  private canvas: HTMLCanvasElement;
  private hasContext: boolean;

  constructor() {
    super();
    this.canvas = document.createElement('canvas');
    this.shape = [0, 0];
    this.canvas.width = this.shape[0];
    this.canvas.height = this.shape[1];
    this.hasContext = false;
  }

  public setShape(shape: [number, number]) {
    this.shape = shape;
    this.canvas.width = shape[0];
    this.canvas.height = shape[1];
    this.callback();
  }

  public getShape(): Promise<[number, number]> {
    return new Promise(resolve => resolve(this.shape));
  }

  public getStream(): MediaStream {
    if (!this.hasContext) {
      // captureStream will fail on safari if canvas has no context
      this.get2DContext();
    }
    return this.canvas.captureStream();
  }

  public getFrame(): Promise<Frame> {
    return new Promise(resolve => resolve(this.canvas));
  }

  public getGLContext(): WebGLRenderingContext {
    const gl = this.canvas.getContext("webgl", { premultipliedAlpha: false });
    if (!gl) throw new Error("Could not create WebGL context");
    return gl;
  }

  public get2DContext(): CanvasRenderingContext2D {
    const ctx = this.canvas.getContext("2d");
    if (!ctx) throw new Error("Could not create 2D context");
    return ctx;
  }

  public stop(): void {
    this.canvas.width = 0;
    this.canvas.height = 1;
  }
}

export class ImageStreamAdapter extends StreamAdapter {
  private image: Promise<HTMLImageElement>;

  constructor(src: string) {
    super();
    this.image = new Promise((resolve, reject) => {
      const image = document.createElement("img");
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', () => reject("Could not load " + src));
      image.src = src;
    });
  }

  public getShape(): Promise<[number, number]> {
    return this.image.then(image => {
      return [image.naturalWidth, image.naturalHeight];
    });
  }

  public getFrame(): Promise<Frame> {
    return this.image;
  }
}


export function constructInputStreamAdapter(stream: MediaStream): StreamAdapter {
  return new VideoStreamAdapter(stream);
}

export function constructPipeStreamAdapter(): StreamAdapter {
  return new CanvasStreamAdapter();
}

export function constructImageStreamAdapter(src: string): StreamAdapter {
  return new ImageStreamAdapter(src);
}
