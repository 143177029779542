import { State } from '../../lib/reducers';
import { createDeepEqualitySelector } from '../../lib/reduxSelectors';
import { isRemoteVideoStarting } from '../../lib/reduxSelectors/room';
import { ObservedUser } from '../../lib/reduxSelectors/roster';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';

import { getRoster, MeetingObservedRoster } from './reduxSelectors';


type Video = {
  stream: ObservedUser['stream'];
  displayName: ObservedUser['display'];
  viaPhone: ObservedUser['viaPhone'];
  isVideoStarting: boolean;
};
type Videos = { [k: string]: Video };


function getUserID(state: State) {
  return getWsUserId(state.websocket);
}


export const getStreams = createDeepEqualitySelector([getRoster, getUserID],
  (roster: MeetingObservedRoster, myself: string | null): Videos => {
    const videos: Videos = {};
    if (!myself) {
      return videos;
    }
    const keys = Object.keys(roster);
    for (const user of keys) {
      if (user === myself) {
        continue;
      }
      const isVideoStarting = isRemoteVideoStarting(roster, user);
      const remoteVideo = roster[user].stream;
      if (isVideoStarting || remoteVideo) {
        videos[user] = {
          stream: remoteVideo,
          displayName: roster[user].display,
          viaPhone: roster[user].viaPhone,
          isVideoStarting: isVideoStarting,
        } as Video;
      }
      const v = roster[user].screen;
      if (v) {
        videos[user + '_screen'] = {
          stream: v,
          displayName: roster[user].display,
          viaPhone: false,
          isVideoStarting: false, // TODO: remove when we have isRemoteScreenStarting
        } as Video;
      }
    }
    return videos;
  });
