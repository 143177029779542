import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { State as RoomState } from '../../lib/reducers/room';
import { WebSocketReady, WebSocketState as WebsocketState } from '../../lib/reducers/websocket';
import { getWsMeetingDetails, getWsUserId } from '../../lib/reduxSelectors/websocket';
import { getRoomNameFromParams } from '../../lib/utils/router';
import TabTitle from '../../tabTitle';


const messages = defineMessages({
  meetingHeader: { id: 'meetingHeader' },
});


function onMount(meetingTitle: string, isOwnAudioMuted: boolean) {
  TabTitle.setTabTitle(meetingTitle);
  isOwnAudioMuted ? TabTitle.addMuteMarker() : TabTitle.removeMuteMarker();

  return () => {
    TabTitle.resetTabTitle();
  };
}


function MeetingTitle(props: ExtendedProps) {
  const { meetingDetails, isOwnAudioMuted } = props;
  const { formatMessage } = useIntl();

  const meetingId = getRoomNameFromParams(props.match.params);
  const title = (meetingDetails && meetingDetails.title) ? meetingDetails.title : meetingId;
  const meetingTitle = `${formatMessage(messages.meetingHeader)}: ${title}`;

  React.useEffect(() => onMount(meetingTitle, isOwnAudioMuted), [meetingTitle, isOwnAudioMuted]);

  return null;
}


type State = {
  room: RoomState;
  websocket: WebsocketState;
}


type MappedProps = {
  meetingDetails: WebSocketReady['meeting_details'];
  isOwnAudioMuted: boolean;
}


type ExtendedProps = {} & MappedProps & RouteComponentProps<{id: string}>;

const mapStateToProps = (state: State): MappedProps => {
  const myUserId = getWsUserId(state.websocket);
  let isOwnAudioMuted = false;
  if (myUserId) {
    isOwnAudioMuted = (state.room.roster[myUserId] || { isAudioMuted: false }).isAudioMuted;
  }
  return {
    // eslint-disable-next-line @typescript-eslint/camelcase
    meetingDetails: getWsMeetingDetails(state.websocket),
    isOwnAudioMuted: isOwnAudioMuted,
  };
};


export default withRouter(connect(mapStateToProps)(MeetingTitle));
