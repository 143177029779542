import { useDispatch } from "react-redux";

import { logEvent, Event } from '../../lib/analytics';
import { setSelectedPane } from "../actions/room";
import { PaneType } from "../redux_types";


export function setSelectedPaneHelper(
  wantedPane: PaneType,
  currentPane: PaneType,
  from: string,
  dispatch: ReturnType<typeof useDispatch>
) {
  if (currentPane === PaneType.None || currentPane !== wantedPane) {
    // user clicked on another pane: open it
    _logEventHelper(wantedPane, true, from);
    dispatch(setSelectedPane(wantedPane));
  } else {
    // user clicked on the currently opened pane: close it
    _logEventHelper(wantedPane, false, from);
    dispatch(setSelectedPane(PaneType.None));
  }
}

function _logEventHelper(wantedPane: PaneType, open: boolean, from: string) {
  const getEvent = (wantedPane: PaneType): string | null => {
    switch (wantedPane) {
      case PaneType.Roster: return Event.OPEN_ROSTER;
      case PaneType.Chat: return Event.OPEN_CHAT;
      case PaneType.Info: return Event.MEETING_INFO;
      default: return null;
    }
  };
  const event = getEvent(wantedPane);
  if (event) {
    const action = open ? 'open' : 'close';
    logEvent(event, { 'action': action, 'from': from });
  }
}
