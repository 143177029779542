import { graphql } from 'babel-plugin-relay/macro';

import { minimalSessionQueryResponse } from './__generated__/minimalSessionQuery.graphql';


export const minimalSessionRequest = graphql`
query minimalSessionQuery{
  loggedUser{
    session{
      realm
      username
    }
  }
}
`;

export function getSession(response: minimalSessionQueryResponse) {
  return response['loggedUser']['session'];
}
