import React, { KeyboardEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useRelayEnvironment } from 'relay-hooks';

import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { logEvent, Event } from '../../lib/analytics';
import { searchContacts, getContacts } from '../../lib/api/relay/searchContact';
import useDebounce from '../../lib/utils/debounce';
import { IconSend } from '../IconSet';

import EmailContainer from './EmailContainer';
import FileSelector from './FileSelector';

import useStyles from './style';


const messages = defineMessages({
  scheduleMeetingAttendeesPlaceHolder: { id: 'scheduleMeetingAttendeesPlaceHolder' },
  attendeesUploadedInvalidFound: { id: 'attendeesUploadedInvalidFound' },
  attendeesUploadedInvalidFoundMore: { id: 'attendeesUploadedInvalidFoundMore' },
});


type Props = {
  autoFocus?: boolean;
  textFieldLabel?: string;
  items: Array<string>;
  onAdd: (value: string) => void;
  onAddAll: (values: string[]) => void;
  onRemove: (index: number) => void;
  validator: (value: string) => boolean;
  invalidMessage?: string;
}


function ListAdder(props: Props) {
  const { onAdd, onAddAll, items } = props;
  const [ text, setText ] = React.useState<string>('');
  const [ invalidText, setInvalidText ] = React.useState<boolean>(false);
  const [ invalidFileMessage, setInvalidFileMessage ] = React.useState<string>('');
  const [ options, setOptions ] = React.useState<string[]>([]);
  const debouncedText = useDebounce(text, 200);
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const autoFocus = props.autoFocus || false;

  const relayEnvironment = useRelayEnvironment();

  React.useEffect(
    () => {
      if (debouncedText) {
        searchContacts(relayEnvironment, debouncedText)
          .then((data) => {
            const contacts = getContacts(data);
            if (contacts) {
              // remove emails already added
              const filtered = contacts.filter((u) => !items.includes(u));
              setOptions(filtered);
            }
          });
      } else {
        setOptions([]);
      }
    }, [debouncedText, relayEnvironment, setOptions, items]
  );

  const handleAdd = React.useCallback(
    () => {
      const value = text;
      if (value && !invalidText) {
        onAdd(value);
        setText('');
        setInvalidText(false);
      }
    }, [setText, setInvalidText, onAdd, invalidText, text]
  );

  const handleRemove = (index: number) => {
    props.onRemove(index);
  };

  const handleKeyUp = React.useCallback(
    (ev: KeyboardEvent<HTMLInputElement>) => {
      if ((ev.key === 'Enter') && text) {
        handleAdd();
      }
    }, [handleAdd, text]
  );

  const onInputChange = (_event: object, value: string, _reason: string) => {
    setText(value);
    setInvalidText(props.validator ? !props.validator(value) : false);
    setInvalidFileMessage('');
  };

  const getInputAdornment = React.useCallback(
    () => {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={handleAdd}
          >
            <IconSend size={24} />
          </IconButton>
        </InputAdornment>
      );
    }, [handleAdd]
  );

  const renderInput = React.useCallback(
    (params) => {
      return (
        <TextField
          {...params}
          onKeyUp={handleKeyUp}
          fullWidth
          autoFocus={autoFocus}
          error={(invalidText && !!props.invalidMessage)}
          placeholder={formatMessage(messages.scheduleMeetingAttendeesPlaceHolder)}
          inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                { getInputAdornment() }
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      );
    }, [getInputAdornment, handleKeyUp, autoFocus, invalidText, props.invalidMessage, formatMessage]
  );

  const onAddList = (list: Array<string>) => {
    const filteredList = list.filter((e) => !items.includes(e));
    const invalidEmailList: Array<string> = [];
    filteredList.forEach((e) => {
      if (!e || !props.validator(e)) {
        invalidEmailList.push(' ' + e);
      }
    });
    const length = invalidEmailList.length;
    if (length === 0) {
      setInvalidFileMessage('');
      onAddAll(filteredList);
      logEvent(Event.UPLOAD_EMAILS);
    } else {
      const threshold = 3;
      let message = '';
      if (length <= threshold) {
        message = formatMessage(
          messages.attendeesUploadedInvalidFound,
          { list: invalidEmailList.toString() }
        );
      } else {
        const more = length - threshold;
        const errorMessage = formatMessage(
          messages.attendeesUploadedInvalidFound,
          { list: invalidEmailList.slice(0, threshold).toString() }
        );
        const errorInfo = formatMessage(
          messages.attendeesUploadedInvalidFoundMore,
          { number: more }
        );
        message = errorMessage + errorInfo;
      }
      setInvalidFileMessage(message);
    }
  };


  return (
    <div className={classes.container}>
      <FormControl error={invalidText || invalidFileMessage.length !== 0}>
        <InputLabel>{props.textFieldLabel}</InputLabel>
        <div className={classes.inputWrapper}>
          <Autocomplete
            options={options}
            className={classes.autocomplete}
            autoComplete
            autoHighlight
            freeSolo
            size='small'
            onInputChange={onInputChange}
            noOptionsText={''}
            value={text}
            disableClearable={true}
            renderInput={renderInput}
          />
          <FileSelector onAddList={onAddList} />
        </div>
        {(invalidText && props.invalidMessage)
          ? <FormHelperText>{props.invalidMessage}</FormHelperText>
          : null
        }
        {invalidFileMessage.length !== 0
          ? <FormHelperText>{invalidFileMessage}</FormHelperText>
          : null
        }
      </FormControl>
      { (items.length > 0) &&
        <div className={classes.list}>
          <EmailContainer
            handleRemove={handleRemove}
            list={items}
          />
        </div>
      }
    </div>
  );
}


export default ListAdder;
