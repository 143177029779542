import React, { Component } from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { logEvent, Event } from '../../lib/analytics';
import getRouteFor, { Route } from '../../lib/utils/router';
import ClosableDialog from '../ClosableDialog';


const messages = defineMessages({
  title: { id: 'joinUsingInvite' },
  joinButton: { id: 'joinButton' },
  insertMeetingCodeHere: { id: 'insertMeetingCodeHere' },
});


type Props = {
  onClose: () => void;
};


const styles = (theme: Theme) =>
  createStyles({
    input: {
      paddingTop: theme.spacing(2),
    },
  });


type ExtendedProps = Props
  & WithStyles<typeof styles>
  & {intl: IntlShape}
  & RouteComponentProps;


type State = {
  meetingId: string | null;
}


class EnterMeetingDialog extends Component<ExtendedProps, State> {
  constructor(props: ExtendedProps) {
    super(props);
    this.state = {
      meetingId: null
    };
  }

  handleMeetingIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      meetingId: ev.target.value,
    });
  }

  handleMeetingIdKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      ev.stopPropagation();
      this.gotoMeeting();
    }
  }

  gotoMeeting = () => {
    if (this.state.meetingId) {
      const path = getRouteFor(Route.Meeting, { slug: this.state.meetingId });
      this.props.history.push(path);
      logEvent(Event.JOIN_WITH_CODE, { 'join_as': 'user' } );
    }
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <ClosableDialog
        open={true}
        title={this.props.intl.formatMessage(messages.title)}
        onClose={this.props.onClose}
      >
        <DialogContent>
          <TextField
            inputProps={{ autoCapitalize: 'off' }}
            autoFocus={true}
            fullWidth
            className={classes.input}
            placeholder={intl.formatMessage(messages.insertMeetingCodeHere)}
            onChange={this.handleMeetingIdChange}
            onKeyUp={this.handleMeetingIdKeyUp}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.gotoMeeting}
            disabled={!this.state.meetingId}
          >
            {intl.formatMessage(messages.joinButton)}
          </Button>
        </DialogActions>
      </ClosableDialog>
    );
  }
}


export default injectIntl(withRouter(withStyles(styles)(EnterMeetingDialog)));
