import React from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  lockRoom,
  toggleOwnAudioMute,
  togglePublishVideo,
  toggleRaiseHand
} from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { PaneType } from '../../lib/redux_types';
import { amModerator } from '../../lib/reduxSelectors/room';
import { disableRoster } from '../../lib/reduxSelectors/roster';
import {
  amRequestingRaiseHand,
  canRaiseHand,
  canToggleAudio,
  canToggleVideo,
  haveRaisedHand,
  isOwnAudioUnmuted,
  isOwnVideoMuted
} from '../../lib/reduxSelectors/user';
import { setSelectedPaneHelper } from '../../lib/utils/pane';


function isTextArea(ev: KeyboardEvent) {
  const target = ev.target as HTMLInputElement;
  const targetType = target.type;
  return (targetType === 'text' || targetType === 'textarea');
}


function ShortcutKeys(props: ExtendedProps) {
  const {
    canToggleAudio,
    isOwnAudioMuted,
    canToggleVideo,
    isOwnVideoMuted,
    canRaiseHand,
    haveRaisedHand,
    amRequestingRaiseHand,
    canLockRoom,
    isRoomLocked,
    isRequestingLockRoom,
    selectedPane,
    canOpenRoster
  } = props;

  const dispatch = useDispatch();

  // track some props manually to avoid the dependency in the useEffect()
  // below (we need to install and remove listeners only on mount/unmount, not
  // every time the prop changes
  const canToggleAudioRef = React.useRef(canToggleAudio);
  const isMicMutedRef = React.useRef(isOwnAudioMuted);
  const canToggleVideoRef = React.useRef(canToggleVideo);
  const isVideoMutedRef = React.useRef(isOwnVideoMuted);
  const canToggleRaisedHandRef = React.useRef(canRaiseHand);
  const isHandRaisedRef = React.useRef(haveRaisedHand);
  const isRequestingRaiseHandRef = React.useRef(amRequestingRaiseHand);
  const canLockRoomRef = React.useRef(canLockRoom);
  const isRoomLockedRef = React.useRef(isRoomLocked);
  const isRequestingLockRoomRef = React.useRef(isRequestingLockRoom);
  const selectedPaneRef = React.useRef(selectedPane);
  const canOpenRosterRef = React.useRef(canOpenRoster);

  React.useEffect(
    () => {
      canToggleAudioRef.current = canToggleAudio;
      isMicMutedRef.current = isOwnAudioMuted;
      canToggleVideoRef.current = canToggleVideo;
      isVideoMutedRef.current = isOwnVideoMuted;
      canToggleRaisedHandRef.current = canRaiseHand;
      isHandRaisedRef.current = haveRaisedHand;
      isRequestingRaiseHandRef.current = amRequestingRaiseHand;
      canLockRoomRef.current = canLockRoom;
      isRoomLockedRef.current = isRoomLocked;
      isRequestingLockRoomRef.current = isRequestingLockRoom;
      selectedPaneRef.current = selectedPane;
      canOpenRosterRef.current = canOpenRoster;
    }, [
      canToggleAudio,
      isOwnAudioMuted,
      canToggleVideo,
      isOwnVideoMuted,
      canRaiseHand,
      haveRaisedHand,
      amRequestingRaiseHand,
      canLockRoom,
      isRoomLocked,
      isRequestingLockRoom,
      selectedPane,
      canOpenRoster,
    ]
  );

  const handleMuteMicAction = React.useCallback(
    () => {
      if (canToggleAudioRef.current) {
        const action = isMicMutedRef.current ? 'unmute' : 'mute';
        logEvent(Event.MUTE_MY_MICROPHONE, { 'action': action, 'from': 'shortcut_key' });
        dispatch(toggleOwnAudioMute(!isMicMutedRef.current));
      }
    }, [dispatch]
  );

  const handleMuteVideoAction = React.useCallback(
    () => {
      if (canToggleVideoRef.current) {
        const action = isVideoMutedRef.current ? 'unmute' : 'mute';
        logEvent(Event.MUTE_MY_VIDEO, { 'action': action, 'from': 'shortcut_key' });
        dispatch(togglePublishVideo(!isVideoMutedRef.current));
      }
    }, [dispatch]
  );

  const handleRaiseHandAction = React.useCallback(
    () => {
      if (canToggleRaisedHandRef.current && !isRequestingRaiseHandRef.current) {
        const action = isHandRaisedRef.current ? 'lower' : 'raise';
        logEvent(Event.RAISE_HAND, { 'action': action, 'from': 'shortcut_key' });
        dispatch(toggleRaiseHand());
      }
    }, [dispatch]
  );

  const handleLockRoomAction = React.useCallback(
    () => {
      if (canLockRoomRef.current && !isRequestingLockRoomRef.current) {
        const action = isRoomLockedRef.current ? 'unlock' : 'lock';
        logEvent(Event.LOCK_ROOM, { 'action': action, 'from': 'shortcut_key' });
        dispatch(lockRoom());
      }
    }, [dispatch]
  );

  const handleOpenRosterAction = React.useCallback(
    () => {
      if (canOpenRosterRef.current) {
        setSelectedPaneHelper(PaneType.Roster, selectedPaneRef.current, 'shortcut_key', dispatch);
      }
    }, [dispatch]
  );

  const handleOpenChatAction = React.useCallback(
    () => {
      setSelectedPaneHelper(PaneType.Chat, selectedPaneRef.current, 'shortcut_key', dispatch);
    }, [dispatch]
  );

  React.useEffect(() => {
    const onKeyUp = (ev: KeyboardEvent) => {
      if (!isTextArea(ev)) {
        switch (ev.key.toLowerCase()) {
          case 'm':
            handleMuteMicAction();
            break;
          case 'v':
            handleMuteVideoAction();
            break;
          case 'h':
            handleRaiseHandAction();
            break;
          case 'l':
            if (ev.shiftKey) handleLockRoomAction();
            break;
          case 'r':
            handleOpenRosterAction();
            break;
          case 'c':
            handleOpenChatAction();
            break;
          default:
            break;
        }
      }
    };
    window.addEventListener('keyup', onKeyUp, false);
    return () => {
      window.removeEventListener('keyup', onKeyUp, false);
    };
  }, [
    dispatch,
    handleMuteMicAction,
    handleMuteVideoAction,
    handleRaiseHandAction,
    handleLockRoomAction,
    handleOpenRosterAction,
    handleOpenChatAction
  ]);

  return null;
}


type MappedProps = {
  canToggleAudio: boolean;
  isOwnAudioMuted: boolean;
  canToggleVideo: boolean;
  isOwnVideoMuted: boolean;
  canRaiseHand: boolean;
  haveRaisedHand: boolean;
  amRequestingRaiseHand: boolean;
  canLockRoom: boolean;
  isRoomLocked: boolean;
  isRequestingLockRoom: boolean;
  selectedPane: PaneType;
  canOpenRoster: boolean;
}


type ExtendedProps = {} & MappedProps;

const mapStateToProps = (state: State): MappedProps => {
  return {
    canToggleAudio: canToggleAudio(state),
    isOwnAudioMuted: !isOwnAudioUnmuted(state),
    canToggleVideo: canToggleVideo(state),
    isOwnVideoMuted: isOwnVideoMuted(state),
    canRaiseHand: canRaiseHand(state),
    haveRaisedHand: haveRaisedHand(state),
    amRequestingRaiseHand: amRequestingRaiseHand(state),
    canLockRoom: amModerator(state),
    isRoomLocked: state.room.isLocked,
    isRequestingLockRoom: state.room.isRequestingLockRoom,
    selectedPane: state.room.selectedPane,
    canOpenRoster: !disableRoster(state),
  };
};


export default connect(mapStateToProps)(ShortcutKeys);
