import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { Tab, Typography, Tooltip, Badge } from '@material-ui/core';
import List from '@material-ui/core/List';

import { resetUnreadPrivateUserMessageThread } from '../../lib/actions/room';
import { State } from '../../lib/reducers';
import { PrivateUserMessageData } from '../../lib/redux_types';
import { isChatOpen } from '../../lib/reduxSelectors/room';

import Input from './ChatInput';
import Messages from './ChatMessages';
import ShareFileInput from './ShareFileInput';
import { AggregateChatMessage, makeGetChatMessages } from './utils';

import useStyles from './chatStyles';


const messages = defineMessages({
  userOffline: { id: 'userOffline' },
});


function PrivateChatLabel(props: ExtendedProps & { dispatch: ReturnType<typeof useDispatch> }) {
  const {
    index,
    currVal,
    user,
    chatMessages,
    unreadMessages,
    isOnline, // unused but required to avoid it getting into others
    myUsername,
    isChatOpen,
    dispatch,
    ...others
  } = props;

  const classes = useStyles();
  const isSelected = index === currVal;

  React.useEffect(
    () => {
      if (isSelected && isChatOpen) {
        dispatch(resetUnreadPrivateUserMessageThread(user));
      }
    }
  );

  const getTabLabel = (chatMessages: MappedProps['chatMessages'], mySelf: MappedProps['myUsername']) => {
    const message = chatMessages[0].messages[0].data as PrivateUserMessageData;
    const label = message.from === mySelf ? message.toDisplayName : message.fromDisplayName;
    return label;
  };

  const name = getTabLabel(chatMessages, myUsername);

  const getLabel = () => {
    return (
      <Badge
        badgeContent={unreadMessages}
        overlap='circle'
        variant='dot'
        color="secondary">
        <Tooltip title={name}>
          <Typography noWrap variant='caption' className={classes.label}>
            {name}
          </Typography>
        </Tooltip>
      </Badge>
    );
  };

  return (
    <Tab className={classes.tab} label={getLabel()} value={index} {...others} />
  );
}


function PrivateChat(props: ExtendedProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { chatMessages, currVal, index, user, isOnline } = props;

  const getOffline = () => {
    return (
      <Typography variant='caption' className={classes.offline}>
        {formatMessage(messages.userOffline)}
      </Typography>
    );
  };

  const getInput = () => {
    return (
      <div className={classes.inputContainer}>
        <Input to={user} />
        <ShareFileInput to={user} />
      </div>
    );
  };

  return (
    <React.Fragment>
      {(index === currVal) &&
        <React.Fragment>
          <List className={classes.messageList}>
            <Messages messages={chatMessages} />
          </List>
          {isOnline ? getInput() : getOffline()}
        </React.Fragment>
      }
    </React.Fragment>
  );
}


type Props = {
  index: number;
  currVal: number;
  user: string;
}

type MappedProps = {
  chatMessages: AggregateChatMessage[];
  unreadMessages: number;
  isOnline: boolean;
  myUsername: string | null;
  isChatOpen: boolean;
}

type ExtendedProps = Props & MappedProps

const makeMapStateToProps = () => {
  const getChatMessages = makeGetChatMessages();
  const mapStateToProps = (state: State, props: Props): MappedProps => {
    const chatMessages = getChatMessages(state.room.privateMessages[props.user]);
    const unreadMessages = state.room.unreadPrivateMessages[props.user];
    const isOnline = !!Object.entries(state.room.roster)
      .find((k) => k[1].username === props.user);
    const myUsername = state.session.username;
    const isChatOpened = isChatOpen(state);
    return { chatMessages, unreadMessages, isOnline, myUsername, isChatOpen: isChatOpened };
  };
  return mapStateToProps;
};


const PrivateChatTabLabel = connect(makeMapStateToProps)(PrivateChatLabel);
const PrivateChatTab = connect(makeMapStateToProps)(PrivateChat);


export { PrivateChatTabLabel, PrivateChatTab };
