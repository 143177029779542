import moment from 'moment';


function humanizeTime(elapsed, formatMessage, messages ) {
  const momentObject = moment.duration(elapsed, 'seconds');
  const days = momentObject.days();
  const hours = momentObject.hours();
  const minutes = momentObject.minutes();
  const seconds = momentObject.seconds();

  let string;

  if (elapsed >= 60 * 60 * 24) {
    string = formatMessage(messages.meetingVeryLongDuration, {
      time1: days, time2: hours, time3: minutes,
    });
  } else if (elapsed >= 60 * 60) {
    string = formatMessage(messages.meetingLongDuration, {
      time1: hours, time2: minutes,
    });
  } else if (elapsed >= 60) {
    string = formatMessage(messages.meetingCommonDuration, {
      time1: minutes, time2: seconds
    });
  } else {
    string = formatMessage(messages.meetingBriefDuration, { time: seconds });
  }

  return string;
}


function callWhen(condition, f, interval = 1000, maxTries = 3) {
  if (maxTries <= 0) {
    throw Error(`invalid argument ${maxTries} for maxTries argument`);
  }
  if (interval <= 0) {
    throw Error(`invalid argument ${interval} for interval argument`);
  }
  return _callWhen(condition, f, 0, interval, maxTries);
}

function _callWhen(condition, f, tryNum, interval, maxTries) {
  if (condition()) {
    return f();
  }
  else {
    if (tryNum < maxTries) {
      setTimeout(() => _callWhen(condition, f, tryNum + 1, interval, maxTries), interval);
    }
  }
}


export { humanizeTime, callWhen };
