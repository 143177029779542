import { isElectron } from 'react-device-detect';

import detectedBrowser from '../../detectBrowser';
import PiP from '../../pip';
import { VideoRoom } from '../api/videoroom';
import { State } from '../reducers';
import { RosterUser } from '../redux_types';

import {
  amModerator,
  amWebinarPresenter,
  getMemoizedDeskControlledUser,
  isAudioOnlyLayout,
  isThereAnyVideo,
  isWebinarLayout
} from './room';
import { isVideoEnabledForUser } from './videoRoom';
import { getWsUserId } from './websocket';


export function isKickEnabled(userId: string, state: State) {
  const user = state.room.roster[userId] || {};
  const myUserId = getWsUserId(state.websocket) || '';
  const myUser = state.room.roster[myUserId] || {};
  if (user.role === 'room_owner' || myUserId === userId) {
    return false;
  }
  if (myUser.role === 'room_owner' || myUser.role === 'room_moderator') {
    return true;
  }
  return false;
}

export function isPipEnabled(userId: string, state: State) {
  const myUserId = getWsUserId(state.websocket) || '';
  const fullscreenUser = state.room.fullScreenEnabled;
  const pipUser = state.room.pipEnabled;
  const hasVideo = isVideoEnabledForUser(userId, state);
  const isOwnVideoMuted = !VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream)
    || (state.room.roster[myUserId] || { isVideoMuted: false }).isVideoMuted;

  const isAudioOnly = isAudioOnlyLayout(state) || !isThereAnyVideo(state);

  if (!PiP.isAvailable()) {
    return false;
  }

  if (!hasVideo) {
    return false;
  }

  if (userId === myUserId) {
    return false;
  }

  if (fullscreenUser && fullscreenUser === userId) {
    // fullscreen enabled
    return false;
  }

  if (pipUser && pipUser !== userId) {
    // pip enabled on other video
    return false;
  }

  if (isOwnVideoMuted && userId === myUserId) {
    return false;
  }

  if (isAudioOnly) {
    return false;
  }

  const amInPrivateConf = (state.room.roster[myUserId] || { privateAudioConf: null }).privateAudioConf;
  const isInPrivateConf = (state.room.roster[userId] || { privateAudioConf: null }).privateAudioConf;
  const isInDifferentAudioConf = amInPrivateConf !== isInPrivateConf;

  if (isInDifferentAudioConf){
    return false;
  }

  return true;
}

export function canChangeRole(userId: string, state: State) {
  const user = state.room.roster[userId] || {};
  const myUserId = getWsUserId(state.websocket) || '';
  const myUser = state.room.roster[myUserId] || {};

  if (user.viaPhone || myUserId === userId) {
    return false;
  }

  if (user.role === 'room_owner') {
    return false;
  }

  if (myUser.role === 'room_owner' || myUser.role === 'room_moderator') {
    return true;
  }
  return false;
}

export function canFullscreen(userId: string, state: State) {
  const myUserId = getWsUserId(state.websocket) || '';
  const fullscreenUser = state.room.fullScreenEnabled;
  const hasVideo = isVideoEnabledForUser(userId, state);
  const pipUser = state.room.pipEnabled;
  const isOwnVideoMuted = !VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream)
    || (state.room.roster[myUserId] || { isVideoMuted: false }).isVideoMuted;

  const isAudioOnly = isAudioOnlyLayout(state) || !isThereAnyVideo(state);

  const os = ((detectedBrowser || { os: '' }).os || '').toLowerCase();
  if (os === 'ios') {
    return false;
  }

  if (!hasVideo) {
    return false;
  }

  if ((userId === myUserId) || (userId === `${myUserId}_screen`)) {
    // dont let fullscreen for yourself
    return false;
  }

  if (pipUser && pipUser === userId) {
    return false;
  }

  if (fullscreenUser && fullscreenUser !== userId) {
    return false;
  }

  if (isOwnVideoMuted && userId === myUserId) {
    return false;
  }

  if (isAudioOnly) {
    return false;
  }

  const amInPrivateConf = (state.room.roster[myUserId] || { privateAudioConf: null }).privateAudioConf;
  const isInPrivateConf = (state.room.roster[userId] || { privateAudioConf: null }).privateAudioConf;
  const isInDifferentAudioConf = amInPrivateConf !== isInPrivateConf;

  if (isInDifferentAudioConf){
    return false;
  }

  return true;
}

export function canRaiseHand(state: State): boolean {
  return isWebinarLayout(state) ? !amWebinarPresenter(state) : true;
}

export function haveRaisedHand(state: State): boolean {
  const me = getMyRosterEntry(state);
  return me ? me.raisedHand : false;
}

export function amRequestingRaiseHand(state: State): boolean {
  const me = getMyRosterEntry(state);
  return me ? me.isRequestingRaiseHand : false;
}

export function canInvite(state: State): boolean {
  return amModerator(state);
}

export function canToggleAudio(state: State): boolean {
  return state.room.mediaPermissions.canPublishAudio || isOwnAudioUnmuted(state);
}

export function isOwnAudioUnmuted(state: State): boolean {
  const me = getMyRosterEntry(state);
  return me ? !me.isAudioMuted : false;
}

export function haveAudioInputTrack(state: State): boolean {
  return Boolean(VideoRoom.getAudioTrackFromStream(state.room.localvideo_stream));
}


export function canToggleVideo(state: State) {
  return state.room.mediaPermissions.canPublishVideo && haveVideoStream(state);
}

export function haveVideoStream(state: State): boolean {
  return (state.room && state.room.localvideo_stream)
    ? Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream))
    : false;
}

export function isOwnVideoMuted(state: State): boolean {
  const me = getMyRosterEntry(state);
  return me ? me.isVideoMuted : false;
}

export function getMyRosterEntry(state: State): RosterUser | null {
  const myUser = getWsUserId(state.websocket);
  return myUser ? state.room.roster[myUser] : null;
}

export function canEnableDesktopControl(state: State): boolean {
  const controlledUser = getMemoizedDeskControlledUser(state);
  const someoneHasDesktopControl = controlledUser !== null && controlledUser !== getWsUserId(state.websocket);
  return isElectron && state.room.screenSourceType === 'screen' && someoneHasDesktopControl;
}

/**
 * @returns the number of extra controls in the own controls more menu
 */
function numberOfExtraControls(state: State): number {
  return [
    canRaiseHand(state),
    canInvite(state),
    canEnableDesktopControl(state)
  ].filter(a => a).length;
}

export function canShowExtraControls(state: State): boolean {
  return (numberOfExtraControls(state) > 0);
}

/**
 * @returns the more controls button in own controls is shown only if items are greater than 1
 * otherwise the single button is shown
 */
export function canShowExtraControlsMenu(state: State): boolean {
  return canShowExtraControls(state) || amModerator(state);
}
