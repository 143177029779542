import React, { Component } from 'react';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import moment from 'moment';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import ScheduleMeeting from '../ScheduleMeeting';
//import { Meeting } from '../Calendar';

import Empty from './Empty';
import MeetingDetails from './MeetingDetails';
import { Meeting } from './View';

import style from './styleDetails';


const messages = defineMessages({
  create: { id: 'create' },
  today: { id: 'today' },
  tomorrow: { id: 'tomorrow' },
});

type Props = {
  //formatDate: () => string;
  onDelete: (meeting: Meeting) => void;
  //onEdit: (meeting: Meeting) => void;
  meetingDetails: Array<Meeting>;
  selectedDay: moment.Moment | null;
}

type PropsType = Props & WithStyles<typeof style> & { intl: IntlShape };

interface State {
  createScheduleMeetingDialog: boolean;
}

class Details extends Component<PropsType, State> {
  _isMounted = false;
  constructor(props: PropsType) {
    super(props);

    this.state = {
      createScheduleMeetingDialog: false,
    };
    this.onCreateMeetingClose = this.onCreateMeetingClose.bind(this);
  }

  createScheduleMeetingDialog = () => {
    this.setState({
      createScheduleMeetingDialog: true,
    });
  }

  //Maybe use redux?
  onCreateMeetingClose() {
    if (this._isMounted) {
      this.setState({ createScheduleMeetingDialog: false });
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const classes = this.props.classes;
    if (this.props.meetingDetails && this.props.meetingDetails.length > 0) {
      return (
        <div>
          <div className={classes.meetingList}>
            <List>
              {this.getMeetings()}
            </List>

          </div>
          {this.createMeeting()}
          {this.state.createScheduleMeetingDialog === true &&
            <ScheduleMeeting
              onCloseClick={this.onCreateMeetingClose}
              selectedDay={this.props.selectedDay}
            />
          }</div>
      );
    } else {
      return (
        <div><Empty /> {this.createMeeting()}
          {this.state.createScheduleMeetingDialog === true &&
            <ScheduleMeeting
              onCloseClick={this.onCreateMeetingClose}
              selectedDay={this.props.selectedDay}
            />
          }
        </div>);
    }
  }

  createMeeting = () => {
    const classes = this.props.classes;
    return (
      <Grid container>
        <Grid item className={classes.createMeetingButton}>
          <Button
            variant='contained'
            color='primary'
            onClick={this.createScheduleMeetingDialog}
          >
            {this.props.intl.formatMessage(messages.create)}
          </Button>
        </Grid>
      </Grid>);
  }

  addDay = (prevDay: string, currentDay: string) => {
    if (moment(prevDay).isSame(currentDay, 'day')) {
      return false;
    }
    return true;
  }

  getDayDateLabel = (meeting: Meeting) => {
    if (moment(meeting.dtStart).isSame(new Date(), 'day')) {
      return this.props.intl.formatMessage(messages.today);
    }
    else if (moment(meeting.dtStart).isSame(moment().add(1, 'days'), 'day')) {
      return this.props.intl.formatMessage(messages.tomorrow);
    }
    return this.formatDateLabel(meeting.dtStart);
  }
  formatDateLabel = (date: string) => {
    return moment(date).format('DD MMMM');
  }


  showDayDivider = (show: boolean, meetingDetail: Meeting) => {
    const classes = this.props.classes;
    if (show === false) {
      return null;
    }
    return (<Grid container>
      <Grid item xs={2}>
        <Typography variant="caption" gutterBottom className={classes.dayString}>
          <Box fontWeight={500}>
            {this.getDayDateLabel(meetingDetail)}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Divider className={classes.dayDivider} />
      </Grid>
    </Grid>);
  }

  getMeetings = () => {
    let currentDay = "";
    return this.props.meetingDetails.map((meetingDetail, index) => {
      const differentDay = this.addDay(currentDay, meetingDetail.dtStart);
      if (differentDay) {
        currentDay = meetingDetail.dtStart;
      }
      return (
        <Grid container key={index}>
          {this.showDayDivider(differentDay, meetingDetail)}
          <MeetingDetails onDelete={this.props.onDelete} meeting={meetingDetail} />
        </Grid>
      );
    });
  }
}

export default withStyles(style)(injectIntl(Details));
