import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { IconButton, Tooltip } from '@material-ui/core';

import { newEvent, ERROR } from '../../lib/notifications';
import { IconArrowDown } from '../IconSet';

import { findEmails } from './utils';

import useStyles from './style';


const messages = defineMessages({
  attendeesUpload: { id: 'attendeesUpload' },
});


type Props = {
  onAddList: (list: Array<string>) => void;
}


function FileSelector(props: Props) {
  const { onAddList } = props;
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const showError = () => {
    newEvent(ERROR, 'attendeesUploadInvalidFile', 'attendeesUploadInvalidFile', 'attendeesUploadInvalidFile');
  };

  const readFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const fileReader = new FileReader();
      fileReader.onloadend = (_e) => {
        if (fileReader.result) {
          const list = findEmails(fileReader.result.toString());
          onAddList(list);
        } else {
          showError();
        }
      };
      fileReader.readAsText(fileList[0]);
    } else {
      showError();
    }
    // reset target value to allow uploading the same file again
    event.target.value = '';
  };

  return (
    <React.Fragment>
      <input
        accept=".csv, text/plain"
        style={{ display: 'none' }}
        id="icon-button-file"
        type="file"
        onChange={readFile}
      />
      <label htmlFor="icon-button-file">
        <Tooltip title={formatMessage(messages.attendeesUpload)} placement='top'>
          <IconButton className={classes.uploadButton} component='span'>
            <IconArrowDown size={24} />
          </IconButton>
        </Tooltip>
      </label>
    </React.Fragment>
  );
}


export default FileSelector;
