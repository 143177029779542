import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { WebSocketState as WebsocketState } from '../../lib/reducers/websocket';
import { getWsLeaveReason } from '../../lib/reduxSelectors/websocket';
import { usePrevious } from '../../lib/utils/hooks';


const messages = defineMessages({
  ok: { id: 'ok' },
  content: { id: 'meetingLeftDialogContent' },
  kickedContent: { id: 'meetingLeftDialogContentWhenKicked' },
  endedContent: { id: 'meetingLeftDialogContentWhenEnded' },
  iceFailedContent: { id: 'meetingLeftDialogContentWhenIceFailed' },
  serverErrorContent: { id: 'errorCode1001Body' },
  channelErrorContent: { id: 'connectionClosedUnexpectedlyMessage' },
  title: { id: 'meetingLeftDialogTitle' },
});


type State = {
  websocket: WebsocketState;
}

type MappedProps = {
  meetingLeftReason: string | null;
}


type ExtendedProps = MappedProps & RouteComponentProps;


function MeetingLeftDialog(props: ExtendedProps) {
  const [openMeetingLeftDialog, setOpenMeetingLeftDialog] = React.useState(false);
  const { meetingLeftReason } = props;
  const prevMeetingLeftReason = usePrevious(meetingLeftReason, '');
  const { formatMessage } = useIntl();

  const getContent = () => {
    if (meetingLeftReason === 'kicked') {
      return formatMessage(messages.kickedContent);
    }
    else if (meetingLeftReason === 'ended') {
      return formatMessage(messages.endedContent);
    }
    else if (meetingLeftReason === 'ice_failed') {
      return formatMessage(messages.iceFailedContent);
    }
    else if (meetingLeftReason === 'channel_error') {
      return formatMessage(messages.channelErrorContent);
    }
    else if (meetingLeftReason === 'crashed') {
      return formatMessage(messages.serverErrorContent);
    }
    else {
      return formatMessage(messages.content);
    }
  };

  const handleConfirm = (): void => {
    setOpenMeetingLeftDialog(false);
    props.history.push('/', { doNotConfirm: true });
  };

  React.useEffect(() => {
    if (meetingLeftReason && !prevMeetingLeftReason) {
      setOpenMeetingLeftDialog(true);
    }
    else if (openMeetingLeftDialog && !meetingLeftReason) {
      setOpenMeetingLeftDialog(false);
    }

  }, [meetingLeftReason, prevMeetingLeftReason, openMeetingLeftDialog]);


  return (
    <Dialog
      open={openMeetingLeftDialog}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm">
      <DialogTitle>
        {formatMessage(messages.title)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {getContent()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleConfirm} color="primary">
          {formatMessage(messages.ok)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state: State): MappedProps => {
  const meetingLeftReason = getWsLeaveReason(state.websocket);
  return ({ meetingLeftReason });
};


export default (withRouter(connect(mapStateToProps)(MeetingLeftDialog)));
