import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { bindActionCreators, Dispatch } from 'redux';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { logoutUser } from '../../lib/actions/auth';
import { logEvent, Event } from '../../lib/analytics';
import { AuthState } from '../../lib/reducers/auth';
import { getIsAuthenticated, getToken } from '../../lib/reduxSelectors/auth';
import getRouteFor, { Route } from '../../lib/utils/router';
import LocalStorage from '../../localStorage';
import ClosableDialog from '../ClosableDialog';


const messages = defineMessages({
  ok: { id: 'ok' },
  cancel: { id: 'cancel' },
  title: { id: 'logout' },
  body: { id: 'logoutDialogMessage' },
});


type MappedProps = {
  isAuthenticated: boolean;
  authToken: string | null;
}


type Props = {
  localStore: LocalStorage;
  onLogoutClick: () => void;
  onCancelClick: () => void;
} & MappedProps;



type State = {
  auth: AuthState;
}


const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout: (authToken, localStore) => logoutUser(authToken, localStore)
    },
    dispatch
  );


const mapStateToProps = (state: State): MappedProps => ({
  isAuthenticated: getIsAuthenticated(state.auth),
  authToken: getToken(state.auth),
});


type ExtendedProps = Props
  & RouteComponentProps
  & ReturnType<typeof mapDispatchToProps>;


function Logout(props: ExtendedProps) {
  const {
    onCancelClick,
    logout,
    onLogoutClick,
    authToken,
    localStore,
  } = props;

  const { formatMessage } = useIntl();

  const handleCancel = () => {
    onCancelClick();
  };

  const handleLogout = () => {
    logout(authToken, localStore);
    onLogoutClick();
    props.history.push(getRouteFor(Route.Home), { doNotConfirm: true });
    logEvent(Event.LOGOUT);
  };

  return (
    <ClosableDialog
      open={true}
      title={formatMessage(messages.title)}
      onClose={handleCancel}
    >
      <DialogContent>
        <DialogContentText>
          {formatMessage(messages.body)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleLogout} color="primary">
          {formatMessage(messages.ok)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
