import { fetchQuery } from 'relay-runtime';

import { SESSIONDETAILS_CLEAR } from '../actions/session';
import { getSession } from '../api/relay/minimalSession';
import { login, logout, guestLogin } from '../api/session';
import { getLogger } from '../logger';
import { newEvent, ERROR } from '../notifications';
import { unsetTracedUser } from '../tracer';


export const AUTH_READY = 'AUTH_READY';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GUESTLOGIN_REQUEST = 'GUESTLOGIN_REQUEST';
export const GUESTLOGIN_SUCCESS = 'GUESTLOGIN_SUCCESS';
export const GUESTLOGIN_FAILURE = 'GUESTLOGIN_FAILURE';
export const SYNC_TOKEN_LOGIN = 'SYNC_TOKEN_LOGIN';
export const SYNC_TOKEN_LOGOUT = 'SYNC_TOKEN_LOGOUT';


function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    payload: {
      username: creds.username,
    }
  };
}


function receiveLogin(user, token) {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      token: token,
      username: user,
    }
  };
}


function loginError(errmessage, errcode) {
  return {
    type: LOGIN_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
    },
    error: true,
  };
}



function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    payload: {}
  };
}


function requestGuestAuth() {
  return {
    type: GUESTLOGIN_REQUEST,
    payload: {}
  };
}


function receiveGuestAuth(token, displayName) {
  return {
    type: GUESTLOGIN_SUCCESS,
    payload: {
      token: token,
      displayName: displayName,
    }
  };
}


function guestAuthError(errmessage, errcode) {
  return {
    type: GUESTLOGIN_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
    },
    error: true,
  };
}


function syncTokenLogin(token) {
  return {
    type: SYNC_TOKEN_LOGIN,
    payload: {
      token: token
    }
  };
}

function syncTokenLogout() {
  return {
    type: SYNC_TOKEN_LOGOUT,
  };
}

function safeClearToken(localStore) {
  try {
    localStore.clearToken();
  } catch (_e) {
    return;
  }
}

function clearSessionDetails() {
  return {
    type: SESSIONDETAILS_CLEAR,
    payload: {}
  };
}

function safeSaveToken(localStore, token) {
  try {
    localStore.saveToken(token);
  } catch (_e) {
    return;
  }
}

function deauthenticate(token, dispatch, localStore) {
  unsetTracedUser();
  dispatch(receiveLogout());
  safeClearToken(localStore);
  dispatch(clearSessionDetails());
  const logger = getLogger('Logout');
  logout(token).then(
    () => {
      logger.debug("session closed on server");
    }
  ).catch(
    (err) => {
      logger.error("There was an error while closing the session", err.errorMessage, err.errorCode);
    }
  );
}


function authenticate(creds, dispatch, localStore) {
  login(creds.username, creds.password).then(
    (res) => {
      if (res.token) {
        safeSaveToken(localStore, res.token);
        dispatch(receiveLogin(creds.username, res.token));
      } else {
        safeClearToken(localStore);
        const err = (res.errors || { detail: 'unknown error' }).detail;
        onLoginError(err, null);
        dispatch(loginError(err, null));
      }
    }
  ).catch(
    (err) => {
      safeClearToken(localStore);
      onLoginError(err, err.errorCode);
      dispatch(loginError(err.errorMessage, err.errorCode));
    }
  );
}

function onLoginError(errorMessage, errorCode) {
  let messageID = 'errorLoginGeneric';

  if (errorCode >= 600) {
    messageID = 'errorLoginGeneric';
  } else if (errorCode >= 500) {
    messageID = 'errorLoginCode5xx';
  } else if (errorCode === 401) {
    messageID = 'errorLoginCode401';
  } else if (errorCode >= 400) {
    messageID = 'errorLoginCode4xx';
  }

  newEvent(ERROR, messageID, messageID, errorMessage);
}


function authenticateGuest(displayName, dispatch) {
  guestLogin(displayName).then(
    (res) => {
      if (res.token) {
        dispatch(receiveGuestAuth(res.token, displayName));
      } else {
        const err = (res.errors || { detail: 'unknown error' }).detail;
        dispatch(guestAuthError(err, null));
      }
    }
  ).catch(
    (e) => {
      // safeClearToken(localStore);
      dispatch(guestAuthError(e.errorMessage, e.errorCode));
    }
  );
}


export function loginUser(creds, localStore) {
  return (dispatch) => {
    dispatch(requestLogin(creds));
    return authenticate(creds, dispatch, localStore);
  };
}


export function logoutUser(token, localStore) {
  return (dispatch) => {
    return deauthenticate(token, dispatch, localStore);
  };
}


export function loginGuest(displayName) {
  return (dispatch) => {
    dispatch(requestGuestAuth());
    return authenticateGuest(displayName, dispatch);
  };
}

export function authReady(token) {
  return {
    type: AUTH_READY,
    payload: { token: token }
  };
}

export function checkValidateSession(relay, sessionRequest, dispatch, token, localStore) {
  fetchQuery(relay, sessionRequest, dispatch)
    .then((res) => {
      const session = getSession(res);
      dispatch(receiveLogin(`${session.username}@${session.realm}`, token));
    })
    .catch(() => {
      safeClearToken(localStore);
      dispatch(receiveLogout());
    });
}

export function setSyncToken(dispatch, token) {
  if (token) {
    dispatch(syncTokenLogin(token));
  } else {
    dispatch(syncTokenLogout());
  }
}
