import { commitMutation } from 'react-relay';

import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';

import { inviteToMeetingMutation } from './__generated__/inviteToMeetingMutation.graphql';


const mutation = graphql`
mutation inviteToMeetingMutation(
  $input: InviteToPersonalRoomInput!
) {
  inviteToPersonalRoom(input: $input) {
    result {
      code
      reason
    }
    errors {
      key
      message
      reason
    }
  }
}
`;


export function inviteToPersonalRoom(environment: IEnvironment, baseUrl: string, emails: string[]) {
  return new Promise((resolve, reject) => {
    commitMutation<inviteToMeetingMutation>(
      environment,
      {
        mutation,
        variables: { input: { baseUrl, emails } },
        onCompleted: ({ inviteToPersonalRoom }, otherErrors) => {
          if (otherErrors) {
            return reject(otherErrors);
          } else if (inviteToPersonalRoom && inviteToPersonalRoom.errors) {
            return reject(inviteToPersonalRoom.errors);
          }
          else if (inviteToPersonalRoom && inviteToPersonalRoom.result) {
            return resolve(inviteToPersonalRoom.result);
          }
          else {
            return reject({ code: 500, reason: 'unknown' });
          }
        },
        onError: err => {
          reject(err);
        }
      }
    );
  });
}
