import appconfig, { State as AppConfigState, defaultState as appConfigDefaultState } from './appconfig';
import auth, { AuthState, defaultState as authDefaultState } from './auth';
// eslint-disable-next-line @typescript-eslint/camelcase
import invite_participants from './invite_participants';
import notifications, { State as NotificationsState, defaultState as notificationsDefaultState } from './notifications';
import relay from './relay';
import room, { State as RoomState, defaultState as roomDefaultState } from './room';
import session, { State as SessionState, defaultState as sessionDefaultState } from './session';
import settings, { State as SettingsState, defaultState as settingsDefaultState } from './settings';
import waitingRoom from './waitingRoom';
import { State as WaitingRoomState, defaultState as waitingRoomDefaultState } from './waitingRoom';
import websocket, { WebSocketState as WebsocketState, defaultIdleState as websocketDefaultState } from './websocket';


// FIXME: remove anys
export interface State {
  auth: AuthState;
  websocket: WebsocketState;
  room: RoomState;
  waitingRoom: WaitingRoomState;
  session: SessionState;
  settings: SettingsState;
  // eslint-disable-next-line @typescript-eslint/camelcase, @typescript-eslint/no-explicit-any
  invite_participants: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relay: any;
  notifications: NotificationsState;
  appconfig: AppConfigState;
}


const defaultState = {
  auth: authDefaultState,
  websocket: websocketDefaultState,
  room: roomDefaultState,
  waitingRoom: waitingRoomDefaultState,
  session: sessionDefaultState,
  // eslint-disable-next-line @typescript-eslint/camelcase
  invite_participants: undefined,
  settings: settingsDefaultState,
  relay: undefined,
  notifications: notificationsDefaultState,
  appconfig: appConfigDefaultState,
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rootReducer(state: State | undefined, action: any) {
  if (!state) {
    state = defaultState;
  }
  return {
    auth: auth(state.auth, action),
    websocket: websocket(state.websocket, action),
    room: room(state.room, action),
    waitingRoom: waitingRoom(state.waitingRoom, action),
    session: session(state.session, action),
    settings: settings(state.settings, action),
    // eslint-disable-next-line @typescript-eslint/camelcase
    invite_participants: invite_participants(state.invite_participants, action),
    relay: relay(state.relay, action),
    notifications: notifications(state.notifications, action),
    appconfig: appconfig(state.appconfig, action),
  };
}


export { rootReducer };
