import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { iconColors as colors } from '../../colors';
import { enableDesktopControl } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { IconDesktopControl, IconDesktopControlOff } from '../IconSet';

import messages from './buttonsMessages';


function DesktopControl(props: ExtendedProps) {
  const { user, isDesktopControlEnabled, screenSourceType, isRequestingDesktopControl } = props;

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const enableOrDisableDesktopControl = React.useCallback(
    () => {
      logEvent(Event.REMOTE_CONTROL, { 'action': isDesktopControlEnabled ? 'disable' : 'enable' });
      dispatch(enableDesktopControl(user, !isDesktopControlEnabled, screenSourceType));
    }, [dispatch, user, isDesktopControlEnabled, screenSourceType]
  );

  const label =
    isDesktopControlEnabled
      ? formatMessage(messages.disableDesktopControl)
      : formatMessage(messages.enableDesktopControl);

  return (
    <MenuItem onClick={enableOrDisableDesktopControl} disabled={isRequestingDesktopControl}>
      <ListItemIcon>
        {isDesktopControlEnabled
          ? <IconDesktopControlOff size={32} color={colors.inactive} />
          : <IconDesktopControl size={32} />
        }
      </ListItemIcon>
      <ListItemText primary={label} />
    </MenuItem>
  );
}


type MappedProps = {
  user: string;
  isDesktopControlEnabled: boolean;
  isRequestingDesktopControl: boolean;
  screenSourceType: string;
}

type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const user = getWsUserId(state.websocket);
  let isDesktopControlEnabled = false;
  let isRequestingDesktopControl = false;

  if (user && state.room.roster[user]) {
    isDesktopControlEnabled = state.room.roster[user].isDesktopControlEnabled;
    isRequestingDesktopControl = state.room.roster[user].isRequestingDesktopControl;
  }

  return {
    user: user || '',
    isDesktopControlEnabled: isDesktopControlEnabled,
    isRequestingDesktopControl: isRequestingDesktopControl,
    screenSourceType: state.room.screenSourceType ? state.room.screenSourceType.toString() : '',
  };
};


export default connect(mapStateToProps)(DesktopControl);
