const style = (theme) => ({
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto'
  },
  overlay: {
    top: 0,
    left: 0,
    zIndex: theme.zIndex.modal + 1,
    position: 'absolute',
    background: theme.palette.common.black,
    height: '100%',
    width: '100%',
    opacity: 0.5
  }
});

export default style;
