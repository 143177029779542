import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';

import { Popover } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import { logEvent, Event } from '../../../lib/analytics';
import { IconInfo } from '../../IconSet';
import RoomInformation from '../../RoomInformation';


const messages = defineMessages({
  roomInfoTooltip: { id: 'roomInfoTooltip' },
});


function RoomInfo(_props: {}) {
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleRoomInfo = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    logEvent(Event.MEETING_INFO, { 'action': 'open' });
  };

  const closeRoomInfo = (): void => {
    setAnchorEl(null);
    logEvent(Event.MEETING_INFO, { 'action': 'close' });
  };

  const getIcon = () => {
    return (
      <IconInfo size={32} />
    );
  };

  const getMobileLayout = () => {
    return (
      <MenuItem onClick={handleRoomInfo}>
        <ListItemIcon>
          {getIcon()}
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.roomInfoTooltip)} />
      </MenuItem>
    );
  };

  const getLayout = () => {
    return (
      <Tooltip title={formatMessage(messages.roomInfoTooltip)}>
        <IconButton onClick={handleRoomInfo}>
          {getIcon()}
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <div>
      { isMobileOnly ? getMobileLayout() : getLayout() }
      <Popover
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={closeRoomInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {<RoomInformation />}
      </Popover>
    </div>
  );
}


export default RoomInfo;
