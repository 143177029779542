import React from 'react';

import Menu, { MenuProps as OriginalMenuProps } from '@material-ui/core/Menu';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';


const styles = (theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: '0.5em',
    },
  });


type MenuProps = OriginalMenuProps & WithStyles<typeof styles>;


function StyledMenu(props: MenuProps) {
  const { classes, children, ...other } = props;
  const styledChildren = React.Children.map(
    children,
    (c) => (
      <div>
        {c}
      </div>
    )
  );

  return (
    <Menu PaperProps={{ className: classes.paper }} {...other}>
      {styledChildren}
    </Menu>
  );
}


export default withStyles(styles)(StyledMenu);
