import LocalStorage from '../../localStorage';
import { State, defaultState } from '../reducers/settings';
import { assign } from '../utils/object';
import { BlurTypeCustomValue } from '../utils/pipelineDefault';


function _setAudioInState(state: State, config: Partial<State>) {
  return assign(state, {
    audioInDevice: config.audioInDevice
  });
}

function _setVideoDeviceState(state: State, config: Partial<State>) {
  return assign(state, {
    videoDevice: config.videoDevice
  });
}

function _setAudioOutState(state: State, config: Partial<State>) {
  return assign(state, {
    audioOutDevice: config.audioOutDevice
  });
}

function _setVideoQualityState(state: State, config: Partial<State>) {
  return assign(state, {
    videoQuality: config.videoQuality
  });
}

function _setVideoFacingModeState(state: State, config: Partial<State>) {
  return assign(state, {
    videoFacingMode: config.videoFacingMode
  });
}

function _setBackgroundBlurEnabledState(state: State, config: Partial<State>) {
  return assign(state, {
    backgroundBlurEnabled: config.backgroundBlurEnabled
  });
}

function _setBackgroundBlurValueState(state: State, config: Partial<State>) {
  return assign(state, {
    backgroundBlurValue: config.backgroundBlurValue
  });
}

function _setBeautifyEffectEnabledState(state: State, config: Partial<State>) {
  return Object.assign(state, {
    beautifyEffectEnabled: config.beautifyEffectEnabled
  });
}

function _setBackgroundBlurCustomBackgroundsState(state: State, config: Partial<State>) {
  return assign(state, {
    [BlurTypeCustomValue.CUSTOM_IMAGE_1]: config[BlurTypeCustomValue.CUSTOM_IMAGE_1],
    [BlurTypeCustomValue.CUSTOM_IMAGE_2]: config[BlurTypeCustomValue.CUSTOM_IMAGE_2],
    [BlurTypeCustomValue.CUSTOM_IMAGE_3]: config[BlurTypeCustomValue.CUSTOM_IMAGE_3]
  });
}

export function retriveStoredSettings(localStorage: LocalStorage) {
  const storedMediaConf = localStorage.loadMediaConfig();
  let state = _setAudioInState(defaultState, storedMediaConf);
  state = _setAudioOutState(state, storedMediaConf);
  state = _setVideoDeviceState(state, storedMediaConf);
  state = _setVideoFacingModeState(state, storedMediaConf);
  state = _setVideoQualityState(state, storedMediaConf);
  const storedBackgroundConf = localStorage.loadBackgroundConfig();
  state = _setBeautifyEffectEnabledState(state, storedBackgroundConf);
  state = _setBackgroundBlurEnabledState(state, storedBackgroundConf);
  state = _setBackgroundBlurValueState(state, storedBackgroundConf);
  return _setBackgroundBlurCustomBackgroundsState(state, storedBackgroundConf);
}
