export function getInitials(displayName: string) {
  const tokens = displayName.split(' ');
  let initials = tokens[0].substring(0, 1);
  if (tokens.length > 1) {
    initials += tokens[tokens.length - 1].substring(0, 1);
  } else if (tokens[0].length > 1) {
    initials += tokens[0].substring(1, 2);
  }
  return initials.toUpperCase();
}
