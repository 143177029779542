import React, { Component } from 'react';
import { isIOS, isSafari } from "react-device-detect";
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';

import IntlPropType from '../../intl';
import { RtcDevices } from '../../lib/api/rtcDevices';
import { getLogger } from '../../lib/logger';
import prepareWebRtcProvider from '../../rtc';
import ClosableDialog from '../ClosableDialog';

import style from './style';


const messages = defineMessages({
  ok: { id: 'ok' },
  audioIsPausedDialogTitle: { id: 'audioIsPausedDialogTitle' },
  audioIsPausedDialogMessage: { id: 'audioIsPausedDialogMessage' },
  audioIsPausedDialogSubMessage: { id: 'audioIsPausedDialogSubMessage' },
});


class AudioElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogIsOpen: false,
    };
  }

  componentDidMount() {
    if (this.video) {
      if (isSafari || isIOS) {
        // on safari, if access to the microphone is blocked (no mic or joining
        // without gUM permissions), the audio element is muted and autoplay
        // won't work, due to apple policies
        // This adds a listener to check if the video element is paused even
        // though the stream is ready to play. In that case, we play() it
        // programmatically. But we need to attach this to a user gesture,
        // so we show a dialog to force the user to call the function with a
        // click event
        this.video.addEventListener('canplay', this.onCanPlay);
      }
    }
    if (this.props.src) {
      this.video.srcObject = this.props.src;
    }
    if (this.props.sink) {
      this.setOutputDevice(null, this.props.sink);
    }
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.src && this.props.src !== prevProps.src) {
      this.video.srcObject = this.props.src;
    }
    if (this.props.handleAudioOutputChange) {
      this.setOutputDevice(prevProps.sink, this.props.sink);
    }
  }

  componentWillUnmount() {
    if (this.video) {
      this.video.src = '';
      this.video.srcObject = null;
    }
    if (isSafari || isIOS) {
      this.video.removeEventListener('canplay', this.onCanPlay);
    }
  }

  onCanPlay = () => {
    if (this.video.paused) {
      this.setState({ dialogIsOpen: true });
    }
  }

  setOutputDevice(oldDevice, newDevice) {
    const logger = getLogger('AudioElement Set Output');
    const webrtc = prepareWebRtcProvider();
    const rtc = new RtcDevices(webrtc, logger);

    if (newDevice && newDevice !== oldDevice) {
      rtc.setAudioOutputOn(this.video, newDevice).then(() => {
        logger.debug("Changed audio output to ", newDevice);
      }).catch((e) => logger.error(e));
    }
  }

  dismissDialog = () => {
    if (this.video) {
      this.video.play();
    }
    this.setState({ dialogIsOpen: false });
  }

  render() {
    const formatMessage = this.props.intl.formatMessage;

    const classes = this.props.classes;
    return (
      <>
        <video
          className={classes.video}
          autoPlay playsInline
          ref={(ref) => { this.video = ref; }}
        />
        <ClosableDialog
          open={this.state.dialogIsOpen}
          onClose={this.dismissDialog}
          disableBackdropClick
          disableEscapeKeyDown
          title={formatMessage(messages.audioIsPausedDialogTitle)}
        >
          <DialogContent>
            <DialogContentText>
              {formatMessage(messages.audioIsPausedDialogMessage)}
            </DialogContentText>
            <DialogContentText variant='caption'>
              {formatMessage(messages.audioIsPausedDialogSubMessage)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.dismissDialog} color='primary' variant='contained'>
              {formatMessage(messages.ok)}
            </Button>
          </DialogActions>
        </ClosableDialog>
      </>
    );
  }
}


AudioElement.propTypes = {
  intl: IntlPropType.isRequired,
  src: PropTypes.object,
  classes: PropTypes.object.isRequired,
  sink: PropTypes.object,
  handleAudioOutputChange: PropTypes.bool,
};


AudioElement.defaultProps = {
  handleAudioOutputChange: false
};


function mapStateToProps(state) {
  return {
    sink: state.settings.audioOutDevice,
  };
}


export default withStyles(style)(connect(mapStateToProps)(injectIntl(AudioElement)));
