import React from 'react';
import { connect, useDispatch } from 'react-redux';
import sizeMe, { SizeMeProps } from 'react-sizeme';

import { expandStreamVideo } from '../../../lib/actions/room';
import { logEvent, Event } from '../../../lib/analytics';
import gridElements from '../../../lib/grid';
import { RoomLayoutStreamType } from '../../../lib/redux_types';
import useDebounce from '../../../lib/utils/debounce';
import LoadingVideoElement from '../../VideoElement/LoadingVideoElement';
import Fullscreen from '../Fullscreen';
import VideoToolbar from '../VideoToolbar';

import mapStateToProps, { ExtendedProps } from './state';


function countSecondaryVideos(props: ExtendedProps) {
  const {
    remoteVideoStreams,
    localScreenStream,
    hasVideoStream,
  } = props;

  // one per participant, excluding the selected one
  let num = Object.keys(remoteVideoStreams).length - 1;

  // add local streams, if any
  num = hasVideoStream ? num + 1 : num;
  num = localScreenStream ? num + 1 : num;

  return Math.max(num, 0);
}


function Participants(props: ExtendedProps, width: number, height: number) {
  const {
    myStreamIsSelected,
    myScreenIsSelected,
    remoteVideoStreams,
    selectedStream,
    myUserId,
    localVideoStream,
    localScreenStream,
    hasVideoStream,
  } = props;

  const dispatch = useDispatch();

  const numSecondaryVideos = countSecondaryVideos(props);
  const participantsGrid = gridElements(width, height, numSecondaryVideos);

  let idxOffset = 0;
  const myStreams = [];
  if (selectedStream && !myStreamIsSelected && hasVideoStream && myUserId) {
    const idx = idxOffset;
    idxOffset += 1;
    const setSelectedVideo = () => {
      if (myUserId) {
        logEvent(Event.SET_MAIN_VIDEO, { 'from': 'video_element', 'from_layout': 'featured' });
        dispatch(expandStreamVideo(myUserId, 'stream'));
      }
    };
    myStreams.push(
      <div onDoubleClick={setSelectedVideo} key={idx} style={participantsGrid[idx]}>
        <Fullscreen user={myUserId}>
          <LoadingVideoElement
            user={myUserId}
            mirrored={true}
            addVideoMutedIconOverlay={true}
            stream={localVideoStream}
          />
          <VideoToolbar uid={myUserId} />
        </Fullscreen>
      </div>
    );
  }
  if (!myScreenIsSelected && localScreenStream) {
    const idx = idxOffset;
    idxOffset += 1;
    const setSelectedVideo = () => {
      if (myUserId) {
        logEvent(Event.SET_MAIN_VIDEO, { 'from': 'video_element', 'from_layout': 'featured' });
        dispatch(expandStreamVideo(myUserId, 'screen'));
      }
    };
    const uid = `${myUserId}_screen`;
    myStreams.push(
      <div onDoubleClick={setSelectedVideo} key={idx} style={participantsGrid[idx]}>
        <Fullscreen user={uid}>
          <LoadingVideoElement
            user={uid}
            mirrored={false}
            addVideoMutedIconOverlay={true}
            stream={localScreenStream}
          />
          <VideoToolbar uid={uid} />
        </Fullscreen>
      </div>
    );
  }

  const keys = Object.keys(remoteVideoStreams);
  const participants = keys.flatMap((uid, i) => {
    const idx = i + idxOffset;
    const video = remoteVideoStreams[uid];

    if (uid === selectedStream) {
      idxOffset -= 1;
      return [];
    }

    const setSelectedVideo = () => {
      let streamType: RoomLayoutStreamType = 'stream';
      if (uid.endsWith('_screen')) {
        streamType = 'screen';
      }
      uid = uid.replace(/_screen$/, '');
      logEvent(Event.SET_MAIN_VIDEO, { 'from': 'video_element', 'from_layout': 'featured' });
      dispatch(expandStreamVideo(uid, streamType));
    };

    if (video['stream'] || video['isVideoStarting']) {
      return [
        <div onDoubleClick={setSelectedVideo} key={idx} style={participantsGrid[idx]}>
          <Fullscreen user={uid}>
            <LoadingVideoElement
              user={uid}
              mirrored={false}
              addVideoMutedIconOverlay={true}
              stream={video['stream']}
            />
            <VideoToolbar uid={uid} />
          </Fullscreen>
        </div>
      ];
    }
    else {
      return [];
    }
  });
  return [...myStreams, ...participants];
}


function SecondaryVideos(props: ExtendedProps & SizeMeProps) {
  const { size } = props;

  const debouncedSize = useDebounce(size, 100);

  if (debouncedSize.width && debouncedSize.width > 0 && debouncedSize.height && debouncedSize.height > 0) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {Participants(props, debouncedSize.width, debouncedSize.height)}
      </div>
    );
  }
  else {
    return null;
  }
}


export default connect(mapStateToProps)(sizeMe({ monitorHeight: true })(SecondaryVideos));
