import { createStyles, Theme } from '@material-ui/core/styles';


const style = (theme: Theme) => createStyles({
  meetingListProgressContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  meetingListGridContainer: {
    flexGrow: 90,
    position: 'relative',
    marginTop: theme.spacing(6),
  },
  calendarContainer: {
    width: '100%'
  },
  popupContainer: {
    position: 'relative',
    left: '50%',
  },
  meetingListDetails: {
    display: "flex",
    flexFlow: "column",
    padding: theme.spacing(2),
  }
});

export default style;
