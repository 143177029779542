import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { resetJoinFailure } from '../../../../lib/actions/websocket';
import { State } from '../../../../lib/reducers';
import { WebSocketReady } from '../../../../lib/reducers/websocket';
import { getWsErrorCode, getWsErrorPayload } from '../../../../lib/reduxSelectors/websocket';
import { getRoomNameFromParams } from '../../../../lib/utils/router';
import useTimeout from '../../../../lib/utils/timeout';
import ClosableDialog from '../../../ClosableDialog';

import { DialogMessage, DialogTitle } from './DialogStrings';
import LoginLink from './LoginLink';
import messages from './messages';
import { backToHome, reconnect } from './utils';

import useStyles from './styles';


const reconnectionDelay = 15000; // Auto-reconnection delay in ms

type Props = {};

type MappedProps = {
  errorCode: WebSocketReady['errorCode'];
  errorPayload: WebSocketReady['errorPayload'];
  snapshot: State['waitingRoom']['snapshot'];
}

function mapStateToProps(state: State) {
  return {
    errorCode: getWsErrorCode(state.websocket),
    errorPayload: getWsErrorPayload(state.websocket),
    snapshot: state.waitingRoom.snapshot,
  };
}

type ExtendedProps = Props & MappedProps;


function RetriableError(props: ExtendedProps) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const gotoHome = React.useCallback(
    () => {
      dispatch(resetJoinFailure());
      backToHome(history, true);
    }, [history, dispatch]
  );

  const condition: boolean = !!props.errorCode && props.errorCode === 1005;

  const tryReconnect = React.useCallback(
    () => {
      const slug = getRoomNameFromParams(params);
      if (slug) {
        reconnect(slug, dispatch, props.snapshot);
      }
    }, [dispatch, params, props.snapshot]
  );

  useTimeout(tryReconnect, reconnectionDelay, condition);

  const title = DialogTitle(props);
  const message = DialogMessage(props);

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth
      title={title}
    >
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Box
          className={classes.fullWidth}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box flexGrow={1}>
            <LoginLink renderAs='text' />
          </Box>
          <Box>
            <Button variant='contained' onClick={tryReconnect} color='primary'>
              {formatMessage(messages.tryAgain)}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </ClosableDialog>
  );
}


export default connect(mapStateToProps)(RetriableError);
