import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import { useSnackbar } from 'notistack';

import { removeNotification } from '../../lib/actions/notifications';
import { State } from '../../lib/reducers';
import { NotificationEvent } from '../../lib/redux_types';
import { isRecorder } from '../../lib/reduxSelectors/session';

import { getNotificationLevel } from './notificationLevels';


type MappedProps = {
  notifications: State['notifications']['notifications'];
  isRecorder: State['session']['isRecorder'];
}

let displayed: string[] = [];

function storeDisplayed(ref: string) {
  displayed = [...displayed, ref];
}

function removeDisplayed(ref: string) {
  displayed = [...displayed.filter(key => ref !== key)];
}

function NotificationArea(props: MappedProps) {
  const {
    notifications,
    isRecorder
  } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getMessage = useCallback((notification: NotificationEvent) => {
    if (!notification) { return null; }

    const message = formatMessage({
      id: String(notification.code) || notification.message || 'defaultNotificationError',
      defaultMessage: notification.message
    }, notification.payload || {});

    if (message) {
      return message;
    } else {
      return notification.message;
    }
  }, [formatMessage]);

  const getSeverity = useCallback((notification: NotificationEvent) => {
    return (notification && notification.prio && notification.prio.description)
      ? getNotificationLevel(notification.prio.description)
      : 'info';
  }, []);

  React.useEffect(() => {
    if (isRecorder) return;

    notifications.forEach(({ ref, notification }) => {

      if (displayed.includes(ref)) return;

      const message = getMessage(notification);
      const level = getSeverity(notification);

      enqueueSnackbar(message, {
        key: ref,
        autoHideDuration: 5000,
        variant: level,
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        disableWindowBlurListener: true,
        onExited: () => {
          dispatch(removeNotification(ref));
          removeDisplayed(ref);
        },
      });

      storeDisplayed(ref);
    });
  }, [
    notifications,
    isRecorder,
    getMessage,
    getSeverity,
    closeSnackbar,
    enqueueSnackbar,
    dispatch
  ]);

  return null;
}

const mapStateToProps = (state: State): MappedProps => ({
  notifications: state.notifications.notifications || [],
  isRecorder: isRecorder(state),
});

export default connect(mapStateToProps)(NotificationArea);
