import { useSelector } from 'react-redux';

import { publishersCount } from '../../lib/reduxSelectors/room';
import { usePrevious } from '../../lib/utils/hooks';


function usePublishersCount() {
  const numOfPublishers = useSelector(publishersCount);
  const numOfPreviousPublishers = usePrevious(numOfPublishers, 0);

  return [ numOfPublishers, numOfPreviousPublishers ];
}


export default usePublishersCount;
