import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { togglePip } from '../../lib/actions/room';
import { VideoRoom } from '../../lib/api/videoroom';
import { State } from '../../lib/reducers';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { usePrevious } from '../../lib/utils/hooks';

import { getStreams } from './StandardDimensionLayout';


function Pip(props: ExtendedProps) {
  const { pipUser, myUser, isOwnVideoMuted, remoteVideoStreams } = props;
  const prevRemoteVideoStreams = usePrevious(remoteVideoStreams, []);
  const prevIsOwnVideoMuted = usePrevious(isOwnVideoMuted, false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const currentUsers = Object.keys(remoteVideoStreams);
    const prevUsers = Object.keys(prevRemoteVideoStreams);

    if (pipUser && !currentUsers.includes(pipUser) && prevUsers.includes(pipUser)) {
      dispatch(togglePip(null));
    }

    if (pipUser && pipUser === myUser && !prevIsOwnVideoMuted && isOwnVideoMuted) {
      dispatch(togglePip(null));
    }

  }, [pipUser, remoteVideoStreams, prevRemoteVideoStreams, myUser, prevIsOwnVideoMuted,
    isOwnVideoMuted, dispatch]);

  return null;
}

type Props = {
}

type MappedProps = {
  pipUser: string | null;
  myUser: string;
  isOwnVideoMuted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remoteVideoStreams: any; // FIXME

}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State): MappedProps => {
  const myUserId = getWsUserId(state.websocket) || '';
  return {
    pipUser: state.room.pipEnabled,
    myUser: myUserId,
    remoteVideoStreams: getStreams(state),
    isOwnVideoMuted: !VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream)
      || (state.room.roster[myUserId] || { isVideoMuted: false }).isVideoMuted,
  };
};


export default connect(mapStateToProps)(Pip);
