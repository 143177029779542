import React from 'react';
import { connect } from 'react-redux';

import { mapStateToProps, MappedProps } from './mapState';
import MaximizeMainVideo from './MaximizeMainVideo';
import MoveVideoToMain from './MoveVideoToMain';
import StreamSwitch from './StreamSwitch';


function SecondaryActions(props: Props) {
  const { iconSize, mini, uid, mySelf, myScreen } = props;

  return (
    <React.Fragment>
      <MaximizeMainVideo uid={uid} iconSize={iconSize} mini={mini} />
      <MoveVideoToMain uid={uid} iconSize={iconSize} mini={mini} />
      { !(mySelf || myScreen) && <StreamSwitch uid={uid} iconSize={iconSize} mini={mini} /> }
    </React.Fragment>
  );
}


type Props = {
  uid: string;
  iconSize?: number;
  mini?: boolean;
} & MappedProps


export default connect(mapStateToProps)(SecondaryActions);
