import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import { GridList, GridListTile, InputLabel, makeStyles, Theme } from '@material-ui/core';

import { saveBackgroundBlurValueConfig, saveBackgroundBlurEnabledConfig } from '../../lib/actions/settings';
import { logEvent, Event } from '../../lib/analytics';
import { findBlurTypeValue, BlurValue, blurValues, BlurValueKind } from '../../lib/utils/pipelineDefault';
import LocalStorage from '../../localStorage';

import BackgroundCustomItem from './BackgroundCustomItem/BackgroundCustomItem';
import BackgroundItem from './BackgroundItem';


const messages = defineMessages({
  backgroundBlur: { id: 'backgroundBlur' },
});


const useStyles = makeStyles((theme: Theme) => ({
  backgroundLabel: {
    paddingBottom: theme.spacing(1),
    color: "inherit",
    fontSize: "1rem",
    margin: 0,
  },
  buttonDisabled: {
    filter: 'opacity(0.5)',
  }
}));


function BackgroundSelection(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { isButtonDisabled } = props;

  const className = classNames(
    isButtonDisabled ? classes.buttonDisabled : null,
  );

  const handleBackgroundBlurChange = (value: BlurValue['value']) => {
    if (isButtonDisabled) return;
    const val = findBlurTypeValue(value);
    logEvent(Event.BLUR, { 'value': val });
    if (value) dispatch(saveBackgroundBlurValueConfig(value, new LocalStorage()));
    dispatch(saveBackgroundBlurEnabledConfig(Boolean(value), new LocalStorage()));
  };

  const getItem = (blurValue: BlurValue) => {
    if ( blurValue.kind === BlurValueKind.CUSTOM )
      return <BackgroundCustomItem
        isButtonDisabled={isButtonDisabled}
        item={blurValue}
        onClick={handleBackgroundBlurChange}
      />;
    else return <BackgroundItem
      isButtonDisabled={isButtonDisabled}
      item={blurValue}
      onClick={handleBackgroundBlurChange}
    />;
  };

  return (
    <div className={className}>
      <InputLabel className={classes.backgroundLabel}>
        {formatMessage(messages.backgroundBlur)}
      </InputLabel>
      <GridList cellHeight={50} cols={3} spacing={16}>
        {blurValues.map((blurVal) => (
          <GridListTile key={blurVal.value}>
            { getItem(blurVal) }
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

type Props = {
  isButtonDisabled: boolean;
};

export default BackgroundSelection;
