import React, { useState } from "react";

import classNames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { IconChevronRight } from '../IconSet';

import CascadingMenu, { CascadingMenuItem } from "./CascadingMenu";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subMenuItem: {
      display: "flex"
    },
    caption: {
      display: "flex",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      flexGrow: 4
    }
  }),
);

type Props = {
  caption: string;
  icon?: React.ReactNode;
  menuItems: Array<CascadingMenuItem> | undefined;
}

function SubMenu(props: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const { caption, menuItems, icon } = props;
  const classes = useStyles();

  const handleItemClick = React.useCallback(

    (event) => {
      if (!anchorElement) {
        setAnchorElement(event.currentTarget);
      }
      setMenuOpen(!menuOpen);
    },
    [setAnchorElement, setMenuOpen, anchorElement, menuOpen]
  );

  const handleSubMenuClose = React.useCallback(

    () => {
      setMenuOpen(false);
    },
    [setMenuOpen]
  );

  return (
    <React.Fragment>
      <MenuItem
        onClick={handleItemClick}
        className={classNames(classes.subMenuItem)}
      >
        {icon}
        <Typography variant='body2' className={classes.caption}>
          {caption}
        </Typography>
        <IconChevronRight size={16} />
      </MenuItem>
      <CascadingMenu
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={menuOpen}
        menuItems={menuItems}
        anchorEl={anchorElement}
        onClose={handleSubMenuClose}
      />
    </React.Fragment>
  );

}

export default SubMenu;
