import { createStyles, Theme } from '@material-ui/core/styles';


const homeStyles = (theme: Theme) =>
  createStyles({
    homePadding: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '2%',
        paddingRight: '2%',
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '5%',
        paddingRight: '5%',
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '15%',
        paddingRight: '15%',
      },
    },
  });


export default homeStyles;
