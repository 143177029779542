import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import { resetJoinFailure } from '../../../../lib/actions/websocket';
import { logEvent, Event } from '../../../../lib/analytics';
import { State } from '../../../../lib/reducers';
import { WebSocketReady } from '../../../../lib/reducers/websocket';
import { getIsAuthenticated } from '../../../../lib/reduxSelectors/auth';
import { getWsErrorCode, getWsErrorMessage, getWsErrorPayload } from '../../../../lib/reduxSelectors/websocket';
import getRouteFor, { Route, getRoomNameFromParams } from '../../../../lib/utils/router';

import messages from './messages';

import useStyles from './styles';


type Props = {
  renderAs: 'text' | 'button';
}

type MappedProps = {
  errorCode: WebSocketReady['errorCode'];
  isAuthenticated: boolean;
  loginEnabled: boolean;
}

function mapStateToProps(state: State) {
  return {
    roomError: state.room.roomError,
    errorCode: getWsErrorCode(state.websocket),
    errorMessage: getWsErrorMessage(state.websocket),
    errorPayload: getWsErrorPayload(state.websocket),
    isAuthenticated: getIsAuthenticated(state.auth),
    loginEnabled: state.appconfig.login_enabled,
    snapshot: state.waitingRoom.snapshot,
  };
}

type ExtendedProps = Props & MappedProps;


function LoginLink(props: ExtendedProps) {
  const classes = useStyles();
  const params = useParams<{id: string}>();
  const dispatch = useDispatch();

  const { isAuthenticated, errorCode, loginEnabled, renderAs } = props;

  const { formatMessage } = useIntl();

  const meetingId = getRoomNameFromParams(params);
  const loginQs = meetingId ? `redirectTo=${meetingId}` : undefined;
  const loginRef = getRouteFor(Route.Login, undefined, loginQs);

  const msgDef = (errorCode === 1000) ? messages.clickHereToLogin : messages.clickHereToLoginToOwnRoom;
  const msg = formatMessage(msgDef);

  const resetError = React.useCallback(
    () => {
      dispatch(resetJoinFailure());
      logEvent(Event.LOGIN, { 'from': 'waiting_room' });
    }, [dispatch]
  );

  if (isAuthenticated || !loginEnabled) {
    return null;
  }

  const getAsButton = () => {
    return (
      <Button variant='contained' component={Link} to={loginRef} color='primary'>
        {formatMessage(messages.login)}
      </Button>
    );
  };

  const getAsText = () => {
    return (
      <a href={loginRef} className={classes.link}>
        <Typography
          color='primary'
          variant='caption'
          gutterBottom
          align='center'
        >
          {msg}
        </Typography>
      </a>
    );
  };

  return (
    <div onClick={resetError}>
      { (renderAs === 'button') ? getAsButton() : getAsText() }
    </div>
  );
}


export default connect(mapStateToProps)(LoginLink);
