import { commitMutation } from 'react-relay';

import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';
import { ConnectionHandler } from 'relay-runtime';

import { newEvent, ERROR } from '../../notifications';

import {
  deleteRecordingMutation,
  deleteRecordingMutationResponse
} from './__generated__/deleteRecordingMutation.graphql';


const mutation = graphql`
mutation deleteRecordingMutation(
  $input: DeleteRecordingInput!
) {
  deleteRecording(input: $input)
  {
    errors {
      key
      message
      reason
    }
    recording {
      id
      userId
      domainId
    }
  }
}
`;


function maybeNotifyError(response: deleteRecordingMutationResponse | null) {
  let inError = false;

  if (response && response.deleteRecording && response.deleteRecording.errors !== null) {
    inError = true;
  }
  if (!response) {
    inError = true;
  }

  if (inError) {
    newEvent(ERROR, 'deleteRecordingError', 'deleteRecordingError',
      "Delete recording error");
  }
}


function deleteRecording(environment: IEnvironment, id: string, userId: string, domainId: string) {
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        recording: {
          userId,
          domainId,
          id
        }
      },
    };
    commitMutation<deleteRecordingMutation>(
      environment,
      {
        mutation,
        variables,
        updater: (store) => {
          const payload = store.getRootField("deleteRecording");
          const model = payload.getLinkedRecord("recording");
          const rec = { id: 'client:root' };
          const parent = store.get(rec.id);
          if (parent) {
            const connection = ConnectionHandler.getConnection(parent, "RecordingList_myRecordings");
            connection && ConnectionHandler.deleteNode(connection, model.getValue('id'));
          }
        },
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { deleteRecording };
