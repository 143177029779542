import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect, useDispatch } from 'react-redux';

import { Avatar as MuiAvatar, IconButton, Badge } from '@material-ui/core';

import { iconColors } from '../../colors';
import { toggleVideoMute } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { shouldMirrorVideo } from '../../lib/reduxSelectors/settings';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { showStream } from '../../lib/utils/mobile';
import useTimeout from '../../lib/utils/timeout';
import Avatar from '../AvatarNew';
import { IconShareScreen, IconHandUp, IconLocked } from '../IconSet';
import LoadingVideoElement from '../VideoElement/LoadingVideoElement';

import RippleBadge from './RippleBadge';

import useStyles from './style';


interface Props {
  name: string;
  uid: string;
  isVisible: boolean;
}

type MappedProps = {
  screen: MediaStream | null;
  isTalking: boolean;
  stream: MediaStream | null;
  isVideoMuted: boolean;
  isScreenMuted: boolean;
  myself: boolean;
  isFeatured: boolean;
  featuredType: State['room']['layoutConfig']['featured_type'];
  hasHandRaised: boolean;
  shouldMirrorVideo: boolean;
  isInPrivateConf: boolean;
  isInDifferentAudioConf: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State, { uid }: Props): MappedProps => {
  const myUid = getWsUserId(state.websocket);
  let screen: MediaStream | null = null;
  let stream: MediaStream | null = null;
  const defaults = {
    is_talking: false, // eslint-disable-line @typescript-eslint/camelcase
    isVideoMuted: false,
    isScreenMuted: false,
    raisedHand: false,
    stream: null,
    screen: null,
  };
  const rosterUser = state.room.roster[uid] || defaults;
  const isTalking = rosterUser.is_talking;
  const isVideoMuted = rosterUser.isVideoMuted;
  const isScreenMuted = rosterUser.isScreenMuted;
  const myself = myUid === uid;
  const isFeatured = state.room.layoutConfig.featured_id === uid;
  const featuredType = state.room.layoutConfig.featured_type;
  const hasHandRaised = rosterUser.raisedHand;

  const videoOwner = uid && uid.endsWith('_screen') ? uid.replace(/_screen/, "") : uid;
  let isInDifferentAudioConf = false;
  let isInPrivateConf = null;
  if (myUid && videoOwner) {
    const amInPrivateConf = (state.room.roster[myUid] || { privateAudioConf: null }).privateAudioConf;
    isInPrivateConf = (state.room.roster[videoOwner] || { privateAudioConf: null }).privateAudioConf;
    isInDifferentAudioConf = amInPrivateConf !== isInPrivateConf;
  }
  isInPrivateConf = Boolean(isInPrivateConf);

  if (myUid === uid) {
    stream = state.room.localvideo_stream;
    screen = state.room.screenStream;
  } else {
    stream = rosterUser.stream;
    screen = rosterUser.screen;
  }
  return ({
    screen,
    isTalking,
    stream,
    isVideoMuted,
    isScreenMuted,
    myself,
    isFeatured,
    featuredType,
    hasHandRaised,
    shouldMirrorVideo: shouldMirrorVideo(state),
    isInPrivateConf,
    isInDifferentAudioConf,
  });
};


function RosterAvatar(props: ExtendedProps) {
  const classes = useStyles();
  const {
    isTalking,
    isVisible,
    myself,
    name,
    screen,
    stream,
    isVideoMuted,
    isScreenMuted,
    uid,
    isFeatured,
    featuredType,
    hasHandRaised,
    shouldMirrorVideo,
    isInPrivateConf,
    isInDifferentAudioConf
  } = props;

  const dispatch = useDispatch();

  React.useEffect(
    () => {
      if (!myself && isVisible !== null && isMobileOnly) {
        if (stream && !isVideoMuted) {
          const isStreamFeatured = isFeatured && featuredType === 'stream';
          const hideStream = (isVisible) ? false : !isStreamFeatured;
          dispatch(toggleVideoMute(uid, hideStream));
        }
        if (screen && !isScreenMuted) {
          const isScreenFeatured = isFeatured && featuredType === 'screen';
          const hideScreen = (isVisible) ? false : !isScreenFeatured;
          dispatch(toggleVideoMute(`${uid}_screen`, hideScreen));
        }
      }
    }, [myself, stream, screen, isVideoMuted, isScreenMuted, isFeatured, featuredType, isVisible, uid, dispatch]
  );

  const onSelectStream = () => {
    showStream('stream', uid, dispatch);
    logEvent(Event.MOBILE_SWITCH_STREAM, { 'stream_set': 'video' });
  };
  const onSelectScreen = () => {
    showStream('screen', uid, dispatch);
    logEvent(Event.MOBILE_SWITCH_STREAM, { 'stream_set': 'screen' });
  };

  const getScreenIcon = () => {
    return (
      <div className={classes.badgeContent}>
        <IconButton
          onClick={onSelectScreen}
          className={classes.screenButton}
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          disabled={!isMobileOnly}
          size='small'>
          <IconShareScreen size={20} />
        </IconButton>
      </div>
    );
  };

  const onHandRaisedClick = () => {
    return isMobileOnly
      ? onSelectStream()
      : undefined;
  };

  const getVideoElement = () => {
    return (
      <div onClick={onSelectStream}>
        <LoadingVideoElement
          user={uid}
          mirrored={(myself && shouldMirrorVideo) ? true : false}
          addVideoMutedIconOverlay={false}
          stream={stream}
          circled={true}
        />
      </div>
    );
  };

  // display a lock icon for users in different private conferences
  // using useTimeout to avoid fluctuations when users switch between conferences
  const [showPrivateConfLock, setShowPrivateConfLock] =
    React.useState<boolean>(isInDifferentAudioConf && isInPrivateConf);

  const delayedSetShowPrivateConfLock = React.useCallback(() => {
    setShowPrivateConfLock(isInDifferentAudioConf && isInPrivateConf);
  }, [setShowPrivateConfLock, isInDifferentAudioConf, isInPrivateConf]);

  useTimeout(delayedSetShowPrivateConfLock, 100);

  const getStateOverlay = () => {
    if (hasHandRaised) {
      return (
        <div className={classes.stateContainer} onClick={onHandRaisedClick}>
          <MuiAvatar className={classes.stateAvatar}>
            <IconHandUp size={22} color={iconColors.normal} />
          </MuiAvatar>
        </div>
      );
    } else if (showPrivateConfLock) {
      return (
        <div className={classes.stateContainer}>
          <MuiAvatar className={classes.stateAvatar}>
            <IconLocked size={22} color={iconColors.normal} />
          </MuiAvatar>
        </div>
      );
    }
    return null;
  };

  const getVideoAvatar = () => {
    return (
      <div style={{ position: "relative" }}>
        {getStateOverlay()}
        <React.Fragment>
          {isMobileOnly && stream && !isVideoMuted
            ? getVideoElement()
            : <Avatar name={name} />
          }
        </React.Fragment>
      </div>
    );
  };

  const getScreenBadge = () => {
    return (
      <Badge
        classes={{ badge: classes.badgeStyle }}
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={getScreenIcon()}
      >
        {getVideoAvatar()}
      </Badge>
    );
  };

  const getAvatar = () => {
    return (
      <React.Fragment>
        {screen
          ? getScreenBadge()
          : getVideoAvatar()
        }
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      {getAvatar()}
      {isTalking &&
        <RippleBadge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          classes={{ badge: classes.badge }}
        >
        </RippleBadge>
      }
    </div>
  );
}


export default connect(mapStateToProps)(RosterAvatar);
