import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { iconColors as colors } from '../../colors';
import { togglePublishVideo } from '../../lib/actions/room';
import { saveVideoEnabled, toggleDeviceSettings } from '../../lib/actions/settings';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { isVideoStarting } from '../../lib/reduxSelectors/room';
import { haveVideoStream, isOwnVideoMuted } from '../../lib/reduxSelectors/user';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import LocalStorage from '../../localStorage';
import { IconVideo, IconVideoOff } from '../IconSet';

import messages from './buttonsMessages';
import ToolbarButton from './ToolbarButton';

import useStyles from './buttonsStyle';


function VideoButton(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const muteVideo = React.useCallback(
    () => {
      if (props.canPublishVideo) {
        if (props.hasVideoStream) {
          dispatch(togglePublishVideo(!props.isVideoMuted));
          const action = !props.isVideoMuted ? 'mute' : 'unmute';
          logEvent(Event.MUTE_MY_VIDEO, { 'from': 'own_controls', 'action': action });
        } else {
          dispatch(toggleDeviceSettings());
          logEvent(Event.MEDIA_SETTINGS, { 'from': 'own_controls' });
          dispatch(saveVideoEnabled(true, new LocalStorage()));
          logEvent(Event.MUTE_MY_VIDEO, { 'from': 'own_controls', 'action': 'unmute' });
        }
      }
    }
    , [dispatch, props.isVideoMuted, props.hasVideoStream, props.canPublishVideo]
  );

  const label = props.isVideoMuted ? formatMessage(messages.enable) : formatMessage(messages.disable);
  const selected = props.isVideoMuted ? classes.buttonsSelected : null;
  const cls = classNames(classes.buttons, selected);

  let onClick = muteVideo;
  if (props.isVideoStarting) {
    onClick = () => {};
  }

  if (props.canPublishVideo) {
    return (
      <ToolbarButton
        icon={(props.isVideoMuted || !props.hasVideoStream)
          ? <IconVideoOff color={colors.contrast} size={28} />
          : <IconVideo size={28} />
        }
        text={label}
        buttonProps={{ className: cls, onClick: onClick }}
      />
    );
  }
  else {
    return null;
  }
}


type MappedProps = {
  isVideoMuted: boolean;
  hasVideoStream: boolean;
  canPublishVideo: boolean;
  isVideoStarting: boolean;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const user = getWsUserId(state.websocket);
  let isStreamStarting = false;
  if (user){
    isStreamStarting = isVideoStarting(state, user);
  }
  return {
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
    isVideoMuted: isOwnVideoMuted(state),
    isVideoStarting: isStreamStarting,
    hasVideoStream: haveVideoStream(state)
  };
};


export default connect(mapStateToProps)(VideoButton);
