import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { iconColors as colors } from '../../colors';
import { toggleOwnAudioMute } from '../../lib/actions/room';
import { toggleDeviceSettings } from '../../lib/actions/settings';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { haveAudioInputTrack } from '../../lib/reduxSelectors/user';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { IconMicrophone, IconMicrophoneOff } from '../IconSet';

import messages from './buttonsMessages';
import ToolbarButton from './ToolbarButton';

import useStyles from './buttonsStyle';


function MicButton(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { haveAudioInputTrack } = props;

  const muteAudio = React.useCallback(
    () => {
      if (!haveAudioInputTrack) {
        dispatch(toggleDeviceSettings());
        logEvent(Event.MEDIA_SETTINGS, { 'from': 'own_controls' });
      }
      else {
        dispatch(toggleOwnAudioMute(!props.isAudioMuted));
        const action = !props.isAudioMuted ? 'mute' : 'unmute';
        logEvent(Event.MUTE_MY_MICROPHONE, { 'from': 'own_controls', 'action': action });
      }
    }, [dispatch, props.isAudioMuted, haveAudioInputTrack]
  );

  let label = props.isAudioMuted ? formatMessage(messages.enable) : formatMessage(messages.disable);
  if (!haveAudioInputTrack) {
    label = formatMessage(messages.noAudioInputAvailable);
  }

  const selected = (props.isAudioMuted || !haveAudioInputTrack) ? classes.buttonsSelected : null;
  const cls = classNames(classes.buttons, selected);

  return (
    <ToolbarButton
      icon={(props.isAudioMuted || !haveAudioInputTrack)
        ? <IconMicrophoneOff color={colors.contrast} size={28} />
        : <IconMicrophone size={28} />
      }
      text={label}
      buttonProps={{ className: cls, onClick: muteAudio }}
    />
  );
}


type MappedProps = {
  isAudioMuted: boolean;
  haveAudioInputTrack: boolean;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const user = getWsUserId(state.websocket);
  let isAudioMuted = false;
  if (user) {
    isAudioMuted = (state.room.roster[user] || { isAudioMuted: false }).isAudioMuted;
  }
  return {
    haveAudioInputTrack: haveAudioInputTrack(state),
    isAudioMuted,
  };
};


export default connect(mapStateToProps)(MicButton);
