/* tslint:disable */
/* eslint-disable */
/* @relayHash 77ebe5fc38d4a44d6912135ce63c95eb */

import { ConcreteRequest } from "relay-runtime";
export type FilterMatch = "CONTAINS" | "ENDS" | "EQUALS" | "GT" | "GTE" | "LT" | "LTE" | "STARTS" | "%future added value";
export type SearchFilter = {
    key: string;
    match?: FilterMatch | null;
    value: string;
};
export type searchUserQueryVariables = {
    first?: number | null;
    filters?: Array<SearchFilter | null> | null;
    advancedFilter?: string | null;
};
export type searchUserQueryResponse = {
    readonly search: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id?: string;
                readonly email?: string | null;
                readonly name?: string | null;
                readonly surname?: string | null;
                readonly username?: string | null;
            } | null;
        } | null> | null;
    } | null;
};
export type searchUserQuery = {
    readonly response: searchUserQueryResponse;
    readonly variables: searchUserQueryVariables;
};



/*
query searchUserQuery(
  $first: Int
  $filters: [SearchFilter]
  $advancedFilter: String
) {
  search(first: $first, searchType: USER, filters: $filters, advancedFilter: $advancedFilter) {
    edges {
      node {
        __typename
        ... on User {
          id
          email
          name
          surname
          username
        }
        ... on Node {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "[SearchFilter]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "advancedFilter",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "advancedFilter",
    "variableName": "advancedFilter"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Literal",
    "name": "searchType",
    "value": "USER"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "surname",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "searchUserQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SearchResultConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SearchResultEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": null,
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "type": "User",
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "searchUserQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "search",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "SearchResultConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SearchResultEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": null,
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "type": "User",
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "searchUserQuery",
    "id": null,
    "text": "query searchUserQuery(\n  $first: Int\n  $filters: [SearchFilter]\n  $advancedFilter: String\n) {\n  search(first: $first, searchType: USER, filters: $filters, advancedFilter: $advancedFilter) {\n    edges {\n      node {\n        __typename\n        ... on User {\n          id\n          email\n          name\n          surname\n          username\n        }\n        ... on Node {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '07d77611c603917aa3e6a1f5974bb0ca';
export default node;
