import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';

import { logEvent, Event } from '../../lib/analytics';
import { SupportLinksContext } from '../../lib/contexts';
import { State } from '../../lib/reducers';


const messages = defineMessages({
  waitingRoomPrivacyBody: { id: 'waitingRoomPrivacyBody' },
  manualLabel: { id: 'manualUrlLabel' },
  privacyLabel: { id: 'privacyUrlLabel' },
  termsLabel: { id: 'termsUrlLabel' }
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    privacy: {
      padding: theme.spacing(2, 0.5, 0.5, 0.5),
      color: theme.palette.common.black,
      opacity: 0.6,
      fontSize: 10,
      fontStyle: 'italic'
    },
    links: {
      padding: theme.spacing(0, 0.5),
      color: theme.palette.primary.main
    }
  })
);


function SupportInfo(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const supportLinksContext = React.useContext(SupportLinksContext);
  const {
    hideManual,
    hidePrivacy,
    hideTerms,
    manualUrl,
    privacyUrl,
    termsUrl
  } = props;

  const showManual = manualUrl && !hideManual;
  const showPrivacy = privacyUrl && !hidePrivacy;
  const showTerms = termsUrl && !hideTerms;

  const showLinks = showManual || showPrivacy || showTerms;

  const onManualClick = React.useCallback(
    () => {
      logEvent(Event.SUPPORT_LINK, { 'support_type': 'manual', 'from': supportLinksContext  });
    }, [supportLinksContext]
  );

  const onPrivacyClick = React.useCallback(
    () => {
      logEvent(Event.SUPPORT_LINK, { 'support_type': 'privacy', 'from': supportLinksContext  });
    }, [supportLinksContext]
  );

  const onTermsClick = React.useCallback(
    () => {
      logEvent(Event.SUPPORT_LINK, { 'support_type': 'terms', 'from': supportLinksContext });
    }, [supportLinksContext]
  );

  const getLinks = () => {
    const getSeparator = () => {
      return '\u00a0 \u00a0 \u00B7 \u00a0 \u00a0';
    };
    return (
      <Typography color='primary' variant='caption' align='center' className={classes.links}>
        { showManual &&
          <React.Fragment>
            <Link href={manualUrl} target="_blank" rel="noopener noreferrer" onClick={onManualClick}>
              {formatMessage(messages.manualLabel)}
            </Link>
            {(showPrivacy || showTerms) && getSeparator()}
          </React.Fragment>
        }
        { showPrivacy &&
          <React.Fragment>
            <Link href={privacyUrl} target="_blank" rel="noopener noreferrer" onClick={onPrivacyClick}>
              {formatMessage(messages.privacyLabel)}
            </Link>
            {showTerms && getSeparator()}
          </React.Fragment>
        }
        { showTerms &&
          <React.Fragment>
            <Link href={termsUrl} target="_blank" rel="noopener noreferrer" onClick={onTermsClick}>
              {formatMessage(messages.termsLabel)}
            </Link>
          </React.Fragment>
        }
      </Typography>
    );
  };

  return (
    <React.Fragment>
      { showLinks && getLinks() }
    </React.Fragment>
  );
}


type Props = {
  hideManual?: boolean;
  hidePrivacy?: boolean;
  hideTerms?: boolean;
}

type MappedProps = {
  manualUrl: string | undefined;
  privacyUrl: string | undefined;
  termsUrl: string | undefined;
};

const mapStateToProps = (state: State): MappedProps => ({
  manualUrl: state.appconfig.urls ? state.appconfig.urls.manual : undefined,
  privacyUrl: state.appconfig.urls ? state.appconfig.urls.privacy : undefined,
  termsUrl: state.appconfig.urls ? state.appconfig.urls.terms : undefined
});

type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(SupportInfo);
