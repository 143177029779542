import React, { Component } from 'react';
import { injectIntl, } from 'react-intl';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import VideocamOff from '@material-ui/icons/VideocamOff';

import IntlPropType from '../../intl';

import style from './style';


class NoVideoElement extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <VideocamOff className={classes.icon} />
      </div>
    );
  }
}


NoVideoElement.propTypes = {
  intl: IntlPropType.isRequired,
  classes: PropTypes.object.isRequired,
};


export default withStyles(style)(injectIntl(NoVideoElement));
