import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

import { toggleBackgroundSettings } from '../../lib/actions/settings';
import { logEvent, Event } from '../../lib/analytics';
import { IconBlur } from '../IconSet';

import messages from './buttonsMessages';

import useStyles from './buttonsStyle';


function BackgroundBlurButton(_props: {}) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();


  const handlebackgroundBlurSwitch = React.useCallback(
    () => {
      logEvent(Event.BACKGROUND_SETTINGS, { 'from': 'own_controls' });
      dispatch(toggleBackgroundSettings());
    }, [dispatch]
  );

  return (
    <Badge className={classes.betaBadge} overlap="rectangle" badgeContent="BETA">
      <MenuItem onClick={handlebackgroundBlurSwitch}>
        <ListItemIcon>
          <IconBlur size={32} />
        </ListItemIcon>
        <ListItemText primary={formatMessage(messages.backgroundSettings)} />
      </MenuItem>
    </Badge>
  );
}


export default BackgroundBlurButton;
