import { v4 } from 'uuid';


const { doFetch, abortFetch, getControllers } = (
  () => {
    const controllers: { [key: string]: AbortController } = {};
    const cleanup = (uuid: string) => {
      if (controllers[uuid]) {
        delete (controllers[uuid]);
      }
    };

    return ({
      doFetch: (url: RequestInfo, request: RequestInit) => {
        const abortController = new AbortController();
        const uuid = v4();
        controllers[uuid] = abortController;

        const req = { ...request, signal: abortController.signal };
        const p = fetch(url, req)
          .then((res) => { cleanup(uuid); return res; })
          .catch((err) => { cleanup(uuid); throw err; });
        return { response: p, uuid };
      },

      abortFetch: (uuid: string) => {
        const c = controllers[uuid];
        if (c) {
          c.abort();
          cleanup(uuid);
        }
      },

      getControllers: () => {
        return controllers;
      }
    });
  }
)();


export { abortFetch, doFetch, getControllers };
