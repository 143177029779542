import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import IntlPropType from '../../intl';
import ClosableDialog from '../ClosableDialog';


const messages = defineMessages({
  ok: { id: 'ok' },
  cancel: { id: 'cancel' },
  content: { id: 'endMeetingDialogContent' },
  title: { id: 'endMeetingDialogTitle' },
});


class EndMeetingDialog extends Component {
  render() {
    return (
      <ClosableDialog
        open={this.props.open}
        onClose={this.props.onCancel}
        disableBackdropClick
        maxWidth="xs"
        title={this.props.intl.formatMessage(messages.title)}
      >
        <DialogContent>
          <DialogContentText>
            {this.props.intl.formatMessage(messages.content)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onConfirm} variant="contained" color="primary">
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </ClosableDialog>
    );
  }
}


EndMeetingDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: IntlPropType.isRequired,
};


export default injectIntl(EndMeetingDialog);
