import React  from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { 
  createStyles,
  DialogContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';

import ClosableDialog from '../ClosableDialog';


const messages = defineMessages({
  shortcutsDialogTitle: { id: 'shortcutsDialogTitle' },
  shortcutKeyLabel: { id: 'shortcutKeyLabel' },
  shortcutDescriptionLabel: { id: 'shortcutDescriptionLabel' },
  muteMicKey: { id: 'shortcutMuteMicKey' },
  muteMicDescription: { id: 'shortcutMuteMicDescription' },
  muteVideoKey: { id: 'shortcutMuteVideoKey' },
  muteVideoDescription: { id: 'shortcutMuteVideoDescription' },
  openRosterKey: { id: 'shortcutOpenRosterKey' },
  openRosterDescription: { id: 'shortcutOpenRosterDescription' },
  openChatKey: { id: 'shortcutOpenChatKey' },
  openChatDescription: { id: 'shortcutOpenChatDescription' },
  raiseHandKey: { id: 'shortcutRaiseHandKey' },
  raiseHandDescription: { id: 'shortcutRaiseHandDescription' },
  lockRoomKey: { id: 'shortcutLockRoomKey' },
  lockRoomDescription: { id: 'shortcutLockRoomDescription' },
  fullscreenKey: { id: 'shortcutFullscreenKey' },
  fullscreenDescription: { id: 'shortcutFullscreenDescription' },
  shortcutsFooter: { id: 'shortcutsFooter' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginBottom: theme.spacing(4),
    },
    footer: {
      fontStyle: 'italic',
    }
  })
);


type Props = {
  onClose: () => void;
};

function createShortcutEntry(key: string, description: string) {
  return { key, description };
}


function ShortcutDialog(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const rows = [
    createShortcutEntry(formatMessage(messages.muteMicKey), formatMessage(messages.muteMicDescription)),
    createShortcutEntry(formatMessage(messages.muteVideoKey), formatMessage(messages.muteVideoDescription)),
    createShortcutEntry(formatMessage(messages.openRosterKey), formatMessage(messages.openRosterDescription)),
    createShortcutEntry(formatMessage(messages.openChatKey), formatMessage(messages.openChatDescription)),
    createShortcutEntry(formatMessage(messages.raiseHandKey), formatMessage(messages.raiseHandDescription)),
    createShortcutEntry(formatMessage(messages.lockRoomKey), formatMessage(messages.lockRoomDescription)),
    createShortcutEntry(formatMessage(messages.fullscreenKey), formatMessage(messages.fullscreenDescription)),
  ];

  return (
    <ClosableDialog
      open={true}
      onClose={props.onClose}
      title={formatMessage(messages.shortcutsDialogTitle)}
    >
      <DialogContent>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{formatMessage(messages.shortcutKeyLabel)}</TableCell>
                <TableCell>{formatMessage(messages.shortcutDescriptionLabel)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.key}>
                  <TableCell component="th" scope="row">
                    {row.key}
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant='caption' className={classes.footer}>
          {formatMessage(messages.shortcutsFooter)}
        </Typography>
      </DialogContent>
    </ClosableDialog>
  );
}


export default ShortcutDialog;
