import React from 'react';
import { connect } from 'react-redux';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import loading from '../../assets/icons/loading.gif';
import { State } from '../../lib/reducers';
import { isVideoStarting } from '../../lib/reduxSelectors/room';


import VideoElement from '.';


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    loadingMaskContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }
  })
);


function LoadingVideoElement(props: ExtendedProps) {
  const classes = useStyles();
  const {
    addVideoMutedIconOverlay,
    circled,
    fit,
    fullHeight,
    isVideoStarting,
    mirrored,
    rounded,
    stream,
    user,
  } = props;

  if (isVideoStarting || !stream) {
    return (
      <div className={classes.loadingMaskContainer}
        style={{ backgroundImage: `url(${loading})` }}>
      </div>
    );
  }

  return (
    <VideoElement
      addVideoMutedIconOverlay={addVideoMutedIconOverlay}
      circled={circled}
      fit={fit}
      fullHeight={fullHeight}
      mirrored={mirrored}
      rounded={rounded}
      src={stream}
      user={user}
    />
  );
}


type MappedProps = {
  isVideoStarting: ReturnType<typeof isVideoStarting>;
}


type Props = {
  addVideoMutedIconOverlay?: boolean;
  circled?: boolean;
  fit?: boolean;
  fullHeight?: boolean;
  mirrored?: boolean;
  rounded?: boolean;
  stream: object | null;
  user: string;
};


type ExtendedProps = Props & MappedProps;


const mapStateToProps = (state: State, props: Props): MappedProps => {
  return {
    isVideoStarting: isVideoStarting(state, props.user),
  };
};


export default connect(mapStateToProps)(LoadingVideoElement);
