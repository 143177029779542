import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import Badge from '@material-ui/core/Badge';
import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';

import { iconColors as colors } from '../../colors';
import { State } from '../../lib/reducers';
import {
  amModerator,
  isWebinarLayout,
  amWebinarPresenter,
  isRosterOpen,
} from '../../lib/reduxSelectors/room';
import {
  getNumberOfRaisedHands,
  disableRoster,
  getLatestRaisedHand,
  ObservedUser,
  showRaisedHandBadge
} from '../../lib/reduxSelectors/roster';
import { usePrevious } from '../../lib/utils/hooks';
import { sendNotification } from '../../notification';
import { IconUsers, IconUsersSelected, IconHandUp } from '../IconSet';


const messages = defineMessages({
  raisedHand: { id: 'raisedHand' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    badgeContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  })
);


function RosterIcon(props: ExtendedProps) {
  const classes = useStyles();
  const {
    numberOfRaisedHands,
    latestRaisedHand,
    isRosterOpen,
    amIModerator,
    amWebinarPresenter,
    isWebinarLayout,
    disableRoster,
    showBadge,
    myUsername,
    siteTitle,
  } = props;

  const { formatMessage } = useIntl();

  const canShowNotification = ((isWebinarLayout && amWebinarPresenter) || amIModerator) && showBadge;

  // send notification on latestRaisedHand prop change
  const prevRaisedHand = usePrevious(latestRaisedHand, {} as ObservedUser);
  React.useEffect(
    () => {
      if (canShowNotification && latestRaisedHand) {
        const showNotification = !(document.hasFocus()) && latestRaisedHand.username !== myUsername
          && (prevRaisedHand ? (prevRaisedHand.raisedHandSince < latestRaisedHand.raisedHandSince) : true);
        if (showNotification) {
          const title = siteTitle;
          const lastMessage = formatMessage(messages.raisedHand, { dname: latestRaisedHand.display });
          sendNotification(title, lastMessage);
        }
      }
    },
    [latestRaisedHand, prevRaisedHand, myUsername, formatMessage, siteTitle, canShowNotification]
  );

  const getBadgeContent = () => {
    if (showBadge) {
      return (
        <div className={classes.badgeContent}>
          {numberOfRaisedHands} <IconHandUp color={colors.contrast} size={12} />
        </div>
      );
    } else {
      return 0;
    }
  };

  const theme = useTheme();
  const mobileDisabledColor = theme.palette.text.secondary;
  const defaultColor = theme.palette.common.black;
  return (
    <Badge badgeContent={getBadgeContent()} color="secondary">
      { isRosterOpen
        ? <IconUsersSelected size={28} />
        // isMobileOnly is required because on MeetingBar the disabled color is enforced by the listitem component
        // unfortunately in the MeetingMobileBar the Tab component do not allow to change the icon color in an easy way
        : <IconUsers color={disableRoster && isMobileOnly ? mobileDisabledColor : defaultColor} size={28} />
      }
    </Badge>
  );
}


type Props = {}

type MappedProps = {
  numberOfRaisedHands: number;
  latestRaisedHand: ObservedUser | null;
  amIModerator: boolean;
  isWebinarLayout: boolean;
  amWebinarPresenter: boolean;
  disableRoster: boolean;
  showBadge: boolean;
  myUsername: State['session']['username'];
  siteTitle: State['appconfig']['site_title'];
  isRosterOpen: boolean;
}

type ExtendedProps = Props & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const nOfRaisedHand = getNumberOfRaisedHands(state);
  return ({
    numberOfRaisedHands: nOfRaisedHand,
    latestRaisedHand: getLatestRaisedHand(state),
    amIModerator: amModerator(state),
    isWebinarLayout: isWebinarLayout(state),
    amWebinarPresenter: amWebinarPresenter(state),
    disableRoster: disableRoster(state),
    showBadge: showRaisedHandBadge(state) && nOfRaisedHand > 0,
    myUsername: state.session.username,
    siteTitle: state.appconfig.site_title,
    isRosterOpen: isRosterOpen(state)
  });
};


export default connect(mapStateToProps)(RosterIcon);
