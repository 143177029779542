import { getLogger } from '../logger';

import CallstatsIO from './adapters/callstatsio';
import { User, Config, StreamDirection, StreamEvent, StreamUsage, WebrtcFunction } from './adapters/types';
import Voismart from './adapters/voismart';


let rtcstats: null | CallstatsIO | Voismart = null;


export function statsStart(
  appId: string,
  appSecret: string,
  userId: User,
  parsedUa: undefined | object,
  config: Config,
  backend = 'callstatsio'
) {
  if (rtcstats) {
    return;
  }

  const adapter = backend === 'callstatsio' ? CallstatsIO : Voismart;

  try {
    rtcstats = new adapter();
    rtcstats.start(appId, appSecret, userId, parsedUa, config);
  }
  catch (error) {
    const logger = getLogger('callstats');
    logger.error('error starting call statistics:', error);
  }
}


export function statsAddStream(
  pc: RTCPeerConnection,
  conferenceID: string,
  localUserId: string,
  remoteUserId: string,
  usage: StreamUsage,
  direction: StreamDirection
) {
  if (!pc) throw new Error("statsAddStream: Missing PeerConnection!");
  if (!conferenceID) throw new Error("statsAddStream: Missing Conference ID!");
  if (!localUserId) throw new Error("statsAddStream: Missing Local User ID!");
  if (!remoteUserId) throw new Error("statsAddStream: Missing Remote User ID!");
  if (!usage) throw new Error("statsAddStream: Missing usage");
  if (!direction) throw new Error("statsAddStream: Missing stream direction");

  if (!rtcstats) {
    return;
  }
  try {
    rtcstats.addStream(pc, conferenceID, localUserId, remoteUserId, usage, direction);
  }
  catch (error) {
    const logger = getLogger('callstats');
    logger.error('error calling startsAddStream:', error);
  }
}


export function sendStreamEvent(pc: RTCPeerConnection, event: StreamEvent,
  conferenceID: string, eventData: object = {}) {
  if (!pc) throw new Error("sendStreamEvent: Missing PeerConnection!");
  if (!conferenceID) throw new Error("sendStreamEvent: Missing Conference ID!");
  if (!event) throw new Error("sendStreamEvent: Missing Event Type!");

  if (!rtcstats) {
    return;
  }

  try {
    rtcstats.sendStreamEvent(pc, event, conferenceID, eventData);
  }
  catch (error) {
    const logger = getLogger('callstats');
    logger.error('error calling sendStreamEvent:', error);
  }

}


export function sendConferenceMetadata(conferenceID: string, metadata: object) {
  if (!conferenceID) throw new Error("sendConferenceMetadata: Missing Conference ID!");
  if (!metadata) throw new Error("sendConferenceMetadata: Missing conference metadata");

  if (!rtcstats) {
    return;
  }
  try {
    rtcstats.sendConferenceMetadata(conferenceID, metadata);
  }
  catch (error) {
    const logger = getLogger('callstats');
    logger.error('error sending conference metadata:', error);
  }
}


export function sendUserMetadata(conferenceID: string, userID: string, metadata: object) {
  if (!conferenceID) throw new Error("sendUserMetadata: Missing Conference ID!");
  if (!userID) throw new Error("sendUserMetadata: Missing User ID!");
  if (!metadata) throw new Error("sendUserMetadata: Missing user metadata");

  if (!rtcstats) {
    return;
  }
  rtcstats.sendUserMetadata(conferenceID, userID, metadata);
}


export function reportError(conferenceID: string, userID: string, failedFunction: WebrtcFunction) {
  if (!conferenceID) throw new Error("reportError: Missing Conference ID!");
  if (!userID) throw new Error("reportError: Missing User ID!");

  if (!rtcstats) {
    return;
  }
  rtcstats.reportError(conferenceID, userID, failedFunction);
}
