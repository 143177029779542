const style = theme => ({
  dialogActions: {
    paddingTop: theme.spacing(3),
  },
  loginButton: {
    width: '100%',
  },
  input: {
    paddingTop: theme.spacing(3),
  },
  codeMessage: {
    textAlign: 'center',
    paddingTop: theme.spacing(3),
  },
  inputField: {
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%",
  },
  loginActions: {
    justifyContent: 'flex-end',
  },
  oauthButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  formHelperText: {
    marginLeft: "7px"
  },
  fullWidth: {
    width: '100%',
  },
});

export default style;
