const style = theme => ({
  content: {
    overflow: 'unset',
  },

  text: {
    marginBottom: theme.spacing(),
  }
});

export default style;
