/* tslint:disable */
/* eslint-disable */
/* @relayHash cc8f0d4e3263278295eff24341f9a4a8 */

import { ConcreteRequest } from "relay-runtime";
export type minimalSessionQueryVariables = {};
export type minimalSessionQueryResponse = {
    readonly loggedUser: {
        readonly session: {
            readonly realm: string;
            readonly username: string;
        };
    };
};
export type minimalSessionQuery = {
    readonly response: minimalSessionQueryResponse;
    readonly variables: minimalSessionQueryVariables;
};



/*
query minimalSessionQuery {
  loggedUser {
    session {
      realm
      username
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "loggedUser",
    "storageKey": null,
    "args": null,
    "concreteType": "LoggedUserData",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "session",
        "storageKey": null,
        "args": null,
        "concreteType": "Session",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "realm",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "username",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "minimalSessionQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "minimalSessionQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "minimalSessionQuery",
    "id": null,
    "text": "query minimalSessionQuery {\n  loggedUser {\n    session {\n      realm\n      username\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '1b612d08746e14b06faf82444aa8e16b';
export default node;
