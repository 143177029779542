import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { useRelayEnvironment } from 'relay-hooks';

import { Checkbox, TextField, Typography, FormGroup, FormControlLabel } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getUsers, searchUsers, User, searchUserById } from '../../lib/api/relay/searchUser';
import useDebounce from '../../lib/utils/debounce';


const messages = defineMessages({
  sendAsLabel: { id: 'sendAsLabel' },
  sendAsInsertOrganizerEmail: { id: 'sendAsInsertOrganizerEmail' },
  sendAsOrganizerNotFound: { id: 'sendAsOrganizerNotFound' },
  sendAsOrganizerFillText: { id: 'sendAsOrganizerFillText' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    user: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    hidden: {
      visibility: 'hidden'
    }
  })
);


type Props = {
  setUseOrganizer: (use: boolean) => void;
  setScheduleMeetingAsId: (id: string | null) => void;
  useOrganizer: boolean;
  scheduleMeetingAsId: string | null;
  isUpdate: boolean;
}


function ItemUser(props: { name: User['name']; surname: User['surname']; email: User['email'] }) {
  const classes = useStyles();
  const { name, surname, email } = props;
  return (
    <div className={classes.user}>
      <Typography variant={"body2"}>{`${name} ${surname}`}</Typography>
      <Typography color="textSecondary" variant={"caption"}>{email}</Typography>
    </div>
  );
}


function ScheduleAsForm(props: Props) {
  const {
    setUseOrganizer,
    setScheduleMeetingAsId,
    useOrganizer,
    scheduleMeetingAsId,
    isUpdate
  } = props;
  const { formatMessage } = useIntl();
  const relayEnvironment = useRelayEnvironment();
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);
  const [options, setOptions] = React.useState<User[]>([]);
  const [text, setText] = React.useState<string>('');
  const debouncedText = useDebounce(text, 500);

  React.useEffect(
    () => {
      if (scheduleMeetingAsId && isUpdate) {
        searchUserById(relayEnvironment, scheduleMeetingAsId)
          .then((data) => {
            const users = getUsers(data);
            if (users) {
              setText(users[0].email);
            }
          });
      }
    }, [scheduleMeetingAsId, relayEnvironment, setText, isUpdate]
  );

  React.useEffect(
    () => {
      if (debouncedText) {
        searchUsers(relayEnvironment, debouncedText)
          .then((data) => {
            const users = getUsers(data);
            if (users) {
              setOptions(users);
            }
          });
      } else {
        setOptions([]);
      }
    }, [debouncedText, relayEnvironment, setOptions]
  );

  const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setChecked(checked);
    setUseOrganizer(checked);
  };

  const onChange = (_event: object, value: User | null, _reason: string) => {
    setScheduleMeetingAsId(value ? value.id : null);
  };

  const onInputChange = (_event: object, value: string, _reason: string) => {
    setText(value);
  };

  const getOptionLabel = React.useCallback(
    (option) => {
      return option.email;
    }, []
  );

  const getOptionSelected = React.useCallback(
    (option, value) => {
      return option.email === value.email;
    }, []
  );

  const renderOption = React.useCallback(
    (option) => {
      return (
        <ItemUser name={option.name} surname={option.surname} email={option.email} />
      );
    }, []
  );

  const renderInput = React.useCallback(
    (params) => {
      const placeholder = useOrganizer && scheduleMeetingAsId && isUpdate && text
        ? text
        : formatMessage(messages.sendAsInsertOrganizerEmail);
      return (
        <TextField
          {...params}
          placeholder={placeholder}
          inputProps={{ ...params.inputProps, autoComplete: "off" }}
        />
      );
    }, [text, useOrganizer, scheduleMeetingAsId, formatMessage, isUpdate]
  );

  // Checkboxes cannot switch from controlled state to uncontrolled
  // thus we use two different components when it should appear as default checked or act normal
  const getCheckBox = () => {
    return (
      (useOrganizer && isUpdate)
        ? (
          <Checkbox
            disabled={true}
            defaultChecked={true}
          />
        )
        : (
          <Checkbox
            disabled={isUpdate}
            checked={checked}
            onChange={onCheckChange}
          />
        )
    );
  };

  const noOptionText = formatMessage((text && text.length > 1)
    ? messages.sendAsOrganizerNotFound
    : messages.sendAsOrganizerFillText
  );

  const filterOptions = React.useCallback(
    (options, _state) => {
      return options;
    }, []
  );

  const cls = (checked || useOrganizer) ? undefined : classes.hidden;

  return (
    <FormGroup className={classes.root}>
      <FormControlLabel
        control={getCheckBox()}
        label={formatMessage(messages.sendAsLabel)}
      />
      <Autocomplete
        disabled={!checked || isUpdate}
        className={cls}
        options={options}
        filterOptions={filterOptions}
        autoComplete
        autoHighlight
        size='small'
        onChange={onChange}
        onInputChange={onInputChange}
        noOptionsText={noOptionText}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        renderInput={renderInput}
      />
    </FormGroup>
  );
}


export default ScheduleAsForm;
