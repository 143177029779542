type Dim = {
  width: string;
  height: string;
}

const avatarLarge: Dim = {
  width: '320px',
  height: '180px',
};

const avatarSmall: Dim = {
  width: '160px',
  height: '90px',
};


export {
  avatarLarge,
  avatarSmall,
};
