/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MeetingPrivacy = "LOCKED" | "LOCKED_LOGGED" | "LOGGED" | "OPEN" | "%future added value";
export type MeetingType = "AUDIO_ONLY" | "LESSON" | "REGULAR" | "WEBINAR" | "%future added value";
export type View_data = {
    readonly loggedUser: {
        readonly meetings: {
            readonly pageInfo: {
                readonly startCursor: string | null;
                readonly endCursor: string | null;
                readonly hasNextPage: boolean;
                readonly hasPreviousPage: boolean;
            };
            readonly edges: ReadonlyArray<{
                readonly cursor: string | null;
                readonly node: {
                    readonly title: string;
                    readonly slug: string;
                    readonly dtStart: string;
                    readonly dtEnd: string;
                    readonly id: string;
                    readonly userId: string;
                    readonly organizerId: string;
                    readonly domainId: string;
                    readonly notes: string | null;
                    readonly type: MeetingType;
                    readonly meta: {
                        readonly privacy: MeetingPrivacy | null;
                    };
                    readonly attendees: ReadonlyArray<{
                        readonly email: string;
                    }>;
                } | null;
            } | null> | null;
        } | null;
    };
    readonly " $refType": "View_data";
};
export type View_data$data = View_data;
export type View_data$key = {
    readonly " $data"?: View_data$data;
    readonly " $fragmentRefs": FragmentRefs<"View_data">;
};



const node: ReaderFragment = {
  "kind": "Fragment",
  "name": "View_data",
  "type": "RootQueryType",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "after",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "last",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "before",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "sorting",
      "type": "[SearchSort]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "filters",
      "type": "[SearchFilter]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "loggedUser",
      "storageKey": null,
      "args": null,
      "concreteType": "LoggedUserData",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "meetings",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "before",
              "variableName": "before"
            },
            {
              "kind": "Variable",
              "name": "filters",
              "variableName": "filters"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            },
            {
              "kind": "Variable",
              "name": "last",
              "variableName": "last"
            },
            {
              "kind": "Variable",
              "name": "sorting",
              "variableName": "sorting"
            }
          ],
          "concreteType": "MeetingConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "pageInfo",
              "storageKey": null,
              "args": null,
              "concreteType": "PageInfo",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "startCursor",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "endCursor",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasNextPage",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasPreviousPage",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "MeetingEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cursor",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Meeting",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "title",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "slug",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "dtStart",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "dtEnd",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "id",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "userId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "organizerId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "domainId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "notes",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "type",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "meta",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "MeetingMeta",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "privacy",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "attendees",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "Attendee",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "email",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'fc6df557f6712b2fcb53ec83954d1f16';
export default node;
