import { commitMutation } from 'react-relay';

import { graphql } from 'babel-plugin-relay/macro';
import { IEnvironment } from 'relay-runtime';

import {
  scheduleMeetingAsMutation,
  scheduleMeetingAsMutationResponse
} from './__generated__/scheduleMeetingAsMutation.graphql';
import { MeetingParams } from './scheduleMeeting';
import { handleSchedulingError } from './utils/errors';


const mutation = graphql`
mutation scheduleMeetingAsMutation(
  $input: ScheduleMeetingAsInput!
) {
  scheduleMeetingAs(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title
      url
      slug
      number
    }
  }
}
`;


function maybeNotifyError(response: scheduleMeetingAsMutationResponse | null) {
  if (!response || !response.scheduleMeetingAs || response.scheduleMeetingAs.errors !== null) {
    handleSchedulingError(response ? response.scheduleMeetingAs : null);
  }
}


function scheduleMeetingAs(
  environment: IEnvironment,
  { title, userId, domainId, dtStart, dtEnd, users, notes, type, meta }: MeetingParams
) {
  const attendees = users.value.map((el: string) => {
    return ({ email: el });
  });
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          title,
          userId,
          domainId,
          dtStart,
          dtEnd,
          attendees,
          notes,
          type,
          meta
        }
      },
    };
    commitMutation<scheduleMeetingAsMutation>(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { scheduleMeetingAs };
