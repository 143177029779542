import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import { WithAuthorization } from '../../authorization';
import { lockRoom } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State as RoomState } from '../../lib/reducers/room';
import { IconUnlocked, IconUnlockedSelected } from '../IconSet';
import { FirstTimePopoverDialog } from '../PopoverDialog';


const messages = defineMessages({
  lockRoom: { id: 'lockRoom' },
  unLockRoom: { id: 'unLockRoom' },
  lockRoomFirstTimeMsg: { id: 'lockRoomFirstTimeMsg' },
});


type MappedProps = {
  isLocked: boolean;
  isRequestingLockRoom: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    handleLockRoom: () => lockRoom(),
  },
  dispatch
  );

type State = {
  room: RoomState;
}

const mapStateToProps = (state: State): MappedProps => ({
  isLocked: state.room.isLocked,
  isRequestingLockRoom: state.room.isRequestingLockRoom,
});

type Props = {
} & MappedProps

type PropsType = Props & ReturnType<typeof mapDispatchToProps>

function LockRoom(props: PropsType) {
  const { formatMessage } = useIntl();
  const { isLocked, isRequestingLockRoom } = props;

  const lockRoom = (): void => {
    const action = isLocked ? 'unlock' : 'lock';
    logEvent(Event.LOCK_ROOM, { 'action': action, 'from': 'meeting_bar' });
    props.handleLockRoom();
  };
  const msg = formatMessage(props.isLocked ? messages.unLockRoom : messages.lockRoom);

  const getIcon = () => {
    return (
      <Tooltip placement="left" title={msg}>
        <div onClick={isRequestingLockRoom ? undefined : lockRoom}>
          {isLocked
            ? <IconUnlockedSelected size={28} />
            : <IconUnlocked size={28} />
          }
        </div>
      </Tooltip>
    );
  };

  const getDesktopIcon = () => {
    return (
      <ListItem button>
        <ListItemIcon>
          { getIcon() }
        </ListItemIcon>
      </ListItem>
    );
  };

  return (
    <FirstTimePopoverDialog
      configKey="LockRoom"
      message={formatMessage(messages.lockRoomFirstTimeMsg)}
    >
      { isMobileOnly ? getIcon() : getDesktopIcon() }
    </FirstTimePopoverDialog>

  );
}

const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, connect(mapStateToProps, mapDispatchToProps)(LockRoom));
