import React from "react";
import { connect } from 'react-redux';

import { createStyles, makeStyles, Theme } from "@material-ui/core";

import { logEvent, Event } from "../../lib/analytics";
import { RoomActionsContext } from "../../lib/contexts";
import { State } from "../../lib/reducers";
import { canEnableDesktopControl, canInvite, canRaiseHand } from "../../lib/reduxSelectors/user";
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { isBlurAvailable } from "../../lib/utils/pipelineDefault";
import ActionRoomMenu from "../ActionRoomMenu";
import StyledMenu from "../StyledMenu";

import BackgroundBlurButton from './BackgroundBlurButton';
import DesktopControlButton from "./DesktopControlButton";
import ExtraControlsButton from "./ExtraControlsButton";
import InviteButton from "./InviteButton";
import RaiseHandButton from "./RaiseHandButton";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      padding: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    item: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    }
  })
);

//TODO maybe refactor this in reduxSelectors/user.ts ?
export function isVideoMuted(state: State): boolean {
  const user = getWsUserId(state.websocket);
  let isVideoMuted = false;
  if (user) {
    isVideoMuted = (state.room.roster[user] || { isVideoMuted: false }).isVideoMuted;
  }
  return isVideoMuted;
}

function OwnControlsMenu(props: ExtendedProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    canEnableDesktopControl,
    canRaiseHand,
    canInvite,
    canIPublishVideo,
    isVideoMuted,
  } = props;

  const open = Boolean(anchorEl);

  const handleMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      logEvent(Event.MORE_CONTROLS);
      setAnchorEl(event.currentTarget);
    }, [setAnchorEl]
  );


  const closeMenu = React.useCallback(
    () => {
      setAnchorEl(null);
    }, [setAnchorEl]
  );

  return (
    <React.Fragment>
      <ExtraControlsButton showMenu={handleMenu} />
      <StyledMenu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeMenu}
        onClick={closeMenu}
      >
        <div className={classes.menu}>
          {canRaiseHand && <RaiseHandButton /> }
          {canInvite && <InviteButton /> }
          {canEnableDesktopControl && <DesktopControlButton />}
          {canIPublishVideo && !isVideoMuted && isBlurAvailable() && <BackgroundBlurButton />}
          <RoomActionsContext.Provider value='own_controls'>
            <ActionRoomMenu />
          </RoomActionsContext.Provider>
        </div>
      </StyledMenu>
    </React.Fragment>
  );
}


type Props = {}

type MappedProps = {
  canEnableDesktopControl: boolean;
  canRaiseHand: boolean;
  canInvite: boolean;
  canIPublishVideo: boolean;
  isVideoMuted: boolean;
}

const mapStateToProps = (state: State): MappedProps => ({
  canEnableDesktopControl: canEnableDesktopControl(state),
  canRaiseHand: canRaiseHand(state),
  canInvite: canInvite(state),
  canIPublishVideo: state.room.mediaPermissions.canPublishVideo,
  isVideoMuted: isVideoMuted(state),

});

type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(OwnControlsMenu);
