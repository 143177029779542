import React from 'react';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';


export default function DebouncedTextField(props: TextFieldProps & {onChange: (v: string) => void}) {
  const [value, setValue] = React.useState("");

  const { onChange, ...restOfProps } = props;

  React.useEffect(
    () => {
      const handler = setTimeout(() => props.onChange(value), 300);
      return () => clearTimeout(handler);
    },
    [value, props]
  );

  const onValueChange =
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setValue(ev.target.value);
    };

  return (
    <TextField onChange={onValueChange} {...restOfProps} />
  );
}
