import lodash from 'lodash';

import { State } from "../../lib/redux_types";
import { getRoster as centralGetRoster,  ObservedUser } from '../../lib/reduxSelectors/roster';


type MeetingObservedUser = Pick<ObservedUser, 'stream' | 'screen' | 'display' | 'viaPhone' | 'streamPublisherData'>;


export type MeetingObservedRoster = {
  [k: string]: MeetingObservedUser;
}


export const getRoster = (state: State): MeetingObservedRoster  => {
  const wantedKeys = ['stream', 'screen', 'display', 'viaPhone', 'streamPublisherData'];
  const roster = centralGetRoster(state);
  const users = Object.keys(roster);
  const observedRoster: MeetingObservedRoster = {};
  for (const user of users) {
    observedRoster[user] = lodash.pick(roster[user], wantedKeys) as MeetingObservedUser;
  }

  return observedRoster;
};
