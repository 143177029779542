import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { useRelayEnvironment } from 'relay-hooks';

import { setupPushNotifications, unsubscribeToPushNotifications } from '../../lib/actions/notifications';
import { PushSubscriptionLocale } from '../../lib/api/relay/__generated__/storeSubscription.graphql';
import { State } from '../../lib/reducers';
import { ReadyAuthState } from '../../lib/reducers/auth';
import { getIsAuthenticated, getToken } from '../../lib/reduxSelectors/auth';
import { usePrevious } from '../../lib/utils/hooks';
import LocalStorage from '../../localStorage';


function PushNotifications(props: ExtendedProps) {
  const {
    isAuthenticated,
    locale,
    localStore,
    pushNotificationsConfig,
    authToken,
    pushSubscription,
  } = props;

  const prevIsAuthenticated = usePrevious(isAuthenticated, null);

  const dispatch = useDispatch();
  const relay = useRelayEnvironment();

  const convertedLocale: PushSubscriptionLocale = locale.toUpperCase() === 'IT' ? 'IT' : 'EN';

  // onLogin effect
  React.useEffect(
    () => {
      if (pushNotificationsConfig && authToken && isAuthenticated && !prevIsAuthenticated) {
        dispatch(setupPushNotifications(pushNotificationsConfig, authToken, convertedLocale, localStore, relay));
      }
    },
    [
      dispatch,
      relay,
      isAuthenticated,
      prevIsAuthenticated,
      convertedLocale,
      localStore,
      pushNotificationsConfig,
      authToken
    ]
  );

  // onLogout effect
  React.useEffect(
    () => {
      if (pushNotificationsConfig && !isAuthenticated && prevIsAuthenticated && pushSubscription) {
        dispatch(unsubscribeToPushNotifications(
          pushSubscription,
          pushNotificationsConfig,
          null,
          new LocalStorage()
        ));
      }
    },
    [
      dispatch,
      isAuthenticated,
      prevIsAuthenticated,
      pushNotificationsConfig,
      pushSubscription,
    ]
  );

  return null;
}

type MappedProps = {
  pushSubscription: State['notifications']['pushSubscription'];
  pushNotificationsConfig: State['appconfig']['push_notifications'];
  authToken: ReadyAuthState['token'];
  isAuthenticated: ReadyAuthState['isAuthenticated'];
}

type Props = {
  locale: PushSubscriptionLocale;
  localStore: LocalStorage;
};

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State): MappedProps => ({
  pushSubscription: state.notifications.pushSubscription,
  pushNotificationsConfig: state.appconfig.push_notifications,
  authToken: getToken(state.auth),
  isAuthenticated: getIsAuthenticated(state.auth),
});

export default connect(mapStateToProps)(PushNotifications);
