import React from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { VideoRoom } from '../../lib/api/videoroom';
import { State } from '../../lib/reducers';
import { WebSocketReady } from '../../lib/reducers/websocket';
import { ScreenSharingOptions } from '../../lib/redux_types';
import { ScreenSourceType } from '../../lib/redux_types';
import {
  amWebinarPresenter,
  isWebinarLayout,
  isLessonLayout,
  isAudioOnlyLayout,
  amModerator,
} from '../../lib/reduxSelectors/room';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { canShowExtraControlsMenu, canToggleAudio } from '../../lib/reduxSelectors/user';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { FirstTimePopoverDialog, LocalStorageConfigBackend } from '../PopoverDialog';

import messages from './buttonsMessages';
import ExitButton from './ExitButton';
import LeavePrivateAudioButton from './LeavePrivateAudioButton';
import MicButton from './MicButton';
import OwnControlsMenu from './OwnControlsMenu';
import ShareScreenButton from './ShareScreenButton';
import SwitchCamera from './SwitchCamera';
import VideoButton from './VideoButton';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: theme.zIndex.drawer,
      height: '90px', // TODO
    },
    paper: {
      position: 'absolute',
      top: 'auto',
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      maxHeight: '100%',
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      zIndex: theme.zIndex.drawer,
      WebkitOverflowScrolling: 'touch',
      outline: 0,
      opacity: '0.8',
    },
    toolbar: {
      padding: '1em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5em',
      },
    },
    buttons: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0.2ex',
        marginRight: '0.2ex',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '2em',
        marginRight: '2em',
      },
    },
  })
);


function OwnMeetingControls(props: ExtendedProps) {
  const classes = useStyles();
  const {
    amIModerator,
    hasVideoStream,
    canPublishVideo,
    canToggleAudioMute,
    screenshareFrameRate,
    privateAudioConf,
    uid,
  } = props;

  const { formatMessage } = useIntl();

  const hideControlsTimeout = 3000;
  const easeTimeout = 500;

  const popoverConfigKey = 'videoToolbar';

  const [popoverDismissed, setPopoverDismissed] = React.useState(false);
  React.useEffect(() => {
    const ls = new LocalStorageConfigBackend();
    // FIXME: this component shouldn't know how to build the key
    setPopoverDismissed(ls.isDismissed(`FirstTimePopoverDialog::${popoverConfigKey}`));
  }, [setPopoverDismissed]
  );

  const [shown, setShown] = React.useState(!popoverDismissed);
  const preventHide = React.useRef(false);
  const timerRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  const hideControlsWhenIdle = React.useCallback(
    () => {
      if (!popoverDismissed || isMobileOnly) {
        return;
      }
      else {
        timerRef.current = setTimeout(
          () => {
            if (shown && !preventHide.current) {
              setShown(false);
            }
            if (timerRef.current) {
              timerRef.current = null;
            }
          }
          , hideControlsTimeout
        );
      }
    }
    , [shown, timerRef, hideControlsTimeout, popoverDismissed]
  );

  // cleanup timers on unmount, to avoid trying to set state on an unmounted
  // component
  React.useEffect(() => {
    hideControlsWhenIdle();
    return function cleanup() {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  });

  const dismissPopover = React.useCallback(
    () => {
      setPopoverDismissed(true);
      preventHide.current = false;
    }
    , [setPopoverDismissed]
  );

  const onMouseMoved = React.useCallback(
    () => {
      if (!shown) {
        setShown(true);
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      hideControlsWhenIdle();
    }
    , [shown, timerRef, hideControlsWhenIdle]
  );

  const onMouseEntered = React.useCallback(
    () => {
      if (!preventHide.current) preventHide.current = true;
    }
    , []
  );

  const onMouseLeft = React.useCallback(
    () => {
      if (preventHide.current) preventHide.current = false;
    }
    , []
  );

  let sharingConstraints: undefined | ScreenSharingOptions = {
    frameRate: screenshareFrameRate || undefined
  };

  if (props.isLessonLayout && !amIModerator) {
    const maxFrameRate = 3;
    const frameRate = screenshareFrameRate ? screenshareFrameRate : maxFrameRate;

    sharingConstraints = {
      frameRate: Math.min(maxFrameRate, frameRate),
    };
  }

  if (props.isRecorder) return null;

  const getControls = () => {
    return (
      <Paper
        elevation={0}
        square
        className={classes.paper}
        onMouseEnter={onMouseEntered}
        onMouseLeave={onMouseLeft}
      >
        <FirstTimePopoverDialog
          message={formatMessage(messages.manageYourControlsHere)}
          configKey={popoverConfigKey}
          onDismiss={dismissPopover}
        >
          <Toolbar
            canToggleAudioMute={canToggleAudioMute}
            sharingConstraints={sharingConstraints}
            hasVideoStream={hasVideoStream}
            canPublishVideo={canPublishVideo}
            amIModerator={amIModerator}
            privateAudioConf={privateAudioConf}
            uid={uid}
          />
        </FirstTimePopoverDialog>
      </Paper>
    );
  };

  const getSlidingBar = () => {
    return (
      <div
        className={classes.container}
        onMouseMove={onMouseMoved}
      >
        <Slide
          in={shown || isMobileOnly}
          direction='up'
          timeout={easeTimeout}
          appear={false}
        >
          {getControls()}
        </Slide>
      </div>
    );
  };

  return (
    <React.Fragment>
      { isMobileOnly ? getControls() : getSlidingBar()}
    </React.Fragment>
  );
}


type ToolbarProps = {
  canToggleAudioMute: boolean;
  sharingConstraints?: ScreenSharingOptions;
  hasVideoStream: boolean;
  canPublishVideo: boolean;
  amIModerator: boolean;
  privateAudioConf?: string | null;
  uid: WebSocketReady['uid'];
}

function Toolbar(props: ToolbarProps) {
  const classes = useStyles();
  const {
    canPublishVideo,
    canToggleAudioMute,
    sharingConstraints,
    amIModerator,
    privateAudioConf,
    uid,
  } = props;

  const showExtraControlsMenu = useSelector((state: State) => canShowExtraControlsMenu(state));

  return (
    <div className={classes.toolbar}>
      { canToggleAudioMute && <MicButton />}
      { canPublishVideo && <VideoButton />}
      { amIModerator && privateAudioConf
        ? <LeavePrivateAudioButton privateAudioConf={privateAudioConf} uid={uid} />
        : <ExitButton />
      }
      { canPublishVideo &&
        (isMobile
          ? <SwitchCamera />
          : <ShareScreenButton constraints={sharingConstraints} />
        )
      }
      { showExtraControlsMenu && <OwnControlsMenu /> }
    </div>
  );
}

type Props = {} & RouteComponentProps

type ExtendedProps = Props & MappedProps;


type MappedProps = {
  isRecorder: boolean;
  amWebinarPresenter: boolean;
  isWebinarLayout: boolean;
  isAudioLayout: boolean;
  screenSourceType: ScreenSourceType;
  isLessonLayout: boolean;
  amIModerator: boolean;
  canToggleAudioMute: boolean;
  ownerIsAudioOnly: boolean;
  hasVideoStream: boolean;
  canPublishVideo: boolean;
  screenshareFrameRate: State['session']['screenshareFrameRate'];
  privateAudioConf?: string | null;
  uid: WebSocketReady['uid'];
}


const mapStateToProps = (state: State, { match }: Props): MappedProps => {
  const myUser = getWsUserId(state.websocket);
  const me = myUser ? state.room.roster[myUser] : null;
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }

  return {
    isRecorder: isRecorder(state, match.url),
    amWebinarPresenter: amWebinarPresenter(state),
    amIModerator: amModerator(state),
    isWebinarLayout: isWebinarLayout(state),
    isAudioLayout: isAudioOnlyLayout(state),
    screenSourceType: state.room.screenSourceType,
    isLessonLayout: isLessonLayout(state),
    canToggleAudioMute: canToggleAudio(state),
    ownerIsAudioOnly: state.room.ownerIsAudioOnly,
    hasVideoStream,
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
    screenshareFrameRate: state.session.screenshareFrameRate,
    privateAudioConf: (me || {}).privateAudioConf,
    uid: myUser,
  };
};


export default withRouter(connect(mapStateToProps)(OwnMeetingControls));
