import React  from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import classNames from 'classnames';

import { makeStyles, Theme, Tooltip } from '@material-ui/core';

import { State } from '../../lib/reducers';
import { BlurValue } from '../../lib/utils/pipelineDefault';
import { Icon } from '../IconSet';


const useStyles = makeStyles((theme: Theme) => ({
  cellIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: theme.spacing(1),
  },
  cellImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  borderDefault: {
    border: `1px solid ${theme.palette.secondary.light}`
  },
  borderSelected: {
    border: `2px solid ${theme.palette.secondary.main}`,
  },
  pointerEnabled: {
    cursor: 'pointer',
  },
  pointerDisabled: {
    cursor: 'auto',
  },
}));

function BackgroundItem(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { currentBgBlurValue, currentBgBlurEnabled, item, onClick, isButtonDisabled } = props;
  const { image, label, type, value } = item;

  const handleClick = React.useCallback(
    () => {
      if (onClick) onClick(value);
    }, [onClick, value]
  );

  const borderStyle = (currentBgBlurEnabled && currentBgBlurValue === value)
    ? classes.borderSelected
    : classes.borderDefault;

  const pointerStyle = isButtonDisabled ? classes.pointerDisabled : classes.pointerEnabled;

  const getIcon = () => {
    const cls = classNames(classes.cellIcon, borderStyle, pointerStyle);
    return (
      <div className={cls} onClick={handleClick}>
        <Icon size={28} icon={image} />
      </div>
    );
  };

  const getImage = () => {
    const cls = classNames(classes.cellImage, borderStyle, pointerStyle);
    return (
      <img src={image} className={cls} onClick={handleClick} alt={formatMessage(label)} />
    );
  };

  return (
    <Tooltip placement={'top'} title={formatMessage(label)}>
      { type === 'icon' ? getIcon() : getImage() }
    </Tooltip>
  );
}


type Props = {
  item: BlurValue;
  onClick?: (value: BlurValue['value']) => void;
  isButtonDisabled: boolean;
}

type MappedProps = {
  currentBgBlurValue: State['settings']['backgroundBlurValue'];
  currentBgBlurEnabled: State['settings']['backgroundBlurEnabled'];
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State) => ({
  currentBgBlurValue: state.settings.backgroundBlurValue,
  currentBgBlurEnabled: state.settings.backgroundBlurEnabled,
});


export default connect(mapStateToProps)(BackgroundItem);
