import React from 'react';
import { connect, useDispatch } from 'react-redux';
import sizeMe, { SizeMeProps } from 'react-sizeme';

import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import {
  subscribeToVideo,
  expandStreamVideo,
  changeLayout,
  toggleVideoMute
} from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { RoomLayout, RoomLayoutStreamType } from '../../lib/redux_types';
import { IconArrowDown } from '../IconSet';
import LoadingVideoElement from '../VideoElement/LoadingVideoElement';

import Fullscreen from './Fullscreen';
import mapStateToProps, { ExtendedProps } from './PresentationLayout/state';
import usePublishersWatcher from './usePublishersWatcher';
import VideoToolbar from './VideoToolbar';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    participantsContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '250px',
      height: '100%',
      zIndex: theme.zIndex.appBar - 1,
    },
    scrollButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    buttons: {
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        background: theme.palette.grey.A100,
      },
      '&:disabled': {
        background: theme.palette.grey.A700,
      },
      marginLeft: '0.5ex',
      marginRight: '0.5ex',
    },
    fullDim: {
      width: '100%',
      height: '100%'
    },
    otherVideosColumn: {
      padding: '1em',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    videoToolbarContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: theme.zIndex.appBar - 1,
    },
  })
);


type ParticipantsOwnProps = {
  displayOffset: number;
  shownVideos: number;
  desiredVideoHeight: number;
}


function Participants(props: ExtendedProps & SizeMeProps & ParticipantsOwnProps) {
  const {
    selectedStream,
    myStreamIsSelected,
    myScreenIsSelected,
    remoteVideoStreams,
    myUserId,
    hasVideoStream,
    localVideoStream,
    localScreenStream,
    desiredVideoHeight,
    shownVideos,
    displayOffset,
  } = props;

  const dispatch = useDispatch();

  const width = Math.floor(desiredVideoHeight * 16 / 9);

  const localStreams: { uid: null | string; el: JSX.Element }[] = [];

  let idxOffset = 0;
  if (myUserId && !myStreamIsSelected && hasVideoStream) {
    // also render local video
    const setSelectedVideo = () => {
      if (myUserId) {
        logEvent(Event.SET_MAIN_VIDEO, { 'from': 'video_element', 'from_layout': 'fullscreen' });
        dispatch(expandStreamVideo(myUserId, 'stream'));
      }
    };
    localStreams.push(
      {
        uid: null,
        el:
          <div
            onDoubleClick={setSelectedVideo}
            key={idxOffset}
            style={{ position: 'relative', width, height: '100%', maxHeight: desiredVideoHeight, margin: '1ex' }}
          >
            <Fullscreen user={myUserId}>
              <LoadingVideoElement
                user={myUserId}
                rounded
                mirrored={true}
                addVideoMutedIconOverlay={true}
                stream={localVideoStream}
              />
              <VideoToolbar rounded uid={myUserId} />
            </Fullscreen>
          </div>
      }
    );
    idxOffset += 1;
  }
  if (!myScreenIsSelected && localScreenStream) {
    // also render local screen
    const setSelectedVideo = () => {
      if (myUserId) {
        logEvent(Event.SET_MAIN_VIDEO, { 'from': 'video_element', 'from_layout': 'fullscreen' });
        dispatch(expandStreamVideo(myUserId, 'screen'));
      }
    };
    localStreams.push(
      {
        uid: null,
        el:
          <div
            onDoubleClick={setSelectedVideo}
            key={idxOffset}
            style={{ position: 'relative', width, height: '100%', maxHeight: desiredVideoHeight, margin: '1ex' }}
          >
            <Fullscreen user={`${myUserId}_screen`}>
              <LoadingVideoElement
                user={`${myUserId}_screen`}
                rounded
                mirrored={false}
                addVideoMutedIconOverlay={true}
                stream={localScreenStream}
              />
              <VideoToolbar rounded uid={`${myUserId}_screen`} />
            </Fullscreen>
          </div>
      }
    );
    idxOffset += 1;
  }

  const keys = Object.keys(remoteVideoStreams);
  let participants: { uid: null | string; el: JSX.Element }[] = keys.flatMap((uid, idx) => {
    const video = remoteVideoStreams[uid];
    if (uid === selectedStream) {
      // do not rerender the main stream
      return [];
    }
    else if (video['stream'] || video['isVideoStarting']) {
      const setSelectedVideo = () => {
        let streamType: RoomLayoutStreamType = 'stream';
        if (uid.endsWith('_screen')) {
          streamType = 'screen';
        }
        logEvent(Event.SET_MAIN_VIDEO, { 'from': 'video_element', 'from_layout': 'fullscreen' });
        dispatch(expandStreamVideo(uid.replace(/_screen$/, ''), streamType));
      };

      return [
        { uid,
          el:
            <div
              onDoubleClick={setSelectedVideo}
              key={idx + idxOffset}
              style={{ position: 'relative', width, height: '100%', maxHeight: desiredVideoHeight, margin: '1ex' }}
            >
              <Fullscreen user={uid}>
                <LoadingVideoElement
                  user={uid}
                  rounded
                  mirrored={false}
                  addVideoMutedIconOverlay={true}
                  stream={video['stream']}
                />
                <VideoToolbar rounded uid={uid} />
              </Fullscreen>
            </div>
        }
      ];
    }
    else {
      return [];
    }
  });

  participants = localStreams.concat(participants);

  const len = participants.length;
  const start = Math.max(Math.min(displayOffset, len - shownVideos), 0);
  const stop = Math.min(Math.max(displayOffset + shownVideos, shownVideos), len);

  const selectedParticipants = participants.slice(start, stop);
  const restBefore = participants.slice(0, start);
  const restAfter = participants.slice(stop);
  const rest = restBefore.concat(restAfter);

  const subscribedVideosLimit = 5;

  rest.forEach((p) => {
    if (p.uid && len > subscribedVideosLimit) {
      // pause videos which are not shown
      // we check for uid not being null to avoid pausing own streams
      dispatch(toggleVideoMute(p.uid, true));
    }
  });

  selectedParticipants.forEach((p) => {
    if (p.uid && len > subscribedVideosLimit) {
      // restart videos which were not shown
      // we check for uid not being null to avoid restarting own streams
      dispatch(toggleVideoMute(p.uid, false));
    }
  });

  return (
    <>
      { selectedParticipants.map((x) => x.el) }
    </>
  );
}


function countVideos(props: ExtendedProps) {
  const {
    remoteVideoStreams,
    localScreenStream,
    hasVideoStream,
  } = props;

  // one per participant, excluding the main one
  let num = Object.keys(remoteVideoStreams).length - 1;

  // add local streams, if any
  num = hasVideoStream ? num + 1 : num;
  num = localScreenStream ? num + 1 : num;

  return Math.max(num, 0);
}


function ScrollableVideos(props: ExtendedProps & SizeMeProps) {
  const classes = useStyles();

  const { size } = props;

  const [offset, setOffset] = React.useState(0);

  const desiredVideoHeight = 130;  // in px
  const gutter = 100; // in px
  const totalVideos = countVideos(props);

  const numVideos = size.height ? Math.floor((size.height - gutter) / desiredVideoHeight) : 0;

  const isMaxOffset = offset >= (totalVideos - numVideos);

  const needsScrolling = totalVideos > numVideos;

  const onUp = () => {
    if (offset > 0) {
      setOffset(offset - 1);
      logEvent(Event.SCROLL_MINI_VIDEOS, { 'scroll': 'up' });
    }
  };

  const onDown = () => {
    if (!isMaxOffset) {
      setOffset(offset + 1);
      logEvent(Event.SCROLL_MINI_VIDEOS, { 'scroll': 'down' });
    }
  };

  if (!size.width || !size.height || (totalVideos === 0)) {
    // the outer div is there otherwise the resize detector stops working
    return (
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      </div>
    );
  }

  return (
    <div className={classes.otherVideosColumn}>
      <div className={classes.scrollButtonContainer}>
        <IconButton
          disabled={offset === 0 || !needsScrolling}
          style={{
            visibility: needsScrolling ? 'visible' : 'hidden',
            transform: 'scale(1, -1)'
          }}
          className={classes.buttons}
          onClick={onUp}
        >
          <IconArrowDown size={20} />
        </IconButton>
        <IconButton
          disabled={isMaxOffset || !needsScrolling}
          style={{
            visibility: needsScrolling ? 'visible' : 'hidden',
          }}
          className={classes.buttons}
          onClick={onDown}
        >
          <IconArrowDown size={20} />
        </IconButton>
      </div>
      <Participants
        displayOffset={offset}
        shownVideos={numVideos}
        desiredVideoHeight={desiredVideoHeight}
        {...props}
      />
    </div>
  );
}


const SizedScrollableVideos = sizeMe({ monitorHeight: true })(ScrollableVideos);


function MainVideo(props: ExtendedProps & SizeMeProps) {
  const {
    size,
    selectedStream,
    myScreenIsSelected,
    myUserId,
    remoteVideoStreams,
    myStreamIsSelected,
    hasVideoStream,
    roomOwner,
    roomOwnerHasStream,
    roomOwnerHasScreen,
    fallbackStreamUid,
    fallbackScreenUid,
    localVideoStream,
    localScreenStream,
    layoutConfig,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const maybeUpdateLayout = (uid: string, type: RoomLayoutStreamType) => {
    if (uid !== layoutConfig.featured_id || type !== layoutConfig.featured_type) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      const newLayoutConfig = { ...layoutConfig, featured_type: type, featured_id: uid };
      dispatch(changeLayout('fullscreen' as RoomLayout, newLayoutConfig));
    }
  };

  if (!size.width || !size.height) {
    // the outer div is there otherwise the resize detector stops working
    return (
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      </div>
    );
  }

  const aspectRatio = size.width / size.height;

  let stream = null;
  let uid = null;
  let mirrored = false;
  let isScreenSelected = false;
  if (selectedStream) {
    if (myStreamIsSelected && hasVideoStream && myUserId) {
      uid = myUserId;
      stream = localVideoStream;
      mirrored = true;
    }
    else if (myScreenIsSelected && localScreenStream && myUserId) {
      uid = `${myUserId}_screen`;
      stream = localScreenStream;
      isScreenSelected = true;
    }
    else if (remoteVideoStreams[selectedStream] && remoteVideoStreams[selectedStream].stream) {
      uid = selectedStream;
      stream = remoteVideoStreams[selectedStream].stream;
      isScreenSelected = uid.endsWith('_screen');
    }
  }
  else {
    if (roomOwner && roomOwnerHasScreen) {
      maybeUpdateLayout(roomOwner, 'screen');
      uid = `${roomOwner}_screen`;
      stream = (roomOwner === myUserId) ? localScreenStream : remoteVideoStreams[uid].stream;
      isScreenSelected = true;
    }
    else if (fallbackScreenUid) {
      maybeUpdateLayout(fallbackScreenUid, 'screen');
      uid = `${fallbackScreenUid}_screen`;
      stream = (fallbackScreenUid === myUserId) ? localScreenStream : remoteVideoStreams[uid].screen;
      isScreenSelected = true;
    }
    else if (roomOwner && roomOwnerHasStream) {
      maybeUpdateLayout(roomOwner, 'stream');
      uid = roomOwner;
      stream = (roomOwner === myUserId) ? localVideoStream : remoteVideoStreams[uid].stream;
    }
    else if (fallbackStreamUid) {
      maybeUpdateLayout(fallbackStreamUid, 'stream');
      uid = fallbackStreamUid;
      stream = (uid === myUserId) ? localVideoStream : remoteVideoStreams[uid].stream;
      mirrored = uid === myUserId;
    }
    else if (hasVideoStream && myUserId && localVideoStream) {
      maybeUpdateLayout(myUserId, 'stream');
      uid = myUserId;
      stream = localVideoStream;
      mirrored = true;
    }
  }

  const videoSize = getVideoSize(stream);

  const ratio = aspectRatio / videoSize.aspectRatio;
  const fit = !isScreenSelected && (ratio < 1.2 && ratio > 0.8);

  if (selectedStream && stream) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
          <Fullscreen user={selectedStream}>
            <LoadingVideoElement
              user={selectedStream}
              mirrored={mirrored}
              addVideoMutedIconOverlay={true}
              stream={stream}
              fit={fit}
            />
          </Fullscreen>
        </div>
        {/* the container div is needed to avoid covering scrollable videos with the mouse hover detector div */}
        <div className={classes.videoToolbarContainer}>
          <VideoToolbar centerControls uid={selectedStream} />
        </div>
      </div>
    );
  }
  else {
    // FIXME: decide what to do here
    // the outer div is there otherwise the resize detector stops working
    return (
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      </div>
    );
  }
}


const SizedMainVideo = sizeMe({ monitorHeight: true })(MainVideo);


function getVideoSize(stream: null | MediaStream) {
  const defaultValue = { width: 16, height: 9, aspectRatio: 16 / 9 };
  if (!stream) {
    return defaultValue;
  }

  const track = stream.getVideoTracks()[0];
  if (track) {
    const settings = track.getSettings();
    return {
      aspectRatio: settings.aspectRatio || defaultValue.aspectRatio,
      width: settings.width || defaultValue.width,
      height: settings.height || defaultValue.height,
    };
  }
  else {
    return defaultValue;
  }
}


function FullscreenLayout(props: ExtendedProps) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const newPublishers = usePublishersWatcher();

  newPublishers.forEach((u) => {
    if (u.stream) {
      dispatch(subscribeToVideo(u.uid));
    }
    if (u.screen) {
      dispatch(subscribeToVideo(`${u.uid}_screen`));
    }
  });

  return (
    <div className={classes.fullDim}>
      <SizedMainVideo {...props} />
      <div className={classes.participantsContainer}>
        <SizedScrollableVideos {...props} />
      </div>
    </div>
  );
}


export default connect(mapStateToProps)(FullscreenLayout);
