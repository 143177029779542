import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import { RelayEnvironmentProvider } from 'relay-hooks';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles, ThemeProvider } from '@material-ui/styles';


import BackgroundSettings from './components/BackgroundSettings';
import BrowserCheck from './components/BrowserCheck';
import DeviceSelector from './components/DeviceSelector';
import ErrorBoundary from './components/ErrorBoundary';
import MainRouter from './components/MainRouter';
import MediaPermissions from './components/MediaPermissions';
import NotificationArea from './components/NotificationArea';
import PushNotifications from './components/PushNotifications';
import Spinner from './components/Spinner';
import isElectron from './detectElectron';
import localeData from './l10n';
import { getEnvironment } from './relay/';
import prepareWebRtcProvider from './rtc';
import createHolocomTheme from './theme';

import useStyles from './AppStyles';

import { setSyncToken } from './lib/actions/auth';
import { authReady, checkValidateSession } from './lib/actions/auth';
import { fetchSessionDetails } from './lib/actions/session';
import { setIsElectron } from './lib/actions/settings';
import {
  connectSocket,
  disconnectSocket,
} from './lib/actions/websocket';
import { minimalSessionRequest } from './lib/api/relay/minimalSession';
import { sessionDetailsQueryRequest } from './lib/api/relay/sessionDetails';
import * as Logger from './lib/logger';
import { getLogger } from './lib/logger';
import { getIsAuthenticated,
  getIsAuthenticatedAsGuest,
  getToken,
  getIsFetching
} from './lib/reduxSelectors/auth';
import { roomJoined } from './lib/reduxSelectors/room';
import { getWsChannel, getWsRoomName, isWsConnected, isWsConnecting } from './lib/reduxSelectors/websocket';
import { runInProduction } from './lib/utils/environment';


let theme = createHolocomTheme();

const styles = useStyles(theme);

class App extends Component {
  constructor(props) {
    super(props);

    // install global var for access from the browser's js console
    window.HoloCom = {
      Logger: Logger,
      App: this,
    };

    this.apiLogger = getLogger('Api');

    this.props.dispatch(setIsElectron(isElectron));
    this.webrtc = prepareWebRtcProvider();
    this.state = {
      browser: this.webrtc.adapter.browserDetails.browser,
      browserVersion: this.webrtc.adapter.browserDetails.version,
      parsedUserAgent: this.webrtc.adapter.browserDetails.parsedUserAgent,
      userAgent: this.webrtc.adapter.browserDetails.userAgent,
      browserChecked: false,
      relayEnvironment: {},
    };

    this.messages = this.getMessages();

    this.onBrowserCheckPassed = this.onBrowserCheckPassed.bind(this);

    this._checkAuth(this.state);
    theme = createHolocomTheme(this.props.themeOptions);
  }

  getMessages() {
    return localeData[this.props.lang];
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.isAuthenticatedAsGuest && this.props.isAuthenticated) {
      this._disconnectWebsocket(this.props.dispatch);
    }
    if (this._shouldConnectWebsocket(this.props)) {
      this._tryToConnectWebsocket(this.props.dispatch, this.props.authToken);
    }
    else if (this._shouldDisconnectWebsocket(this.props, prevProps)) {
      this._disconnectWebsocket(this.props.dispatch);
    }

    const authenticated = (!prevProps.isAuthenticated && this.props.isAuthenticated);
    const guest = (!prevProps.isAuthenticatedAsGuest && this.props.isAuthenticatedAsGuest);

    if (authenticated || guest) {
      const relay = getEnvironment(this.props.authToken);
      this.setState({ relayEnvironment: relay });
      prevProps.dispatch(fetchSessionDetails(relay, sessionDetailsQueryRequest));
    }

    if (this.props.themeOptions !== prevProps.themeOptions) {
      theme = createHolocomTheme(this.props.themeOptions);
    }
  }

  _checkAuth(state) {
    if (this.props.initialToken) {
      const relay = getEnvironment(this.props.initialToken);
      state.relayEnvironment = relay;
      checkValidateSession(relay,
        minimalSessionRequest,
        this.props.dispatch,
        this.props.initialToken,
        this.props.localStore,
        this.props.lang
      );
    } else {
      this.props.dispatch(authReady(null));
    }
    window.addEventListener('storage', (event) => {
      if (event.key === 'HoloCom.authToken') {
        if (event.newValue === null) {
          setSyncToken(this.props.dispatch, event.newValue);
        } else {
          const relay = getEnvironment(event.newValue);
          state.relayEnvironment = relay;
          setSyncToken(this.props.dispatch, event.newValue);
          checkValidateSession(relay,
            minimalSessionRequest,
            this.props.dispatch,
            event.newValue,
            this.props.localStore,
            this.props.lang
          );
        }
      }
    });
  }

  componentDidMount() {
    // attach the beforeunload listener before we instantiate the phoenix
    // websocket, since that library also adds a listener and we need to stop
    // propagation if the user clicks on cancel
    runInProduction(() => { window.addEventListener("beforeunload", this.onUnload); }, true);
    if (this._shouldConnectWebsocket(this.props)) {
      this._tryToConnectWebsocket(this.props.dispatch, this.props.authToken);
    }
    else if (this._shouldDisconnectWebsocket(this.props, null)) {
      this._disconnectWebsocket(this.props.dispatch);
    }
  }

  _shouldConnectWebsocket(props) {
    if (props.socketConnecting || props.socketConnected) {
      return false;
    }

    const authed = props.isAuthenticated && props.authToken;
    const guestAuthed = props.isAuthenticatedAsGuest && props.authToken;
    const go = (authed || guestAuthed) && props.sessionReady;
    return go;
  }

  _shouldDisconnectWebsocket(props, lastProps) {
    if (!lastProps) {
      lastProps = {
        isAuthenticated: true,
        isAuthenticatedAsGuest: true,
      };
    }
    const authed = (!props.isAuthenticated && lastProps.isAuthenticated);
    const guestAuthed = (props.isAuthenticatedAsGuest && !lastProps.isAuthenticatedAsGuest);
    return authed || guestAuthed;
  }

  _tryToConnectWebsocket(dispatch, token) {
    const mouseEvent = window.mouseEvent;
    dispatch(connectSocket(token, this.apiLogger, mouseEvent));
  }

  _disconnectWebsocket(dispatch) {
    dispatch(disconnectSocket());
  }

  onUnload = (event) => {
    if (this.props.roomJoined) {
      event.stopImmediatePropagation();
      const alert = 'alert';
      event.returnValue = alert;
      return alert;
    }
  }

  onBrowserCheckPassed() {
    this.setState({ browserChecked: true });
  }

  render() {
    return (
      <RelayEnvironmentProvider environment={this.state.relayEnvironment}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider locale={this.props.lang} messages={this.messages}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3} dense classes={{
                variantSuccess: this.props.classes.success,
                variantError: this.props.classes.error,
                variantWarning: this.props.classes.warning,
                variantInfo: this.props.classes.info,
              }}>
                <BrowserCheck
                  browser={this.state.browser}
                  browserVersion={this.state.browserVersion}
                  browserUserAgent={this.state.parsedUserAgent}
                  userAgent={this.state.userAgent}
                  onCheckSuccess={this.onBrowserCheckPassed}
                />
                {this.state.browserChecked &&
                  <ErrorBoundary>
                    <React.Fragment>
                      <CssBaseline />
                      <Container
                        maxWidth={false}
                        style={
                          {
                            height: '100%',
                            padding: 0,
                            margin: 0,
                            display: 'flex',
                            flexFlow: 'column',
                          }
                        }
                      >
                        <MainRouter localStore={this.props.localStore} history={this.props.history} />
                        {this.props.isAuthFetching || this.props.relayIsFetching ? <Spinner /> : null}
                        <DeviceSelector
                          dispatch={this.props.dispatch}
                          localStore={this.props.localStore}
                        />
                        <MediaPermissions />
                        <NotificationArea />
                        <PushNotifications locale={this.props.lang} localStore={this.props.localStore} />
                        <BackgroundSettings />
                      </Container>
                    </React.Fragment>
                  </ErrorBoundary>}
              </SnackbarProvider>
            </ThemeProvider>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </RelayEnvironmentProvider>
    );
  }
}


App.propTypes = {
  lang: PropTypes.string.isRequired,
  localStore: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  authToken: PropTypes.string,
  initialToken: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isAuthenticatedAsGuest: PropTypes.bool,
  isAuthFetching: PropTypes.bool,
  sessionReady: PropTypes.bool,
  socketConnected: PropTypes.bool,
  socketConnecting: PropTypes.bool,
  relayIsFetching: PropTypes.bool,
  roomJoined: PropTypes.bool,
  themeOptions: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
  return {
    isAuthenticated: getIsAuthenticated(state.auth),
    isAuthenticatedAsGuest: getIsAuthenticatedAsGuest(state.auth),
    authToken: getToken(state.auth),
    isAuthFetching: getIsFetching(state.auth),
    relayIsFetching: state.relay.isFetching,
    sessionReady: state.session.sessionReady,
    roomJoined: roomJoined(getWsChannel(state.websocket), getWsRoomName(state.websocket)),
    socketConnected: isWsConnected(state.websocket),
    socketConnecting: isWsConnecting(state.websocket),
    themeOptions: state.appconfig.theme
  };
}

export default connect(mapStateToProps)(withStyles(styles)(App));
