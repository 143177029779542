import React  from 'react';
import { connect } from 'react-redux';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { State } from '../../lib/reducers';
import { PaneType } from '../../lib/redux_types';
import { amModerator, isChatOpen, isInfoOpen, isRosterOpen } from '../../lib/reduxSelectors/room';
import { disableRoster } from '../../lib/reduxSelectors/roster';
import { ChatIcon } from '../Chat';
import { IconInfo, IconInfoSelected } from '../IconSet';
import LockedJoinRequestsButton from '../LockedJoinRequestsButton';
import LockRoom from '../LockRoom';
import RosterIcon from '../Roster/RosterIcon';


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    tabModerator: {
      minWidth: '25%',
      maxWidth: '25%'
    },
    tabModeratorPlus: {
      minWidth: '20%',
      maxWidth: '20%'
    },
    tab: {
      minWidth: '33%',
      maxWidth: '33%'
    },
  })
);


type Props = {
  openPane: (pane: PaneType) => void;
}

type ExtendedProps = Props & MappedProps


enum TabIndex {
  ROSTER = PaneType.Roster, // 0
  CHAT = PaneType.Chat, // 1
  INFO = PaneType.Info, // 2
  NONE = PaneType.None, // 3
  LOCK = 4,
  JOIN_REQ = 5,
}


function MeetingMobileBar(props: ExtendedProps) {
  const classes = useStyles();
  const {
    selectedPane,
    openPane,
    amModerator,
    disableRoster,
    lockedJoinRequests,
    isRosterOpen,
    isChatOpen,
    isInfoOpen
  } = props;

  const [forceChat, setForceChat] = React.useState(false);

  const onRosterClick = React.useCallback(
    () => {
      if (!isRosterOpen) {
        openPane(PaneType.Roster);
      }
    }, [isRosterOpen, openPane]
  );

  const onChatClick = React.useCallback(
    () => {
      if (!isChatOpen) {
        openPane(PaneType.Chat);
      }
    }, [isChatOpen, openPane]
  );

  const onInfoClick = React.useCallback(
    () => {
      if (!isInfoOpen) {
        openPane(PaneType.Info);
      }
    }, [isInfoOpen, openPane]
  );

  React.useEffect(
    () => {
      if (disableRoster && isRosterOpen) {
        onChatClick();
        setForceChat(true);
      } else if (!disableRoster && forceChat) {
        onRosterClick();
        setForceChat(false);
      }
    }, [isRosterOpen, disableRoster, onChatClick, onRosterClick, setForceChat, forceChat]
  );

  const getRosterIcon = () => {
    return (
      <RosterIcon />
    );
  };

  const getChatIcon = () => {
    return (
      <ChatIcon />
    );
  };

  const getInfoIcon = () => {
    return (
      <React.Fragment>
        {isInfoOpen
          ? <IconInfoSelected size={28} />
          : <IconInfo size={28} />
        }
      </React.Fragment>
    );
  };

  const showJoinRequests = amModerator && lockedJoinRequests.length > 0;

  const cls = amModerator
    ? (showJoinRequests ? classes.tabModeratorPlus : classes.tabModerator)
    : classes.tab;

  return (
    <Tabs
      value={selectedPane}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab
        value={TabIndex.ROSTER}
        className={cls}
        disabled={disableRoster}
        icon={getRosterIcon()}
        onClick={onRosterClick} />
      <Tab
        value={TabIndex.CHAT}
        className={cls}
        icon={getChatIcon()}
        onClick={onChatClick} />
      <Tab
        value={TabIndex.INFO}
        className={cls}
        icon={getInfoIcon()}
        onClick={onInfoClick} />
      {amModerator &&
        <Tab
          value={TabIndex.LOCK}
          className={cls}
          icon={<LockRoom />} />
      }
      {showJoinRequests &&
        <Tab
          value={TabIndex.JOIN_REQ}
          className={cls}
          icon={<LockedJoinRequestsButton />} />
      }
    </Tabs>
  );
}


type MappedProps = {
  amModerator: boolean;
  disableRoster: boolean;
  selectedPane: PaneType;
  lockedJoinRequests: State['notifications']['lockedJoinRequests'];
  isRosterOpen: boolean;
  isChatOpen: boolean;
  isInfoOpen: boolean;
}

const mapStateToProps = (state: State): MappedProps => {
  return {
    amModerator: amModerator(state),
    disableRoster: disableRoster(state),
    lockedJoinRequests: state.notifications.lockedJoinRequests,
    selectedPane: state.room.selectedPane,
    isRosterOpen: isRosterOpen(state),
    isChatOpen: isChatOpen(state),
    isInfoOpen: isInfoOpen(state)
  };
};


export default connect(mapStateToProps)(MeetingMobileBar);
