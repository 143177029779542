let env = 'dev';

function getApplicationEnvironment() {
  return env;
}

function setApplicationEnvironment(desiredEnv) {
  env = desiredEnv;
}

function runInDevelopment(callback) {
  if (getApplicationEnvironment() === 'dev') {
    callback();
  }
}

function runInProduction(callback) {
  if (getApplicationEnvironment() === 'prod') {
    callback();
  }
}

export { getApplicationEnvironment, setApplicationEnvironment, runInProduction, runInDevelopment };
