import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { useRelayEnvironment } from 'relay-hooks';

import { Tooltip } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { updateRoomLockPreference } from '../../lib/actions/user';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import AsyncSwitch from '../AsyncSwitch';
import { IconHelp } from '../IconSet';


const messages = defineMessages({
  roomLockPreferenceSwitchText: { id: 'roomLockPreferenceSwitchText' },
  roomLockPreferenceSwitchInfo: { id: 'roomLockPreferenceSwitchInfo' },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userInfoEntryContent: {
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5em',
    },
    userInfoLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    userInfoLabelContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    info: {
      paddingLeft: theme.spacing(0.5)
    }
  })
);

function RoomLockPreferenceSwitch(props: ExtendedProps) {
  const {
    roomLockPreference,
    isUpdating
  } = props;

  const classes = useStyles();
  const relayEnv = useRelayEnvironment();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const switchDisabled = isUpdating;

  const handleChange = () => {
    dispatch(updateRoomLockPreference(relayEnv, !roomLockPreference));
    const setting = !roomLockPreference ? 'enable' : 'disable';
    logEvent(Event.ENABLE_DEFAULT_LOCK, { 'setting': setting });
  };

  return (
    <div className={classes.userInfoLinkContainer}>
      <div className={`${classes.userInfoEntryContent} ${classes.userInfoLabelContainer}`}>
        {formatMessage(messages.roomLockPreferenceSwitchText)}
        <Tooltip placement='top' title={formatMessage(messages.roomLockPreferenceSwitchInfo)}>
          <div className={classes.info}>
            <IconHelp size={16} />
          </div>
        </Tooltip>
      </div>
      <AsyncSwitch
        isLoading={switchDisabled}
        checked={roomLockPreference}
        onChange={handleChange}
        color="primary"
      />
    </div>
  );
}

type MappedProps = {
  roomLockPreference: State['session']['roomLockPreference'];
  isUpdating: State['session']['isUpdatingRoomLockPreference'];
}

type Props = {
};

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State): MappedProps => ({
  roomLockPreference: state.session.roomLockPreference,
  isUpdating: state.session.isUpdatingRoomLockPreference
});

export default connect(mapStateToProps)(RoomLockPreferenceSwitch);
