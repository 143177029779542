import { saveBackgroundBlurCustomBackgroundConfig } from "../../../lib/actions/settings";
import { ERROR, newEvent, WARNING } from "../../../lib/notifications";
import { NotificationPayload } from "../../../lib/redux_types";
import { BlurTypeCustomValue } from "../../../lib/utils/pipelineDefault";
import LocalStorage from "../../../localStorage";


export enum InputComponentType {
  ICON_BACKGROUND = 'background',
  ICON_EDIT = 'edit',
}


export function showNotification(level: symbol, messageId: string, payload?: NotificationPayload) {
  newEvent(level, messageId, messageId, messageId, payload);
}

function validateImageResolutionAndSave(
  base64Image: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any,
  key: BlurTypeCustomValue,
  onAdd: () => void
) {
  const image = new Image();
  image.src = base64Image;
  image.addEventListener("load", () => {
    if (image.width <= 1920 && image.height <= 1080) {
      dispatch(saveBackgroundBlurCustomBackgroundConfig(base64Image, new LocalStorage(), key));
      onAdd();
    } else {
      showNotification(WARNING, 'backgroundBlurCustomTooBig', { width: '1920', height: '1080' });
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function onLoadEnd(fileReader: FileReader, dispatch: any, value: BlurTypeCustomValue, onAdd: () => void) {
  if (fileReader.result) {
    const base64Image = fileReader.result.toString();
    validateImageResolutionAndSave(base64Image, dispatch, value, onAdd);
  } else {
    showNotification(ERROR, 'backgroundBlurInvalidFile');
  }
}

export function validateImageSize(file: File): boolean {
  // File size smaller than 1mb in bytes
  if (file.size <= 1048576) {
    return true;
  } else {
    showNotification(WARNING, 'backgroundBlurCustomTooLarge', { value: '1', unit: 'MB' });
    return false;
  }
}
