import React from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';
import { useIntl, defineMessages } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';

import { logEvent, Event } from '../../lib/analytics';
import { IconDuplicate } from '../IconSet';
import TooltipMultiLineText from '../TooltipMultiLineText';

import useStyles from './style';


const messages = defineMessages({
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


type Props = {
  rawUrl: string;
}


function CopyToClipboard(props: Props) {
  const { formatMessage } = useIntl();
  const [linkCopied, setLinkCopied] = React.useState<boolean>(false);
  const classes = useStyles();
  const { rawUrl } = props;

  let tooltipTimeoutHandler: NodeJS.Timeout | null = null;

  React.useEffect(() => {
    return () => {
      if (tooltipTimeoutHandler) {
        clearTimeout(tooltipTimeoutHandler);
      }
    };
  });

  const onLinkCopied = (): void => {
    setLinkCopied(true);
    logEvent(rawUrl.startsWith('http') ? Event.COPY_MEETING_LINK : Event.COPY_MEETING_DETAILS);
  };

  const handleClipboardTooltipClose = () => {
    // ugly, but avoid re-rendering the normal tooltip text just before closing it
    tooltipTimeoutHandler = setTimeout(() => {
      setLinkCopied(false);
      tooltipTimeoutHandler = null;
    },
    500);
  };


  const cboardTtipText = formatMessage(linkCopied ? messages.copiedToClipboard : messages.copyToClipboard);

  return (
    <TooltipMultiLineText
      placement="top"
      title={cboardTtipText}
      onClose={handleClipboardTooltipClose}
    >
      <ReactCopyToClipboard
        onCopy={onLinkCopied}
        text={rawUrl}>
        <IconButton classes={{ root: classes.iconContainer }}>
          <IconDuplicate size={24} />
        </IconButton>
      </ReactCopyToClipboard>
    </TooltipMultiLineText>
  );

}


export default CopyToClipboard;
