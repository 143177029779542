const black = '#000000';
const green = '#00FF00';    // Base Green -- FIXME
const orange = '#F57C00';   // Material Orange 700
const red = '#FF0000';      // Base Red -- FIXME
const white = '#FFFFFF';

export const iconColors = {
  active: green,
  inactive: red,
  contrast: white,
  normal: black,
  attention: red,
};

export const uplinkQuality = {
  unknown: white,
  high: green,
  medium: orange,
  low: red,
};