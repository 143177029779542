import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import Tooltip from '@material-ui/core/Tooltip';

import { iconColors as colors } from '../../colors';
import { toggleAudioMute, toggleOwnAudioMute } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { UserActionsContext } from '../../lib/contexts';
import useStyles from '../../style/ControlButton';
import {
  IconMicrophone,
  IconMicrophoneOff,
} from '../IconSet';
import { mapStateToProps, MappedProps } from '../Meeting/VideoToolbar/mapState';
import ResponsiveButton from '../ResponsiveButton';


const messages = defineMessages({
  microphoneButtonEnableTooltip: { id: 'microphoneButtonEnableTooltip' },
  microphoneButtonDisableTooltip: { id: 'microphoneButtonDisableTooltip' },
});


function AudioMuteButton(props: ExtendedProps) {
  const classes = useStyles();
  const userActionsContext = React.useContext(UserActionsContext);

  const {
    mySelf,
    isAudioMuted,
    amModerator,
    uid,
    iconSize = 20,
    isScreen,
    iconColor = colors.normal,
    mini,
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const toggleMute = React.useCallback(
    () => {
      const action = !isAudioMuted ? 'mute' : 'unmute';
      if (mySelf) {
        dispatch(toggleOwnAudioMute(!isAudioMuted));
        logEvent(Event.MUTE_MY_MICROPHONE, { 'action': action, 'from': userActionsContext });
      }
      else {
        dispatch(toggleAudioMute(uid, !isAudioMuted));
        logEvent(Event.MUTE_USER_MICROPHONE, { 'action': action, 'from': userActionsContext });
      }
    }
    , [dispatch, isAudioMuted, uid, mySelf, userActionsContext]
  );


  const disabled = (!amModerator && !mySelf) || isScreen;
  const controlButtonHidden = disabled ? classes.controlButtonHidden : null;
  const msg = formatMessage(
    (isAudioMuted) ? messages.microphoneButtonEnableTooltip : messages.microphoneButtonDisableTooltip
  );

  const cls = classNames(
    classes.controlButton,
    props.cName,
    controlButtonHidden);

  // add div due to https://github.com/mui-org/material-ui/issues/8416#issuecomment-332556082
  return (
    <Tooltip placement="top" title={disabled || mini ? '' : msg}>
      <div>
        <ResponsiveButton
          disabled={disabled}
          cName={cls}
          mini={mini}
          onClick={toggleMute}
          size='small'
          text={msg}>
          {isAudioMuted
            ? <IconMicrophoneOff color={colors.inactive} size={iconSize} />
            : <IconMicrophone color={iconColor} size={iconSize} />
          }
        </ResponsiveButton>
      </div>
    </Tooltip>
  );
}


type Props = {
  uid: string;
  iconSize?: number;
  iconColor?: string;
  cName?: string;
  mini?: boolean;
}


type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(AudioMuteButton);
