function findEmails(text: string): string[] {
  const rows = text
    .replace(/^\s*[\r\n]/gm, "")  // Remove all blank lines
    .replace(/^[\s,;]+$/gm, "")   // Remove all lines with only delimiters chars
    .trim()                       // Remove all leading/trailing left blank lines
    .split(/[\n\r]+/);            // Splits on new lines
  
  return rows.map((row) => {
    // Splits on [space][comma][semicolon]
    const fields = row.trim().split(/[\s,;]+/);
    // email should be the first field
    return fields[0];
  });
}


export { findEmails };