export enum TLogLevel {
  // keep correct ordering (from lower to higher) because later it is used when
  // deciding what to log
  DEBUG = 0,
  INFO,
  WARNING,
  ERROR
}


interface TLoggerFunction {
  // it is ok to allow any here, since it is feeded to console logging funs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]): void;
}


interface Transport {
  log: TLoggerFunction;
  debug: TLoggerFunction;
  info: TLoggerFunction;
  warn: TLoggerFunction;
  error: TLoggerFunction;
}


interface Config {
  transport: Transport | null;
}


class DefaultTransport implements Transport {
  // it is ok to allow any here, since it is feeded to console logging funs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  log(..._args: any[]) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  debug(..._args: any[]) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  info(..._args: any[]) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  warn(..._args: any[]) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  error(..._args: any[]) {}
}


export interface LoggerInterface extends Transport {
  setLogLevel(level: TLogLevel): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  warning(..._args: any[]): void;
}


let logLevel = TLogLevel.INFO;
const loggers: { [id: string]: LoggerInterface } = {};
const config: Config = { transport: null };


class Logger implements LoggerInterface {
  id: string;
  loglevel: TLogLevel;
  transport: Transport;

  constructor(id: string, loglevel: TLogLevel) {
    this.id = id;
    this.loglevel = loglevel;
    if (config.transport) {
      this.transport = config.transport;
    }
    else {
      this.transport = new DefaultTransport();
    }
  }

  setLogLevel(level: TLogLevel) {
    this.loglevel = level;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(...args: any[]) {
    if (this._mustBeLogged(TLogLevel.DEBUG)) {
      this._log(this.transport.debug, 'debug', ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(...args: any[]) {
    if (this._mustBeLogged(TLogLevel.DEBUG)) {
      this._log(this.transport.debug, 'debug', ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(...args: any[]) {
    if (this._mustBeLogged(TLogLevel.INFO)) {
      this._log(this.transport.info, 'info', ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warning(...args: any[]) {
    if (this._mustBeLogged(TLogLevel.WARNING)) {
      this._log(this.transport.warn, 'warning', ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(...args: any[]) {
    if (this._mustBeLogged(TLogLevel.WARNING)) {
      this._log(this.transport.warn, 'warning', ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(...args: any[]) {
    if (this._mustBeLogged(TLogLevel.ERROR)) {
      this._log(this.transport.error, 'error', ...args);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _log(loggerFun: TLoggerFunction, label: string, ...args: any[]) {

    let now;
    try {
      const options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      };
      now = new Date().toLocaleString('en-GB', options);
      now = `[${now}]`;
    }
    catch (e) {
      now = "";
    }
    args = [`${now}[${label}] ${this.id}:`, ...args];

    try {
      loggerFun.apply(this, args);
    }
    catch (_e) {
      // just ignore the exception
    }
  }

  _mustBeLogged(targetLevel: TLogLevel) {
    return targetLevel >= this.loglevel;
  }
}


export function setLogLevel(level: TLogLevel) {
  for (const id in loggers) {
    loggers[id].setLogLevel(level);
  }
  logLevel = level;
}


export function getLogger(id: string) {
  let logger = loggers[id];
  if (!logger) {
    logger = new Logger(id, logLevel);
    loggers[id] = logger;
  }
  return logger;
}


export function setLoggerTransport(t: Transport) {
  config.transport = t;
}
