import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { logEvent, Event } from '../../../lib/analytics';
import getRouteFor, { Route } from '../../../lib/utils/router';


const messages = defineMessages({
  join: { id: 'joinButton' },
  cancel: { id: 'cancel' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingTop: {
      margin: theme.spacing(3, 0, 0, 0)
    },
    actionsWrapper: {
      width: '100%',
      padding: theme.spacing(2, 0),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    actions: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    cancel: {
      [theme.breakpoints.only('md')]: {
        marginLeft: theme.spacing(2)
      }
    }
  })
);


type BottomContentProps = {
  canJoin: boolean;
  onJoin?: () => void;
  showProgress: boolean;
} & RouteComponentProps;

function BottomContent(props: BottomContentProps) {
  const { canJoin, onJoin, showProgress } = props;
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const homePath = getRouteFor(Route.Home);

  const gotoHome = React.useCallback(
    () => {
      if (props.location.pathname !== homePath) {
        props.history.push(homePath);
        logEvent(Event.CANCEL_JOIN);
      }
    },
    [props.history, props.location, homePath]
  );

  const getJoin = () => {
    return (
      <Button variant='contained' onClick={onJoin} color='primary'>
        {formatMessage(messages.join)}
      </Button>
    );
  };

  const getCancel = () => {
    return (
      <Button variant='outlined' onClick={gotoHome} color='primary' className={classes.cancel}>
        {formatMessage(messages.cancel)}
      </Button>
    );
  };

  return (
    <div className={classes.actionsWrapper}>
      <div className={classes.actions}>
        { canJoin && getJoin() }
        { getCancel() }
      </div>
      { showProgress && <CircularProgress className={classes.paddingTop} /> }
    </div>
  );
}


export default withRouter(BottomContent);
