/* eslint @typescript-eslint/camelcase: 0 */

import update from 'immutability-helper';
import { Channel } from 'phoenix';

import { MEETING_UPDATED } from '../actions/room';
import {
  SOCKET_CONNECT,
  SOCKET_CONNECTING,
  SOCKET_DISCONNECT,
  SOCKET_FAILURE,
  JOIN_SUCCESS,
  JOIN_FAILURE,
  CHANNEL_FAILURE,
  JOIN_REQUEST,
  LEAVE_SUCCESS,
  SET_ROOM_ROLE,
  RESET_JOIN_FAILURE,
} from '../actions/websocket';
import { ApiT as RoomApi } from '../api/videoroom';
import { ApiT as  WaitingRoomApi } from '../api/waitingRoom';
import { assign } from '../utils/object';



export interface Organizer {
  username: string | null;
  display: string | null;
}


interface Attendee {
  email: string;
}


export interface Meeting {
  title: string;
  notes: string;
  dt_start: string;
  dt_end: string;
  slug: string;
  attendees: Attendee[];
  number: string;
  room_type: string;
  type: "audio_only" | "regular" | "lesson" | "webinar";
  publish_stream_url: null | string;
  organizer: Organizer;
}

interface WebSocketIdle {
  kind: 'SOCKET_IDLE';
}

const defaultIdleState: WebSocketIdle = {
  kind: 'SOCKET_IDLE',
};

interface WebSocketConnecting {
  kind: 'SOCKET_CONNECTING';
  isConnecting: boolean;
}

export interface WebSocketConnected {
  kind: 'SOCKET_CONNECTED';
  isConnected: boolean;
  token: string;
  apiserver: RoomApi;
  waitingroomapiserver: WaitingRoomApi;
  leaveReason: null | string;
  errorCode: null | number;
  errorMessage: null | string;
  errorPayload: null | Meeting;
  joinFailure: boolean;
}

interface WebSocketFailed {
  kind: 'SOCKET_FAILED';
  errorCode: number;
  errorMessage: string;
}

export interface WebSocketReady {
  kind: 'SOCKET_READY';
  errorCode: null | number;
  errorMessage: null | string;
  errorPayload: null | Meeting;
  channel: null | Channel;
  room_name: null | string;
  room_roles: string[];
  uid: null | string;
  room_uid: null | string;
  joinFailure: boolean;
  meeting_details: Meeting | null;
  isConnected: boolean;
  token: string;
  apiserver: RoomApi;
  waitingroomapiserver: WaitingRoomApi;
}

export type WebSocketState = WebSocketIdle
  | WebSocketConnecting
  | WebSocketConnected
  | WebSocketReady
  | WebSocketFailed;


const myActions = [
  SOCKET_CONNECT,
  SOCKET_CONNECTING,
  SOCKET_DISCONNECT,
  SOCKET_FAILURE,
  JOIN_SUCCESS,
  JOIN_FAILURE,
  JOIN_REQUEST,
  LEAVE_SUCCESS,
  SET_ROOM_ROLE,
  RESET_JOIN_FAILURE,
  CHANNEL_FAILURE
];


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function websocket(state: WebSocketState = defaultIdleState, action: any = {}) {
  if (myActions.indexOf(action.type) === -1) {
    return state;
  }

  switch (state.kind) {
    case 'SOCKET_IDLE':
      return socketIdle(state, action);
    case 'SOCKET_CONNECTING':
      return socketConnecting(state, action);
    case 'SOCKET_CONNECTED':
      return socketConnected(state, action);
    case 'SOCKET_FAILED':
      return socketFailed(state, action);
    case 'SOCKET_READY':
      return socketReady(state, action);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function socketIdle(state: WebSocketIdle, action: any = {}) {
  let connectingState: WebSocketConnecting;

  switch (action.type) {
    case SOCKET_DISCONNECT:
      return assign(state, {});
    case SOCKET_CONNECTING:
      connectingState = {
        kind: 'SOCKET_CONNECTING',
        isConnecting: true
      };
      return connectingState;
    default:
      throw new Error(`Unhandled transition in state ${state.kind}, action: ${JSON.stringify(action)}`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function socketFailed(_state: WebSocketFailed, action: any = {}) {
  return socketIdle({ kind: 'SOCKET_IDLE' }, action);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function socketConnecting(state: WebSocketConnecting, action: any = {}) {
  let connectedState: WebSocketConnected;
  let failureState: WebSocketFailed;

  switch (action.type) {
    case SOCKET_CONNECT:
      connectedState = {
        kind: 'SOCKET_CONNECTED',
        isConnected: true,
        token: action.payload.token,
        apiserver: action.payload.apiserver,
        waitingroomapiserver: action.payload.waitingroomapiserver,
        leaveReason: null,
        errorPayload: null,
        errorMessage: null,
        errorCode: null,
        joinFailure: false
      };
      return connectedState;
    case SOCKET_FAILURE:
      failureState = {
        kind: 'SOCKET_FAILED',
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage
      };
      return failureState;
    default:
      throw new Error(`Unhandled transition in state ${state.kind}, action: ${JSON.stringify(action)}`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function socketConnected(state: WebSocketConnected, action: any = {}) {
  let readyState: WebSocketReady;

  switch (action.type) {
    case SOCKET_DISCONNECT: {
      return defaultIdleState;
    }
    case JOIN_REQUEST:
      return assign(state, {
        leaveReason: null
      });
    case JOIN_SUCCESS:
      readyState = {
        kind: 'SOCKET_READY',
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        channel: action.payload.channel,
        room_name: action.payload.room_name,
        room_roles: action.payload.room_roles,
        meeting_details: action.payload.meeting_details,
        room_uid: action.payload.room_uid,
        uid: action.payload.uid,
        joinFailure: false,
        isConnected: state.isConnected,
        token: state.token,
        apiserver: state.apiserver,
        waitingroomapiserver: state.waitingroomapiserver
      };
      return readyState;
    case JOIN_FAILURE:
      readyState = {
        kind: 'SOCKET_READY',
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        errorPayload: action.payload.errorPayload,
        channel: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
        joinFailure: true,
        meeting_details: null,
        isConnected: state.isConnected,
        token: state.token,
        apiserver: state.apiserver,
        waitingroomapiserver: state.waitingroomapiserver
      };
      return readyState;
    case LEAVE_SUCCESS:
      return assign(state, {
        leaveReason: action.payload.reason
      });
    case RESET_JOIN_FAILURE:
      return assign(state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        joinFailure: false,
      });
    default:
      throw new Error(`Unhandled transition in state ${state.kind}, action: ${JSON.stringify(action)}`);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function socketReady(state: WebSocketReady, action: any = {}) {
  let connectedState: WebSocketConnected;

  switch (action.type) {
    case JOIN_REQUEST:
      return assign(state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        channel: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
        joinFailure: false,
      });
    case RESET_JOIN_FAILURE:
      connectedState = {
        kind: 'SOCKET_CONNECTED',
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        joinFailure: false,
        isConnected: true,
        token: state.token,
        apiserver: state.apiserver,
        waitingroomapiserver: state.waitingroomapiserver,
        leaveReason: null
      };
      return connectedState;
    case LEAVE_SUCCESS:
      connectedState = {
        kind: 'SOCKET_CONNECTED',
        leaveReason: action.payload.reason,
        isConnected: true,
        token: state.token,
        apiserver: state.apiserver,
        waitingroomapiserver: state.waitingroomapiserver,
        errorPayload: state.errorPayload,
        errorMessage: state.errorMessage,
        errorCode: state.errorCode,
        joinFailure: state.joinFailure
      };
      return connectedState;
    case CHANNEL_FAILURE:
      connectedState = {
        kind: 'SOCKET_CONNECTED',
        leaveReason: action.payload.errorMessage,
        isConnected: true,
        token: state.token,
        apiserver: state.apiserver,
        waitingroomapiserver: state.waitingroomapiserver,
        errorPayload: state.errorPayload,
        errorMessage: state.errorMessage,
        errorCode: state.errorCode,
        joinFailure: state.joinFailure
      };
      return connectedState;
    case SET_ROOM_ROLE:
      return assign(state, {
        room_roles: [action.payload.role],
      });
    case MEETING_UPDATED:
      return update(state, {
        meeting_details: {
          $merge: action.payload.meeting,
        }
      });
    default:
      throw new Error(`Unhandled transition in state ${state.kind}, action: ${JSON.stringify(action)}`);
  }
}


export default websocket;
export { defaultIdleState };
