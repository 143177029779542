import { getApiServer, initApi, Transport } from '../api/videoroom';
import { initApi as initWaitingRoomApi,
  getApiServer as getWaitingRoomApiServer } from '../api/waitingRoom';
import { newEvent, INFO } from '../notifications';
import { traceError as tracerTraceError } from '../tracer';

import {
  removeLockedJoinRequest,
  clearLockedJoinRequests,
  setLockedJoinRequests,
  addLockedJoinRequests
} from './notifications';
import {
  forceLayout, startRecordingSuccess, lockRoomStatus,
  stopRecordingSuccess, setRecordable, toggleDeskshare,
  toggleDialout, onEnableDesktopControl, setAudioRecordable,
  // setMousePointer,
  startLivestreamingSuccess, stopLivestreamingSuccess,
  setLivestreamable,
  setRoomOwnership,
  setMediaPermissions,
  setMyRole,
  setJoiningRoom,
  rosterReset,
} from './room';
import { setRecorder } from './session';
import { setCanJoinRoom, setWaitingReason, setLockedJoinRequestDenied, setJoiningWaitingRoom } from './waitingRoom';


export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTING = 'SOCKET_CONNECTING';
export const SOCKET_FAILURE = 'SOCKET_FAILURE';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const JOIN_FAILURE = 'JOIN_FAILURE';
export const CHANNEL_FAILURE = 'CHANNEL_FAILURE';
export const JOIN_SUCCESS = 'JOIN_SUCCESS';
export const JOIN_REQUEST = 'JOIN_REQUEST';
export const LEAVE_SUCCESS = 'LEAVE_SUCCESS';
export const ROSTER_CHANGE = 'ROSTER_CHANGE';
export const ROSTER_SET_AUDIO = 'ROSTER_SET_AUDIO';
export const SET_ROOM_ROLE = 'SET_ROOM_ROLE';
export const RESET_JOIN_FAILURE = 'RESET_JOIN_FAILURE';


function connecting() {
  return {
    type: SOCKET_CONNECTING,
    payload: {}
  };
}


function connectionSuccess(token, apiserver, waitingroomapiserver) {
  return {
    type: SOCKET_CONNECT,
    payload: {
      token: token,
      apiserver: apiserver,
      waitingroomapiserver: waitingroomapiserver,
    }
  };
}


function disconnectSuccess() {
  return {
    type: SOCKET_DISCONNECT,
    payload: {
    }
  };
}


function connectionError(errmessage, errcode) {
  return {
    type: SOCKET_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
    },
    error: true
  };
}


function joinFailure(errmessage, errcode, payload) {
  return {
    type: JOIN_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
      errorPayload: payload
    },
    error: true
  };
}


function channelFailure(errmessage, errcode, payload) {
  return {
    type: CHANNEL_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
      errorPayload: payload
    },
    error: true
  };
}



function joinSuccess(channel, name, uid, room_uid, meeting_details, room_roles) {
  return {
    type: JOIN_SUCCESS,
    payload: {
      channel: channel,
      room_name: name,
      room_roles: room_roles,
      uid: uid,
      room_uid: room_uid,
      meeting_details: meeting_details
    },
  };
}


function joinRequest() {
  return {
    type: JOIN_REQUEST,
    payload: {
    },
  };
}

function leaveSuccess(reason) {
  return {
    type: LEAVE_SUCCESS,
    payload: {
      reason: reason
    },
  };
}


function rosterChange(participants) {
  return {
    type: ROSTER_CHANGE,
    payload: {
      participants: participants,
    }
  };
}


function setRosterAudio(participantsAudioState) {
  return {
    type: ROSTER_SET_AUDIO,
    payload: {
      participants: participantsAudioState,
    }
  };
}


function setRoomRole(role) {
  return {
    type: SET_ROOM_ROLE,
    payload: {
      role: role,
    },
  };
}

export function resetJoinFailure() {
  return {
    type: RESET_JOIN_FAILURE,
    payload: {
    },
  };
}

function connect(token, logger, dispatch, getState, mouseEvent) {
  dispatch(connecting());
  const transport = new Transport('/socket', token);
  transport.connect().then(
    () => {
      let apiserver = initApi(transport, logger);
      let setAudioState = (members) => {
        let res = {};
        Object.keys(members).forEach(
          (k) => {
            const metas = members[k].metas;
            if (metas && metas[0]) {
              res[k] = { muted: metas[0].muted, privateAudioConf: metas[0].private_conf_name };
            }
          }
        );
        dispatch(setRosterAudio(res));
      };
      let applyLayout = (data) => {
        dispatch(forceLayout(data.layout, data.layoutConfig));
      };
      let onLockRoom = (data) => {
        if (!data.locked) {
          dispatch(clearLockedJoinRequests());
        }
        dispatch(lockRoomStatus(data.locked));
      };
      let onRecordingStatus = (d) => {
        if (d.is_recording !== undefined) {
          let type = 'video';
          if (d.type) {
            type = d.type;
          }
          if (d.is_recording) {
            notifyStartRecording();
            dispatch(startRecordingSuccess(type));
          }
          else {
            notifyStopRecording();
            dispatch(stopRecordingSuccess(type));
          }
        }

        if (d.is_livestreaming !== undefined) {
          if (d.is_livestreaming) {
            notifyStartStreaming();
            dispatch(startLivestreamingSuccess());
          }
          else {
            notifyStopStreaming();
            dispatch(stopLivestreamingSuccess());
          }
        }
      };

      apiserver.subscribe('audio_state', (d) => setAudioState(d));
      apiserver.subscribe('applyLayout', (d) => applyLayout(d));
      apiserver.subscribe('lockRoom', (d) => onLockRoom(d));
      apiserver.subscribe('recordingStatus', (d) => onRecordingStatus(d));
      apiserver.subscribe('startDeskControl',
        (d) => dispatch(onEnableDesktopControl(d.user, true, d.desktopControlType)));
      apiserver.subscribe('stopDeskControl', (d) => {
        notifyStopDesktopControl();
        dispatch(onEnableDesktopControl(d.user, false, null));
      });
      apiserver.subscribe('startDrawing', () => {
        const state = (getState() || {}).settings;
        if (state.isElectron) {
          notifyStartDrawing();
        } else {
          notifyStartDrawingBrowser();
        }

      });
      apiserver.subscribe('stopDrawing', () => {
        notifyStopDrawing();
      });
      apiserver.subscribe('mouseEvent', (d) => {
        const state = (getState() || {}).websocket;

        if (state.uid !== d.userId) {
          // if (d.mouseEventType === 'move') {
          //   dispatch(setMousePointer(d));
          // }
          if (typeof mouseEvent !== 'undefined') {
            mouseEvent(d.userId, d.displayName, d.mouseEventType, d.mouseEventData);
          }

        }

      });
      apiserver.subscribe('channelevent', (d) => {
        if (d.type === 'leave' && d.reason) {
          const videoRoom = (getState() || {}).room;
          const roomObject = (videoRoom || {}).roomObject;
          if (roomObject) {
            roomObject.tearDown();
          }
          leave(dispatch, getState, d.reason);
        }
        else if (d.type === 'myrole_change' && d.role === 'room_user') {
          dispatch(clearLockedJoinRequests());
          dispatch(setRoomRole(d.role));
        }
        else if (d.type === 'myrole_change' && d.role === 'room_moderator' && d.locked_join_requests) {
          dispatch(setLockedJoinRequests(d.locked_join_requests));
          dispatch(setRoomRole(d.role));
          dispatch(setMyRole(d.role));
        }
        else if (d.type === 'join_on_locked' && d.dname) {
          newEvent(INFO, 'joinOnLockedRoom', 'joinOnLockedRoom',
            `${d.dname} could not enter the room because it is locked`, { dname: d.dname });
        }
        else if (d.type === 'locked_join_request'
          && d.dname
          && d.username
          && d.req_id) {
          const request = {
            dname: d.dname,
            username: d.username,
            reqId: d.req_id,
            snapshot: d.snapshot || null
          };
          dispatch(addLockedJoinRequests([request]));
        } else if (d.type === 'clear_locked_join_request' && d.req_id) {
          dispatch(removeLockedJoinRequest(d.req_id));
        }
      });

      // setup WaitingRoomApi
      let waitingroomapiserver = initWaitingRoomApi(transport, logger);

      waitingroomapiserver.subscribe('meetingStarted', (_msg) => {
        dispatch(setCanJoinRoom(true));
      });

      waitingroomapiserver.subscribe('roomUnlocked', (_msg) => {
        dispatch(setCanJoinRoom(true));
      });

      waitingroomapiserver.subscribe('lockedJoinRequestAccepted', (_msg) => {
        dispatch(setCanJoinRoom(true));
      });

      waitingroomapiserver.subscribe('lockedJoinRequestDenied', (_msg) => {
        dispatch(leaveWaitingRoomChannel());
        dispatch(setLockedJoinRequestDenied(true));
      });

      waitingroomapiserver.subscribe('meetingEnded', ({ code, reason }) => {
        dispatch(leaveWaitingRoomChannel());
        dispatch(joinFailure(reason, code));
      });

      waitingroomapiserver.subscribe('updateWaitingReason', ({ code, reason }) => {
        dispatch(setWaitingReason(reason, code));
      });

      waitingroomapiserver.subscribe('ringTimeout', ({ code, reason }) => {
        dispatch(leaveWaitingRoomChannel());
        dispatch(joinFailure(reason, code));
      });

      return dispatch(connectionSuccess(token, apiserver, waitingroomapiserver));

    }
  ).catch(
    (reason) => {
      dispatch(connectionError(reason, null));
    }
  );
}


function disconnect(dispatch, getState) {
  const state = (getState() || {}).websocket;
  const apiserver = (state || {}).apiserver;
  if (apiserver && apiserver.transport) {
    apiserver.transport.disconnect();
    dispatch(disconnectSuccess());
  }
}


function recordingStatus(record_status) {
  let is_recording = record_status.is_recording;
  let is_audio_recording = record_status.is_audio_recording;
  let record_type = 'video';
  if (is_audio_recording) {
    record_type = 'audio';
  }
  if (is_recording || is_audio_recording) {
    notifyStartRecording();
    return startRecordingSuccess(record_type);
  } else {
    return stopRecordingSuccess(record_type);
  }
}

function notifyStopDesktopControl() {
  newEvent(INFO, 'stopDesktopControl', 'stopDesktopControl',
    'Remote desktop control for this room has been disabled');
}
function notifyStartDrawing() {
  newEvent(INFO, 'startDrawing', 'startDrawing',
    'Remote drawing for this room has started');
}
function notifyStartDrawingBrowser() {
  newEvent(INFO, 'startDrawingBrowser', 'startDrawingBrowser',
    'Remote drawing for this room has started. You need to manually start desktop share in fullscreen to use it.');
}
function notifyStopDrawing() {
  newEvent(INFO, 'stopDrawing', 'stopDrawing',
    'Remote drawing for this room has been disabled');
}

function notifyStartRecording() {
  newEvent(INFO, 'roomStartRecording', 'roomStartRecording',
    'This room is being recorded.');
}

function notifyStopRecording() {
  newEvent(INFO, 'roomStopRecording', 'roomStopRecording',
    'Recording has been stopped.');
}

function streamingStatus(is_streaming) {
  if (is_streaming) {
    notifyStartStreaming();
    return startLivestreamingSuccess();
  } else {
    return stopLivestreamingSuccess();
  }
}

function notifyStartStreaming() {
  newEvent(INFO, 'roomStartStreaming', 'roomStartStreaming',
    'This room is being streaming.');
}

function notifyStopStreaming() {
  newEvent(INFO, 'roomStopStreaming', 'roomStopStreaming',
    'Streaming has been stopped.');
}


function configureRecording(dispatch, record_status) {
  // set the room recording status
  dispatch(recordingStatus(record_status));

  // set the room recording status
  dispatch(streamingStatus(record_status.is_livestreaming));

  // flag the session as beloging to a recorder
  dispatch(setRecorder(record_status.is_recorder));

  // flag the room as recordable or not
  dispatch(setRecordable(record_status.enabled));

  // flag the room as recordable or not
  dispatch(setAudioRecordable(record_status.audio_recording_enabled));

  // flag the room as livestreamable or not
  dispatch(setLivestreamable(record_status.livestreaming_enabled));
}


function configureDeskshare(dispatch, deskshare_status) {
  dispatch(toggleDeskshare(deskshare_status.enabled));
}


function configureDialout(dispatch, dialout_status) {
  dispatch(toggleDialout(dialout_status.enabled));
}


function join(roomname, dispatch, getState, logger) {
  const state = (getState() || {}).websocket;
  const apiserver = (state || {}).apiserver;
  const session = (getState() || {}).session;
  const realm = (session || {}).realm;
  const roomState = (getState() || {}).room;
  const roomObject = roomState.roomObject;

  dispatch(clearLockedJoinRequests());
  dispatch(setJoiningRoom(true));
  dispatch(joinRequest());
  if (apiserver) {
    const topic = 'room:' + roomname + '@' + realm;
    let updateRosterCb = (users) => dispatch(rosterChange(users));
    apiserver.joinVideoRoom(topic, {}, updateRosterCb).then(
      (joinedMsg) => {
        if (getState().room.isJoining) {
          joinedMsg.channel.onError((e) => {
            leaveChannel('channel_error');
            if (roomObject) {
              roomObject.tearDown();
            }
            logger.error("channel error", e);
            tracerTraceError('channel error', { errCode: 1408, errMessage: 'channel_error' });
            dispatch(channelFailure('channel_error', 1408));
          });
          if (roomObject) {
            roomObject.setUserId(joinedMsg.uid);
            roomObject.setRoomId(joinedMsg.room_uid);
          }
          // N.B. set room ownership and media permissions as first, since other
          // actions could use these values
          dispatch(setRoomOwnership(joinedMsg.room_roles, joinedMsg.owner_is_audio_only));
          dispatch(setMediaPermissions(joinedMsg.can_publish_video, joinedMsg.can_publish_audio));

          configureRecording(dispatch, joinedMsg.record_status);
          configureDeskshare(dispatch, joinedMsg.deskshare_status);
          configureDialout(dispatch, joinedMsg.dialout_status);
          dispatch(lockRoomStatus(joinedMsg.locked));
          dispatch(forceLayout((joinedMsg.layout || {}).layout, (joinedMsg.layout || {}).layoutConfig));
          if (joinedMsg.locked_join_requests && joinedMsg.locked_join_requests.length > 0) {
            dispatch(addLockedJoinRequests(joinedMsg.locked_join_requests));
          }

          if (typeof deskcontrol_status !== 'undefined' && joinedMsg.deskcontrol_status.running === true) {
            setTimeout(() => {
              dispatch(onEnableDesktopControl(joinedMsg.deskcontrol_status.payload.user,
                true, joinedMsg.deskcontrol_status.payload.desktopControlType));
            }, 500);
          }

          // N.B. dispatch joinSuccess last to be sure that the previous
          // properties (layout conf, dialout status, etc.) are already
          // propagated in the state.
          dispatch(
            joinSuccess(joinedMsg.channel, roomname, joinedMsg.uid,
              joinedMsg.room_uid, joinedMsg.meeting_details, joinedMsg.room_roles)
          );

          dispatch(setJoiningRoom(false));
        } else {
          logger.warn("not in room anymore, ignoring join response");
          joinedMsg.channel.leave();
        }
      }
    ).catch(
      ({ type, reason, payload, errorCode }) => {
        if (getState().room.isJoining) {
          logger.error("failed join", type, reason, errorCode);
          reason = type === 'timeout' ? 'timeout' : reason;
          errorCode = errorCode ? errorCode : 1001;
          tracerTraceError('failed join', { errCode: errorCode, errMessage: reason, payload: payload });
          dispatch(joinFailure(reason, errorCode, payload));
          dispatch(setJoiningRoom(false));
        } else {
          logger.warn("not in waiting room anymore, ignoring join response");
        }
      }
    );
  }
}

function doJoinWaitingRoom(roomname, snapshot, dispatch, getState, logger) {
  const state = (getState() || {}).websocket;
  const waitingroomapiserver = (state || {}).waitingroomapiserver;
  const session = (getState() || {}).session;
  const realm = (session || {}).realm;

  dispatch(joinRequest());
  dispatch(setJoiningWaitingRoom(true));
  if (waitingroomapiserver) {
    const topic = 'waiting_room:' + roomname + '@' + realm;
    waitingroomapiserver.joinWaitingRoom(topic, snapshot).then(
      ({ reason, channel, payload }) => {
        if (getState().waitingRoom.isJoining) {
          channel.onError((e) => {
            channel.leave();
            logger.error("channel error", e);
            tracerTraceError('channel error', { errCode: 1408, errMessage: 'channel_error' });
            dispatch(channelFailure('channel_error', 1408));
          });

          dispatch(setWaitingReason(reason, payload));
          dispatch(
            joinSuccess(channel, "waiting_room:" + roomname, null, null, null, null)
          );
          dispatch(setJoiningWaitingRoom(false));
        } else {
          logger.warn("not in waiting room anymore, ignoring join response");
          channel.leave();
        }
      }
    ).catch(
      ({ type, reason, payload, errorCode }) => {
        if (getState().waitingRoom.isJoining) {
          if (reason === "can_join") {
            dispatch(setCanJoinRoom(true));
          } else if (reason === 'too_early' && payload) {
            dispatch(setWaitingReason('too_early', payload));
          } else {
            logger.error("failed join", type, reason, errorCode);
            reason = type === 'timeout' ? 'timeout' : reason;
            errorCode = errorCode ? errorCode : 1001;
            dispatch(joinFailure(reason, errorCode, payload));
          }
          dispatch(setJoiningWaitingRoom(false));
        } else {
          logger.warn("not in waiting room anymore, ignoring join response");
        }
      }
    );
  }
}

function leave(dispatch, getState, reason = 'user') {
  const apiserver = getApiServer();
  if (apiserver) {
    apiserver.leaveChannel();
  }
  dispatch(setJoiningWaitingRoom(false));
  dispatch(setJoiningRoom(false));
  dispatch(leaveSuccess(reason));
  dispatch(rosterReset());
}


function leaveWaitingRoom(dispatch, getState, reason = 'user') {
  const apiserver = getWaitingRoomApiServer();
  if (apiserver) {
    apiserver.leaveChannel();
  }
  dispatch(setJoiningWaitingRoom(false));
  dispatch(setJoiningRoom(false));
  dispatch(leaveSuccess(reason));
  dispatch(rosterReset());
}


export function connectSocket(token, logger, mouseEvent) {
  return (dispatch, getState) => {
    return connect(token, logger, dispatch, getState, mouseEvent);
  };
}


export function disconnectSocket() {
  return (dispatch, getState) => {
    return disconnect(dispatch, getState);
  };
}


export function joinRoom(room, logger) {
  return (dispatch, getState) => {
    return join(room, dispatch, getState, logger);
  };
}

export function joinWaitingRoom(room, snapshot, logger) {
  return (dispatch, getState) => {
    return doJoinWaitingRoom(room, snapshot, dispatch, getState, logger);
  };
}

export function leaveChannel(reason = 'user') {
  return (dispatch, getState) => {
    return leave(dispatch, getState, reason);
  };
}

export function leaveWaitingRoomChannel() {
  return (dispatch, getState) => {
    return leaveWaitingRoom(dispatch, getState);
  };
}
