import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import VisibilitySensor from "react-visibility-sensor";

import { Dispatch } from 'redux';

import GridList from '@material-ui/core/GridList';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import { toggleVideoMute } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { getIsAuthenticated, getIsAuthenticatedAsGuest } from '../../lib/reduxSelectors/auth';
import { amModerator, isLessonLayout, hasModeratorRole } from '../../lib/reduxSelectors/room';
import { getRoster, ObservedUser } from '../../lib/reduxSelectors/roster';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import { keys } from '../../lib/utils/array';
import { orderedRosterKeys } from '../../lib/utils/roster';
import { getColumns } from '../../lib/utils/rosterDimensions';
import DebouncedTextField from '../DebouncedTextField';
import { IconSearch } from '../IconSet';

import Header from './Header';
import User from './User';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      backgroundColor: theme.palette.background.default,
    },
    inputField: {
      width: '100%',
    },
    userlist: {
      overflowY: 'auto',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
    search: {
      marginRight: theme.spacing(1),
    }
  }),
);


const messages = defineMessages({
  findParticpants: { id: 'findParticipants' },
  participantNumber: { id: 'participantNumber' },
  filteredParticipantNumber: { id: 'filteredParticipantNumber' },
});


const minFilterLen = 3;


// FIXME: add missing actions' type in redux
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Action = any

function renderRoster(
  roster: MappedProps['roster'],
  filter: string, myUid: MappedProps['myUid'],
  ref: React.RefObject<HTMLElement>,
  amModerator: boolean,
  isLessonLayout: boolean,
  dispatch: Dispatch<Action>
) {

  const users = orderedRosterKeys(roster, myUid).flatMap((key, idx) => {
    const user = roster[key] as ObservedUser;
    const displayName = user.display;
    const mySelf = myUid === key;

    const isModerator = hasModeratorRole(user.role);
    const isFiltered = filter.length >= minFilterLen && !displayName.toLowerCase().includes(filter.toLowerCase());
    const cannotBeShown = isMobileOnly && !amModerator && isLessonLayout && !isModerator && !mySelf;

    if (isFiltered || cannotBeShown) {
      // Stop receiving streams which you are not allowed to see on mobile
      if (cannotBeShown) {
        dispatch(toggleVideoMute(key, true));
        dispatch(toggleVideoMute(`${key}_screen`, true));
      }
      return [];
    }
    else {
      return (
        [
          <VisibilitySensor key={idx} containment={ref.current} partialVisibility intervalDelay={500}>
            {({ isVisible }) => {
              return (
                <ListItem key={idx}>
                  <User uid={key} displayName={displayName} isVisible={isVisible} />
                </ListItem>
              );
            }}
          </VisibilitySensor>
        ]
      );
    }
  });

  return users;
}


function Roster(props: ExtendedProps) {
  const classes = useStyles();
  const ref = React.useRef(null);
  const dispatch = useDispatch();

  const {
    roster,
    intl,
    isAuthenticatedAsGuest,
    isAuthenticated,
    myUid,
    amModerator,
    isLessonLayout,
  } = props;

  const [filter, setFilter] = React.useState("");

  const handleFilterChange = React.useCallback(
    (s) => {
      setFilter(s);
      if (s.length >= minFilterLen) logEvent(Event.FILTER_USERS);
    }, []
  );

  if (!isAuthenticated && !isAuthenticatedAsGuest) {
    return null;
  }

  const users = renderRoster(roster, filter, myUid, ref, amModerator, isLessonLayout, dispatch);

  const rosterLen = keys(roster).length;
  const usersLen = users.length;
  const headerText = (rosterLen === usersLen)
    ? intl.formatMessage(messages.participantNumber, { number: rosterLen })
    : intl.formatMessage(messages.filteredParticipantNumber, { number: rosterLen, filtered: usersLen });

  const cols = (!isMobileOnly && props.size) ? getColumns(props.size) : 1;

  return (
    <List ref={ref} component="nav" className={classes.root}>
      { !isMobileOnly && <Header headerText={headerText} /> }
      { !isMobileOnly &&
        <ListItem>
          <DebouncedTextField
            inputProps={{ autoCapitalize: 'off', className: classes.inputField }}
            InputProps={{
              endAdornment:
                <InputAdornment className={classes.search} position="end">
                  <IconSearch size={22} />
                </InputAdornment>,
            }}
            autoFocus={isMobileOnly ? false : true}
            fullWidth
            placeholder={intl.formatMessage(messages.findParticpants)}
            onChange={handleFilterChange}
          />
        </ListItem>
      }
      <GridList spacing={0} cellHeight={50} className={classes.userlist} cols={cols}>
        {users}
      </GridList>
    </List>
  );
}


type MappedProps = {
  roster: ReturnType<typeof getRoster>;
  isAuthenticatedAsGuest: boolean;
  isAuthenticated: boolean;
  myUid: string | null;
  amModerator: boolean;
  isLessonLayout: boolean;
}


type Props = {
  size?: string | number;
}


type ExtendedProps = Props & MappedProps & { intl: IntlShape }


const mapStateToProps = (state: State): MappedProps => ({
  roster: getRoster(state),
  isAuthenticated: getIsAuthenticated(state.auth),
  isAuthenticatedAsGuest: getIsAuthenticatedAsGuest(state.auth),
  myUid: getWsUserId(state.websocket),
  amModerator: amModerator(state),
  isLessonLayout: isLessonLayout(state),
});


export default connect(mapStateToProps)(injectIntl(Roster));
