import React from 'react';
import { withOrientationChange } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import useMediaStream from '../../hooks/useMediaStream';
import { republishStream } from '../../lib/actions/room';
import { toggleBackgroundSettings } from '../../lib/actions/settings';
import { getLogger } from '../../lib/logger';
import { State } from '../../lib/reducers';
import { inMeeting } from '../../lib/reduxSelectors/videoRoom';
import { OrientationProps } from '../../lib/utils/types';
import DevicePreview, { DevicePreviewProps } from '../AVSettings';
import ClosableDialog from '../ClosableDialog';

import BackgroundSelection from './BackgroundSelection';
import BeautifySwitch from './BeautifySwitch';


const messages = defineMessages({
  ok: { id: 'ok' },
  dialogTitle: { id: 'backgroundSettings' },
  dialogHelp: { id: 'backgroundSettingsHelp' }
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    portrait: {
      display: 'flex',
      flexDirection: 'column',
    },
    landscape: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    previewPortrait: {
      width: '320px',
      height: '180px',
      marginBottom: theme.spacing(4),
      alignSelf: 'center',
    },
    previewLandscape: {
      minWidth: '160px',
      maxWidth: '160px',
      minHeight: '90px',
      maxHeight: '90px',
      alignSelf: 'center',
    },
    rootPortrait: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('sm')]: {
        width: 350
      }
    },
    rootLandscape: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: theme.palette.background.paper,
      width: 350
    },
    help: {
      whiteSpace: 'pre-line'
    }
  })
);


function BackgroundSettings(props: ExtendedProps) {
  const {
    canPublishVideo,
    isOpen,
    inMeeting,
    isLandscape,
    isVideoEnabled,
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  const withVideo = inMeeting ? canPublishVideo && isVideoEnabled : isVideoEnabled;

  const roomOptions = {
    acquireVideo: withVideo,
    muted: false,
    frameRate: props.roomOptions.frame_rate,
    streamQuality: props.roomOptions.stream_quality,
  };

  const onClose = React.useCallback(
    () => dispatch(toggleBackgroundSettings())
    , [dispatch]
  );

  const accept = React.useCallback(
    () => {
      if (inMeeting) {
        const logger = getLogger(`Republish stream`);
        dispatch(republishStream(roomOptions, logger));
      }
      onClose();
    }
    , [dispatch, inMeeting, roomOptions, onClose]
  );

  const forceFullScreen = useMediaQuery(useTheme().breakpoints.down('sm')) && isLandscape;

  const getHelp = () => {
    return (
      <div className={classes.help}>
        { formatMessage(messages.dialogHelp) }
      </div>
    );
  };

  const [isAcquiringMedia, setIsAcquiringMedia] = React.useState(true);

  return (
    <ClosableDialog
      open={isOpen}
      disableBackdropClick
      onClose={onClose}
      fullWidth={isLandscape}
      forceFullScreen={forceFullScreen}
      title={formatMessage(messages.dialogTitle)}
      help={getHelp()}
      helpIcon="question-mark"
    >
      <DialogContent className={isLandscape ? classes.landscape : classes.portrait}>
        <div className={isLandscape ? classes.previewLandscape : classes.previewPortrait}>
          <MediaPreview videoEnabled={withVideo} onAcquiringMedia={setIsAcquiringMedia} />
        </div>
        <div className={isLandscape ? classes.rootLandscape : classes.rootPortrait}>
          <BackgroundSelection isButtonDisabled={isAcquiringMedia} />
          <BeautifySwitch isButtonDisabled={isAcquiringMedia} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={accept} color='primary'>
          {formatMessage(messages.ok)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


type MediaPreviewProps = {
  videoEnabled: DevicePreviewProps['videoEnabled'];
  onAcquiringMedia: (b: boolean) => void;
}


function MediaPreview({ videoEnabled, onAcquiringMedia }: MediaPreviewProps) {
  const [isAcquiringMedia, setIsAcquiringMedia] = React.useState(true);
  const onVideoLoading = React.useCallback(
    (isAcquiring: boolean) => {
      setIsAcquiringMedia(isAcquiring);
      onAcquiringMedia(isAcquiring);
    },
    [setIsAcquiringMedia, onAcquiringMedia]
  );

  const { stream, error: streamError } = useMediaStream({ videoEnabled: videoEnabled, onVideoLoading });


  return (
    <DevicePreview
      stream={stream}
      streamError={streamError}
      videoEnabled={videoEnabled}
      isAcquiringMedia={isAcquiringMedia}
    />
  );
}


type Props = {};

type MappedProps = {
  isOpen: State['settings']['backgroundSettingsDialogOpen'];
  inMeeting: boolean;
  roomOptions: State['appconfig']['room_options'];
  canPublishVideo: boolean;
  isVideoEnabled: boolean;
}

type ExtendedProps = Props & MappedProps & OrientationProps

function mapStateToProps(state: State) {
  const amInMeeting = inMeeting(state);
  return {
    isOpen: state.settings.backgroundSettingsDialogOpen,
    inMeeting: amInMeeting,
    roomOptions: state.appconfig.room_options,
    canPublishVideo: state.room.mediaPermissions.canPublishVideo,
    isVideoEnabled: state.settings.videoEnabled,
  };
}


export default withOrientationChange(connect(mapStateToProps)(BackgroundSettings));
