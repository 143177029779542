import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import moment from 'moment';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { State as RoomState } from '../../lib/reducers/room';
import { humanizeTime } from '../../lib/utils/time';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    meeting: {
      color: theme.palette.text.primary,
      marginLeft: '1ex',
      width: '8ex',
    },
  })
);


const messages = defineMessages({
  meetingDuration: { id: 'meetingDuration' },
  meetingBriefDuration: { id: 'meetingBriefDuration' },
  meetingCommonDuration: { id: 'meetingCommonDuration' },
  meetingLongDuration: { id: 'meetingLongDuration' },
  meetingVeryLongDuration: { id: 'meetingVeryLongDuration' },
  unit: {
    day: { id: 'day' },
    days: { id: 'days' },
    hour: { id: 'hour' },
    hours: { id: 'hours' },
    minute: { id: 'minute' },
    minutes: { id: 'minutes' },
    second: { id: 'second' },
    seconds: { id: 'seconds' }
  },
});


type MappedProps = {
  elapsedTime: number;
}

type State = {
  room: RoomState;
}

type Props = {
  humanize?: boolean;
} & MappedProps

const mapStateToProps = (state: State): MappedProps => ({
  elapsedTime: state.room.elapsed,
});

function MeetingDuration(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const getTime = () => {
    if (props.humanize) {
      const time = humanizeTime(props.elapsedTime, formatMessage, messages);
      return (
        <div>{time}</div>
      );
    } else {
      const momentDuration = moment.duration(props.elapsedTime, "seconds").asMilliseconds();
      const time = moment.utc(momentDuration).format("HH:mm:ss");
      return (
        <Typography variant="body2" className={classes.meeting}>
          {time}
        </Typography>
      );
    }
  };

  return (
    <div>
      {getTime()}
    </div>
  );
}

export default connect(mapStateToProps)(MeetingDuration);
