import React, { Component, Fragment } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import update from 'immutability-helper';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ReactRelayContext } from 'relay-hooks';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import IntlPropType from '../../intl';
import { logEvent, Event } from '../../lib/analytics';
import { scheduleMeeting } from '../../lib/api/relay/scheduleMeeting';
import { scheduleMeetingAs } from '../../lib/api/relay/scheduleMeetingAs';
import { updateMeeting } from '../../lib/api/relay/updateMeeting';
import { updateMeetingAs } from '../../lib/api/relay/updateMeetingAs';
import { meetingPrivacyValues } from '../../lib/api/relay/utils/types';
import { isEmail } from '../../lib/utils';
import { relayOperation, SCHEDULE_MEETING } from '../../lib/utils/relayOperation';
import { generateRequestId } from '../../lib/utils/requestId';
import ClosableDialog from '../ClosableDialog';
import { IconCalendarNewEvent, IconCalendar, IconChevronDown, IconChevronLeft, IconChevronRight } from '../IconSet';
import ListAdder from '../ListAdder';

import MeetingPrivacySelector from './MeetingPrivacySelector';
import MeetingTypeSelector from './MeetingTypeSelector';
import ScheduleAsForm from './ScheduleAsForm';

import style from './style';


const messages = defineMessages({
  ok: { id: 'ok' },
  close: { id: 'close' },
  cancel: { id: 'cancel' },
  dateFormat: { id: 'dateFormat' },
  scheduleMeeting: { id: 'scheduleMeeting' },
  scheduleMeetingPlaceHolder: { id: 'scheduleMeetingPlaceHolder' },
  startMeeting: { id: 'startMeeting' },
  endMeeting: { id: 'endMeeting' },
  email: { id: 'email' },
  save: { id: 'save' },
  titleMeeting: { id: 'titleMeeting' },
  titleMeetingError: { id: 'titleMeetingError' },
  notes: { id: 'notes' },
  notesPlaceHolder: { id: 'notesAdd' },
  particpantsAdd: { id: 'particpantsAdd' },
  scheduleHour: { id: 'scheduleHour' },
  sendInvite: { id: 'sendInvite' },
  attendeesError: {
    email: {
      id: 'attendeesErrorEmail'
    },
    required: {
      id: 'attendeesErrorRequired'
    },
  },
  dtError: {
    too_long: {
      id: 'dtErrorTooLong'
    },
    too_brief: {
      id: 'dtErrorTooBrief'
    },
    invalid_format: {
      id: 'dtErrorInvalidFormat'
    }
  },
});

class ScheduleMeeting extends Component {

  static contextType = ReactRelayContext;

  constructor(props) {
    super(props);
    const now = moment().seconds(0);
    const now_hour = now.get('hour');
    const now_minutes = now.get('minutes');
    let selectedDay = null;
    let selectedDayEnd = null;

    if (props.selectedDay) {
      selectedDay = props.selectedDay.clone();
      selectedDay.set('minute', now_minutes);
      selectedDay.set('hour', now_hour);
      selectedDay.set('seconds', 0);
      selectedDayEnd = this.addMinutes(selectedDay, 60);
    }

    const useOrganizer = props.meetingDetails.userId && props.meetingDetails.userId !== props.userId;
    const meetingType = props.meetingDetails.type ? props.meetingDetails.type : props.defaultMeetingType;
    const meetingPrivacy = this._getMeetingPrivacy(props.meetingDetails, props.roomLockPreference);

    this.state = {
      users: { error: false, errorType: null, value: props.meetingDetails.users },
      title: { error: false, errorType: null, value: props.meetingDetails.title },
      dt_start: { error: false, errorType: null, value: props.meetingDetails.dtStart || selectedDay || now },
      dt_end: {
        error: false, errorType: null, value: props.meetingDetails.dtEnd ||
          selectedDayEnd || this.addMinutes(now, 60)
      },
      notes: props.meetingDetails.notes,
      type: meetingType,
      privacy: meetingPrivacy,
      meeting_id: props.meetingDetails.meetingId,
      selectValue: 10,
      useOrganizer: useOrganizer,
      scheduleMeetingAsId: useOrganizer ? props.meetingDetails.userId : null,
      isUpdate: Boolean(props.meetingDetails.meetingId),
    };

    this.handleChangedtStart = this.handleChangedtStart.bind(this);
    this.handleChangedtTimeEnd = this.handleChangedtTimeEnd.bind(this);
    this.handleChangedtTimeStart = this.handleChangedtTimeStart.bind(this);
    this.handleChangedtEnd = this.handleChangedtEnd.bind(this);
    this.handleErrorDtEnd = this.handleErrorDtEnd.bind(this);
    this.handleErrorDtStart = this.handleErrorDtStart.bind(this);
    this.handleChangeTitleMeeting = this.handleChangeTitleMeeting.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.handleChangeMeetingType = this.handleChangeMeetingType.bind(this);
    this.handleUseOrganizer = this.handleUseOrganizer.bind(this);
    this.handleSetScheduleMeetingAsId = this.handleSetScheduleMeetingAsId.bind(this);
    this.handleChangeMeetingPrivacy = this.handleChangeMeetingPrivacy.bind(this);
    this.addUser = this.addUser.bind(this);
    this.addAllUser = this.addAllUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  _getMeetingPrivacy(meetingDetails, roomLockPreference) {
    if (meetingDetails.meta && meetingDetails.meta.privacy) {
      return meetingDetails.meta.privacy;
    } else {
      if (roomLockPreference)
        return meetingPrivacyValues.locked.value;
      else
        return meetingPrivacyValues.open.value;
    }
  }

  handleChangeTitleMeeting(event) {
    this.setState({
      title: update(this.state.title, { error: { $set: false }, value: { $set: event.target.value } })
    });
  }

  handleErrorDtEnd(error) {
    if (error) {
      this.setState({
        dt_end: update(this.state.dt_end, { error: { $set: true }, errorType: { $set: "invalid_format" } })
      });
    }
    return;
  }

  handleErrorDtStart(error) {
    if (error) {
      this.setState({
        dt_start: update(this.state.dt_start, { error: { $set: true }, errorType: { $set: "invalid_format" } })
      });
    }
    return;
  }

  handleChangedtTimeEnd(event) {
    if (event) {
      if (!moment(event).isValid()) {
        this.setState({
          dt_end: update(this.state.dt_end, { error: { $set: true }, errorType: { $set: "invalid_format" } })

        });
        return;
      }
      const edit_hour = event.get('hour');
      const edit_minutes = event.get('minutes');

      let new_end = this.state.dt_end.value.clone();
      new_end.set('minute', edit_minutes);
      new_end.set('hour', edit_hour);
      let dt_start = this.state.dt_start.value;
      if (new_end.isBefore(dt_start)) {
        dt_start = this.delMinutes(new_end, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start,
          { error: { $set: false }, errorType: { $set: null }, value: { $set: dt_start } }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_end }
        })
      });
    }
  }

  handleChangedtTimeStart(event) {
    if (event) {

      if (!moment(event).isValid()) {
        this.setState({
          dt_start: update(this.state.dt_start, { error: { $set: true }, errorType: { $set: "invalid_format" } })

        });
        return;
      }

      let dt_end = this.state.dt_end.value;
      const edit_hour = event.get('hour');
      const edit_minutes = event.get('minutes');

      let new_start_date = this.state.dt_start.value.clone();
      new_start_date.set('minute', edit_minutes);
      new_start_date.set('hour', edit_hour);

      if (!new_start_date.isBefore(dt_end)) {
        dt_end = this.addMinutes(new_start_date, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_start_date }
        }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false }, errorType: { $set: null }, value: { $set: dt_end }
        })
      });
    }
  }

  handleChangedtStart(event) {
    if (event) {
      let dt_end = this.state.dt_end.value;
      if (!moment(event).isValid()) {
        this.setState({
          dt_start: update(this.state.dt_start, {
            error: { $set: true },
            errorType: { $set: "invalid_format" }
          })

        });
        return;
      }

      let old_start_date = this.state.dt_start.value.clone();
      const edit_hour = old_start_date.get('hour');
      const edit_minutes = old_start_date.get('minutes');

      let new_start_date = event.clone();
      new_start_date.set('minute', edit_minutes);
      new_start_date.set('hour', edit_hour);

      if (!new_start_date.isBefore(dt_end)) {
        dt_end = this.addMinutes(new_start_date, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_start_date }
        }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false }, errorType: { $set: null }, value: { $set: dt_end }
        })
      });
    }
  }

  handleChangedtEnd(event) {
    if (event) {

      if (!moment(event).isValid()) {
        this.setState({
          dt_end: update(this.state.dt_end, { error: { $set: true }, errorType: { $set: "invalid_format" } })

        });
        return;
      }

      let old_end_date = this.state.dt_end.value.clone();
      const edit_hour = old_end_date.get('hour');
      const edit_minutes = old_end_date.get('minutes');

      let new_end_date = event.clone();
      new_end_date.set('minute', edit_minutes);
      new_end_date.set('hour', edit_hour);

      let dt_start = this.state.dt_start.value;
      if (new_end_date.isBefore(dt_start)) {
        dt_start = this.delMinutes(new_end_date, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: dt_start }
        }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_end_date }
        })
      });
    }
  }

  handleChangeNotes(event) {
    this.setState({
      notes: update(this.state.notes, { $set: event.target.value })
    });
  }

  handleChangeMeetingType(type) {
    this.setState({
      type: update(this.state.type, { $set: type })
    });
  }

  handleChangeMeetingPrivacy(privacy) {
    this.setState({
      privacy: update(this.state.privacy, { $set: privacy })
    });
  }

  handleUseOrganizer(use) {
    this.setState({
      useOrganizer: update(this.state.useOrganizer, { $set: use })
    });
  }

  handleSetScheduleMeetingAsId(id) {
    this.setState({
      scheduleMeetingAsId: update(this.state.scheduleMeetingAsId, { $set: id })
    });
  }

  addMinutes(date, minutes) {
    return moment(date + (minutes * 60 * 1000));
  }

  delMinutes(date, minutes) {
    return moment(date - (minutes * 60 * 1000));
  }

  addUser(item) {
    this.setState({
      users: update(this.state.users, { error: { $set: false }, value: { $push: [item] } })
    });
  }

  addAllUser(items) {
    this.setState({
      users: update(this.state.users, { error: { $set: false }, value: { $push: items } })
    });
  }

  deleteUser(index) {
    this.setState({
      users: update(this.state.users, { value: { $splice: [[index, 1]] } })
    });
  }

  isDuplicateEmail(v) {
    let found = false;
    if (this.state.users.value.length > 0) {
      this.state.users.value.forEach((users) => {
        if (v === users) {
          found = true;
        }
      });
    }
    return found;
  }

  validateTitleMeetingForm() {
    if (this.state.title.value.length > 0) {
      return true;
    } else {
      this.setState({
        title: update(this.state.title, { error: { $set: true }, errorType: { $set: "empty" } })
      });
      return false;
    }
  }

  validateUsers() {
    if (this.state.users.value.length > 0) {
      return true;
    } else {
      this.setState({
        users: update(this.state.users, { error: { $set: true }, errorType: { $set: "required" } })
      });
      return false;
    }
  }


  validateForm() {
    const isTitleMeetingFormValid = this.validateTitleMeetingForm();
    const areUsersValid = this.validateUsers();
    const dtStartValid = !this.state.dt_start.error;
    const dtEndValid = !this.state.dt_end.error;
    if (isTitleMeetingFormValid && areUsersValid && dtEndValid && dtStartValid) {
      return true;
    } else {
      return false;
    }
  }

  getMeetingParams() {
    const { title, dt_start, dt_end, users, notes, meeting_id, type, privacy } = this.state;
    const { userId, domainId } = this.props;
    return {
      title: title.value,
      dtStart: moment(dt_start.value),
      dtEnd: moment(dt_end.value),
      users: users,
      userId: userId,
      domainId: domainId,
      notes: notes ? notes : null,
      id: meeting_id,
      type: type,
      meta: { privacy: privacy }
    };
  }

  sendInvite() {
    const meetingParams = this.getMeetingParams();
    const relayEnvironment = this.context.environment;

    const actions = [
      scheduleMeeting,
      scheduleMeetingAs,
      updateMeeting,
      updateMeetingAs
    ];

    const params = [
      'scheduleMeeting',
      'scheduleMeetingAs',
      'updateMeeting',
      'updateMeetingAs',
    ];

    let index = 0;
    let event = Event.CREATE_MEETING;
    let asParam = 'create_as';
    let as = 'owner';

    if (this.state.useOrganizer && this.state.scheduleMeetingAsId) {
      meetingParams.userId = this.state.scheduleMeetingAsId;
      if (meetingParams.id === null) {
        // scheduleMeetingAs
        index = 1;
      } else {
        // updateMeetingAs
        index = 3;
        event = Event.UPDATE_MEETING;
        asParam = 'update_as';
      }
      as = 'organizer';
    } else {
      if (meetingParams.id === null) {
        // scheduleMeeting
        index = 0;
      } else {
        // updateMeeting
        index = 2;
        event = Event.UPDATE_MEETING;
        asParam = 'update_as';
      }
    }

    return actions[index](relayEnvironment, meetingParams)
      .then((res) => {
        const { errors } = res[params[index]];
        if (errors) {
          errors.forEach((form) => {
            this.setState({
              [form.key]: update(
                this.state[form.key],
                { error: { $set: true }, errorType: { $set: form.reason[0] } }
              )
            });
          });
        } else {
          this.props.onCloseClick();
          logEvent(event, {
            [asParam]: as,
            'meeting_type': meetingParams.type.toLowerCase(),
            'meeting_privacy': meetingParams.meta.privacy.toLowerCase()
          });
        }
      })
      .catch(() => { });
  }

  handleSendInvite = () => {
    if (this.validateForm()) {
      return relayOperation(this.props.dispatch, () => this.sendInvite(), generateRequestId(), SCHEDULE_MEETING);
    }

  }

  handleChange = (ev) => {
    this.setState({ selectValue: ev.target.value });
  }

  emailValidator = (email) => {
    return (!this.isDuplicateEmail(email) && isEmail(email)) || email.length === 0;
  }

  render() {
    const classes = this.props.classes;
    const fullScreen = this.props.fullScreen;
    const dialogTitle = this.props.dialogTitle || this.props.intl.formatMessage(messages.scheduleMeeting);
    return (
      <div>
        <Fragment>
          <ClosableDialog
            open={true}
            disableBackdropClick
            maxWidth='md'
            fullWidth={true}
            onClose={this.props.onCloseClick}
            onEscapeKeyDown={this.props.onCloseClick}
            fullScreen={fullScreen}
          >
            <DialogContent className={classes.dialogContent}>
              <form noValidate>
                <Grid container direction='row'
                  alignItems='flex-start'
                  justify='space-between'
                  spacing={1}
                >
                  <Grid item xs={12} sm={12}>
                    <Box pb={1} flexGrow={1} display="flex" alignItems='center'
                      alignContent='center'>
                      <Box pb={1}>
                        <IconCalendarNewEvent size={96} />
                      </Box>
                      <Box flexGrow={1} pb={1} px={2}>
                        <Typography variant="h5">
                          {dialogTitle}
                        </Typography>
                      </Box>
                    </Box>

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} error={this.state.title.error}>
                      <InputLabel>{this.props.intl.formatMessage(messages.titleMeeting)}</InputLabel>
                      <Input
                        autoFocus
                        id="title"
                        variant="text"
                        placeholder={this.props.intl.formatMessage(messages.scheduleMeetingPlaceHolder)}
                        defaultValue={this.state.title.value}
                        onChange={this.handleChangeTitleMeeting}
                        className={classes.textField}
                      />
                      {this.state.title.error &&
                        <FormHelperText>
                          {this.props.intl.formatMessage(messages.titleMeetingError)}
                        </FormHelperText>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl}>
                      <MeetingTypeSelector
                        defaultMeetingType={this.state.type}
                        setMeetingType={this.handleChangeMeetingType}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Grid item xs={6} sm={6}>
                      <FormControl className={classes.formControl} error={this.state.dt_start.error}>
                        <KeyboardDatePicker
                          disableToolbar={true}
                          disablePast={true}
                          autoOk={true}
                          mask="__/__/____"
                          leftArrowIcon={<IconChevronLeft size={24} />}
                          rightArrowIcon={<IconChevronRight size={24} />}
                          format="L"
                          label={this.props.intl.formatMessage(messages.startMeeting)}
                          value={this.state.dt_start.value}
                          keyboardIcon={<IconCalendar size={24} />}
                          onChange={this.handleChangedtStart}
                          onError={this.handleErrorDtStart}
                          okLabel={this.props.intl.formatMessage(messages.ok)}
                          cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                        />
                        {this.state.dt_start.error &&
                          <div>
                            <FormHelperText className={classes.formError}>
                              {this.props.intl.formatMessage(messages.dtError[this.state.dt_start.errorType])}
                            </FormHelperText>
                          </div>
                        }
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormControl className={classes.formControl} error={this.state.dt_start.error}>
                        <KeyboardTimePicker
                          clearable={false}
                          ampm={false}
                          mask="__:__"
                          label={this.props.intl.formatMessage(messages.scheduleHour)}
                          value={this.state.dt_start.value}
                          onChange={this.handleChangedtTimeStart}
                          keyboardIcon={<IconChevronDown size={24} />}
                          okLabel={this.props.intl.formatMessage(messages.ok)}
                          cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                        />
                        {this.state.dt_start.error &&
                          <div>
                            <FormHelperText className={classes.formError}>
                              {this.props.intl.formatMessage(messages.dtError[this.state.dt_start.errorType])}
                            </FormHelperText>
                          </div>
                        }
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormControl className={classes.formControl} error={this.state.dt_end.error}>
                        <KeyboardDatePicker
                          disableToolbar={true}
                          disablePast={true}
                          autoOk={true}
                          mask="__/__/____"
                          leftArrowIcon={<IconChevronLeft size={24} />}
                          rightArrowIcon={<IconChevronRight size={24} />}
                          format="L"
                          label={this.props.intl.formatMessage(messages.endMeeting)}
                          value={this.state.dt_end.value}
                          onChange={this.handleChangedtEnd}
                          keyboardIcon={<IconCalendar size={24} />}
                          onError={this.handleErrorDtEnd}
                          okLabel={this.props.intl.formatMessage(messages.ok)}
                          cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                        />
                        {this.state.dt_end.error &&
                          <div>
                            <FormHelperText className={classes.formError}>
                              {this.props.intl.formatMessage(messages.dtError[this.state.dt_end.errorType])}
                            </FormHelperText>
                          </div>
                        }
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <FormControl className={classes.formControl} error={this.state.dt_end.error}>
                        <KeyboardTimePicker
                          clearable={false}
                          mask="__:__"
                          ampm={false}
                          label={this.props.intl.formatMessage(messages.scheduleHour)}
                          value={this.state.dt_end.value}
                          onChange={this.handleChangedtTimeEnd}
                          keyboardIcon={<IconChevronDown size={24} />}
                          okLabel={this.props.intl.formatMessage(messages.ok)}
                          cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                        />
                        {this.state.dt_end.error &&
                          <div>
                            <FormHelperText className={classes.formError}>
                              {this.props.intl.formatMessage(messages.dtError[this.state.dt_end.errorType])}
                            </FormHelperText>
                          </div>
                        }
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{this.props.intl.formatMessage(messages.notes)}</InputLabel>
                        <Input
                          id="text"
                          variant="text"
                          placeholder={this.props.intl.formatMessage(messages.notesPlaceHolder)}
                          multiline
                          rows="4"
                          rowsMax="4"
                          defaultValue={this.state.notes}
                          onChange={this.handleChangeNotes}
                          className={classes.textField}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} container>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl}>
                        <MeetingPrivacySelector
                          setMeetingPrivacy={this.handleChangeMeetingPrivacy}
                          defaultMeetingPrivacy={this.state.privacy}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className={classes.formControl} error={this.state.users.error}>
                        <ListAdder
                          items={this.state.users.value}
                          textFieldLabel={this.props.intl.formatMessage(messages.particpantsAdd)}
                          placeholder={"Insert the email address to invite"}
                          onAdd={this.addUser}
                          onAddAll={this.addAllUser}
                          onRemove={this.deleteUser}
                          invalidMessage={this.props.intl.formatMessage(messages.attendeesError.email)}
                          validator={this.emailValidator}
                        />
                        {this.state.users.error &&
                          <Typography className={classes.formError} variant="body2" gutterBottom align="center">
                            {this.props.intl.formatMessage(messages.attendeesError.required)}
                          </Typography>
                        }
                      </FormControl>
                    </Grid>
                  </Grid>
                  {this.props.sendAs &&
                    <Grid item xs={12} sm={6}>
                      <FormControl className={classes.formControl}>
                        <ScheduleAsForm
                          setUseOrganizer={this.handleUseOrganizer}
                          setScheduleMeetingAsId={this.handleSetScheduleMeetingAsId}
                          useOrganizer={this.state.useOrganizer}
                          scheduleMeetingAsId={this.state.scheduleMeetingAsId}
                          isUpdate={this.state.isUpdate}
                        />
                      </FormControl>
                    </Grid>
                  }
                </Grid>
              </form>

            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button ref={this.myRef}
                color="primary" variant="contained"
                onClick={this.handleSendInvite}>
                {this.props.intl.formatMessage(messages.save)}
              </Button>
            </DialogActions>
          </ClosableDialog>
        </Fragment>
      </div>
    );
  }

}


ScheduleMeeting.defaultProps = {
  dialogTitle: null,
  meetingDetails: {
    users: [],
    title: '',
    notes: '',
    dtStart: null,
    dtEnd: null,
    meetingId: null
  }
};

ScheduleMeeting.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: IntlPropType.isRequired,
  userId: PropTypes.string,
  domainId: PropTypes.string,
  sendAs: PropTypes.bool,
  defaultMeetingType: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  meetingDetails: PropTypes.object,
  selectedDay: PropTypes.object,
  dialogTitle: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  roomLockPreference: PropTypes.bool.isRequired
};


function mapStateToProps(state) {
  return {
    domainId: state.session.domainId,
    userId: state.session.userId,
    sendAs: state.session.sendAs,
    defaultMeetingType: state.session.defaultMeetingType,
    roomLockPreference: state.session.roomLockPreference
  };
}

export { ScheduleMeeting };
export default withStyles(style)(injectIntl(connect(mapStateToProps)(withMobileDialog()(ScheduleMeeting))));
