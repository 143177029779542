import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import lodash from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import { muteAll, unMuteAll, toggleVideoMute, } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { isWebinarLayout, isAudioOnlyLayout, isThereAnyVideo } from '../../lib/reduxSelectors/room';
import { getWsUserId } from '../../lib/reduxSelectors/websocket';
import useStyles from '../../style/ControlButton';
import { IconVideo, IconMicrophone, IconMicrophoneOff } from '../IconSet';


const messages = defineMessages({
  muteAllButton: { id: 'muteAllButton' },
  unMuteAllButton: { id: 'unMuteAllButton' },
  muteAllVideos: { id: 'muteAllVideos' },
});


function Header(props: ExtendedProps) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const authorized = ['room_owner', 'room_moderator'].includes(props.myRole);
  const disabled = props.isRequestingAllAudioMute;
  const classes = useStyles();

  const hideMuteAllVideosButton = props.isWebinarLayout || (props.isAudioOnlyLayout || !props.isThereAnyVideo);

  const muteAllCmd = React.useCallback(
    () => {
      dispatch(muteAll());
      logEvent(Event.MUTE_ALL_MICROPHONES, { 'action': 'mute' });
    }, [dispatch]
  );

  const unMuteAllCmd = React.useCallback(
    () => {
      dispatch(unMuteAll());
      logEvent(Event.MUTE_ALL_MICROPHONES, { 'action': 'unmute' });
    }, [dispatch]
  );

  const muteAllVideos = React.useCallback(
    () => {
      props.others.forEach((user) => dispatch(toggleVideoMute(user, true)));
      logEvent(Event.MUTE_ALL_VIDEOS);
    }, [dispatch, props]
  );

  const amIAlone = props.users.length === 1;

  const Buttons = (
    <div style={{ visibility: amIAlone ? 'hidden' : 'visible' }}>
      {authorized &&
        <Tooltip placement="top" title={formatMessage(messages.muteAllButton)}>
          <IconButton size='small' disabled={disabled} onClick={muteAllCmd} className={classes.controlButton}>
            <IconMicrophoneOff size={24} />
          </IconButton>
        </Tooltip>
      }
      {authorized &&
        <Tooltip placement="top" title={formatMessage(messages.unMuteAllButton)}>
          <IconButton size='small' disabled={disabled} onClick={unMuteAllCmd} className={classes.controlButton}>
            <IconMicrophone size={24} />
          </IconButton>
        </Tooltip>

      }
      {!hideMuteAllVideosButton &&
        <Tooltip placement="top" title={formatMessage(messages.muteAllVideos)}>
          <IconButton size='small' onClick={muteAllVideos} className={classes.controlButton}>
            <IconVideo size={24} />
          </IconButton>
        </Tooltip>
      }
    </div>
  );

  return (
    <ListItem>
      <ListItemText primary={props.headerText} />
      {Buttons}
    </ListItem>
  );
}


type MappedProps = {
  myRole: string;
  isRequestingAllAudioMute: boolean;
  users: Array<string>;
  others: Array<string>;
  isAudioOnlyLayout: boolean;
  isThereAnyVideo: boolean;
  isWebinarLayout: boolean;
}


type Props = {
  headerText: string;
}

type ExtendedProps = Props & MappedProps;


const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  lodash.isEqual
);

const getUsers = (state: State) => {
  const roster = state.room.roster;
  return Object.keys(roster);
};

const mapUsers = createDeepEqualSelector(getUsers, (u) => u || {});

const getMyUid = (state: State) => {
  return getWsUserId((state.websocket || {}));
};

const getOtherMembers = createDeepEqualSelector(
  getUsers,
  getMyUid,
  (users, me) => users.filter(u => u !== me)
);

const mapStateToProps = (state: State): MappedProps => {

  const user = getWsUserId(state.websocket);
  let myRole = '';
  let isRequestingAllAudioMute = false;
  if (user) {
    myRole = (state.room.roster[user] || { myRole: '' }).role;
    isRequestingAllAudioMute = (state.room.roster[user] ||
      { isRequestingAllAudioMute: false }).isRequestingAllAudioMute;
  }
  return {
    isAudioOnlyLayout: isAudioOnlyLayout(state),
    isThereAnyVideo: isThereAnyVideo(state),
    isWebinarLayout: isWebinarLayout(state),
    myRole,
    isRequestingAllAudioMute,
    others: getOtherMembers(state),
    users: mapUsers(state)
  };
};


export default connect(mapStateToProps)(Header);
