import { graphql } from 'babel-plugin-relay/macro';


const myMeetings = graphql`
  query myMeetingsQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sorting: [SearchSort]
    $filters: [SearchFilter]
  ) {
    ...View_data @arguments(
        first: $first, after: $after,
        last: $last, before: $before,
        sorting: $sorting, filters: $filters
      )
  }
`;
export { myMeetings };
