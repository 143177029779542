/* tslint:disable */
/* eslint-disable */
/* @relayHash 8f5b8fdd2254d142a5cb52eac4043611 */

import { ConcreteRequest } from "relay-runtime";
export type UpdateProfileInput = {
    profile?: ProfileUpdate | null;
};
export type ProfileUpdate = {
    email?: string | null;
    meta?: UserMetaInput | null;
    middlename?: string | null;
    name?: string | null;
    password?: string | null;
    surname?: string | null;
};
export type UserMetaInput = {
    livestreaming?: LivestreamingConfigInput | null;
    roomLockPreference?: boolean | null;
    screenshareFrameRate?: number | null;
};
export type LivestreamingConfigInput = {
    apiKey?: string | null;
    apiUrl?: string | null;
    publishUrl?: string | null;
};
export type updateProfileMutationVariables = {
    input: UpdateProfileInput;
};
export type updateProfileMutationResponse = {
    readonly updateProfile: {
        readonly errors: ReadonlyArray<{
            readonly key: string;
            readonly message: ReadonlyArray<string | null> | null;
            readonly reason: ReadonlyArray<string | null> | null;
        } | null> | null;
        readonly user: {
            readonly meta: {
                readonly livestreaming: {
                    readonly apiKey: string | null;
                    readonly apiUrl: string | null;
                    readonly publishUrl: string | null;
                } | null;
                readonly roomLockPreference: boolean | null;
                readonly screenshareFrameRate: number | null;
            } | null;
        } | null;
    } | null;
};
export type updateProfileMutation = {
    readonly response: updateProfileMutationResponse;
    readonly variables: updateProfileMutationVariables;
};



/*
mutation updateProfileMutation(
  $input: UpdateProfileInput!
) {
  updateProfile(input: $input) {
    errors {
      key
      message
      reason
    }
    user {
      meta {
        livestreaming {
          apiKey
          apiUrl
          publishUrl
        }
        roomLockPreference
        screenshareFrameRate
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProfileInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "InputError",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "key",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "reason",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "meta",
  "storageKey": null,
  "args": null,
  "concreteType": "UserMeta",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "livestreaming",
      "storageKey": null,
      "args": null,
      "concreteType": "LivestreamingConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "apiKey",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "apiUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "publishUrl",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "roomLockPreference",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "screenshareFrameRate",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateProfileMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProfile",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProfilePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "updateProfileMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProfile",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProfilePayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateProfileMutation",
    "id": null,
    "text": "mutation updateProfileMutation(\n  $input: UpdateProfileInput!\n) {\n  updateProfile(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    user {\n      meta {\n        livestreaming {\n          apiKey\n          apiUrl\n          publishUrl\n        }\n        roomLockPreference\n        screenshareFrameRate\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '53bb112d3e567e875dfb1ce7a0570a49';
export default node;
