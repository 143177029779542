import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { State } from '../../lib/reducers';
import getRouteFor, { Route } from '../../lib/utils/router';
import ClosableDialog from '../ClosableDialog';
import { IconUser, IconEmail, IconLocation, IconAlternateEmail } from '../IconSet';
import RoomInfo from '../RoomInfo';


const messages = defineMessages({
  title: { id: 'userInformation' },
  email: { id: 'email' },
  username: { id: 'username' },
  name: { id: 'name' },
  personalRoom: { id: 'personalRoom' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      paddingBottom: theme.spacing(2),
    },
    container: {
      padding: theme.spacing(1),
    },
    avatar: {
      width: "50%",
      height: "auto",
      paddingBottom: "50%",
      marginTop: '20px',
    },
    icons: {
      textAlign: 'center',
      alignSelf: 'center',
    },
    userInfoEntryTitle: {
      color: theme.palette.text.secondary,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1em',
    },
    userInfoEntryContent: {
      color: theme.palette.text.primary,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },
    linkContent: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    userInfoIconContainer: {
      display: 'flex',
    },
    userInfoLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);


type MappedProps = {
  email: State['session']['email'];
  username: State['session']['username'];
  displayName: State['session']['displayName'];
}


type Props = {
  onClose: () => void;
};


type ExtendedProps = Props & MappedProps;


function UserProfile(props: ExtendedProps) {

  const {
    username,
    onClose,
    displayName,
    email
  } = props;

  const classes = useStyles();
  const { formatMessage } = useIntl();

  const personalRoomRawUrl = getRouteFor(
    Route.Meeting,
    { slug: username ? username : "" },
    undefined,
    window.location.origin
  );

  return (
    <ClosableDialog
      open={true}
      onClose={onClose}
      title={formatMessage(messages.title)}
    >
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconUser size={24} />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {formatMessage(messages.name)}
                <div className={classes.userInfoEntryContent}>
                  {displayName}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconEmail size={24} />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {formatMessage(messages.email)}
                <div className={classes.userInfoEntryContent}>
                  {email}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconAlternateEmail size={22} />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {formatMessage(messages.username)}
                <div className={classes.userInfoEntryContent}>
                  {username}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconLocation size={24} />
            </Grid>
            <Grid item xs={10}>
              <RoomInfo
                roomUrl={personalRoomRawUrl}
                roomTitle={messages.personalRoom}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </ClosableDialog>
  );
}

const mapStateToProps = (state: State): MappedProps => ({
  email: state.session.email,
  username: state.session.username,
  displayName: state.session.displayName,
});


export default connect(mapStateToProps)(UserProfile);
