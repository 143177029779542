import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { subscribeToVideo, toggleAllVideoMute, changeVideoQuality } from '../../lib/actions/room';
import { getLogger } from '../../lib/logger';
import { State } from '../../lib/reducers';
import { VideoQualityOptions, StreamQualityValue } from '../../lib/redux_types';

import GridView from './GridView';
import { getStreams } from './StandardDimensionLayout';
import usePublishersCount from './usePublishersCount';
import usePublishersWatcher from './usePublishersWatcher';


function GridLayout(props: ExtendedProps) {
  const dispatch = useDispatch();

  const lowQualityStreamThresh = 5;

  React.useEffect(() => {
    dispatch(toggleAllVideoMute(false));
  }, [dispatch]);

  const newPublishers = usePublishersWatcher();

  newPublishers.forEach((u) => {
    if (u.stream) {
      dispatch(subscribeToVideo(u.uid));
    }
    if (u.screen) {
      dispatch(subscribeToVideo(`${u.uid}_screen`));
    }
  });

  const [ publCount ] = usePublishersCount();

  if (publCount > lowQualityStreamThresh) {
    const constraints: VideoQualityOptions = {
      streamQuality: StreamQualityValue.P180,
      frameRate: 5
    };
    dispatch(changeVideoQuality(constraints, getLogger('gridLayout')));
  }
  else if (publCount <= lowQualityStreamThresh) {
    const constraints: VideoQualityOptions = { streamQuality: props.configuredVideoQuality };
    if (props.roomOptions.frame_rate) {
      constraints.frameRate = props.roomOptions.frame_rate;
    }
    dispatch(changeVideoQuality(constraints, getLogger('gridLayout')));
  }

  return <GridView {...{ remoteVideoStreams: props.remoteVideoStreams }} />;
}


type MappedProps = {
  configuredVideoQuality: undefined | VideoQualityOptions['streamQuality'];
  roomOptions: State['appconfig']['room_options'];
  remoteVideoStreams: ReturnType<typeof getStreams>;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  return {
    configuredVideoQuality: state.settings.videoQuality ? state.settings.videoQuality.value : undefined,
    roomOptions: state.appconfig.room_options,
    remoteVideoStreams: getStreams(state),
  };
};


export default connect(mapStateToProps)(GridLayout);
