import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { ExtendedMeeting } from '.';

import useStyles from './style';


const messages = defineMessages({
  roomParticipants: { id: 'roomInfoParticipants' },
  roomMoreParticipants: { id: 'roomInfoMoreParticipants' },
});


type Props = {
  roomDetails: ExtendedMeeting;
}


function MeetingAttendees(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const getMeetingAttendees = (roomDetails: ExtendedMeeting) => {
    const atts = roomDetails.attendees;
    if (atts.length === 0) {
      return null;
    }

    const shownParticipants = atts.slice(0, 5);
    const extraParticipants = atts.length - shownParticipants.length;
    return (
      <div>
        <div className={classes.title}>
          {formatMessage(messages.roomParticipants)}
        </div>
        <ul className={classes.participants}>
          {shownParticipants.map((val, idx) => {
            return (
              <li key={idx}>
                <div className={classes.content}>
                  {val.email}
                </div>
              </li>
            );
          })}
          {extraParticipants > 0 && <li>
            <div className={classes.content}>
              {formatMessage(messages.roomMoreParticipants, { otherAttendees: extraParticipants })}
            </div>
          </li>}
        </ul>
      </div>
    );
  };

  return (
    <div>
      {getMeetingAttendees(props.roomDetails)}
    </div>
  );

}


export default MeetingAttendees;