import { StreamAdapter } from "../../../../streamAdapters";


export abstract class AbstractStreamTransformation {
  protected flipTexture: boolean;
  protected source?: StreamAdapter;
  protected sink?: StreamAdapter;
  protected setupPromise: Promise<void>;
  private setupResolve: () => void;

  constructor(flipTexture = false) {
    this.flipTexture = flipTexture;
    this.setupResolve = () => {};
    this.setupPromise = new Promise(resolve => this.setupResolve = resolve);
  }

  protected setStreams(source: StreamAdapter, sink: StreamAdapter): void {
    this.source = source;
    this.sink = sink;
    source.getShape().then(shape => {
      sink.setShape(shape);
      this.setupResolve();
    });
  }

  protected async stopStreams(): Promise<void> {
    await this.setupPromise;
    if (this.source) this.source.stop();
    if (this.sink) this.sink.stop();
  }

  public async getSink(): Promise<StreamAdapter> {
    await this.setupPromise;
    if (this.sink) return this.sink;
    else throw new Error("setupPromise resolved without setting sink");
  }

  public abstract setup(source: StreamAdapter, sink: StreamAdapter): Promise<void>;
  public abstract render(): Promise<void>;
  public abstract stop(): Promise<void>;
}
