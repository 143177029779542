import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { SupportLinksContext } from '../../lib/contexts';
import { State } from '../../lib/reducers';
import getRouteFor, { Route } from '../../lib/utils/router';
import { IconDoor } from '../IconSet';
import SupportInfo from '../SupportInfo';


const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(5),
    },
    main: {
      width: '100%',
      height: '100%',
    },
    title: {
      color: theme.palette.primary.main,
    },
    dialogContent: {
      textAlign: 'center',
      maxWidth: '25em',
    },
    dialogActions: {
      paddingTop: theme.spacing(3),
    },
    input: {
      paddingTop: theme.spacing(3),
    },
    loginMessage: {
      textAlign: 'center',
      paddingTop: theme.spacing(3),
    },
    codeInputField: {
      textAlign: 'center',
    },
  });


type MappedProps = {
  siteTitle: string;
}

const mapStateToProps = (state: State): MappedProps => ({
  siteTitle: state.appconfig.site_title,
});

type Props = {} & MappedProps;

type LocalState = {
  meetingId: string | null;
}

type ExtendedProps = Props & WithStyles<typeof styles> & RouteComponentProps;


class LoginPage extends React.Component<ExtendedProps, LocalState> {

  constructor(props: ExtendedProps) {
    super(props);
    this.state = {
      meetingId: null
    };
  }

  gotoLogin = () => {
    this.props.history.push(getRouteFor(Route.Login));
  }

  gotoMeeting = () => {
    if (this.state.meetingId) {
      const path = getRouteFor(Route.Meeting, { slug: this.state.meetingId });
      this.props.history.push(path);
    }
  }

  handleMeetingIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      meetingId: ev.target.value,
    });
  }

  handleMeetingIdKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      this.gotoMeeting();
    }
  }

  render() {
    const { children, siteTitle, classes } = this.props;

    return (
      <Container maxWidth='md' className={classes.container}>
        <Box display="flex" alignItems="center" flexDirection="column" p={1} className={classes.main}>
          <Box flexGrow={1} p={1}>
            <Box display="flex" alignItems="center" p={1} className={classes.main}>
              <Typography variant="h3" className={classes.title}>
                { siteTitle }
              </Typography>
            </Box>
          </Box>
          <Box flexGrow={1} p={1}>
            <Box display="flex" alignItems="center" p={1} className={classes.main}>
              <IconDoor size={86} />
            </Box>
          </Box>
          <Box flexGrow={18} p={1}>
            <Box display="flex" alignItems="flex-start" p={1} className={classes.main}>
              <Box p={1} flexGrow={1} className={classes.dialogContent}>
                {children}
              </Box>
            </Box>
          </Box>
          <SupportLinksContext.Provider value='login_page'>
            <SupportInfo />
          </SupportLinksContext.Provider>
        </Box>
      </Container>
    );
  }
}


export default withStyles(styles)(withRouter(connect(mapStateToProps)(LoginPage)));
