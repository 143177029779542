import { Dispatch } from 'react';

import uuid from 'uuid';

import { addNotification, Action as NotificationAction } from '../actions/notifications';
import { LoggerInterface } from '../logger';
import { NotificationPayload } from '../redux_types';


export const DEBUG = Symbol('DEBUG');
export const INFO = Symbol('INFO');
export const WARNING = Symbol('WARNING');
export const ERROR = Symbol('ERROR');

let _instance: Notifications;

class Notifications {
  _dispatch: Dispatch<NotificationAction>;
  _logger: LoggerInterface;

  constructor(dispatch: Dispatch<NotificationAction>, logger: LoggerInterface) {
    this._dispatch = dispatch;
    this._logger = logger;
  }

  newEvent(priority: symbol, code: number | string, reason: string, message?: string, payload?: NotificationPayload) {
    const event = { prio: priority, code: code, reason: reason, message: message, payload: payload || {} };
    const ref = uuid.v4();

    this._logger.debug("Dispatching notification ", event, ref);

    this._dispatch(addNotification(ref, event));
  }
}

export function newEvent(
  priority: symbol,
  code: number | string,
  reason: string,
  message?: string,
  payload?: NotificationPayload) {
  if (_instance) {
    _instance.newEvent(priority, code, reason, message, payload);
  }
}

export function initNotifications(dispatch: Dispatch<NotificationAction>, logger: LoggerInterface) {
  if (!_instance) {
    logger.info("Started notifications subsystem");
    _instance = new Notifications(dispatch, logger);
  }
  return _instance;
}

