import React from 'react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import { WithAuthorization } from '../../../authorization';
import { changeRole } from '../../../lib/actions/room';
import { logEvent, Event } from '../../../lib/analytics';
import { UserActionsContext } from '../../../lib/contexts';
import { State } from '../../../lib/reducers';
import { canChangeRole } from '../../../lib/reduxSelectors/user';
import { IconModerator, IconModeratorOff } from '../../IconSet';


const messages = defineMessages({
  demoteMessage: { id: 'demoteRoleIconButtonTooltip' },
  promoteMessage: { id: 'promoteRoleIconButtonTooltip' }
});



function ChangeRoleItem(props: ExtendedProps) {
  const { isRequestingRoleChange, changeRoleEnabled, userRole, user, onClose, iconSize = 20 } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const userActionsContext = React.useContext(UserActionsContext);
  const presentatorRoles = ['room_owner', 'room_moderator'];

  const onChangeRole = React.useCallback(
    () => {
      if (user) {
        const who = user.replace(/_screen$/, '');
        const newRole = (userRole === 'room_user') ? 'room_moderator' : 'room_user';
        dispatch(changeRole(who, newRole));
        const action = (newRole === 'room_user') ? 'remove' : 'make';
        logEvent(Event.MAKE_MODERATOR, { 'action': action, 'from': userActionsContext });
      }
      onClose();
    }
    , [dispatch, user, onClose, userRole, userActionsContext]
  );

  const getIcon = (): React.ReactElement => {
    if (presentatorRoles.includes(props.userRole)) {
      return <IconModeratorOff size={iconSize} />;
    }
    else {
      return <IconModerator size={iconSize} />;
    }

  };

  if (!changeRoleEnabled) {
    return null;
  }

  return (
    <MenuItem disabled={isRequestingRoleChange} onClick={onChangeRole}>
      <ListItemIcon>
        {getIcon()}
      </ListItemIcon>
      <ListItemText primary={formatMessage(presentatorRoles.includes(userRole)
        ? messages.demoteMessage
        : messages.promoteMessage)} />
    </MenuItem>

  );

}

type Props = {
  user: string;
  iconSize?: number;
  onClose: () => void;
}

type MappedProps = {
  changeRoleEnabled: boolean;
  userRole: string;
  isRequestingRoleChange: boolean;
}

type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, props: Props): MappedProps => {
  const userId = (props.user || "").replace(/_screen$/, '');
  const user = state.room.roster[userId] || {};
  return {
    changeRoleEnabled: canChangeRole(userId, state),
    userRole: user.role,
    isRequestingRoleChange: user.isRequestingRoleChange,
  };

};

const roles = ['room_owner', 'room_moderator'];

export default WithAuthorization(roles, connect(mapStateToProps)(ChangeRoleItem));
