import { newEvent, ERROR } from "../../../notifications";
import { scheduleMeetingAsMutationResponse } from "../__generated__/scheduleMeetingAsMutation.graphql";
import { scheduleMeetingMutationResponse } from "../__generated__/scheduleMeetingMutation.graphql";
import { updateMeetingAsMutationResponse } from "../__generated__/updateMeetingAsMutation.graphql";
import { updateMeetingMutationResponse } from "../__generated__/updateMeetingMutation.graphql";


type ScheduleMeeting = scheduleMeetingMutationResponse['scheduleMeeting'];
type ScheduleMeetingAs = scheduleMeetingAsMutationResponse['scheduleMeetingAs'];
type UpdateMeeting = updateMeetingMutationResponse['updateMeeting'];
type UpdateMeetingAs = updateMeetingAsMutationResponse['updateMeetingAs'];

type MeetingResponse =
  ScheduleMeeting
  | ScheduleMeetingAs
  | UpdateMeeting
  | UpdateMeetingAs
  | null


function handleSchedulingError(meeting: MeetingResponse): void {
  let erroMessage = 'errorCodeGenericBody';
  if (meeting && meeting.errors && meeting.errors[0]) {
    const error = meeting.errors[0];
    switch (error.key) {
      case 'attendees': {
        if (error.reason && error.reason[0]) {
          const reason = error.reason[0];
          switch (reason) {
            case 'required': {
              erroMessage = 'scheduleMeetingErrorRequired';
              break;
            }
            case 'too_many':
            default: {
              erroMessage = 'scheduleMeetingErrorTooMany';
              break;
            }
          }
        }
        break;
      }
      case 'meeting_type': {
        if (error.reason && error.reason[0]) {
          const reason = error.reason[0];
          switch (reason) {
            case 'meeting_type_not_allowed':
            default: {
              erroMessage = 'scheduleMeetingErrorTypeNotAllowed';
              break;
            }
          }
        }
        break;
      }
      default: {
        erroMessage = 'errorCodeGenericBody';
        break;
      }
    }
  }
  newEvent(ERROR, erroMessage, erroMessage, erroMessage);
}


export { handleSchedulingError };
