import { useSelector } from 'react-redux';

import { State } from '../../../lib/reducers';
import { createDeepEqualitySelector } from '../../../lib/reduxSelectors';
import { usePrevious } from '../../../lib/utils/hooks';
import { difference } from '../../../lib/utils/set';


type Subscription = {
  uid: string;
  kind: 'screen' | 'stream';
}


const _getSubscriptions = ({ room: { roster } }: State) => {
  const subscriptions: Set<Subscription> = new Set();
  Object.keys(roster).forEach(
    k => {
      const u = roster[k];
      if (u && u.streamPublisherData && u.streamPublisherData.subscribed) {
        const s: Subscription = {
          uid: k,
          kind: 'stream',
        };
        subscriptions.add(s);
      }
      if (u && u.screenPublisherData && u.screenPublisherData.subscribed) {
        const s: Subscription = {
          uid: k,
          kind: 'screen',
        };
        subscriptions.add(s);
      }
    }
  );
  return subscriptions;
};


const getSubscriptions = createDeepEqualitySelector(
  [_getSubscriptions],
  (subs: Set<Subscription>) => subs
);


export default function useSubscriptionsWatcher() {
  const subscriptions = useSelector(getSubscriptions);
  const previousSubcriptions = usePrevious(subscriptions, new Set<Subscription>());

  return difference(subscriptions, previousSubcriptions);
}
