import React from 'react';
import { connect } from 'react-redux';
import SplitPane from 'react-split-pane';

import { useTheme } from '@material-ui/core/styles';

import { State } from '../../../lib/reducers';
import { PaneType } from '../../../lib/redux_types';
import { isPaneOpen } from '../../../lib/reduxSelectors/room';
import { isRecorder } from '../../../lib/reduxSelectors/session';
import useDebounce from '../../../lib/utils/debounce';
import {
  getRosterSizePercent,
  getMinRosterSizePx,
  getMaxRosterSizePx,
  getPercent,
} from '../../../lib/utils/rosterDimensions';
import MeetingBar from '../MeetingBar';
import MeetingContent from '../MeetingContent';

import PaneComponent from './PaneComponent';

import useStyles from './styles';


type Props = {
  openPane: (pane: PaneType) => void;
  url: string;
}

type ExtendedProps = Props & MappedProps


function PaneDesktop(props: ExtendedProps) {
  const classes = useStyles();
  const {
    openPane,
    paneOpened,
    url,
  } = props;

  const initialPaneSize = getRosterSizePercent();
  const minPaneSize = getMinRosterSizePx();
  const maxPaneSize = getMaxRosterSizePx();

  const [paneSize, setPaneSize] = React.useState<string>(initialPaneSize);

  const debouncedPaneSize = useDebounce(paneSize, 100);

  const setNewPaneSize = (size: number) => {
    const newSize = getPercent(size);
    setPaneSize(newSize);
  };

  const hideBar = props.isRecorder || !props.canJoinRoom;
  const theme = useTheme();

  return (
    <SplitPane
      split="vertical"
      minSize={hideBar ? 0 : 60}
      defaultSize={hideBar ? 0 : 60}
      allowResize={false}
      className={classes.mainSplit}
      resizerStyle={{ visibility: 'hidden', display: 'none' }}
    >
      <div className={classes.toolbar}>
        {!hideBar &&
          <MeetingBar openPane={openPane} />
        }
      </div>
      <SplitPane
        split="vertical"
        minSize={minPaneSize}
        maxSize={maxPaneSize}
        defaultSize={initialPaneSize}
        allowResize={paneOpened}
        resizerStyle={paneOpened ? { marginLeft: '8px' } : { visibility: 'hidden', display: 'none' }}
        size={paneOpened ? paneSize : 0}
        onChange={setNewPaneSize}
        pane1Style={paneOpened ? { zIndex: theme.zIndex.modal - 2 } : {}}
      >
        <div className={classes.paneDesktop}>
          <PaneComponent size={debouncedPaneSize} />
        </div>
        <div className={classes.content}>
          <MeetingContent url={url} />
        </div>
      </SplitPane>
    </SplitPane>
  );

}


type MappedProps = {
  isRecorder: boolean;
  canJoinRoom: boolean;
  paneOpened: boolean;
}


const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    isRecorder: isRecorder(state, ownProps.url),
    canJoinRoom: state.waitingRoom.canJoinRoom,
    paneOpened: isPaneOpen(state),
  };
};


export default connect(mapStateToProps)(PaneDesktop);
