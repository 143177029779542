import React from 'react';
import { connect } from 'react-redux';

import AudioMuteButton from '../../AudioMuteButton';
import VideoMuteButton from '../../VideoMuteButton';

import { mapStateToProps, MappedProps } from './mapState';


function MainActions(props: Props) {
  const { iconColor, iconSize, cName, mini, uid, mySelf, myScreen } = props;

  return (
    <React.Fragment>
      {(mySelf || myScreen) ? null :
        <React.Fragment>
          <AudioMuteButton iconColor={iconColor} iconSize={iconSize} cName={cName} uid={uid} mini={mini} />
          <VideoMuteButton iconColor={iconColor} iconSize={iconSize} cName={cName} uid={uid} mini={mini} />
        </React.Fragment>
      }
    </React.Fragment>
  );
}


type Props = {
  uid: string;
  cName?: string;
  iconColor?: string;
  iconSize?: number;
  mini?: boolean;
} & MappedProps


export default connect(mapStateToProps)(MainActions);
