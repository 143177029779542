import React from 'react';
import { connect } from 'react-redux';

import { State } from '../../../lib/reducers';
import { PaneType } from '../../../lib/redux_types';
import { ChatPanel } from '../../Chat';
import RoomInformation from '../../RoomInformation';
import Roster from '../../Roster';


type PaneProps = {
  size: string;
}


function PaneComponent(props: ExtendedProps) {
  const { selection, size } = props;

  if (selection === PaneType.Roster) {
    return (<Roster size={size} />);
  }
  else if (selection === PaneType.Chat) {
    return (<ChatPanel />);
  }
  else if (selection === PaneType.Info) {
    return (<RoomInformation />);
  }
  else {
    return null;
  }
}


type MappedProps = {
  selection: PaneType;
}

const mapStateToProps = (state: State): MappedProps => ({
  selection: state.room.selectedPane
});

type ExtendedProps = PaneProps & MappedProps


export default connect(mapStateToProps)(PaneComponent);
