import React from 'react';
import { isMobileOnly, isMobileSafari } from 'react-device-detect';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';

import { RoomActionsContext } from '../../../lib/contexts';
import { State } from '../../../lib/reducers';
import { inMeeting } from '../../../lib/reduxSelectors/videoRoom';
import ActionRoomMenu from '../../ActionRoomMenu';
import { IconMore } from '../../IconSet';
import StyledMenu from '../../StyledMenu';

import Home from './Home';
import MediaSettings from './MediaSettings';
import MobileFullScreen from './MobileFullScreen';
import RoomInfo from './RoomInfo';


type MappedProps = {
  inMeeting: boolean;
};

const mapStateToProps = (state: State): MappedProps => ({
  inMeeting: inMeeting(state),
});

function Actions(props: MappedProps) {
  const inMeeting = props.inMeeting;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (): void => setAnchorEl(null);

  const getActions = () => {
    return (
      <React.Fragment>
        <Home />
        <MediaSettings />
        { inMeeting && !isMobileOnly && <RoomInfo /> }
      </React.Fragment>
    );
  };

  const getActionsMobile = () => {
    return (
      <React.Fragment>
        <IconButton size='small' onClick={handleMenu}>
          <IconMore size={24} />
        </IconButton>
        <StyledMenu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={closeMenu}
          onClick={closeMenu}
        >
          {getActions()}
          { !isMobileSafari && <MobileFullScreen /> }
          <RoomActionsContext.Provider value='upper_menu'>
            <ActionRoomMenu />
          </RoomActionsContext.Provider>
        </StyledMenu>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      { isMobileOnly ? getActionsMobile() : getActions() }
    </React.Fragment>
  );
}


export default connect(mapStateToProps)(Actions);
