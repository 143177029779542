import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { enlargeStreamVideo } from '../../../lib/actions/room';
import { logEvent, Event } from '../../../lib/analytics';
import { State } from '../../../lib/reducers';
import {
  getSelectedStream,
  areSecondaryVideosHidden,
} from '../../../lib/reduxSelectors/presentationLayout';
import { isPresentationLayout } from '../../../lib/reduxSelectors/room';
import useStyles from '../../../style/ControlButton';
import { IconExpand, IconShrink } from '../../IconSet';


const messages = defineMessages({
  enlargeTooltip: { id: 'enlargeIconButtonTooltip' },
  collapseTooltip: { id: 'collapseIconButtonTooltip' },
});


function MaximizeMainVideo(props: ExtendedProps) {
  const classes = useStyles();

  const { isPresentationLayout, isMainVideo, iconSize = 20, mini, secondaryVideosAreHidden } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const hideSecondaryVideos = () => {
    const action = !secondaryVideosAreHidden ? 'expand' : 'collapse';
    logEvent(Event.MAXIMIZE_FEATURED_VIDEO, { 'action': action });
    dispatch(enlargeStreamVideo(!secondaryVideosAreHidden));
  };

  const cls = classNames(classes.controlButton, mini ? classes.controlButtonMini : null);

  if (isPresentationLayout && isMainVideo) {
    const msg = formatMessage(
      (secondaryVideosAreHidden) ? messages.collapseTooltip : messages.enlargeTooltip
    );
    return (
      <Tooltip placement="top" title={msg}>
        <div>
          <IconButton
            onClick={hideSecondaryVideos}
            className={cls}
            size='small'
          >
            {secondaryVideosAreHidden ? <IconShrink size={iconSize} /> : <IconExpand size={iconSize} />}
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  return null;
}


type Props = {
  uid: string;
  iconSize?: number;
  mini?: boolean;
}


type MappedProps = {
  isMainVideo: boolean;
  isPresentationLayout: boolean;
  secondaryVideosAreHidden: boolean;
}


type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, { uid }: Props): MappedProps => {
  const selectedStream = getSelectedStream(state);
  return {
    secondaryVideosAreHidden: areSecondaryVideosHidden(state),
    isMainVideo: uid === selectedStream,
    isPresentationLayout: isPresentationLayout(state),
  };
};


export default connect(mapStateToProps)(MaximizeMainVideo);
