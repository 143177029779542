import { Channel } from 'phoenix';
import { createSelector } from 'reselect';

import { VideoRoom } from '../api/videoroom';
import { State } from '../reducers';
import { PaneType, RoomRole, RosterUser } from '../redux_types';

import { getWsMeetingDetails, getWsUserId } from './websocket';

import { createDeepEqualitySelector } from '.';


export function isThereAnyVideo(state: State) {
  const localVideo = (
    VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream)
    || state.room.screenStream
  );

  if (localVideo) {
    return true;
  }

  const roster = state.room.roster;
  return Object.keys(roster).some(
    (uid) => {
      const user = roster[uid];
      return (
        Boolean(VideoRoom.getVideoTrackFromStream(user.stream))
        || user.screen
        || user.screenPublisherData
        || user.streamPublisherData
      );
    }
  );
}

export function amModerator(state: State): boolean {
  const myUid = getWsUserId(state.websocket);
  if (myUid) {
    const myUser = state.room.roster[myUid];
    if (myUser && myUser.role) {
      return ['room_owner', 'room_moderator'].includes(myUser.role);
    }
  }
  return false;
}


export function hasModeratorRole(role: RoomRole): boolean {
  return ['room_owner', 'room_moderator'].includes(role);
}


export function isModerator(state: State, uid: string): boolean {
  const user = state.room.roster[uid];
  if (user && user.role) {
    return hasModeratorRole(user.role);
  }
  return false;
}


function _getModerators(state: State) {
  return Object.keys(state.room.roster).filter(
    (uid) => {
      const user = state.room.roster[uid];
      return ['room_owner', 'room_moderator'].includes(user.role);
    }
  );
}


export const getModerators = createDeepEqualitySelector([_getModerators], (mods) => mods);


function _publishersCount(state: State) {
  let count = 0;
  const roster = state.room.roster;
  Object.keys(roster).forEach(
    (uid) => {
      const user = roster[uid];
      if (user.streamPublisherData) {
        count += 1;
      }

      if (user.screenPublisherData) {
        count += 1;
      }
    }
  );
  return count;
}


export const publishersCount = createSelector([ _publishersCount ], (c: number) => c);


export function isGridLayout(state: State): boolean {
  return state.room.layout === 'default';
}

export function isPresentationLayout(state: State): boolean {
  return state.room.layout === 'featured';
}

export function isFullscreenLayout(state: State): boolean {
  return state.room.layout === 'fullscreen';
}

export function isAudioOnlyLayout(state: State): boolean {
  return state.room.layout === 'audioonly';
}

export function isWebinarLayout(state: State): boolean {
  return state.room.layout === 'webinar';
}


export function isLessonLayout(state: State): boolean {
  return state.room.layout === 'lesson';
}


export function isMobileLayout(state: State): boolean {
  return state.room.layout === 'mobile';
}

export function amRoomOwner(state: State): boolean {
  const myUid = getWsUserId(state.websocket);
  if (myUid) {
    const myUser = state.room.roster[myUid];
    if (myUser && myUser.role) {
      return ['room_owner'].includes(myUser.role);
    }
  }
  return false;
}

/*
 * now WebinarPresenter == RoomOwner
 * but the logic can be changed here transparently
 */
export function amWebinarPresenter(state: State): boolean {
  return amRoomOwner(state);
}

/**
 * Checks whether the current meeting is personal
 */
export function isPersonal(state: State): boolean {
  // eslint-disable-next-line @typescript-eslint/camelcase
  return (getWsMeetingDetails(state.websocket) || { room_type: '' }).room_type === 'personal';
}

/**
 * Checks whether the current meeting is my personal room
 */
export function isMyPersonalRoom(state: State): boolean {
  return state.room.amIRoomOwner && isPersonal(state);
}


type remoteVideoRosterUser = {
  streamPublisherData: RosterUser['screenPublisherData'];
}

type remoteVideoRoster = {
  [k: string]: remoteVideoRosterUser;
}

export const isRemoteVideoStarting = <T extends remoteVideoRoster>(roster: T, user: string): boolean => {
  const userData = roster[user] || { };
  const streamPublisherData = userData.streamPublisherData || { subscribeRequested: false };

  return streamPublisherData.subscribeRequested;
};


/**
 * Checks whether the local or remote stream is being published
 */
export function isVideoStarting(state: State, user: string): boolean {
  const me = getWsUserId(state.websocket);

  if (user === me) {
    switch (state.room.localStreamState) {
      case 'PUBLISHING':
        return true;
      default:
        return false;
    }
  } else {
    // look in roster
    return isRemoteVideoStarting(state.room.roster, user);
  }
}


function _getDeskControlledUser(state: State) {
  const roster = state.room.roster;
  const user = Object.keys(roster).find((user) => roster[user].isDesktopControlEnabled);
  return user
    ? { user: user, hasStartedDrawing: roster[user].hasStartedDrawing }
    : null;
}


/**
 * @returns the controlled user if any otherwise undefined
 */
export const getMemoizedDeskControlledUser = createSelector([_getDeskControlledUser], (dCUser) => {
  return dCUser ? dCUser.user : null;
});


/**
 * Checks whether the controlled user is drawing
 */
export const getDrawingHasStarted = createSelector([_getDeskControlledUser], (dCUser) => {
  return (dCUser && dCUser.hasStartedDrawing);
});


/**
 * Checks whether the given room is joined
 */
export function roomJoined(room: Channel | null, roomName: string | null) {
  roomName = roomName == null ? "" : roomName;
  return Boolean(room) && !roomName.startsWith("waiting_room");
}


/**
 * Checks whether the pane component is open
 */
export function isPaneOpen(state: State) {
  return state.room.selectedPane !== PaneType.None;
}

/**
 * Checks whether the roster pane is open
 */
export function isRosterOpen(state: State) {
  return state.room.selectedPane === PaneType.Roster;
}

/**
 * Checks whether the chat pane is open
 */
export function isChatOpen(state: State) {
  return state.room.selectedPane === PaneType.Chat;
}

/**
 * Checks whether the info pane is open
 */
export function isInfoOpen(state: State) {
  return state.room.selectedPane === PaneType.Info;
}
