import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useIntl, defineMessages } from 'react-intl';
import { connect, useDispatch } from 'react-redux';

import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import { WithAuthorization } from '../../authorization';
import { setLockedJoinRequestDialogMinimized } from '../../lib/actions/notifications';
import { logEvent, Event } from '../../lib/analytics';
import { State } from '../../lib/reducers';
import { LockedJoinRequest } from '../../lib/redux_types';
import { usePrevious } from '../../lib/utils/hooks';
import play, { tones } from '../../lib/utils/webaudio/tones';
import { checkPermissionNotification, sendNotification } from '../../notification';
import { IconUserWait } from '../IconSet';


const messages = defineMessages({
  showLockedJoinRequests: { id: 'showLockedJoinRequests' },
  lockedJoinRequestTitle: { id: 'lockedJoinRequestTitle' },
  lockedJoinRequestBody: { id: 'lockedJoinRequestBody' }
});


type MappedProps = {
  isLocked: boolean;
  lockedJoinRequests: State['notifications']['lockedJoinRequests'];
  isDialogMinimized: boolean;
}

const mapStateToProps = (state: State): MappedProps => ({
  isLocked: state.room.isLocked,
  lockedJoinRequests: state.notifications.lockedJoinRequests,
  isDialogMinimized: state.notifications.lockedJoinRequestDialogMinimized,
});

type Props = {
} & MappedProps

function LockedJoinRequestsButton(props: Props) {
  const { formatMessage } = useIntl();
  const { isLocked, lockedJoinRequests, isDialogMinimized } = props;
  const dispatch = useDispatch();

  // check browser notification permission at mount
  React.useEffect(() => checkPermissionNotification(), []);

  // send notification on lockedJoinRequests prop change
  const defaultValue: State['notifications']['lockedJoinRequests'] = [];
  const prevLastLockedJoinRequest =  usePrevious(lockedJoinRequests, defaultValue);

  React.useEffect(
    () => {
      const newLockedJoinRequests = lockedJoinRequests.filter((req1: LockedJoinRequest) =>
        !prevLastLockedJoinRequest.some((req2: LockedJoinRequest) => req2.reqId === req1.reqId));
      if (newLockedJoinRequests.length > 0) {
        play(tones.lockedJoinRequest);
        if (!document.hasFocus()) {
          newLockedJoinRequests.forEach((r: LockedJoinRequest) => {
            const title = formatMessage(messages.lockedJoinRequestTitle);
            const lastMessage = formatMessage(messages.lockedJoinRequestBody, { dname: r.dname });
            sendNotification(title, lastMessage);
          });
        }
      }
    },
    [prevLastLockedJoinRequest, lockedJoinRequests, formatMessage]
  );

  const showRequests = (): void => {
    logEvent(Event.LOCKED_REQUESTS, { 'action': isDialogMinimized ? 'open' : 'close' });
    dispatch(setLockedJoinRequestDialogMinimized(!isDialogMinimized));
  };
  const msg = formatMessage(messages.showLockedJoinRequests);

  const requestCount = lockedJoinRequests.length;

  // requires fixing because the tooltip won't hide on dialog show and icon change
  const getIcon = () => {
    return (
      <Tooltip placement="left" title={msg}>
        <div onClick={showRequests}>
          <Badge badgeContent={requestCount} color="secondary">
            <IconUserWait size={28} />
          </Badge>
        </div>
      </Tooltip>
      // <React.Fragment>
      //   {isDialogMinimized
      //     ? <IconUserWait size={28} />
      //     : <IconUserWaitSelected size={28} />
      //   }
      // </React.Fragment>
    );
  };

  const getDesktopIcon = () => {
    return (
      <ListItem button>
        <ListItemIcon>
          { getIcon() }
        </ListItemIcon>
      </ListItem>
    );
  };

  if (isLocked && requestCount > 0) {
    return (
      <React.Fragment>
        { isMobileOnly ? getIcon() : getDesktopIcon() }
      </React.Fragment>
    );
  } else {
    return null;
  }
}

const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, connect(mapStateToProps)(LockedJoinRequestsButton));
