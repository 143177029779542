import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { toggleInviteParticipantsDialog } from '../../lib/actions/room';
import { logEvent, Event } from '../../lib/analytics';
import { IconUserAdd } from '../IconSet';

import messages from './buttonsMessages';


function InviteButton() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onClick = React.useCallback(
    () => {
      dispatch(toggleInviteParticipantsDialog());
      logEvent(Event.INVITE_USERS, { 'from': 'own_controls' });
    }
    , [dispatch]
  );

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <IconUserAdd size={32} />
      </ListItemIcon>
      <ListItemText primary={formatMessage(messages.invite)} />
    </MenuItem>
  );
}


export default InviteButton;
